<template>
    <div class="container-fluid content-wrapper">
        <div v-if="noSessions">
            <div class="row pt-5">
                <div class="col-12 text-center">
                    <h2 class="text-thin">You have no open user sessions.</h2>
                </div>
            </div>
        </div>
        <p-modal
            size="lg"
            label="Multiple Sessions"
            name="buyerSessions"
            ref="buyerSessions"
            :disable-button="disabled"
            hide-footer
        >
            <template v-slot:default>
                <p-form ref="form">
                    <div class="text-center">
                        <h5>
                            Please choose which session you would like to login to
                            <br />
                            <i>
                                *Note: You will be logged out of the other
                                session</i
                            >
                        </h5>
                    </div>
                    <div>
                        <b-row cols="1" cols-sm="2" cols-xl="4">
                            <b-col
                                class="mt-2"
                                v-for="buybackSession in buybackSessions"
                                :key="buybackSession.buybackSessionId"
                            >
                                <p-button
                                    class="pr-0 mr-0"
                                    variant="outline-primary"
                                    @click="
                                        redirectBuyBooks(
                                            buybackSession.buybackSessionId
                                        )
                                    "
                                    >{{ buybackSession.description }}</p-button
                                >
                            </b-col>
                        </b-row>
                    </div>
                </p-form>
            </template>
        </p-modal>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            buybackSessions: [],
            noSessions: false
        };
    },
    methods: {
        async loadData() {
            let promise = axios.get('buyback/usersessions');

            promise.then(resp => {
                this.buybackSessions = resp.data;
                if (this.buybackSessions.length === 0) {
                    this.noSessions = true;
                    this.$toasted.global
                        .app_error(`You have no open user sessions`)
                        .goAway(5000);
                    return;
                }
                this.$refs['buyerSessions'].show();
            });
        },
        redirectBuyBooks(sessionId) {
            this.$router.push('buybooks/' + sessionId);
        }
    },
    mounted: async function() {
        await this.loadData();
    }
};
</script>
