var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-modal',{ref:"editTaxRateModal",attrs:{"icon-plus":_vm.isNew,"size":_vm.sizemodal,"label":_vm.pageTitle,"no-close-on-backdrop":"","no-close-on-esc":"","ok-only":""},on:{"ok":function($event){$event.preventDefault();return _vm.handleModalOk.apply(null, arguments)},"show":_vm.loadData,"close":_vm.checkDirtyBeforeModalClose},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.taxRate)?_c('div',{staticClass:"container-fluid content-wrapper"},[_c('p-form',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('p-input',{attrs:{"label":"Tax Name","name":"Tax Name","rules":"required|max:80","mode":"lazy"},model:{value:(_vm.taxRate.name),callback:function ($$v) {_vm.$set(_vm.taxRate, "name", $$v)},expression:"taxRate.name"}})],1)],1),_c('b-row',[_c('b-col',[_c('p-card',{staticStyle:{"height":"350px"}},[_c('h4',[_vm._v("Current Tax Rate")]),_c('p-table',{attrs:{"items":_vm.taxRate.taxCodes,"fields":_vm.computedFields,"show-record-count":false,"enable-row-selection":false,"foot-clone":""},scopedSlots:_vm._u([{key:"cell(rate)",fn:function(ref){
                                        var value = ref.value;
                                        var item = ref.item;
                                        var index = ref.index;
return [_c('p-number',{attrs:{"name":item.customTaxRateName + ' rate',"data-input-name":'currentTaxRate' + (index + 1),"compact-format":"","push-right":"","disabled":!_vm.isNew,"input-type":"percentHighPrecision","rules":"required|min_value:0|max_value:99.999"},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", $$v)},expression:"item.rate"}})]}},{key:"foot(rate)",fn:function(data){return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.percentFormatter(_vm.total, 3))+" ")])]}},{key:"foot(customTaxRateName)",fn:function(data){return [_c('div',{staticClass:"font-weight-bold pr-2"},[_vm._v(" Tax Combined Total ")])]}}],null,false,3904193367)})],1)],1),(!_vm.isNew)?_c('b-col',{attrs:{"cols":"auto"}},[_c('p-card',{staticStyle:{"height":"350px"}},[_c('h4',[_vm._v("New Tax Rate")]),_c('p-table',{attrs:{"items":_vm.taxRate.taxCodes,"fields":_vm.newfields,"show-record-count":false,"enable-row-selection":false,"foot-clone":""},scopedSlots:_vm._u([{key:"cell(scheduledRate)",fn:function(ref){
                                        var value = ref.value;
                                        var item = ref.item;
return [_c('p-number',{attrs:{"name":item.customTaxRateName + ' rate',"compact-format":"","push-right":"","input-type":"percentHighPrecision","rules":"min_value:0|max_value:99.999"},on:{"input":function($event){_vm.scheduleIsDirty = true}},model:{value:(item.scheduledRate),callback:function ($$v) {_vm.$set(item, "scheduledRate", $$v)},expression:"item.scheduledRate"}})]}},{key:"foot(customTaxRateName)",fn:function(data){return [_c('div',{staticClass:"font-weight-bold pr-2"},[_vm._v(" Tax Combined Total ")])]}},{key:"foot(scheduledRate)",fn:function(data){return [_c('div',{staticClass:"font-weight-bold pr-2"},[_vm._v(" "+_vm._s(_vm.percentFormatter( _vm.scheduledTotal, 3 ))+" ")])]}}],null,false,310726434)}),(!_vm.isNew)?_c('b-row',{attrs:{"cols":"2"}},[_c('b-col',[_c('p-datepicker',{attrs:{"label":"Start Date","start-date":_vm.tomorrow},on:{"date-picker-initialized":function (e) {_vm.scheduleIsDirty = false},"input":function($event){_vm.scheduleIsDirty = true}},model:{value:(_vm.scheduledDate),callback:function ($$v) {_vm.scheduledDate=$$v},expression:"scheduledDate"}})],1),_c('b-col',{staticClass:"text-center",staticStyle:{"margin-top":"30px"}},[_c('p-button',{attrs:{"variant":"primary","disabled":!_vm.canAddToSchedule},on:{"click":_vm.addToSchedule}},[_vm._v("Add to Schedule")])],1)],1):_vm._e()],1)],1):_vm._e()],1)],1),(!_vm.isNew)?_c('b-row',{staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('h4',[_vm._v("Scheduled Updates")])])],1):_vm._e(),(!_vm.isNew)?_c('p-card',{attrs:{"name":"scheduledUpdatesCard"}},[_c('b-overlay',{staticClass:"d-inline-block w-100",attrs:{"show":_vm.addingSchedule,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('p-table',{ref:"scheduleTable",attrs:{"items":_vm.scheduledRates,"fields":_vm.scheduleFields,"show-record-count":false,"enable-row-selection":false},scopedSlots:_vm._u([{key:"cell(description)",fn:function(ref){
                                var value = ref.value;
                                var item = ref.item;
return [_vm._l((item.taxCodes),function(taxCode){return [_vm._v(" "+_vm._s(taxCode.customTaxRateName)+" "),_c('br',{key:taxCode.taxRateName})]}),_c('strong',[_vm._v("Total")])]}},{key:"cell(rate)",fn:function(ref){
                                var value = ref.value;
                                var item = ref.item;
return [_vm._l((item.taxCodes),function(taxCode){return [_vm._v(" "+_vm._s(_vm.percentFormatter(taxCode.rate, 3))+" "),_c('br',{key:taxCode.taxRateName})]}),_c('strong',[_vm._v(_vm._s(_vm.percentFormatter( item.taxCodes .map(function (x) { return x.rate; }) .reduce(function (a, b) { return a + b; }, 0), 3 )))])]}},{key:"cell(removeScheduledRate)",fn:function(ref){
                                var value = ref.value;
                                var item = ref.item;
                                var index = ref.index;
return [_c('b-button',{staticClass:"p-1 ml-1 text-danger short-button",attrs:{"name":'deleteUpdate' + (index + 1),"variant":"light"},on:{"click":function($event){return _vm.removeScheduledRate(item)}}},[_c('BIconTrash',{staticClass:"mb-2",attrs:{"font-scale":"0.9","icon":"trash"}})],1)]}}],null,false,4001937560)})],1)],1):_vm._e()],1):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(ref){
                                var ok = ref.ok;
return [_c('b-row',{staticClass:"w-100"},[_c('b-col',{staticClass:"text-center"},[_c('p-button',{staticClass:"ml-2",attrs:{"is-busy":_vm.isBusy,"name":"saveEditTaxRate","variant":"primary","disabled":_vm.saveDisabled},on:{"click":ok}},[_vm._v("Save")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }