var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-secure',{attrs:{"require-role":[_vm.SECURITYROLES.STOREMANAGER]}},[_c('div',{staticClass:"container-fluid content-wrapper"},[_c('b-row',{staticClass:"content-heading"},[_c('b-col',[_c('div',[_vm._v("Tax Rates")])])],1),_c('b-overlay',{staticClass:"mt-5",attrs:{"show":!_vm.taxData,"rounded":"","opacity":"0.5","spinner-variant":"primary"}}),(_vm.taxData)?_c('b-tabs',{attrs:{"justified":""}},[_c('b-tab',{attrs:{"title":"Tax Rates"}},[_c('b-row',[_c('b-col',[_c('editRate',{staticClass:"pb-2 ml-3 mr-2",attrs:{"tax-rate-id":"new"},on:{"data-saved":_vm.loadData}})],1)],1),_c('b-card',[_c('p-table',{staticStyle:{"white-space":"pre-line"},attrs:{"fields":_vm.fields,"items":_vm.taxData.taxRates,"selectable":false,"enable-row-selection":false,"is-busy":_vm.isBusy,"sort-by":"name","show-per-page-selector":true,"per-page":_vm.filters.perPage,"show-top-pagination":true}})],1)],1),_c('p-feature-flag',{attrs:{"name":"Tax Nexus"}},[_c('b-tab',{attrs:{"title":"Tax Nexus"}},[_c('b-row',[_c('b-col',[_c('addTaxNexus',{staticClass:"pb-2 ml-3 mr-2",attrs:{"excluded-states":_vm.stateIds,"warning-message":_vm.AUTO_UPDATE_WARNING},on:{"data-saved":_vm.loadNexusData}})],1)],1),_c('b-card',[_c('p-table',{ref:"taxNexusSearch",attrs:{"fields":_vm.nexusFields,"items":this.taxNexus,"selectable":false,"enable-row-selection":false,"is-busy":_vm.isBusy,"sort-by":"stateName"},scopedSlots:_vm._u([{key:"cell(isActive)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"extra-padding d-inline-block"},[_c('span',{staticClass:"v-center"},[_c('p-checkbox',{staticClass:"d-inline-block",attrs:{"use-switch":true,"size":"lg"},on:{"change":function($event){return _vm.updateItemShippingTaxCollected(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}}),_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(item.isActive ? 'Active' : 'Inactive')+" ")])],1)])]}},{key:"cell(autoUpdate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"extra-padding"},[_c('span',{staticClass:"v-center"},[_c('p-checkbox',{attrs:{"use-switch":true,"value":item.autoUpdate,"size":"lg"},on:{"change":function($event){return _vm.updateItemAutoUpdate(item)}}}),_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(item.autoUpdate ? 'Active' : 'Inactive')+" ")])],1)])]}},{key:"cell(isShippingTaxCollected)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"extra-padding"},[_c('span',{staticClass:"v-center"},[_c('p-checkbox',{attrs:{"use-switch":true,"size":"lg"},on:{"change":_vm.saveUpdates},model:{value:(item.isShippingTaxCollected),callback:function ($$v) {_vm.$set(item, "isShippingTaxCollected", $$v)},expression:"item.isShippingTaxCollected"}}),_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(item.isShippingTaxCollected ? 'Active' : 'Inactive')+" ")])],1)])]}},{key:"cell(remove)",fn:function(ref){
var item = ref.item;
return [_c('p-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeNexus(item)}}},[_c('BIconTrash',{staticClass:"mr-2",attrs:{"icon":"trash"},on:{"click":function($event){return _vm.removeNexus(item)}}}),_vm._v(" Remove ")],1)]}}],null,false,740013804)})],1)],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }