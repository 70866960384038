<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="xl"
        :label="pageTitle"
        ref="editSessionModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
        :button-variant="buttonVariant"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div v-if="session">
                    <b-row>
                        <b-col cols="8">
                            <p-input
                                label="Description"
                                v-model="session.description"
                                rules="required|max:50"
                            />
                        </b-col>
                        <b-col cols="4">
                            <p-input
                                label="Status"
                                v-model="session.status"
                                disabled="disabled"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                            <p-select
                                label="Session Type"
                                v-model="session.sessionType"
                                :options="sessionTypes"
                                text-field="value"
                            />
                        </b-col>
                        <b-col cols="3">
                            <p-select
                                label="Cash Provider"
                                v-model="session.cashProvider"
                                :options="cashProviders"
                                text-field="value"
                            />
                        </b-col>
                        <b-col cols="6">
                            <p-select
                                label="Copied From Session Setup"
                                v-model="session.copiedFromSessionId"
                                no-selection-message="None"
                                data-type="buybackSessions"
                            >
                            </p-select>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default {
    mixins: [NavigationGuard],
    props: {
        sessionId: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,
            session: null,
            sessionTypes: [
                { value: 'Student Buy' },
                { value: 'Excess Store Stock' }
            ],
            cashProviders: [{ value: 'Store' }, { value: 'Wholesale' }]
        };
    },

    computed: {
        isNew() {
            return this.sessionId === 'new';
        },
        pageTitle() {
            return this.isNew ? 'Add Buyback Session' : 'Edit Buyback Session';
        },
        buttonLabel() {
            return this.isNew ? 'Create Session' : 'Edit';
        },
        buttonVariant() {
            return this.isNew ? 'primary' : 'outline-primary';
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            axios.post('buyback/session', this.session).then(resp => {
                this.$emit('data-saved');

                this.$router.push(
                    'buybacksessions/' + resp.data.buybackSessionId
                );

                this.$toasted.global
                    .app_success(
                        `Session '${this.session.description}' saved successfully.`
                    )
                    .goAway(5000);
            });
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('buyback/newsession')
                : axios.get('buyback/session', {
                      params: { id: this.sessionId }
                  });

            promise.then(resp => {
                this.session = resp.data;
                this.dataLoaded();
            });
        }
    }
};
</script>
