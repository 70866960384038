<template>
    <p-modal
            name='receivedNotYetInvoicedModal'
            label='Received Not Yet Invoiced Report'
            size='md'
            @hide='resetFilters'
            >
            <template v-slot:default>
                <b-row>
                    <b-col>
                        <p-card class='mb-2 px-2 py-2' no-body>
                            <label class='text-sm' v-if='supplierSelectorHelpText'>{{supplierSelectorHelpText}}</label>
                            <p-filter-select
                                dataType='suppliers'
                                label='Suppliers'
                                v-model='filters.supplierIds'
                            />
                        </p-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-card class='mb-2 px-2 py-2' no-body>
                            <label class='text-sm' v-if='dccSelectorHelpText'>{{dccSelectorHelpText}}</label>
                            <DCCsSelector class='container-fluid' @selection-change='filters.dccIds = $event' />
                        </p-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols='4'>
                        <p-datepicker
                            label='End Date'
                            v-model='filters.endDate'
                            is-end-date
                            />
                    </b-col>
                    <b-col class='pt-4'>
                        <p-checkbox
                            label='Include Details'
                            v-model='filters.showDetails'
                            />
                    </b-col>
                </b-row>
            </template>
            <template v-slot:modal-footer>
                 <p-button @click='$bvModal.hide("receivedNotYetInvoicedModal")'
                            variant='outline-primary'>Cancel</p-button>
                 <b-overlay :show='isBusy'
                                    rounded
                                    opacity='0.5'
                                    spinner-small
                                    spinner-variant='primary'
                                    class='d-inline-block'>
                        <b-dropdown
                            text='Export'
                            variant='primary'
                        >
                            <b-dropdown-item
                                @click='exportReceivedNotYetInvoiced("pdf")'
                                >PDF</b-dropdown-item
                            >
                            <b-dropdown-item
                                @click='exportReceivedNotYetInvoiced("csv")'
                                >CSV</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-overlay>
             </template>
        </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import DCCsSelector from '@/components/DCCsSelector.vue';

export default {
    data() {
        return {
            filters: {
                supplierIds: [],
                dccIds: [],
                endDate: null,
                showDetails: false
            },
            isBusy: false,
            sortOrderOptions: [
                'SKU',
                'Description'
            ]
        };
    },
    components: {
        DCCsSelector
    },
    computed: {
        dccSelectorHelpText() {
            return this.filters.dccIds.length === 0
                ? 'ALL DCCs will be included in the report, you may select individual DCCs below.'
                : '';
        },
        supplierSelectorHelpText() {
            return this.filters.supplierIds.length === 0
                ? 'ALL Suppliers will be included in the report, you may select individual Suppliers below.'
                : '';
        }
    },
    methods: {
        exportReceivedNotYetInvoiced(exportFormat) {
            this.isBusy = true;
            if (this.filters.dccIds.length > 0) {
                //the dcc selector returns an object
                //for selected dccs, just need the
                //dccId
                this.filters.dccIds = this.filters.dccIds.map(d => d.dccId);
            }

            switch(exportFormat)
            {
                case 'pdf':
                    axios.post('receivednotyetinvoiced/export', this.filters, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Received Not Yet Invoiced Report', 'application/pdf');
                                this.$toasted.global.app_success(`Received Not Yet Invoiced report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport('receivedNotYetInvoicedModal');
                        });
                    break;
                case 'csv':
                    axios.post('receivednotyetinvoiced/exportcsv', this.filters, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Received Not Yet Invoiced Report.csv', 'application/csv');
                                this.$toasted.global.app_success(`Received Not Yet Invoiced report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport('receivedNotYetInvoicedModal');
                        });
                    break;
            }
        },
        resetFilters: function(){
            this.filters = {
                supplierIds: [],
                dccIds: [],
                endDate: null,
                showDetails: false,
                showPending: false
            };
        },
        finalizeExport(modalName) {
            this.isBusy = false;
            this.$bvModal.hide(modalName);
        }
    }
}
</script>