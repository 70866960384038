import {min_value} from 'vee-validate/dist/rules';
import {extend} from 'vee-validate';
import {pDecimalValidationTest} from '@/components/mixins/ValidatedInput.js';
import SharedValidation from './SharedValidation.js';
import {dateFormatter} from "@/components/Common/Formatters.js";


extend('unique-value', {
    params: ['isDuplicated'],
    validate(value, {isDuplicated}) {
        return !isDuplicated(value);
    },
    message(field) {
        return `${field} cannot be be duplicated.`
    },
});

extend('valid-isbn', {
    params: ['isValidISBN'],
    validate(value, {isValidISBN}) {
        return isValidISBN(value);
    },
    message() {
        return `The ISBN length excluding dashes must be 10 or 13.`
    },
});

export default {
    mixins: [SharedValidation],
    data() {
        return {
            TradeBookConfiguration: {
                type: 'tradebooks',
                label: 'Trade Books',
                sampleNotes: [
                    'Barcodes will be generated from ISBN.',
                    'Binding matches on abbreviation.',
                    '"DCC fields are a concatenation of department, class, and category 2 digit numbers (151010). "',
                    '"Dates are expected to be in YYYY-MM-DD format."',
                ],
                fields: [
                    {
                        key: 'ISBN',
                        hasErrorCalculator: (value, row, checkOriginalData) => {                        
                            if (this.isEmpty(value)) {
                                return true;
                            }

                            return value.length < 10 || 
                                this.isDuplicatedOnImportISBN(value, checkOriginalData) || 
                                !this.isValidISBN(value)
                        },
                        rules: {
                            'unique-value': {
                                isDuplicated: this.isDuplicatedOnImportISBN
                            },
                            'valid-isbn': {
                                isValidISBN: this.isValidISBN
                            }, 
                            min: {length: 10},
                            required: true,   
                        },
                        samples: ['978-0-534-58484-9','978-0-534-58486-3','978-0-534-58505-1'],
                        convertToNumber: false
                    },
                    {
                        key: 'Title',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            if (value === '' || value === undefined) {
                                return true;
                            }
                            return value.length > 200;
                        },
                        rules: {
                            max: {length: 200},
                            required: true
                        },
                        samples: ['"Trade Book Title1"','"Trade Book Title2"','"Trade Book Title3"']
                    },
                    {
                        key: 'Author',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            if (value === '' || value === undefined) {
                                return true;
                            }
                            return value.length > 200;
                        },
                        rules: {
                            max: {length: 20},
                            required: true
                        },
                        samples: ['"Doe, Jane"','"Doe, John"','"Doe, Jim"']
                    },
                    {
                        key: 'Edition',
                        hasWarningCalculator: value => {
                            if (value === '' || value === undefined) {
                                return false;
                            }
                            return value.length > 20;
                        },
                        warningDefault: null,
                        rules: {
                            max: {length: 20}
                        },
                        samples: ['"1"','"2"','"1"'],
                        convertToNumber: false
                    },
                    {
                        key: 'Copyright',
                        hasWarningCalculator: value => {
                            if (value === '' || value === undefined) {
                                return false;
                            }
                            return value.length > 10;
                        },
                        warningDefault: null,
                        rules: {
                            max: {length: 10}
                        },
                        samples: ['"14"','"18"','"22"'],
                        convertToNumber: false
                    },
                    {
                        key: 'Binding',
                        hasWarningCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"3R"', '"AC"', 'NO'],
                        dataType: 'bindings',
                        comparisonProperty: 'abbreviation'
                    },
                    {
                        key: 'Imprint',
                        hasWarningCalculator: value => {
                            if (value === '' || value === undefined) {
                                return false;
                            }
                            return value.length > 20;
                        },
                        warningDefault: null,
                        rules: {
                            max: {length: 20}
                        },
                        samples: ['"Avery"','"DK"','"DK"'],
                        convertToNumber: false
                    },
                    {
                        key: 'SupplierCode',
                        label: 'Supplier Code',
                        dataType: 'suppliers',
                        comparisonProperty: 'code',
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                value === undefined ||
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        rules: 'min_value:0|required',
                        samples: ['AMY001','BAR001','YAR001']
                    },
                    {
                        key: 'Status',
                        hasWarningCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"None"', '"New Edition Pre"', '"Old Edition"'],
                        dataType: 'textStatus'
                    },
                    {
                        key: 'StatusDate',
                        hasWarningCalculator: value => {
                            return !this.isValidDate(value);
                        },
                        warningDefault: null,
                        rules: {
                            'valid-date': {
                                isValidDate: this.isValidDate,
                                propertyName: 'StatusDate'
                            }
                        },
                        inputType: 'date',
                        formatter: dateFormatter,
                        addRowDataToRule: 'valid-date',
                        samples: [
                            '"2022-10-09"',
                            '"2022-10-09"',
                            '"2022-10-05"'
                        ]
                    },
                    {
                        key: 'Comment',
                        hasWarningCalculator: value => {
                            if (value === '' || value === undefined) {
                                return false;
                            }
                            return value.length > 200;
                        },
                        warningDefault: null,
                        rules: {
                            max: {length: 200}
                        },
                        samples: ['"Product Comment1"','"Product Comment2"','""']
                    },
                    {
                        key: 'TradeDCC',
                        selectOptionsFilter: () => {return 2;},
                        hasErrorCalculator: (value) => {
                            if (this.isEmpty(value)) {
                                return true;
                            }
                            return !this.matchesExistingObject(value, true);
                        },
                        rules: 'min_value:0|required',
                        samples: ['151010','151010','151010'],
                        comparisonProperty: 'dccNumbers',
                        dataType: 'dcc',
                    },
                    {
                        key: 'TradeCost',
                        hasErrorCalculator: value => {
                            return (
                                value &&
                                !pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                })
                            );
                        },
                        rules: 'p-decimal:10,2|min_value:0',
                        samples: [10.55, 50, 50],
                        convertToNumber: true
                    },
                    {
                        key: 'TradeRetail',
                        hasErrorCalculator: value => {
                            return (
                                !pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                })
                            );
                        },
                        rules: 'p-decimal:10,2|min_value:0|required',
                        samples: [20.55, 70, 80],
                        convertToNumber: true
                    },
                ]
            }
        };
    },
    methods: {
        isDuplicatedOnImportISBN(isbn, checkOriginalData) {
            return (
                isbn != '' &&
                this.loadedData.filter(x => x.ISBN === isbn||
                    (checkOriginalData &&
                        x.originalData?.ISBN === isbn)).length > 1
            );
        },
        isValidISBN(isbn){
            var isbnCompressed = isbn.replace(/-/g,'');
            return (isbnCompressed.length === 10 || isbnCompressed.length === 13);
        }
    }
};
