<template>
  <ContentWrapper>
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="text-thin">You do not have permission to perform this action.</h2>
            </div>
        </div>
    </ContentWrapper>
</template>

<script>
export default {

}
</script>

<style>

</style>