<template>
    <p-modal
        name="WebSalesModal"
        label="Web Sales and Tax by State"
        ref="WebSalesModal"
        size="lg"
    >
        <template v-slot:default>
            <p-form ref="webSalesForm">
                <div class="container-fluid content-wrapper">
                    <b-row cols="1">                        
                        <b-col>
                                <p-advance-date-range :date-range-options="reportParameters" />                               
                           
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer>
            <b-row>
                <b-col>
                    <b-overlay :show="isBusy"
                                   rounded
                                   opacity="0.5"
                                   spinner-small
                                   spinner-variant="primary"
                                   class="d-inline-block">
                            <b-dropdown text="Export"
                                        variant="outline-primary">
                                <b-dropdown-item @click="exportWebSalesReport('html')">HTML</b-dropdown-item>
                                <b-dropdown-item @click="exportWebSalesReport('csv')">CSV</b-dropdown-item>
                            </b-dropdown>
                        </b-overlay>
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    data() {
        return {
            isBusy: false,
            reportParameters: {
                fromDate: null,
                toDate: null,
                dateOption: 0,
                locationId: null
            },
        };
    },
    methods: {
        async show() {
            this.$refs.WebSalesModal.show();
        },
        exportWebSalesReport: async function (exportFormat) {
            if (!(await this.$refs.webSalesForm.validate())) {
                return;
            }

            this.isBusy = true;

            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();       

            switch(exportFormat)
            {
                case 'html':
                    axios.post('sales/exportWebSalesTaxByStateHTML', this.reportParameters, { responseType: 'text/html' } )
                        .then((result) => {
                                console.log(result);
                                var htmlContent = result.data;
                                var newTab = window.open();
                                newTab.document.write(htmlContent);
                                newTab.document.close();
                                this.$toasted.global.app_success(`Web Sales report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport();
                        });
                    break;
                case 'csv':
                    axios.post('sales/exportWebSalesTaxByStateCSV', this.reportParameters, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Web Sales Report.csv', 'application/csv');
                                this.$toasted.global.app_success(`Web Sales report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport();
                        });
                    break;
            }
        },
        finalizeExport() {
            this.isBusy = false;
            this.$refs.WebSalesModal.hide();        
        }
    },
};
</script>
