export default {
    data() {
        return {
            formIsDirty: false,
            displayMessage: null,
            prevLocation:"",
            isLocationChanged: false
        };
    },
    methods: {
        preventNav: function(event) {
            if (!this.formIsDirty) return;
            event.preventDefault();
            event.returnValue = '';
        },
        dataLoaded: function() {
            setTimeout(() => {
                this.formIsDirty = false;
            }, 0);
        },
        dataSaved: function() {
            this.formIsDirty = false;
        },
        async warnBeforeLeaving(next) {
            await this.$bvModal
                .msgBoxConfirm([this.displayMessage])
                .then(value => {
                    if (!value) {
                        if(this.isLocationChanged){
                            this.$store.commit('updateSelectedLocationId', this.prevLocation)
                            localStorage.setItem(this.filterName, this.prevLocation);
                            this.isLocationChanged = false;
                        }
                        next(false);
                        return;
                    } else{
                        this.isLocationChanged = false;
                        next();
                    }
                });
        },
        checkDirtyBeforeModalClose(bvModalEvt) {
            if(this.formIsDirty){
                // Prevent modal from closing
                bvModalEvt.preventDefault();
                this.$bvModal
                .msgBoxConfirm([this.displayMessage]).then(value => {
                    if (value) {
                        //close
                        bvModalEvt.vueTarget.hide();
                        this.dataSaved();
                    }
                });
            }
        }
    },
    beforeMount() {
        window.addEventListener('beforeunload', this.preventNav);
        this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('beforeunload', this.preventNav);
        });
    },
    async beforeRouteLeave(to, from, next) {
        if (this.formIsDirty) {
            await this.warnBeforeLeaving(next);
            return;
        }
        next();
    },
    computed: {
        selectedLocation(){
            return this.$store.getters.getSelectedLocationId;
        }
    },
    watch: {
        selectedLocation: {
            deep: true,
            handler: function(newVal, oldVal) {
                this.prevLocation = oldVal;
                this.isLocationChanged = true;
            }
        }
    },
    $watcher: {
        handler: function() {
            this.formIsDirty = true;
        },
        deep: true
    },
    created() {
        const h = this.$createElement;
        this.displayMessage = h('div', [
            h('p', ['Are you sure you want to leave without saving?']),
            h('p', ['Select ‘OK’ to discard changes or ‘Cancel’ to return to editing.'])
        ]);
    }
};