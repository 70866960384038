<template>
    <b-row cols="1" cols-xl="3">
        <b-col>
            <a href="/weborders" class="no-decoration">  
            <p-box-widget
                primary-bg="bg-white"
                secondary-bg="bg-white"
                primary-text="Web Orders"
                secondary-text="Open Web Orders"
                primary-text-color="#316076"
                secondary-text-color="#3a3f51"
                border
            >
                <div class="left-box">{{statusCount.openWeborders}}</div>
            </p-box-widget>
            </a>
        </b-col>      
        <b-col>                   
            <p-box-widget
                primary-bg="bg-white"
                secondary-bg="bg-white"
                primary-text="Stock Shortages"
                secondary-text="Orders With Fulfillment Shortages"
                primary-text-color="#316076"
                secondary-text-color="#3a3f51"
                border
            >
                <div class="left-box">{{statusCount.stockShortage}}</div>
            </p-box-widget>  
        </b-col>        
        <b-col>
            <a href="/purchaseorders" class="no-decoration">    
            <p-box-widget
                primary-bg="bg-white"
                secondary-bg="bg-white"
                primary-text="Outstanding PO's"
                secondary-text="Outstanding Purchase Orders"
                primary-text-color="#316076"
                secondary-text-color="#3a3f51"
                border
            >
                <div class="left-box">{{statusCount.outstandingPOs}}</div>
            </p-box-widget>
            </a>
        </b-col>
    </b-row>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            statusCount: {
                openWeborders:"",
                stockShortage:"",
                outstandingPOs:""
            }
        };
    },
    methods: {
        loadData() {
            axios.get('dashboard/weborderstatus').then(resp => {
                this.statusCount = resp.data;
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
 a.no-decoration{
    text-decoration: none;
    color: $body-bg;
}
.left-box{
    color:$blue-darker;
    font-size:46px;
    font-weight:bold;
}
</style>
