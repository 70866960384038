<template>
    <p-modal name="exportInventoryByFixtureModal"
                label="Export Inventory By Fixture"
                ref="exportInventoryByFixtureModal"
                size="sm"
                @show="loadParameters">
        <template v-slot:default>
            <div class="container-fluid content-wrapper">
                <b-row cols="1" >
                    <b-col>
                        <p-input label="Fixture"
                                    v-model="reportParameters.fixture" />
                        <p-input label="Area"
                                    v-model="reportParameters.area" />
                        <p-select v-model="reportParameters.sortBy"
                                    name="Sort By"
                                    label="Sort By"
                                    :options="sortByInFixtureReport" />

                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-slot:modal-footer >
            <b-row>
                <b-col class=" mb-3 text-center">
                        <p-button  :is-busy="isBusy" variant="outline-primary" @click="exportInventoryByFixtureReport('csv')"> Export CSV</p-button>
                    </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>

import reports from './reports.js';

export default {
    props: {},
    mixins: [reports],
    data() {
        return {
        };
    },
     components: {
    },
    methods: {
        exportInventoryByFixtureReport(type) {
            let destination = type === 'csv' ? '/physicalInventory/exportInventoryByFixtureCsv' : "";
            let message = `Inventory By Fixture Csv report generated successfully.`;
            let fileName = 'Inventory By Fixture';
            this.exportReport(type, destination, message, fileName);
        },

        show() {
            this.$refs.exportInventoryByFixtureModal.show();
        },
    }
};

</script>

<style scoped></style>
