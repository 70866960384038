<template>
    <p-modal
        name="SalesEventModal"
        label="Sales Event"
        size="xl"
        ref="salesEvent"
        ok-only
    >
        <template v-slot:default>
            <p-form ref="form">
                <b-row cols="1" cols-lg="6" justify-content-start>
                    <b-col>
                        <span>Discount Type</span>
                        <b-radio-group
                            class="mt-3"
                            v-model="
                                productInventory.salesEvent.priceMod
                                    .discountType
                            "
                            disabled
                        >
                            <b-radio :value="1">Dollar ($)</b-radio>
                            <b-radio :value="2">Percentage (%)</b-radio>
                        </b-radio-group>
                    </b-col>
                    <b-col>
                        <p-number
                            label="Discount Amount"
                            :inputType="
                                getInputType(
                                    productInventory.salesEvent.priceMod
                                        .discountType
                                )
                            "
                            v-model="
                                productInventory.salesEvent.priceMod.amount
                            "
                            disabled="disabled"
                            left-align
                        />
                    </b-col>
                    <b-col>
                        <p-number
                            label="Event Price"
                            :value="eventPrice"
                            disabled="disabled"
                            input-type="money"
                            left-align
                        >
                        </p-number>
                    </b-col>
                    <b-col>
                        <p-datepicker
                            label="Event Start Date"
                            v-model="productInventory.salesEvent.startDate"
                            disabled
                        />
                    </b-col>
                    <b-col>
                        <p-datepicker
                            label="Event End Date"
                            v-model="productInventory.salesEvent.endDate"
                            disabled
                        />
                    </b-col>
                </b-row>
            </p-form>
        </template>
    </p-modal>
</template>

<script>
export default {
    props: {
        productInventory: {
            type: Object,
            default: () => null
        },
        eventPrice: null
    },

    methods: {
        getInputType(value) {
            return value == 2 ? 'percent' : 'money';
        }
    }
};
</script>
