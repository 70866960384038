<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <p-form @submit="onSubmit" ref="form">
            <div class="container-fluid content-wrapper" v-if="sessionSetup">
                <b-row class="content-heading">
                    <b-col>
                        <div>Buyback Session Setup</div>
                    </b-col>
                </b-row>
                <b-row>
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        class="ml-3 mr-2"
                        type="button"
                        @click="closeSession"
                        :disabled="statusClosed"
                        >Close Session</p-button
                    >

                    <purchaserPackingList
                        :buybackSessionSetupPurchasers="
                            sessionSetup.buybackSessionPurchasers
                        "
                    />

                    <b-col class="text-right">
                        <p-button
                            :is-busy="isBusy"
                            variant="primary"
                            type="submit"
                            :disabled="statusClosed"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
                <p-card class="mt-2">
                    <b-row>
                        <b-col cols="6">
                            <p-input
                                label="Description"
                                rules="required|max:50"
                                v-model="sessionSetup.description"
                                :disabled="statusClosed"
                            />
                        </b-col>
                        <b-col cols="3">
                            <p-input
                                label="Status"
                                disabled="disabled"
                                v-model="sessionSetup.status"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Session Type"
                                disabled="disabled"
                                v-model="sessionSetup.sessionType"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                        <b-col>
                            <p-input
                                label="Cash Provider"
                                disabled="disabled"
                                v-model="sessionSetup.cashProvider"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Price Base"
                                :options="priceBase"
                                text-field="value"
                                v-model="sessionSetup.priceBase"
                                :disabled="statusClosed"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                data-type="locations"
                                label="Posting Location"
                                v-model="sessionSetup.postingLocationId"
                                rules="required"
                                :disabled="statusClosed"
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                v-model="sessionSetup.buybackPercentage"
                                input-type="percent"
                                rules="p-decimal:5,2|min_value:0|max_value:100|required"
                                label="Buyback %"
                                :disabled="statusClosed"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Buyback Rounding Amount"
                                :options="buyBackRoundingAmount"
                                v-model="sessionSetup.buybackRoundingAmount"
                                :disabled="statusClosed"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Buyback Rounding Method"
                                :options="buyBackRoundingMethod"
                                text-field="value"
                                v-model="sessionSetup.buybackRoundingMethod"
                                rules="required"
                                :disabled="statusClosed"
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                label="Total Cash Remaining"
                                disabled="disabled"
                                input-type="money"
                                :value="gettotalCashRemaining"
                                :add-money-rules="false"
                            />
                        </b-col>
                    </b-row>
                </p-card>
                <p-card class="mt-2">
                    <b-row>
                        <div class="ml-2 ">
                            <h4>Purchasers</h4>
                        </div>
                        <b-col>
                            <b-row>
                                <b-col class="text-left">
                                    <editsessionpurchaser
                                        class="pb-2"
                                        :buybackSessionSetupPurchasers="
                                            buybackSessionSetupPurchasers
                                        "
                                        @load-data="updateSessionPurchasers"
                                        :disabled="statusClosed"
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-table
                                :items="buybackSessionSetupPurchasers"
                                :fields="fields"
                                :enableRowSelection="false"
                                ref="sessionPurchasers"
                            >
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>

                <p-card class="mt-2">
                    <b-row>
                        <div class="ml-2">
                            <h4>Location</h4>
                        </div>
                        <b-col>
                            <b-row>
                                <b-col class="text-left">
                                    <editSessionLocation
                                        class="pb-2"
                                        buyback-sessionlocation-id="new"
                                        :buybackSessionSetupLocations="
                                            sessionSetup.buybackSessionLocations
                                        "
                                        @load-data="updateSessionLocations"
                                        :disabled="statusClosed"
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-table
                                :items="sessionSetup.buybackSessionLocations"
                                :fields="buybackSessionLocationParameters"
                                :enableRowSelection="false"
                                ref="sessionLocations"
                                :selectable="false"
                                sort-by="name"
                            >
                                <template v-slot:cell(edit)="{ value, item }">
                                    <editSessionLocation
                                        :buyback-sessionlocation-id="
                                            item.buybackLocationId
                                        "
                                        :buybackSessionSetupLocations="
                                            sessionSetup.buybackSessionLocations
                                        "
                                        @load-data="updateSessionLocations"
                                        :disabled="statusClosed"
                                    />
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <p-card class="mt-2">
                    <b-row>
                        <div class="ml-2">
                            <h4>Buyer</h4>
                        </div>
                        <b-col>
                            <b-row>
                                <b-col class="text-left">
                                    <editsessionbuyer
                                        class="pb-2"
                                        buyback-session-buyer-id="new"
                                        :buyback-session-setup="sessionSetup"
                                        @load-data="updateSessionBuyers"
                                        :disabled="statusClosed"
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-table
                                :items="result"
                                :fields="fieldsbuyers"
                                :enableRowSelection="false"
                                ref="sessionPurchasers"
                            >
                                <template v-slot:cell(edit)="{ value, item }">
                                    <editsessionbuyer
                                        class="pb-2"
                                        :buyback-session-buyer-id="
                                            item.buybackBuyerId
                                        "
                                        :buyback-session-setup="sessionSetup"
                                        @load-data="updateSessionBuyers"
                                        :disabled="statusClosed"
                                    />
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <b-row class="mt-4">
                    <b-col class="text-center mb-3">
                        <b-button
                            variant="primary"
                            type="submit"
                            class="text-right"
                            :disabled="statusClosed"
                            >Save</b-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </p-secure>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import editsessionpurchaser from '@/views/Buyback/EditSessionPurchaser.vue';
import editSessionLocation from '@/views/Buyback/EditSessionLocation.vue';
import editsessionbuyer from '@/views/Buyback/EditSessionBuyer';
import Vue from 'vue';
import purchaserPackingList from '@/views/Buyback/PackingList';

import {
    booleanFormatter,
    currencyFormatter,
    stringHasValueFormatter
} from '@/components/Common/Formatters.js';

export default {
    components: {
        editsessionpurchaser,
        editSessionLocation,
        editsessionbuyer,
        purchaserPackingList
    },

    mixins: [NavigationGuard],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        id: String
    },
    data() {
        return {
            totalCashRemaining: 0,
            result: [],
            buybackSessionSetupPurchasers: [],
            buyersGuideVendorPurchaser: {},
            isBusy: false,
            priceBase: [{ value: 'Off Retail' }, { value: 'Above Cost' }],
            buyBackRoundingAmount: [
                { value: 0, text: 'None' },
                { value: 0.05, text: '.05' },
                { value: 0.25, text: '.25' },
                { value: 0.5, text: '.50' },
                { value: 1, text: 'Whole Dollar' }
            ],
            buyBackRoundingMethod: [
                { value: 'Up' },
                { value: 'Down' },
                { value: 'Nearest' }
            ],
            sessionSetup: null,
            fields: [
                {
                    key: 'name',
                    label: 'Purchaser'
                }
            ],
            fieldsbuyers: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'buyerName',
                    label: 'Buyer Name'
                },
                {
                    key: 'locationName',
                    label: 'Location(s)'
                },
                {
                    key: 'cash',
                    label: 'Cash Remaining',
                    formatter: currencyFormatter
                }
            ],
            buybackSessionLocationParameters: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'buybackLocationId',
                    label: 'Location',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: value => {
                        return this.$store.getters.getLocationById(value)?.text;
                    }
                },
                {
                    key: 'studentIdRequired',
                    label: 'Student ID',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: booleanFormatter
                },
                {
                    key: 'conditionFieldPrompt',
                    label: 'Condition Prompt',
                    hidden: true,
                    sortable: true,
                    sortByFormatted: true,
                    formatter: booleanFormatter
                },
                {
                    key: 'queuePriceTags',
                    label: 'Queue Price Tags',
                    hidden: true,
                    sortable: true,
                    sortByFormatted: true,
                    formatter: booleanFormatter
                },
                {
                    key: 'printReceipts',
                    label: 'Print Receipts',
                    hidden: true,
                    sortable: true,
                    sortByFormatted: true,
                    formatter: booleanFormatter
                },
                {
                    key: 'receiptHeader',
                    label: 'Receipt Header',
                    hidden: true,
                    sortable: true,
                    formatter: stringHasValueFormatter
                },
                {
                    key: 'receiptFooter',
                    label: 'Receipt Footer',
                    hidden: true,
                    sortable: true,
                    formatter: stringHasValueFormatter
                }
            ]
        };
    },
    mounted: async function() {
        await this.loadData();
    },
    computed: {
        gettotalCashRemaining() {
            if (this.result.length == 0) return 0;
            else if (this.result.length == 1) return this.result[0].cash;
            else return this.result.reduce((a, b) => a + b.cash, 0);
        },
        statusClosed() {
            return this.sessionSetup.status === 'Closed';
        }
    },
    methods: {
        async loadData() {
            this.sessionSetup = null;
            this.result = [];
            let promise = axios.get('buyback/session', {
                params: { id: this.id }
            });

            promise.then(resp => {
                this.sessionSetup = resp.data;

                let sessionBuyer = this.buyersgroupby(
                    this.sessionSetup.buybackSessionBuyers
                );
                this.result.push(...sessionBuyer);

                this.buyersGuideVendorPurchaser = this.sessionSetup.buybackSessionPurchasers.filter(
                    x => x.buyersGuideVendorId != null
                )[0];

                this.buybackSessionSetupPurchasers = [
                    ...this.sessionSetup.buybackSessionPurchasers.filter(
                        x => x.buyersGuideVendorId == null
                    )
                ];

                if (this.sessionSetup.priceBase == null) {
                    this.sessionSetup.priceBase = 'Off Retail';
                }
                if (this.sessionSetup.buybackRoundingMethod == null) {
                    this.sessionSetup.buybackRoundingMethod = 'Nearest';
                }

                this.dataLoaded();
            });
        },
        buyersgroupby: function(array) {
            let item = Object.values(
                array.reduce((r, o) => {
                    r[o.buybackBuyerId] = r[o.buybackBuyerId] || {
                        buybackBuyerId: o.buybackBuyerId,
                        locationName: '',
                        cash: 0
                    };
                    r[o.buybackBuyerId].locationName +=
                        r[o.buybackBuyerId].locationName == ''
                            ? o.locationName
                            : ', ' + o.locationName;
                    r[o.buybackBuyerId].cash += o.cash;
                    r[o.buybackBuyerId].buyerName = o.buyerName;
                    return r;
                }, {})
            );
            return item;
        },
        updateSessionPurchasers(items) {
            this.sessionSetup.buybackSessionPurchasers = [...items];

            if (this.buyersGuideVendorPurchaser != null) {
                this.sessionSetup.buybackSessionPurchasers.push(
                    this.buyersGuideVendorPurchaser
                );
            }
            
            this.buybackSessionSetupPurchasers = [...items];
        },
        updateSessionLocations(item) {
            if (
                !this.sessionSetup.buybackSessionLocations.some(
                    x => x.buybackLocationId == item.buybackLocationId
                )
            ) {
                this.sessionSetup.buybackSessionLocations.push({ ...item });
            } else {
                let index = this.sessionSetup.buybackSessionLocations.findIndex(
                    x => x.buybackLocationId == item.buybackLocationId
                );

                Vue.set(this.sessionSetup.buybackSessionLocations, index, item);
            }
        },
        updateSessionBuyers(item) {
            let sessionBuyer = this.buyersgroupby(item);
            if (
                !this.result.some(x =>
                    sessionBuyer.some(y => y.buybackBuyerId == x.buybackBuyerId)
                )
            ) {
                this.sessionSetup.buybackSessionBuyers.push(...item);
                this.result.push(...sessionBuyer);
            } else {
                this.sessionSetup.buybackSessionBuyers.map(x =>
                    item
                        .filter(
                            y =>
                                y.locationId == x.locationId &&
                                y.buybackBuyerId == x.buybackBuyerId
                        )
                        .map(y => {
                            (x.cash = y.cash), (x.comment = y.comment);
                        })
                );
                let index = this.result.findIndex(x =>
                    sessionBuyer.some(y => y.buybackBuyerId == x.buybackBuyerId)
                );
                Vue.set(this.result, index, sessionBuyer[0]);
            }
        },
        onSubmit() {
            axios.post('buyback/session', this.sessionSetup).then(() => {
                this.$toasted.global
                    .app_success(
                        `Session '${this.sessionSetup.description}' saved successfully.`
                    )
                    .goAway(5000);
                this.loadData();
            });
        },
        async closeSession() {
            if (!(await this.$refs.form.validate())) {
                this.$toasted.global
                    .app_error(
                        'Please resolve all validation errors before closing session.'
                    )
                    .goAway(5000);
                return;
            }
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to close the session?')
                .then(value => {
                    if (value) {
                        this.sessionSetup.status = 'Closed';
                        this.onSubmit();
                    }
                });
        }
    },
    watch: {
        sessionSetup: NavigationGuard.$watcher,

        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    }
};
</script>
