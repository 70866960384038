<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <p-form @submit="onSubmit">
            <div
                class="container-fluid content-wrapper"
                v-if="buybackListSetup"
            >
                <b-row class="content-heading">
                    <b-col>
                        <div>Buyback Purchaser List</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-left">
                        <p-button
                            :is-busy="isBusy"
                            variant="outline-primary"
                            class="mr-2"
                            type="button"
                            @click="handleGenerateList"
                            :disabled="statusClosed"
                            >Generate List</p-button
                        >
                    </b-col>

                    <b-col class="text-right">
                        <p-button
                            :is-busy="isBusy"
                            variant="primary"
                            type="submit"
                            :disabled="statusClosed"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
                <p-card class="mt-2">
                    <b-row cols="1" cols-sm="2" cols-xl="3">
                        <b-col>
                            <p-input
                                label="Session"
                                v-model="buybackListSetup.sessionDescription"
                                rules="required|max:50"
                                disabled="disabled"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Purchaser"
                                v-model="buybackListSetup.purchaserName"
                                rules="required|max:50"
                                disabled="disabled"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Status"
                                v-model="buybackListSetup.status"
                                :options="buybackListStatus"
                                text-field="value"
                                disabled="disabled"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" sm="6">
                            <p-input
                                label="Description"
                                v-model="buybackListSetup.description"
                                rules="required|max:100"
                                :disabled="statusClosed"
                            />
                        </b-col>
                        <b-col cols="12" sm="6" xl="3">
                            <p-datepicker
                                v-model="buybackListSetup.createDate"
                                @date-picker-initialized="e => {dataLoaded()}"
                                label="Create Date"
                                disabled="disabled"
                            />
                        </b-col>
                        <b-col cols="12" sm="6" xl="3" class="mt-3 pt-3">
                            <p-checkbox
                                label="Include $0 Priced Books"
                                v-model="buybackListSetup.includeZeroPriced"
                                :disabled="statusClosed"
                            >
                            </p-checkbox>
                        </b-col>
                    </b-row>
                </p-card>
                <p-card>
                    <b-row>
                        <div class="ml-2 ">
                            <h4>Locations</h4>
                        </div>
                        <b-col>
                            <b-row>
                                <b-col class="text-left">
                                    <edittermlocation
                                        class="pb-2"
                                        :buybackSessionId="getSelectedSessionId"
                                        buybackLocationId="new"
                                        :buybackListLocationTerms="buybackListSetup.buybackListLocationTerms"
                                        termId="new"
                                        index="-1"
                                        @load-data="updateTermLocation"
                                        :disabled="statusClosed"
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-table
                                :items="
                                    buybackListSetup.buybackListLocationTerms
                                "
                                :fields="fieldTermLocations"
                                :enableRowSelection="false"
                                ref="termLocations"
                                :selectable="false"
                                sort-by="name"
                            >
                                <template
                                    v-slot:cell(edit)="{ value, item, index }"
                                >
                                    <edittermlocation
                                        :buybackSessionId="getSelectedSessionId"
                                        :buybackLocationId="item.locationId"
                                        :termId="item.termId"
                                        :buybackListLocationTerms="
                                            buybackListSetup.buybackListLocationTerms
                                        "
                                        :index="index"
                                        @load-data="updateTermLocation"
                                        :disabled="statusClosed"
                                    />
                                </template>
                                <template
                                    v-slot:cell(subtractOrderShort)="{
                                        value,
                                        item
                                    }"
                                >
                                    <p-checkbox
                                        disabled
                                        v-model="item.subtractOrderShort"
                                    />
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <p-card>
                    <b-row>
                        <div class="ml-2 ">
                            <h4>Books to Buy</h4>
                        </div>
                        <b-col>
                            <b-row>
                                <b-col
                                    class="text-left"
                                    cols="12"
                                    sm="6"
                                    xl="3"
                                >
                                    <p-advanced-select
                                        dataType="terms"
                                        label="Location/Term"
                                        :disabled="disableLocationTerm"
                                        :filter="locationTermFilter"
                                        @input="loadTermBookToBuy($event)"
                                        v-model="selectedTermId"
                                        add-empty-option
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-table
                                :items="buybackListDetailsOptions"
                                :fields="fieldBuybackListDetails"
                                :enableRowSelection="false"
                                ref="buybacklistdetail"
                                :selectable="false"
                                sort-by="locationname"
                            >
                                <template
                                    v-slot:cell(buyQty)="{
                                        value,
                                        item
                                    }"
                                >
                                    <p-number
                                        v-model="item.buyQty"
                                        name="Buy Qty"
                                        input-type="integer"
                                        rules="min_value:0"
                                        :disabled="statusClosed"
                                        compact-format
                                    />
                                </template>

                                <template
                                    v-slot:cell(buyPrice)="{
                                        value,
                                        item
                                    }"
                                >
                                    <p-number
                                        v-model="item.buyPrice"
                                        name="Buy Price"
                                        input-type="money"
                                        rules="min_value:0"
                                        :disabled="(statusClosed || buybackListSetup.cashProvider != 'Store')"
                                        compact-format
                                    />
                                </template>
                                <template
                                    v-slot:cell(comment)="{ value, item }"
                                >
                                    <p-text-area
                                        compact-format
                                        v-model="item.comment"
                                        rules="max:2000"
                                        name="Comment"
                                        size="sm"
                                        :disabled="statusClosed"
                                    />
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <b-row class="mt-4">
                    <b-col class="text-center mb-3">
                        <b-button
                            variant="primary"
                            type="submit"
                            class="text-right"
                            :disabled="statusClosed"
                            >Save</b-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </p-secure>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import edittermlocation from '@/views/Buyback/EditBuybackListLocationTerm.vue';
import { percentFormatter } from '@/components/Common/Formatters.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import Vue from 'vue';

import { currencyFormatter } from '@/components/Common/Formatters.js';
export default {
    components: { edittermlocation },
    mixins: [NavigationGuard, StoreListManager],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        sessionid: [String, Number],
        purchaserid: [String, Number],
        buybacklistid: {
            type: [String, Number],
            default: 0
        }
    },
    data() {
        return {
            selectedTermId: 0,
            result: [],
            isBusy: false,
            selectListOptions: {
                locations: [],
                terms: []
            },
            buybackSessionId: 0,
            buybackPurchaseId: 0,
            buybackListDetailsOptions: [],
            buybackTerms: [],
            buybackListSetup: null,
            buybackListStatus: [{ value: 'Open' }, { value: 'Closed' }],
            fieldTermLocations: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'locationId',
                    label: 'Location',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: value => {
                        return this.$store.getters.getLocationById(value)?.text;
                    }
                },
                {
                    key: 'termId',
                    label: 'Term',
                    sortable: true,
                    formatter: value => {
                        return this.$store.getters.getTermById(value)?.text;
                    }
                },
                {
                    key: 'amountToUse',
                    label: 'Amount to Use',
                    sortable: true
                },
                {
                    key: 'qtppercent',
                    label: '% of Qtp',
                    sortable: true,
                     formatter: value => {
                        return this.percentFormatter(value)
                    }
                },
                {
                    key: 'subtractOrderShort',
                    label: 'Subtract Order Short',
                    sortable: true
                },
                {
                    key: 'buybackToSubtract',
                    label: 'Buyback to Subtract',
                    sortable: true
                }
            ],
            fieldBuybackListDetails: [
                {
                    key: 'locationId',
                    label: 'Location',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: value => {
                        return this.$store.getters.getLocationById(value)?.text;
                    }
                },
                {
                    key: 'sku',
                    label: 'SKU',
                    sortable: true,
                    sortByFormatted: true
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    sortable: true,
                    sortByFormatted: true
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                    sortByFormatted: true
                },
                {
                    key: 'termId',
                    label: 'Term Name',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: value => {
                        return this.$store.getters.getTermById(value)?.text;
                    }
                },
                {
                    key: 'boughtQty',
                    label: 'Bought Qty',
                    sortable: true,
                    sortByFormatted: true,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'buyQty',
                    label: 'Buy Qty',
                    sortByFormatted: true,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'buyPrice',
                    label: 'Buy Price',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'guidePrice',
                    label: 'Guide Price',
                    sortable: true ,
                    sortByFormatted: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'usedCost',
                    label: 'Used Cost',
                    sortable: true ,
                    sortByFormatted: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'retail',
                    label: 'Retail',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'comment',
                    label: 'Comment',
                    sortByFormatted: true
                }
            ]
        };
    },
    created: function() {
        this.buybackSessionId = this.sessionid;
        this.buybackPurchaseId = this.purchaserid;
    },
    computed: {
        getSelectedSessionId() {
            return this.buybackSessionId;
        },
        disableLocationTerm() {
            return this.buybackListSetup.buybackListLocationTerms.length === 0;
        },
        isNew() {
            return this.buybacklistid === 0;
        },
        statusClosed() {
            return this.buybackListSetup.status === 'Closed';
        }
    },
    mounted: async function() {
        await this.loadData();
    },

    methods: {
        percentFormatter: percentFormatter,
        async loadData() {
            let dataPromise = this.isNew
                ? axios.get('buyback/newbuybacklist', {
                      params: {
                          BuybackSessionId: this.buybackSessionId,
                          PurchaserId: this.buybackPurchaseId
                      }
                  })
                : axios.get('buyback/buybacklists', {
                      params: { id: this.buybacklistid }
                  });

        dataPromise.then(resp => {
            this.buybackListSetup = resp.data;
            this.buybackSessionId = resp.data.buybackSessionId;
            this.buybackPurchaserid = resp.data.buybackPurchaseId;

            if (!this.isNew) {
                this.dataLoaded();
            }
        });
    },
    onSubmit() {
        this.isBusy = true;
        axios
            .post('buyback/buybacklist', this.buybackListSetup)
            .then(resp => {
                this.$toasted.global
                    .app_success(
                        `Session '${this.buybackListSetup.description}' saved successfully.`
                    )
                    .goAway(5000);

                if (this.isNew) {
                    this.dataSaved();
                    this.$router.push(
                        '/buybacklists/' + resp.data.buybackListId
                    );
                } else {
                    this.buybackListSetup = resp.data;
                    this.dataLoaded();
                }
            })
            .finally(() => {
                this.isBusy = false;
            });
        },
        locationTermFilter(termLocation) {
            return this.buybackListSetup.buybackListLocationTerms.some(
                x => x.termId === termLocation.value
            );
        },
        updateTermLocation(item) {
            if (item.index != -1) {
                Vue.set(
                    this.buybackListSetup.buybackListLocationTerms,
                    item.index,
                    item
                );
            } else {
                this.buybackListSetup.buybackListLocationTerms.push({
                    ...item
                });
            }
        },
        loadTermBookToBuy(term) {
            if (term != null) {
                this.buybackListDetailsOptions = this.buybackListSetup.buybackListDetails.filter(
                    x => x.termId == term
                );
            } else {
                this.buybackListDetailsOptions = this.buybackListSetup.buybackListDetails;
            }
        },
        handleGenerateList: async function() {
            if (this.disableLocationTerm) {
                this.$toasted.global
                    .app_error(
                        'Please add at least one location/term to generate a list.'
                    )
                    .goAway(5000);
                return;
            }

            if (!this.isNew && (this.buybackListSetup.buybackListDetails.length > 0 || this.buybackListSetup.buybackListDetails != null)) {
                 this.$bvModal
                .msgBoxConfirm('Any changes made to list pricing or quantities will be wiped clean.')
                .then(value => {
                    if (value) {
                        this.generateList();
                    }
                });
            }
            if(this.isNew){
                         this.generateList();
            }
        },
        generateList()
        {
        this.isBusy = true;

            let promise = axios.post('buyback/generatelist', {
                buybackSessionId: this.buybackListSetup.buybackSessionId,
                includeZeroPriced: this.buybackListSetup.includeZeroPriced,
                buybackListId: +this.buybacklistid,
                buybackListLocationTerms: this.buybackListSetup
                    .buybackListLocationTerms
            });
            promise
                .then(resp => {
                    if (resp.data.length == 0) {
                        this.$toasted.global
                            .app_error(
                                `No Order Decisions found to generate buyback list.`
                            )
                            .goAway(5000);
                    } else {
                        this.buybackListSetup.buybackListDetails = resp.data;
                        this.buybackListDetailsOptions = this.buybackListSetup.buybackListDetails;
                        this.selectedTermId = null;
                        this.dataLoaded();
                    }
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },
    watch: {
        buybackListSetup: NavigationGuard.$watcher,

        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    }
};
</script>
