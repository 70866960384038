<template>
    <p-modal
        size="lg"
        label="Manage Money"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        name="cashDrawerModal"
        ref="cashDrawerModal"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div>
                    <b-row>
                        <b-col cols="12" sm="6" xl="3">
                            <p-number
                                label="Current Value"
                                disabled="disabled"
                                input-type="money"
                                v-model="buybackSessionBuyerLog.previousCash"
                            />
                        </b-col>
                        <b-col cols="12" sm="6" xl="3">
                            <p-number
                                input-type="money"
                                rules="required|min_value:0"
                                label="New Value"
                                v-model="buybackSessionBuyerLog.cashIn"
                            />
                        </b-col>
                        <b-col cols="12" xl="6">
                            <p-input
                                label="Reason"
                                rules="required|max:2000"
                                v-model="buybackSessionBuyerLog.reason"
                            />
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        class="mr-2"
                        variant="outline-primary"
                        @click="resetModal"
                        >Cancel</p-button
                    >
                    <p-button
                        :is-busy="isBusy"
                        class="ml-2"
                        variant="primary"
                        @click="ok"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';

export default {
    mixins: [],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        buybackSessionBuyer: {}
    },
    data() {
        return {
            isBusy: false,
            buybackSessionBuyerLog: {
                buybackSessionBuyerId: null,
                previousCash: null
            }
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return '';
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            axios
                .post(
                    'buyback/buybacksessionbuyerlog',
                    this.buybackSessionBuyerLog
                )
                .then(() => {
                    this.$emit('data-saved');
                    this.$toasted.global
                        .app_success(
                            `BuybackSessionBuyerLog saved successfully.`
                        )
                        .goAway(5000);

                    this.$emit('load-data', this.buybackSessionBuyerLog.cashIn);

                    this.resetModal();
                });
        },
        loadData() {
            this.buybackSessionBuyerLog.buybackSessionBuyerId = this.buybackSessionBuyer.buybackSessionBuyerId;
            this.buybackSessionBuyerLog.previousCash = this.buybackSessionBuyer.cash;
        },
        resetModal() {
            this.buybackSessionBuyerLog.cashIn = null;
            this.buybackSessionBuyerLog.reason = null;
            this.$refs.cashDrawerModal.hide();
        }
    }
};
</script>
