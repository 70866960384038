<template>
    <p-form ref="form">
        <div class="container-fluid content-wrapper" v-if="orderDecision">
            <b-row class="content-heading">
                <b-col>
                    <div>
                        {{ isSingleRecord ? 'Edit' : 'Work' }} Order Decision
                    </div>
                </b-col>
                <b-col>
                    {{ isSingleRecord ? '' : `(${index + 1}/${ids.length})` }}
                </b-col>
                <b-col class="text-right">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="saveSingleRecord"
                        :disabled = "closedTerm"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <b-row class="pb-2" cols="2" cols-lg="12">
                <b-col>
                    <p-button
                        :is-busy="isBusy"
                        v-if="!isSingleRecord"
                        @click="previous"
                        :disabled="first"
                        variant="outline-primary"
                        >&#8592; Previous</p-button
                    >
                </b-col>
                <b-col class="text-right">
                    <p-button
                        :is-busy="isBusy"
                        v-if="!isSingleRecord"
                        @click="next"
                        variant="outline-primary"
                        class="mb-2"
                        >{{
                            showDoneButton ? 'Done' : `Next &#8594;`
                        }}</p-button
                    >
                </b-col>
            </b-row>
            <p-card>
                <b-row>
                    <b-col cols="12">
                        <p-input
                            label="Author / Title or Desc"
                            v-model="orderDecision.authorTitleDescription"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-input
                            label="Cat #/ISBN"
                            v-model="orderDecision.catIsbn"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-input
                            label="Edition"
                            v-model="orderDecision.edition"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-input
                            label="Copyright"
                            v-model="orderDecision.copyright"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-input
                            label="Status"
                            v-model="orderDecision.status"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-datepicker
                            label="Status Date"
                            v-model="orderDecision.statusDate"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-text-area
                            label="Comment"
                            v-model="orderDecision.comment"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-input
                            label="Sku"
                            v-model="orderDecision.sku"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-input
                            label="Used Sku"
                            v-model="orderDecision.usedSKU"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-checkbox
                            label="Want List Title"
                            v-model="orderDecision.wantListTitle"
                            disabled
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-checkbox
                            label="Buyback Title"
                            v-model="orderDecision.buybackTitle"
                            disabled
                        />
                    </b-col>
                </b-row>
            </p-card>

            <p-card>
                <b-row v-if="this.orderDecision.pastTerms.length > 0">
                    <b-col cols="auto mb-2" class="ml-auto">
                        <p-select
                            v-model="comparisonTermCount"
                            label="# of Previous Terms to Display"
                            :key="orderDecision.currentTerm.orderDecisionId"
                            :options="comparisonTermCountOptions"
                        />
                    </b-col>
                </b-row>
                <b-table-simple name="termComparisonTable" small>
                    <b-thead>
                        <b-tr>
                            <b-th sticky-column>Term</b-th>
                            <!-- for each term -->
                            <b-th
                                v-for="term in allDisplayedTerms"
                                :key="term.termId"
                                >{{ term.termName }}</b-th
                            >
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <edit-row
                            label="Add to PO"
                            :terms="allDisplayedTerms" 
                            property-name="purchaseOrder"
                        >
                        <div v-bind:class= "closedTerm?'d-none':'d-block'">
                            <p-number style="width:70px;display:inline-block"                                    
                                    input-type="integer"
                                    rules="min_value:0"
                                    name="Quantity"
                                    v-model="
                                        orderDecision.currentTerm.qty
                                    "
                                    @blur="checkOpenToBuy"
                                ></p-number>
                                <b-dropdown style="width:75px;display:inline-block" class="pl-2" text="Add To PO" variant="outline-primary">
                                    <b-dropdown-item @click="generatePurchaseOrder()">
                                        Create New
                                    </b-dropdown-item>
                                    <b-dropdown-divider v-if="allPurchaseOrders.length > 0"></b-dropdown-divider>
                                    <b-dropdown-item v-for="purchaseOrder in allPurchaseOrders"
                                                    :key="purchaseOrder.purchaseOrderId"
                                                    @click="generatePurchaseOrder(purchaseOrder.purchaseOrderId)">
                                        {{ purchaseOrder.purchaseOrderNumber }}
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </edit-row>

                        <edit-row
                            label="Location"
                            :terms="allDisplayedTerms"
                            property-name="locationName"
                        />

                        <edit-row
                            label="Supplier Code"
                            :terms="allDisplayedTerms"
                        >
                            {{ orderDecision.vendorCode }}
                        </edit-row>

                        <edit-row
                            label="New Retail Price"
                            :terms="allDisplayedTerms"
                        >
                            <p-number
                                text-only
                                class=""
                                input-type="money"
                                v-model="orderDecision.newRetail"
                                compact-format
                            />
                        </edit-row>
                        <edit-row
                            label="Used Retail Price"
                            :terms="allDisplayedTerms"
                        >
                            <p-number
                                text-only
                                class=""
                                input-type="money"
                                v-model="orderDecision.usedRetail"
                                compact-format
                            />
                        </edit-row>

                        <edit-row :terms="allDisplayedTerms"><br /></edit-row>

                        <edit-row
                            label="Requested Qty"
                            :terms="allDisplayedTerms"
                            format-as="integer"
                            property-name="requestQty"
                        />
                        <edit-row
                            label="Open To Buy"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                        >
                            <p-number
                                text-only
                                class=""
                                input-type="integer"
                                :value="openToBuy"
                                compact-format
                            />
                        </edit-row>
                        <edit-row 
                            label="New SOH" 
                            :terms="allDisplayedTerms" 
                            :is-modal-edit=true
                            :is-Busy="isBusy"
                            @propertyModalEditClicked="adjustSOH('New')"
                            :disabled="closedTerm">
                            <p-number
                                text-only
                                input-type="integer"
                                v-model="orderDecision.newSOH"
                                compact-format
                            />
                        </edit-row>
                        <edit-row 
                            label="Used SOH" 
                            :terms="allDisplayedTerms" 
                            :is-modal-edit=true
                            :is-Busy="isBusy"
                            v-on:propertyModalEditClicked="adjustSOH('Used')"
                            :disabled="closedTerm">
                            <p-number
                                text-only
                                input-type="integer"
                                v-model="orderDecision.usedSOH"
                                compact-format
                            />
                        </edit-row>

                        <edit-row :terms="allDisplayedTerms"><br /></edit-row>

                        <edit-row
                            label="Status"
                            :terms="allDisplayedTerms"
                            property-name="workStatus"
                        >
                            <p-select
                                data-type="workStatus"
                                compact-format
                                text-field="value"
                                v-model="orderDecision.currentTerm.workStatus"
                                :disabled="closedTerm"
                            ></p-select>
                        </edit-row>
                        <edit-row
                            v-show="false"
                            label="Calculated Quantity to Provide"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="calculatedQtp"
                        >
                        </edit-row>
                        <edit-row
                            label="Quantity to Provide"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="quantityToProvide"
                        >
                            <p-number
                                compact-format
                                input-type="integer"
                                v-model="
                                    orderDecision.currentTerm.quantityToProvide
                                "
                                @change="setStatusWorked"
                                :disabled = "closedTerm"
                            ></p-number>
                        </edit-row>
                        <edit-row
                            label="Allocated"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="allocated"
                        >
                            <p-number
                                compact-format
                                input-type="integer"
                                v-model="orderDecision.currentTerm.allocated"
                                @change="setStatusWorked"
                                rules="min_value:0"
                                name="Allocated"
                                :disabled = "closedTerm"
                            ></p-number>
                        </edit-row>
                        <edit-row
                            label="Order Short"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="orderShort"
                        >
                            <p-number
                                compact-format
                                input-type="integer"
                                v-model="orderDecision.currentTerm.orderShort"
                                @change="setStatusWorked"
                                rules="min_value:0"
                                name="Order Short"
                                :disabled = "closedTerm"
                            ></p-number>
                        </edit-row>
                        <edit-row
                            label="Auto Order Tolerance"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="tolerance"
                        >
                            <p-number
                                compact-format
                                input-type="integer"
                                v-model="orderDecision.currentTerm.tolerance"
                                :disabled = "closedTerm"
                            ></p-number>
                        </edit-row>
                        <edit-row
                            label="Want List Title"
                            :terms="allDisplayedTerms"
                        >
                            <p-checkbox
                                compact-format
                                v-model="orderDecision.currentTerm.wantListTitle"
                                :disabled = "closedTerm"
                            ></p-checkbox>
                        </edit-row>
                        <edit-row
                            label="Buyback Title"
                            :terms="allDisplayedTerms"
                        >
                            <p-checkbox
                                compact-format
                                v-model="orderDecision.currentTerm.buybackTitle"
                                :disabled = "closedTerm"
                            ></p-checkbox>
                        </edit-row>
                        <edit-row
                            label="Max Buyback"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="maxBb"
                        >
                            <p-number
                                compact-format
                                input-type="integer"
                                :disabled="!orderDecision.buybackTitle || closedTerm"
                                v-model="orderDecision.currentTerm.maxBb"
                            ></p-number>
                        </edit-row>
                        <edit-row
                            label="Estimated Buyback"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="estimatedBb"
                        >
                            <p-number
                                compact-format
                                input-type="integer"
                                :disabled="!orderDecision.buybackTitle || closedTerm"
                                v-model="orderDecision.currentTerm.estimatedBb"
                                @change="setStatusWorked"
                                rules="min_value:0"
                                name="Estimated Buyback"
                            ></p-number>
                        </edit-row>
                        <edit-row
                            label="Actual Buyback"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="actualBb"
                        />
                        <edit-row
                            label="On Order"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="onOrder"
                        />
                        <edit-row
                            label="Received"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="received"
                        />
                        <edit-row
                            label="Returned"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="returned"
                        />
                        <edit-row
                            label="Ending Inventory"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="endInventory"
                        />
                        <edit-row
                            label="Sales New"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="newSales"
                        />
                        <edit-row
                            label="Sales Used"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="usedSales"
                        />
                        <edit-row
                            label="Est. Enrollment"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="estimatedEnrollment"
                        />
                        <edit-row
                            label="Act. Enrollment"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="actualEnrollment"
                        />
                        <edit-row
                            label="Sale New Rental"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="newRentalSales"
                        />
                        <edit-row
                            label="Sale Used Rental"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="usedRentalSales"
                        />
                        <edit-row
                            label="Estimated Rental Return"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="estimatedRentalReturn"
                        >
                            <p-number
                                compact-format
                                input-type="integer"
                                v-model="orderDecision.currentTerm.estimatedRentalReturns"
                                @change="setStatusWorked"
                                rules="min_value:0"
                                name="Estimated Rental Return"
                                :disabled="closedTerm"
                            ></p-number>
                        </edit-row>
                        <edit-row
                            label="Actual Rental Return"
                            format-as="integer"
                            :terms="allDisplayedTerms"
                            property-name="actualRentalReturns"
                        />
                    </b-tbody>
                </b-table-simple>
            </p-card>

            <b-row class="pb-2" cols="3" cols-lg="12">
                <b-col>
                    <p-button
                        :is-busy="isBusy"
                        v-if="!isSingleRecord"
                        @click="previous"
                        :disabled="first"
                        variant="outline-primary"
                        >&#8592; Previous</p-button
                    >
                </b-col>
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="saveSingleRecord"
                        :disabled = "closedTerm"
                        >Save</p-button
                    >
                </b-col>
                <b-col class="text-right">
                    <p-button
                        :is-busy="isBusy"
                        v-if="!isSingleRecord"
                        @click="next"
                        variant="outline-primary"
                        class="mb-2"
                        >{{
                            showDoneButton ? 'Done' : `Next &#8594;`
                        }}</p-button
                    >
                </b-col>
            </b-row>
            
            <StockAdjustment
                :adjustment-data="stockAdjustment"
                @ok="stockAdjustmentSucceeded"
            />
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import EditRow from '@/views/OrderDecisions/OrderDecisionEditRow.vue';
import StockAdjustment from '@/components/StockAdjustment.vue';

export default {
    mixins: [NavigationGuard],
    props: {
        id: String,
        ids: {
            type: [Array],
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            newPurchaseOrders: [],
            purchaseOrder: null,
            orderDecision: null,
            index: 0,
            comparisonTermCount: 5,
            isBusy: false,
            stockAdjustment: { },
            closedTerm:false,
        };
    },
    watch: {
        'orderDecision.currentTerm.workStatus': NavigationGuard.$watcher,
        'orderDecision.currentTerm.quantityToProvide': NavigationGuard.$watcher,
        'orderDecision.currentTerm.allocated': NavigationGuard.$watcher,
        'orderDecision.currentTerm.orderShort': NavigationGuard.$watcher,
        'orderDecision.currentTerm.tolerance': NavigationGuard.$watcher,
        'orderDecision.wantListTitle': NavigationGuard.$watcher,
        'orderDecision.buybackTitle': NavigationGuard.$watcher,
        'orderDecision.currentTerm.maxBb': NavigationGuard.$watcher,
        'orderDecision.currentTerm.estimatedBb': NavigationGuard.$watcher
    },
    computed: {
        openToBuy() {
            // Quantity To Provide
            // -Allocated Inventory
            // - Order Short (if the  term is set to subtract Order Short)
            // - Estimated buyback/actual buyback/ or no buyback (set on the term)
            // -On Order Quantities
            // -Received Quantities
            // -Received Quantities
            // = Open to Buy Quantity

            return (
                this.orderDecision.currentTerm.quantityToProvide -
                this.orderDecision.currentTerm.allocated -
                this.orderShortAdjustemnt -
                this.buybackAdjustemnt -
                this.orderDecision.currentTerm.onOrder -
                this.orderDecision.currentTerm.received
            );
        },
        allPurchaseOrders() {
                return [...this.orderDecision.currentTerm.purchaseOrders, ...this.newPurchaseOrders]
            }, 
        orderShortAdjustemnt() {
            return this.orderDecision.currentTerm.subtractOrderShort
                ? this.orderDecision.currentTerm.orderShort
                : 0;
        },
        buybackAdjustemnt() {
            if (
                this.orderDecision.currentTerm.buybackToSubtract === 'Estimated'
            ) {
                return this.orderDecision.currentTerm.estimatedBb;
            }
            if (this.orderDecision.currentTerm.buybackToSubtract === 'Actual') {
                return this.orderDecision.currentTerm.actualBb;
            }

            return 0;
        },
        comparisonTermCountOptions() {
            return [
                ...Array(this.orderDecision.pastTerms.length + 1).keys()
            ].map(x => ({ text: x, value: x }));
        },
        isNew() {
            return this.id === 'new';
        },
        done() {
            return !this.isSingleRecord && this.index === this.ids.length;
        },
        showDoneButton() {
            return !this.isSingleRecord && this.index === this.ids.length - 1;
        },
        first() {
            return this.index === 0;
        },
        isSingleRecord() {
            return this.id || this.ids.length == 1;
        },
        allDisplayedTerms() {
            return [
                this.orderDecision.currentTerm,
                ...this.orderDecision.pastTerms.slice(
                    0,
                    this.comparisonTermCount
                )
            ];
        }
    },
    methods: {
        next: async function() {
            this.isBusy = true;
            try {
                if (this.done) {
                    this.$router.push('/orderdecisions');
                    return;
                }
                if (!(await this.$refs.form.validate())) {
                    this.isBusy = false;
                    return;
                }
                try {
                    await this.save();
                } catch {
                    return;
                }
                this.index++;
                if (this.done) {
                    this.$router.push('/orderdecisions');
                    return;
                }
                await this.loadOrderDecision();
            } finally {
                this.isBusy = false;
            }
        },
        previous: async function() {
            if (this.first) {
                return;
            }

            this.isBusy = true;
            try {
                if (!(await this.$refs.form.validate())) {
                    return;
                }
                try {
                    await this.save();
                } catch {
                    return;
                }
                this.index--;
                await this.loadOrderDecision();
            } finally {
                this.isBusy = false;
            }
        },
       async checkOpenToBuy(){
            if(this.orderDecision.currentTerm.qty>this.openToBuy){
                 if (!(await this.$refs.form.validate())) {
                       return;
                }                 
                this.$bvModal
                .msgBoxConfirm('Open to Buy Qty exceeded. Do you wish to continue?')
                .then(value => {
                    if (!value) {
                        this.orderDecision.currentTerm.qty = 0;
                    }
                })
            }            
        },
        generatePurchaseOrder(purchaseOrderId) {
                  this.isBusy = true;
                    axios.post('orderDecisions/generatepurchaseorder', {
                        purchaseOrderId:purchaseOrderId,
                        orderDecisionId: this.orderDecision.currentTerm.orderDecisionId,
                        qty: this.orderDecision.currentTerm.qty,
                        supplierCode:this.orderDecision.vendorCode,
                        termName:this.orderDecision.currentTerm.termName
                    })
                    .then(resp => {                     
                        if(!purchaseOrderId) this.newPurchaseOrders.push(resp.data); 
                        this.$toasted.global.app_success(`QTY ${this.orderDecision.currentTerm.qty} added to ${resp.data.purchaseOrderNumber}`).goAway(5000);
                            this.setStatusWorked();
                    }).finally(() => {
                    this.isBusy = false;
                    });

        },
        loadOrderDecision: async function() {
            if (this.done) {
                return;
            }
            let id = this.ids.length > 0 ? this.ids[this.index] : this.id;
            let resp = await axios.get('orderdecisions', {
                params: { id: id }
            });
            this.orderDecision = resp.data;
            this.closedTerm = !this.orderDecision.currentTerm.isOpen;
            this.comparisonTermCount = Math.min(
                this.comparisonTermCount,
                this.orderDecision.pastTerms.length
            );
            if (
                this.orderDecision.currentTerm.quantityToProvide === null &&
                this.orderDecision.currentTerm.workStatus === 'Work'
            ) {
                this.orderDecision.currentTerm.quantityToProvide = this.orderDecision.currentTerm.calculatedQtp;
            }
           if (!this.isNew) {
                         this.dataLoaded();
            }
            if (!this.orderDecision.vendorCode) {
                this.$toasted.global
                .app_error(`Please add a Supplier to the Product.`)
                .goAway(5000);
            }
        },
        save: async function() {
            this.isBusy = true;
            await axios.post('orderdecisions', this.orderDecision.currentTerm)
                .finally(() => {
                    this.isBusy = false;
                });
            this.$toasted.global
                .app_success(`Order decision saved successfully.`)
                .goAway(5000);
            this.dataSaved();
        },
        saveSingleRecord: async function() {
            this.isBusy = true;
            try {
                if (!(await this.$refs.form.validate())) {
                    this.isBusy = false;
                    return;
                }
                try {
                    await this.save();
                } catch {
                    return;
                }
            } finally {
                this.isBusy = false;
            }
        },
        setStatusWorked: function() {
            this.orderDecision.currentTerm.workStatus = 'Worked';
        },
        adjustSOH: async function(stockVariant) {
            let retail = stockVariant === 'New' ? this.orderDecision.newRetail : this.orderDecision.usedRetail;
            if (retail === 0) {
                await this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want make a $0 retail stock adjustment?'
                    )
                    .then(value => {
                        if (value) {
                            this.openSOHModal(stockVariant);
                        }
                    });
            } else {
                this.openSOHModal(stockVariant);
            }
        },
        openSOHModal(stockVariant) {
            this.stockAdjustment.cost = this.orderDecision.cost;
            if (stockVariant === 'New') {
                //Use $set for sku so we can watch this object in the stockadjustment component
                this.$set(
                    this.stockAdjustment,
                    'sku',
                    this.orderDecision.sku == 0 ? null : this.orderDecision.sku
                );
                this.stockAdjustment.retail = this.orderDecision.newRetail;
            } else {
                this.$set(
                    this.stockAdjustment,
                    'sku',
                    this.orderDecision.usedSKU == 0 ? null : this.orderDecision.usedSKU
                );
                this.stockAdjustment.retail = this.orderDecision.usedRetail;
            }

            this.$bvModal.show('StockAdjustmentModal');
        },
        stockAdjustmentSucceeded(stockAdjustment) {
            if (stockAdjustment.sku === this.orderDecision.sku) {
                this.orderDecision.newSOH += stockAdjustment.qty;
            }
            else if (stockAdjustment.sku === this.orderDecision.usedSKU) {
                this.orderDecision.usedSOH += stockAdjustment.qty;
            }

            this.$toasted.global
                .app_success(`Stock adjusted successfully.`)
                .goAway(5000);
        }
    },
    mounted: async function() {
        if (this.id == null && (this.ids == null || this.ids.length == 0)) {
            this.$router.push('/orderdecisions');
        }
        this.isBusy = true;
        await this.loadOrderDecision();
        this.isBusy = false;
    },
    components: {
        EditRow,
        StockAdjustment
    }
};
</script>

<style scoped>
::v-deep td {
       padding-right: 15px;
    min-width: 250px;
    max-width: 500px;
    vertical-align: middle;
    text-align: center;
    padding-left: 15px;

}
::v-deep th {
    padding-right: 15px;
    padding-left: 15px;
    min-width: 200px;
    /*Big enough to hopefully hold evrything, but not too big so you can't compare columns*/
    max-width: 500px;
    vertical-align: middle;
     text-align: center;
     position: sticky;
     top:0px;
     left: 0px;
     z-index: 999;
     background: #fff;
}
::v-deep th:first-child{
     text-align: left;
}
::v-deep table {
    width: 100%;
    display: block;
    overflow-x: auto;
    padding-bottom:20px ;
}
::v-deep .form-group{
    margin-bottom: 0px;
}
::v-deep .dropdown-menu {
    max-height: 500px;
    overflow-y:auto ;
}
::v-deep .compact-container {
    margin: auto;
}
::v-deep input.align-right {
    text-align: center;
}
</style>
