<template>
    <p-modal
        ref="sessionBuyerModal"
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="lg"
        label="Session Buyer"
        @ok.prevent="handleModalOk"
        @show="loadData"
        :disable-button="disabled"
    >
        <p-form ref="form">
            <template v-slot:default>
                <div>
                    <b-row cols="1">
                        <b-col>
                            <p-select
                                label="Buyers"
                                :disabled="!isNew"
                                data-type="buybackBuyers"
                                :filter="buyerFilter"
                                v-model="selectedBuyerId"
                                @change="loadLocationData($event)"
                                rules="required"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1">
                        <b-col>
                            <p-table
                                :items="buybackSessionBuyerLocations"
                                :fields="buyerFields"
                                :enableRowSelection="false"
                                :emptyText="emptyText"
                                ref="sessionBuyerLocations"
                            >
                                <template v-slot:cell(cash)="{ value, item }">
                                    <p-number
                                        v-model.number="item.cash"
                                        compact-format
                                        input-type="money"
                                        name="cash"
                                        rules="min_value:0"
                                        :disabled="cashDisabled"
                                    />
                                </template>
                                <template
                                    v-slot:cell(comment)="{ value, item }"
                                >
                                    <p-text-area
                                        compact-format
                                        v-model="item.comment"
                                        rules="max:2000"
                                        name="Comment"
                                        size="sm"
                                    />
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </div>
            </template>
        </p-form>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import StoreListManager from '@/components/mixins/StoreListManager.js';
export default {
    mixins: [StoreListManager],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        buybackSessionBuyerId: [String, Number],
        buybackSessionSetup: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            isBusy: false,
            selectedBuyerId: null,
            selectListOptions: {
                buybackBuyerGroups: [],
                buybackBuyers: []
            },
            buybackSession: [],
            buybackSessionBuyerLocations: [],
            buyerFields: [
                {
                    key: 'name',
                    label: 'Locations',
                    sortable: false
                },
                {
                    key: 'cash',
                    label: 'Cash',
                    sortable: false
                },
                {
                    key: 'comment',
                    label: 'Comment',
                    sortable: false
                }
            ]
        };
    },
    created: function() {
        this.buybackSession = this.buybackSessionSetup;
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        },
        buybackSessionSetup: {
            handler: function(newVal) {
                this.buybackSession = newVal;
            }
        }
    },
    components: {},
    computed: {
        isNew() {
            return this.buybackSessionBuyerId === 'new';
        },
        buttonLabel() {
            return this.isNew ? 'Add' : 'Edit';
        },
        emptyText() {
            return this.selectedBuyerId === null
                ? 'Please select a buyer'
                : 'Selected buyer is not associated with any locations in this session';
        },
        cashDisabled() {
            if (this.isNew) return false;
            else {
                return this.buybackSessionSetup.buybackSessionBuyers.find(
                    x => x.buybackBuyerId == this.buybackSessionBuyerId
                ).buybackSessionBuyerId === undefined
                    ? false
                    : true;
            }
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            let name = this.$store.getters.getBuybackBuyerById(
                this.selectedBuyerId
            ).text;
            this.buybackSessionBuyerLocations.forEach(x => {
                (x.buybackBuyerId = this.selectedBuyerId),
                    (x.buyerName = name),
                    (x.locationName = x.name);
            });
            this.$emit('load-data', this.buybackSessionBuyerLocations);
            this.buybackSessionBuyerLocations = [];
            this.$refs.sessionBuyerModal.hide();
        },
        loadData() {
            this.selectedBuyerId = null;
            this.buybackSessionBuyerLocations = [];

            if (!this.isNew) {
                this.selectedBuyerId = this.buybackSessionBuyerId;
                this.loadLocationData(this.selectedBuyerId);
            }
        },
        loadLocationData(ev) {
            if (!ev) return;
            let groupId = this.$store.getters.getBuybackBuyerById(
                this.selectedBuyerId
            ).groupId;
            this.buybackSessionBuyerLocations = this.$store.getters
                .getBuybackBuyerGroupById(groupId)
                .buyerGroupLocations.filter(x =>
                    this.buybackSessionSetup.buybackSessionLocations.some(
                        y => y.buybackLocationId == x.locationId
                    )
                )
                .map(x => ({ ...x }));

            if (!this.isNew) {
                this.selectedBuyerId = this.buybackSessionBuyerId;
                this.buybackSessionBuyerLocations.forEach(x => {
                    (x.cash = this.buybackSessionSetup.buybackSessionBuyers.find(
                        y =>
                            y.locationId == x.locationId &&
                            y.buybackBuyerId == this.selectedBuyerId
                    ).cash),
                        (x.comment = this.buybackSessionSetup.buybackSessionBuyers.find(
                            y =>
                                y.locationId == x.locationId &&
                                y.buybackBuyerId == this.selectedBuyerId
                        ).comment);
                });
            } else {
                this.buybackSessionBuyerLocations.forEach(x => (x.cash = 0));
            }
        },
        buyerFilter(buyer) {
            return (
                !this.isNew ||
                !this.buybackSessionSetup.buybackSessionBuyers.some(
                    x => x.buybackBuyerId === buyer.value
                )
            );
        }
    }
};
</script>
