<template>
    <div>
        <b-card
            v-for="department in dccs"
            :key="department.dccDepartmentId"
            class="mb-1"
            no-body
        >
            <b-card-body class="p-1">
                <b-button
                    v-b-toggle="
                        'departmentToggle_' + department.dccDepartmentId
                    "
                    class="short-button p-0"
                >
                    <b-icon-chevron-down class="when-open mb-1" font-scale=".9"></b-icon-chevron-down>
                    <b-icon-chevron-right class="when-closed mb-1" font-scale=".9"></b-icon-chevron-right>
                </b-button>
                <span
                    :class="{
                        'pl-1' : true,
                        'selectable-element': true,
                        'selected-element':
                            selectedDcc.department === department &&
                            selectedDcc.class === undefined,
                        'selected-parent':
                            selectedDcc.department === department &&
                            selectedDcc.class != undefined
                    }"
                    @click="selectedElement(department)"
                    >{{ numberToString(department.number) }}
                    {{ department.name }}</span
                >

                <b-collapse
                    :id="'departmentToggle_' + department.dccDepartmentId"
                >
                    <div
                        class="pl-3"
                        v-for="dccClass in department.dccClasses"
                        :key="dccClass.dccclassId"
                    >
                        <b-button
                            v-b-toggle="'dccClassToggle_' + dccClass.dccClassId"
                            class="short-button p-0 "
                        >
                            <b-icon-chevron-down class="when-open mb-1" font-scale=".9"></b-icon-chevron-down>
                            <b-icon-chevron-right class="when-closed mb-1" font-scale=".9"></b-icon-chevron-right>

                        </b-button>
                        <span
                            :class="{
                                'pl-1' : true,
                                'selectable-element': true,
                                'selected-element':
                                    selectedDcc.class === dccClass &&
                                    selectedDcc.category === undefined,

                                'selected-parent':
                                    selectedDcc.class === dccClass &&
                                    selectedDcc.category != undefined
                            }"
                            @click="selectedElement(department, dccClass)"
                            >{{ numberToString(dccClass.number) }}
                            {{ dccClass.name }}
                        </span>
                        <b-collapse
                            :id="'dccClassToggle_' + dccClass.dccClassId"
                        >
                            <div
                                class="pl-4"
                                v-for="dccCategory in dccClass.dccCategories"
                                :key="dccCategory.dccCategoryId"
                            >
                                <span
                                    :class="{
                                        'selectable-element': true,
                                        'inactive-element': !dccCategory.isActive,
                                        'active-element': dccCategory.isActive,
                                        'selected-element':
                                            selectedDcc.category === dccCategory
                                    }"
                                    @click="
                                        selectedElement(
                                            department,
                                            dccClass,
                                            dccCategory
                                        )
                                    "
                                    >{{ numberToString(dccCategory.number) }}
                                    {{ dccCategory.name }}</span
                                >
                            </div>
                        </b-collapse>
                    </div>
                </b-collapse>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
export default {
    name: "dcc-list",
    props: {
        dccs: [Array],
        selectedDcc: Object
    },
    data() {
        return {};
    },
    watch: {},
    methods: {
        selectedElement(dccDepartment, dccClass, dccCategory) {
            this.$emit('selectedDccChanged', {
                department: dccDepartment,
                class: dccClass,
                category: dccCategory
                });
        },

        numberToString(number) {
            return number.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false
            });
        }
    }
};
</script>
<style scoped lang="scss">
@import "@/styles/app/common/variables.scss";
/deep/ .selected-element {
    background-color: $gray-lighter;
    outline-style: solid;
    outline-width: 1px;
    padding: 2px;
    outline-color: $gray;
}
/deep/ .selectable-element:hover {
    outline-style: solid;
    outline-width: 1px;
    padding: 2px;
    outline-color: $gray;
}
/deep/ .collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
/deep/ .short-button {
    height: 20px;
    background-color: inherit;
    border: none;
}

/deep/ .selectable-element {
    cursor: pointer;
    width: 100%;
}

/deep/ .selected-parent {
    text-decoration: underline;
}
/deep/ .inactive-element {
    opacity: .8;
}

</style>
