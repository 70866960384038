var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"d-inline-block container-fluid content-wrapper",attrs:{"show":_vm.isBusy,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('p-form',{on:{"submit":_vm.onSubmit}},[(_vm.salesEvent)?_c('div',[_c('b-row',{staticClass:"content-heading"},[_c('b-col',[_c('div',[_vm._v(_vm._s(_vm.isNew ? 'Add' : 'Edit')+" Sales Event")])]),_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('p-button',{attrs:{"type":"submit","variant":"primary","is-busy":_vm.isBusy}},[_vm._v("Save")])],1)],1),_c('p-card',[_c('h4',[_vm._v("General Parameters")]),_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"3"}},[_c('b-col',[_c('p-input',{attrs:{"label":"Sales Event Name","rules":"required|max:50"},model:{value:(_vm.salesEvent.name),callback:function ($$v) {_vm.$set(_vm.salesEvent, "name", $$v)},expression:"salesEvent.name"}})],1),_c('b-col',[_c('p-select',{attrs:{"label":"Sales Location","rules":"required","options":_vm.salesEventLocation},model:{value:(_vm.salesEvent.saleLocation),callback:function ($$v) {_vm.$set(_vm.salesEvent, "saleLocation", $$v)},expression:"salesEvent.saleLocation"}})],1),_c('b-col',[_c('p-select',{attrs:{"label":"Sales Event Price Rounding (Up)","rules":"required","options":_vm.salesEventPriceRounding},model:{value:(
                                _vm.salesEvent.priceMod.roundingTarget
                            ),callback:function ($$v) {_vm.$set(_vm.salesEvent.priceMod, "roundingTarget", _vm._n($$v))},expression:"\n                                salesEvent.priceMod.roundingTarget\n                            "}})],1)],1),_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"3"}},[_c('b-col',[_c('p-select',{attrs:{"label":"Discount Type","rules":"required","options":_vm.salesEventDiscountType},model:{value:(
                                _vm.salesEvent.priceMod.discountType
                            ),callback:function ($$v) {_vm.$set(_vm.salesEvent.priceMod, "discountType", $$v)},expression:"\n                                salesEvent.priceMod.discountType\n                            "}})],1),_c('b-col',[_c('p-number',{attrs:{"label":"Amount","rules":_vm.getAmountRules(_vm.salesEvent.priceMod.discountType),"inputType":_vm.getInputType(
                                    _vm.salesEvent.priceMod
                                        .discountType
                                ),"left-align":""},on:{"change":_vm.handleEventPriceChange},model:{value:(_vm.salesEvent.priceMod.amount),callback:function ($$v) {_vm.$set(_vm.salesEvent.priceMod, "amount", $$v)},expression:"salesEvent.priceMod.amount"}})],1)],1),_c('b-row',{staticClass:"ml-1 mb-2 text-danger",attrs:{"cols":"1","cols-sm":"2","cols-xl":"3"}},[_vm._v("(Changing either of the Discount Type or the Amount will change the Event Price)")]),_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"3"}},[_c('b-col',[_c('p-datepicker',{attrs:{"label":"Start Date","rules":"required","vid":"salesEventStartDate"},model:{value:(_vm.salesEvent.startDate),callback:function ($$v) {_vm.$set(_vm.salesEvent, "startDate", $$v)},expression:"salesEvent.startDate"}})],1),_c('b-col',[_c('p-datepicker',{attrs:{"label":"End Date","vid":"salesEventEndtDate","rules":"required|dateGreaterOrEqual:@salesEventStartDate"},model:{value:(_vm.salesEvent.endDate),callback:function ($$v) {_vm.$set(_vm.salesEvent, "endDate", $$v)},expression:"salesEvent.endDate"}})],1)],1)],1),_c('p-card',[_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Product Attachments")]),(true)?_c('p-form',{attrs:{"inline":""}},[_c('ProductMultiSelectQuickSearch',{attrs:{"label":"Add Product(s)","include-terms":"","dcc-filter":"","is-active-filter":"","icon-plus":"","disableAddButton":!_vm.isFormValidated},on:{"selections":_vm.addSelectedItem}})],1):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":false,"rounded":"","opacity":"0.5","spinner-variant":"primary"}},[_c('p-table',{attrs:{"items":_vm.salesEvent.products,"fields":_vm.getFields,"enableRowSelection":false},scopedSlots:_vm._u([{key:"cell(EventPrice)",fn:function(ref){
                                var value = ref.value;
                                var item = ref.item;
return [(item.isActive)?_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter(_vm.getEventPrice(item.retail)))+" ")]):_vm._e()]}},{key:"cell(overridePrice)",fn:function(ref){
                                var value = ref.value;
                                var item = ref.item;
return [_c('p-number',{attrs:{"compact-format":"","input-type":"money","name":"overridePrice","rules":"min_value:0","disabled":!item.isActive},model:{value:(item.overridePrice),callback:function ($$v) {_vm.$set(item, "overridePrice", _vm._n($$v))},expression:"item.overridePrice"}})]}},{key:"cell(isActive)",fn:function(ref){
                                    var value = ref.value;
                                    var item = ref.item;
return [_c('div',{staticClass:"extra-padding"},[_c('p-checkbox',{staticClass:"mr-0",attrs:{"use-switch":true,"size":"lg"},on:{"change":function($event){return _vm.isActiveToggleChange(item,value)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})],1)]}}],null,false,316780893)})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:" mb-3 text-center"},[_c('p-button',{attrs:{"variant":"primary","type":"submit","is-busy":_vm.isBusy}},[_vm._v("Save")])],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }