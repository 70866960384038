<template>
    <div>
        <SearchScreen title="Product Transfers"
                      api-destination="productTransfers"
                      :filters="filters"
                      :filterBy="filterBy"
                      :fields="fields"
                      ref="transfersSearchScreen"

                      >
            <template v-slot:actionButtons>
                <b-row>
                    <router-link to="producttransfers/new"
                                 class="btn btn-primary ml-3 mr-3">
                        <b-icon-plus></b-icon-plus> Add Transfer
                    </router-link>

                </b-row>
            </template>
            <template v-slot:belowSearch>
                <p-form>
                    <b-row class="mb-3 mt-4 mr-2">
                        <p-checkbox
                            label="Open"
                            class="ml-3"
                            v-model="filters.open"
                        />
                        <p-checkbox
                            label="In Transit"
                            class="ml-2"
                            v-model="filters.inTransit"
                        />
                        <p-checkbox
                            label="Closed"
                            class="ml-3"
                            v-model="filters.closed"
                        />

                    </b-row>
                </p-form>
            </template>
             <template v-slot:cell(view)="{ item }">
                <p-button
                variant="outline-primary"
                size="sm"
                @click='viewProductTransfer(item)'
                > View
                </p-button
                >
            </template>
        </SearchScreen>

    </div>
</template>

<script>
    import SearchScreen from '../../components/SearchScreen.vue';
    import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
    import { datetimeToLocalTimeFormatter } from '../../components/Common/Formatters.js';


    export default {
        mixins: [SearchStorage],
        data() {
            return {
                filterName: 'ProductTransfersFilter',
                filters: {
                    automatic: "",
                    perPage: 100,
                    sortBy: 'sentDateTime',
                    sortDesc: true,
                    searchBy: 'All',
                    open: true,
                    inTransit: true,
                    closed: false,
                },
                filterBy: ['All', 'Transfer #', 'Location of Origin', 'Destination'],
                fields: [
                    {
                        key: 'view',
                        label: 'Details',

                    },
                    {
                        key:'transferNumber',
                        label: 'Transfer #',
                        sortable: false
                    },
                    {
                        key:'lastModifiedDateTime',
                        label: 'Date & Time Last Update',
                        sortable: false,
                        formatter: datetimeToLocalTimeFormatter
                    },
                     {
                        key:'sentDateTime',
                        label: 'Date & Time Sent ',
                        sortable: true,
                        formatter: datetimeToLocalTimeFormatter
                    },
                    {
                        key:'locationOfOrigin',
                        label: 'Location of Origin',
                        sortable: false
                    },
                     {
                        key:'destination',
                        label: 'Destination',
                        sortable: false
                    },
                     {
                        key:'status',
                        label: 'Status',
                        sortable: false
                    }
                ],
                totalRows: 0,
            };
        },
        components: {
            SearchScreen,
        },
        methods: {
            viewProductTransfer(item){
                this.$router.push('/producttransfers/'+ item.productTransferId);
            }
        }
    };
</script>
