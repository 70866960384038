import { pDecimalValidationTest } from '@/components/mixins/ValidatedInput.js';
import { email, max, min_value } from 'vee-validate/dist/rules';

export default {
    data() {
        return {
            supplierConfiguration: {
                type: 'suppliers',
                label: 'Suppliers',
                importModes: [
                    {value:'add', text:'Add New (Skip when supplier code already present)'},
                    {value:'update', text:'Update (Skip when supplier code not present, update when found)'},
                    {value:'addAndUpdate', text:'Add and Update'}
                ],
                sampleNotes: [
                    'Any Code fields left blank will be generated',
                    'State must be provided as a valid abbreviation',
                    'If ReturnAddressSameAsOrdering is Yes, any data in the return address fields will be ignored and the ordering address data will be used',                    
                    'Supplier code is required to match existing suppliers'
                ],
                fields: [
                    {
                        key: 'Name',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 50 })
                            );
                        },
                        rules: 'required|max:50',
                        samples: ['"AAA Supplier, Inc."','"ABC Supplier, Inc."','"Paper Providers"']
                    },
                    {
                        key: 'Alias',
                        rules: 'max:10',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 10 });
                        },
                        warningDefault: '',
                        samples: ['"AAASup"','"ABCSup"','"PPaper"']
                    },
                    {
                        key: 'Code',
                        hasWarningCalculator: (value) => {
                            if (value === '' || value === undefined) {
                                return false;
                            }
                            return (value.length > 10 );
                        },
                        warningDefault: null,
                        rules: {
                            max: { length: 10 }
                        },
                        samples: ['"AAA001"','"ABC001"','"PAP001"']
                    },
                    {
                        key: 'Email',
                        hasWarningCalculator: value => {
                            if (value === '' || value === undefined) {
                                return false;
                            }
                            return (
                                !email.validate(value) ||
                                !max.validate(value, { length: 254 })
                            );
                        },
                        warningDefault: '',
                        rules: 'email|max:254',
                        samples: ['"AAASupplier@supplies.com"','"ABCSupplier@supplies.com"','"paper@supplies.com"']
                    },
                    {
                        key: 'Url',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 255 });
                        },
                        warningDefault: '',
                        rules: 'max:255',
                        samples: ['"www.AAASupplierInc.com"','"www.ABVSupplierInc.com"','"www.PaperProvider.com"']
                    },

                    // Ordering Address
                    {
                        key: 'OrderingAddress',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 255 });
                        },
                        warningDefault: '',
                        rules: 'max:255',
                        samples: ['"223 N 1st Street"','"1487 N 2nd Street"','"1212 S 1st Street"']
                    },
                    {
                        key: 'OrderingCity',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 50 });
                        },
                        warningDefault: '',
                        rules: 'max:50',
                        samples: ['"Vernon"','"Phoenix"','"Omaha"']
                    },

                    {
                        key: 'OrderingState',
                        dataType: 'states',
                        comparisonProperty: 'abbreviation',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"CA"','"AZ"','"NE"']
                    },
                    {
                        key: 'OrderingPostalCode',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"90058"','"87516"','"12491"']
                    },
                    {
                        key: 'OrderingPhoneNumber',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"1-555-444-3333"','"1-555-789-3333"','"1-555-845-3333"']
                    },
                    {
                        key: 'OrderingFaxNumber',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"1-555-444-3334"','"1-555-789-3334"','"1-555-845-4215"']
                    },

                    // Returns Address
                    {
                        key: 'ReturnAddressSameAsOrdering',
                        hasWarningCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        warningDefault: false,
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0',
                        samples: ['"Yes"', '"No"', '""']
                    },
                    {
                        key: 'ReturnsAddress',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 255 });
                        },
                        warningDefault: '',
                        rules: 'max:255',
                        samples: ['"223 N 1st Street"','"1487 N 2nd Street"','"1212 S 1st Street"']
                    },
                    {
                        key: 'ReturnsCity',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 50 });
                        },
                        warningDefault: '',
                        rules: 'max:50',
                        samples: ['"Vernon"','"Phoenix"','"Omaha"']
                    },

                    {
                        key: 'ReturnsState',
                        dataType: 'states',
                        comparisonProperty: 'abbreviation',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"CA"','"AZ"','"NE"']
                    },
                    {
                        key: 'ReturnsPostalCode',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"90058"','"87516"','"12491"']
                    },
                    {
                        key: 'ReturnsPhoneNumber',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"1-555-444-3333"','"1-555-789-3333"','"1-555-845-3333"']
                    },
                    {
                        key: 'ReturnsFaxNumber',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"1-555-444-3334"','"1-555-789-3334"','"1-555-845-4215"']
                    },
                    {
                        key: 'EasyLink',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"EZLA"','"EZLB"','"EZLC"']
                    },
                    {
                        key: 'PubnetSan',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 7 });
                        },
                        warningDefault: '',
                        rules: 'max:7',
                        samples: ['"PubAdd1"','"8571584"','"2014568"']
                    },
                    {
                        key: 'AccountNumberWithSupplier',
                        hasWarningCalculator: value => {
                            return (
                                !max.validate(value, { length: 15 })
                            );
                        },
                        warningDefault: '',
                        rules: 'max:15',
                        samples: ['"5367"','"4567"','"78957"']
                    },
                    {
                        key: 'FreeShippingOrderValue',
                        hasWarningCalculator: value => {
                            return (
                                value &&
                                (!min_value.validate(value, { min: 0 }) ||
                                    !pDecimalValidationTest(value, {
                                        precision: '16',
                                        scale: '2'
                                    }))
                            );
                        },
                        warningDefault: null,
                        convertToNumber: true,
                        rules: 'p-decimal:16,2|min_value:0',
                        samples: [50.25,35,100]
                    },
                    {
                        key: 'ShippingProvider',
                        dataType: 'shippingProviders',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        //using -1 to indicate that we received an invalid value and not an empty value
                        warningDefault: -1,
                        rules: 'min_value:0',
                        samples: ['"Best Method"','""','""']
                    },
                    {
                        key: 'ShippingLevel',
                        dataType: 'shippingLevels',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"Overnight"','"Second Day"','""']
                    },
                    {
                        key: 'ReturnPolicy',
                        hasWarningCalculator: (value) => {
                            return !this.matchesExistingObject(value);
                        },
                        warningDefault: null,
                        convertToNumber: true,
                        rules: 'min_value:0',
                        samples: ['Return permission required', 'No permission required', 'No returns'],
                        dataType: 'supplierReturnPolicies'
                    },
                    {
                        key: 'ReturnMinDays',
                        hasWarningCalculator: (value) => {
                            return !this.isNonNegativeInteger(value);
                        },
                        warningDefault: null,
                        convertToNumber: true,
                        rules: 'integer|min_value:0',
                        samples: ['30', '5', '10']
                    },
                    {
                        key: 'ReturnMaxDays',
                        hasWarningCalculator: (value) => {
                            return !this.isNonNegativeInteger(value);
                        },
                        warningDefault: null,
                        convertToNumber: true,
                        rules: 'integer|min_value:0',
                        samples: ['90', '30', '30']
                    },
                    {
                        key: 'ReturnComment',
                        hasErrorCalculator: value => {
                            if (value === '' || value === undefined) {
                                return false;
                            }
                            return value.length > 255;
                        },
                        rules: {
                            max: {length: 255 }
                        },
                        samples: ['"Return Comment1"','"Always use orange box"','"Do not overfill boxes"','""']
                    }
                ]
            }
        };
    }
};
