<template>
    <p-secure prism-rbs-only>
        <p-form @submit="onSubmit">
            <div class="container-fluid content-wrapper" v-if="tenant">
                <b-row class="content-heading">
                    <b-col>
                        <div>{{ isNew ? 'Add' : 'Edit' }} Tenant</div>
                    </b-col>
                    <p-secure :require-role="['Developer']" :show-permission-message="false">
                        <b-col cols="auto" class="ml-auto">
                            <p-button :is-busy="isBusy" variant="primary" type="submit">Save</p-button>
                        </b-col>
                    </p-secure>
                </b-row>
                <b-row>
                    <b-col xs="12" :lg="isNew?6:12">
                        <p-card>
                            <b-row>
                                <b-col>
                                    <h4>Provision Parameters</h4>
                                </b-col>
                            </b-row>
                            <content-wrapper>
                            <b-row cols="1" :cols-sm="isNew?1:2">
                                <b-col>
                                    <p-input
                                        label="Name"
                                        v-model="tenant.name"
                                        rules="required|max:64"
                                        :disabled="!isNew"
                                    />
                                </b-col>

                                <b-col>
                                    <p-input
                                        label="Abbreviation"
                                        v-model="tenant.abbreviation"
                                        rules="required|max:50"
                                        :disabled="!isNew"
                                    />
                                </b-col>

                                <b-col>
                                    <p-select
                                            label="Database Server"
                                            :options="config.sqlServers"
                                            v-model="tenant.databaseServer"
                                            rules="required"
                                            :disabled="!isNew"
                                        />
                                </b-col>

                                <b-col>
                                    <p-select
                                            label="Database Pool"
                                            :options="config.sqlPools"
                                            v-model="tenant.databasePool"
                                            rules="required"
                                            :disabled="!isNew"
                                        />
                                </b-col>

                                <b-col>
                                    <p-input
                                        label="Database Name (Case Sensitive)"
                                        v-model="tenant.databaseName"
                                        rules="required|max:32"
                                        :disabled="!isNew"
                                    />
                                </b-col>

                                <b-col>
                                    <p-input label="Subdomain" v-model="tenant.subdomain" rules="required|max:32" :disabled="!isNew">
                                        <template #append>
                                            <b-input-group-text>.{{config.dnsZone}}</b-input-group-text>
                                        </template>
                                    </p-input>
                                </b-col>

                                <b-col>
                                    <label v-if="!isNew">Database ID: {{ tenant.databaseId }}</label>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-checkbox
                                        label="Active"
                                        v-model="tenant.isActive"
                                        class="pb-4"
                                    />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <div v-if="!isNew">
                                        Provision Status: {{ tenant.provisionStatus }}
                                        <span v-if="tenant.provisionStatus != null && tenant.provisionStatus != 'Done'">
                                            for {{ provisionStatusTime }} seconds.
                                            <p-button @click="loadData()"><i class="fas fa-sync"></i></p-button>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </content-wrapper>
                        </p-card>
                    </b-col>
                    <b-col xs="12" lg="6">
                        <p-card v-if="isNew">
                            <b-row>
                                <b-col>
                                    <h4>Default Location Address</h4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-address v-model="tenant" :fullWidth="true" :requireFullAddress="true"></p-address>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-select
                                        label="Time Zone"
                                        v-model="tenant.timezone"
                                        :options="
                                            timezoneOptions
                                        "
                                        rules="required"
                                    />
                                </b-col>
                            </b-row>
                        </p-card>
                    </b-col>
                </b-row>
                <p-card v-if="!isNew" class="mt-2">
                    <b-row>
                        <div class="ml-2">
                            <h4>Locations</h4>
                        </div>
                        <b-col>
                            <p-secure :require-role="['Developer']" :show-permission-message="false">
                            <b-row>
                                <b-col class="text-left">
                                    <editLocation
                                        v-if="tenant.provisionStatus != null && tenant.provisionStatus == 'Done'"
                                        class="pb-2"
                                        location-id="new"
                                        @load-data="updateLocations"
                                        :locations="tenant.locations"
                                    />
                                </b-col>
                            </b-row>
                            </p-secure>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-table
                                :items="tenant.locations"
                                :fields="locationFields"
                                :enable-row-selection="false"
                                ref="locations"
                                :selectable="false"
                                sort-by="name"
                            >
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <p-secure :require-role="['Developer']" :show-permission-message="false">
                    <b-row>
                        <b-col class="mt-3 text-center">
                            <p-button :is-busy="isBusy" variant="primary" type="submit">Save</p-button>
                        </b-col>
                    </b-row>
                </p-secure>
            </div>
        </p-form>
    </p-secure>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import moment from 'moment';
import ContentWrapper from '../../../components/Layout/ContentWrapper.vue';
import editLocation from './EditLocation.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
  components: { ContentWrapper, editLocation },
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            tenant: null,
            config: {
                sqlServers: [],
                sqlPools: []
            },
            isBusy: false,
            locationFields: [
                {
                    key: 'name',
                    label: 'Location Name'
                },
                {
                    key: 'shortName',
                    label: 'Short Name'
                },
                {
                    key: 'abbreviation',
                    label: 'Abbreviation',
                },
                {
                    key: 'storeNumber',
                    label: 'Location Number',
                }
            ],
            timezoneOptions: []
        };
    },
    watch: {
        locations: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        provisionStatusTime() {
            return moment
                .utc()
                .diff(moment.utc(this.tenant.provisionStatusDate), 'seconds');
            //return moment.utc(this.tenant.provisionStatusDate).fromNow(true);
        }
    },
    methods: {
        onSubmit() {
            this.isBusy = true;
            if (this.isNew) {
                axios.post('tenants', this.tenant).then(response => {
                    this.dataSaved();
                        this.$router.push('/tenants/' + response.data);
                        this.$toasted.global
                            .app_success(
                                `Tenant '${this.tenant.name}' saved successfully.`
                            )
                            .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
            }
            else{
                axios.post('locations/createLocations', {tenant: this.tenant}).then(response => {
                    this.tenant = response.data;
                    this.dataSaved();
                    this.$toasted.global
                    .app_success(
                        `Locations saved successfully.`
                    )
                    .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
            }
            // todo error handling
        },
        async loadData() {
            this.tenant = this.isNew
                ? (await axios.get('tenants/new')).data
                : (await axios.get('tenants', { params: { id: this.id } })).data;

            this.config = (await axios.get('tenants/config')).data;
            this.timezoneOptions = await selectListOptionsDataContext.getStoreDropdownData('timezones');
        },
        updateLocations(location){
            this.tenant.locations.push(
                location
            );
        }
    },
    async created() {
        await this.loadData();
    }
};
</script>

<style></style>
