<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="campus">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Campus</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary"
                        >Save</b-button
                    >
                </b-col>
            </b-row>

            <p-card>
                <b-row cols="1" cols-lg="2">
                    <b-col>
                        <p-input
                            label="Name"
                            v-model="campus.name"
                            rules="required|max:80"
                        />
                        <p-input
                            label="Description"
                            v-model="campus.description"
                            rules="required|max:255"
                        />
                        <p-input
                            label="Abbreviation"
                            v-model="campus.abbreviation"
                            rules="required|max:40"
                        />
                        <p-checkbox
                            label="Active"
                            v-model="campus.isActive"
                            class="pb-4"
                        />
                    </b-col>
                </b-row>
            </p-card>
            <b-row>
                <b-col class="text-center">
                    <b-button variant="primary" type="submit"
                        >Save</b-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js'
import editPage from '@/components/mixins/EditPage';

    export default {
        mixins: [NavigationGuard, editPage],
        data() {
            return {
                campus: null
            };
        },
        watch: {
            campus: NavigationGuard.$watcher
        },
        methods: {
            onSubmit() {
                axios.post("campuses", this.campus)
                    .then((response) => {
                        this.dataSaved();
                        if (this.isNew) {
                            this.$router.push("/campuses/" + response.data);
                        }
                        this.$toasted.global.app_success(`Campus '${this.campus.name}' saved successfully.`).goAway(5000);
                    });
                // todo error handling
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('campuses/new')
                : axios.get('campuses', { params: { id: this.id } });

            promise.then(resp => {
                this.campus = resp.data;
                if (!this.isNew) {
                         this.dataLoaded();
                    }
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
