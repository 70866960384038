<template>
    <p-card title="Recent Transactions">
        <b-row>
            <b-col style="height: 150px; overflow-y: auto;">
                <p-table
                    :items="transactions"
                    :enable-row-selection="false"
                    :fields="transactionWidgetFields"
                    :is-busy="isBusy"
                    :pagination="false"
                    :showRecordCount="false"
                    :perPage="50"
                    v-if="transactions != null"
                >
                </p-table>
            </b-col>
        </b-row>
    </p-card>
</template>

<script>
import axios from 'axios';
import {
    currencyFormatter,
    datetimeToLocalTimeFormatter
} from '@/components/Common/Formatters.js';
export default {
    data() {
        return {
            isBusy: false,
            transactions: null,
            transactionWidgetFields: [
                {
                    key: 'transactionId',
                    label: '',
                    tdClass: 'noWrap compact-control'
                },                {
                    key: 'customerName',
                    label: '',
                    tdClass: 'noWrap compact-control'
                },
                {
                    key: 'transactionDate',
                    label: '',
                    formatter: datetimeToLocalTimeFormatter,
                    tdClass: 'noWrap compact-control'
                },
                {
                    key: 'transactionTotal',
                    label: '',
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right compact-control'
                }
            ]
        };
    },
    mounted: async function() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.isBusy = true;
            axios
                .get('dashboard/transactions')
                .then(resp => {
                    if (resp.data != null) {
                        this.transactions = resp.data;
                    }
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>

<style scoped lang="scss">
/deep/ .noWrap {
    white-space: nowrap;
}

/deep/ .compact-control {
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 2px;
    height: 1.5rem;
    width:150px;
    font-size: 12px;
}
</style>