<template>
    <p-form @submit='onSubmit'>
        <div class="container-fluid content-wrapper" v-if="productVariantDetails">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Product Variant</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <p-button variant="primary" type="submit" :is-busy="isBusy">Save</p-button>
                </b-col>
            </b-row>
            <b-row cols="1" cols-md="3">
                <b-col>
                    <p-input
                        label="Variant Name"
                        name="Variant Name"
                        v-model="productVariantDetails.variantName"
                        rules="required|max:80"
                        :disabled="!isNew"
                    />
                </b-col>
                <b-col>
                    <p-select
                        dataType="productVariantTypes"
                        label="Category"
                        name="Category"
                        v-model="productVariantDetails.productVariantTypeId"
                        rules="required"
                    />
                </b-col>
                <b-col>
                    <p-select
                        label="Status"
                        v-model="productVariantDetails.status"
                        :options="status"
                        text-field="value"
                    />
                </b-col>
            </b-row>
            <p-card>
                <b-row>
                    <b-col cols="12">
                        <p-table
                            :items="getItems"
                            :enable-row-selection="false"
                            :fields="fields"
                            :pagination="false"
                            :per-page="500"
                        >
                            <template v-slot:above-table>
                                <b-row
                                >
                                    <b-col>
                                        <p-checkbox
                                            v-model="filterSelected"
                                            size="md"
                                            label="Show Only Selected"
                                        ></p-checkbox>
                                    </b-col>
                                </b-row>
                            </template>

                            <template v-slot:cell(isActive)="{ value, item }">
                                <p-checkbox
                                    v-model="item.isActive"
                                    size="md"
                                ></p-checkbox>
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
            <b-row class="mt-3">
                <b-col class="text-left">
                    <p-button
                        variant="outline-danger"
                        type="button"
                        :is-busy="isBusy"
                        @click="deleteVariant"
                        v-if="!isNew"
                        >Delete</p-button
                    >
                </b-col>
                <b-col class="text-right">
                    <p-button
                        variant="primary"
                        type="submit"
                        :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import editPage from '@/components/mixins/EditPage';
import axios from 'axios';

export default {
    mixins: [NavigationGuard, editPage],
    components: {},
    data() {
        return {
            isBusy: false,
            filterSelected: false,
            status: [{ value: 'Active' }, { value: 'Inactive' }],
            fields: [
                {
                    key: 'isActive',
                    label: ''
                },
                {
                    key: 'name',
                    label: 'Variant Groups',
                    automaticSearch: true
                }
            ],
            productVariantDetails: null
        };
    },
    watch: {
        productVariantDetails: NavigationGuard.$watcher
    },
    mounted: function() {
        this.loadData();
    },
    computed: {
        getItems() {
            return this.filterSelected
                ? this.productVariantDetails.productVariantGroupings.filter(
                      x => x.isActive == true
                  )
                : this.productVariantDetails.productVariantGroupings;
        },
        isAnyGroupLinked() {
            return (
                this.productVariantDetails.productVariantGroupings.filter(
                    x => x.isActive == true
                ).length > 0
            );
        }
    },
    methods: {
        onSubmit() {
            this.isBusy = true;
            axios
                .post('productvariant', this.productVariantDetails)
                .then(response => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push(
                            '/productvariant/' + response.data
                        );
                    }
                    this.$toasted.global
                        .app_success(
                            `Product variant details saved successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },

        loadData() {
            let promise = this.isNew
                ? axios.get('productvariant/new')
                : axios.get('productvariant', { params: { id: this.id } });

            promise.then(resp => {
                this.productVariantDetails = resp.data;
                this.dataLoaded();
            });
        },

        deleteVariant() {
            if(this.isAnyGroupLinked){
                this.$toasted.global
                    .app_error(
                        'Unable to delete variants assigned to groups. Please remove variant from any groups before attempting to delete.'
                    )
                    .goAway(5000);
            }
            else if (this.productVariantDetails.status == "Active")
            {
                this.$toasted.global
                    .app_error(
                        'Unable to delete product variant as it is Active. Please set variant status to inactive before attempting to delete.'
                    )
                    .goAway(5000);
            }
            else{
                this.isBusy = true;
                axios
                    .post('productvariant/delete', this.productVariantDetails)
                    .then(() => {
                        this.dataSaved();
                        this.$router.push('/productvariants');
                        this.$toasted.global
                            .app_success(`Product variant deleted successfully.`)
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });

            }
        }
    }
};
</script>