<template>
    <p-form @submit='onSubmit' ref='form'>
        <div class='container-fluid content-wrapper' v-if='course'>
            <b-row class='content-heading' v-if='!isModal'>
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Course Creation</div>
                </b-col>
                <b-col class='text-right'>
                    <b-button type='submit' variant='primary'>Save</b-button>
                </b-col>
            </b-row>
            <p-card>
                <b-row :cols='!isModal ? 1 : 12' :cols-lg='!isModal ? 2 : 12'>
                    <b-col>
                        <p-input
                            label='Name'
                            vid='courseName'
                            v-model='course.name'
                            rules='required|max:80'
                        />
                        <p-input
                            label='Description'
                            vid='courseDescription'
                            v-model='course.description'
                            rules='required|max:255'
                        />
                        <p-advanced-select
                            v-if='!isModal'
                            label='Campus'
                            :disabled="!isNew"
                            data-type='campuses'
                            rules='required'
                            v-model='course.campusId'
                        />
                        <p-advanced-select
                            v-if='!isModal'
                            label='Academic Department'
                            data-type='courseDepartments'
                            no-options-message='No Departments Associated To Campus'
                            rules='required'
                            empty-on-null-filter
                            :filter='course.campusId'
                            v-model='course.courseDepartmentId'
                        />
                        <p-text-area label='Comments' v-model='course.comments' rules='max:255' />
                    </b-col>
                </b-row>
            </p-card>
        </div>
        <b-col class='text-center' v-if='!isModal'>
            <b-button type='submit' variant='primary'>Save</b-button>
        </b-col>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

    export default {
        mixins: [NavigationGuard],
        props: {
            id: String,
            isModal: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                course: null
            };
        },
        watch: {
            course: NavigationGuard.$watcher,
            $route: function (to, from) {
                if (to !== from) {
                    this.loadData();
                }
            }
        },
        computed: {
            isNew() {
                return this.id === 'new';
            }
        },
        methods: {
            async onSubmit() {
                let response = await axios.post('courses', this.course);
                this.dataSaved();

                if (!this.isModal && this.isNew) {
                    this.$router.push("/courses/" + response.data);
                }

                this.$toasted.global.app_success(`Course '${this.course.name}' saved successfully.`).goAway(5000);
                // todo error handling
                return response.data;
            },
            loadData() {
                let promise = this.isNew
                    ? axios.get('courses/new')
                    : axios.get('courses', { params: { id: this.id } });

                promise.then((resp) => {
                    this.course = resp.data;
                    if (!this.isNew) {
                         this.dataLoaded();
                    }
                });
            }
        },
        mounted: async function () {
            this.loadData();
        },
    };
</script>