<template>
    <div class="container-fluid content-wrapper">
        <p-card>
            <b-row cols="1" cols-md="2">
                <b-col> <strong v>Author: </strong>{{ product.author }} </b-col>
                <b-col> <strong v>Title: </strong>{{ product.title }} </b-col>
            </b-row>
            <b-row cols="1" cols-md="4">
                <b-col>
                    <strong v>New Selling Price: </strong
                    ><span v-if="product.newVariant">{{ currencyFormatter(product.newVariant.retail) }}</span>
                    <span v-else>{{ currencyFormatter(0.00) }}</span>
                </b-col>
                <b-col>
                    <strong v>Used Selling Price: </strong
                    ><span v-if="product.usedVariant">{{ currencyFormatter(product.usedVariant.retail) }}</span>
                    <span v-else>{{ currencyFormatter(0.00) }}</span>
                </b-col>
                <b-col>
                    <strong v>New Cost: </strong
                    ><span v-if="product.newVariant">{{ currencyFormatter(product.newVariant.cost) }}</span>
                    <span v-else>{{ currencyFormatter(0.00) }}</span>
                </b-col>
                <b-col>
                    <strong v>Used Cost: </strong
                    ><span v-if="product.usedVariant">{{ currencyFormatter(product.usedVariant.cost) }}</span>
                    <span v-else>{{ currencyFormatter(0.00) }}</span>
                </b-col>
            </b-row>
        </p-card>
        <p-card>
            <b-row cols="1" cols-md="4">
                <b-col>
                    <p-number
                        label="New Rental Price"
                        input-type="money"
                        v-model="rentalData.newRentalPrice"
                        :disabled="!rentalData.newRentalItemId"
                        rules="min_value:0"
                    >
                    </p-number>
                </b-col>
                <b-col>
                    <p-number
                        label="Used Rental Price"
                        input-type="money"
                        v-model="rentalData.usedRentalPrice"
                        :disabled="!rentalData.usedRentalItemId"
                        rules="min_value:0"
                    >
                    </p-number>
                </b-col>
                <b-col>
                    <p-number
                        label="Rebate Price"
                        input-type="money"
                        disabled="disabled"
                        v-model="rentalData.rebatePrice"
                    >
                    </p-number>
                </b-col>
                <b-col>
                    <p-datepicker
                        label="Due Date"
                        vid="rentalsDueDate"
                        v-model="rentalData.dueDate"
                        disabled="disabled"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p-number
                        label="Replacement Fee"
                        input-type="money"
                        v-model="rentalData.replacementFee"
                        rules="min_value:0"
                    >
                    </p-number>
                </b-col>
                <b-col>
                    <p-number
                        label="Late Fee"
                        input-type="money"
                        v-model="rentalData.lateFee"
                        disabled="disabled"
                    >
                    </p-number>
                </b-col>
                <b-col>
                    <p-input
                        label="Price Source"
                        disabled="disabled"
                        :value="checkPriceSource"
                    />
                </b-col>
            </b-row>
        </p-card>
    </div>
</template>
<script>
import { currencyFormatter } from '@/components/Common/Formatters.js';
export default {
    props: {
        product: Object,
        rentalData: Object
    },
    data() {
        return {
        };
    },
    computed: {
        checkPriceSource() {
            return this.rentalData.isNbcRental
                ? 'Mosaic Rental'
                : 'Store Managed';
        }
    },
    methods: {
        currencyFormatter: currencyFormatter
    }
};
</script>
