<template>
  <SearchScreen
    title="Suppliers"
    api-destination="suppliers"
    edit-key="supplierId"
    :filters="filters"
    :fields="fields"
    :filterBy="filterBy"
    add-new-button
  ></SearchScreen>
</template>

<script>
import SearchScreen from "../../components/SearchScreen.vue";
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
  mixins: [SearchStorage],
  data() {
    return {
      filterName: 'SuppliersFilter',
      filters: {
        automatic: "",
        perPage: 100,
        sortBy: 'name',
        sortDesc: true,
        searchBy:'All'
      },
      filterBy:[
          'All',
          'Name',
          'Alias',
          'Code'
      ],
      fields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "alias",
          sortable: true
        },
        {
          key: "code",
          sortable: true
        }
      ]
    };
  },
  components: {
    SearchScreen
  }
};
</script>
