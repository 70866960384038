<template>
<div>
    <b-row  cols="1" cols-lg="2">
        <b-col>
             <p-input name="from"
                                 v-model="emailDetail.fromName"
                                 label="From Name"
                                 rules="max:100" />
        </b-col>
        <b-col>
             <p-input name="to"
                                 v-model="emailDetail.replyEmail"
                                 label="Reply to"
                                 rules="email|max:254" />
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <p-input name="subject"
                                 v-model="emailDetail.emailSubject"
                                 label="Subject"
                                 rules="max:1000" />
        </b-col>
    </b-row>
     <b-row class='pb-3'>
                <b-col>
                    <custom-quill-editor v-model='emailDetail.emailContent' />
                </b-col>
            </b-row>
    
</div>
</template>
<script>
import 'quill/dist/quill.snow.css';
export default {
    name: 'EmailTemplate',
    components: {
    },
    props: {
        emailDetail:{
                fromName:"",
                replyEmail:"",
                emailSubject:"",
                emailContent:""
            }


    },
    data() {
        return {
            
        }
    }
}
</script>
<style scoped lang="scss">
   /deep/.ql-image, /deep/.ql-video{
        display:none !important;
    }
</style>