<template>
    <p-form @submit="onSubmit" v-if="tenders">
        <div>
            <b-row>
                <b-col cols="auto" class="ml-auto mb-3">
                    <p-button
                        variant="primary"
                        :is-busy="isBusy"
                        type="submit"
                        >Save Tenders
                    </p-button>
                </b-col>
            </b-row>
            <b-row cols="1" cols-lg="2">
                <b-col>
                    <p-card title="Tenders">
                        <p-table
                            :items="tenders"
                            :fields="fields"
                            :enable-row-selection="false"
                            sort-by="tenderName"
                        >
                            <template
                                v-slot:cell(isActiveOnline)="{ value, item }"
                            >
                                <p-checkbox
                                    class="tcw100"
                                    v-model="item.isActiveOnline"
                                    name="isDefault"
                                />
                            </template>
                        </p-table>
                    </p-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-3 text-center">
                    <p-button
                        variant="primary"
                        :is-busy="isBusy"
                        type="submit"
                        >Save Tenders</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import axios from 'axios';

export default {
    mixins: [NavigationGuard],
    data() {
        return {
            isBusy: false,
            tenders: null,
            fields: [
                {
                    key: 'tenderName',
                    label: 'Name',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'isActiveOnline',
                    label: 'Is Active',
                    sortable: true
                }
            ]
        };
    },
    mounted: function() {
        this.loadData();
    },
    methods: {
        loadData() {
            axios.get('tenders').then(resp => {
                this.tenders = resp.data;
                this.dataLoaded();
            });
        },
        onSubmit() {
            this.isBusy = true;
            axios.post('tenders', {updateTenders: this.tenders} ).then(() => {
                this.dataSaved();
                this.$toasted.global
                    .app_success(
                        `Tenders saved successfully.`
                    )
                    .goAway(5000);
            }).finally(() => {
                this.isBusy = false;
            });
        }
    },
    watch: {
        tenders: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    }
};
</script>

<style scoped>
.tcw100 {
    width: 100px;
}
</style>