<template>
    <div class="container-fluid content-wrapper">
        <b-row class="content-heading">
            <b-col>
                <div>Departments, Classes, and Categories</div>
            </b-col>
            <b-col cols="auto" class="ml-auto">
                <b-button type="submit" form="dcc-edit-form" variant="primary">Save</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-button variant="primary"
                      class="ml-3 mb-2 "
                      @click="addNewDccClick()">
                    <b-icon-plus></b-icon-plus>
                Add DCC
            </b-button>
            <b-button variant="primary"
                      @click="addNewClassAndCategoryClick()"
                      v-show="departmentSelected"
                      class="ml-3 mb-2">
                <b-icon-plus></b-icon-plus>Add Class and Category</b-button>
            <b-button variant="primary"
                      @click="addNewCategoryClick()"
                      v-show="classSelected"
                      class="ml-3 mb-2">
                <b-icon-plus></b-icon-plus>Add Category</b-button>
        </b-row>
        <b-row>
            <b-col cols="5" sm="4" lg="3">
                <dccList :dccs="dccs"
                         :selectedDcc="selectedDcc"
                         @selectedDccChanged="selectedDccChanged"
                         class="dcc-list-window">
                </dccList>

                <b-button variant="primary"
                          @click="addNewClassAndCategoryClick()"
                          v-show="departmentSelected"
                          class="mr-1 mt-2">
                    <b-icon-plus></b-icon-plus>Add Class and Category</b-button>
                <b-button variant="primary"
                          @click="addNewCategoryClick()"
                          v-show="classSelected"
                          class="mr-1 mt-2">
                    <b-icon-plus></b-icon-plus>Add Category</b-button>
            </b-col>
            <b-col cols="7" sm="8" lg="9">
                <edit v-if="selectedDcc.department"
                      :selectedDcc="selectedDcc"
                      :dccs="dccs"
                      @reload-dccs="loadDccs"
                      ref="dccEdit"></edit>
            </b-col>

        </b-row>
        <b-row>
            <b-button variant="primary"
                      class="ml-3 mt-2 "
                      v-show="dccs.length > 0"
                      @click="addNewDccClick()">
                <b-icon-plus></b-icon-plus>Add DCC</b-button>
        </b-row>
    </div>
</template>

<script>
    import axios from "axios";
    import NavigationGuard from '@/components/mixins/NavigationGuard.js'
    import dccList from "./DccList.vue";
    import edit from "./Edit.vue";

    export default {
        mixins: [NavigationGuard],
        data() {
            return {
                dccs: [],
                selectedDcc: this.getEmptySelection(),
                displayMessage:null
            };
        },
        components: {
            dccList,
            edit
        },
        computed: {
            departmentSelected() {
                return (
                    this.selectedDcc.department?.dccDepartmentId &&
                    !this.selectedDcc.class
                );
            },
            classSelected() {
                return (
                    this.selectedDcc.class?.dccClassId && !this.selectedDcc.category
                );
            },
            categorySelected() {
                return this.selectedDcc.category?.dccCategoryId;
            }
        },
        methods: {
            getEmptySelection() {
                return {
                    department: null,
                    class: null,
                    category: null,
                };
            },
            async selectedDccChanged(selectedDcc) {
                if (this.$refs.dccEdit && this.$refs.dccEdit.formIsDirty) {
                    await this.$bvModal
                        .msgBoxConfirm('Are you sure you want to leave without saving?')
                        .then(value => {
                            if (!value) {
                                return;
                            } else {
                                this.selectedDcc = selectedDcc;
                            }
                        });
                }
                else {
                    this.selectedDcc = selectedDcc;
                }
            },
            
            getNewCategory() {
                return { 
                        isNew: true, 
                        allowsDiscounts: true
                    }
            },
            addNewDccClick() {
                this.selectedDcc = {
                    department: { isNew: true, type: null },
                    class: { isNew: true },
                    category: this.getNewCategory()
                };
            },
            addNewClassAndCategoryClick() {
                this.selectedDcc.class = { isNew: true };
                this.selectedDcc.category = this.getNewCategory();
            },
            addNewCategoryClick() {
                this.selectedDcc.category = this.getNewCategory();
            },
            loadDccs() {
                this.dccs = [];
                this.selectedDcc = this.getEmptySelection();
                axios.get("dccs").then(resp => {
                    this.dccs = resp.data;
                });
            },
        },
        async beforeRouteLeave(to, from, next) {
            if (this.$refs.dccEdit && this.$refs.dccEdit.formIsDirty) {
                await this.$bvModal
                    .msgBoxConfirm(this.displayMessage)
                    .then(value => {
                        if (!value) {
                            next(false);
                            return;
                        } else {
                            next();
                        }
                    });
            }
            else {
                next();
            }
        },
        mounted: function () {
             const h = this.$createElement;
        this.displayMessage = h('div', [
            h('p', ['Are you sure you want to leave without saving?',]),
            h('p', ['Select ‘OK’ to discard changes or ‘Cancel’ to return to editing.'])
        ]);
            this.loadDccs();
        }
    };
</script>
<style scoped>
    >>> .dcc-list-window {
        max-height: 750px;
        overflow-y: auto;
    }
</style>
