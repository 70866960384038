<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <p-modal size="lg"
                 name="customerTransactionModal">
            <template v-slot:modal-header>
                <b-container fluid>
                    <b-row>
                        <b-col v-if="transaction">
                           <h4> {{transactionNumber}} Details</h4>
                        </b-col>
                        <b-col v-if="false" cols='auto' class='ml-auto'>
                            <p-button variant='outline-primary' @click='printReceipt'>Print Receipt</p-button>
                        </b-col>
                    </b-row>
                </b-container>
                <button type="button" aria-label="Close" class="close"
                @click="() => { $bvModal.hide('customerTransactionModal')}">×</button>
            </template>
            <template v-slot:default>
                <div v-if="transaction" class='container-fluid content-wrapper'>
                    <b-row  cols="1" cols-lg="4" v-if="transaction.promptDetails.length>0">
                        <b-col v-for="prompDetial in transaction.promptDetails"
                        :key="prompDetial.label">
                            <p-input
                                :label="prompDetial.promptName"
                                :value="prompDetial.promptValue"
                            disabled/>
                        </b-col>
                    </b-row>
                    <div style="max-height:450px;padding:0 15px;overflow-y:auto;" class="mb-4">
                    <div><h4>Customer Details</h4></div> 
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                        <b-col>
                            <p-input
                                label="Account #"
                                rules="max:80"
                                :disabled="true"
                                v-model="transaction.customerDetails.accountNumber"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="First Name"
                                rules="max:50"
                                :disabled="true"
                                v-model="transaction.customerDetails.firstName"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Last Name"
                                rules="max:50"
                                :disabled="true"
                                v-model="transaction.customerDetails.lastName"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Student ID"
                                rules="max:50"
                                :disabled="true"
                                v-model="transaction.customerDetails.studentId"
                            />
                        </b-col>
                    </b-row>

                    <div><h4>Transaction Details</h4></div>    
                    <p-table :fields='transactionFields'
                             :enable-row-selection='false'
                             :per-page="5000"
                             :show-record-count='false'
                             :items=[transaction.transactionDetails]>
                             <template v-slot:foot(dateTime)="data">
                                        {{ this.transaction.transactionDetails.dateTime }}
                             </template>
                    </p-table> 
                    </div>
                    <div style="max-height:450px;padding:0 15px;overflow-y:auto;" class="mb-4">
                    <div><h4>Purchased Items</h4></div>    
                    <p-table :fields='productFields'
                             :enable-row-selection='false'
                             :per-page="5000"
                             :show-record-count='false'
                             :items='transaction.products'>
                             <template
                                v-slot:head(description)="{
                                    value,
                                    item
                                }"
                            >
                                <div>Product Description</div>
                                <div>Variants</div>
                            </template>
                            <template
                                v-slot:cell(description)="{
                                    value,
                                    item
                                }"
                            >
                                <div>{{ value }}</div>
                                <div>
                                    <span>{{ item.gmVariant1 }} 
                                        <span v-if="item.gmVariant1 != '' && item.gmVariant2 != ''">-</span> 
                                        {{ item.gmVariant2 }}
                                    </span> 
                                    <span v-if="!item.isShipping && item.serial != '' && item.serial != null"> /SN #: {{ item.serial }}</span>
                                </div>
                            </template>
                    </p-table>
                    </div>
                    <b-row cols="1" cols-md="2" >
                        <b-col>
                            <p-card title="Applied Discount(s)">
                                <p-table foot-clone
                                        no-footer-sorting
                                        :fields='discountFields'
                                        :enable-row-selection='false'
                                        :show-record-count='false'
                                        thead-class="d-none"
                                        :items='transaction.discounts'
                                        :show-empty='false'
                                        >
                                    <template v-slot:foot(total)="data">
                                        {{ totalDiscounts }}
                                    </template>
                                    <template v-slot:foot(name)="data">
                                        <strong>Discount Total</strong>
                                    </template>
                                </p-table>
                            </p-card>
                            <p-card title="Transaction Total">
                                <b-row cols="2">
                                    <b-col>
                                        <label>Line Items Extended </label>
                                    </b-col>
                                    <b-col class="text-right">
                                        <label>{{currencyFormatter(transaction.totals.totalExtSellingPrice)}}</label>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label>Discount Total</label>
                                    </b-col>
                                    <b-col class="text-right">
                                        <label>{{currencyFormatter(transaction.totals.totalDiscount)}}</label>
                                    </b-col>
                                </b-row>
                                
                                <b-row>
                                    <b-col>
                                        <label>Subtotal</label>
                                    </b-col>
                                    <b-col class="text-right">
                                        <label>{{currencyFormatter(transaction.totals.subTotal)}}</label>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label>Tax Rate Total</label>
                                    </b-col>
                                    <b-col class="text-right">
                                        <label>{{currencyFormatter(transaction.totals.totalTax)}}</label>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <strong>Total Amount Due </strong>
                                    </b-col>
                                    <b-col class="text-right">
                                        <strong>{{currencyFormatter(transaction.totals.totalAmountDue)}}</strong>
                                    </b-col>
                                </b-row>
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card title="Transaction Tax Rates">
                                <p-table foot-clone
                                        no-footer-sorting
                                        :fields='taxFields'
                                        :enable-row-selection='false'
                                        :show-record-count='false'
                                        thead-class="d-none"
                                        :items='transaction.taxes'
                                        :show-empty='false'>
                                    <template v-slot:foot(total)="data">
                                        {{ totalTaxes }}
                                    </template>
                                    <template v-slot:foot(name)="data">
                                        <strong>Tax Rate Total</strong>
                                    </template>
                                </p-table>
                            </p-card>
                            <p-card title="Tenders">
                                <p-table foot-clone
                                         no-footer-sorting
                                         :fields='tenderFields'
                                         :enable-row-selection='false'
                                         :show-record-count='false'
                                         thead-class="d-none"
                                         :items='transaction.tenders'
                                         :show-empty='false'>
                                    <template v-slot:cell(name)="{ item }">
                                         {{ item.name }}<br/>
                                         <strong>{{ item.accountNumber? `AR Account Name/AR Agency`:``}}</strong><br/>
                                         {{ item.accountNumber?item.accountNumber+'/'+item.agencyName:'' }}
                                    </template>
                                    <template v-slot:foot(total)="data">
                                        {{ totalTenders }}
                                    </template>
                                    <template v-slot:foot(name)="data">
                                        <strong>Tender Total</strong>
                                    </template>
                                </p-table>
                            </p-card>
                            <p-card title="Token">
                                <p-table :fields='tokenFields'
                                         :enable-row-selection='false'
                                         :show-record-count='false'
                                         thead-class="d-none"
                                         :items='transaction.tokens'
                                         :show-empty='false'>
                                </p-table>
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:modal-footer>
                <p-button variant='outline-primary'
                          @click="() => { $bvModal.hide('customerTransactionModal')}">Close</p-button>
            </template>
        </p-modal>
    </p-secure>
</template>

<script>
    // import axios from 'axios';
    import NavigationGuard from '@/components/mixins/NavigationGuard.js';
    import { currencyFormatter } from './Common/Formatters.js';
    import { datetimeToLocalTimeFormatter } from '../components/Common/Formatters.js';

    export default {
        mixins: [NavigationGuard],
        props: {
            transaction: null
        },
        data() {
            return {


                productFields: [
                    {
                        key: "description",
                        label: "Product Description Variants"
                    },
                    {
                        key: "barcode",
                        label: "Default Barcode"
                    },
                    {
                        key: "qty",
                        thClass: 'text-right',
                        tdClass: 'text-right',
                    },
                    {
                        key: "price",
                        label: "Selling Price",
                        thClass: 'text-right',
                        tdClass: 'text-right',
                        formatter: currencyFormatter
                    },
                    {
                        key: "extendedPricePreDiscount",
                        label: "Line Item Extended",
                        thClass: 'text-right',
                        tdClass: 'text-right',
                        formatter: currencyFormatter
                    },
                    {
                        key: "discount",
                        label: "Line Item Discount",
                        thClass: 'text-right',
                        tdClass: 'text-right',
                        formatter: currencyFormatter
                    }
                ],
                discountFields: [
                    {
                        key: "name",
                        label: ""
                    },
                    {
                        key: "total",
                        label: "",
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        formatter: currencyFormatter
                    }
                ],
                taxFields: [
                    {
                        key: "name",
                        label: ""
                    },
                    {
                        key: "total",
                        label: "",
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        formatter: currencyFormatter
                    }
                ],
                tenderFields: [
                    {
                        key: "name",
                        label: ""
                    },
                    {
                        key: "total",
                        label: "",
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        formatter: currencyFormatter
                    }
                ],
                tokenFields: [
                    {
                        key: "name",
                        label: ""
                    },
                    {
                        key: "total",
                        label: "",
                        tdClass: 'text-right',
                        formatter: (value, key, item) => {
                            //todo I think we need a better way to determine which is the token and which is the token provider.
                            // token provider is always currency
                            // token is alphanumeric
                            if (item.name === 'Token') {
                                return value;
                            }

                            return currencyFormatter(value);
                        }
                    }
                ],
                transactionFields: [
                    {
                        key: "transactionDateTime",
                        label: "Date & Time",
                        formatter: datetimeToLocalTimeFormatter
                    },
                    {
                        key: "location",
                        label: "Location"
                    },
                    {
                        key: "posDevice",
                        label: "POS Device"
                    },
                    {
                        key: "cashier",
                        label: "Cashier"
                    },
                    {
                        key: "transactionType",
                        label: "Trans. Type"
                    },
                    {
                        key: "transactionTotal",
                        label: "Total",
                        thClass: 'text-right',
                        tdClass: 'text-right',
                        formatter: currencyFormatter
                    }
                ],

            };
        },
        computed: {
            transactionNumber() {
                return "Transaction #" + this.transaction?.transactionId;
            },
            totalTaxes() {
                return currencyFormatter(this.transaction && this.transaction.taxes ? this.transaction.taxes.reduce((a, c) => a + parseFloat(c.total), 0) : 0);
            },
            totalDiscounts() {
                return currencyFormatter(this.transaction && this.transaction.discounts ? this.transaction.discounts.reduce((a, c) => a + parseFloat(c.total), 0) : 0);
            },
            totalTenders() {
                return currencyFormatter(this.transaction && this.transaction.tenders ? this.transaction.tenders.reduce((a, c) => a + parseFloat(c.total), 0) : 0);
            },


        },

        methods:
        {
            currencyFormatter: currencyFormatter,
            printReceipt() {
                //todo build this out later.
            }
        }
    };
</script>
<style scoped>
    > > > .card-body {
        min-height: 135px;
    }
</style>
