var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchScreen',{ref:"search",attrs:{"title":"Data Exchange Log","api-destination":"dataexchange","search-destination":"searchlog","filters":_vm.filters,"filterBy":_vm.filterBy,"fields":_vm.fields,"useIsActiveCheckbox":false},scopedSlots:_vm._u([{key:"actionButtons",fn:function(){return [_c('router-link',{staticClass:"btn btn-outline-primary",attrs:{"to":"/dataexchange"}},[_vm._v("Back to Data Exchange")]),_c('b-overlay',{staticClass:"d-inline-block pl-3",attrs:{"show":_vm.isBusy,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('p-button',{attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.exportJobLog()}}},[_vm._v("Export (CSV)")])],1)]},proxy:true},{key:"belowSearch",fn:function(){return [_c('b-form',[_c('b-row',{staticClass:"ml-0 mr-0",attrs:{"cols":"1","cols-sm":"4"}},[_c('b-col',[_c('p-select',{staticClass:"search-filter search-by-select",attrs:{"no-selection-message":"Type (All)","data-type":"jobTypes"},model:{value:(_vm.filters.typeFilter),callback:function ($$v) {_vm.$set(_vm.filters, "typeFilter", $$v)},expression:"filters.typeFilter"}})],1),_c('b-col',[_c('p-select',{staticClass:"search-filter search-by-select",attrs:{"options":_vm.statusFilter},model:{value:(_vm.filters.statusFilter),callback:function ($$v) {_vm.$set(_vm.filters, "statusFilter", $$v)},expression:"filters.statusFilter"}})],1),_c('b-col',[_c('p-select',{staticClass:"search-filter search-by-select",attrs:{"options":_vm.resultFilter},model:{value:(_vm.filters.resultFilter),callback:function ($$v) {_vm.$set(_vm.filters, "resultFilter", $$v)},expression:"filters.resultFilter"}})],1)],1)],1)]},proxy:true},{key:"cell(message)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.result)?_c('div',[_vm._v(" Job executed Successfully ")]):_c('div',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(expand)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',[_c('div',[_c('p-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.expandMessage(item)}}},[_vm._v("Expand")])],1)])]}}])}),_c('p-modal',{attrs:{"name":"JobMessageModal","label":'Job Message (' + this.jobName + ')',"size":"xl"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"container-fluid content-wrapper"},[_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',{staticClass:"messageDetails"},[_c('ul',_vm._l((_vm.errorMessages),function(message){return _c('li',{key:message},[_vm._v(" "+_vm._s(message)+" ")])}),0)])],1)],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }