<template>
    <p-modal
        ref="buyBookSearchModal"
        button-label="Advanced Search"
        size="xl"
        label="Multiple Buyback Items"
        @ok="handleModalOk"
        @show="resetData"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div>
                    <p-card>
                        <b-row>
                            <b-col>
                                <b-input-group>
                                    <b-form-input
                                        autofocus 
                                        ref="searchInput"
                                        type="search"
                                        :placeholder="searchMessageComputed"
                                        debounce="300"
                                        @keydown.enter.prevent=""
                                        v-model="automatic"
                                    />
                                    <p-select
                                        class="ml-2"
                                        :options="filterBy"
                                        value="Author"
                                        v-model="searchBy"
                                    />
                                </b-input-group>
                            </b-col>
                            <b-col cols="2" class="text-right">
                                <p-button
                                    :is-busy="isBusy"
                                    @click="loadData"
                                    variant="primary"
                                    :disabled="disableSearch"
                                    >Search</p-button
                                >
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <p-table class="search-results p-2"
                                    v-if="searchFlag"
                                    :items="buyBookData"
                                    :is-busy="isBusy"
                                    :fields="fields"
                                    :enableRowSelection="true"
                                    :pagination="false"
                                    :per-page="500"
                                    :disable-row-check="
                                        data => {
                                            return (
                                                data.offerPrice <= 0 ||
                                                (!data.isBuyerGuide &&
                                                    data.quantity <= 0)
                                            );
                                        }
                                    "
                                    ref="buyBookSearch"
                                    @input="selectedRowsChanged"
                                    :empty-text="showEmptyText"
                                >
                                    <template v-slot:above-table v-if="resultsLimited">
                                        <p class="h4 text-danger">Results limited to 500. Please refine search criteria.</p>
                                    </template>
                                    <template
                                        v-slot:cell(quantity)="{ value, item }"
                                    >
                                        <div>
                                            <div>
                                                {{ computeQuantity(value) }}
                                            </div>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:cell(offerPrice)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <div
                                            class="price-box"
                                            :disabled="isDisableRow(item)"
                                            @click="addOfferDebounce(item)"
                                        >
                                            <div>{{ value }}</div>

                                            <div>{{ item.location }}</div>
                                        </div>
                                    </template>
                                    <template
                                        v-slot:cell(buybackComment)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <div>{{ item.buybackComment }}</div>
                                    </template>

                                    <template #row-details="row">
                                        <div
                                            v-if="row.item.buybackComment"
                                            class="row-details pt-2"
                                        >
                                            Buyback Notes:
                                            {{ row.item.buybackComment }}
                                        </div>
                                    </template>
                                </p-table>
                            </b-col>
                        </b-row>
                    </p-card>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        variant="outline-primary"
                        @click="handleCancel"
                        class="ml-2"
                        >CANCEL</p-button
                    >
                    <p-button variant="primary" @click="ok" class="ml-2"
                        >ADD ({{ itemCount }}) ITEMS</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import axios from 'axios';
import { currencyFormatter } from '@/components/Common/Formatters.js';
import Vue from 'vue';
import debounce from 'lodash/debounce';
export default {
    props: {
        buybackSessionId: {
            type: [String, Number],
            default: 0
        }
    },
    data() {
        return {
            buyBookData: [],
            selectedRows: [],
            fields: [
                {
                    key: 'isSelected',
                    label: '',
                },
                {
                    key: 'author',
                    label: 'Author',
                },
                {
                    key: 'title',
                    label: 'Title',
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'binding',
                    label: 'Binding',
                },
                {
                    key: 'edition',
                    label: 'Ed.',
                },
                {
                    key: 'copyright',
                    label: 'Copy.',
                },
                {
                    key: 'quantity',
                    label: 'Need #',
                    class: 'text-right'
                },
                {
                    key: 'offerPrice',
                    label: 'Offer',
                    class: 'text-center',
                    formatter: currencyFormatter
                }
            ],
            automatic: '',
            searchBy: 'Author',
            filterBy: ['Author', 'Title', 'ISBN', 'Barcode', 'Bookkey'],
            searchFlag: false,
            maxResults: 500,
            isBusy : false,
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    components: {},
    computed: {
        itemCount() {
            return this.selectedRows.length;
        },
        searchMessageComputed() {
            let allScans = ['SKU','ISBN','Barcode','Scan Code'];
            let scanStr = allScans.some(x=>x.toLowerCase()==this.searchBy.toLowerCase());
            return (scanStr?'Scan/Search by ':'Search by ') + this.searchBy;
        },
        disableSearch() {
            return this.automatic.trim().length <= 0;
        },
        showEmptyText() {
            return this.searchFlag ? 'Book not found' : '';
        },
        resultsLimited(){
            return this.buyBookData.length === this.maxResults;
        }
    },
    methods: {
        async addOffer(item) {
            if (!this.isBusy) {
                this.isBusy = true;
                this.$emit('load-data', [item]);
                this.$refs.buyBookSearchModal.hide();
                this.isBusy = false;
            }
        },
        isDisableRow(item) {
            return (
                item.offerPrice <= 0 ||
                (!item.isBuyerGuide && item.quantity <= 0)
            );
        },
        async handleModalOk() {
            this.$emit('load-data', this.selectedRows);
            this.$refs.buyBookSearchModal.hide();
        },
        selectedRowsChanged(items) {
            this.selectedRows = items;
        },
        handleCancel() {
            this.$refs.buyBookSearchModal.hide();
        },
        computeQuantity(val) {
            return val == -1 ? 'Unlimited' : val;
        },
        loadData() {
            this.selectedRows = [];
            this.isBusy = true;
            let promise = axios.get('buyback/searchbooks', {
                params: {
                    buybackSessionId: this.buybackSessionId,
                    automatic: this.automatic,
                    searchBy: this.searchBy,
                    maxResults: this.maxResults
                }
            });
            promise.then(resp => {
                this.searchFlag = true;
                this.buyBookData = resp.data;
                if(this.resultsLimited){
                    this.$refs.searchInput.focus();
                }
                this.buyBookData.forEach(x => Vue.set(x, '_showDetails', true));
            })
            .finally(() => {
                this.isBusy = false;
            });
        },
        resetData() {
            this.automatic = '';
            this.buyBookData = [];
            this.selectedRows = [];
            this.searchFlag = false;
        }
        },
     mounted: function () {
            this.addOfferDebounce = debounce(this.addOffer, 300);
        },

};
</script><style scoped lang="scss">
@import "@/styles/app/common/variables.scss";

.row-details {
    margin: 0% 2% 0% 9%;
    color: red;
}
.price-box {
    border: 1px solid;
    background-color: $blue;
    color: #fff;
    cursor: pointer;
}
.price-box[disabled] {
    pointer-events: none;
    opacity: 0.3;
}
.search-results {
    max-height: 640px;
    overflow-x: hidden;
}
</style>
