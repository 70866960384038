<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="group">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Web Catalog Group</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary"
                        >Save</b-button
                    >
                </b-col>
            </b-row>

            <p-card>
                <b-row >
                    <b-col cols="12" sm="9">
                        <p-input
                            label="Name"
                            :disabled="group.isSystemLevel"
                            v-model="group.name"
                            rules="required|max:255"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Status"
                            :disabled="group.isSystemLevel"
                            dataType="isActiveOptions"
                            v-model="group.isActive"
                        />
                    </b-col>
                </b-row>
                <b-row >
                    <b-col cols="12">
                        <p-input
                            label="Description"
                            :disabled="group.isSystemLevel"
                            v-model="group.description"
                            rules="max:2000"
                        />
                    </b-col>
                </b-row>
                <b-row >
                    <b-col>
                        <p-card class="mb-2 px-2 py-2" no-body>
                            <p-filter-select v-model="group.catalogIds"
                                             label="Catalogs"
                                             stacked
                                             tall-list
                                             :clear-button="false"
                                             :initial-display-count="18"
                                             data-type="webCatalogs" />
                        </p-card>
                    </b-col>
                </b-row>
            </p-card>
            <b-row class="mt-3">
                <b-col>
                <p-button
                    v-if="!isNew"
                    variant="outline-danger"
                    @click="deleteGroup"
                    :disabled="group.isSystemLevel"
                    :is-busy="isBusy"
                    >Delete</p-button>
                </b-col>
                <b-col cols="auto" class="ml-auto pr-0">
                        <p-button
                            variant="outline-primary"
                            @click="cancelEdit"
                            :is-busy="isBusy"
                            >Cancel</p-button>
                </b-col>
                <b-col cols="auto">
                        <p-button
                            variant="primary"
                            type="submit"
                            :is-busy="isBusy"
                            >Save</p-button>
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js'
import editPage from '@/components/mixins/EditPage';

export default {
    mixins: [NavigationGuard, editPage],
    data() {
        return {
            group: null,
            isBusy: false,
        };
    },
    watch: {
        group: NavigationGuard.$watcher
    },
    methods: {
        onSubmit() {
            if(!this.isBusy)
            {
                this.isBusy = true;
                axios.post("catalogs/groups", this.group)
                    .then((response) => {
                        this.dataSaved();
                        if (this.isNew) {
                            this.$router.push("/webcataloggroups/" + response.data);
                        }
                        this.$toasted.global.app_success(`Cagetory Group '${this.group.name}' saved successfully.`).goAway(5000);
                    }).finally(() => {
                        this.isBusy = false;
                    });
                    // todo error handling
            }
        },
        deleteGroup() {
            if(this.group.isActive){
                this.$toasted.global
                    .app_error('Catalog group must be inactive to be removed.')
                    .goAway(5000);
                return;
            }

            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this catalog group?')
                .then(value => {
                    if (value) {
                        this.isBusy = true;
                        axios
                            .post('catalogs/deletegroup', this.group)
                            .then(resp => {
                                if (resp) {
                                    this.dataSaved();
                                    this.$router.push('/webcataloggroups');
                                }
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    }
                });
        },

        cancelEdit() {
            this.$router.push('/webcataloggroups');
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('catalogs/groups/new')
                : axios.get('catalogs/groups', { params: { id: this.id } });

            promise.then(resp => {
                this.group = resp.data;
                if (!this.isNew) {
                         this.dataLoaded();
                    }
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
