<template>
    <p-modal
        :button-label="buttonLabel"
        size="lg"
        :label="pageTitle"
        ref="addRentals"
        @ok.prevent="handleModalOk"
        :button-variant="buttonVariant"
        :disable-button="disabled"
        @shown="assignRentalData"
    >
        <template v-slot:default>
            <p-form ref="form">
                <b-row>
                    <b-col cols="6">
                        <p-select
                            :options="rentalOptions"
                            label="Rental Source"
                            v-model="rentalsData.rentalSource"
                            :disabled="
                                rentalsData.newRentalItemPeriodId != null ||
                                    rentalsData.usedRentalItemPeriodId != null
                            "
                        />
                    </b-col>
                    <b-col cols="6">
                        <b-col>
                            <p-number
                                label="Rebate Price"
                                input-type="money"
                                v-model="rentalsData.rebatePrice"
                                rules="min_value:0"
                            >
                            </p-number>
                        </b-col>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div><b>Author/Title/ISBN</b></div>
                        <div class="mb-3">{{ rentalsData.author + ' / ' + rentalsData.title + ' / ' + rentalsData.isbn}}</div>                        
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-table
                            :items="[rentalsData]"
                            :fields="rentalFields"
                            :enableRowSelection="false"
                            :showRecordCount="false"
                        >
                            <template
                                v-slot:cell(bookVariant)="{
                                    value,
                                    item
                                }"
                            >
                                <div class="mt-2 col-2">
                                    New
                                </div>
                                <div
                                    class="mt-4 col-2"
                                    v-if="item.usedSku != null"
                                >
                                    Used
                                </div>
                            </template>
                            <template
                                v-slot:cell(newRentalPrice)="{
                                    value,
                                    item
                                }"
                            >
                                <p-number
                                    input-type="money"
                                    v-model="item.newRentalPrice"
                                    :rules="isRequired + '|min_value:0'"
                                    class="col-8 mr-0 pr-0 pl-0"
                                    name="New Rental Price"
                                />
                                <p-number
                                    input-type="money"
                                    v-model="item.usedRentalPrice"
                                    :rules="isRequired + '|min_value:0'"
                                    class="col-8 mr-0 pr-0 pl-0"
                                    name="Used Rental Price"
                                    v-if="item.usedSku != null"
                                />
                            </template>
                            <template
                                v-slot:cell(newPrice)="{
                                    value,
                                    item
                                }"
                            >
                                <div class="mt-2 col-2 pl-0">
                                    {{ currencyFormatter(item.newPrice) }}
                                </div>
                                <div
                                    class="mt-3 pt-3 col-2 pl-0"
                                    v-if="item.usedSku != null"
                                >
                                    {{ currencyFormatter(item.usedPrice) }}
                                </div>
                            </template>
                            <template
                                v-slot:cell(newCost)="{
                                    value,
                                    item
                                }"
                            >
                                <div class="mt-2 col-2 pl-0">
                                    {{ currencyFormatter(item.newCost) }}
                                </div>
                                <div
                                    class="mt-3 pt-3 col-2 pl-0"
                                    v-if="item.usedSku != null"
                                >
                                    {{ currencyFormatter(item.usedCost) }}
                                </div>
                            </template>
                            <template
                                v-slot:cell(newIsActive)="{
                                    value,
                                    item
                                }"
                            >
                                <p-checkbox
                                    :use-switch="true"
                                    v-model="item.newIsActive"
                                    class="text-right mt-1 col-2 pl-0"
                                    size="lg"
                                >
                                </p-checkbox>
                                <p-checkbox
                                    :use-switch="true"
                                    v-model="item.usedIsActive"
                                    class="text-right mt-3 pt-3 col-2 pl-0"
                                    size="lg"
                                    v-if="item.usedSku != null"
                                >
                                </p-checkbox>
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        :disabled="rentalsData.rentalSource == 'None'"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { currencyFormatter } from '@/components/Common/Formatters.js';
export default {
    props: {
        rentals: {
            type: Object,
            default: () => null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    created: function() {
        this.rentalsData = JSON.parse(JSON.stringify(this.rentals));
    },
    data() {
        return {
            isBusy: false,
            rentalsData: [],
            rentalOptions: [
                { value: 'Store Managed', text: 'Store Managed' },
                { value: 'None', text: 'None' }
            ],
            rentalFields: [
                {
                    label: '',
                    key: 'bookVariant'
                },
                {
                    key: 'newRentalPrice',
                    label: 'Rental Price',
                    formatter: currencyFormatter
                },
                {
                    key: 'newPrice',
                    label: 'Price',
                    formatter: currencyFormatter
                },
                {
                    key: 'newCost',
                    label: 'Cost',
                    formatter: currencyFormatter
                },
                {
                    key: 'newIsActive',
                    label: 'Status'
                }
            ]
        };
    },
    computed: {
        buttonLabel() {
            return 'Edit';
        },
        pageTitle() {
            return 'Edit Rentals';
        },
        buttonVariant() {
            return 'outline-primary';
        },
        isRequired() {
            return this.rentalsData.newIsActive || this.rentalsData.usedIsActive
                ? 'required'
                : '';
        }
    },
    methods: {
        currencyFormatter: currencyFormatter,
        assignRentalData(){
            this.rentalsData = JSON.parse(JSON.stringify(this.rentals));
        },
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            axios.post('rentals/saverentals', this.rentalsData).then(() => {
                this.$emit('data-saved');
                this.$refs.addRentals.hide();
                this.$toasted.global
                    .app_success(`Rental saved successfully.`)
                    .goAway(5000);
            });

            
        }
    }
};
</script>
