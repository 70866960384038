<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <div class="container-fluid content-wrapper" >
            <b-row class="content-heading">
                <b-col>
                    <div>Tax Rates</div>
                </b-col>
            </b-row>
            <b-overlay
                :show="!taxData"
                rounded
                opacity="0.5"
                spinner-variant="primary"
                class="mt-5"
            ></b-overlay>
            <b-tabs justified v-if="taxData">
                <b-tab title="Tax Rates">
                    <b-row>
                        <b-col>
                            <editRate
                                class="pb-2 ml-3 mr-2"
                                tax-rate-id="new"
                                @data-saved="loadData"
                            />
                        </b-col>
                    </b-row>
                    <b-card>
                        <p-table
                            :fields="fields"
                            :items="taxData.taxRates"
                            :selectable="false"
                            :enable-row-selection="false"
                            :is-busy="isBusy"
                            sort-by="name"
                            style='white-space: pre-line;'
                            :show-per-page-selector="true"
                            :per-page="filters.perPage"
                            :show-top-pagination="true"
                        >
                            <!-- <template v-slot:cell(edit)="{ value, item }">
                                <editRate
                                    class="pb-2 ml-3 mr-2"
                                    :tax-rate-id="item.taxCodeId"
                                    @data-saved="loadData"
                                />
                            </template> -->
                        </p-table>
                    </b-card>
                </b-tab>
                <p-feature-flag name="Tax Nexus">
                <b-tab title="Tax Nexus">
                    <b-row>
                        <b-col>
                            <addTaxNexus
                                class="pb-2 ml-3 mr-2"
                                @data-saved="loadNexusData"
                                :excluded-states="stateIds"
                                :warning-message="AUTO_UPDATE_WARNING"
                            />
                        </b-col>
                    </b-row>
                    <b-card>
                        <p-table
                            :fields="nexusFields"
                            :items="this.taxNexus"
                            ref="taxNexusSearch"
                            :selectable="false"
                            :enable-row-selection="false"
                            :is-busy="isBusy"
                            sort-by="stateName"
                        >
                            <template
                                v-slot:cell(isActive)="{ item }"
                            >
                                <div class="extra-padding d-inline-block">
                                    <span class="v-center">
                                        <p-checkbox
                                            :use-switch="true"
                                            v-model="item.isActive"
                                            size="lg"
                                            class="d-inline-block"
                                            @change="updateItemShippingTaxCollected(item)"
                                        />
                                        <span class="mt-2">
                                            {{ item.isActive ? 'Active' : 'Inactive'}}
                                        </span>
                                    </span>
                                </div>
                            </template>
                            <template
                                v-slot:cell(autoUpdate)="{ item }"
                            >
                                <div class="extra-padding">
                                    <span class="v-center">
                                        <p-checkbox
                                            :use-switch="true"
                                            :value="item.autoUpdate"
                                            size="lg"
                                            @change="updateItemAutoUpdate(item)"
                                        />
                                        <span class="mt-2">
                                            {{ item.autoUpdate ? 'Active' : 'Inactive'}}
                                        </span>
                                    </span>
                                </div>
                            </template>
                                <template
                                    v-slot:cell(isShippingTaxCollected)="{ item }"
                                >
                                        <div class="extra-padding">
                                            <span class="v-center">
                                                <p-checkbox
                                                    :use-switch="true"
                                                    v-model="item.isShippingTaxCollected"
                                                    size="lg"
                                                    @change="saveUpdates"
                                                />
                                                <span class="mt-2">
                                                    {{ item.isShippingTaxCollected ? 'Active' : 'Inactive'}}
                                                </span>
                                            </span>
                                        </div>
                                </template>
                            <template v-slot:cell(remove)="{ item }">
                                <p-button
                                    variant="outline-danger"
                                    @click="removeNexus(item)"
                                >
                                    <BIconTrash
                                        icon="trash"
                                        @click="removeNexus(item)"
                                        class="mr-2"
                                    ></BIconTrash>
                                    Remove
                                </p-button>
                            </template>
                        </p-table>
                    </b-card>
                </b-tab>
                </p-feature-flag>
            </b-tabs>
        </div>
    </p-secure>
</template>
<script>
import axios from 'axios';
import editRate from '@/views/Taxes/EditTaxRate.vue';
import { percentFormatter } from '@/components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import addTaxNexus from '@/views/Taxes/AddTaxNexus.vue';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

const AUTO_UPDATE_WARNING = 'Notice: Upon enabling this convenience feature, tax tables will be updated with publicly available sales tax rates as aggregated by a third party. PrismRBS does not independently validate this third-party data. You are responsible for confirming your own tax table information.'

export default {
    mixins: [NavigationGuard, SearchStorage],
    components: {
        editRate,
        addTaxNexus
    },
    props: {
        id: String
    },
    data() {
        return {
            taxData: null,
            isBusy: false,
            appendTaxRateLabel: '\nTax Rate',
            filterName: 'TaxesFilter',
            filters: {
                automatic: "",
                perPage: 10,
                sortBy: 'name',
                sortDesc: true,
                searchBy:'All'
            },
            taxNexus: null,
            AUTO_UPDATE_WARNING
        };
    },
    computed: {
        fields() {
            return [
                // {
                //     key: 'edit',
                //     label: '',
                //     class: 'tcw100'
                // },
                {
                    key: 'taxType',
                    label: 'Tax Type',
                    automaticSearch: true,
                    class: 'tcw100'
                },
                {
                    key: 'locality',
                    label: 'Locality',
                    automaticSearch: true,
                    class: 'tcw100'
                },
                {
                    key: 'city',
                    label: 'City',
                    class: 'tcw100'
                },
                {
                    key: 'cityTaxRate',
                    label: 'City Tax Rate',
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: value => {
                        return this.percentFormatter(value, 3)
                    }
                },
                {
                    key: 'stateTaxRate',
                    label: 'State Tax Rate',
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: value => {
                        return this.percentFormatter(value, 3)
                    }
                },
                {
                    key: 'countyTaxRate',
                    label: 'County Tax Rate',
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: value => {
                        return this.percentFormatter(value, 3)
                    }
                },
                {
                    key: 'specialTaxRate',
                    label: 'Special Tax Rate',
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: value => {
                        return this.percentFormatter(value, 3)
                    }
                },
                {
                    key: 'total',
                    label: 'Total' + this.appendTaxRateLabel,
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: (value, key, item) => {
                        var total = item.cityTaxRate + item.stateTaxRate + item.countyTaxRate + item.specialTaxRate;
                        return this.percentFormatter(total, 3)
                    }
                },
                {
                    key: 'taxNexus',
                    label: 'Tax Nexus',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    automaticSearch: true
                },
                {
                    key: 'source'
                }
            ];
        },
        nexusFields() {
            return [
                {
                    key: 'name',
                    label: 'Nexus Name'
                },
                {
                    key: 'abbreviation',
                    label: 'Abbreviation'
                },
                {
                    key: 'isActive',
                    label: 'Nexus Status',
                },
                {
                    key: 'autoUpdate',
                    label: 'Auto Update Status'
                },
                {
                    key: 'isShippingTaxCollected',
                    label: 'Collect Shipping Tax'
                },
                {
                    key: 'remove',
                    label: ''
                }
            ];
        },
        stateIds() {
            return this.taxNexus.map(x => x.stateId);
        }
    },
    watch: {
        taxNexus : NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        percentFormatter: percentFormatter,
        async loadData() {
            this.isBusy = true;
            let locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            Promise
                .all([
                    axios.get('taxes/taxrates', { params: { locationId: locationId } }),
                    axios.get('taxes/taxnexuslist')
                ])
                .then((resp) => {
                    this.taxData = resp[0].data;
                    this.taxNexus = resp[1].data;
                    this.dataLoaded();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        loadNexusData() {
            axios.get('taxes/taxnexuslist')
                .then((resp) => {
                    this.taxNexus = resp.data;
                    this.dataLoaded();
                });
        },
        async removeNexus(item) {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to remove this tax nexus?')
                .then(value => {
                    if (value) {
                        this.isBusy = true;
                        axios.post(
                            'taxes/deletetaxnexus',
                            { taxNexusId: item.taxNexusId }
                        )
                        .then((resp) => {
                            if (resp) {
                                this.loadNexusData();
                                this.$toasted.global.app_success(
                                    'Tax Nexus removed successfully.'
                                )
                                .goAway(5000);
                            }
                        })
                        .finally(() => {
                            this.isBusy = false;
                        });
                    }
                });
        },
        updateItemAutoUpdate(item) {
            this.isBusy = true;
            item.autoUpdate = !item.autoUpdate;
            if (item.autoUpdate === true) {
                this.$bvModal
                    .msgBoxConfirm(
                            AUTO_UPDATE_WARNING,
                            {
                                okTitle: 'Accept'
                            }
                        )
                    .then(value => {
                        if (value) {
                            this.saveUpdates();
                        } else {
                            item.autoUpdate = false;
                            this.dataLoaded();
                        }
                    });
            }
            else {
                this.saveUpdates();
            }
            this.isBusy = false;
        },
        updateItemShippingTaxCollected(item){
            if(!item.isActive) item.isShippingTaxCollected = false;
            this.saveUpdates();
        },
        saveUpdates() {
            this.isBusy = true;
            axios
                .post('taxes/taxnexus',
                    {
                        commands: this.taxNexus
                    }
                )
                .then(() => {
                    this.$toasted.global.app_success(
                        'Tax Nexus updated successfully.'
                    )
                    .goAway(5000);
                    this.dataSaved();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },
    Components: {
        percentFormatter
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
<style scoped>
/deep/ .tcw100 {
    width: 100px;
}
.extra-padding {
    padding-right: 15px;
}
.v-center{
    display: flex;
    align-items: center;
}
</style>