<template>
    <div>
        <ProductMultiSelectQuickSearch
            :label="buttonLabel"
            icon-plus
            show-empty
            empty-text="All products from the purchase order have been fully invoiced."
            field-set="invoiceFields"
            :items="computedItems"
            v-on="$listeners"
            v-bind="$attrs"
            search-destination="purchaseordersearch"
            :show-content="showContent"
            :additional-filters="filters"
        >
        
            <template v-if="!vendorId" v-slot:abovetable>
                Please select a supplier before selecting items to add.    
            </template> 
            <template v-else v-slot:additionalFilters="slotProps">
                <p-advanced-select
                    label="Select Purchase Order to add items from"
                    @change="purchaseOrderChange(slotProps.filters.purchaseOrderId)"
                    :options="filteredPurchaseOrders"
                    v-if="!purchaseOrderId"
                    v-model="slotProps.filters.purchaseOrderId"
                >
                    <template v-slot:headerSlot>
                        <p-checkbox
                            v-model="includeFullyInvoicedPurchaseOrders"
                            @change="purchaseOrderChange(slotProps.filters.purchaseOrderId)"
                            compact-format
                            label="Show fully invoiced purchase orders"
                        ></p-checkbox>
                        <p-checkbox
                            v-model="filterByVendor"
                            compact-format
                            :label="
                                `Limit purchase orders to current vendor (${vendorName})`
                            "
                        ></p-checkbox>
                    </template>
                    <template v-slot:optionText="optionProps">
                       {{optionProps.option.text}} {{optionProps.option.isFullyInvoiced ? '(fully invoiced)' : ''}}
                    </template>
                </p-advanced-select>
                
                <p-checkbox
                    v-show="showContent"
                    v-model="slotProps.filters.includeFullyInvoicedProducts"
                    label="Show fully invoiced products"
                    @input='$emit("update:includeFullyInvoicedProducts", $event)'
                    class="pb-3"
                ></p-checkbox>
            </template>
        </ProductMultiSelectQuickSearch>
    </div>
</template>

<script>
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import axios from 'axios';
export default {
    inheritAttrs: false,
    mixins: [StoreListManager],
    props: {
        items: {
            type: Array,
            default: () => []
        },
        existingInvoiceDetails: {
            type: Array,
            default: () => []
        },
        vendorId: {
            type: Number,
            default: null
        },
        purchaseOrderId: {
            type: Number,
            default: null
        },
        invoiceId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            buttonLabel: 'Add Product(s)',
            showContent: false,
            purchaseOrderOptions: [],
            includeFullyInvoicedPurchaseOrders: false,
            filterByVendor: true,
            selectListOptions: {
                suppliers: []
            }
        };
    },
    watch: {
        vendorId: {
            handler: function() {
                this.showContent = false;
            }
        }
    },
    components: { ProductMultiSelectQuickSearch },
    computed: {
        vendorName() {
            return (
                this.selectListOptions.suppliers.find(
                    x => x.value === this.vendorId
                )?.text || ''
            );
        },
        filters() {
            return { 
                    excludedPurchaseOrderDetailIds: this.existingInvoiceDetails.map(x => x.purchaseOrderDetailId) 
                };
        },
        singlePurchaseOrderMode() {
            return this.purchaseOrderId != null;
        },
        computedItems() {
            return this.singlePurchaseOrderMode ? this.items : null;
        },
        filteredPurchaseOrders() {
            var filtered = this.purchaseOrderOptions;
            if (this.filterByVendor) {
                filtered = filtered.filter(x => x.vendorId === this.vendorId);
            }
            if (!this.includeFullyInvoicedPurchaseOrders) {
                filtered = filtered.filter(x => !x.isFullyInvoiced);
            }
            return filtered;
        }
    },
    methods: {
        purchaseOrderChange: function(value) {
            this.showContent = value != null;
        }
    },
    created: function() {
        this.showContent = this.singlePurchaseOrderMode;
        axios
            .get('invoices/purchaseOrders', {
                params: { vendorId: this.vendorId }
            })
            .then(resp => {
                this.purchaseOrderOptions = resp.data.map(x => ({
                    text: x.poNumber,
                    value: x.purchaseOrderId,
                    ...x
                }));
            })
            .finally(() => {
                this.isBusy = false;
            });
    }
};
</script>
