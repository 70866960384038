<template>
    <p-modal
        name="cashierReconciliationModal"
        label="Cashier Reconciliation"
        ref="cashierReconciliation"
        size="lg"
        @ok.prevent="handleModalOk"
    >
        <template v-slot:default>
            <p-form ref="cashierReconciliationForm">
                <div class="container-fluid content-wrapper">
                    <b-row>
                        <b-col>
                            <p>Sales by POS device by cashier</p>
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-lg="2">
                        <b-col>
                            <p-card title="Cashier(s)">
                                <p-filter-select
                                    data-type="nbcUsers"
                                    rules="required"
                                    :default-selected="true"
                                    :initial-display-count="99999"
                                    v-model="reportParameters.nbcUserIds"
                                />
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card title="Date Range">
                                <p-date-range :date-range-options="reportParameters" />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="cancel"
                        >Cancel</p-button
                    >
                    <p-button
                        variant="primary"
                        @click="ok"
                        :is-busy="isBusy"
                        class="ml-2"
                        >OK</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import pCard from '@/components/p-card.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    components: { pCard },
    data() {
        return {
            isBusy: false,
            disabled: true,
            reportParameters: {
                nbcUserIds: [],
                startDate: null,
                endDate: null,
                dateOption: 0,
                locationId: null
            }
        };
    },
    computed: {
        pageTitle() {
            return 'Cashier Reconciliation';
        }
    },
    methods: {
        async show() {
            this.reportParameters.nbcUserIds = [];
            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.$refs.cashierReconciliation.show();
        },
        handleModalOk: async function() {
            if (!(await this.$refs.cashierReconciliationForm.validate())) {
                return;
            }

            if (this.reportParameters.nbcUserIds.length === 0) {
                this.$toasted.global.app_error(
                    'Please select one or more Pos Devices.'
                );
                return;
            }

            this.isBusy = true;

            await axios
                .post(
                    '/sales/exportCashierReconciliation',
                    this.reportParameters,
                    {
                        responseType: 'blob'
                    }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Cashier Reconciliation',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            `Cashier reconciliation report generated successfully.`
                        )
                        .goAway(5000);
                    this.$refs.cashierReconciliation.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>

<style scoped>
.static-height {
    height:370px
}
</style>
