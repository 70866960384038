import { pDecimalValidationTest} from '@/components/mixins/ValidatedInput.js';
import { max, email } from 'vee-validate/dist/rules';
import {dateFormatter} from "@/components/Common/Formatters.js";

export default {
    data() {
        return {
            giftCardConfiguration: {
                type: 'giftcards',
                label: 'Gift Cards',
                importModes: [
                    {value:'add', text:'Add New (Skip when card code already present)'},
                    {value:'update', text:'Update (Skip when card code not present, update when found)'},
                    {value:'addAndUpdate', text:'Add and Update'}
                ],
                sampleNotes: [
                    '"Dates are expected to be in the format YYYY-MM-DD."',
                    '"If customer account number is provided, that customer\'s data will be associated with the gift card and any provided phone or email will be ignored."'
                ],
                fields: [
                    {
                        key: 'CardCode',

                        hasErrorCalculator: value => {
                            return (
                                this.isEmpty(value) || !max.validate(value, { length: 52 })
                            );
                        },
                        rules: 'required|max:52',
                        samples: ['"F75D-4C8B-8812"', '"325F-4A68-B634"', '"564D-4A64-A307"']
                    },
                    {
                        key: 'PIN',
                        hasErrorCalculator: value => {
                            return (
                                !this.isEmpty(value) && !max.validate(value, { length: 4 })
                            );
                        },
                        rules: 'max:4',
                        samples: ['"2F82"', '"EC27"', '"A26D"']
                    },
                    {
                        key: 'Status',
                        hasErrorCalculator: value => {
                            return (
                                !this.isEmpty(value) &&
                                !this.matchesExistingObject(value)
                            );
                        },
                        dataType: 'isActiveOptions',
                        rules: 'min_value:0',
                        samples: ['"Active"', '"Active"', '"Inactive"']
                    },
                    {
                        key: 'CardComment',
                        hasErrorCalculator: value => {
                            return (
                                !this.isEmpty(value) &&
                                !max.validate(value, { length: 2000 })
                            );
                        },
                        rules: {
                            max: {length: 2000}
                        },
                        samples: ['""','"Spend 100 Get 10 Promo"','"Reissue for lost card"']
                    },
                    {
                        key: 'ExpirationDate',
                        hasErrorCalculator: value => {
                            return !this.isValidDate(value);
                        },
                                      
                        formatter: dateFormatter,
                        rules: {
                            'valid-date': {
                                isValidDate: this.isValidDate,
                                propertyName: 'ExpirationDate'
                            }
                        },
                        inputType: 'date',
                        addRowDataToRule: 'valid-date',
                        samples: [
                            '"2030-10-09"',
                            '"2030-10-09"',
                            '"2028-10-05"'
                        ]
                    },
                    {
                        key: 'Balance',
                        hasErrorCalculator: value => {
                            return (
                                value &&
                                !pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                })
                            );
                        },
                        rules: 'p-decimal:10,2',
                        convertToNumber: true,
                        samples: ['0', '75.00', '34.71']
                    }, 
                    {
                        key: 'CustomerAccountNumber',

                        hasErrorCalculator: value => {
                            return (
                                !this.isEmpty(value) &&
                                !this.matchesExistingObject(value, true)
                            );
                        },
                        rules: 'min_value:0',

                        dataType: 'customers',
                        comparisonProperty: 'number',
                        samples: ['"0045172"', '"0045173"', '']
                    },

                    {
                        key: 'Email',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return (
                                !email.validate(value) ||
                                !max.validate(value, { length: 254 })
                            );
                        },
                        rules: 'email|max:254',
                        samples: ['""','""', '"lg@mail.com"']
                    },

                    {
                        key: 'Phone',
                        hasErrorCalculator: value => {
                            return !max.validate(value, { length: 26 });
                        },
                        rules: 'max:26',
                        samples: ['""','""', '"1-555-789-3333"']
                    }                   
                ]
            }
        };
    },
    methods: {}
};
