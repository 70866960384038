<template>
    <p-form>
        <div>
            <p-card>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col class="d-flex mb-3">
                                 <GMSerializationModal
                                    :product-family="productFamily"
                                    @ok="saveSerialNumbers"
                                ></GMSerializationModal>
                                 <p-button
                                    variant="outline-primary"
                                    class="ml-2"
                                    :disabled="true"
                                    >Print Labels</p-button
                                >
                                <b-dropdown
                                    class="ml-2"
                                    text="Exports"
                                    variant="outline-primary"
                                    :disabled="true"
                                >
                                    <b-dropdown-item v-b-modal.outstandingCreditRequestModal
                                        >CSV</b-dropdown-item
                                    >
                                    <b-dropdown-item v-b-modal.receivedNotYetInvoicedModal
                                        >PDF</b-dropdown-item
                                    >
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <p-table
                            ref="serializationTable"
                            :items="filteredItems"
                            :fields="fieldsSerialization"
                            sort-by="serialNumber"
                        >
                            <template v-slot:above-table>
                                <b-row class=" my-2">
                                    <b-col md="3" class="mr-md-auto">
                                        <p-select
                                            label='Sold / Unsold Filter'
                                            text-field='value'
                                            :options='soldUnsoldOptions'
                                            v-model='filters.soldUnsold'
                                        />
                                    </b-col>
                                    <b-col md="3" class="ml-md-auto">
                                        <p-datepicker
                                            label="Sold Date: From"
                                            vid="digitalTransactionStartDate"
                                            v-model="filters.soldStartDate"
                                        />
                                    </b-col>
                                    <b-col md="3" class="ml-2">
                                        <p-datepicker
                                            label="Sold Date: To"
                                            vid="digitalTransactionEndDate"
                                            v-model="filters.soldEndDate"
                                            rules="dateGreaterOrEqual:@digitalTransactionStartDate"
                                            is-end-date
                                        />
                                    </b-col>
                                </b-row>
                            </template>
                             <template
                                v-slot:cell(isDefault)="{ value, item }"
                            >
                                <b-button
                                    class="p-1 ml-1"
                                    @click="editSerialNumber(item)"
                                >Edit
                                    
                                </b-button>
                            </template>

                        <template
                                v-slot:cell(removeDetail)="{ value, item }"
                            >
                                <b-button
                                    class="p-1 ml-1 short-button"
                                    @click="deleteSerialNumber(item)"
                                >
                                    <BIconTrash
                                        font-scale="1.1"
                                        class="mb-3"
                                        icon="trash"
                                    ></BIconTrash>
                                </b-button>
                            </template>
                            <template
                                v-slot:cell(customerNumber)="{ value, item }"
                            >
                                <div v-if="item.customerNumber != null && item.customerNumber != ''">
                                    <router-link :to="`/customers/${item.customerId}`">
                                        <nobr> {{item.customerNumber}}</nobr>
                                    </router-link>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </template>

                        </p-table>
                    </b-col>
                </b-row>
        <p-modal
            size="md"
            name="editSerialModal"
            label="Edit Serial Number"
            ref="editSerialModal"
            @shown="showModal"
        >
        <template v-slot:default>
             <p-form ref="form">
            <b-row>
               <b-col class="d-flex  align-items-center" >                     
                        <p-input
                        label="Serial Number"
                        ref="serialNumber"
                        rules="required|max:25"
                        class="w-100"
                        placeholder="Scan or type your serial number (ex: HH07222022)"
                        v-model="updatedSerialNumber"
                    />
               </b-col>
            </b-row>
             </p-form>
        </template>
        <template v-slot:modal-footer="{cancel }">
            <b-row>
                    <p-button
                        variant="outline-primary"
                        @click="cancel"
                    >
                        Cancel
                    </p-button>                    

                    <p-button
                        ref="btnSave"
                        variant="primary"
                        @click="getUpdateSerialNumber"
                        class="ml-3"
                        >OK </p-button
                    >
            </b-row>
        </template>
</p-modal>
            </p-card>
        </div>
    </p-form>
</template>
<script>
import { dateFormatter, datetimeToLocalTimeFormatter, dashIfNullFormatter } from '../../../components/Common/Formatters.js';
import GMSerializationModal from './GMSerializationModal';
export default {
    components: {GMSerializationModal},
    props: {
        productFamily: Object
    },
    data() {
        return {
            updatedSerialNumber: '',
            serialIndex: 0,
            filters:{
                soldStartDate:null,
                soldEndDate:null,
                soldUnsold:'Show All'
            },
            soldUnsoldOptions:[{ value: 'Show All' }, { value: 'Sold' }, { value: 'Unsold' }],
            fieldsSerialization: [
                {
                    key: 'isDefault',
                    label: '',
                    sortable: false
                },
                {
                    key: 'serialNumber',
                    label: 'Serial Number',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'transactionName',
                    label: 'Transaction',
                    formatter: (value) => dashIfNullFormatter(value),
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'dateAndTimeSold',
                    label: 'Date/Time Sold',
                    formatter: (value) => value ? datetimeToLocalTimeFormatter(value) : '-',
                    sortable: true,
                    automaticSearch: true
                },
                 {
                    key: 'lastName',
                    label: 'Last Name',
                    formatter: (value) => dashIfNullFormatter(value),
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'firstName',
                    label: 'First Name',
                    formatter: (value) => dashIfNullFormatter(value),
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'customerNumber',
                    label: 'Customer Account',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'removeDetail',
                    label: ''
                }
            ],
        };
    },
    computed: {
        filteredItems(){
            let filterData;
            switch(this.filters.soldUnsold){
                case 'Show All':
                   filterData = this.productFamily.serialNumbers;
                   break
                case 'Sold':
                   filterData = this.productFamily.serialNumbers.filter(x=>x.isSold===true);
                   break
                case 'Unsold':
                   filterData = this.productFamily.serialNumbers.filter(x=>x.isSold===false);
                   break
            }

            if(this.filters.soldStartDate){
                filterData = filterData.filter(x=>dateFormatter(x.dateAndTimeSold)>=dateFormatter(this.filters.soldStartDate));
            }
            if(this.filters.soldEndDate){
                filterData = filterData.filter(x=>dateFormatter(x.dateAndTimeSold)<=dateFormatter(this.filters.soldEndDate));
            }
            return filterData;
        }
    },
    methods: {
        saveSerialNumbers(serialNumbers){
            this.productFamily.serialNumbers = [...this.productFamily.serialNumbers, ...serialNumbers];
        },
        deleteSerialNumber(item){
            var index = this.productFamily.serialNumbers.findIndex(x => x.serialNumber === item.serialNumber);
            this.productFamily.serialNumbers.splice(index, 1);
        },
        editSerialNumber(item){
             this.serialIndex = this.productFamily.serialNumbers.findIndex(x => x.serialNumber === item.serialNumber);
            this.updatedSerialNumber= item.serialNumber
            this.$refs['editSerialModal'].show();

        },
        async getUpdateSerialNumber(){
             if (!(await this.$refs.form.validate())) {
                return;
            }
            
            if(this.productFamily.serialNumbers.some(x=> x.serialNumber==this.updatedSerialNumber) && this.productFamily.serialNumbers[this.serialIndex].serialNumber != this.updatedSerialNumber){
                this.$toasted.global
                     .app_error('Serial Numbers cannot be duplicated')
                     .goAway(5000);
                return;
            }
            this.productFamily.serialNumbers[this.serialIndex].serialNumber=this.updatedSerialNumber
             this.$refs['editSerialModal'].hide();
             this.$refs.serialNumber.focus();
        },
        showModal() {    
            this.$refs.serialNumber.focus();
        },
    }
};
</script>
<style scoped>
>>> .short-button {
    height: 25px;
}
/deep/ .table td{
    vertical-align: middle;
}
</style>