<template>
    <div>
        <SearchScreen
            title="Academic Departments"
            api-destination="coursedepartments"
            edit-key="courseDepartmentId"
            :filters="filters"
            :fields="fields"
            :filterBy="filterBy"
            add-new-button
            add-bulk-is-active-toggle
            ref="SearchScreen"
            :edit-url-func="
                item => `/academicdepartments/${item.courseDepartmentId}`
            "
            add-url="/academicdepartments/new"
            @isActiveChanged="updateIsActiveStatus"
        >
            <template v-slot:addButton>
                <p-button
                    variant="outline-primary"
                    class="ml-2"
                    v-b-modal.CopyCampusModel
                    :disabled="disableCopy()"
                    >Copy To Campus</p-button
                >
            </template>
        </SearchScreen>
        <p-modal
            id="CopyCampusModel"
            size="sm"
            name="CopyCampusModel"
            label="Copy To Campus"
            @ok="handleModalOk"
        >
            <template>
                <p-form ref="copyForm">
                    <b-row>
                        <b-col>
                            <p-advanced-select
                                return-object
                                label="Campus"
                                dataType="campuses"
                                rules="required"
                                v-model="newCampus"
                            />
                        </b-col>
                    </b-row>
                </p-form>
            </template>
        </p-modal>
    </div>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            newCampus: null,
            filterName: 'CourseDepartmentFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All', 'Name', 'Description', 'Code', 'Campus'],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },
                {
                    key: 'code',
                    sortable: true
                },
                {
                    key: 'campus',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: true
                }
            ]
        };
    },
    methods: {
        disableCopy: function() {
            if (
                this.$refs.SearchScreen &&
                this.$refs.SearchScreen.selectedRows
            ) {
                return this.$refs.SearchScreen.selectedRows.length < 1;
            }
            return true;
        },
        updateIsActiveStatus(coursedepartment) {
            axios.post('coursedepartments', coursedepartment).then(() => {
                var msg = `${coursedepartment.name} ${
                    coursedepartment.isActive ? 'activated' : 'de-activated'
                } successfully`;
                this.$toasted.global.app_success(msg).goAway(5000);
            });
        },
        handleModalOk: async function(event) {
            event.preventDefault();
            if (await this.$refs.copyForm.validate()) {
                let selectedIds = this.$refs.SearchScreen.selectedRows.map(
                    x => x.courseDepartmentId
                );
                axios
                    .post('coursedepartments/copytocampus', {
                        courseDepartmentIds: selectedIds,
                        campusId: this.newCampus.value
                    })
                    .then(() => {
                        this.$refs.SearchScreen.refreshSearchResults();
                        this.$bvModal.hide('CopyCampusModel');
                        this.newCampus = null;
                    });
            }
        }
    },
    components: {
        SearchScreen
    }
};
</script>
<style scoped></style>
