<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="xl"
        :label="pageTitle"
        ref="editBuyerModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div class="container-fluid content-wrapper" v-if="buyer">
                    <b-row cols="1">
                        <b-col v-if="!isNew">
                            <p-advanced-select
                                label="User"
                                :disabled="!isNew"
                                data-type="users"
                                rules="required"
                                v-model="buyer.userId"
                                :filter="userFilter"
                            />
                        </b-col>
                        <b-col v-else>
                            <p-card class="mt-2">
                                <b-row class="mb-2">
                                    <b-col>
                                        <b-radio-group
                                            v-model="buyer.createNewUser"
                                        >
                                            <b-radio :value="false"
                                                >Use existing user</b-radio
                                            >
                                            <b-radio :value="true"
                                                >Create new user</b-radio
                                            >
                                        </b-radio-group>
                                    </b-col>
                                </b-row>
                                <b-row
                                    class="mb-2"
                                    cols="1"
                                    cols-xl="2"
                                    v-if="!buyer.createNewUser"
                                >
                                    <b-col>
                                        <p-advanced-select
                                            label="User"
                                            :disabled="!isNew"
                                            data-type="users"
                                            rules="required"
                                            v-model="buyer.userId"
                                            :filter="userFilter"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row class="mb-2" cols="1" cols-xl="3" v-else>
                                    <b-col>
                                        <p-input
                                            label="User Name"
                                            v-model="buyer.newUser.userName"
                                            rules="required|max:256|valid_username"
                                            :disabled="!isNew"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-input
                                            label="Display Name"
                                            v-model="buyer.newUser.displayName"
                                            rules="required|max:75"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-input
                                            label="Email"
                                            v-model="buyer.newUser.email"
                                            rules="email|max:254"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-input
                                            label="Password"
                                            v-model="buyer.newUser.password"
                                            type="password"
                                            rules="required|confirmed:confirmation|min:7|strong_password"
                                        />
                                    </b-col>
                                    <b-col>
                                        <p-input
                                            label="Confirm Password"
                                            v-model="confirmPassword"
                                            type="password"
                                            vid="confirmation"
                                        />
                                    </b-col>
                                    <b-col class="d-none d-xl-block"></b-col>
                                    <b-col>
                                        <h5 class="text-muted">
                                            Passwords must be at least 7
                                            characters and contain at least: 1
                                            letter and 1 number
                                        </h5>
                                    </b-col>
                                </b-row>
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Fast End Transactions"
                                v-model="buyer.fastEndTransactions"
                            />
                        </b-col>
                        <b-col>
                            <p-advanced-select
                                label="Group"
                                v-model="buyer.buybackBuyerGroup"
                                return-object
                                rules="required"
                                @input="updateLocations"
                                data-type="buybackBuyerGroups"
                            />
                        </b-col>
                        <b-col v-if="buyer.buybackBuyerGroup != null">
                            <p-dragable
                                :key="buyer.buybackBuyerGroup.value"
                                numbered
                                v-model="buyer.buybackBuyerGroupLocations"
                                :label="
                                    buyer.buybackBuyerGroupLocations > 1
                                        ? 'Active Locations - Drag priority location to top'
                                        : 'Active Locations'
                                "
                                :additional-options-label="'Inactive'"
                                :additional-options="locationOptions"
                                top-message="(Top priority)"
                            ></p-dragable>
                        </b-col>
                        <b-col> </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import Vue from 'vue';

export default {
    mixins: [StoreListManager],
    props: {
        buyerId: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,
            buyer: null,
            confirmPassword: '',
            locationOptions: [],
            selectListOptions: {
                buybackBuyers: [],
                users: [],
                locations: [],
                buybackBuyerGroups: []
            }
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.buyerId === 'new';
        },
        pageTitle() {
            return this.isNew ? 'Add Buyback Buyer' : 'Edit Buyback Buyer';
        },
        buttonLabel() {
            return this.isNew ? 'Create Buyer' : 'Edit';
        },
        userName() {
            if (this?.buyer?.userId === null) {
                return '';
            }
            return this.selectListOptions.users.find(
                x => x.value === this.buyer.userId
            ).text;
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            if (
                this.locationsModified() &&
                !(await this.$bvModal.msgBoxConfirm(
                    "The Buyer's Group will update for all associated users.  Are you sure?"
                ))
            ) {
                return;
            }

            this.buyer.buybackBuyerGroupId = this.buyer.buybackBuyerGroup.value;
            this.isBusy = true;

            axios
                .post('buyback/buyer', this.buyer)
                .then(() => {
                    this.$emit('data-saved');

                    this.$toasted.global
                        .app_success(
                            `Buyer '${this.userName}' saved successfully.`
                        )
                        .goAway(5000);
                    this.$refs.editBuyerModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        locationsModified() {
            if (
                this.buyer.buybackBuyerGroupLocations.length !=
                this.buyer.buybackBuyerGroup.buyerGroupLocations.length
            ) {
                return true;
            }

            return !this.buyer.buybackBuyerGroupLocations.every(
                (value, index) => {
                    return (
                        value.locationId ===
                        this.buyer.buybackBuyerGroup.buyerGroupLocations[index]
                            .locationId
                    );
                }
            );
        },
        updateLocations() {
            if (this.buyer?.buybackBuyerGroup == null) {
                this.buyer.buybackBuyerGroupLocations = [];
                this.locationOptions = [];
                return;
            }
            this.buyer.buybackBuyerGroupLocations = [
                ...this.buyer.buybackBuyerGroup.buyerGroupLocations
            ];

            this.locationOptions = this.selectListOptions.locations
                .filter(
                    x =>
                        !this.buyer.buybackBuyerGroupLocations.some(
                            y => y.locationId === x.value
                        )
                )
                .map(x => ({ name: x.text, locationId: x.value }));
        },

        userFilter(user) {
            return (
                !this.isNew ||
                !this.selectListOptions.buybackBuyers.some(
                    x => x.userId === user.value
                )
            );
        },
        loadData() {
            this.buyer = null;
            this.confirmPassword = '';
            let promise = this.isNew
                ? axios.get('buyback/newbuyer')
                : axios.get('buyback/buyer', { params: { id: this.buyerId } });

            promise.then(resp => {
                this.buyer = resp.data;
                Vue.set(this.buyer, 'newUser', {});
                Vue.set(this.buyer, 'createNewUser', false);
                Vue.set(
                    this.buyer,
                    'buybackBuyerGroup',
                    this.selectListOptions.buybackBuyerGroups.find(
                        x => x.value === this.buyer.buybackBuyerGroupId
                    )
                );
                this.updateLocations();
            });
        }
    }
};
</script>
