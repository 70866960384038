<template>
    <b-overlay
        :show="isBusy"
        rounded
        opacity="0.5"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block container-fluid content-wrapper"
        >
        <p-form @submit="onSubmit">
            <div v-if="salesEvent">
                <b-row class="content-heading">
                    <b-col>
                        <div>{{ isNew ? 'Add' : 'Edit' }} Sales Event</div>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <p-button type="submit" variant="primary" :is-busy="isBusy"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>

                <p-card>
                    <h4>General Parameters</h4>
                    <b-row cols="1" cols-sm="2" cols-xl="3">
                        <b-col>
                            <p-input
                                label="Sales Event Name"
                                rules="required|max:50"
                                v-model="salesEvent.name"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Sales Location"
                                rules="required"
                                v-model="salesEvent.saleLocation"
                                :options="salesEventLocation"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Sales Event Price Rounding (Up)"
                                rules="required"
                                :options="salesEventPriceRounding"
                                v-model.number="
                                    salesEvent.priceMod.roundingTarget
                                "
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="3">
                        <b-col>
                            <p-select
                                label="Discount Type"
                                rules="required"
                                :options="salesEventDiscountType"
                                v-model="
                                    salesEvent.priceMod.discountType
                                "
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                label="Amount"
                                :rules="getAmountRules(salesEvent.priceMod.discountType)"
                                :inputType="
                                    getInputType(
                                        salesEvent.priceMod
                                            .discountType
                                    )
                                "
                                @change="handleEventPriceChange"
                                v-model="salesEvent.priceMod.amount"
                                left-align
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="3" class="ml-1 mb-2 text-danger">(Changing either of the Discount Type or the Amount will change the Event Price)</b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="3">
                        <b-col>
                            <p-datepicker
                                label="Start Date"
                                v-model="salesEvent.startDate"
                                rules="required"
                                vid="salesEventStartDate"
                            />
                        </b-col>
                        <b-col>
                            <p-datepicker
                                label="End Date"
                                v-model="salesEvent.endDate"
                                vid="salesEventEndtDate"
                                rules="required|dateGreaterOrEqual:@salesEventStartDate"
                            />
                        </b-col>
                    </b-row>
                </p-card>

                <p-card>
                    <b-row>
                        <b-col cols="12" class="text-left">
                            <h4>Product Attachments</h4>
                            <p-form inline v-if="true">
                                <ProductMultiSelectQuickSearch
                                    label="Add Product(s)"
                                    include-terms
                                    dcc-filter
                                    is-active-filter
                                    icon-plus
                                    @selections="addSelectedItem"
                                    :disableAddButton = "!isFormValidated"
                                />
                            </p-form>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col cols="12">
                            <b-overlay
                                :show="false"
                                rounded
                                opacity="0.5"
                                spinner-variant="primary"
                            >
                                <p-table
                                    :items="salesEvent.products"
                                    :fields="getFields"
                                    :enableRowSelection="false"
                                >
                                    <template
                                        v-slot:cell(EventPrice)="{ value, item }"
                                    >
                                        <div v-if="item.isActive">
                                            {{
                                                currencyFormatter(getEventPrice(item.retail))
                                            }}
                                        </div>
                                    </template>

                                    <template v-slot:cell(overridePrice)="{ value, item }">
                                        <p-number
                                            v-model.number="item.overridePrice"
                                            compact-format
                                            input-type="money"
                                            name="overridePrice"
                                            rules="min_value:0"
                                            :disabled="!item.isActive"
                                        />
                                    </template>

                                    <template
                                        v-slot:cell(isActive)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <div class="extra-padding">
                                            <p-checkbox
                                                :use-switch="true"
                                                v-model="item.isActive"
                                                size="lg"
                                                class="mr-0"
                                                @change="isActiveToggleChange(item,value)"
                                            >
                                            </p-checkbox>
                                        </div>
                                    </template>
                                </p-table>
                            </b-overlay>
                        </b-col>
                    </b-row>
                </p-card>
                <b-row>
                    <b-col class=" mb-3 text-center">
                        <p-button variant="primary" type="submit" :is-busy="isBusy"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </b-overlay>
</template>

<script>
import {
    currencyFormatter,
    bookVariantFormatter
} from '../../components/Common/Formatters.js';
import pricing from '@/services/PricingCalculations.js';
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import unionBy from 'lodash/unionBy';

export default {
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            salesEventPriceRounding: [
                { text: '0.01', value: 0.01 },
                { text: '0.05', value: 0.05 },
                { text: '0.10', value: 0.1 },
                { text: '0.25', value: 0.25 },
                { text: '0.50', value: 0.5 },
                { text: '1.00', value: 1.0 }
            ],
            salesEventLocation: [
                { text: 'POS', value: 1 },
                { text: 'POS and WEB', value: 2 }
            ],
            salesEventDiscountType: [
                { text: 'Percent off', value: 2 },
                { text: 'Dollar off', value: 1 }
            ],
            salesEvent: null,
            isBusy: false,
            // details grid
            fields: [
                {
                    key: 'sku',
                    label: 'SKU',
                    sortable: true,
                    automaticSearch: true,
                    hidden: true
                },
                {
                    label: 'Author',
                    key: 'author',
                    sortable: true,
                    automaticSearch: true,
                    hidden: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                    automaticSearch: true,
                    hidden: true
                },
                {
                    label: 'Description',
                    key: 'authorTitleOrDescription',
                    sortable: true,
                    automaticSearch: true,
                    hidden: true
                },
                {
                    label: 'Author /Title or Description',
                    key: 'authorTitleOrDescription',
                    sortable: true
                },
                {
                    key: 'copyright',
                    sortable: true,
                    label: 'Variant 1 /Copyright'
                },
                {
                    key: 'edition',
                    sortable: true,
                    label: 'Variant 2 /Edition'
                },
                {
                    key: 'bookVariant',
                    formatter: bookVariantFormatter,
                    label: 'Type'
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    automaticSearch: true,
                    hidden: true
                },
                {
                    key: 'defaultBarcode',
                    label: 'Barcode',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'retail',
                    label: 'Selling Price',
                    formatter: currencyFormatter,
                    thClass: 'text-right',
                    tdClass: 'text-right'
                },
                {
                    key: 'EventPrice',
                    label: 'Event Price',
                    formatter: currencyFormatter,
                    thClass: 'text-right',
                    tdClass: 'text-right'
                },
                {
                    key: 'overridePrice',
                    label: 'Price Override',
                    thClass: 'text-left',
                    tdClass: 'text-right'
                },
                {
                    key: 'isActive',
                    label: 'On Sale',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                }
            ]
        };
    },
    watch: {
        salesEvent: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        getFields() {
            return [...this.fields];
        },
        isFormValidated(){
            return (this.salesEvent.name && 
                    this.salesEvent.saleLocation && 
                    this.salesEvent.priceMod.roundingTarget && 
                    this.salesEvent.priceMod.discountType && 
                    this.salesEvent.priceMod.amount && 
                    this.salesEvent.startDate && 
                    this.salesEvent.endDate )
        }
    },
    methods: {
        currencyFormatter: currencyFormatter,
        getInputType(value) {
            return value == 2 ? 'percent' : 'money';
        },
        addSelectedItem(items) {
            let details = items.map(i => ({
                salesEventId: this.salesEvent.salesEventId,
                isActive: true,
                ...i
            }));

            this.salesEvent.products = unionBy(
                this.salesEvent.products,
                details,
                x => x.sku + '-' + (x.termId ?? 0)
            );
        },
        onSubmit() {
            this.isBusy = true;
            this.salesEvent.products.forEach(product => 
                    product.eventPrice = this.getEventPrice(product.retail));
            axios.post('SalesEvents', this.salesEvent).then(response => {
                if (this.isNew) {
                    this.$router.push('/salesevents/' + response.data);
                }
                this.dataSaved();
                this.$toasted.global
                    .app_success(
                        `Sale Event '${this.salesEvent.name}' saved successfully.`
                    )
                    .goAway(5000);
            })
            .finally(() => {
                this.isBusy = false;
            });
        },
        async loadData() {
            let promise = this.isNew
                ? axios.get('SalesEvents/new')
                : axios.get('SalesEvents', { params: { id: this.id } });
            promise.then(resp => {
                this.salesEvent = resp.data;
                if (!this.isNew) {
                      this.dataLoaded();
                }
            });
        },
        getEventPrice(value) {
            if (this.salesEvent.products.length <= 0 || this.salesEvent.priceMod.roundingTarget == null) return;
            return pricing.computeEventPrice(value, this.salesEvent.priceMod);
        },
        calculateDiscountedPrice(originalPrice, discountPercentage) {
            let discount = originalPrice * (discountPercentage / 100);
            let discountedPrice = originalPrice - discount;
            return discountedPrice;
        },
        isActiveToggleChange(item,value){
            if(!value) item.overridePrice = null;
        },
        getAmountRules(discountType){
            if (discountType == 2){
                return "required|min_value:0.1|max_value:100";
            } else {
                return "required|min_value:0.1";
            }
        },
        handleEventPriceChange(){
            this.salesEvent.products.forEach(product => 
                product.eventPrice = this.getEventPrice(product.retail));
        }
    },
    mounted: async function() {
        await this.loadData();
    },
    components: {
        ProductMultiSelectQuickSearch
    }
};
</script>

<style scoped></style>
