<template>
  <ContentWrapper>
    <p-card>
      <p>
        Validation uses
        <a href="https://logaretm.github.io/vee-validate/">VeeValidate 3.0</a>.
      </p>
      <p>
        Use
        <b>&lt;p-form @submit="onSubmit"&gt;</b> to enable validation.
      </p>
      <ul>
        <li>
          <a href="https://logaretm.github.io/vee-validate/guide/rules.html#rules">All rules</a>
        </li>
      </ul>
    </p-card>
    <p-form @submit="onSubmit">
      <b-container fluid="lg">
        <b-row class="mb-3">
          <b-col>
            <h3>rules</h3>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>

        <hr />

        <b-row class="mb-3">
          <b-col>required</b-col>
          <b-col>
            <p-input v-model="inputValue" label="Input" rules="required" />
            <p-select
              v-model="selectValue"
              label="Select"
              no-selection-message="Please select your favorite letter"
              :options="options"
              rules="required"
            />
            <p-advanced-select label="Suppliers" dataType="suppliers"  v-model="selectedSupplier" rules="required" />
            <p-datepicker label="DatePicker" rules="required" />
          </b-col>
          <b-col>Input Value: {{ inputValue }}</b-col>
        </b-row>

        <hr />

        <b-row class="mb-3">
          <b-col>min:3</b-col>
          <b-col>
            <p-input v-model="minInputValue" label="Input" rules="min:3" />
          </b-col>
          <b-col>Input Value: {{ inputValue }}</b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col>
            <b-button variant="primary" type="submit">Trigger Submit</b-button>
          </b-col>
        </b-row>
      </b-container>
    </p-form>
  </ContentWrapper>
</template>

<script>
export default {
  name: "Validation",
  data() {
    return {
      inputValue: null,
      minInputValue: 'a',
      selectValue: null,
      selectedSupplier: null,
      checkboxValue: false,
      datePickerValue: null,
      options: [
        { value: "a", text: "(a)" },
        { value: "b", text: "(b-selected)" },
        { value: "c", text: "(c)" },
        { value: "d", text: "This one is disabled", disabled: true }
      ]
    };
  },
  computed: {},
  methods: {
    onSubmit() {
      console.log('submitted')
    }
  },
  mounted: function() {},
  components: {}
};
</script>

<style scoped></style>
