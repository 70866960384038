
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import axios from 'axios';
import DCCsSelector from '@/components/DCCsSelector.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    props: {
    },
    components: {
        DCCsSelector
    },
    data() {
        return {
            isBusy:false,
            sortByInFixtureReport: [
                 { text: ' ', value: 0 },
                { text: 'Description', value: 1 },
                { text: 'ISBN', value: 2},
                { text: 'SKU', value: 3 },
                { text: 'Barcode', value: 4}
            ],
            reportParameters: null,
            negativeInventoryOptions: [
                { text: 'Do Not Include Negative Inventory', value: 1 },
                { text: 'Include Negative Inventory', value: 2 },
                { text: 'Include ONLY Negative Inventory', value: 3 }
            ],
        };
    },
    computed: {
        dccSelectorHelpText() {
            return this.reportParameters.dccIds.length === 0
                ? 'ALL DCCs will be included in the report, you may select individual DCCs below.'
                : '';
        }
    },
    methods:{
        loadParameters() {
            this.reportParameters = {
                onlyStockNotZero: false,
                productTypes: [1, 2],
                dccIds: [],
                fixture: null,
                area: null,
                includeFixtureAreaDetail: false,
                includeDetails: false,
                includeOnlyNonZeroVariance: false,
                includeInventoryNotCounted: false,
                includeInventoryDetail: true,
                negativeInventory: 1,
                sortBy: 0,
                locationId: null
            }
        },
        async exportReport(type, destination, successMessage, fileName) {
            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            let parameters = {
                ...this.reportParameters,
                dccIds: this.reportParameters.dccIds.map(d => d.dccId)
            }
            this.isBusy = true;
            axios
                .post(
                    destination,
                    parameters,
                    { responseType: 'blob' }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        type === 'pdf' ? fileName : fileName + '.csv',
                        type === 'pdf' ? 'application/pdf' : 'application/csv',
                    );
                    this.$toasted.global
                        .app_success(
                            successMessage
            )
                        .goAway(5000);
                    this.$refs.exportInventoryNotCountedModal?.hide();
                    this.$refs.exportInventoryByDccModal?.hide();
                    this.$refs.exportInventoryValueModal?.hide();
                    this.$refs.exportInventoryByFixtureModal?.hide();
                    this.$refs.exportSalesByDccModal?.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },
    watch: {
    },
};
