<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="xl"
        :label="pageTitle"
        :button-variant="buttonVariant"
        ref="editShippingCodeModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
        @hidden="resetModal"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div
                    class="container-fluid content-wrapper"
                    v-if="shippingcode"
                >
                    <b-row>
                        <b-col cols="6">
                            <p-input
                                label="Shipping Name"
                                v-model="shippingcode.shippingCodeName"
                                rules="required|max:100"
                                :disabled="!isNew"
                            />
                        </b-col>
                        <b-col cols="3">
                            <p-checkbox
                                label="In-Store Pickup"
                                class="mt-3 pt-3"
                                v-model="shippingcode.isStorePickup"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Code Status"
                                data-type="isActiveOptions"
                                text-field="text"
                                v-model="shippingcode.isActive"
                            ></p-select>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <p-input
                                label="Code Description"
                                v-model="shippingcode.description"
                                rules="max:255"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="Shipping Method"
                                data-type="ShippingMethods"
                                text-field="text"
                                v-model="shippingcode.shippingMethodId"
                                :disabled="!isNew"
                                @input="getInputType()"
                            ></p-select>
                        </b-col>
                        <b-col>
                            <p-number
                                label="Display Priority"
                                v-model="shippingcode.priority"
                                input-type="integer"
                                rules="required|min_value:1|max_value:5"
                                left-align
                            />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <p-card class="mt-2">
                                <p-table
                                    :foot-clone="isNew"
                                    no-footer-sorting
                                    foot-variant="light"
                                    :enable-row-selection="false"
                                    :items="shippingcode.shippingCodeDetails"
                                    :fields="computedFields"
                                    empty-text=""
                                    ref="detailTable"
                                >
                                    <template
                                        v-slot:cell(startRange)="{
                                            value,
                                            item,
                                            index
                                        }"
                                    >
                                        <p-number
                                            v-model="item.startRange"
                                            name="Start Order Qty"
                                            :ref="'startInputs_' + index"
                                            :inputType="inputType"
                                            rules="required|min_value:0"
                                            :disabled="true"
                                        >
                                        </p-number>
                                    </template>
                                    <template
                                        v-slot:cell(endRange)="{
                                            value,
                                            item,
                                            index
                                        }"
                                    >
                                        <p-number
                                            v-model.number="item.endRange"
                                            name="Ending Order Qty"
                                            :inputType="inputType"
                                            rules="required|min_value:0"
                                            :disabled="true"
                                            :ref="'endInputs_' + index"
                                        >
                                        </p-number>
                                    </template>
                                    <template
                                        v-slot:cell(shippingFee)="{
                                            value,
                                            item,
                                            index
                                        }"
                                    >
                                        <p-number
                                            v-model.number="item.shippingFee"
                                            name="Shipping Charge"
                                            input-type="money"
                                            :ref="'feeInputs_' + index"
                                            rules="required|min_value:0"
                                        >
                                        </p-number>
                                    </template>
                                    <!-- footer -->
                                    <template v-slot:foot(startRange)="data">
                                        <p-number
                                            v-model="newItem.startRange"
                                            name="Start Range"
                                            ref="newStartInput"
                                            :inputType="inputType"
                                            rules="required|min_value:0"
                                            class="text-right"
                                            :disabled="true"
                                        >
                                        </p-number>
                                    </template>

                                    <template v-slot:foot(endRange)="data">
                                        <p-number
                                            v-model="newItem.endRange"
                                            name="End Range"
                                            ref="newEndInput"
                                            :inputType="inputType"
                                            :rules="newItemEndRangeRules"
                                            :value="maxRange"
                                            :disabled="!isNew"
                                        >
                                        </p-number>
                                    </template>
                                    <template v-slot:foot(shippingFee)="data">
                                        <p-number
                                            v-model.number="newItem.shippingFee"
                                            name="Shipping Fee"
                                            ref="newFeeInput"
                                            input-type="money"
                                            rules="required|min_value:0"
                                            @keydown="
                                                handleNewFeeKeydown(
                                                    $event,
                                                    newItem
                                                )
                                            "
                                            @blur="handleNewEndBlur(newItem)"
                                            class="text-right"
                                        >
                                        </p-number>
                                    </template>
                                </p-table>
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row class="w-100">
                <b-col class="text-left">
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        @click="cancel"
                        class="ml-2"
                    >
                        Cancel
                    </p-button>
                    <b-button
                        v-if="!isNew"
                        class="ml-2"
                        variant="outline-danger"
                        :disabled="isActive"
                        @click="deleteShippingCode"
                    >
                        Delete
                    </b-button>
                </b-col>
                <b-col class="mb-3 text-right">
                    <p-button
                        ref="btnSave"
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="mr-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import round from 'lodash/round';

export default {
    mixins: [NavigationGuard],
    props: {
        shippingCodeId: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,
            shippingcode: null,
            inputType: 'money',
            maxRange: 100000,
            nextValueIncreament: 0.01,
            searchPrefill: null,
            newItem: {
                startRange: 0,
                endRange: 100000,
                shippingFee: null
            },
            isChangedEvent:true
        };
    },
    watch: {
        shippingcode: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.shippingCodeId === 'new';
        },
        pageTitle() {
            return this.isNew ? 'Add Shipping Code' : 'Edit Shipping Code';
        },
        buttonLabel() {
            return this.isNew ? 'Add' : 'Edit';
        },
        buttonVariant() {
            return this.isNew ? 'primary' : 'outline-primary';
        },
        isActive() {
            return this.shippingcode?.isActive;
        },
        newItemEndRangeRules() {
            return (
                'required|min_value:' +
                (this.shippingcode.shippingMethodId == 1?round(this.newItem.startRange + 0.01, 2):this.newItem.startRange) +
                '|max_value:100000'
            );
        },
        computedFields() {
            return [
                {
                    key: 'startRange',
                    label:
                        this.shippingcode.shippingMethodId == 1
                            ? 'Starting Order Value'
                            : 'Starting Order Qty'
                },
                {
                    key: 'endRange',
                    label:
                        this.shippingcode.shippingMethodId == 1
                            ? 'Ending Order Value'
                            : 'Ending Order Qty'
                },
                {
                    key: 'shippingFee',
                    label: 'Shipping Fee'
                }
            ];
        }
    },
    methods: {
        getInputType() {
            if(this.isNew && this.shippingcode.shippingCodeDetails.length > 0 && this.isChangedEvent){
                this.$bvModal
                .msgBoxConfirm('The rows will be cleared. Are you sure you want to the change the shipping method?')
                .then(value => {
                    if (value) {
                        this.shippingcode.shippingCodeDetails = [];
                        this.newItem = {
                            startRange: 0,
                            endRange: 100000,
                            shippingFee: null
                        };
                        this.setConfiguration();                        
                    }else{
                        this.shippingcode.shippingMethodId = this.shippingcode.shippingMethodId==1?2:1;
                        this.isChangedEvent=false;
                        this.setConfiguration();
                        return;
                    }
                })
            }else{
                this.isChangedEvent=true;
                this.setConfiguration();
            }
        },
        setConfiguration(){
            this.inputType = this.shippingcode.shippingMethodId == 1 ? 'money' : 'integer';
            this.maxRange = this.shippingcode.shippingMethodId == 1 ? 100000 : 500;
            this.nextValueIncreament = this.shippingcode.shippingMethodId == 1 ? 0.01 : 1;
            this.newItem.endRange = this.maxRange;
        },
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            let finalShippingcode = JSON.parse(
                JSON.stringify(this.shippingcode)
            );
            if (this.isNew) {
                finalShippingcode.shippingCodeDetails.push({
                    startRange: this.newItem.startRange,
                    endRange: this.newItem.endRange,
                    shippingFee: this.newItem.shippingFee
                });
            }
            this.isBusy = true;
            axios
                .post('shippingcodes', finalShippingcode)
                .then(() => {
                    this.$emit('result-updated');
                    this.$toasted.global
                        .app_success(
                            `Shipping Code '${this.shippingcode.shippingCodeName}' saved successfully.`
                        )
                        .goAway(5000);
                    this.$refs.editShippingCodeModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        loadData() {
            this.shippingcode = null;
            let promise = this.isNew
                ? axios.get('shippingcodes/new')
                : axios.get('shippingCodes', {
                      params: { id: this.shippingCodeId }
                  });

            promise
                .then(resp => {
                    this.shippingcode = resp.data;
                    this.getInputType();
                    if (!this.isNew) {
                        this.dataLoaded();
                    } else {
                        this.newItem = {
                            startRange: 0,
                            endRange: 100000,
                            shippingFee: null
                        };
                    }
                })
                .catch(() => {
                    this.$refs.editShippingCodeModal.hide();
                });
        },
        resetModal() {
            this.$emit('result-updated');
            this.$refs.editShippingCodeModal.hide();
        },
        deleteShippingCode() {
            if (!this.shippingcode.isActive) {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to delete this shipping code?',
                        {
                            okVariant: 'danger',
                            okTitle: 'Delete',
                            cancelTitle: 'Cancel'
                        }
                    )
                    .then(value => {
                        if (value) {
                            axios
                                .post('shippingcodes/delete', this.shippingcode)
                                .then(resp => {
                                    if (resp) {
                                        this.dataSaved();
                                        this.$toasted.global
                                            .app_success(
                                                `Shippingcode '${this.shippingcode.shippingCodeName}' deleted successfully.`
                                            )
                                            .goAway(5000);
                                        this.$refs.editShippingCodeModal.hide();
                                    }
                                });
                        }
                    });
            }
        },
        handleNewFeeKeydown(e, item) {
            if (this.enterOrTab(e.code) && item.endRange < this.maxRange) {
                e.preventDefault();

                if (e.shiftKey == true) {
                    this.$refs['newEndInput'].focus();
                    return;
                }
                if (item.endRange < this.maxRange) {
                    this.addItem(item);
                }
            }
        },
        handleNewEndBlur(item) {
            if (item.endRange < this.maxRange) {
                this.addItem(item);
            }
        },
        async addItem(item) {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.shippingcode.shippingCodeDetails.push({
                startRange: item.startRange,
                endRange: item.endRange,
                shippingFee: item.shippingFee
            });

            this.clearNewItem(item);
            this.$refs['newEndInput'].focus();
        },
        clearNewItem(item) {
            this.newItem.startRange = item.endRange + this.nextValueIncreament;
            this.newItem.endRange = this.maxRange;
            this.newItem.shippingFee = null;
            this.newItem._rowVariant = null;
        },
        enterOrTab(code) {
            return code == 'Enter' || code == 'Tab' || code == 'NumpadEnter';
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
.select-filter /deep/ .custom-select {
    height: 34px;
    min-width: 135px;
}
</style>
