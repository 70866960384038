<template>
    <SearchScreen
        title="Credit Requests"
        api-destination="creditrequests"
        edit-key="creditRequestId"
        :filters="filters"
        :fields="fields"
        :filter-by="filterBy"
        add-new-button
    >
        <template v-slot:belowSearch>
            <p-form>
                <b-row class="ml-3 mt-4 mr-2">
                    <p-checkbox
                        label="Proposed"
                        class="ml-2"
                        v-model="filters.proposed"
                    />
                    <p-checkbox
                        label="Open"
                        class="ml-3"
                        v-model="filters.open"
                    />
                    <p-checkbox
                        label="Closed"
                        class="ml-3"
                        v-model="filters.closed"
                    />
                    <b-col cols="12" class="d-lg-none"></b-col>
                    <b-col cols="12" sm="6" lg="3" class="ml-lg-auto">
                        <p-datepicker
                            label="Create Date: From"
                            vid="beginDate"
                            v-model="filters.beginDate"
                        />
                    </b-col>
                    <b-col cols="12" sm="6" lg="3">
                        <p-datepicker
                            label="Create Date: To"
                            v-model="filters.endDate"
                            rules="dateGreaterOrEqual:@beginDate"
                            is-end-date
                        />
                    </b-col>
                </b-row>
            </p-form>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import {
    currencyFormatter,
    dateFormatter
} from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'CreditRequestFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'createDate',
                sortDesc: true,
                beginDate: null,
                endDate: null,
                closed: false,
                proposed: true,
                open: true,
                searchBy: 'All'
            },
            filterBy: [
                'All',
                'Request #',
                'Supplier',
                'Invoice #',
                'SKU',
                'Barcode',
                'ISBN',
                'Catalog #'
            ],
            fields: [
                {
                    key: 'requestNumber',
                    sortable: true
                },
                {
                    key: 'supplier',
                    sortable: true
                },
                {
                    key: 'createDate',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    key: 'total',
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: currencyFormatter,
                    sortable: false
                },
                {
                    key: 'status',
                    sortable: true
                }
            ]
        };
    },
    methods: {},
    components: {
        SearchScreen
    }
};

</script>