import { pDecimalValidationTest, dateGreaterOrEqualTest } from '@/components/mixins/ValidatedInput.js';
import {dateFormatter, currencyFormatter} from "@/components/Common/Formatters.js";

export default {
    data() {
        return {
            customerArConfiguration: {
                type: 'CustomerArAccounts',
                label: 'Customer AR Accounts',
                sampleNotes: [
                    '"If start date, end date, credit limit, or customer balance are left blank, they will be set to the AR account defaults when making new customer account links.  When updating existing links, defaults will not be applied."',
                    '"When unlinking accounts any values provided for start/end dates, credit limit, and customer balance will be ignored."',
                    '"Dates are preferred to be in YYYY-MM-DD."',
                    '"When linking debit accounts credit limit will be ignored."'
                ],
                fields: [
                    {
                        key: 'CustomerAccountNumber',
                        hasErrorCalculator: value => {
                            return (
                                !this.matchesExistingObject(value, true)
                            );
                        },
                        rules: 'min_value:0|required',
                        dataType: 'customers',
                        comparisonProperty: 'number',
                        samples: ['"0045172"', '"0045173"', '"0045174"'],
                    },
                    {
                        key: 'ARAccountCode',
                        label: 'AR Account Code',
                        hasErrorCalculator: value => {
                            return (
                                !this.matchesExistingObject(value, true)
                            );
                        },
                        rules: 'min_value:0|required',
                        comparisonProperty: 'number',
                        dataType: 'accounts',
                        samples: [
                            '"PELL"',
                            '"Susan Smith Freshman Fund"',
                            '"ALUMNI"'
                        ]
                    },
                    {
                        key: 'Action',
                        label: 'Action',
                        hasErrorCalculator: value => {
                            return (
                                !this.matchesExistingObject(value, true)
                            );
                        },
                        dataType: 'arAccountActionOptions',
                        rules: 'min_value:0|required',
                        samples: ['"Link"', '"Link"', '"Unlink"']
                    },
                    {
                        key: 'StartDate',
                        hasWarningCalculator: value => {
                            return !this.isValidDate(value);
                        },
                        warningDefault: null,
                        formatter: dateFormatter,
                        rules: {
                            'valid-date': {
                                isValidDate: this.isValidDate,
                                propertyName: 'StartDate'
                            }
                        },
                        inputType: 'date',
                        addRowDataToRule: 'valid-date',
                        samples: [
                            '"2022-10-09"',
                            '"2022-10-09"',
                            '"2022-10-05"'
                        ]
                    },
                    {
                        key: 'EndDate',
                        hasWarningCalculator: value => {
                            return !this.isValidDate(value);
                        },
                        hasErrorCalculator: ( value, item ) => {
                            if(this.isEmpty(value) || !this.isValidDate(value)) return false;
                            return !dateGreaterOrEqualTest(value,item.StartDate)
                        },
                        warningDefault: null,
                        formatter: dateFormatter,
                        rules: {
                            'valid-date': {
                                isValidDate: this.isValidDate,
                                propertyName: 'EndDate'
                            },
                            'dateGreaterOrEqualImport':{propertyName: 'StartDate',propertyLabel:'Start Date' }
                            
                        },
                        inputType: 'date',
                        addRowDataToRule: ['valid-date','dateGreaterOrEqualImport'],
                        samples: [
                            '"2023-10-09"',
                            '"2023-10-09"',
                            '"2023-10-05"'
                        ]
                    },
                    {
                        key: 'CreditLimit',
                        hasWarningCalculator: value => {
                            return (
                                 value &&
                                (!pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                    })
                                    || value < 0)
                            );
                        },
                        warningDefault: null,
                        formatter: currencyFormatter,
                        rules: 'p-decimal:10,2|min_value:0',
                        convertToNumber: true,
                        samples: ['500', '75', '750']
                    },
                    {

                        key: 'CustomerBalance',
                        hasWarningCalculator: value => {
                            return (
                                value &&
                                !pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                })
                            );
                        },
                        warningDefault: null,
                        formatter: currencyFormatter,
                        rules: 'p-decimal:10,2',
                        convertToNumber: true,
                        samples: ['0', '75', '0']
                    }
                ],
                canRunNoParse: true,
                cronJobTypeId: 3
            }
        };
    },
    methods: {}
};
