<template>
    <p>Please wait...</p>
</template>

<script>
    import router from '@/router';
    import Oidc from 'oidc-client';
    import userManager from '@/services/SecurityService';

    export default {
        mounted() {
            var mgr = new Oidc.UserManager({ userStore: new Oidc.WebStorageStateStore() });
            var self = this;

            mgr.signinRedirectCallback().then(function (user) {
                var url = user.state || '/';
                router.push(url);

                if (user && user.profile) {
                    userManager.gainsightIdentify();
                }
            }).catch(function () {
                self.$toasted.global.app_error(`We're sorry, an error has occurred during authentication.`);
            });
        }
    };
</script>