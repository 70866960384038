<template>
    <p-modal
        name="exportTaxesbyJurisdictionModal"
        label="Export Taxes By Jurisdiction"
        ref="exportTaxesbyJurisdictionModal"
        size="lg"
        @ok.prevent="handleModalOk"
    >
     <template v-slot:default>
            <p-form ref="taxesByJurisdictionForm">
                <div class="container-fluid content-wrapper">
                    <b-row>
                    <b-col>
                            <p-select
                                data-type="states"
                                v-model="taxReportParameters.stateId"
                                mode="lazy"
                                label="State"
                                rules="required"
                                add-empty-option
                                ref="stateSelect"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-lg="2">
                        <b-col>
                            <p-card title="POS Devices" class="static-height">
                                <p-filter-select
                                    :options="selectListOptions.posDevices"
                                    rules="required"
                                    :default-selected="false"
                                    :initial-display-count="10"
                                    v-model="taxReportParameters.registerIds"
                                />
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card title="Date Range">
                                <p-date-range :date-range-options="taxReportParameters" />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="cancel"
                        >Cancel</p-button
                    >
                    <p-button
                        variant="primary"
                        @click="ok"
                        :is-busy="isBusy"
                        class="ml-2"
                        >OK</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
    mixins: [StoreListManager],
    data() {
        return {
            isBusy: false,
            taxReportParameters: {
                registerIds: [],
                startDate: null,
                endDate: null,
                stateId: null,
                locationId: null,
            },
            selectListOptions: {
                posDevices: [],
            }
        };
    },
    computed: {
        pageTitle() {
            return 'Taxes By Jurisdiction';
        }
    },
    methods: {
        async show() {
            this.$refs.exportTaxesbyJurisdictionModal.show();
            this.posDevicesVal=  this.selectListOptions.posDevices;
            const foundObjects = this.posDevicesVal.filter((obj) => {
                return (obj.text).toLowerCase() == "web register"
            });
            this.taxReportParameters.registerIds = [...foundObjects?.map(obj => obj['value'])];
        },
        handleModalOk: async function() {

            if (!(await this.$refs.taxesByJurisdictionForm.validate())) {
                return;
            }
            if (this.taxReportParameters.registerIds.length === 0) {
                this.$toasted.global.app_error(
                    'Please select one or more Pos Devices.'
                );
                return;
            }

            this.isBusy = true;

            this.taxReportParameters.locationId =   await selectListOptionsDataContext.getSelectedLocationIdAsync();
            await axios
                .post('/sales/exportTaxesByJurisdictionCsv', this.taxReportParameters, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Taxes By Jurisdiction.csv',
                        'application/csv'
                    );
                    this.$toasted.global
                        .app_success(`Taxes By Jurisdiction generated successfully.`)
                        .goAway(5000);
                    this.$refs.exportTaxesbyJurisdictionModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>

<style scoped>
.static-height {
    height:370px
}
</style>
