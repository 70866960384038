<template>
    <p-modal
        ref="printLabelModal"
        :name="'printLabelModal'+type"
        :icon-plus="false"
        size="md"
        label="Print Label"
        @ok.prevent="handleModalOk"
        @show="loadData"
    >
        <p-form ref="form">
            <template v-slot:default>
                <div>
                    <b-row>
                        <b-col cols="6" sm="6" xl="6">
                            Print Qty:
                        </b-col>
                        <b-col cols="6" sm="6" xl="6">
                            <p-number
                                input-type="integer"
                                label=""
                                rules="required|min_value:1"
                                name="Qty of labels"
                                v-model="labelQty"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" sm="6" xl="6">
                            Price Tag Type:
                        </b-col>
                        <b-col cols="6" sm="6" xl="6">
                            {{ tagType }}
                        </b-col>
                        <span id='tagContent' v-html="tagContent" />
                    </b-row>
                </div>
            </template>
        </p-form>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row class="mb-3">
                <p-button variant="outline-primary" @click="cancel"
                    >Cancel</p-button
                >
                <p-button variant="primary" @click="ok" class="ml-2"
                    >Print</p-button
                >
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import StoreListManager from '@/components/mixins/StoreListManager.js';
import axios from 'axios'
import printJS from 'print-js'
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [StoreListManager],
    props: {
        product: {
            type: Object
        },
        type: String
    },
    data() {
        return {
            labelQty: 1,
            selectListOptions: {
                priceTagTypes: []
            },
            defaultBarcodeInfo: null,
            tagContent: null
        };
    },
    computed: {
        tagType() {
            return this.selectListOptions.priceTagTypes.find(
                x => x.value === this.product.priceTagTypeId
            )?.text;
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        loadData() {
            this.labelQty = 1;
        },
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            this.defaultBarcodeInfo = this.product.barcodes.find(
                x=>x.isDefault === true
            );
            var locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            await axios
            .get("products/printpricetag", { params: { sku: this.product.sku, barcodeId: this.defaultBarcodeInfo.productBarcodeId, locationId: locationId, qty: this.labelQty } })
            .then(result => {
                this.tagContent = result.data;
                this.$nextTick(() => {
                    printJS("tagContent", 'html', '#tagContent: visible;');
                    this.tagContent = null;
                     this.$refs.printLabelModal.hide();
                });
            });
        }
    }
};
</script>
<style scoped>
#tagContent {
    visibility: hidden;
}
</style>
