<template>
    <div>
        ID: {{ id }}
        <p-form @submit="onSubmit">
            <div class="container-fluid content-wrapper" v-if="featureFlag">
                <b-row class="content-heading">
                    <b-col>
                        <div>Edit Feature Flag</div>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <b-button type="submit" variant="primary"
                            >Save</b-button
                        >
                    </b-col>
                </b-row>
                <p-card>
                    <b-row>
                        <b-col>
                            <p-input
                                label="Name"
                                v-model="featureFlag.name"
                                readonly                                
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Client"
                                v-model="featureFlag.client"
                                readonly                                
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-input
                                label="Description"
                                v-model="featureFlag.description"
                                readonly                                
                            />
                        </b-col>
                    </b-row>
                    <b-row cols=1 cols-xl="4" cols-lg="2">
                        <b-col>
                            <p-input
                                label="Start Date (UTC)"
                                v-model="featureFlag.startDate"
                                                                
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-checkbox 
                                label="Is Active" 
                                v-model="featureFlag.isActive">
                            </p-checkbox>
                        </b-col>
                    </b-row>
                </p-card>
                <p-card title="Assignments">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button
                                variant="outline-primary"
                                v-b-modal.AddDepartmentModel>
                                <b-icon-plus></b-icon-plus> Add Assignment
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-table
                                :items="featureFlag.featureFlagAssignments"
                                :fields="fields"
                                :enable-row-selection="false">
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <b-row>
                    <b-col class='mt-3 text-center'>
                        <p-button variant='primary' type='submit' :isBusy='isBusy'>Save</p-button>
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </div>
</template>

<script>
import pForm from '@/components/p-form.vue';
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import PCard from '@/components/p-card.vue';
import editPage from '@/components/mixins/EditPage';

export default {
    mixins: [NavigationGuard, editPage],
    components: { pForm, PCard },
    data() {
        return{
            featureFlag: null,
            fields:[
                {
                    key: 'tenant',
                    label: 'Tenant',
                },
                {
                    key: 'user',
                    label: 'User',
                }
            ]
        }
    },
    methods: {
        onSubmit() {

        },
        async loadData(){
            this.featureFlag = await axios.get('featureFlags', { params: { id: this.id } });
            this.dataLoaded();
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>

<style></style>
