<template>
    <p-modal
        :button-label="'Add Nexus'"
        :button-busy="isBusy"
        :icon-plus="true"
        size="lg"
        :label="'Add a Nexus'"
        @show="loadData"
        ref="addTaxNexus"
        no-close-on-backdrop
    >
        <template v-slot:default>
            <div class="container-fluid content-wrapper">
                <p-form ref="newNexusForm">
                    <b-row>
                        <b-col cols="12" sm="4" xl="6">
                            <p-select
                                dataType="states"
                                :value="stateId"
                                v-model="stateId"
                                mode="lazy"
                                label="Nexus"
                                :filter="(option) => option.filter == 1 && excludedStates.indexOf(option.value) == -1"
                                rules="required"
                                add-empty-option
                                ref="stateSelect"
                            />
                        </b-col>
                        <b-col cols="12" sm="4" xl="3">
                            <div class="extra-padding">
                                <div>
                                    Auto Update Status
                                </div>
                                <span class="v-center">
                                    <p-checkbox
                                        :use-switch="true"
                                        v-model="autoUpdate"
                                        size="lg"
                                        class="d-inline-block" 
                                        @change="handleAutoUpdateToggle"                        
                                    />
                                    <span class="mt-2">
                                        {{ autoUpdate ? 'Active' : 'Inactive'}}
                                    </span>
                                </span>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="4" xl="3">
                            <div class="extra-padding">
                                <div>
                                    Collect Shipping Tax
                                </div>
                                <span class="v-center">
                                <p-checkbox
                                    :use-switch="true"
                                    v-model="isShippingTaxCollected"
                                    size="lg"
                                    class="d-inline-block"                     
                                />                            
                                <span class="mt-2">
                                    {{ isShippingTaxCollected ? 'Active' : 'Inactive'}}
                                </span>
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                </p-form>
            </div>
        </template>
        
        <template v-slot:modal-footer="{ cancel }">
            <b-row>
                <b-col>
                    <p-button variant="outline-primary" @click="cancel">Cancel</p-button>
                    <p-button variant="primary" type="submit" :disabled="saveDisabled" @click="handleModalOk" :is-busy="isBusy" class="ml-2">Save</p-button>
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        warningMessage: {
            type: String
        },
        excludedStates: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isBusy: false,
            disabled: true,
            stateId: null,
            autoUpdate: false,
            isShippingTaxCollected: false
        };
    },
    computed: {
        saveDisabled() {
            return this.statedId === null || this.stateId === undefined ||this.stateId < 1;
        }
    },
    methods: {
        loadData() {
            this.stateId = null;
            this.autoUpdate = false;
            this.isShippingTaxCollected = false;
        },
        handleAutoUpdateToggle(status) {
            this.isBusy = true;
            if (!status) {
            this.$bvModal
                .msgBoxConfirm(
                        this.warningMessage,
                        {
                            okTitle: 'Accept'
                        }
                    )
                .then(value => {
                    if (!value) {
                        this.$nextTick(() => {
                            this.autoUpdate = false;
                        });
                    }
                });
            }
            this.isBusy = false;
        },
        handleModalOk() {
            this.isBusy = true;
            axios
                .post('taxes/taxnexus', {
                    commands: [{
                        stateId: this.stateId,
                        autoUpdate: this.autoUpdate,
                        isShippingTaxCollected: this.isShippingTaxCollected
                    }]
                })
                .then(() => {
                    this.$refs.addTaxNexus.hide();
                    this.$emit('data-saved');
                    this.$toasted.global.app_success(
                        'Tax Nexus added successfully.'
                    )
                    .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
}
</script>

<style scoped>
.extra-padding {
    padding-right: 15px;
}
.v-center{
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
}
</style>