<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <div class="container-fluid content-wrapper">
            <b-row class="content-heading">
                <b-col>
                    <div>Buyback - Buyers</div>
                </b-col>
            </b-row>
            <b-row>
                <editbuyer
                    class="pb-2 ml-3 mr-2"
                    buyer-id="new"
                    @data-saved="updateBuyerSearch()"
                />
                <editbuyergroup
                    class="pb-2"
                    group-id="new"
                    @data-saved="updateGroupSearch()"
                />
            </b-row>
            <b-tabs justified>
                <b-tab title="Buyback Buyers">
                    <p-search-table
                        :fields="buyerFields"
                        ref="buyerSearch"
                        :selectable="false"
                        :enable-row-selection="false"
                        search-destination="buyers"
                        sort-by="name"
                        api-destination="buyback"
                    >
                        <template v-slot:cell(edit)="{ value, item }">
                            <editbuyer
                                :buyer-id="item.buybackBuyerId"
                                @data-saved="updateBuyerSearch()"
                            />
                        </template>
                        <template v-slot:cell(fastEndTransaction)="{ value, item }">
                            <p-checkbox
                                disabled
                                v-model="item.fastEndTransactions"
                            />
                        </template>
                    </p-search-table>
                </b-tab>
                <b-tab title="Buyback Groups">
                    <p-search-table
                        :fields="groupFields"
                        ref="groupSearch"
                        :selectable="false"
                        :enable-row-selection="false"
                        search-destination="groups"
                        sort-by="description"
                        api-destination="buyback"
                    >
                        <template v-slot:cell(edit)="{ value, item }">
                            <editbuyergroup
                                :group-id="item.buybackBuyerGroupId"
                                @data-saved="updateGroupSearch()"
                            />
                        </template>
                    </p-search-table>
                </b-tab>
            </b-tabs>
        </div>
    </p-secure>
</template>

<script>
import editbuyer from '@/views/Buyback/EditBuyer.vue';
import editbuyergroup from '@/views/Buyback/EditBuyerGroup.vue';

export default {
    components: { editbuyer, editbuyergroup},
    props: {
        id: String
    },
    data() {
        return {
            buyerFields: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'loginId',
                    sortable: true
                },
                {
                    key: 'buyerGroup',
                    sortable: true
                },
                {
                    key: 'fastEndTransaction',
                    label: 'Fast End Trans.'
                }
            ],
            groupFields: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'description',
                    label: 'Group',
                    sortable: true
                },
                {
                    key: 'priorityLocation',
                    sortable: true
                }
            ]
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        updateBuyerSearch() {
            this.$refs?.buyerSearch.refreshSearchResults();
        },
        updateGroupSearch() {
            this.$refs?.groupSearch.refreshSearchResults();
        }
    }
};
</script>
<style scoped>
/deep/ .tcw100 {
    width: 100px;
}
</style>
