<template>
    <SearchScreen
        title="Web Catalogs"
        api-destination="catalogs"
        search-destination="searchcatalogs"
        edit-key="catalogId"
        :edit-url-func="item => `/webcatalogs/${item.catalogId}`"
        add-url="/webcatalogs/new"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :use-is-active-checkbox="false"
        add-new-button
    >
        <template v-slot:belowSearch>
            <p-form>
                <b-row class="ml-3 mt-4 mr-2">
                        <p-checkbox label="Open"
                                    class="ml-2"
                                    v-model="filters.active" />
                        <p-checkbox label="Closed"
                                    class="ml-2"
                                    v-model="filters.closed" />
                        <p-checkbox label="Hold"
                                    class="ml-2"
                                    v-model="filters.hold" />
                        <p-checkbox label="Inactive"
                                    class="ml-3"
                                    v-model="filters.inactive" />
                    </b-row>
            </p-form>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'CatalogsFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                searchBy: 'name',
                active: true,
                closed: true,
                hold: true,
                inactive: false
            },
            filterBy: ['All', 'Catalog Name', 'Catalog Group', 'Barcode'],
            fields: [
                {
                    key: 'name',
                    label: 'Catalog Name',
                    sortable: true
                },
                {
                    key: 'group',
                    label: 'Catalog Group(s)'
                },
                {
                    key: 'status',
                    label: 'Catalog Status',
                    sortable: true
                }
            ]
        };
    },
    methods: {},
    components: {
        SearchScreen
    }
};
</script>
<style scoped></style>
