import { max, min_value } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { pDecimalValidationTest } from '@/components/mixins/ValidatedInput.js';

extend('unique-value', {
    params: ['isDuplicated'],
    validate(value, { isDuplicated }) {
        return !isDuplicated(value);
    }
});

export default {
    data() {
        return {
            gmConfiguration: {
                type: 'gm',
                label: 'General Merchandise',
                sampleNotes: ['Rows with barcodes that already exist will be skipped',
                            'Rows with GM Barcode Xrefs that already exist will still be imported but the duplicate xref will be skipped',
                            'Variant1 is for product size and Variant2 is for product color',
                            'Brand names not found will be added',
                            'Catalog names not found will not be associated to the product'],
                importNotes: () =>  { return this.textBookImportNotes},
                fields: [
                    {
                        key: 'Barcode',
                        hasWarningCalculator: value => {
                            return (
                                !this.isEmpty(value) &&
                                !max.validate(
                                    value,
                                    { length: 128 } ||
                                        this.isDuplicatedOnImportBarcode()
                                )
                            );
                        },
                        warningDefault: '',
                        rules: {
                            'unique-value': {
                                isDuplicated: this.isDuplicatedOnImportBarcode
                            },
                            max: { length: 128 }
                        },
                        samples: ['"2235467890161"','"83266024874"','"45171766836"']
                    },
                    {
                        key: 'GmBarcodeXref',
                        label: 'GM Barcode Xref',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 500 });
                        },
                        warningDefault: '',
                        rules: {
                            max: { length: 500 }
                        },
                        samples: ['','','"0097522|048419315414|048419307129|048419205494"']
                    },
                    {
                        key: 'DCC',
                        selectOptionsFilter: () => {return  1;},
                        hasErrorCalculator: (value) => {
                            return !this.matchesExistingObject(value, true);
                        },
                        rules: 'min_value:0|required',
                        samples: ['"202030"','"202020"','"202010"'],
                        comparisonProperty: 'dccNumbers',
                        dataType: 'dcc'
                    },
                    {
                        key: 'ItemComment',
                        hasWarningCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return !max.validate(value, { length: 200 });
                        },
                        warningDefault: '',
                        rules: 'max:200',
                        samples: ['"SP ODR DEBBI BUMPOUS"']
                    },
                    {
                        key: 'Cost',
                        hasWarningCalculator: value => {
                            return (
                                value &&
                                (!pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                })
                                || value < 0)
                            );
                        },
                        warningDefault: null,
                        rules: 'p-decimal:10,2|min_value:0',
                        samples: [10.55,1.64,17.5],
                        convertToNumber: true
                    },
                    {
                        key: 'Retail',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return true;
                            }
                            return (
                                value &&
                                (!pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                })
                                || value < 0)
                            );
                        },
                        rules: 'required|p-decimal:10,2|min_value:0',
                        samples: [12.55,2.75,33.95],
                        convertToNumber: true
                    },
                    {
                        key: 'Description',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return true;
                            }
                            return !max.validate(value, { length: 200 });
                        },
                        warningDefault: '',
                        rules: 'required|max:200',
                        samples: ['"Logo T-Shirt"','"ACADEMY WATERCOLOR"','"HOOD POWERBLEND TWO LOCATION PRINT"']
                    },
                    { 
                        key: 'BrandName',
                        rules: 'max:50',
                        hasWarningCalculator: value => {
                            return (!max.validate(value, { length: 50 }));
                        },
                        warningDefault: '',
                        samples: ['"2-4001"','"AAA001"','"GM 001"']
                    },
                    {
                        key: 'Variant1',
                        selectOptionsFilter: () => {return  '1';},
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"XL"','"XXL"','"XXXL"'],
                        dataType: 'productVariants'
                    },
                    {
                        key: 'Variant2',
                        selectOptionsFilter: () => {return  '2';},
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"Red"','"Gray"','"Navy"'],
                        dataType: 'productVariants'
                    },
                    {
                        key: 'WebsiteDisplayName',
                        canConvertCase: true,
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 400 });
                        },
                        warningDefault: '',
                        rules: 'max:400',
                        samples: ['"Logo T-Shirt"','"ACADEMY WATERCOLOR"','"HOOD POWERBLEND TWO LOCATION PRINT"']
                    },
                    {
                        key: 'WebsiteInventoryTolerance',
                        hasWarningCalculator: (value) => {
                            return !this.isNonNegativeInteger(value);
                        },
                        warningDefault: null,
                        convertToNumber: true,
                        rules: 'integer|min_value:0',
                        samples: [0,0,0]
                    },
                    {
                        key: 'WebsiteLongDescription',
                        canConvertCase: true,
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 400 });
                        },
                        warningDefault: '',
                        rules: 'max:400',
                        samples: ['"Logo T-Shirt"','"ACADEMY WATERCOLOR"','"HOOD POWERBLEND TWO LOCATION PRINT"']
                    },
                    {
                        key: 'ShowOnlineFlag',
                        hasWarningCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        warningDefault: false,
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0',
                        samples: ['"No"','"Yes"','"No"']
                    },
                    {
                        key: 'WebCatalog',
                        hasWarningCalculator: value => {
                            return ( 
                                 !max.validate(value, { length: 1000 }
                                    ));
                        },
                        warningDefault: '',
                        rules: 'max:1000',
                        samples: ['"9-21 test|Gifts"','"ART SUPPLIES"','"OUTERWEAR"']
                    },
                    {
                        key: 'Serialization',
                        hasWarningCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        warningDefault: false,
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0',
                        samples: ['"Yes"','"No"','"No"']
                    }
                ]
            }
        };

    },
    computed:{
        
        textBookImportNotes(){   
            let notes = []
            if(this.newBrandNames.length > 0){
                notes.push(`Brand names not found will be added: ${this.newBrandNames.join(', ')}`)
            }  
            if(this.newCatalogNames.length > 0){
                notes.push(`Catalog names not found will not be associated to the product: ${this.newCatalogNames.join(', ')}`  )
            }

            return notes;
        },

        newBrandNames(){   
                return [...new Set(this.loadedData.filter(x => 
                    !this.selectListOptions.brands.some(s => s.text === x.BrandName) && x.BrandName.length < 50)
                    .map(x => x.BrandName))];
        },

        newCatalogNames()
        {
             
            var splitVal = this.loadedData.filter(x => x.WebCatalog.split('|'));
            var result=[]; 

            for(let i = 0;i<splitVal.length;i++)
            {
                splitVal[i].WebCatalog.split('|').forEach(element => { 
                    result.push(element)
                }); 
            }  
            
            return [...new Set(result.filter(x => 
                !this.selectListOptions.webCatalogs.some(s => s.text === x))
                .map(x => x))];
        }
    },
    methods: {
        isDuplicatedOnImportBarcode(barcode) {
            return (
                !this.isEmpty(barcode)  &&
                this.loadedData.filter(x => x.Barcode === barcode).length > 1
            );
        }
    }
};
