<template>
    <div class="container-fluid content-wrapper">
        <BookVariantSummaries
            :variant-options="bookVariants"
            :product="product"
            :show-add-button="false"
        ></BookVariantSummaries>

        <p-card>
            <b-row class="ml-1">
                <p-button
                    variant="outline-primary"
                    @click="checkForupdate()"
                    :is-busy="isBusy"
                    >Check for Update
                </p-button>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-md="3" cols-xl="4" class="pt-2">
                <b-col>
                    <strong v>Status:</strong>
                    {{ this.product['digitalVariant'].digitalStatus }}
                </b-col>

                <b-col>
                    <strong>Last Refresh:</strong>
                    {{
                        dateFormatter(
                            product['digitalVariant'].lastDigitalRefreshDate
                        )
                    }}
                    {{
                        datetimeToLocalTimeOnlyFormatter(
                            product['digitalVariant'].lastDigitalRefreshDate
                        )
                    }}
                </b-col>
                <b-col>
                    <strong>Last Sold: </strong>
                    <span v-if="product['digitalVariant'].lastSold">
                    {{
                        dateFormatter(
                            product['digitalVariant'].lastSold
                        )
                    }}
                    {{
                        datetimeToLocalTimeOnlyFormatter(
                            product['digitalVariant'].lastSold
                        )
                    }}
                    </span>
                    <span v-else>N/A</span> 
                </b-col>
            </b-row>
        </p-card>

        <p-card v-if="product['digitalVariant']">
            <b-row cols="1" cols-sm="2" cols-md="3" cols-xl="4">
                <b-col>
                    <p-input
                        label="Author"
                        v-model="product.author"
                        :disabled="true"
                    />
                </b-col>
                <b-col>
                    <p-input label="Title" v-model="product.title" />
                </b-col>
                <b-col>
                    <p-number
                        label="Selling Price"
                        v-model.number="product['digitalVariant'].retail"
                        input-type="money"
                        :disabled="true"
                        left-align
                    />
                </b-col>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-md="3" cols-xl="4">
                <b-col>
                    <p-input
                        label="Digital ISBN"
                        v-model="product.digitalIsbn"
                        :disabled="true"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Edition"
                        v-model="product.edition"
                        :disabled="true"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Copyright"
                        v-model="product.copyright"
                        :disabled="true"
                    />
                </b-col>
                <b-col>
                    <p-advanced-select
                        label="DCC"
                        name="DCC"
                        dataType="dcc"
                        :filter="2"
                        v-model.number="product['digitalVariant'].dccId"
                        short-dropdown
                        :disabled="true"
                    />
                </b-col>
            </b-row>
        </p-card>

        <p-card>
            <b-card-title>
                <b-row>
                    <b-col cols="12">
                        <span>Digital Transactions</span>
                        <span class="float-right pl-2">
                            <p-button
                                name="SearchTransactions"
                                v-if="!showSearch"
                                @click="showSearch = true"
                                variant="primary"
                                class=""
                                >Show Transactions</p-button
                            >
                        </span>
                    </b-col>
                </b-row>
            </b-card-title>
            <p-search-table
                api-destination="products"
                :filters="filters"
                sort-by="transactionDate"
                :fields="fields"
                search-destination="searchdigitaltransactions"
                :enable-row-selection="false"
                :sort-desc="true"
                v-show="showSearch"
            >
                <template v-slot:above-table>
                    <b-row class=" mt-2">
                        <b-col md="3" class="ml-md-auto">
                            <p-datepicker
                                label="Digital Transaction Date: From"
                                vid="digitalTransactionStartDate"
                                v-model="filters.digitalTransactionStartDate"
                            />
                        </b-col>
                        <b-col md="3" class="ml-2  mr-2">
                            <p-datepicker
                                label="Digital Transaction Date: To"
                                vid="digitalTransactionEndDate"
                                v-model="filters.digitalTransactionEndDate"
                                rules="dateGreaterOrEqual:@digitalTransactionStartDate"
                                is-end-date
                            />
                        </b-col>
                    </b-row>
                </template>
            </p-search-table>
        </p-card>
    </div>
</template>

<script>
import axios from 'axios';
import BookVariantSummaries from './BookVariantSummaries';
import {
    dateFormatter,
    currencyFormatter,
    datetimeToLocalTimeOnlyFormatter
} from '@/components/Common/Formatters.js';

export default {
    props: {
        product: Object
    },
    components: {
        BookVariantSummaries
    },
    data() {
        return {
            showSearch: false,
            isBusy: false,
            filters: {
                automatic: '',
                searchBy: 'All',
                digitalTransactionStartDate: null,
                digitalTransactionEndDate: null,
                options: [
                    'All',
                    'Transaction #',
                    'Customer Name',
                    'Customer Account #'
                ]
            },
            fields: [
                {
                    label: 'Transaction #',
                    key: 'transactionNumber',
                    sortable: true
                },
                {
                    label: 'Customer Name',
                    key: 'customerName',
                    sortable: true
                },
                {
                    label: 'Customer Account #',
                    key: 'customerAccountNumber',
                    sortable: true
                },
                {
                    label: 'Transaction Date',
                    key: 'transactionDate',
                    sortable: true,
                    formatter: dateFormatter
                }
            ],
            bookVariants: ['new', 'used', 'digital', 'trade']
        };
    },

    methods: {
        datetimeToLocalTimeOnlyFormatter: datetimeToLocalTimeOnlyFormatter,
        dateFormatter: dateFormatter,
        currencyFormatter: currencyFormatter,
        accessBasket(transaction) {
            this.selectedTransaction = null;
            axios
                .get('customers/getTransaction', {
                    params: { transactionId: transaction.transactionId }
                })
                .then(resp => {
                    this.selectedTransaction = resp.data;
                    this.$bvModal.show('customerTransactionModal');
                });
        },

        checkForupdate: async function() {
            this.isBusy = true;
            let isbns = [];
            isbns.push(this.product.digitalIsbnDigits);
            let response = await axios.post(
                'courserequests/checkdigital',
                isbns
            );
            let refreshData = response.data[0];

            this.product['digitalVariant'].retail = refreshData.retail;

            this.product['digitalVariant'].digitalStatus =
                refreshData.digitalStatus;

            this.product['digitalVariant'].lastDigitalRefreshDate =
                refreshData.lastDigitalRefreshDate;
            this.isBusy = false;
        }
    }
};
</script>
