<template>
    <p-modal
        ref="editSupplierModal"
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="xl"
        :label="pageTitle"
        @ok.prevent="handleModalOk"
        @show="loadData"
    >
        <p-form ref="form">
            <template v-slot:default>
                <div>
                    <b-row >
                        <b-col cols="9" sm="6" xl="3">
                            <p-advanced-select
                                data-type="suppliers"
                                :disabled="!isNew"
                                label="Supplier Name"
                                rules="required"
                                :filter="supplierFilter"
                                v-model="selectedSupplier"
                            />
                        </b-col>
                        <b-col cols="3" sm="6" xl="1">
                            <p-checkbox
                                class="mt-3 pt-3"
                                label="Default"
                                :disabled="isDisabled"
                                v-model="supplier.isDefault"
                            />
                        </b-col>
                        <b-col cols="12" sm="6" xl="3">
                            <p-input
                                label="Supplier Catalog #"
                                v-model="supplier.catalogNumber"
                                rules="required|max:50"
                            />
                        </b-col>
                        <b-col cols="12" sm="6" xl="5">
                            <p-input
                                label="Description"
                                rules="required|max:250"
                                v-model="supplier.description"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="3">
                        <b-col>
                            <p-number
                                input-type="integer"
                                name="Purchase Unit"
                                label="Purchase Unit"
                                rules="required|min_value:1"
                                v-model="supplier.purchaseUnit"
                            />
                        </b-col>
                        <b-col
                            ><p-number
                                input-type="money"
                                name="Cost Per Unit"
                                label="Cost Per Unit"
                                rules="required|min_value:0.00"
                                v-model="supplier.costPerUnit"
                        /></b-col>
                        <b-col>
                            <p-number
                                input-type="integer"
                                name="Auto Order Qty"
                                label="Auto Order Qty"
                                rules="min_value:1"
                                v-model="supplier.autoOrderQty"
                            />
                        </b-col>
                    </b-row>
                </div>
            </template>
        </p-form>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row class="mb-3">
                <p-button variant="outline-primary" @click="cancel"
                    >Cancel</p-button
                >
                <p-button variant="primary" @click="ok" class="ml-2"
                    >Save</p-button
                >
            </b-row>
        </template>
    </p-modal>
</template>
<script>
export default {
    props: {
        vendorId: [String, Number],
        product: Object,
        productFamily: Object
    },
    data() {
        return {
            supplier: null,
            selectedSupplier:null
        };
    },
    computed: {
        isNew() {
            return this.vendorId === 'new';
        },
        buttonLabel() {
            return this.isNew ? 'Add Supplier' : 'Edit';
        },
        pageTitle() {
            return this.isNew ? 'Add Supplier' : 'Edit Supplier';
        },
        isDisabled() {
            return (this.productFamily.suppliersProperties === null || this.productFamily.suppliersProperties.length === 0 || (!this.isNew && this.productFamily.suppliersProperties.length === 1));
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        loadData() {
            if (this.isNew) {
                this.selectedSupplier=null;
                this.supplier = {
                    catalogNumber: this.product.sku === null ? null : this.product.sku.toString(),
                    description: this.productFamily.description,
                    purchaseUnit: 1,
                    costPerUnit: 0.0,
                    autoOrderQty: 1,
                    vendorId: null,
                    isDefault: this.productFamily.suppliersProperties === null || this.productFamily.suppliersProperties.length === 0
                };
            } else {
                this.selectedSupplier=this.vendorId;
                const item = this.productFamily.suppliersProperties.filter(
                    supplier => supplier.vendorId === this.vendorId
                )[0];
                this.supplier = JSON.parse(JSON.stringify(item));
            }
        },
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            let name = this.$store.getters.getSupplierById(
                this.selectedSupplier
            ).text;
            this.supplier.vendorName = name;
            this.supplier.vendorId=this.selectedSupplier;
            this.$emit('load-data', this.supplier);
            this.$refs.editSupplierModal.hide();
        },
        supplierFilter(sup) {
            return (
                !this.isNew ||
                !(this.productFamily.suppliersProperties && this.productFamily.suppliersProperties.some(
                    x => x.vendorId === sup.value
                ))
            )

        }
    }
};
</script>
<style scoped></style>
