<template>
    <p-form ref="form">
        <p-modal
            button-label='Print Shelf Tag'
            size='lg'
            label='Shelf Tag'
            ref='shelfTagModal'
            @ok.prevent='handleModalOk'
        :disable-button="disabled"
        >
            <template v-slot:default>
                <div class='container-fluid content-wrapper'>
                    <b-row cols='1' cols-md='2'>
                        <b-col>
                            <p-select
                                label='Location'
                                v-model.number='shelfTag.locationId'
                                :disabled='true'
                                data-type='locations'
                            />

                            <p-select
                                label='Shelf Tag Type'
                                text-field='value'
                                :options='shelfTagType'
                                v-model='parameters.shelfTagType'
                            />

                            <p-select
                                label='Sort Order'
                                :options='sortOrderOptions'
                                text-field='value'
                                v-model='parameters.sortOrder'
                            />

                            <p-checkbox
                                size='md'
                                label='Print only tags that have not yet been printed'
                                v-model='parameters.printOnlyNotPrinted'
                            ></p-checkbox>
                            <p-checkbox
                                size='md'
                                label='Include blank course requests'
                                v-model='parameters.includeBlankCourseRequests'
                            ></p-checkbox>
                            <p-checkbox
                                size='md'
                                label='Exclude pricing information'
                                v-model='parameters.excludePricingInformation'
                                class='pb-2'
                            ></p-checkbox>
                            <div>
                                <span>Margins</span>
                                <b-row class='pt-2'>
                                    <b-col cols='2'>
                                        <p-number label='Top' rules='min_value:0|max_value:10' v-model='parameters.topMargin' compact-format />
                                    </b-col>
                                    <b-col class='ml-5' >
                                        <p-number label='Bottom' rules='min_value:0|max_value:10' v-model='parameters.bottomMargin' compact-format />
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <b-col>
                            <p-card class='mb-2 px-2 py-2' no-body>
                                <p-filter-select
                                    data-type='courseRequestBookStatuses'
                                    label='Request Type'
                                    v-model='parameters.CourseRequirementStatusIds'
                                    :initial-display-count= '8'
                                    :default-selected='true'
                                />
                            </p-card>
                            <p-card class='mb-2 pl-2 py-0' no-body >
                                <course-sections-selector
                                    :course-request='courseRequest'
                                    class='pt-2'
                                    :filter-course-data="filterCourseData"
                                    :show-closed-term-option="false"
                                    :show-expand-all-option="showExpandAllOption"
                                    @selection-change='parameters.courseSections = $event'
                                />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:modal-footer='{ ok, cancel, hide }'>
                <b-row>
                    <b-col>
                        <p-button variant='outline-primary' @click='cancel'>Cancel</p-button>
                        <p-button variant='primary' @click='ok' :is-busy="isBusy" class='ml-2'>Export PDF</p-button>
                    </b-col>
                </b-row>
            </template>
        </p-modal>
    </p-form>
</template>

<script>
import axios from 'axios';
import store from '@/store/store.js';
import courseSectionsSelector from '@/components/CourseSectionsSelector.vue';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    props: {
        hideCourseSelector: {
            type: Boolean,
            default: false
        },
        courseRequest: {
            type: Object,
            default: null
        },
         disabled: {
            type: Boolean,
            default: false
        },
        showClosedTermOption: {
            type: Boolean,
            default: true
        },
        showExpandAllOption: {
            type: Boolean,
            default: false
        },
        filterCourseData: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            parameters: {
                sortOrder:'Term/Dept/Course/Section',
                CourseRequirementStatusIds: [],
                courseSections: [],
                shelfTagType:'Single Title',
                printOnlyNotPrinted: false,
                includeBlankCourseRequests: false,
                combineLikeSections: false,
                excludePricingInformation:false,
                topMargin: 5,
                bottomMargin: 5
            },
            isBusy : false,
            sortOrderOptions: [
                { value: 'Term/Dept/Course/Section' },
                { value: 'Term/Course/Section/Dept' },
                { value: 'Term/Instructor/Dept/Course' },
                { value: 'Term/Title' },
            ],
            shelfTagType: [{ value: 'Multi Title' }, { value: 'Single Title' }],
            shelfTag: [],
        };
    },
    components: {
        courseSectionsSelector
    },
    methods: {
        handleModalOk: async function (event) {
            if(!(await this.$refs.form.validate()))
            {
                return;
            }
            if (this.parameters.courseSections.length === 0) {
                event.preventDefault();
                this.$toasted.global.app_error('Please select one or more course sections.');
                return;
            }
            this.isBusy = true;
            await axios
                .post('shelftags/exportShelfTag', this.parameters, { responseType: 'blob' })
                .then((result) => {
                    downloadFileToBrowser(result.data, 'Shelf Tags', 'application/pdf');
                    this.$refs.shelfTagModal.hide();
                    this.$toasted.global.app_success(`Shelf Tag generated successfully.`).goAway(5000);
                 }).finally(() => {
                        this.isBusy = false;
                    });
        },
        getCourseRequestBookStatuses: function () {
            return store.getters.getCourseRequestBookStatuses;
        },
    },
    mounted: function () {
        axios.get('shelfTags/default').then((resp) => {
            this.shelfTag = resp.data;
        });
    },
};
</script>
