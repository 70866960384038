<template>
    <p-modal
        name='openPurchaseOrderProductsModal'
        label='Open Purchase Order Products Report'
        size='md'
        @hide='resetFilters'
        >
        <template v-slot:default>
            <b-row>
                <b-col>
                    <p-advanced-select data-type='terms'
                        label='Term'
                        v-model='filters.termId'
                        add-empty-option />
                </b-col>
                <b-col>
                    <b-form-group label='Product Types'>
                        <b-form-checkbox-group
                            v-model='filters.productTypes'
                            :options='selectListOptions.productTypes'
                            name='Product Type'
                        ></b-form-checkbox-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p-advanced-select data-type='suppliers'
                        label='Supplier'
                        v-model='filters.supplierId'
                        add-empty-option />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p-datepicker label='Start Date'
                        v-model='filters.startDate' />
                </b-col>
                <b-col>
                    <p-datepicker label='End Date'
                        v-model='filters.endDate' />
                </b-col>
            </b-row>
        </template>
        <template v-slot:modal-footer>
                 <p-button @click='$bvModal.hide("openPurchaseOrderProductsModal")'
                            variant='outline-primary'>Cancel</p-button>
                 <b-overlay :show='isBusy'
                                    rounded
                                    opacity='0.5'
                                    spinner-small
                                    spinner-variant='primary'
                                    class='d-inline-block'>
                        <b-dropdown
                            text='Export'
                            variant='primary'
                        >
                            <b-dropdown-item
                                @click='exportOpenPurchaseOrderProducts("pdf")'
                                >PDF</b-dropdown-item
                            >
                            <b-dropdown-item
                                @click='exportOpenPurchaseOrderProducts("csv")'
                                >CSV</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-overlay>
             </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
    mixins: [StoreListManager],
    data() {
        return {
            filters: {
                termId: null,
                supplierId: null,
                productTypes: [1, 2],
                startDate: null,
                endDate: null
            },
            isBusy: false,
            selectListOptions: { productTypes: [] }
        };
    },
    methods: {
        exportOpenPurchaseOrderProducts(exportFormat) {
            this.isBusy = true;
            switch(exportFormat)
            {
                case 'pdf':
                    axios.post('openpurchaseorderProducts/export', this.filters, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Open Purchase Order Products Report', 'application/pdf');
                                this.$toasted.global.app_success(`Open Purchase Order Products report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport('openPurchaseOrderProductsModal');
                        });
                    break;
                case 'csv':
                    axios.post('openpurchaseorderProducts/exportcsv', this.filters, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Open Purchase Order Products Report.csv', 'application/csv');
                                this.$toasted.global.app_success(`Open Purchase Order Products report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport('openPurchaseOrderProductsModal');
                        });
                    break;
            }
        },
        resetFilters: function(){
            this.filters = {
                termId: null,
                supplierId: null,
                productTypes: [1, 2],
                startDate: null,
                endDate: null
            };
        },
        finalizeExport(modalName) {
            this.isBusy = false;
            this.$bvModal.hide(modalName);
        }
    }
}
</script>