<template>
    <p-feature-flag
        name="Accounting" 
        redirect
    >
        <SearchScreen
            title="Chart of Accounts"
            ref="searchScreen"
            api-destination="chartofaccounts"
            edit-key="chartOfAccountsId" 
            :filters="filters"
            :fields="fields"
            :filterBy="filterBy"
            add-new-button
        >
            <template v-slot:belowSearch>
                <b-row class="ml-2 mr-1 mt-2">
                    <b-col cols="12" sm="4" xl="2">
                        <p-select
                                label="Location"
                                data-type="locations"
                                v-model="filters.locationFilter"
                                addNullOption="All Locations"
                            />
                    </b-col>
                    <b-col cols="12" sm="4" xl="2">
                        <p-select
                                label="Type"
                                data-type="chartOfAccountsTypes"
                                v-model="filters.chartOfAccountsTypeFilter"
                                addEmptyOption
                            />
                    </b-col>
                    <b-col cols="12" sm="4" xl="2">
                        <p-select
                            label="Category"
                            data-type="chartOfAccountsCategories"
                            v-model="filters.categoryFilter"
                            addEmptyOption
                        />
                    </b-col>
                    <b-col cols="12" sm="4" xl="2">
                        <p-select
                                label="Debit/Credit"
                                data-type="debitOrCreditOptions"
                                v-model="filters.debitCreditFilter"
                                addEmptyOption
                            />
                    </b-col>
                </b-row>
            </template>
        </SearchScreen>
    </p-feature-flag>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            isBusy: false,
            filterName: 'ChartOfAccountsFilter',
            filters: {
                automatic: null,
                perPage: 100,
                sortBy: 'ledgerNumber',
                sortDesc: true,
                locationFilter: {},
                chartOfAccountsTypeFilter: null,
                categoryFilter: null,
                debitCreditFilter: null,
                searchBy: 'All'
            },
            filterBy: [
                'All',
                'Ledger Number',
                'Description'
            ],
            fields: [
                {
                    key: 'location',
                    sortable: true,
                    label: 'Location'
                },
                {
                    key: 'ledgerNumber',
                    sortable: true,
                    label: 'Ledger Number'
                },
                {
                    key: 'chartOfAccountsType',
                    sortable: true,
                    label: 'Type'
                },
                {
                    key: 'category',
                    sortable: true,
                    label: 'Category'
                },
                {
                    key: 'debitOrCredit',
                    sortable: true,
                    label: 'Debit/Credit'
                },
                {
                    key: 'description',
                    sortable: true,
                    label: 'Description'
                },
                {
                    key: 'chartOfAccountsId',
                    hidden: true
                }
            ]
        };
    },
    mounted: async function () {
        this.filters.locationFilter = await selectListOptionsDataContext.getSelectedLocationIdAsync();
    }
};
</script>