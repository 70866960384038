<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="courseDepartment">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Academic Department</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary"
                        >Save</b-button
                    >
                </b-col>
            </b-row>

            <p-card>
                <b-row cols="1" cols-lg="2">
                    <b-col>
                        <b-col>
                            <p-input
                                label="Name"
                                v-model="courseDepartment.name"
                                rules="required|max:80"
                            />
                            <p-input
                                label="Description"
                                v-model="courseDepartment.description"
                                rules="required|max:255"
                            />
                            <p-input
                                label="Code"
                                v-model="courseDepartment.code"
                                rules="max:20"
                            />
                            <p-select
                                label="Campus"
                                dataType="campuses"
                                :disabled="!isNew"
                                v-model="courseDepartment.campusId"
                                rules="required"
                            />
                            <p-checkbox
                                label="Is Active?"
                                v-model="courseDepartment.isActive"
                                class="pb-4"
                            />
                        </b-col>
                    </b-col>
                </b-row>
            </p-card>
            <b-row>
                <b-col class="text-center">
                    <b-button variant="primary" type="submit"
                        >Save</b-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default {
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            courseDepartment: null
        };
    },
    watch: {
        courseDepartment: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        }
    },
    methods: {
        onSubmit() {
            axios
                .post('courseDepartments', this.courseDepartment)
                .then(response => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push(
                            '/academicdepartments/' + response.data
                        );
                    }
                    this.$toasted.global
                        .app_success(
                            `Course department '${this.courseDepartment.name}' saved successfully.`
                        )
                        .goAway(5000);
                });
            // todo error handling
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('courseDepartments/new')
                : axios.get('courseDepartments', { params: { id: this.id } });

            promise.then(resp => {
                this.courseDepartment = resp.data;
                if (!this.isNew) {
                         this.dataLoaded();
                    }
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
