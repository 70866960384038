<template>
    <SearchScreen
        title="Course Requests"
        api-destination="courserequests"
        edit-key="courseRequestId"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        :edit-url-func="item => `/courserequests/${item.courseRequestId}`"
        add-new-button
        ref="search"
    >
        <template v-slot:actionButtons>
            <b-row class="pl-3">
                <router-link
                    to="/courserequests/new"
                    class="btn btn-primary mr-2"
                >
                    <b-icon-plus></b-icon-plus> Add
                </router-link>
                <p-button
                    @click="workRequests"
                    variant="outline-primary"
                    :disabled="filters.showInActive"
                >
                    Work Requests
                </p-button>

                <shelfTag class="ml-2"></shelfTag>
            </b-row>
        </template>

        <template v-slot:filters>
            <h4 class="m-0 pb-3">Filters</h4>
            <!-- no-body allows for us to set smaller padding -->
            <p-card class="mb-2 px-2 py-2" no-body>
                <p-filter-select
                    dataType="terms"
                    label="Terms"
                    :filter="termFilter"
                    v-model="filters.termIds"
                />
            </p-card>
            <p-card class="mb-2 px-2 py-2" no-body>
                <p-filter-select
                    dataType="instructors"
                    label="Instructors"
                    use-text-for-value
                    v-model="filters.instructorNames    "
                />
            </p-card>

            <p-card class="mb-2 px-2 py-2" no-body>
                <p-filter-select
                    dataType="courseDepartments"
                     use-text-for-value
                    label="Departments"
                    v-model="filters.courseDepartmentNames"
                />
            </p-card>

            <p-card class="mb-2 px-2 py-2" no-body>
                <p-filter-select
                    dataType="courses"
                    label="Courses"
                     use-text-for-value
                    no-match-message="No Matches In Selected Depts"
                    :filter="courseFilter"
                    v-model="filters.courseNames"
                />
            </p-card>
            <p-card class="mb-2 px-2 py-2" no-body>
                <p-filter-select
                    :options="sectionOptions"
                    label="Sections"
                    no-match-message="No Matches In Selected Courses"
                    v-model="filters.sectionNames"
                />
            </p-card>
        </template>
        <template v-slot:belowSearch>
            <b-form>
                <b-form-group label-for="automatic" class="ml-3 mr-2">
                    <b-row class="ml-1 mt-4 mr-2">  
                    <p-checkbox
                        label="Open"
                        v-model="filters.open"
                    />
                    <p-checkbox
                        label="Completed"
                        class="ml-3"
                        v-model="filters.completed"
                    />
                    <p-checkbox
                        label="Incoming"
                        class="ml-3"
                        v-model="filters.incoming"
                    />
                    <p-checkbox
                        label="Closed"
                        class="ml-3"
                        v-model="filters.closed"
                    />
                    </b-row>
                </b-form-group>
            </b-form>
        </template>
    </SearchScreen>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import lodashUnion from 'lodash/union';
import shelfTag from '../../components/ShelfTag.vue';
import store from '@/store/store.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
    mixins: [StoreListManager, SearchStorage],
    data() {
        return {
            index: 0,
            filterName: 'CourseRequestsFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'termdepartmentcoursesection',
                sortDesc: true,
                searchBy: 'All',
                instructorNames: [],
                courseDepartmentNames: [],
                courseNames: [],
                termIds: [],
                sectionNames: [],
                open:true,
                completed:true,
                incoming:true,
                closed: false
            },
            filterBy: [
                'All',
                'Description',
                'Catalog #',
                'ISBN',
                'Barcode',
                'Book Key'
            ],
            courses: [],
            fields: [
                {
                    key: 'termDepartmentCourseSection',
                    label: 'Term/Dept/Course/Sec',
                    sortable: true
                },
                {
                    key: 'instructor',
                    sortable: true
                },
                {
                    key: 'courseDescription',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'adoptionStatus',
                    sortable: true
                }
            ]
        };
    },
    computed: {
        sectionOptions() {
            return lodashUnion(
                this.courses
                    .filter(x => this.filters.courseNames.indexOf(x.text) > -1)
                    .flatMap(x => x.sections)
                    .map(x => x.sectionName)
            ).map(x => ({ value: x, text: x }));
        }
    },
    mounted: async function() {
        this.loadCourses();
    },
    methods: {
         courseFilter(option) {
                         return  this.filters.courseDepartmentNames.includes(option.departmentName);
        },
        termFilter(option){
            return  option.filter == "True"
        },
        workRequests: function() {
            axios
                .get('courserequests/getidlist', {
                    params: this.$refs.search.getQueryParameters()
                })
                .then(resp => {
                    var ids = resp.data;
                    this.$router.push({
                        name: 'workcourserequests',
                        params: { ids }
                    });
                });
        },
        loadCourses: async function() {
            this.courses = await selectListOptionsDataContext.getStoreDropdownData(
                'courses'
            );
        }
    },
    created() {
        this.unsubscribe = store.subscribe(mutation => {
            if (
                mutation.type.toLowerCase().includes('courses') &&
                mutation.payload.length > 0
            ) {
                this.courses = mutation.payload;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
    components: {
        SearchScreen,
        shelfTag
    }
};
</script>

<style scoped></style>
