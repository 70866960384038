<template>
    <p-card v-if="variant" :key="this.isNew">
        <b-card-title class="text-capitalize">{{ type }}</b-card-title>

        <b-row cols="1" cols-sm="2" cols-lg="3">
            <b-col>
                <p-input :value="variant.sku" label="SKU" :disabled="true">
                </p-input>
            </b-col>
            <b-col>
                <p-input
                    label="Default Barcode"
                    name="Default Barcode"
                    v-model="variant.defaultBarcode"
                    :rules="isNew ? '' : 'required'"
                    :disabled="true"
                >
                    <template v-slot:append>
                        <p-button @click="openBarcodes" class="pl-0">
                            <i class="fas fa-edit"></i>
                        </p-button>
                    </template>
                </p-input>
            </b-col>
            <b-col
                ><p-number
                    input-type="integer"
                    name="SOH"
                    readonly
                    label="Stock on Hand"
                    v-model.number="variant.stockOnHand"
                >
                    <template v-slot:append>
                        <b-button @click="sohModal" style="white-space:nowrap;"
                            >Adjust SOH</b-button
                        >
                    </template>
                </p-number>
            </b-col>
            <b-col>
                <p-advanced-select
                    label="DCC"
                    name="DCC"
                    dataType="dcc"
                    :filter="2"
                    v-model.number="variant.dccId"
                    @change="dccChanged"
                    rules="required"
                    short-dropdown
                />
            </b-col>
            <b-col>
                <p-select
                    data-type="priceTagTypes"
                    label="Price Tag Type"
                    v-model="variant.priceTagTypeId"
                    rules="required"
                >
                    <template v-slot:append>
                        <p-feature-flag name="Barcode Print">
                            <b-button
                                            class=" px-2"
                                            style="white-space:nowrap;"
                                             variant="outline-primary"                
                                             @click="showPrintLabel"
                                            > Print Label 
                                    </b-button >
                            <PrintLabel
                                v-if="
                                    variant.priceTagTypeId &&
                                        variant.defaultBarcode && variant.productInventoryId > 0
                                "
                                :product="variant"
                                :type="type"
                                ref="printLabelModalBook"
                            />
                        </p-feature-flag>
                    </template>
                </p-select>
            </b-col>
            <b-col>
                
                    <p-input :value="variant.onOrder" label="On Order" disabled >
                    </p-input>
                  
            </b-col>

            <b-col>
                <p-number
                    input-type="money"
                    name="Cost"
                    label="Cost"
                    v-model.number="variant.cost"
                    rules="required|min_value:0"
                    @change="calculateMargin()"
                    left-align
                >
                </p-number>
            </b-col>
            <b-col>
                <p-number
                    input-type="percent"
                    disabled="disabled"
                    label="Current Margin"
                    v-model.number="variant.margin"
                    left-align
                />
            </b-col>
            <b-col>
                <p-number
                    input-type="money"
                    name="Retail"
                    label="Selling Price"
                    v-model.number="variant.retail"
                    rules="required|min_value:0"
                    @change="calculateMargin()"
                    left-align
                >
                </p-number>
            </b-col>
        </b-row>
    <b-row>
            <b-col cols="8">
                <b-row class="mt-2 mb-4 mx-2">
                    <p-checkbox
                            label="Buyback Title"
                            class="mr-2 in"
                            v-model="variant.isBuybackTitle"
                            :disabled="type === 'trade'"
                        /><p-checkbox
                            class="mr-2"
                            label="Wantlist Title"
                            v-model="variant.isWantlistTitle"
                            :disabled="type === 'trade'"
                        />
                        <p-checkbox
                            label="Discontinued"
                            v-model="variant.isDiscontinued"
                        />
                </b-row>
            </b-col>
            <b-col cols="4">
                  <strong>Last Sold: </strong>
                  <span v-if="variant.lastSold && !isNewVariant">
                      {{   dateFormatter(variant.lastSold) }}
                      {{   datetimeToLocalTimeOnlyFormatter(variant.lastSold) }}
                  </span>
                <span v-else>N/A</span>
                  
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="variant.sku">
                <p-secure :require-role="[SECURITYROLES.MERCHANDISEMANAGER]">
                    <b-button
                        class="text-capitalize"
                        :name="type + '-delete-button'"
                        variant="outline-danger"
                        @click="deleteProduct"
                    >
                        Delete {{ type }}
                    </b-button>
                </p-secure>
            </b-col>
            <b-col v-else-if="type == 'new'"></b-col>
            <b-col v-else>
                <b-button variant="outline-primary" @click="removeVariant"
                    ><b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
                    Undo</b-button
                >
            </b-col>
        </b-row>
        <PriceCalculator
            :name="`${type}variantPriceCalculator`"
            :initial-values="variant"
            :parameters="parameters"
        ></PriceCalculator>
    </p-card>
</template>

<script>
import PrintLabel from './PrintLabel.vue';
import PriceCalculator from './PriceCalculator.vue';
import pricing from '@/services/PricingCalculations.js';
import { dateFormatter, datetimeToLocalTimeOnlyFormatter } from '@/components/Common/Formatters.js';

export default {
    props: {
        variant: Object,
        parameters: Object,
        type: String,
        isNewVariant: Boolean
    },
    data() {
        return {
            barcode: null,
        };
    },
    methods: {
        dateFormatter: dateFormatter,
        datetimeToLocalTimeOnlyFormatter: datetimeToLocalTimeOnlyFormatter,
        async showPrintLabel(){
            if(this.variant.priceTagTypeId && this.variant.defaultBarcode && this.variant.productInventoryId == 0 || this.variant.productInventoryId == null){
                await this.$bvModal
                    .msgBoxOk(
                        'You must save the product before you can print this label.'
                    )
            }else if(this.variant.priceTagTypeId && this.variant.defaultBarcode && this.variant.productInventoryId > 0){
                this.$emit('savePage',this.type);
            }
        },
        sohModal() {
            if (this.variant.isPhysical) {
                this.$emit('sohModal', this.variant);
            }
        },
        removeVariant() {
            this.$emit('removeVariant', this.type);
        },
        openBarcodes() {
            this.$emit('openBarcodes', this.variant);
        },
        dccChanged() {
            this.$emit('dccChanged', this.variant);
        },
        forceUpdate() {
            this.$forceUpdate();
        },

        calculateMargin() {
            this.variant.margin = pricing.computeMargin(
                this.variant.cost,
                this.variant.retail
            );
        },
        deleteProduct() {
            this.$emit('deleteVariant');
        }
    },
    computed: {
        isNew() {
            return !this.variant.sku;
        }
    },
    components: {
        PrintLabel,
        PriceCalculator
    }
};
</script>

