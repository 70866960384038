<template>
    <p-modal
        name="exportSalesByDccModal"
        label="Export Sales By DCC"
        ref="exportSalesByDccModal"
        size="lg"
        @ok.prevent="handleModalOk"
    >
     <template v-slot:default>
            <p-form ref="salesByDccForm">
                <div class="container-fluid content-wrapper">
                    <b-row cols="1" cols-lg="2">
                        <b-col>
                            <p-card title="POS Devices" class="static-height">
                                <p-filter-select
                                    data-type="posDevices"
                                    rules="required"
                                    :default-selected="true"
                                    :initial-display-count="10"
                                    v-model="reportParameters.registerIds"
                                />
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card title="Date Range">
                                <p-date-range :date-range-options="reportParameters" />
                            </p-card>
                            <p-card title="Additional Options">
                                <b-row>
                                    <b-col>
                                         <p-checkbox size="md"
                                        label="DCC Details by Device"
                                        v-model="
                                    reportParameters.dccDetailsByDevice
                                " />
                            <p-checkbox size="md"
                                        label="DCC Summary for Selected Devices"
                                        v-model="
                                    reportParameters.dccSummaryForSelectedDevices
                                " />
                                    </b-col>
                                </b-row>
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
          <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="cancel"
                        >Cancel</p-button
                    >
                    <p-button
                        variant="primary"
                        @click="ok"
                        :is-busy="isBusy"
                        class="ml-2"
                        >OK</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    data() {
        return {
            isBusy: false,
            reportParameters: {
                registerIds: [],
                startDate: null,
                endDate: null,
                dateOption: 0,
                dccDetailsByDevice:false,
                dccSummaryForSelectedDevices:false,
                locationId: null
            }
        };
    },
    computed: {
        pageTitle() {
            return 'Sales By Dcc';
        }
    },
    methods: {
        async show() {
            this.reportParameters.registerIds = [];
            this.reportParameters.dccDetailsByDevice = true;
            this.reportParameters.dccSummaryForSelectedDevices = false;
            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.$refs.exportSalesByDccModal.show();
        },
        handleModalOk: async function() {

            if (!(await this.$refs.salesByDccForm.validate())) {
                return;
            }

            if (this.reportParameters.registerIds.length === 0) {
                this.$toasted.global.app_error(
                    'Please select one or more Pos Devices.'
                );
                return;
            }

            if (!this.reportParameters.dccDetailsByDevice && !this.reportParameters.dccSummaryForSelectedDevices) {
                this.$toasted.global.app_error(
                    'Please select one or more Additional Options.'
                );
                return;
            }

            this.isBusy = true;

            await axios
                .post('/sales/exportSalesByDcc', this.reportParameters, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Sales By Dcc',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(`Sales By DCC generated successfully.`)
                        .goAway(5000);
                    this.$refs.exportSalesByDccModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>

<style scoped>
.static-height {
    height:370px
}
</style>
