<template>
    <p-modal
        @ok="exportCourseListReport"
        hide="reportToExport = null"
        size="lg"
        name="exportCourseListModal"
        label="Export Course List"
        ref="exportCourseListModal"
        @show="initialize"
    >
        <template v-slot:default>
            <p-form ref="courseListForm">
                <div class="container-fluid content-wrapper">
                    <b-row cols="1" cols-md="2">
                        <b-col>
                            <p-checkbox
                                label="Include Retail Prices"
                                v-model="
                                    courseListParameters.includeRetailPrices
                                "
                            />
                            <p-checkbox
                                label="Include Stock On Hand"
                                v-model="
                                    courseListParameters.includeStockOnHand
                                "
                            />
                            <p-checkbox
                                label="Include QTP"
                                v-model="courseListParameters.includeQtp"
                            />
                            <p-checkbox
                                label="Include Enrollment (Estimated/Actual)"
                                v-model="courseListParameters.includeEnrollment"
                            />
                            <p-checkbox
                                label="Include Price Disclaimer"
                                v-model="
                                    courseListParameters.includePriceDisclaimer
                                "
                            />
                            <p-checkbox
                                label="Include ISBNs"
                                v-model="courseListParameters.includeIsbn"
                            />
                            <p-checkbox
                                label="New Page Per Department"
                                v-model="
                                    courseListParameters.newPagePerDepartment
                                "
                            />
                        </b-col>
                        <b-col>
                            
                            <p-advanced-select
                                v-if="!termId"
                                v-model="selectedTermId"
                                label="Term"
                                data-type="terms"
                                rules="required"
                            ></p-advanced-select>
                            <p-card class="mb-2 pl-2 py-2" no-body>
                                <label
                                    class="text-sm"
                                    v-if="courseSectionSelectHelpText"
                                    >{{ courseSectionSelectHelpText }}</label
                                >
                                <course-sections-selector
                                    class="pt-2"
                                    @selection-change="
                                        courseListParameters.courseSections = $event
                                    "
                                    :course-request="mockCourseRequest"
                                    filter-course-data
                                    :select-filtered-data="false"
                                    :show-closed-term-option="false"
                                    :show-expand-all-option="true"
                                />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button :is-busy="isBusy" variant="outline-primary" @click="cancel">Cancel</p-button>
                    <p-button :is-busy="isBusy" variant="primary" @click="ok" class="ml-2">OK</p-button>
                </b-col>
            </b-row>
        </template>
    </p-modal>
        
</template>

<script>

import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import CourseSectionsSelector from '@/components/CourseSectionsSelector';

export default {
    props: {
        termId: {
            type: Number,
            required: false
        },
    },
    data() {
        return {            
            selectedTermId: null,
            isBusy: false,
            courseListParameters:  null
        };
    },
    components: {
         CourseSectionsSelector
    },
    computed:{      
        mockCourseRequest(){
            return {termId: this.termId || this.selectedTermId}
        },
        courseSectionSelectHelpText() {
            if(!this.termId && !this.selectedTermId){
                return 'Please select a term to select sections from.'
            }
            return this.courseListParameters.courseSections.length === 0
                ? 'ALL sections for this term will be included in the course list, you may select individual sections below.'
                : '';
        }
    },
    methods: {            
        async exportCourseListReport(bvModalEvt) {            
            bvModalEvt.preventDefault();
            if (!(await this.$refs.courseListForm.validate())) {
                return;
            }

            this.courseListParameters.termId = this.termId || this.selectedTermId;
            this.isBusy = true;

            axios
                .post('/terms/exportCourseList', this.courseListParameters, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Course List',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            `Course list report generated successfully.`
                        )
                        .goAway(5000);
                    this.$refs.exportCourseListModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                    this.courseListParameters.courseSections = [];
                });
        },
        initialize(){
            this.selectedTermId = null;
            this.courseListParameters = {
                termId: null,
                includeRetailPrices: false,
                includeStockOnHand: false,
                includeQtp: false,
                includeEnrollment: false,
                includePriceDisclaimer: false,
                includeIsbn: false,
                newPagePerDepartment: false,
                courseSections: []
            }
        },
        show() {
            this.$refs.exportCourseListModal.show();
        },
    }
};

</script>
