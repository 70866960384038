<template>
    <b-row cols="1" cols-lg="2" cols-xl="4">
        <b-col v-for="v in variantOptions" :key="v">
            <p-card
                class="variant-card"
                :name="`${v}Variant-Summary`"
                v-if="product[`${v}Variant`]"
            >
                <b-col>
                    <span class="text-center">
                        <strong class="text-capitalize">{{ v }}</strong
                        ><br />

                        <strong>Sell: </strong>
                        {{ currencyFormatter(product[`${v}Variant`].retail)
                        }}<br />

                        <!-- todo: rental price -->

                        <span v-if="product[`${v}Variant`].isPhysical == 0">
                            <strong>Last Refresh: </strong>
                            {{
                                dateFormatter(
                                    product[`${v}Variant`]
                                        .lastDigitalRefreshDate
                                )
                            }}
                        </span>

                        <span v-if="product[`${v}Variant`].isPhysical == 1">
                            <strong>Stock on Hand: </strong>
                            {{ product[`${v}Variant`].stockOnHand }}
                        </span>
                        <br />
                        <div v-if="product.rentalData">
                            <span
                                v-if="
                                    product[`${v}Variant`].isPhysical == 1 &&
                                        v == 'new'
                                "
                            >
                                <strong> Rent: </strong>
                                {{
                                    currencyFormatter(
                                        product.rentalData.newRentalPrice
                                    )
                                }}
                                <br />

                                <strong> Rented Qty: </strong>
                                {{ product.rentalData.newRentedQuantity }}
                                <br />
                            </span>

                            <span
                                v-if="
                                    product[`${v}Variant`].isPhysical == 1 &&
                                        v == 'used'
                                "
                            >
                                <strong> Rent: </strong>
                                {{
                                    currencyFormatter(
                                        product.rentalData.usedRentalPrice
                                    )
                                }}
                                <br />

                                <strong> Rented Qty: </strong>
                                {{ product.rentalData.usedRentedQuantity }}
                                <br />
                            </span>
                        </div>
                        <span>
                            <strong>Last Sold: </strong>
                            <span v-if="product[`${v}Variant`]
                                        .lastSold">
                            {{
                                dateFormatter(
                                    product[`${v}Variant`]
                                        .lastSold
                                )
                            }}
                            </span>
                            <span v-else>N/A</span>
                        </span>
                    </span>
                </b-col>
            </p-card>

            <EmptyBookVariant
                v-else
                centered-mode
                :type="v"
                @addVariant="addVariant"
                :show-add-button="showAddButton"
                :has-new="product.newVariant !== null"
                :is-busy="isBusy"
                :is-new="isNew"
            />
        </b-col>
    </b-row>
</template>

<script>
import EmptyBookVariant from './EmptyBookVariant';
import {
    currencyFormatter,
    dateFormatter,
} from '@/components/Common/Formatters.js';
export default {

    props: {
        variantOptions: {
            type: Array,
            required: true
        },
        product: Object,
        showAddButton: {
            type: Boolean,
            default: false
        },
        isBusy: Boolean,
        isNew: Boolean
    },

    data() {
        return {};
    },
    methods: {
        currencyFormatter: currencyFormatter,
        dateFormatter: dateFormatter,
        addVariant(type) {
            this.$emit('addVariant', type);
        }
    },
    components: {
        EmptyBookVariant
    }
};
</script>

<style scoped>
>>> .variant-card {
    min-height: 160px;
    text-align: center;
}
</style>
