var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"cols":"1","cols-lg":"2","cols-xl":"4"}},_vm._l((_vm.variantOptions),function(v){return _c('b-col',{key:v},[(_vm.product[(v + "Variant")])?_c('p-card',{staticClass:"variant-card",attrs:{"name":(v + "Variant-Summary")}},[_c('b-col',[_c('span',{staticClass:"text-center"},[_c('strong',{staticClass:"text-capitalize"},[_vm._v(_vm._s(v))]),_c('br'),_c('strong',[_vm._v("Sell: ")]),_vm._v(" "+_vm._s(_vm.currencyFormatter(_vm.product[(v + "Variant")].retail))),_c('br'),(_vm.product[(v + "Variant")].isPhysical == 0)?_c('span',[_c('strong',[_vm._v("Last Refresh: ")]),_vm._v(" "+_vm._s(_vm.dateFormatter( _vm.product[(v + "Variant")] .lastDigitalRefreshDate ))+" ")]):_vm._e(),(_vm.product[(v + "Variant")].isPhysical == 1)?_c('span',[_c('strong',[_vm._v("Stock on Hand: ")]),_vm._v(" "+_vm._s(_vm.product[(v + "Variant")].stockOnHand)+" ")]):_vm._e(),_c('br'),(_vm.product.rentalData)?_c('div',[(
                                _vm.product[(v + "Variant")].isPhysical == 1 &&
                                    v == 'new'
                            )?_c('span',[_c('strong',[_vm._v(" Rent: ")]),_vm._v(" "+_vm._s(_vm.currencyFormatter( _vm.product.rentalData.newRentalPrice ))+" "),_c('br'),_c('strong',[_vm._v(" Rented Qty: ")]),_vm._v(" "+_vm._s(_vm.product.rentalData.newRentedQuantity)+" "),_c('br')]):_vm._e(),(
                                _vm.product[(v + "Variant")].isPhysical == 1 &&
                                    v == 'used'
                            )?_c('span',[_c('strong',[_vm._v(" Rent: ")]),_vm._v(" "+_vm._s(_vm.currencyFormatter( _vm.product.rentalData.usedRentalPrice ))+" "),_c('br'),_c('strong',[_vm._v(" Rented Qty: ")]),_vm._v(" "+_vm._s(_vm.product.rentalData.usedRentedQuantity)+" "),_c('br')]):_vm._e()]):_vm._e(),_c('span',[_c('strong',[_vm._v("Last Sold: ")]),(_vm.product[(v + "Variant")]
                                    .lastSold)?_c('span',[_vm._v(" "+_vm._s(_vm.dateFormatter( _vm.product[(v + "Variant")] .lastSold ))+" ")]):_c('span',[_vm._v("N/A")])])])])],1):_c('EmptyBookVariant',{attrs:{"centered-mode":"","type":v,"show-add-button":_vm.showAddButton,"has-new":_vm.product.newVariant !== null,"is-busy":_vm.isBusy,"is-new":_vm.isNew},on:{"addVariant":_vm.addVariant}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }