<template>
    <p-card>
        <h4>
            {{ header }}
            <b-button
                size="sm"
                class="ml-4"
                v-b-toggle="uniqueId"
                variant="secondary"
                >Toggle Example</b-button
            >
        </h4>
        <slot name="description"></slot>
        <b-collapse :id="uniqueId" class="mt-2">
            <slot name="example"></slot>
        </b-collapse>
    </p-card>
</template>

<script>
import uniqueid from 'lodash.uniqueid';
export default {
    name: 'ExampleCard',
    props: {
        header: String
    },
    computed: {
        uniqueId() {
            return uniqueid('toggle_');
        }
    }
};
</script>
