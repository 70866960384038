<template>
    <p-modal
        :name="name"
        label="Select To Calculate"
        size="md"
        no-close-esc
        @show="loadCalculation"
        ok-only
    >
        <template v-slot:default>
            <div>
                <b-row>
                    <b-col>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        <b-row class="pl-3">
                                            <b-radio
                                                :value="0"
                                                v-model="calculationOption"
                                            >
                                                Cost
                                            </b-radio>
                                        </b-row>
                                    </th>
                                    <th>
                                        <b-row class="pl-3">
                                            <b-radio
                                                :value="1"
                                                v-model="calculationOption"
                                            >
                                                Margin
                                            </b-radio>
                                        </b-row>
                                    </th>
                                    <th>
                                        <b-row class="pl-3">
                                            <b-radio
                                                :value="2"
                                                v-model="calculationOption"
                                            >
                                                Selling Price
                                            </b-radio>
                                        </b-row>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <td>
                                    <p-number
                                        input-type="money"
                                        rules="required"
                                        name="Cost"
                                        label=""
                                        v-model.number="tempPricing.cost"
                                        @change="updateCalculation()"
                                        :disabled="calculationOption == '0'"
                                    />
                                </td>
                                <td>
                                    <p-number
                                        input-type="percent"
                                        :rules="marginValidationRules"
                                        name="Margin"
                                        label=""
                                        v-model.number="tempPricing.margin"
                                        @change="updateCalculation()"
                                        :disabled="calculationOption == '1'"
                                    />
                                </td>
                                <td>
                                    <p-number
                                        input-type="money"
                                        rules="required"
                                        name="Retail"
                                        label=""
                                        v-model.number="tempPricing.retail"
                                        @change="updateCalculation()"
                                        :disabled="calculationOption == '2'"
                                    />
                                </td>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>  
                <b-row cols="1" cols-sm="1" cols-md="3">
                    <b-col>
                        <p-number 
                        input-type="money"                       
                        :disabled="true"
                         v-model.number="initialValues.accountingCost"
                        label="Accounting Cost"
                    ></p-number>
                    </b-col>
                </b-row>              
                <b-row>
                    <b-col>
                        <small>
                            This utility does not impact database values
                        </small>
                    </b-col>
                </b-row>
            </div>
        </template>
    </p-modal>
</template>

<script>
import pricing from '@/services/PricingCalculations.js';

export default {
    props: {
        initialValues: {
            type: Object,
            default: () => {
                return {
                    margin: 0,
                    cost: 0,
                    retail: 0
                };
            }
        },
        name: {
            type: String,
            default: 'calculate'
        },
        
        parameters: Object
    },
    data() {
        return {
            calculationOption: 2,
            tempPricing: {
                margin: 0,
                cost: 0,
                retail: 0
            }
        };
    },
    computed: {
        marginValidationRules() {
            //can only have margin == 100 if cost is zero.
            //otherwise the percentage of revenue that is profit can never reach 100%.
            return this.tempPricing.cost == 0
                ? 'required|oneOf:0,100'
                : 'required|max_value:99.99';
        }
    },
    methods: {
        loadCalculation() {
            console.log(this.initialValues);
            this.tempPricing.margin = this.initialValues.margin;
            this.tempPricing.cost = this.initialValues.cost;
            this.tempPricing.retail = this.initialValues.sellingPrice || this.initialValues.retail;
        },
        updateCalculation: function() {
            switch (this.calculationOption) {
                case 0:
                    this.tempPricing.cost = pricing.computeCost(
                        this.tempPricing.retail,
                        this.tempPricing.margin
                    );
                    break;
                case 1:
                    this.tempPricing.margin = pricing.computeMargin(
                        this.tempPricing.cost,
                        this.tempPricing.retail
                    );
                    break;
                case 2:
                    if (
                        !pricing.isInvalidMargin(
                            this.tempPricing.margin,
                            this.tempPricing.cost
                        )
                    ) {
                        this.tempPricing.retail = pricing.computeRetail(
                            this.tempPricing.cost,
                            this.tempPricing.margin,
                            this.tempPricing.retail,
                            this.parameters.roundingTarget
                        );
                    }
                    this.tempPricing.margin = pricing.computeMargin(
                        this.tempPricing.cost,
                        this.tempPricing.retail
                    );
                    break;
            }
        }
    }
};
</script>
