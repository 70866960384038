var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-modal',{ref:"posDeviceReconciliation",attrs:{"name":"posDeviceReconciliationModal","label":"POS Device Reconciliation","size":"lg"},on:{"ok":function($event){$event.preventDefault();return _vm.handleModalOk.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p-form',{ref:"posDeviceReconciliationForm"},[_c('div',{staticClass:"container-fluid content-wrapper"},[_c('b-row',[_c('b-col',[_c('p',[_vm._v("Sales by POS Device by Tender")])])],1),_c('b-row',{attrs:{"cols":"1","cols-lg":"2"}},[_c('b-col',[_c('p-card',{staticClass:"static-height",attrs:{"title":"POS Devices"}},[_c('p-filter-select',{attrs:{"data-type":"posDevices","rules":"required","default-selected":true,"initial-display-count":10},model:{value:(_vm.reportParameters.registerIds),callback:function ($$v) {_vm.$set(_vm.reportParameters, "registerIds", $$v)},expression:"reportParameters.registerIds"}})],1)],1),_c('b-col',[_c('p-card',{attrs:{"title":"Date Range"}},[_c('p-date-range',{attrs:{"date-range-options":_vm.reportParameters}})],1),_c('p-card',{attrs:{"title":"Tender Status"}},[_c('b-row',[_c('b-col',[_c('b-radio',{attrs:{"value":true},model:{value:(
                                            _vm.reportParameters.tenderStatusOption
                                        ),callback:function ($$v) {_vm.$set(_vm.reportParameters, "tenderStatusOption", $$v)},expression:"\n                                            reportParameters.tenderStatusOption\n                                        "}},[_vm._v(" Active Tenders Only ")])],1)],1),_c('b-row',{staticClass:"pt-1"},[_c('b-col',[_c('b-radio',{attrs:{"value":false},model:{value:(
                                            _vm.reportParameters.tenderStatusOption
                                        ),callback:function ($$v) {_vm.$set(_vm.reportParameters, "tenderStatusOption", $$v)},expression:"\n                                            reportParameters.tenderStatusOption\n                                        "}},[_vm._v(" All ")])],1)],1)],1)],1)],1)],1)])]},proxy:true},{key:"modal-footer",fn:function(ref){
                                        var ok = ref.ok;
                                        var cancel = ref.cancel;
return [_c('b-row',[_c('b-col',[_c('p-button',{attrs:{"variant":"outline-primary","is-busy":_vm.isBusy},on:{"click":cancel}},[_vm._v("Cancel")]),_c('p-button',{staticClass:"ml-2",attrs:{"variant":"primary","is-busy":_vm.isBusy},on:{"click":ok}},[_vm._v("OK")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }