import { max} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('unique-term-name', {
    params: ['isExistingTerm', 'isDuplicateTerm'],
    message(field, { isExistingTerm, _value_ }) {
        if (isExistingTerm(_value_)) {
            return 'Term name already in use.';
        }
        return 'Cannot import multiple terms with same name.';
    },
    validate(value, { isDuplicateTerm }) {
        return !isDuplicateTerm(value);
    }
});

export default {
    data() {
        return {
            termConfiguration: {
                type: 'terms',
                label: 'Terms',
                sampleNotes: [
                    '"Dates are expected to be in YYYY-MM-DD."'
                ],
                fields: [
                    {
                        key: 'Name',
                        hasErrorCalculator:  (value, row, checkOriginalData) => {
                            return (
                                this.isEmpty(value)
                                || !max.validate(value, { length: 80 })
                                || this.isDuplicateTerm(value, checkOriginalData)
                            );
                        },
                        
                        rules: {
                            'unique-term-name': {
                                isExistingTerm: this.isExistingTerm,
                                isDuplicateTerm: this.isDuplicateTerm
                            },
                            max: {length: 80},
                            required: true
                        },
                        samples: ['"2022AGRE"','"2022BGRE"','"2022CGRE"']
                    },       
                    {
                        key: 'Description',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            return (
                                !max.validate(value, { length: 255 })
                            );
                        },
                        rules: 'max:255',
                        samples: [
                            '"Text Description for term"',
                            '""',
                            '""'
                        ]
                    },              
                    {
                        key: 'CampusName',
                        dataType: 'campuses',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        rules: 'min_value:0',
                        samples: [
                            '"Greenville University"',
                            '"Greenville University"',
                            '"Greenville University"']
                    },
                    {
                        key: 'ClassStartDate',
                        hasErrorCalculator: value => {
                            return this.isEmpty(value) ||  !this.isValidDate(value);
                        },
                        rules: {
                            'valid-date': {
                                isValidDate: this.isValidDate,
                                propertyName: 'ClassStartDate'
                            },
                            required: true
                        },
                        inputType: 'date',
                        addRowDataToRule: 'valid-date',
                        samples: [
                            '"2022-10-09"',
                            '"2022-10-09"',
                            '"2022-10-05"'
                        ]
                    },
                    {
                        key: 'IsOpen',
                        hasErrorCalculator: value => {
                            return this.isEmpty(value) || !this.matchesExistingObject(value);
                        },
                        rules: 'required|min_value:0',
                        samples: ['Yes', 'No', 'Yes'],
                        dataType: 'yesNoOptions'
                    },
                    {
                        key: 'IsActive',
                        hasErrorCalculator: value => {
                            return this.isEmpty(value) || !this.matchesExistingObject(value);
                        },
                        rules: 'required|min_value:0',
                        samples: ['Yes', 'No', 'Yes'],
                        dataType: 'yesNoOptions'
                    },
                    {
                        key: 'SubtractOrderShort',
                        hasErrorCalculator: value => {
                            return this.isEmpty(value) || !this.matchesExistingObject(value);
                        },
                        rules: 'required|min_value:0',
                        samples: ['Yes', 'No', 'Yes'],
                        dataType: 'yesNoOptions'
                    },
                    {
                        key: 'BuybackToSubtract',
                        hasErrorCalculator: value => {
                            return this.isEmpty(value) || !this.matchesExistingObject(value);
                        },
                        rules: 'required|excluded:-1',
                        samples: ['None', 'Estimated', 'Actual'],
                        dataType: 'buybackToSubtractOptions'
                    },
                ]
            }
        };
    },
    methods: {
        isDuplicateTerm(termName, rowData, checkOriginalData) {
            return this.isExistingTerm(termName) 
                    || this.isDuplicatedOnImportTerm(termName, rowData, checkOriginalData);                
        },

        isExistingTerm(termName) {
            return (
                termName != '' &&
                this.selectListOptions.terms.some(x => x.name.toLowerCase() === termName.toLowerCase())
            );
        },
        isDuplicatedOnImportTerm(termName, checkOriginalData) {
            return (
                termName != '' &&
                this.loadedData.filter(x => x.Name.toLowerCase() === termName.toLowerCase() 
                    || (checkOriginalData &&
                        x.originalData?.Name.toLowerCase()  === termName.toLowerCase() )).length > 1
            );
        }
    }
       
};
