import SharedValidation from './SharedValidation.js';
import { extend } from 'vee-validate';
import {dateFormatter} from "@/components/Common/Formatters.js";

extend('require-look-up', {
    params: ['hasAtLeastOneLookUpValue', 'rowData'],
    message() {
        return `Must include one of: ISBN, title, author, catalog number, or description`
    },
    validate(value, { hasAtLeastOneLookUpValue, rowData }) {
        return hasAtLeastOneLookUpValue(rowData);
    },    
    computesRequired: true //needed, otherwise this is skipped when empty
});


import { max, email } from 'vee-validate/dist/rules';
export default {
    mixins: [SharedValidation],
    data() {
        return {
            courseRequestConfiguration: {
                type: 'courserequests',
                label: 'Course Requests with Instructors and Term/Department Associations',
                sampleNotes: [
                    '"Campuses and terms match on abbreviation."',
                    '"Departments and courses match on name."',
                    '"The course must be associated with the deparment"',
                    '"Course requests will match their details based on matching term, course, and section."',
                    '"Products will be matched by ISBN if provided, next it will try to match author and title, finally products will be matched by catalog number and description."',
                    '"GM products without a catalog number risk being matched to the wrong product if multiple products share a description."',
                    '"Instructor matches on email, if no match it creates a new instructor from the name and email."',
                    '"Dates are expected to be in the format YYYY-MM-DD."'
                ],
                importNotes: () => { return this.importNotes},
                fields: [
                    {
                        key: 'Term',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        comparisonProperty: 'name',
                        rules: 'min_value:0|required',
                        dataType: 'terms',
                        samples: ['"2027AMSU"', '"2027AMSU"', '"2027AMSU"']
                    },
                    {
                        key: 'Department',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },

                        dataType: 'courseDepartments',
                        rules: 'min_value:0|required',
                        samples: ['"BIO"', '"BIO"', '"BIO"']
                    },
                    {
                        key: 'Course',
                        selectOptionsFilter: (row) => {
                            return row.DepartmentId > 0 ? row.DepartmentId : -1;
                        },
                        hasErrorCalculator: (value, row) => {
                            return !this.matchesExistingObject(value, true) || !this.isValidCourseForDepartment(value, row);
                        },

                        dataType: 'courses',
                        rules: 'min_value:0|required',
                        samples: ['"101"', '"101"', '"101"']
                    },
                    {
                        key: 'InstructorName',
                        canConvertCase: true,
                        hasErrorCalculator: (value) => {
                            return this.isEmpty(value) || !max.validate(value, { length: 80 });
                        },

                        rules: 'required|max:80',
                        samples: [
                            '"Benning, Jim"',
                            '"Benning, Jim"',
                            '"Benning, Jim"'
                        ]
                    },
                    {
                        key: 'InstructorEmail',
                        hasErrorCalculator: (value) => {
                            return this.isEmpty(value) || !email.validate(value) ||  !max.validate(value, { length: 255 });
                        },

                        rules: 'required|email|max:255',
                        samples: [
                            '"jb@myschool.edu"',
                            '"jb@myschool.edu"',
                            '"jb@myschool.edu"'
                        ]
                    },
                    {
                        key: 'SectionId',
                        hasErrorCalculator: (value) => {
                            return !this.isEmpty(value) && !max.validate(value, { length: 20 });
                        },

                        rules: 'max:20',
                        samples: ['"123"', '"123"', '"123"']
                    },
                    {
                        key: 'Section',//SectionName in the db, matches to section in the UI                        
                        canConvertCase: true,
                        hasErrorCalculator: (value) => {
                            return this.isEmpty(value) || !max.validate(value, { length: 80 });
                        },

                        rules: 'required|max:80',
                        samples: ['"H"', '"1"', '"1"']
                    },
                    {
                        key: 'CourseLevel',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },

                        dataType: 'courseLevels',
                        rules: 'min_value:0',
                        samples: ['1', '1', '1']
                    },

                    {
                        key: 'Quantity',
                        hasErrorCalculator: (value) => {                            
                            return !this.isNonNegativeInteger(value);
                        },
                        convertToNumber: true,
                        rules: 'min_value:0|integer',
                        samples: ['10', '55', '20']
                    },

                    {
                        key: 'EstimatedEnrollment',
                        hasErrorCalculator: (value) => {
                            return !this.isNonNegativeInteger(value);
                        },
                        convertToNumber: true,
                        rules: 'min_value:0|integer',
                        samples: ['80', '80', '80']
                    },

                    {
                        key: 'ActualEnrollment',
                        hasErrorCalculator: (value) => {
                            return !this.isNonNegativeInteger(value);
                        },
                        convertToNumber: true,
                        rules: 'min_value:0|integer',
                        samples: ['85', '85', '85']
                    },
                    {
                        key: 'RequestDate',
                        hasErrorCalculator: value => {
                            return !this.isValidDate(value);
                        },
                        inputType: 'date',
                        formatter: dateFormatter,
                        rules: {
                            'valid-date': {
                                isValidDate: this.isValidDate,
                                propertyName: 'RequestDate'
                            }
                        },

                        addRowDataToRule: 'valid-date',
                        samples: [
                            '"2022-10-09"',
                            '"2022-10-09"',
                            '"2022-10-05"'
                        ]
                    },
                    {
                        key: 'RequestType',
                        hasErrorCalculator: (value) => {
                            return !this.matchesExistingObject(value);
                        },
                        dataType: 'courseRequestBookStatuses',
                        rules: 'excluded:-1',
                        samples: ['"Optional"', '"Required"', '"Recommended"']
                    },
                    {
                        key: 'AdoptionStatus',
                        hasErrorCalculator: (value) => {
                            return !this.matchesExistingObject(value);
                        },
                        dataType: 'openCompletedOptions',
                        rules: 'excluded:-1',
                        samples: ['"Open"', '"Open"', '"Open"']
                    },
                    {
                        key: 'InclusiveAccess',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0',
                        samples: ['"Yes"', '"No"', '"Yes"']
                    },
                    {
                        key: 'PrintShelfTag',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0|required',
                        samples: ['"Yes"', '"Yes"', '"Yes"']
                    },
                    {
                        key: 'Continuation',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0',
                        samples: ['"Yes"', '"No"', '"Yes"']
                    },
                    {
                        key: 'Cancelled',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0',
                        samples: ['"No"', '"Yes"', '"No"']
                    },
                    {
                        key: 'ShelfTagComment',
                        hasErrorCalculator: (value) => {
                            return !max.validate(value, { length:1000 });
                        },

                        rules: 'max:1000',
                        samples: [
                            '"Tag Comment"',
                            '"Tag Comment"',
                            '"Tag Comment"'
                        ]
                    },
                    {
                        key: 'WebComment',
                        hasErrorCalculator: (value) => {
                            return !max.validate(value, { length:500 });
                        },

                        rules: 'max:500',
                        samples: [
                            '"Web Comment"',
                            '"Web Comment"',
                            '"Web Comment"'
                        ]
                    },
                    {
                        key: 'Isbn',
                        hasErrorCalculator: (value, row) => {
                            if(!this.hasAtLeastOneLookUpValue(row)){
                                return true;
                            }
                            return !this.isEmpty(value) && value.length < 10;
                        },
                        addRowDataToRule: 'require-look-up',                        
                        rules: {
                            'require-look-up': {
                                hasAtLeastOneLookUpValue: this
                                    .hasAtLeastOneLookUpValue
                            },
                            min: { length: 10 }
                        },
                        samples: ['"1-231-23123-8"', '"1-231-23124-8"', '""']
                    },
                    {
                        key: 'CatalogNumber',
                        hasErrorCalculator: (value, row) => {
                            if(!this.hasAtLeastOneLookUpValue(row)){
                                return true;
                            }
                            return !max.validate(value, { length:50 });
                        },
                        addRowDataToRule: 'require-look-up',
                        rules: {
                            'require-look-up': {
                                hasAtLeastOneLookUpValue: this
                                    .hasAtLeastOneLookUpValue
                            },
                            max: { length: 50 }
                        },
                        samples: ['""', '""', '"555123"']
                    },
                    {
                        key: 'Description',
                        hasErrorCalculator: (value, row) => {
                            if(!this.hasAtLeastOneLookUpValue(row)){
                                return true;
                            }
                            return !max.validate(value, { length:200 });
                        },
                        addRowDataToRule: 'require-look-up',
                        rules: {
                            'require-look-up': {
                                hasAtLeastOneLookUpValue: this
                                    .hasAtLeastOneLookUpValue
                            },
                            max: { length: 200 }
                        },
                        samples: ['""', '""', '"ABC Sweatshirt"']
                    },
                    {
                        key: 'Author',
                        hasErrorCalculator: (value, row) => {
                            if(!this.hasAtLeastOneLookUpValue(row)){
                                return true;
                            }
                            return !max.validate(value, { length:200 });
                        },
                        addRowDataToRule: 'require-look-up',
                        rules: {
                            'require-look-up': {
                                hasAtLeastOneLookUpValue: this
                                    .hasAtLeastOneLookUpValue
                            },
                            max: { length: 200  }
                        },
                        samples: ['"My Book Title1"','"My Book Title2"','"My Book Title3"','"My Book Title4"']
                    },
                    {
                        key: 'Title',
                        hasErrorCalculator: (value, row) => {
                            if(!this.hasAtLeastOneLookUpValue(row)){
                                return true;
                            }
                            return !max.validate(value, { length:200 });
                        },
                        addRowDataToRule: 'require-look-up',
                        rules: {
                            'require-look-up': {
                                hasAtLeastOneLookUpValue: this
                                    .hasAtLeastOneLookUpValue
                            },
                            max: { length: 200  }
                        },
                        samples: ['"My Book Title1"','"My Book Title2"','"My Book Title3"','"My Book Title4"']
                    }
                ]
            }
        };
    },
    watch: {
        sameAsAddress: {
            handler: function() {
                this.syncSameAsAddress();
            }
        },
    },

    computed:{
        importNotes(){
            let notes = []
            if(this.newInstructors.length > 0){
                notes.push(`New instructor(s) will be created for the following instructor email(s) not found: ${this.newInstructors.join(', ')}`  )
            }
            if(this.newTermDepartmentAssociations.length > 0){
                notes.push(`New department/term association(s) will be created with 100% allocation percentage for the following: ${this.newTermDepartmentAssociations.join(', ')}`  )
            }

            return notes;
        },

        newInstructors(){
            return [...new Set(this.loadedData.filter(x => 
                !this.selectListOptions.instructors.some(s => s.email === x.InstructorEmail))
                .map(x => x.InstructorEmail))];
        },

        newTermDepartmentAssociations(){
            return this.loadedData.filter(x => 
                !this.selectListOptions.terms.some(t => t.courseDepartmentIds.includes(x.DepartmentId)))
                .map(x => x.Department + '/' + x.Term);

        },
    },
    
    methods: {
        isValidCourseForDepartment(course, row) {
            if(this.isEmpty(course) ||  row.DepartmentId < 1){
                return true;
            }
            return this.selectListOptions.courses.find(x => x.value == course)?.filter == row.DepartmentId
        },

        hasAtLeastOneLookUpValue(row){
            return !(this.isEmpty(row.Isbn)
                && this.isEmpty(row.CatalogNumber)
                && this.isEmpty(row.Title)
                && this.isEmpty(row.Description));
        }

    }
};
