<template>
    <SearchScreen
        title="Sales Events"
        api-destination="salesevents"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        edit-key="salesEventId"
        add-new-button
    >
        <template v-slot:actionButtons>
            <b-row class="pl-3">
                <router-link
                    to="/salesevents/new"
                    class="btn btn-primary mr-2"
                >
                    <b-icon-plus></b-icon-plus> Add
                </router-link>
            </b-row>
        </template>

        <template v-slot:belowSearch>
                <p-form>
                    <b-row class="ml-3 mr-2">
                        <p-checkbox
                            label="Active"
                            class="mt-4"
                            v-model="filters.Active"
                        />
                        <p-checkbox
                            label="Future"
                            class="ml-2 mt-4"
                            v-model="filters.Future"
                        />
                        <p-checkbox
                            label="Expired"
                            class="ml-2 mt-4"
                            v-model="filters.Expired"
                        />
                        <b-col md="3" class="ml-4">
                            <p-datepicker
                                label="Start Date"
                                vid="salesEventStartDate"
                                v-model="filters.StartDate"
                            />
                        </b-col>
                        <b-col md="3">
                            <p-datepicker
                                label="End Date"
                                vid="salesEventEndtDate"
                                rules="dateGreaterOrEqual:@salesEventStartDate"
                                v-model="filters.EndDate"
                            />
                        </b-col>
                    </b-row>
                </p-form>
            </template>          
    </SearchScreen>
</template>

<script>
import { dateFormatter } from '../../components/Common/Formatters.js';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'SalesEventsFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'startDate',
                sortDesc: true,
                Active: true,
                Future: false,
                Expired: false,
                searchBy: 'All',
                StartDate: null,
                EndDate:null
            },
            filterBy: ['All','Sales Event Name','Product Description','Barcode'],
            fields: [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'startDate',
                    sortable: true,

                    formatter: dateFormatter
                },
                {
                    key: 'endDate',
                    sortable: true,
                    formatter: dateFormatter
                },
               
            ]
        };
    },
    components: {
        SearchScreen
    },
    methods: {
     
    } 
};
</script>
