<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <SearchScreen
            title="Buyback List Search"
            api-destination="buyback"
            :filters="filters"
            :filterBy="filterBy"
            :fields="buybackListFields"
            :search-message="searchMessage"
            edit-key="id"
            :edit-url-func="item => `/buybacklists/${item.buybackListId}`"
            search-destination="searchbuybacklists"
            :refreshSearchResults="refreshSearchResults"
            ref="sessionSearch"
        >
            <template v-slot:actionButtons>
                <editbuybacklist
                    class="mr-2"
                    buyback-list-id="new"
                    @data-saved="updateSessionSearch()"
                />
            </template>
            <template v-slot:filters>
                <h4 class="m-0 pb-3">Filters</h4>
                <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        dataType="locations"
                        label="Locations"
                        v-model="filters.locationIds"
                    />
                </p-card>
                <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        dataType="terms"
                        label="Terms"
                        v-model="filters.termIds"
                    />
                </p-card>
            </template>
            <template v-slot:belowSearch>
                <p-form>
                    <b-row class="ml-3 mt-4 mr-2">
                        <p-checkbox
                            label="Open"
                            class="ml-3"
                            v-model="filters.open"
                        />
                        <p-checkbox
                            label="Closed"
                            class="ml-3"
                            v-model="filters.closed"
                        />
                    </b-row>
                </p-form>
            </template>
        </SearchScreen>
    </p-secure>
</template>
<script>
import editbuybacklist from '@/views/Buyback/EditBuybackList.vue';
import { dateFormatter } from '../../components/Common/Formatters.js';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    components: { editbuybacklist, SearchScreen },
    props: {
        id: String,
        searchMessage: {
            type: String,
            default:
                'Search by Description, Session, Purchaser, SKU, ISBN, or Barcode'
        }
    },
    data() {
        return {
            filterName: 'BuybackListFilter',
            buybackListFields: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'session',
                    label: 'Session',
                    sortable: true
                },
                {
                    key: 'list',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'purchaser',
                    label: 'Purchaser',
                    sortable: true
                },
                {
                    key: 'createDate',
                    label: 'Create Date',
                    sortable: true,
                    formatter: dateFormatter,
                    sortByFormatted: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }
            ],
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'list',
                sortDesc: true,
                searchBy: 'All',
                open: true,
                closed: false,
                termIds: [],
                locationIds: []
            },
            filterBy: [
                'All',
                'Description',
                'Session',
                'Purchaser',
                'SKU',
                'ISBN',
                'Barcode'
            ],
            refreshSearchResults: false
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {},
    methods: {
        updateSessionSearch() {
            this.$refs?.sessionSearch.refreshSearchResults();
        }
    }
};
</script>
<style scoped>
.tcw100 {
    width: 100px;
}
</style>
