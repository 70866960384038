<template>
    <p-form>
        <div>
            <b-row class="pb-2">
                <p-feature-flag name="Product Images">
                    <b-col cols="auto">
                        <div  >
                            <img class="img-fluid" style="max-width:120px;max-height:120px;" :src="primaryURL" alt="Product Image"/>
                        </div>
                    </b-col>
                 </p-feature-flag>
                <b-col cols="auto" align-self="center"><strong>{{ productFamily.description }}</strong>
                </b-col>
            </b-row>
            <p-card>
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col class="text-left">
                                <edit-supplier
                                    class="pb-2"
                                    vendor-id="new"
                                    :product="product"
                                    :productFamily="productFamily"
                                    @load-data="updatedSupplier"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <p-table
                            :items="productFamily.suppliersProperties"
                            :fields="fieldsSuppliersProfiles"
                            :enableRowSelection="false"
                            ref="suppliersProfiles"
                            :selectable="false"
                            sort-by="vendorName"
                        >
                            <template v-slot:cell(edit)="{ value, item }">
                                <edit-supplier
                                    class="pb-2"
                                    :vendor-id="item.vendorId"
                                    :product="product"
                                    :productFamily="productFamily"
                                    @load-data="updatedSupplier"
                                />
                            </template>
                            <template
                                v-slot:cell(isDefault)="{
                                    value,
                                    item,
                                    index
                                }"
                            >
                                <p-checkbox
                                    :value="item.isDefault"
                                    @change="setDefaultSupplier(item)"
                                    :disabled="isDisabled"
                                />
                            </template>
                            <template
                                v-slot:cell(removeDetail)="{ value, item }"
                            >
                                <b-button
                                    class="p-1 ml-1 short-button"
                                    @click="deleteSupplier(item)"
                                >
                                    <BIconTrash
                                        font-scale="0.9"
                                        class="mb-3"
                                        icon="trash"
                                    ></BIconTrash>
                                </b-button>
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
        </div>
    </p-form>
</template>
<script>
import {
    currencyFormatter
} from '@/components/Common/Formatters.js';
import EditSupplier from './GMEditSupplier.vue';

export default {
    components: { EditSupplier },
    computed:{
        isDisabled() {
            return this.product.suppliersProperties && this.product.suppliersProperties.length === 1;
        },
        primaryURL() {
            return this.productFamily.imageUrl ? this.productFamily.imageUrl : '/img/no-img.png';
        }
    },
    props: {
        product: Object,
        productFamily: Object
    },
    data() {
        return {
            fieldsSuppliersProfiles: [
                {
                    key: 'edit'
                },
                {
                    key: 'isDefault',
                    label: 'Default',
                    sortable: false
                },
                {
                    key: 'vendorName',
                    label: 'Supplier Name',
                    sortable: false
                },
                {
                    key: 'catalogNumber',
                    label: 'Supplier Catalog #',
                    sortable: false
                },
                {
                    key: 'description',
                    label: 'Supplier Catalog Description',
                    sortable: false
                },
                {
                    key: 'purchaseUnit',
                    label: 'Purchase Unit',
                    sortable: false,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'costPerUnit',
                    label: 'Cost Per Unit',
                    sortable: false,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'autoOrderQty',
                    label: 'Auto Order Qty',
                    sortable: false,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'removeDetail',
                    label: ''
                }
            ]
        };
    },
    methods: {
        updatedSupplier(item) {
            if (!this.productFamily.suppliersProperties ||
                !this.productFamily.suppliersProperties.some(
                    supplier => supplier.vendorId === item.vendorId
                )
            ) {
                if (this.productFamily.suppliersProperties === null) {
                    this.productFamily.suppliersProperties = [];
                }
                this.productFamily.suppliersProperties.push(item);
            } else {
                const index = this.productFamily.suppliersProperties.findIndex(
                    supplier => supplier.vendorId === item.vendorId
                );
                this.productFamily.suppliersProperties.splice(index, 1);
                this.productFamily.suppliersProperties.splice(index, 0, item);
            }
            if (item.isDefault) {
                item.isDefault = false;
                this.setDefaultSupplier(item);
            }
        },
        setDefaultSupplier(item) {
            if (!item.isDefault) {
                item.isDefault = true;
                this.productFamily.suppliersProperties.map(supplier => {
                    if (supplier.vendorId !== item.vendorId) {
                        supplier.isDefault = false;
                    }
                });
                 this.$emit('change-default-supplier');
            } else {
                item.isDefault = false;
                this.$emit('change-default-supplier');
            }
        },
        deleteSupplier(item) {
            if (item.isDefault && this.productFamily.suppliersProperties && this.productFamily.suppliersProperties.length > 1) {
                this.$bvModal.msgBoxOk(
                    `Please select a default supplier before removing this supplier`
                );
                return;
            } else {
                // TODO check whether need to call API to delete the supplier
                this.productFamily.suppliersProperties = this.productFamily.suppliersProperties.filter(
                    supplier => supplier.vendorId !== item.vendorId
                );
                this.$emit('change-default-supplier');
            }
        }
    }
};
</script>
<style scoped>
>>> .short-button {
    height: 20px;
}
</style>
