<template>
    <!-- <div>
        <div class="content-heading">
            <div class="content-heading-inner">
                <h1>Feature Flags</h1>
            </div>
            <router-link tag="button" type="button" class="btn btn-primary ml-auto" to="/users/add">
                <em class="fa fa-plus-circle fa-fw mr-1"></em>Create A Feature Flag
            </router-link>
        </div>

                <p-card>
                    <v-card-title>
                        <v-flex xs12 sm6 md3>
                            <v-text-field v-model="search"
                                          append-icon="search"
                                          label="Search"
                                          single-line
                                          hide-details></v-text-field>
                        </v-flex>
                    </v-card-title>
                    <v-data-table :items="this.featureFlags" :headers="headers" :search="search" hide-actions overflow>
                        <template v-slot:items="props">
                            <tr @click="rowSelect(props.item)">
                                <td>
                                    <v-switch v-model="props.item.enabled"></v-switch>
                                </td>
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.client }}</td>
                                <td>{{ props.item.startDate }}</td>
                                <td v-bind:class="getAssignedClass(props.item)">{{ props.item.assigned }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </p-card>



    </div> -->
    <SearchScreen
        title="Feature Flags"
        api-destination="featureflags"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        edit-key="id"
        @isActiveChanged="updateIsActiveStatus"
    />
</template>

<script>

import axios from 'axios';
import SearchScreen from '../../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    components: {
        SearchScreen
    },
    data() {
        return {
            filterName: 'FeatureFlagFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All'],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'client',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },
                {
                    key: 'startDate',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: true
                }
            ]
        };
    },

    created() {
    },

    watch: {},

    methods: {

        getAssignedClass(item) {
            if (item.assigned == 'All') {
                return 'text-success';
            } else if (item.assigned == 'Partial') {
                return 'text-warning';
            } else {
                return 'text-danger';
            }
        },
        updateIsActiveStatus(featureFlag) {
            axios.post('featureflags/setisactive', featureFlag).then(() => {
                var msg = `${featureFlag.name} ${
                    featureFlag.isActive ? 'activated' : 'de-activated'
                } successfully`;
                this.$toasted.global.app_success(msg).goAway(5000);
            });
        },
    }
};
</script>
