<template>
  <div>
  <p-button :is-busy="isWebOrderBusy" @click="showModal" no-border class="pl-2 d-inline">
                    <i class="fas fa-external-link-alt"></i
                ></p-button>
     <p-modal      
        size="lg"
        label="Department Charge Details"
        ref="departmentChargeModal "
    >
        <template v-slot:default>
            <p-form ref="form">
                <b-row  cols="1" cols-lg="2" v-if="webOrder.promptDetails.length>0">
                        <b-col v-for="promptDetail in webOrder.promptDetails"
                            :key="promptDetail.promptName">
                                <p-input
                                    :label="promptDetail.promptName"
                                    v-model="promptDetail.promptValue"
                                    :rules="promptDetail.isRequired?'required|max:50':'max:50'"
                                />
                        </b-col>
                </b-row>
                <b-row cols="1">
                    <b-col>
                        <p-text-area
                            label="Special Comments"
                            v-model="webOrder.specialComments"
                            :disabled="true"
                        />
                    </b-col>
                </b-row>
            </p-form>
        </template>

        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <b-button :is-busy="isBusy" variant="outline-primary" @click="cancel">Cancel</b-button>
                    <p-button :is-busy="isBusy" variant="primary" @click="saveDetails" class="ml-2">Save</p-button>
                </b-col>
            </b-row>
        </template>
    </p-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
 props: {
          webOrder:{},
          isWebOrderBusy: {
            type: Boolean,
            default: false
          }
        },
    data() {
        return { 
            isBusy:false     
         };
    },
    methods: {        
        saveDetails: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            this.isBusy=true;
            this.webOrder.isDepartmentCharge = true;
            axios
                .post('weborders', this.webOrder)
                .then(() => {
                    this.$emit('loadData');
                    this.$refs['departmentChargeModal '].hide();
                        this.$toasted.global
                            .app_success(
                                `Department charges saved successfully.`
                            )
                            .goAway(5000);                    
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        showModal(){
            this.$refs['departmentChargeModal '].show();            
        }
    }
}
</script>