import { pDecimalValidationTest } from '@/components/mixins/ValidatedInput.js';
import { max, min_value, max_value, integer } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import {dccNumberFormatter} from "@/components/Common/Formatters.js";

extend('unique-dcc-department-number', {
    params: ['isDuplicateDccDepartmentNumber', 'rowData','isExistingDccDepartmentNumber'],
    message(field, {isExistingDccDepartmentNumber, _value_, rowData}) {
        if(isExistingDccDepartmentNumber(_value_, rowData)){
            return `${field} already exists with a different name.`;
        }
        return `${field} repeated in import data with a different name.`;
    },
    validate(value, { isDuplicateDccDepartmentNumber, rowData }) {
        return !isDuplicateDccDepartmentNumber(value, rowData);
    }
});
extend('unique-dcc-class-number', {
    params: ['isDuplicateDccClassNumber', 'rowData','isExistingDccClassNumber'],
    message(field, {isExistingDccClassNumber, _value_, rowData}) {
        if(isExistingDccClassNumber(_value_, rowData)){
            return `${field} already exists with a different name.`;
        }
        return `${field} repeated in import data with a different name.`;
    },
    validate(value, { isDuplicateDccClassNumber, rowData }) {
        return !isDuplicateDccClassNumber(value, rowData);
    }
});
extend('unique-dcc-category-number', {
    params: ['isDuplicateDccCategoryNumber', 'rowData','isExistingDccCategoryNumber'],
    message(field, { isExistingDccCategoryNumber, _value_, rowData }) {
        if(isExistingDccCategoryNumber(_value_, rowData)){
            return `${field} already exists in this class.`;
        }
        
        return `${field} repeated in import data.`;
    },
    validate(value, { isDuplicateDccCategoryNumber, rowData }) {
        return !isDuplicateDccCategoryNumber(value, rowData);
    }
});

export default {
    data() {
        return {
            dccConfiguration: {
                type: 'dcc',
                label: 'Departments, Classes, and Categories',
                sampleNotes: [
                    '"DCC Type: GM or Book"',
                    '"Desired Margin is in percentage.  For a 10% margin you would provide 10, not .1"'
                ],
                fields: [
                    {
                        key: 'DepartmentName',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 50 })
                            );
                        },
                        rules: 'max:50|required',
                        samples: [
                            '"Sundries"',
                            '"Sundries"',
                            '"Sundries"',
                            '"Mail Center"'
                        ]
                    },
                    {
                        key: 'DepartmentNumber',
                        hasErrorCalculator: (value, row, checkOriginalData) => {
                            return (
                                value === '' ||
                                value === undefined ||
                                !min_value.validate(value, { min: 0 }) ||
                                !max_value.validate(value, { max: 99 }) ||
                                !integer.validate(value) ||
                                this.isDuplicateDccDepartmentNumber(
                                    value,
                                    row,
                                    checkOriginalData
                                )
                            );
                        },
                        rules: {
                            'unique-dcc-department-number': {
                                isDuplicateDccDepartmentNumber: this
                                    .isDuplicateDccDepartmentNumber,
                                    isExistingDccDepartmentNumber: this.isExistingDccDepartmentNumber
                            },
                            required: true,
                            min_value: { min: 0 },
                            max_value: { max: 99 },
                            integer: true
                        },
                        addRowDataToRule: 'unique-dcc-department-number',
                        samples: [54, 54, 54, 90],
                        convertToNumber: true
                    },
                    {
                        key: 'ClassName',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 50 })
                            );
                        },
                        rules: 'max:50|required',
                        samples: [
                            '"Laundry"',
                            '"Laundry"',
                            '"Sunblock"',
                            '"Postage"'
                        ]
                    },
                    {
                        key: 'ClassNumber',
                        hasErrorCalculator: (value, row, checkOriginalData) => {
                            return (
                                value === '' ||
                                value === undefined ||
                                !min_value.validate(value, { min: 0 }) ||
                                !max_value.validate(value, { max: 99 }) ||
                                !integer.validate(value) ||
                                this.isDuplicateDccClassNumber(
                                    value,
                                    row,
                                    checkOriginalData
                                )
                            );
                        },
                        rules: {
                            'unique-dcc-class-number': {
                                isDuplicateDccClassNumber: this
                                    .isDuplicateDccClassNumber,
                                isExistingDccClassNumber: this.isExistingDccClassNumber
                            },
                            required: true,
                            min_value: { min: 0 },
                            max_value: { max: 99 },
                            integer: true
                        },
                        addRowDataToRule: 'unique-dcc-class-number',
                        samples: [22, 22, 50, 10],
                        convertToNumber: true
                    },
                    {
                        key: 'CategoryName',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 50 })
                            );
                        },
                        rules: 'max:50|required',
                        samples: [
                            '"Baskets"',
                            '"Detergent"',
                            '"SPF 50"',
                            '"Stamps"'
                        ]
                    },
                    {
                        key: 'CategoryNumber',
                        hasErrorCalculator: (value, row, checkOriginalData) => {
                            return (
                                value === '' ||
                                value === undefined ||
                                !min_value.validate(value, { min: 0 }) ||
                                !max_value.validate(value, { max: 99 }) ||
                                !integer.validate(value) ||
                                this.isDuplicateDccCategoryNumber(
                                    value,
                                    row,
                                    checkOriginalData
                                )
                            );
                        },
                        rules: {
                            'unique-dcc-category-number': {
                                isDuplicateDccCategoryNumber: this
                                    .isDuplicateDccCategoryNumber,
                                level: 'category',
                                isExistingDccCategoryNumber: this.isExistingDccCategoryNumber
                            },
                            required: true,
                            min_value: { min: 0 },
                            max_value: { max: 99 },
                            integer: true
                        },
                        addRowDataToRule: 'unique-dcc-category-number',
                        samples: [22, 23, 24, 22],
                        convertToNumber: true
                    },
                    {
                        key: 'DccType',
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                value === undefined ||
                                !min_value.validate(value, { min: 0 }) ||
                                !max_value.validate(value, { max: 7 }) ||
                                !integer.validate(value)
                            );
                        },
                        samples: ['GM', 'GM', 'GM', 'GM'],
                        dataType: 'productTypes',
                        rules: 'required|integer|min_value:1'
                    },
                    {
                        key: 'TaxType',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        dataType: 'taxItems',
                        rules: 'min_value:0|required'
                    },
                    {
                        key: 'DesiredMargin',
                        hasWarningCalculator: value => {
                            return (
                                value &&
                                !pDecimalValidationTest(value, {
                                    precision: '10',
                                    scale: '2'
                                })
                            );
                        },
                        rules: 'p-decimal:10,2|min_value:0',
                        samples: [10.55, 50, 50, 0],
                        convertToNumber: true
                    },
                    {
                        key: 'IsActive',
                        hasWarningCalculator: value => {
                            return !min_value.validate(value, { min: 0 }) || !this.matchesExistingObject(value);
                        },
                        warningDefault: true,
                        samples: ['Yes', 'No', 'Yes', 'No'],
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0'
                    },
                    {
                        key: 'AllowDiscounts',
                        hasWarningCalculator: value => {
                            return !min_value.validate(value, { min: 0 }) || !this.matchesExistingObject(value);
                        },
                        warningDefault: true,
                        dataType: 'yesNoOptions',
                        rules: 'min_value:0'
                    }
                ]
            }
        };
    },
    
    methods: {
        //Department Validation
        isDuplicateDccDepartmentNumber(value, rowData, checkOriginalData){         
            return this.isExistingDccDepartmentNumber(value, rowData)
            //compare against other data being imported for duplicates
            || this.isDuplicateDccDepartmentNumberOnImport(rowData, checkOriginalData, value);
        },
        isExistingDccDepartmentNumber(value, rowData) {               
            let numbersToMatch = dccNumberFormatter(value);
            let nameToMatch = rowData.DepartmentName.toLowerCase() + ' > ';
            return this.selectListOptions.dcc.some(x => {
                let numbers = x.text.substring(0, 8);
                let names = x.text.substring(9).toLowerCase();
                //dept with same number, but different name, consider it a reuse of a dcc number
                return numbers.indexOf(numbersToMatch) == 0 && names.indexOf(nameToMatch) != 0;
            });
        },
        isDuplicateDccDepartmentNumberOnImport(rowData, checkOriginalData, value) {
            return (this.loadedData.filter(x => x.DepartmentNumber == rowData.DepartmentNumber)
                .map(x => x.DepartmentName.toLowerCase())
                .filter((v, i, a) => a.indexOf(v) === i) //make list distinct
                .length > 1
                || (checkOriginalData &&
                    this.loadedData.filter(x => x.originalData?.DepartmentNumber == value)
                        .map(x => x.originalData?.DepartmentName.toLowerCase())
                        .filter((v, i, a) => a.indexOf(v) === i)
                        .length > 1));
        },

        //Class Validation
        isDuplicateDccClassNumber(value, rowData, checkOriginalData){
            return this.isExistingDccClassNumber(value, rowData)
            //compare against other data being imported for duplicates
            || this.isDupplicateDccClassNumberOnImport(rowData, value, checkOriginalData);
        },
        isExistingDccClassNumber(value, rowData) {
            
            let numbersToMatch =  `${dccNumberFormatter(rowData.DepartmentNumber)}-${dccNumberFormatter(value)}` 
            let classToMatch =  `${rowData.ClassName.toLowerCase()} > `;

            return this.selectListOptions.dcc.some(x => {
                let numbers = x.text.substring(0, 8);
                let className = x.text.toLowerCase().substring(x.text.indexOf(' > ') + 3);
                //class with same number/different name, but in same dept is a duplicate
                return numbers.indexOf(numbersToMatch) == 0 && (className.indexOf(classToMatch) != 0);
            });
        },
        isDupplicateDccClassNumberOnImport(rowData, value, checkOriginalData) {
            return (this.loadedData.filter(x => x.DepartmentNumber == rowData.DepartmentNumber && x.ClassNumber == value)
                .map(x => x.ClassName.toLowerCase())
                .filter((v, i, a) => a.indexOf(v) === i) //make list distinct
                .length > 1
                || (checkOriginalData &&
                    this.loadedData.filter(x => x.originalData?.DepartmentNumber == rowData.DepartmentNumber && x.ClassNumber == value)
                        .map(x => x.originalData?.ClassName.toLowerCase())
                        .filter((v, i, a) => a.indexOf(v) === i)
                        .length > 1));
        },
        
        //Category Validaiton
        isDuplicateDccCategoryNumber(value, rowData, checkOriginalData){            
            return this.isExistingDccCategoryNumber(value, rowData)
            //compare against other data being imported for duplicates
            || this.isDuplicateDccCategoryNumberOnImport(rowData, value, checkOriginalData);
        },
        isExistingDccCategoryNumber(value, rowData) {
            let numbersToMatch = `${dccNumberFormatter(rowData.DepartmentNumber)}-${dccNumberFormatter(rowData.ClassNumber)}-${dccNumberFormatter(value)}`;
            return this.selectListOptions.dcc.some(x => {
                let numbers = x.text.substring(0, 8);
                return numbers.indexOf(numbersToMatch) == 0;
            });
        },
        
        isDuplicateDccCategoryNumberOnImport(rowData, value, checkOriginalData) {
            return (this.loadedData.filter(x => x.DepartmentNumber == rowData.DepartmentNumber && x.ClassNumber == rowData.ClassNumber && x.CategoryNumber == value)
                .length > 1
                || (checkOriginalData &&
                    this.loadedData.filter(x => x.originalData?.DepartmentNumber == rowData.DepartmentNumber && x.originalData?.ClassNumber == rowData.ClassNumber && x.originalData?.CategoryNumber == value)
                        .length > 1));
        }
        
    },
};


