<template>
    <SearchScreen
        title="Web Orders"
        api-destination="weborders"
        edit-key="saleId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :use-bootstrap-selection="false"
        :show-set-to="false"
        @selected="onSelection"
        :add-new-button="false"
    >
        <template v-slot:belowSearch>
            <b-row class="ml-2 mr-1 mt-2">
             <b-col cols="12"  xl="8" class="d-flex">
                <p-checkbox
                    label="Open"
                    class="ml-1"
                    v-model="filters.isOpen"
                />
                <p-checkbox
                    label="Partial"
                    class="ml-2"
                    v-model="filters.isPartial"
                />
                <p-checkbox
                    label="Backordered"
                    class="ml-2"
                    v-model="filters.isBackordered"
                />
                <p-checkbox
                    label="Complete"
                    class="ml-2"
                    v-model="filters.isComplete"
                />
                <p-checkbox
                    label="Failed"
                    class="ml-2"
                    v-model="filters.isFailed"
                />
                <p-checkbox
                    label="Cancelled"
                    class="ml-2"
                    v-model="filters.isCancelled"
                />                
                <p-checkbox
                    label="Completed at Register"
                    class="ml-2"
                    v-model="filters.isCompletedAtRegister"
                />
             </b-col>
            </b-row>
            <b-row class="ml-2 mr-1 mt-2">
                <b-col cols="12" sm="4" xl="2">
                    <p-datepicker
                        class="ml-3"
                        label="Order Date: From"
                        vid="webOrderStartDate"
                        v-model="filters.webOrderStartDate"
                    />
                </b-col>
                <b-col cols="12" sm="4" xl="2">
                    <p-datepicker
                        label="Order Date: To"
                        vid="webOrderEndDate"
                        v-model="filters.webOrderEndDate"
                        rules="dateGreaterOrEqual:@webOrderStartDate"
                        is-end-date
                    />
                </b-col>
                 <b-col cols="12" sm="4" xl="2">
                         <p-select
                                    label="Shipping Method"
                                    data-type="shippingCodes"
                                    v-model="filters.shippingMethod"
                                    addEmptyOption
                                />
                </b-col>
                <b-col cols="12" sm="4" xl="2">
                    <p-select
                        label="Tender Type"
                        data-type="tenders"
                        v-model="filters.tenderId"
                        addEmptyOption
                    />
                </b-col>
            </b-row>
        </template>
        <template v-slot:actionButtons>
            <p-button
                variant="outline-primary"
                :disabled="selectedWeborders.length <= 0"
                @click="printPicklist()"
                :is-busy="isBusy"
                >Print Picklist</p-button
            >
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import { datetimeToLocalTimeFormatter ,currencyFormatter} from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import axios from 'axios';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            isBusy: false,
            filterName: 'WebOrdersFilter',
            selectedWeborders: [],
            filters: {
                automatic: null,
                perPage: 100,
                sortBy: 'webOrderNumber',
                sortDesc: true,
                searchBy: 'Web Order Number',
                filterByStatus: 'Complete',
                webOrderStartDate: null,
                webOrderEndDate: null,
                shippingMethod:null,
                isOpen: true,
                isPartial: true,
                isBackordered: true,
                isComplete: true,
                isFailed: true,
                isCancelled: true,
                isCompletedAtRegister: true,
                tenderId:null
            },
            filterBy: [
                'All',
                'Order Number',
                'Customer Name',
                'Customer Email',
                'Shipping Method'
            ],
            fields: [
                {
                    key: 'webOrderNumber',
                    sortable: true,
                    label: 'Order Number'
                },
                {
                    key: 'webOrderDate',
                    sortable: true,
                    label: 'Order Capture Date',
                    formatter: datetimeToLocalTimeFormatter
                },
                 {
                    key: 'shippingMethod',
                    sortable: true,
                    label: 'Shipping Method',
                },
                {
                    key: 'tender',
                    label: 'Tender Type',
                },
                {
                    key: 'webOrderTotal',
                    sortable: true,
                    label: 'Captured Order Total',
                    tdClass: 'text-right',
                    thClass: 'text-center',
                    formatter: currencyFormatter,
                },
                {
                    key: 'customerName',
                    sortable: true,
                    label: 'Customer Name'
                },
                {
                    key: 'customerEmail',

                    label: 'Customer Email'
                },
                {
                    key: 'customerPhone',

                    label: 'Customer Phone Number'
                },
                {
                    key: 'webOrderStatus',
                    label: 'Order Status',
                    sortable: true
                },
                {
                    key: 'saleId',
                    hidden: true
                }
            ]
        };
    },
    methods: {
        onSelection(weborders) {
            this.selectedWeborders = weborders;
        },
        async printPicklist() {

            let selectedids = this.selectedWeborders.map(obj => obj.saleId);
            var locId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            let apiUrl = `/weborders/exportPrintPickList`;

            this.isBusy = true;

            axios
                .post(
                    apiUrl,
                    {
                        ids: selectedids,
                        locationId: locId
                    },
                    { responseType: 'blob' }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        `Print Picklist`,
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            `Print picklist report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>
