<template>
  <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
    <SearchScreen
        title="Transaction Search"
        api-destination="transactions"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        >
        <template v-slot:actionButtons>
            <b-row class="pl-3 pr-2">
               <p-button
                    variant="outline-primary"
                    class="mr-2"
                    v-on:click="printScreen()"
                    >Print</p-button
                >
                <b-overlay :show="isExporting"
                                       rounded
                                       opacity="0.5"
                                       spinner-small
                                       spinner-variant="primary"
                                       class="d-inline-block">
                                <b-dropdown text="Export" variant="outline-primary">
                                    <b-dropdown-item @click="exportTransactions('csv')" >Export Summary (CSV)</b-dropdown-item>
                                    <b-dropdown-item @click="exportTransactions('pdf')" >Export Details (PDF)</b-dropdown-item>
                                    <b-dropdown-item @click="exportTransactions('detailsCsv')" >Export Details (CSV)</b-dropdown-item>
                                </b-dropdown>
                            </b-overlay>
            </b-row>
        </template>
        <template v-slot:belowSearch>
            <p-form>
                <b-row class="pl-3 pr-2" cols="1" cols-sm="2" cols-xl="3">
                    <b-col>
                        <p-select
                            label="POS Device"
                            data-type="posDevices"
                            v-model="filters.posDeviceId"
                            noSelectionMessage="Show All"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Cashier"
                            data-type="nbcUsers"
                            v-model="filters.cashierId"
                            noSelectionMessage="Show All"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Transaction Type"
                            :options="transactionTypeOptions"
                            v-model="filters.transactionType"
                            noSelectionMessage="Show All"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Tender"
                            data-type="tenders"
                            v-model="filters.tenderId"
                            noSelectionMessage="Show All"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="AR Account / Agency"
                            data-type="accounts"
                            v-model="filters.accountId"
                            noSelectionMessage="Show All"
                        />
                    </b-col>
                </b-row>
                <b-row class="pl-3 pr-2">
                    <b-col>
                            <p-advance-date-range :date-range-options="filters" />
                    </b-col>
                </b-row>
            </p-form>
        </template>
        <template v-slot:cell(view)="{ value, item }">
            <b-button variant="outline-primary" size="sm" @click='getTransaction(item)' v-b-modal.customerTransactionModal>View</b-button>
        </template>
    </SearchScreen>
    <TransactionDetailModal :transaction="selectedTransaction" />
    </p-secure>
</template>

<script>
    import axios from 'axios';
    import SearchScreen from '../../components/SearchScreen.vue';
    import { datetimeToLocalTimeFormatter, currencyFormatter } from '../../components/Common/Formatters.js';
    import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
    import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
    import TransactionDetailModal from '../../components/TransactionDetailModal.vue';

    export default {
        data() {
            return {
                isExporting: false,
                selectedTransaction: null,
                filters: {
                    automatic: "",
                    perPage: 100,
                    sortBy: 'transactionId',
                    sortDesc: true,
                    searchBy:'All',
                    fromDate: new Date(),
                    toDate: new Date(),
                    posDeviceId: null,
                    cashierId: null,
                    transactionType: null,
                    tenderId: null,
                    accountId: null
                },
                filterBy:[
                    'All',
                    'Transaction ID',
                    'Last Name',
                    'First Name',
                    'Customer Account #',
                    'Serial #',
                    'Dollar Amount'
                ],
                transactionTypeOptions: [
                    { text: 'Pay In', value: 'Pay In' },
                    { text: 'Pay Out', value: 'Pay Out' },
                    { text: 'Refund', value: 'Refund' },
                    { text: 'Sale', value: 'Sale' },
                    { text: 'Rental Check In', value: 'Rental Check In' },
                    { text: 'Rented', value: 'Rented' }
                ],
                fields: [
                    {
                        key: 'view',
                        label: ''
                    },
                    {
                        key: 'transactionId',
                        label: 'Transaction ID',
                        sortable: true
                    },
                    {
                        key: 'lastName',
                        label: 'Last Name',
                        sortable: true
                    },
                    {
                        key: 'firstName',
                        label: 'First Name',
                        sortable: true
                    },
                    {
                        key: 'studentId',
                        label: 'Student ID',
                        sortable: true
                    },
                    {
                        key: 'transactionDate',
                        label:'Date & Time',
                        sortable: true,
                        formatter: datetimeToLocalTimeFormatter
                    },
                    {
                        key: 'posDeviceName',
                        label: 'POS Device',
                        sortable: true
                    },
                    {
                        key: 'cashier',
                        label: 'Cashier',
                        sortable: true
                    },
                    {
                        key: 'tender',
                        label: 'Tender'
                    },
                    {
                        key: 'arAccountCode',
                        label: 'AR Code'
                    },
                    {
                        key: 'arAgencyName',
                        label: 'AR Agency',
                        formatter: (value) => {
                            return value?.substr(0,15);
                        },
                    },
                    {
                        key: 'transactionType',
                        label: 'Transaction Type',
                        formatter: (value, key, item) => {
                            let transactionTypes = [];

                            if (item.hasPayIn)
                            {
                                transactionTypes.push("Pay In");
                            }

                            if (item.hasPayOut)
                            {
                                transactionTypes.push("Pay Out");
                            }

                            if (item.hasRefund)
                            {
                                transactionTypes.push("Refund");
                            }

                            if (item.hasSale)
                            {
                                transactionTypes.push("Sale");
                            }

                            if (item.hasCheckIn)
                            {
                                transactionTypes.push("Rental Check In");
                            }

                            if (item.hasRented)
                            {
                                transactionTypes.push("Rented");
                            }

                            return transactionTypes.join(', ');
                        },

                        sortable: true
                    },
                    {
                        key: 'transactionTotal',
                        label: 'Total',
                        sortable: true,
                        formatter: currencyFormatter,
                        thClass: 'text-center',
                        tdClass: 'text-right'
                    }
                ]
            };
        },
        methods: {
            printScreen()
            {
                window.print();
            },
            getTransaction(transaction) {
                this.selectedTransaction = null;
                axios.get("customers/getTransaction", { params: { transactionId: transaction.transactionId, saleFulfillmentId: transaction.saleFulfillmentId } })
                    .then(resp => {
                        this.selectedTransaction = resp.data;
                        this.$bvModal.show("customerTransactionModal");
                    });
            },
            async exportTransactions(type) {
                this.isExporting = true;

            if (type == 'csv' || type == 'detailsCsv') {
                let apiUrl = '/transactions/exporttransactions';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                axios
                    .post(
                        apiUrl,
                        {
                            automatic: this.filters.automatic,
                            searchBy: this.filters.searchBy,
                            locationId: await selectListOptionsDataContext.getSelectedLocationIdAsync(),
                            fromDate: this.filters.fromDate,
                            toDate: this.filters.toDate,
                            posDeviceId: this.filters.posDeviceId ?? 0,
                            cashierId: this.filters.cashierId ?? 0,
                            transactionType: this.filters.transactionType,
                            tenderId: this.filters.tenderId,
                            sortBy: this.filters.sortBy,
                            sortDesc: this.filters.sortDesc,
                            accountId: this.filters.accountId,
                            isExportDetailsCsv:(type == 'detailsCsv'?true:false)
                        },
                        { responseType: 'blob' }
                    )
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            `Transction Search.csv`,
                            'application/csv'
                        );
                        this.$toasted.global
                            .app_success(
                                `Transaction Search report generated successfully.`
                            )
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isExporting = false;
                    });
            }
            if (type == 'pdf') {
                let apiUrl='/transactions/exportpdftransactions';
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                this.isExporting = true;
                axios
                    .post(
                        apiUrl,
                        {
                            automatic: this.filters.automatic,
                            searchBy: this.filters.searchBy,
                            locationId: await selectListOptionsDataContext.getSelectedLocationIdAsync(),
                            fromDate: this.filters.fromDate,
                            toDate: this.filters.toDate,
                            posDeviceId: this.filters.posDeviceId ?? 0,
                            cashierId: this.filters.cashierId ?? 0,
                            transactionType: this.filters.transactionType,
                            tenderId: this.filters.tenderId,
                            sortBy: this.filters.sortBy,
                            sortDesc: this.filters.sortDesc,
                            accountId: this.filters.accountId
                        },
                        { responseType: 'blob' }
                    )
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            `TransctionDetails`,
                            'application/pdf'
                        );
                        this.$toasted.global
                            .app_success(
                                `Transaction Search report generated successfully.`
                            )
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isExporting = false;
                    });
            }            
        },
        },
        components: {
            SearchScreen,
            TransactionDetailModal
        }
    };
</script>
<style scoped>
</style>