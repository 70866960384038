export function downloadFileToBrowser(data, fileName, fileType) {
    const blob = new Blob([data], { type: fileType });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download =
        fileType == 'application/csv'
            ? fileName.replace('.csv', '-' + reportDateTimeFormatter() + '.csv')
            : fileName + '-' + reportDateTimeFormatter();
    link.click();
    URL.revokeObjectURL(link.href);
}

export function reportDateTimeFormatter() {
    const current = new Date();

    const date = `${current.getFullYear()}${current.getMonth() +
        1}${current.getDate()}`;

    const time = `${current.getHours()}${current.getMinutes()}${current.getSeconds()}`;

    const reportDatetime = date + '-' + time;

    return reportDatetime;
}
