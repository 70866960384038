<template>
  <ContentWrapper>
    <b-container fluid="lg">
      <b-row class="mb-3">
        <b-col>p-input</b-col>
        <b-col>
          <p-input
            v-model="inputValue"
            name="demoInput"
            label="PInput"
            placeholder="Enter Greeting"
          />
        </b-col>
        <b-col>Input Value: {{ inputValue }}</b-col>
      </b-row>
      <b-row>
        <b-col>p-input debounce</b-col>
        <b-col>
          <p-input
            v-model="inputDebounceValue"
            name="demoInputDebounce"
            label="PInput With Debounce For Expensive Updates"
            :debounce-wait="600"
            placeholder="Enter Costly Input"
          />
        </b-col>
        <b-col>Input Value: {{ inputDebounceValue }}</b-col>
      </b-row>

      <hr/>

       <b-row>
        <b-col>p-number (money)</b-col>
        <b-col>
          <p-number
            v-model.number="price"
            input-type="money"
            name="moneyDemo"
            label="PMoney"
          />
        </b-col>
        <b-col>Input Value: {{ price | number }}</b-col>
      </b-row>
       <b-row>
        <b-col>p-number (percent)</b-col>
        <b-col>
          <p-number
            v-model.number="price"
            input-type="percent"
            name="percentageDemo"
          />
        </b-col>
        <b-col>Input Value: {{ price | number }}</b-col>
      </b-row>
      <hr />

      <b-row class="mb-3">
        <b-col>p-select</b-col>
        <b-col>
          <p-select
            v-model="selectValue"
            name="demoSelect"
            label="PSelect"
            no-selection-message="Please select your favorite letter"
            :options="options"
          />
        </b-col>
        <b-col>Selected Value: {{ selectValue }}</b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>p-advanced-select</b-col>
        <b-col>
          <p-advanced-select
            v-model="selectValue"
            name="demoAdvancedSelect"
            label="PAdvancedSelect"
            item-text="alternateText"
            no-selection-message="Please select your favorite letter"
            :options="options"
          />
        </b-col>
        <b-col>Selected Value: {{ selectValue }}</b-col>
      </b-row>

      <hr />

      <b-row class="mb-3">
        <b-col>p-checkbox-group</b-col>
        <b-col>
          <p-checkbox-group
            v-model="selectedCheckBoxes"
            name="demoCheckbox"
            label="PCheckboxGroup - Stacked"
            stacked
            :options="options"
          />
          <p-checkbox-group
            v-model="selectedCheckBoxes"
            label="PCheckboxGroup - Inline"
            :options="options"
          />
        </b-col>
        <b-col>Selected Value: {{ selectedCheckBoxes }}</b-col>
      </b-row>

      <hr />

      <b-row class="mb-3">
        <b-col>p-filter-select</b-col>
        <b-col>
          <p-filter-select dataType="states"
          label="States"
															v-model="selectedStates"/>
        </b-col>
        <b-col>Selected Value: {{ selectedStates }}</b-col>
      </b-row>

      <hr />

      <b-row class="mb-3">
        <b-col>p-checkbox</b-col>
        <b-col>
          <p-checkbox v-model="checkboxValue" label="PCheckbox" />
        </b-col>
        <b-col>Selected Value: {{ checkboxValue }}</b-col>
      </b-row>

      <hr />

      <b-row class="mb-3">
        <b-col>p-text-area</b-col>
        <b-col>
          <p-text-area v-model="textAreaValue" label="PTextArea" />
        </b-col>
        <b-col>Text Area Value: {{ textAreaValue }}</b-col>
      </b-row>

      <hr />

      <b-row class="mb-3">
        <b-col>p-datepicker</b-col>
        <b-col>
          <p-datepicker v-model="datePickerValue" label="PDatePicker" />
        </b-col>
        <b-col>Selected Value: {{ datePickerValue }}</b-col>
      </b-row>

      <hr />

      <b-row class="mb-3">
        <b-col>p-modal</b-col>
        <b-col>
          <p-modal
            button-label="OK Modal"
            no-close-on-backdrop
            ok-only
            name="OK-Model"
            label="PModal Confirm"
          >This is an important message!</p-modal>

          <p-modal
            button-label="Edit Options"
            no-close-on-backdrop
            @show="editDialogOptions = JSON.parse(JSON.stringify(options))"
            @ok="options = editDialogOptions"
            label="Edit Options"
          >
            <template v-slot:default>
              <b-row>
                <b-col>Text</b-col>
                <b-col>Value</b-col>
                <b-col></b-col>
              </b-row>
              <b-row v-for="(option, index) in editDialogOptions" :key="index">
                <b-col>
                  <p-input v-model="option.text" />
                </b-col>
                <b-col>
                  <p-input v-model="option.value" />
                </b-col>
                <b-col>
                  <p-checkbox v-model="option.disabled" label="Disabled" />
                </b-col>
              </b-row>
              <b-button v-b-modal.AddModel>Add A New Option</b-button>
            </template>
          </p-modal>

          <p-modal
            @show="newOption = { value: '', text: '' }"
            @ok="editDialogOptions.push(newOption)"
            size="sm"
            name="AddModel"
            label="Add A New Option"
            ref="addOptionModal"
          >
            <template v-slot:default>
              <p-input v-model="newOption.text" label="Option Text" />
              <p-input v-model="newOption.value" label="Option Value" />
            </template>
          </p-modal>
          <b-button @click="showModal">Add option modal via show()</b-button>
        </b-col>
        <b-col></b-col>
      </b-row>

      <hr/>

      <b-row class="mb-3">
        <b-col>PDF Test</b-col>
        <b-col>
          <p-button @click="downloadReport">Download Report</p-button>
        </b-col>
        <b-col></b-col>
      </b-row>

      <hr/>

      <b-row class="mb-3">
        <b-col>p-draggable</b-col>
        <b-col>
          <p-dragable numbered v-model='dragList' top-message="(Top Pick)" bottom-message="(Last Pick)"></p-dragable>
        </b-col>
        <b-col>{{dragList}}</b-col>
      </b-row>

      <hr/>

      <b-row class="mb-3">
        <b-col>print</b-col>
        <b-col>
          <p-button @click="printTestPriceTag" >Test Print Price Tag</p-button>
          <span id='testContent' v-html="testContent" />
        </b-col>
      </b-row>
    </b-container>
  </ContentWrapper>
</template>

<script>
import axios from 'axios'
import printJS from 'print-js'

export default {
  name: "Components",
  data() {
    return {
      selectedSku: null,
      dragList: [{
                    name: 'Trevor Lawrence'
                }, {
                    name: 'Penei Sewell'
                }, {
                    name: 'Micah Parsons'
                }, {
                    name: 'Justin Fields',
                    active: true
                }, {
                    name: 'Ja\'Marr Chase'
                }],
      inputValue: "Hello",
      inputDebounceValue: "",
      price: 1234.56,
      selectValue: "b",
      selectedCheckBoxes: ["b"],
      selectedStates: [],
      checkboxValue: true,
      textAreaValue: "Test Text Area Data",
      datePickerValue: "Sun Oct 16 2016 00:00:00 GMT-0500",
      options: [
        { value: "a", text: "(a)", alternateText: 'alternate (a)' },
        { value: "b", text: "(b-selected)", alternateText: "alternate (b-selected)" },
        { value: "c", text: "(c)" , alternateText: "alternate (c)" },
        { value: "d", text: "This one is disabled", alternateText: "alternate This one is disabled", disabled: true }
      ],
      newOption: {},
      editDialogOptions: [],
      testContent: null
    };
  },
  computed: {},
  methods: {
    showModal() {
      this.$refs.addOptionModal.show();
    },
    async downloadReport() {
        await axios
            .get("reports/test",{
                params: this.filters,
                responseType: 'blob'
            })
            .then(result => {
                const blob = new Blob([result.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download =`ReportTest.pdf`;
                link.click();
                URL.revokeObjectURL(link.href);
            });
    },
    async printTestPriceTag() {

        //need to supply sku/productbarcodeid for this to work properly.
        await axios
        .get("products/printpricetag?sku=50000593&barcodeId=121447")
        .then(result => {
            this.testContent = result.data;
            this.$nextTick(() => {
                printJS("testContent", 'html', '#testContent: visible;');
                this.testContent = null;
            });
        });
    }
  }
};
</script>

<style scoped>
#testContent {
    visibility: hidden;
}
</style>
