<template>

     <span>
        <SearchScreen
            title="Reports"
            :filters="filters"
            :fields="fields"
            :filterBy="filterBy"
            api-destination="reports"
        >
            <template v-slot:cell(view)="{ item }">
                <p-button
                variant="outline-primary"
                 @click="launchReport(item)"
                  size="sm"
                  :is-busy="isBusy"
                    >View</p-button
                >
            </template>
        </SearchScreen>

        <TotalInventoryReport ref="Total Inventory Value"></TotalInventoryReport>
        <InventoryByDcc ref="Inventory By DCC"></InventoryByDcc>
        <InventoryByFixture ref="Inventory By Fixture"></InventoryByFixture>
        <InventoryNotCounted ref="Inventory Not Counted"></InventoryNotCounted>
        <PriceListReport ref="Price List"></PriceListReport>
        <CourseListReport ref="Course List"></CourseListReport>
        <AuthorListReport ref="Author List" />
        <SalesByDccReport ref="Sales By DCC"/>
        <PosDeviceReconciliationReport ref="POS Device Reconciliation"/>
        <CashierReconciliationReport ref="Cashier Reconciliation"/>
        <TaxesByJurisdictionReport ref="Taxes By Jurisdiction"/>
        <RentalReconciliationReport ref="Rental Reconciliation"/>
        <RentalStatusReport ref="Rental Status"/>
        <AppleSalesReport ref="Apple Report"/>
        <InvoiceDetailReport ref="Invoice Detail Report"/>
        <WebSalesReport ref="Web Sales and Tax by State"/>
        <ProductSalesReport ref="Product Sales"/>
    </span>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

import TotalInventoryReport from '@/views/Reports/PhysicalInventory/TotalInventory.vue';
import InventoryByDcc from '@/views/Reports/PhysicalInventory/InventoryByDcc.vue';
import InventoryByFixture from '@/views/Reports/PhysicalInventory/InventoryByFixture.vue';
import InventoryNotCounted from '@/views/Reports/PhysicalInventory/InventoryNotCounted.vue';
import AuthorListReport from '@/views/Reports/Terms/AuthorList.vue';
import CourseListReport from '@/views/Reports/Terms/CourseList.vue';
import PriceListReport from '@/views/Reports/Terms/PriceList.vue';
import SalesByDccReport from '@/views/Reports/Sales/SalesByDcc.vue';
import PosDeviceReconciliationReport from '@/views/Reports/Sales/PosDeviceReconciliation.vue';
import CashierReconciliationReport from '@/views/Reports/Sales/CashierReconciliation.vue';
import TaxesByJurisdictionReport from '@/views/Reports/Sales/TaxesByJurisdiction.vue';
import RentalReconciliationReport from '@/views/Reports/Sales/RentalReconciliation.vue';
import RentalStatusReport from '@/views/Reports/Rentals/RentalStatus.vue';
import AppleSalesReport from '@/views/Reports/Sales/AppleSales.vue';
import InvoiceDetailReport from '@/views/Reports/Sales/InvoiceDetailReport.vue';
import WebSalesReport from '@/views/Reports/Sales/WebSales.vue';
import ProductSalesReport from '@/views/Reports/Sales/ProductSales.vue';

import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import axios from 'axios';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            isBusy: false,
            filterName: 'reportsFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All', 'Name','Category', 'Description'],
            fields: [
                {
                    key: 'view',
                    class: 'tcw100'
                },
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'category',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                }
            ]
        };
    },
    methods: {
        async launchReport(item){
        if(item.name == 'Gift Card Summary')
        {
            let apiUrl = '/giftcards/exportgiftcards';
            this.isBusy = true;
            axios
                 .get(apiUrl, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        `Gift Card Report`,
                         'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            `Gift Card Report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
            }
            else if(item.name == 'Stock Adjustment')
            {
                this.$router.push('/stockadjustmentreport');
            }
            else
            {
                this.isBusy = false;
                this.$refs[item.name].show();
            }
        }
    },
    components: {
        SearchScreen,
        TotalInventoryReport,
        InventoryByDcc,
        InventoryByFixture,
        InventoryNotCounted,
        AuthorListReport,
        CourseListReport,
        PriceListReport,
        SalesByDccReport,
        PosDeviceReconciliationReport,
        CashierReconciliationReport,
        TaxesByJurisdictionReport,
        RentalReconciliationReport,
        RentalStatusReport,
        AppleSalesReport,
        InvoiceDetailReport,
        WebSalesReport,
        ProductSalesReport
    }
};
</script>
<style scoped></style>
