
export default {
    data() {
        return {}
    },
    async created() {
        var filters = localStorage.getItem(this.filterName);
        if (filters != null) {
            Object.assign(this.filters, JSON.parse(filters));
        }
    },
    async beforeRouteLeave(to, from, next) {
        localStorage.setItem(this.filterName, JSON.stringify(this.filters));
        next();
    }
}