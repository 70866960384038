<template>
    <p-secure require-admin>
        <SearchScreen
            ref="searchScreen"
            title="Users"
            api-destination="users"
            :filters="filters"
            :filterBy="filterBy"
            :fields="fields"
            add-new-button
            edit-key="userId"
            @isActiveChanged="updateIsActiveStatus"
        >
            <template v-slot:afterSearch>
                <b-col cols="3">
                            <p-select class="h-auto ml-2 shadow-none"
                                    v-model="filters.returnOnlyActiveRecords"
                                    :options="searchOptions" />
                </b-col>
            </template>
        </SearchScreen>
    </p-secure>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'UsersFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'displayName',
                sortDesc: true,
                searchBy:'All',
                returnOnlyActiveRecords: true
            },
            filterBy:[
                'All',
                'Display Name',
                'Username',
                'Email'
            ],
            searchOptions: [
                { value: null, text: 'All Users' },
                { value: true, text: 'Active Users' },
                { value: false, text: 'Inactive Users' }
            ],
            fields: [
                {
                    key: 'displayName',
                    sortable: true
                },
                {
                    key: 'userName',
                    sortable: true
                },
                {
                    key: 'email',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: false
                }
            ]
        };
    },
    components: {
        SearchScreen
    },
    methods: {
        updateIsActiveStatus(user) {
            var self = this;
            axios.post('users', user).then(() => {
                self.$refs.searchScreen.refreshSearchResults();
                var msg = `${user.userName} ${
                    user.isActive ? 'activated' : 'de-activated'
                } successfully`;
                this.$toasted.global.app_success(msg).goAway(5000);
            });
        }
    }
};
</script>
<style scoped>
>>> .custom-select{
    height: 43px;
}
</style>
