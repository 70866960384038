import { max, email, min_value } from 'vee-validate/dist/rules';

export default {
    data() {
        return {
            customerConfiguration: {
                type: 'customers',
                label: 'Customers',
                importModes: [
                    {value:'add', text:'Add New (Skip when account number already present)'},
                    {value:'update', text:'Update (Skip when account number not present, update when found)'},
                    {value:'addAndUpdate', text:'Add and Update'}
                ],
                sampleNotes: [
                    'State must be provided as a valid abbreviation.'
                ],
                fields: [
                    {
                        key: 'AccountNumber',
                        hasWarningCalculator: (value, row, checkOriginalData) => {
                            return (
                                !this.isEmpty(value)
                                && !max.validate(value, { length: 75 })
                                || this.isDuplicatedOnImportNumber(value, checkOriginalData)
                            );
                        },
                        warningDefault: null,
                        rules: {
                            max: { length: 75 },                            
                            'unique-value': {
                                isDuplicated: this.isDuplicatedOnImportNumber
                            },
                        },
                        samples: ['"Cust-0040518"', '"Cust-0040519"']
                    },
                    {
                        key: 'FirstName',
                        hasErrorCalculator: value => {
                            return (
                                this.isEmpty(value) ||
                                !max.validate(value, { length: 50 })
                            );
                        },

                        rules: {
                            max: { length: 50 },
                            required: true
                        },
                        samples: ['"Sophia"', '"Liam"']
                    },
                    {
                        key: 'LastName',
                        hasErrorCalculator: value => {
                            return (
                                this.isEmpty(value) ||
                                !max.validate(value, { length: 50 })
                            );
                        },

                        rules: {
                            max: { length: 50 },
                            required: true
                        },
                        samples: ['"Miller"', '"Garcia"']
                    },
                    {
                        key: 'StudentID',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 75 });
                        },
                        warningDefault: null,
                        rules: {
                            max: { length: 75 }
                        },
                        samples: ['"MI7085"', '"GA71505"']
                    },
                    {
                        key: 'AlternateLookupID',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 75 });
                        },
                        warningDefault: null,
                        rules: {
                            max: { length: 75 }
                        },
                        samples: ['"12342143"', '"1234254365"']
                    },
                    {
                        key: 'EmailAddress',
                        hasWarningCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return (
                                !email.validate(value) ||
                                !max.validate(value, { length: 254 })
                            );
                        },
                        warningDefault: null,
                        rules: 'email|max:254',
                        samples: ['"sm101@mail.com"', '"lg@mail.com"']
                    },

                    {
                        key: 'ContactAddress',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 255 });
                        },
                        warningDefault: '',
                        rules: 'max:255',
                        samples: ['"4825 N 1st Street"', '"1487 N 2nd Street"']
                    },
                    {
                        key: 'ContactCity',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 50 });
                        },
                        warningDefault: '',
                        rules: 'max:50',
                        samples: ['"Athens"', '"Phoenix"']
                    },

                    {
                        key: 'ContactState',
                        dataType: 'states',
                        comparisonProperty: 'abbreviation',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"OK"', '"AZ"']
                    },
                    {
                        key: 'ContactPostalCode',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"68747"', '"87516"']
                    },

                    {
                        key: 'ShippingAddress',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 255 });
                        },
                        warningDefault: '',
                        rules: 'max:255',
                        samples: [
                            '"223 N 1st Street"',
                            '"1487 N 2nd Street"',
                            '"1212 S 1st Street"'
                        ]
                    },
                    {
                        key: 'ShippingCity',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 50 });
                        },
                        warningDefault: '',
                        rules: 'max:50',
                        samples: ['"Vernon"', '"Phoenix"']
                    },

                    {
                        key: 'ShippingState',
                        dataType: 'states',
                        comparisonProperty: 'abbreviation',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        warningDefault: null,
                        rules: 'min_value:0',
                        samples: ['"CA"', '"AZ"']
                    },
                    {
                        key: 'ShippingPostalCode',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 20 });
                        },
                        warningDefault: '',
                        rules: 'max:20',
                        samples: ['"90058"', '"87516"']
                    },

                    {
                        key: 'PrimaryPhone',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 26 });
                        },
                        warningDefault: '',
                        rules: 'max:26',
                        samples: ['"1-555-444-5555"', '"1-555-789-3333"']
                    },
                    {
                        key: 'PrimaryPhoneExtension',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 6 });
                        },
                        warningDefault: '',
                        rules: 'max:6',
                        samples: ['"555"', '""']
                    },

                    {
                        key: 'SecondaryPhone',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 26 });
                        },
                        warningDefault: '',
                        rules: 'max:26',
                        samples: ['"1-555-444-1111"', '"1-555-789-2222"']
                    },
                    {
                        key: 'SecondaryExtension',
                        hasWarningCalculator: value => {
                            return !max.validate(value, { length: 6 });
                        },
                        warningDefault: '',
                        rules: 'max:6',
                        samples: ['"1234"', '""']
                    },
                    {
                        key: 'IsActive',                        
                        dataType: 'yesNoOptions',
                        hasWarningCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        warningDefault: true,
                        rules: 'min_value:0',
                        samples: ['Yes', 'No']
                    }
                ],
                canRunNoParse: true,
                cronJobTypeId: 11
            }
        };
    },    
    methods: {
       isDuplicatedOnImportNumber(number, checkOriginalData) {
            return (
                number != '' &&
                this.loadedData.filter(x => x.AccountNumber === number||
                    (checkOriginalData &&
                        x.originalData?.AccountNumber === number)).length > 1
            );
        },
    }
};
