<template>

        <p-modal name="exportInventoryValueModal"
                 label="Export Total Inventory Value"
                 ref="exportInventoryValueModal"
                 size="lg"
                 @show="loadParameters">
            <template v-slot:default>
                <div class="container-fluid content-wrapper">
                    <b-row cols="1" cols-md="2">
                        <b-col>
                            <p-card class="mb-2 px-2 py-2" no-body>
                                <p-filter-select item-text="text"
                                                 data-type="productTypes"
                                                 label="Product Type"
                                                 v-model="reportParameters.productTypes"
                                                 :default-selected="true"
                                                 :clear-button="false" />
                            </p-card>
                            <p-select v-model="reportParameters.negativeInventory"
                                      name="negativeInventory"
                                      label="Negative Inventory"
                                      :options="negativeInventoryOptions" />
                            <p-checkbox size="md"
                                        label="Include inventory detail"
                                        v-model="
                                    reportParameters.includeInventoryDetail
                                " />
                            <p-checkbox size="md"
                                        label="Only include inventory where SOH is not zero"
                                        v-model="reportParameters.onlyStockNotZero" />
                        </b-col>
                        <b-col>
                            <p-card class="mb-2 pl-2 py-2" no-body>
                                <label class="text-sm"
                                       v-if="dccSelectorHelpText">{{ dccSelectorHelpText }}</label>
                                <DCCsSelector class="container-fluid"
                                              @selection-change="
                                        reportParameters.dccIds = $event
                                    " />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:modal-footer>
                <b-row>
                    <b-col>
                        <b-overlay :show="isBusy"
                                   rounded
                                   opacity="0.5"
                                   spinner-small
                                   spinner-variant="primary"
                                   class="d-inline-block">
                            <b-dropdown text="Export"
                                        variant="outline-primary">
                                <b-dropdown-item @click="exportInventoryValueReport('pdf')">PDF</b-dropdown-item>
                                <b-dropdown-item @click="exportInventoryValueReport('csv')">CSV</b-dropdown-item>
                            </b-dropdown>
                        </b-overlay>
                    </b-col>
                </b-row>
            </template>
        </p-modal>
</template>

<script>

import reports from './reports.js';

export default {
    props: {},
    mixins: [reports],
    data() {
        return {
        };
    },
     components: {
    },
    methods: {
            exportInventoryValueReport(type) {
            let destination = type === 'pdf' ? '/physicalInventory/exportInventoryValue' : '/physicalInventory/exportInventoryValueCsv';
            let message = `Total Inventory Value report generated successfully.`;
            let fileName = 'Total Inventory Value';
            this.exportReport(type, destination, message, fileName);
        },

        show() {
            this.$refs.exportInventoryValueModal.show();
        },
    }
};

</script>

<style scoped></style>
