<template>
    <div class="container-fluid content-wrapper" v-if="creditRequest">
        <p-form @submit="onSubmit" id="credit-request-form">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Credit Request</div>
                </b-col>
                <b-col cols="auto" class="ml-auto" >
                    <b-button type="submit" variant="primary"
                        >Save</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col
                    cols="auto"
                    class="mr-2 mb-2 d-flex"
                    v-if="!isNew"
                >
                    <b-dropdown
                        :text="`Invoices (${allInvoices.length})`"
                        variant="outline-primary"
                        v-if="creditRequest.includeInvoiceInformation != false"
                    >
                        <b-dropdown-item
                            v-for="invoice in allInvoices"
                            :key="invoice.invoiceId"
                            @click="
                                loadInvoice(invoice.invoiceId)
                            "
                        >
                            {{ invoice.invoiceNumber }}
                        </b-dropdown-item>
                    </b-dropdown>
                    <ExportCreditRequest
                        :credit-request="creditRequest"
                        :is-busy="isBusy"
                        class="ml-2"
                    >
                    </ExportCreditRequest>
                </b-col>
            </b-row>
            <p-card>
                <CreditRequest
                    :credit-request="creditRequest"
                    :invoices="creditRequest.invoices"
                    :all-request-details="allRequestDetails"
                     @date-picker-initialized="e => {dataLoaded()}"
                    :is-busy="isBusy"
                />
            </p-card>
            <b-row>
                <b-col class=" mt-3 text-center">
                    <p-button
                        variant="primary"
                        type="submit"
                        form="credit-request-form"
                        :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </p-form>
    </div>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import editPage from '@/components/mixins/EditPage';
import CreditRequest from '@/views/PurchaseOrders/CreditRequest.vue';
import ExportCreditRequest from '@/components/ExportCreditRequest.vue';

export default {
    mixins: [NavigationGuard, editPage],

    components: {
        CreditRequest,
        ExportCreditRequest
    },
    data() {
        return {
            creditRequest: null,
            isBusy: false
        };
    },
    watch: {
        creditRequest: NavigationGuard.$watcher
    },
    mounted: function() {
        this.loadData();
    },

    computed: {
        allInvoices(){
            let invoices = this.creditRequest.creditRequestDetails
                        .filter(x => x.invoiceId != null)
                        .map(x =>({invoiceId: x.invoiceId, invoiceNumber : x.invoiceNumber}))
            return invoices
                        .filter((e, i) =>  invoices.findIndex(a => a.invoiceId === e.invoiceId) === i)
                        .sort((a, b) => {return a.invoiceNumber.localeCompare(b.invoiceNumber) });
      },

        allRequestDetails: function() {
            return this.creditRequest.creditRequestDetails.map(y => ({
                requestNumber: this.creditRequest.requestNumber,
                ...y
            }));
        }
    },

    methods: {
        loadInvoice(id) {
            this.$router.push(
                `/invoices/${id}`
            )
        },
        setInvoiceSettings(resp){
            resp.data.includeInvoiceInformation =  this.isNew || resp.data.invoices.length > 0;

            if(!resp.data.includeInvoiceInformation){
                resp.data.termId = resp.data.creditRequestDetails[0]?.termId;
                resp.data.updateOrderDecisions = resp.data.termId != null;
            }
        },
        onSubmit() {
            this.isBusy = true;
            if (this.creditRequest.creditRequestDetails.length === 0) {
                this.$toasted.global.app_error(
                    'Credit Request must have at least one product added before saving'
                );
                return;
            }

            if(!this.creditRequest.includeInvoiceInformation){
                //if they choose to update orderDecisions for a selected term, set the termId on the details,
                //otherwise the termid should be null
                let termId = this.creditRequest.updateOrderDecisions ? this.creditRequest.termId : null;
                this.creditRequest.creditRequestDetails.forEach(x => {x.termId = termId})
            }

            axios
                .post('creditrequests', this.creditRequest)
                .then(response => {
                    if (this.isNew) {
                        this.dataSaved();
                        this.$router.push(
                            '/creditrequests/' + response.data.creditRequestId
                        );
                    }
                    this.setInvoiceSettings(response);
                    this.creditRequest = response.data;
                    this.dataLoaded();

                    this.$toasted.global
                        .app_success(
                            `Credit Request '${this.creditRequest.requestNumber}' saved successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('creditRequests/new')
                : axios.get('creditRequests', { params: { id: this.id } });

            promise.then(resp => {
                this.setInvoiceSettings(resp);
                this.creditRequest = resp.data;
                if (!this.isNew) {
                         this.dataLoaded();
                    }
            });
        }
    }
};
</script>
