<template>
<!-- :button-label="buttonLabel" -->
    <p-modal

        :icon-plus="isNew"
        :size="sizemodal"
        :label="pageTitle"
        ref="editTaxRateModal"
        @ok.prevent="handleModalOk"
        @show="loadData"
        no-close-on-backdrop
        no-close-on-esc
        ok-only
        @close="checkDirtyBeforeModalClose"
    >
        <!-- :disable-button="disabled" -->
        <template v-slot:default>
            <div class="container-fluid content-wrapper" v-if="taxRate">
                <p-form ref="form">
                    <b-row>
                        <b-col cols="6">
                            <p-input
                                label="Tax Name"
                                name="Tax Name"
                                rules="required|max:80"
                                mode="lazy"
                                v-model="taxRate.name"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-card style="height:350px">
                                <h4>Current Tax Rate</h4>
                                <p-table
                                    :items="taxRate.taxCodes"
                                    :fields="computedFields"
                                    :show-record-count="false"
                                    :enable-row-selection="false"
                                    foot-clone
                                >
                                    <template
                                        v-slot:cell(rate)="{
                                            value,
                                            item,
                                            index
                                        }"
                                    >
                                        <p-number
                                            v-model="item.rate"
                                            :name="
                                                item.customTaxRateName + ' rate'
                                            "
                                            :data-input-name="
                                                'currentTaxRate' + (index + 1)
                                            "
                                            compact-format
                                            push-right
                                            :disabled="!isNew"
                                            input-type="percentHighPrecision"
                                            rules="required|min_value:0|max_value:99.999"
                                        />
                                    </template>
                                    <template v-slot:foot(rate)="data">
                                        <div class="font-weight-bold">
                                            {{ percentFormatter(total, 3) }}
                                        </div>
                                    </template>
                                    <template
                                        v-slot:foot(customTaxRateName)="data"
                                    >
                                        <div class="font-weight-bold pr-2">
                                            Tax Combined Total
                                        </div>
                                    </template>
                                </p-table>
                            </p-card>
                        </b-col>
                        <b-col cols="auto" v-if="!isNew">
                            <p-card style="height:350px">
                                <h4>New Tax Rate</h4>
                                <p-table
                                    :items="taxRate.taxCodes"
                                    :fields="newfields"
                                    :show-record-count="false"
                                    :enable-row-selection="false"
                                    foot-clone
                                >
                                    <template
                                        v-slot:cell(scheduledRate)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <p-number
                                            v-model="item.scheduledRate"
                                            :name="
                                                item.customTaxRateName + ' rate'
                                            "
                                            @input="scheduleIsDirty = true"
                                            compact-format
                                            push-right
                                            input-type="percentHighPrecision"
                                            rules="min_value:0|max_value:99.999"
                                        />
                                    </template>
                                    <template
                                        v-slot:foot(customTaxRateName)="data"
                                    >
                                        <div class="font-weight-bold pr-2">
                                            Tax Combined Total
                                        </div>
                                    </template>
                                    <template v-slot:foot(scheduledRate)="data">
                                        <div class="font-weight-bold pr-2">
                                            {{
                                                percentFormatter(
                                                    scheduledTotal,
                                                    3
                                                )
                                            }}
                                        </div>
                                    </template>
                                </p-table>
                                <b-row cols="2" v-if="!isNew">
                                    <b-col>
                                        <p-datepicker
                                            label="Start Date"
                                            v-model="scheduledDate"
                                            @date-picker-initialized="e => {scheduleIsDirty = false}"
                                            :start-date="tomorrow"
                                            @input="scheduleIsDirty = true"
                                        />
                                    </b-col>
                                    <b-col
                                        class="text-center"
                                        style="margin-top:30px"
                                    >
                                        <p-button
                                            variant="primary"
                                            :disabled="!canAddToSchedule"
                                            @click="addToSchedule"
                                            >Add to Schedule</p-button
                                        >
                                    </b-col>
                                </b-row>
                            </p-card>
                        </b-col>
                    </b-row>
                </p-form>
                <b-row class="pb-2" v-if="!isNew">
                    <b-col cols="auto">
                        <h4>Scheduled Updates</h4>
                    </b-col>
                </b-row>

                <p-card v-if="!isNew" name="scheduledUpdatesCard">
                    <b-overlay
                        :show="addingSchedule"
                        rounded
                        opacity="0.5"
                        spinner-small
                        spinner-variant="primary"
                        class="d-inline-block w-100"
                    >
                        <p-table
                            :items="scheduledRates"
                            :fields="scheduleFields"
                            :show-record-count="false"
                            :enable-row-selection="false"
                            ref="scheduleTable"
                        >
                            <template
                                v-slot:cell(description)="{
                                    value,
                                    item
                                }"
                            >
                                <template v-for="taxCode in item.taxCodes">
                                    {{ taxCode.customTaxRateName }}
                                    <br :key="taxCode.taxRateName" />
                                </template>
                                <strong>Total</strong>
                            </template>
                            <template
                                v-slot:cell(rate)="{
                                    value,
                                    item
                                }"
                            >
                                <template v-for="taxCode in item.taxCodes">
                                    {{ percentFormatter(taxCode.rate, 3) }}
                                    <br :key="taxCode.taxRateName" />
                                </template>
                                <strong>{{
                                    percentFormatter(
                                        item.taxCodes
                                            .map(x => x.rate)
                                            .reduce((a, b) => a + b, 0),
                                        3
                                    )
                                }}</strong>
                            </template>
                            <template
                                v-slot:cell(removeScheduledRate)="{
                                    value,
                                    item,
                                    index
                                }"
                            >
                                <b-button
                                    class="p-1 ml-1 text-danger short-button"
                                    :name="'deleteUpdate' + (index + 1)"
                                    variant="light"
                                    @click="removeScheduledRate(item)"
                                >
                                    <BIconTrash
                                        font-scale="0.9"
                                        icon="trash"
                                        class="mb-2"
                                    ></BIconTrash>
                                </b-button>
                            </template>
                        </p-table>
                    </b-overlay>
                </p-card>
            </div>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="text-center">
                    <p-button
                        :is-busy="isBusy"
                        name="saveEditTaxRate"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        :disabled="saveDisabled"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import {
    percentFormatter,
    dateFormatter
} from '@/components/Common/Formatters.js';
import moment from 'moment';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import Vue from 'vue';

export default {
    mixins: [NavigationGuard],
    props: {
        taxRateId: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,
            taxRate: null,
            scheduledDate: null,
            //seperate dirty watcher for schedule inputs
            scheduleIsDirty: false,
            addingSchedule: false,
            fields: [
                {
                    key: 'customTaxRateName',
                    label: 'Tax Description'
                },
                {
                    key: 'rate',
                    label: 'Tax Rate',
                    thClass: 'text-right',
                    tdClass: 'text-right',
                    formatter: value => {
                        return percentFormatter(value, 3);
                    }
                }
            ],
            newfields: [
                {
                    key: 'customTaxRateName',
                    label: 'Tax Description'
                },
                {
                    key: 'scheduledRate',
                    label: 'Tax Rate',
                    thClass: 'text-right',
                    formatter: value => {
                        return percentFormatter(value, 3);
                    }
                }
            ],
            scheduleFields: [
                {
                    key: 'startDate',
                    sortable: true,
                    hidden: true
                },
                {
                    key: 'startDateOnly',
                    label: 'Start Date',
                    formatter: (value, key, item) => {
                        return dateFormatter(item.startDate);
                    }
                },
                {
                    key: 'description',
                    label: 'Tax Description'
                },
                {
                    key: 'rate',
                    label: 'Tax Rate',
                    thClass: 'text-right',
                    tdClass: 'text-right'
                },
                {
                    key: 'removeScheduledRate',
                    label: 'Delete',
                    thClass: 'text-right',
                    tdClass: 'text-right'
                }
            ]
        };
    },
    watch: {
        taxRate: NavigationGuard.$watcher
    },
    computed: {
        canAddToSchedule() {
            return this.scheduleIsDirty && this.scheduledDate != null && this.scheduledDate != '' ;
        },
        isNew() {
            return this.taxRateId === 'new';
        },
        computedFields() {
            return this.fields.filter(x => !this.isNew || x.hideOnNew !== true);
        },
        pageTitle() {
            return this.isNew ? 'Add Tax Rate' : 'Edit Tax Rate';
        },
        tomorrow(){
            var date = new Date();
            date.setDate(date.getDate() + 1);
            return date;
        },
        sizemodal() {
            return this.isNew ? 'md' : 'xl';
        },
        buttonLabel() {
            return this.isNew ? 'Create Tax Rate' : 'Edit';
        },
        total() {
            return this.taxRate.taxCodes.reduce((a, b) => a + b.rate, 0);
        },
        scheduledTotal() {
            return this.taxRate.taxCodes.reduce(
                (a, b) => a + b.scheduledRate,
                0
            );
        },
        scheduledRates() {
            return this.taxRate.scheduledTaxRates
                .filter(x => x.removeFromSchedule !== true)
                .sort(function(a, b) {
                    return new Date(a.startDate) - new Date(b.startDate);
                });
        },
        saveDisabled(){
            return (this.taxRate!= null && !this.isNew)?this.taxRate.scheduledTaxRates.filter(x=>x.removeFromSchedule!=true).length <= 0:false;
        }
    },
    methods: {
        percentFormatter: percentFormatter,
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            this.isBusy = true;
            axios
                .post('taxes/taxrate', this.taxRate)
                .then(() => {
                    this.$emit('data-saved');
                    this.dataSaved();

                    this.$toasted.global
                        .app_success(
                            `Tax rate '${this.taxRate.name}' saved successfully.`
                        )
                        .goAway(5000);
                    this.hideModal();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        removeScheduledRate(scheduledRate) {
            this.$bvModal
                .msgBoxConfirm(
                    'This action will discard the selected update. Select ‘OK’ to continue.'
                )
                .then(value => {
                    if (value) {
                        Vue.set(scheduledRate, 'removeFromSchedule', true);
                    }
                });
        },
        hideModal() {
            this.$refs.editTaxRateModal.hide();
        },
        addToSchedule() {
            let newSheduledRate = JSON.parse(JSON.stringify(this.taxRate));
            newSheduledRate.startDate = this.scheduledDate;

            if (
                this.scheduledRates.some(
                    x =>
                        new Date(x.startDate).getTime() ==
                        this.scheduledDate.getTime()
                )
            ) {
                this.$toasted.global
                    .app_error(
                        'Update already scheduled for selected time.  Please remove before adding new.'
                    )
                    .goAway(5000);
                return;
            }
            this.addingSchedule = true;
            this.scheduleIsDirty = false;
            setTimeout(() => {
                //briefly loading spinner for user feedback
                this.addingSchedule = false;
            }, 450);

            newSheduledRate.taxCodes.forEach(x => {
                x.rate = x.scheduledRate === null ? 0 : x.scheduledRate;
                x.taxCodeId = null;
                x.startDate = this.scheduledDate;
            });

            this.taxRate.scheduledTaxRates.push(newSheduledRate);
        },
        setSchededTaxRateDefaults() {
            this.taxRate.taxCodes.forEach(x => {
                Vue.set(x, 'scheduledRate', null);
            });
        },
        loadData() {
            this.taxRate = null;
            this.scheduleIsDirty = false;
            this.scheduledDate = moment(new Date()).add(1, 'days');
            let promise = this.isNew
                ? axios.get('taxes/newtaxrate')
                : axios.get('taxes/taxrate', {
                      params: { id: this.taxRateId }
                  });

            promise.then(resp => {
                this.taxRate = resp.data;
                this.setSchededTaxRateDefaults();
                this.dataLoaded();
            });
        }
    },
    Components: {
        percentFormatter
    }
};
</script>
<style scoped>
td {
    /* there is a shorthand, TODO! 🙂 */
    margin: auto 0 auto auto;
}

.text-right1 {
    text-align: right !important;
    height: 30px !important;
}
</style>
