<template>
    <p-modal
        size="lg"
        :label="pageTitle"
        ref="authorList"
        name="exportAuthorListModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
        @show="initialize"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div>
                    <b-row cols="1" cols-md="2">
                        <b-col>
                            <p-tree
                                :data="filteredData"
                                include-search-filter
                                :filter-place-holder="'Search by campus'"
                                :key="filteredData.length"
                                @selection-change="onTreeSelection"
                            >
                            </p-tree>
                            <p-advanced-select
                                label="Sort Order"
                                :options="sortOrderOptions"
                                text-field="text"
                                value-field="value"
                                v-model="authorList.sortOrder"
                            />
                        </b-col>
                        <b-col>
                            <p-card class="mb-2 px-2 py-2" no-body>
                                <p-filter-select
                                    data-type="courseRequestBookStatuses"
                                    label="Request Type"
                                    :initial-display-count="8"
                                    :default-selected="true"
                                    v-model="
                                        authorList.CourseRequirementStatusIds
                                    "
                                />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button variant="outline-primary" 
                        :is-busy="isBusy" @click="cancel"
                        >Cancel</p-button
                    >
                    <p-button
                        variant="primary"
                        @click="ok"
                        :is-busy="isBusy"
                        class="ml-2"
                        >OK</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [StoreListManager],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        termId: {
            type: [Number],
            default: 0
        },
        campusId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            isBusy: false,
            authorList: {
                CourseRequirementStatusIds: [],
                sortOrder: 'AuthorTitleOrDescription',
                termSections: []
            },
            sortOrderOptions: [
                { text: 'Department Name', value: 'Department Name' },
                {
                    text: 'Department Description',
                    value: 'Department Description'
                },
                { text: 'Course', value: 'Course' },
                { text: 'Section', value: 'Section' },
                { text: 'Requested Quantity', value: 'Requested Quantity' },
                { text: 'Author/Title', value: 'AuthorTitleOrDescription' }
            ],
            terms: [],
            campuses: [],
            treeData: [],
            treeSelection: [],
            expandAll: false
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        },
        expandAll() {
            this.setTreeData();
        }
    },
    computed: {
        pageTitle() {
            return 'Author List';
        },

        filteredData() {        
            return this.treeData;
        }
    },
    methods: {
        initialize(){           
            this.treeSelection = [];
            this.setTreeData();
        },
        show() {
            this.$refs.authorList.show();
        },
        handleModalOk: async function() {
           if (this.authorList.termSections.length === 0) {
                this.$toasted.global.app_error('Please select one or more terms.');
                return;
            }
            this.isBusy = true;

            await axios
                .post('terms/exportAuthorList', this.authorList, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Author List',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(`Author list generated successfully.`)
                        .goAway(5000);
                    this.$refs.authorList.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        loadData() {},
        setTreeData() {
            this.treeData = this.campuses.map(t => ({
                ...t,
                icon: 'fa fa-university',
                level: 'campus',
                opened: this.expandAll,
                children: this.terms
                    .filter(x => x.campusId === t.value)
                    .map(x => ({
                        value: x.value,
                        text: x.text,
                        campusId: t.value,
                        campusName: t.text,
                        icon: 'fa fa-thumbtack',
                        level: 'term'
                    }))
            }));

            this.onTreeSelection(this.filteredData);
        },
        onTreeSelection(data) {
            this.treeSelection = data
                .flatMap(x => x.children)
                .filter(x => x.selected)
                .map(x => ({
                    termId: x.value,
                    termName: x.text,
                    campusId: x.campusId,
                    campusName: x.campusName
                }));
            this.authorList.termSections = this.treeSelection;
            this.$emit('selection-change', this.treeSelection);
        }
    },
    mounted: async function() {
        this.terms = await selectListOptionsDataContext.getStoreDropdownData(
            'terms'
        );

        this.campuses = await selectListOptionsDataContext.getStoreDropdownData(
            'campuses'
        );

        this.setTreeData();
    }
};
</script>
