<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="xl"
        :label="pageTitle"
        ref="editLocationModal"
        @ok.prevent="handleModalOk"
        @show="loadData"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div>
                    <b-row>
                        <b-col>
                            <p-input
                                label="Location Name"
                                v-model="location.name"
                                rules="required|max:128"
                            />
                        </b-col>
<!-- 
                        <b-col>
                            <p-input
                                label="Short Name"
                                v-model="location.shortName"
                                rules="required|max:24"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Abbreviation"
                                v-model="location.abbreviation"
                                rules="required|max:4"
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                label="Location Number"
                                input-type="integer"
                                v-model="location.storeNumber"
                                rules="required"
                            />
                        </b-col>
-->
                        <b-col>
                            <p-input
                                label="Postal Code"
                                v-model="location.postalCode"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                label="State/Province"
                                v-model="location.stateId"
                                dataType="states"
                                rules="required|max:64"
                            >
                            </p-select>
                        </b-col>
<!--                        
                        <b-col>
                            <p-select
                                label="Time Zone"
                                v-model="location.timeZone"
                                :options="
                                    timezoneOptions
                                "
                                rules="required"
                            />
                        </b-col>
-->                        
                    </b-row>                    
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row class="w-100">
                <b-col class="text-left">
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        @click="cancel"
                        class="ml-2"
                    >
                        Cancel
                    </p-button>                    
                </b-col>
                <b-col class="mb-3 text-right">
                    <p-button
                        ref="btnSave"
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="mr-2"
                        >Ok</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import pInput from '../../../components/p-input.vue';
//import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
  components: { pInput },
    mixins: [],
    props: {
        locationId: [String, Number],  
        locations : []
    },
    data() {
        return {
            isBusy: false,
            location: {
                locationId:0,
                name:"",
                //shortName:"",
                //abbreviation:"",
                //storeNumber:null,
                postalCode:"",
                stateId:null,
                timezone:null
            },
            timezoneOptions: []        
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.locationId == 'new';
        },
        pageTitle() {
            return 'Add New Location';
        },
        buttonLabel() {
            return this.isNew ? 'Add' : 'Edit';
        },
        buttonVariant() {
            return this.isNew ? 'primary' : 'outline-primary';
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            // if(this.nameExists(this.location.name) && this.storeExists(this.location.storeNumber)){
            //     this.$toasted.global
            //         .app_error('Location '+this.location.name+' and Store number '+this.location.storeNumber+' already exists')
            //         .goAway(5000);
            // }else 
            if(this.nameExists(this.location.name)){
                this.$toasted.global
                    .app_error('Location '+this.location.name+' already exists')
                    .goAway(5000);
            // }else if(this.storeExists(this.location.storeNumber)){
            //     this.$toasted.global
            //         .app_error('Store number '+this.location.storeNumber+' already exists')
            //         .goAway(5000);
            }else{
                this.$refs.editLocationModal.hide();
                this.$emit('load-data', this.location);
            }            
        },
        async loadData() {
            //this.timezoneOptions = await selectListOptionsDataContext.getStoreDropdownData('timezones');
            this.location={
                locationId:0,
                name:"",
                // shortName:"",
                // abbreviation:"",
                // storeNumber:null,
                postalCode:"",
                stateId:null,
                timezone:null
            }
        },
        nameExists(name) {
            return this.locations.some(function(el) {
                return el.name === name;
            }); 
        },
        // storeExists(storeNo) {
        //     return this.locations.some(function(el) {
        //         return el.storeNumber === storeNo;
        //     }); 
        // }        
    }
};
</script>
