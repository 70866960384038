<template>
    <p-form @submit="onSubmit" v-if="showTemplate">
        <div>
            <b-row>                
                <b-col cols="auto" class="ml-auto mb-3">
                    <p-button variant="primary" :is-busy="isBusy" type="submit"
                        >Save Website Hosts
                    </p-button>
                </b-col>
            </b-row>
            <b-row cols="1" cols-lg="2">
                <b-col>
                    <p-card title="Course Materials">
                        <p-select
                            label="Website Host"
                            data-type="webhosts"
                            v-model='selectedCMHost'
                            :filter="host => host.isCourseMaterialHost"
                            add-empty-option
                        />
                        <b-row>
                            <b-col>
                                <p-input
                                    v-if='selectedCMSettings'
                                    v-model="selectedCMSettings.apikey"
                                    label="API Key"
                                    rules="max:255"
                                    :disabled="false"
                                    />
                            </b-col>
                            <b-col cols='auto' class='mt-4'>
                                <p-checkbox
                                    v-if='selectedCMSettings'
                                    label='Is Active'
                                    v-model='selectedCMSettings.isActive'
                                    :disabled="false"
                                    @change='cmActiveFlag'
                                    />
                            </b-col>
                        </b-row>
                        <p-input
                            v-if='selectedCMSettings'
                            v-model="selectedCMSettings.storeId"
                            label="Store ID"
                            rules="max:50"
                            :disabled="false"
                        />
                        <p-input
                            v-if='selectedCMSettings'
                            v-model="selectedCMSettings.hostUrl"
                            label="Host URL"
                            rules="max:255"
                            :disabled="false"
                        />
                    </p-card>
                </b-col>
                <b-col>
                    <p-card title="General Merchandise">
                        <p-select
                            label="Website Host"
                            rules="max:255"
                            data-type="webhosts"
                            v-model='selectedGMHost'
                            :filter="host => host.isGeneralMerchandiseHost"
                            add-empty-option
                        />
                        <b-row>
                            <b-col>
                                <p-input
                                    v-if='selectedGMSettings'
                                    v-model="selectedGMSettings.apikey"
                                    label="API Key"
                                    rules="max:50"
                                    :disabled="false"
                                />
                            </b-col>
                            <b-col cols='auto' class='mt-4'>
                                <p-checkbox
                                    v-if='selectedGMSettings'
                                    label='Is Active'
                                    v-model='selectedGMSettings.isActive'
                                    @change='gmActiveFlag'
                                    :disabled="false"
                                    />
                            </b-col>
                        </b-row>

                        <p-input
                            v-if='selectedGMSettings'
                            v-model="selectedGMSettings.storeId"
                            label="Store ID"
                            rules="max:255"
                            :disabled="false"
                        />
                        <p-input
                            v-if='selectedGMSettings'
                            v-model="selectedGMSettings.hostUrl"
                            label="Host URL"
                            :disabled="false"
                        />
                    </p-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-3 text-center">
                    <p-button variant="primary" :is-busy="isBusy" type="submit">Save Website Hosts</p-button>
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import axios from 'axios';

export default {
    mixins: [NavigationGuard],
    data() {
        return {
            isBusy: false,
            webHosts: [],
            selectedCMHost: null,
            selectedGMHost: null
        };
    },
    async created() {
        await axios
            .get('ECommerceSetup/GetAll')
            .then(response => {
                this.webHosts = response.data;
                let activeCMWebHost = response.data.find(x => x.isCourseMaterialHost && x.webHostSettings.some(y => y.isActive));
                if(activeCMWebHost)
                {
                    this.selectedCMHost = activeCMWebHost.webHostId;
                }

                let activeGMWebHost = response.data.find(x => x.isGeneralMerchandiseHost && x.webHostSettings.some(y => y.isActive));
                if(activeGMWebHost)
                {
                    this.selectedGMHost = activeGMWebHost.webHostId;
                }
                this.dataLoaded();
            });
    },
    computed: {
        selectedCMSettings(){
            if(this.selectedCMHost)
            {
                let host = this.webHosts.find(x => x.webHostId === this.selectedCMHost && x.isCourseMaterialHost);

                let settings = this.getSettings(host, "CM");

                return settings;
            }
            return null;
        },
        selectedGMSettings(){
            if(this.selectedGMHost)
            {
                let host = this.webHosts.find(x => x.webHostId === this.selectedGMHost && x.isGeneralMerchandiseHost);

                let settings = this.getSettings(host, "GM");
                return settings;
            }
            return null;
        },
        showTemplate(){
            return this.webHosts.length > 0;
        }
    },
    methods: {
        gmActiveFlag(event)
        {
            if(!event)
            {
                let hosts = this.webHosts.filter(x => x.isGeneralMerchandiseHost);
                let otherSettings = hosts.flatMap(x => x.webHostSettings).filter(x => x.webHostSettingsId !== this.selectedGMSettings.webHostSettingsId);
                if(otherSettings.length > 0)
                {
                    otherSettings.forEach(setting => { setting.isActive = false; });
                }
            }
        },
        cmActiveFlag(event)
        {
            if(!event)
            {
                let hosts = this.webHosts.filter(x => x.isCourseMaterialHost);
                let otherSettings = hosts.flatMap(x => x.webHostSettings).filter(x => x.webHostSettingsId !== this.selectedCMSettings.webHostSettingsId);
                if(otherSettings.length > 0)
                {
                    otherSettings.forEach(setting => { setting.isActive = false; });
                }
            }
        },
        getSettings(host, type)
        {
            let settings = host.webHostSettings.find(x => x.type == type);

            if(!settings)
            {
                settings = {
                    apikey: null,
                    storeId: null,
                    hostUrl: null,
                    type: type,
                    webHostId: host.webHostId
                };

                host.webHostSettings.push(settings);
            }

            return settings;
        },
        onSubmit() {
            this.isBusy = true;
            axios.post('ECommerceSetup', { webHosts: this.webHosts} ).then(res => {
                this.$toasted.global
                    .app_success(`Website Connection saved successfully.`)
                    .goAway(5000);
                this.websiteConnections = res.data;
                this.dataSaved();
            })
            .finally(() => {
                this.isBusy = false;
            });
        }
    },
    watch: {
        selectedCMSettings: NavigationGuard.$watcher,
        selectedGMSettings: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    }
};
</script>
