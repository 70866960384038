<template>
    <p-modal 
            name="InvoiceDetailReportModal"
            label="Invoice Detail Report"
            ref="InvoiceDetailReportModal"
            size="lg"
            @ok.prevent="handleModalOk"
            >
        <template v-slot:default>
            <p-form ref="InvoiceDetailReportForm">
            <div class="container-fluid content-wrapper">
                        <p-modal-date-range :date-range-options="reportParameters" />
                    <b-row cols="1" cols-lg="2">
                    <b-col>
                        <p-select 
                            v-model="reportParameters.status"
                            rules="required"
                            name="Status"
                            label="Status"
                            mode="lazy"
                            :options="status" />
                    </b-col>    
                    <b-col>
                        <p-advanced-select 
                            v-model="reportParameters.supplierId"  
                            add-empty-option  
                            label="Supplier"
                            data-type="suppliers"
                            text-field="text"
                            value-field="value"
                            mode="lazy"
                        />            
                    </b-col>
                </b-row>
            </div>
            </p-form> 
        </template>
        <template v-slot:modal-footer="{ ok }" >
            <b-row>
                <b-col class=" mb-3 text-center">
                        <p-button  :is-busy="isBusy" variant="outline-primary" @click="ok">OK</p-button>
                    </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>

import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    props: {},
    mixins: [],
    data() {
        return {
            isBusy: false,
            reportParameters: {
                fromDate: null,
                toDate: null,
                status: '',
                supplierId: ''
            },
            status: [
                { text: 'All', value: 'All' },
                { text: 'Open', value: 'Open' },
                { text: 'Paid', value: 'Paid' },
            ],
        };
    },
     components: {
    },
    methods: {
        show() {
            this.$refs.InvoiceDetailReportModal.show();
        },
        handleModalOk: async function() {
            if (!(await this.$refs.InvoiceDetailReportForm.validate())) {
                return;
            }
            this.isBusy = true;
            await axios
                .post(
                    '/Invoices/exportInvoiceDetailCsv',
                    this.reportParameters,
                    {
                        responseType: 'blob'
                    }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Export_Invoice_Detail.csv',
                        'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Invoice Detailed report generated successfully.`
                        )
                        .goAway(5000);
                    this.$refs.InvoiceDetailReportModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};

</script>

<style scoped></style>
