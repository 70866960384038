<template>
    <SearchScreen
        ref="searchScreen"
        title="Shipping Codes"
        api-destination="shippingcodes"
        edit-key="shippingCodeId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :use-is-active-checkbox="false"
        add-new-button
        :refreshSearchResults="refreshSearchResults"
    >
        <template v-slot:actionButtons>
            <EditShippingCode
                :shippingCodeId="'new'"
                @result-updated="refreshTable"
            />
        </template>

        <template v-slot:cell(edit)="{ item }">
            <EditShippingCode
                :shippingCodeId="item.shippingCodeId"
                :details="item"
                @result-updated="refreshTable"
            />
        </template>

    </SearchScreen>
</template>

<script>
//import axios from 'axios';
import EditShippingCode from '@/views/ShippingCodes/Edit.vue';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { isActiveFormatter } from '@/components/Common/Formatters.js';

export default {
    mixins: [SearchStorage],
    components: {
        SearchScreen, EditShippingCode
    },
    data() {
        return {
            filterName: 'ShippingCodesFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All', 'Code Name', 'Code Description'],
            fields: [
                {
                    key: 'shippingCodeName',
                    sortable: true,
                    label: 'Code Name'
                },
                {
                    key: 'description',
                    sortable: true,
                    label: 'Code Description'
                },
                {
                    key: 'shippingMethod',
                    sortable: true,
                    label: 'Shipping Method'
                },
                {
                    key: 'isActive',
                    sortable: true,
                    label: 'Code Status',
                    formatter: isActiveFormatter
                }
            ],
            refreshSearchResults: false
        };
    },
    methods: {
        refreshTable() {
            this.$refs?.searchScreen.refreshSearchResults();
        }
    }
};
</script>
<style scoped></style>
