<template>
    <div class="container-fluid content-wrapper">
        <b-row>
            <b-col cols="12">
                <div class="content-heading">
                    {{ isNew ? 'Add' : 'Edit' }} Auto Gen Record
                </div>
            </b-col>
        </b-row>
        <p-form @submit="onSubmit">
            <b-row>
                <b-col class="text-left">
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        class="mr-2"
                        type="button"
                        @click="autoGeneratePO()"
                        >Generate</p-button
                    >
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        class="mr-2"
                        type="button"
                        @click="enforceSave()"
                        >Create Report</p-button
                    >
                </b-col>
                <b-col class="text-right">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        type="submit"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <p-card class="mt-2">
                <b-row class="mt-2">
                    <b-col cols="12" sm="12" xl="7">
                        <p-input
                            name="description"
                            label="Description"
                            v-model="autoGenPo.description"
                            rules="required|max:255"
                            @change="disableAutoGenerate()"
                        />
                    </b-col>
                    <b-col cols="12" sm="12" xl="5">
                        <b-row cols="1" cols-xl="2">
                            <b-col>
                                <p-select
                                    dataType="shippingProviders"
                                    label="Shipping Method"
                                    v-model="autoGenPo.shippingProviderId"
                                    @change="disableAutoGenerate()"
                                />
                            </b-col>
                            <b-col>
                                <p-select
                                    dataType="shippingLevels"
                                    label="Shipping Level"
                                    v-model="autoGenPo.shippingLevelId"
                                    @change="disableAutoGenerate()"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="12" sm="12" xl="7">
                        <div class="overlay-dcc"></div>
                        <p-card title="DCC(s)">
                            <div>
                                <p-input placeholder="Search" type="search" />
                            </div>

                            <div>
                                <DCCsSelector
                                    class="container-fluid"
                                    :include-search-filter="false"
                                    @selection-change="selectedDCC = $event"
                                />
                            </div>
                        </p-card>
                    </b-col>
                    <b-col cols="12" sm="12" xl="5">
                        <b-row class="mt-2" cols="2" cols-lg="2">
                            <b-col>
                                <p-checkbox
                                    label="Use Order Decisions"
                                    v-model="autoGenPo.useOrderDecisions"
                                    @change="disableAutoGenerate()"
                                >
                                </p-checkbox>
                            </b-col>
                            <b-col>
                                <p-checkbox
                                    label="Include Non-Term Items"
                                    :disabled="true"
                                    v-model="autoGenPo.includeNonTermItems"
                                    @change="disableAutoGenerate()"
                                >
                                </p-checkbox>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2" cols="1" cols-sm="2">
                            <b-col>
                                <p-datepicker
                                    label="Ship By Date"
                                    v-model="autoGenPo.shipByDate"
                                    @change="disableAutoGenerate()"
                                >
                                </p-datepicker>
                            </b-col>
                            <b-col>
                                <p-datepicker
                                    label="Arrival Date"
                                    v-model="autoGenPo.arrivalDate"
                                    @change="disableAutoGenerate()"
                                >
                                </p-datepicker>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2" cols="1">
                            <b-col>
                                <p-datepicker
                                    label="Cancel After Date"
                                    v-model="autoGenPo.cancelAfterDate"
                                    @change="disableAutoGenerate()"
                                >
                                </p-datepicker>
                            </b-col>
                        </b-row>

                        <b-row class="mt-2" cols="1">
                            <b-col>
                                <p-text-area
                                    label="Comment"
                                    rules="max:500"
                                    rows="3"
                                    v-model="autoGenPo.comment"
                                    @change="disableAutoGenerate()"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="mt-2" cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-checkbox
                            label="Add items to existing POs"
                            v-model="autoGenPo.addItemsToExistingPos"
                            @change="disableAutoGenerate()"
                        />
                    </b-col>

                    <b-col>
                        <p-checkbox
                            label="Use only titles not already on want list"
                            v-model="autoGenPo.useTitlesNotAlreadyOnWantlist"
                            @change="disableAutoGenerate()"
                        >
                        </p-checkbox>
                    </b-col>
                </b-row>
                <b-row class="mt-2" cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-checkbox
                            label="Use term for subtract order short"
                            v-model="autoGenPo.useTermForSubtractOrderShort"
                            :checked="useTermForSubtractOrderShortChecked()"
                            @change="disableAutoGenerate()"
                        >
                        </p-checkbox>
                    </b-col>

                    <b-col>
                        <p-checkbox
                            label="Use term for buyback to subtract"
                            v-model="autoGenPo.useTermForBuyBackToSubtract"
                            :checked="useTermforBuyBackToSubtractChecked()"
                            @change="disableAutoGenerate()"
                        >
                        </p-checkbox>
                    </b-col>
                    <b-col>
                        <p-checkbox
                            label="Subtract order short amount"
                            v-model="autoGenPo.subtractOrderShortAmount"
                            :disabled="autoGenPo.useTermForSubtractOrderShort"
                            @change="disableAutoGenerate()"
                        >
                        </p-checkbox>
                    </b-col>

                    <b-col>
                        <p-select
                            label="Buyback to subtract"
                            v-model="autoGenPo.buyBackToSubtract"
                            data-type="buybackToSubtractOptions"
                            text-field="value"
                            :disabled="autoGenPo.useTermForBuyBackToSubtract"
                            @change="disableAutoGenerate()"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="2" class="mt-2">
                        <p-button
                            variant="outline-primary"
                            class="ml-2"
                            :disabled="true"
                            >Remove</p-button
                        >
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <p-table
                            :items="autoGenPo.purchaseOrderAutoGenTermLocations"
                            :fields="fields"
                            :enableRowSelection="false"
                        >
                            <template v-slot:cell(Location)="{ value, item }">
                                <div>{{ location.name }}</div>
                            </template>

                            <template v-slot:cell(Term)="{ value, item }">
                                <p-advanced-select
                                    shortDropdown
                                    dataType="terms"
                                    name="Term"
                                    v-model="item.termId"
                                    :value="item.termId"
                                    rules="required"
                                    compact-format
                                    :disabled="termDisabled"
                                />
                            </template>
                            <template v-slot:cell(UseActive)="{ value, item }">
                                <p-checkbox
                                    v-model="item.active"
                                    @change="useActiveChecked()"
                                />
                            </template>
                            <template v-slot:cell(Campus)="{ campusId, item }">
                                <p-select
                                    :options="activeTermCampuses"
                                    name="Campus"
                                    text-field="name"
                                    compact-format
                                    v-model="item.campusId"
                                    :disabled="!item.active"
                                    :rules="checkRequired(item.active)"
                                    @change="campusChanged(item.campusId)"
                                />
                            </template>
                            <template v-slot:cell(OTB)="{ value, item }">
                                <p-number
                                    v-model="item.otbPercent"
                                    compact-format
                                />
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
            <p-modal
                @ok="purchaseOrderAutoGenReport"
                name="PurchaseOrderAutoGenReportModal"
                label="Autogen Proposed Orders Report"
                size="md"
            >
                <template v-slot:default>
                    <div class="container-fluid content-wrapper">
                        <b-row cols="1" cols-md="1">
                            <b-col>
                                <p-select
                                    label="PO Sort Order"
                                    :options="poSortOrderOptions"
                                    text-field="value"
                                    v-model="reportParameters.poSortOrder"
                                />
                                <p-select
                                    label="Product Sort Order"
                                    :options="productSortOrderOptions"
                                    text-field="value"
                                    v-model="reportParameters.productSortOrder"
                                />
                            </b-col>
                        </b-row>
                    </div>
                </template>
            </p-modal>
        </p-form>
    </div>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import DCCsSelector from '@/components/DCCsSelector.vue';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            enableAutoGenButton: false,
            isBusy: false,
            selectedDCC: [],
            termDisabled: false,
            location: {
                name: null
            },
            activeTermCampuses: [],
            autoGenPo: {
                purchaseOrderAutoGenId: 0,
                shippingProviderId: null,
                shippingLevelId: null,
                description: null,
                useOrderDecisions: true,
                includeNonTermItems: false,
                shipByDate: null,
                arrivalDate: null,
                cancelAfterDate: null,
                comment: null,
                addItemsToExistingPos: false,
                useTitlesNotAlreadyOnWantlist: false,
                useTermForSubtractOrderShort: false,
                useTermForBuyBackToSubtract: false,
                subtractOrderShortAmount: false,
                buyBackToSubtract: null,
                lastGenerateDate: null,
                purchaseOrderAutoGenDccs: [
                    {
                        dccId: 0
                    }
                ],
                purchaseOrderAutoGenTermLocations: [
                    {
                        termId: 0,
                        locationId: 0,
                        campusId: 0,
                        active: false,
                        otbPercent: 100
                    }
                ]
            },
            fields: [
                {
                    key: 'Location',
                    label: 'Location'
                },
                {
                    key: 'Term',
                    label: 'Term'
                },
                {
                    key: 'UseActive',
                    label: 'Use Active'
                },
                {
                    key: 'Campus',
                    label: 'Campus'
                },
                {
                    key: 'OTB',
                    label: 'OTB %'
                }
            ],
            reportParameters: {
                poSortOrder: 'Supplier',
                productSortOrder: 'Description',
                purchaseOrderAutoGenId: this.id
            },
            poSortOrderOptions: [{ value: 'Supplier' }, { value: 'DCC' }],
            productSortOrderOptions: [
                { value: 'Description' },
                { value: 'ISBN' },
                { value: 'Binding/Type' },
                { value: 'Edition' },
                { value: 'Copyright' }
            ]
        };
    },
    watch: {
        autoGenPo: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        }
    },
    methods: {
        async onSubmit() {
            if (
                !this.autoGenPo.useOrderDecisions &&
                !this.autoGenPo.includeNonTermItems
            ) {
                this.$toasted.global
                    .app_error('Please select Use order decision')
                    .goAway(5000);
            } else {
                this.isBusy = true;
                axios
                    .post('PurchaseOrderAutoGen', this.autoGenPo)
                    .then(resp => {
                        if (resp) {
                            if (this.isNew) {
                                this.$router.push(
                                    '/purchaseorderautogen/' +
                                        resp.data.purchaseOrderAutoGenId
                                );
                            }
                            this.dataSaved();
                            this.$toasted.global
                                .app_success(
                                    'Auto Gen ' +
                                        this.autoGenPo.description +
                                        ' is saved successfully'
                                )
                                .goAway(5000);
                            this.enableAutoGenButton = true;
                        }
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        },
        async loadData() {
            if (this.isNew) return;

            let promise = axios.get('/purchaseorderautogen/', {
                params: { id: this.id }
            });

            promise.then(resp => {
                this.autoGenPo = resp.data;
                this.enableAutoGenButton = true;
                this.dataLoaded();
            });
        },
        useTermForSubtractOrderShortChecked() {
            if (this.autoGenPo.useTermForSubtractOrderShort)
                this.autoGenPo.subtractOrderShortAmount = false;
        },
        useTermforBuyBackToSubtractChecked() {
            if (this.autoGenPo.useTermForBuyBackToSubtract)
                this.autoGenPo.buyBackToSubtract = null;
        },
        useActiveChecked() {
            if (this.autoGenPo.purchaseOrderAutoGenTermLocations[0].active) {
                this.termDisabled = false;
                this.autoGenPo.purchaseOrderAutoGenTermLocations[0].campusId = null;
            }
        },
        autoGeneratePO() {
            if (!this.enableAutoGenButton) {
                this.$toasted.global
                    .app_error(
                        'Please save the record before generating purchase order'
                    )
                    .goAway(5000);
            } else {
                this.isBusy = true;
                axios
                    .post('PurchaseOrderAutoGen/PurchaseOrderAutoGenerate', {
                        purchaseOrderAutoGenId: this.autoGenPo
                            .purchaseOrderAutoGenId
                    })
                    .then(resp => {
                        if (resp) {
                            this.$toasted.global
                                .app_success(
                                    'Purchase Orders Auto generated Successfully'
                                )
                                .goAway(5000);
                        }
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        },
        disableAutoGenerate() {
            this.enableAutoGenButton = false;
        },
        checkRequired(active) {
            if (active) return 'required';
            else return '';
        },
        campusChanged(campusId) {
            if (!campusId) return;
            axios
                .get('Campuses/recentterm', {
                    params: {
                        id: campusId
                    }
                })
                .then(resp => {
                    if (resp.data != '') {
                        this.autoGenPo.purchaseOrderAutoGenTermLocations[0].termId =
                            resp.data.termId;
                        this.termDisabled = true;
                    }
                });
        },
        enforceSave() {
            if (!this.enableAutoGenButton) {
                this.$toasted.global
                    .app_error(
                        'Please save the record before creating the report'
                    )
                    .goAway(5000);
            } else {
                this.$bvModal.show('PurchaseOrderAutoGenReportModal');
            }
        },
        purchaseOrderAutoGenReport() {
            let apiUrl = `/purchaseorderautogen/export?id=${this.id}&poSortOrder=${this.reportParameters.poSortOrder}&productSortOrder=${this.reportParameters.productSortOrder}`;
            this.isBusy = true;
            axios
                .get(apiUrl, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Auto Generated Purchase Orders',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            'Auto Generated Purchase Orders Report generated successfully.'
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                    this.reportParameters.purchaseOrderAutoGenId = this.id;
                    this.reportParameters.poSortOrder = 'Supplier';
                    this.reportParameters.productSortOrder = 'Description';
                });
        }
    },
    mounted: async function() {
        axios.get('locations/default').then(resp => {
            this.location = resp.data;
            this.autoGenPo.purchaseOrderAutoGenTermLocations[0].locationId = this.location.locationId;
            this.loadData();
        });
        axios.get('Campuses/getcampuslist').then(resp => {
            this.activeTermCampuses = resp.data;
        });
    },
    components: {
        DCCsSelector
    }
};
</script>
<style scoped>
>>> .dropdown-menu {
    max-height: 110px !important;
}
>>> .table-responsive {
    min-height: 120px;
}
.overlay-dcc {
    width: 96%;
    height: 94%;
    position: absolute;
    background-color: #ddd;
    z-index: 11;
    opacity: 0.3;
}
</style>
