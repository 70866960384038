import { mapMutations } from 'vuex';

export default {
    name: 'SidebarToggle',
    data() {
        return {};
    },
    methods: {
        /**
         * Triggers a window resize event when clicked
         * for plugins that needs to be redrawed
         */
        resize: () => {
            // all IE friendly dispatchEvent
            var evt = document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
            // modern dispatchEvent way
            // window.dispatchEvent(new Event('resize'));
        },
        ...mapMutations(['toggleSetting']),
        toggleOffsidebar() {
            this.toggleSetting('offsidebarOpen');
        },
        toggleOffcanvas() {
            this.toggleSetting('asideToggled');
        },
        toggleAsideCollapsed() {
            this.toggleSetting('isCollapsed');
            this.resize();
        },
        toggleNavSearch() {
            this.navSearchOpen = !this.navSearchOpen;
        }
    }
};
