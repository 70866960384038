<template>
  <ContentWrapper>
    <b-row class="mb-3">
      <b-col>p-card</b-col>
      <b-col>Bordered bootstrap card.</b-col>
      <b-col></b-col>
    </b-row>

    <hr/>

    <b-row class="mb-3">
      <b-col>p-form</b-col>
      <b-col>Enables validation.</b-col>
      <b-col><b>@submit</b> event after successful validation.</b-col>
    </b-row>

    <hr/>

    <b-row class="mb-3">
      <b-col>p-modal</b-col>
      <b-col>Bootstrap modal.</b-col>
      <b-col></b-col>
    </b-row>

    <hr/>

    <b-row class="mb-3">
      <b-col>SearchScreen</b-col>
      <b-col>Renders an entire search screen.</b-col>
      <b-col>
        <b>Slots</b>
        <ul>
          <li>search: top of the screen search form.</li>
          <li>filters: right 1/4 of the screen filters.</li>
        </ul>  
      </b-col>
    </b-row>
  </ContentWrapper>
</template>

<script>
export default {
  name: "Layout"
}
</script>