<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="lg"
        :label="pageTitle"
        ref="editBuyerGroupModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div class="container-fluid content-wrapper" v-if="group">
                    <b-row cols="1" >
                        <b-col>
                            <p-input
                                label="Buyer's Group Name"
                                v-model="group.description"
                                rules="required|max:40"
                            />
                        </b-col>
                        <b-col>
                            <p-dragable
                                        numbered
                                        v-model="group.buybackBuyerGroupLocations"
                                        :label="
                                            group.buybackBuyerGroupLocations.length > 1
                                                ? 'Active Locations - Drag priority location to top'
                                                : 'Active Locations'
                                        "
                                        :additional-options-label="
                                            'Inactive'
                                        "
                                        :additional-options="
                                            locationOptions
                                        "
                                        top-message="(Top priority)"
                                    ></p-dragable>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>

        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
               <b-col class="mb-3 text-center">
                    <p-button :is-busy="isBusy" variant="primary" @click="ok" class="ml-2">Save</p-button>
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
    mixins: [StoreListManager],
    props: {
        groupId: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,
            group: null,
            locationOptions: [],
            selectListOptions: {
                locations: []
            }
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    components: {},
    computed: {
        isNew() {
            return this.groupId === 'new';
        },
        pageTitle() {
            return this.isNew
                ? 'Add Buyback Buyer\'s Group'
                : 'Edit Buyback Buyer\'s Group';
        },
        buttonLabel() {
            return this.isNew ? 'Create Buyer\'s Group' : 'Edit';
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.isBusy = true;
            axios.post('buyback/buyergroup', this.group).then(() => {
                this.$emit('data-saved');
                this.$toasted.global
                    .app_success(
                        `Buyer Group '${this.group.description}' saved successfully.`
                    )
                    .goAway(5000);
                this.$refs.editBuyerGroupModal.hide();
            })
            .finally(() => {
                this.isBusy = false;
            });
        },
        loadData() {
            this.group = null;
            let promise = this.isNew
                ? axios.get('buyback/newgroup')
                : axios.get('buyback/buyergroup', {
                      params: { id: this.groupId }
                  });

            promise.then(resp => {
                this.group = resp.data;
                this.locationOptions = this.selectListOptions.locations.filter(
                    x => !this.group.buybackBuyerGroupLocations.some(y => y.locationId === x.value)
                )
                .map(x => ({ name : x.text, locationId : x.value}))
            });
        }
    }
};
</script>
