<template>
    <p-modal
        name="productSalesModal"
        label="Product Sales"
        ref="productSales"
        size="lg"
        @ok.prevent="handleModalOk"
    >
        <template v-slot:default>
            <p-form ref="productSalesForm">
                <div class="container-fluid content-wrapper">
                    <b-row>
                        <b-col>
                            <p>Item Sales</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-card title="Date Range">
                                <p-date-range :date-range-options="reportParameters" />
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card class='mb-2 pl-2 py-2' no-body>
                                <label class='text-sm' v-if='dccSelectorHelpText'>{{dccSelectorHelpText}}</label>
                                <DCCsSelector class='container-fluid' @selection-change='handleDccSelectionChange($event)' />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
               <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="cancel"
                        >Cancel</p-button
                    >
                    <b-overlay :show="isBusy"
                                rounded
                                opacity="0.5"
                                spinner-small
                                spinner-variant="primary"
                                class="d-inline-block">
                        <b-dropdown text="Export" 
                                    variant="outline-primary">
                            <b-dropdown-item @click="ok">CSV</b-dropdown-item>
                        </b-dropdown>
                    </b-overlay>
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import DCCsSelector from '@/components/DCCsSelector.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';


export default {
    data() {
        return {
            isBusy: false,
            reportParameters: {
                dccs: [],
                startDate: null,
                endDate: null,
                dateOption: 0,
                locationId: null,
                sortOrder: 'dcc'
            }
        };
    },
    computed: {
        dccSelectorHelpText() {
            return this.reportParameters.dccs.length === 0
                ? 'ALL DCCs will be included in the report, you may select individual DCCs below.'
                : '';
        }
    },
    methods: {
        async show() {
            this.reportParameters.dccs = [];
            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.$refs.productSales.show();
        },
        async handleModalOk() {
            this.isBusy = true;
            await axios
                .post(
                    'sales/exportproductsalescsv',
                    this.reportParameters,
                    {
                        responseType: 'blob'
                    }
                ).then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Product Sales.csv',
                        'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Product Sales report generated successfully.`
                        ).goAway(5000);
                        this.$refs.productSales.hide();
                }).finally(() => {
                    this.isBusy = false;
                });
        },
        handleDccSelectionChange($event){
            this.reportParameters.dccs = $event.map(x => x.dccId)
        }
    },
    components: {
        DCCsSelector
    }
}
</script>
