<template>
    <SearchScreen
        title="Buyers Guide Search"
        api-destination="productquicksearch" 
        search-destination="buyersguidesearch"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        :per-page-options="[
                { value: 10, text: 'Show 10' },
                { value: 25, text: 'Show 25' },
                { value: 50, text: 'Show 50' },
                { value: 100, text: 'Show 100' }
            ]"
        disableInitialSearch
    > 
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue'; 
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { currencyFormatter } from '@/components/Common/Formatters.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'BuyersGuideFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'author',
                searchBy: 'All'
            },
            filterBy: ['All','Author','Title','ISBN','Book Key','Edition','Imprint','Copyright'],
            fields: [
                {
                    key: 'author',                    
                    sortable:true,
                    label: 'Author'
                },
                {
                    key: 'title',                  
                    sortable:true,
                    label: 'Title' 
                },
                {
                    key: 'imprint',
                    label: 'Imprint' 
                },
                {
                    key: 'edition',
                    label: 'Edition' 
                }, 
                {
                    key: 'copyright',
                    label: 'Copyright' 
                }, 
                {
                    key: 'isbn',
                    label: 'ISBN' 
                }, 
                {
                    key: 'retail',
                    label: 'Price',
                    formatter: currencyFormatter
                }, 
                {
                    key: 'binding',
                    label: 'Binding' 
                }, 
                {
                    key: 'buyPrice',
                    label: 'Buyback Price',
                    formatter: currencyFormatter
                }, 
                {
                    key: 'comments',
                    label: 'Comments' 
                }, 
            ]
        };
    },
    components: {
        SearchScreen 
    }
};
</script>
