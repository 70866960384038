<template>
    <SearchScreen
        title="Campuses"
        api-destination="campuses"
        edit-key="campusId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        add-new-button
        @isActiveChanged="updateIsActiveStatus"
    >
    </SearchScreen>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'CampusesFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All', 'Name', 'Description', 'Abbreviation'],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },
                {
                    key: 'abbreviation',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: true
                }
            ]
        };
    },
    methods: {
        updateIsActiveStatus(campus) {
            axios.post('campuses', campus).then(() => {
                var msg = `${campus.name} ${
                    campus.isActive ? 'activated' : 'de-activated'
                } successfully`;
                this.$toasted.global.app_success(msg).goAway(5000);
            });
        }
    },
    components: {
        SearchScreen
    }
};
</script>
<style scoped></style>
