<template>
    <p-form @submit='onSubmit' ref="form">
        <div class='container-fluid content-wrapper' v-if='supplier'>
            <b-row class='content-heading'>
                <b-col>
                    <div>{{isNew ? 'Add' : 'Edit'}} Supplier</div>
                </b-col>
                <b-col class='text-right'>
                    <b-button
                        v-if='!isNew'
                        class='mr-2'
                        hidden
                        variant='danger'
                        @click='deleteSupplier'
                    >Delete</b-button>
                    <p-button type='submit' variant='primary' :isBusy='isBusy'>Save</p-button>
                </b-col>
            </b-row>
            <p-card>
                <b-row>
                    <b-col cols="12" sm="6" xl='4'>
                        <p-input
                            label='Name'
                            v-model='supplier.name'
                            rules='required|max:50'
                            @blur='getSupplierCode'
                        />
                    </b-col>
                    <b-col cols="6" sm="3" xl='2'>
                        <p-input label='Alias' v-model='supplier.alias' rules='max:10' />
                    </b-col>
                    <b-col cols="6" sm="3" xl='2'>
                        <p-input label='Code'
                            @input="inputDebounce"
                            v-model='supplier.code'
                            :disabled='!isNew'
                            :rules='{"required": true, max: {length: 10}, isUnique: {isUniqueValue: !codeExists, message: "Code already in use."}}' />
                    </b-col>
                </b-row>
            </p-card>
            <p-card>
                <h4>
                    Internal Supplier Notes
                    <span
                        class="text-normal text-sm" v-if="supplier.internalSupplierNote.noteId"
                    >
                        Last updated
                        {{
                            supplier.internalSupplierNote.modifiedDate ==
                            null
                                ? 'unknown'
                                : datetimeToLocalTimeFormatter(
                                    supplier.internalSupplierNote
                                            .modifiedDate
                                    ).replace(', ', ' at ')
                        }}
                        by
                        {{
                            supplier.internalSupplierNote.userId == null
                                ? 'Unknown'
                                : supplier.internalSupplierNote.user
                                        .displayName
                        }}.
                    </span>
                </h4>    
                <p-text-area
                    :shortExpandableFormat="true"
                    rules="max:4000"
                    name="notes"
                    :placeholder="''"
                    v-model="supplier.internalSupplierNote.text"
                />
            </p-card>
            <b-tabs justified>
                <b-tab title='Address'>
                    <b-row cols="1" cols-md="2">
                        <b-col name="orderingAddressSection">
                            <span class="text-center"
                            ><h5>Ordering</h5></span>
                            <p-address
                                    :fullWidth="true"
                                    v-model='supplier.orderingAddress.address'
                                    ></p-address>
                            <b-row class='ml-2'>
                                <b-col cols='6'>
                                    <p-phone-number
                                        label='Phone Number'
                                        v-model='supplier.orderingAddress.phoneNumber'
                                        rules='max:20'
                                    />
                                </b-col>
                                <b-col cols='6'>
                                    <p-phone-number
                                        label='Fax Number'
                                        v-model='supplier.orderingAddress.faxNumber'
                                        rules='max:20'
                                    />
                                </b-col>
                            </b-row>
                            <b-row class='ml-2 mt-4'>
                                <b-col cols='6'>
                                    <p-input label='URL' v-model='supplier.website' rules='max:255' />
                                </b-col>
                                <b-col cols='6'>
                                    <p-input label='Email' v-model='supplier.email' rules='email|max:254' />
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col name="returnAddressSection">
                            <span class="text-center"
                            ><h5>Return</h5></span>
                            <p-address
                                    :fullWidth="true"
                                    v-model='supplier.returnAddress.address'
                                    :same-as-address="
                                        supplier.orderingAddress.address
                                    "
                                    same-as-address-name="Ordering"
                                    :linker-address="
                                        supplier.returnAddress
                                    "
                                    :same-as-linker-address="
                                        supplier.orderingAddress
                                    "
                                    use-same-as-when-empty
                                    @syncLinkerAddress="($event) => {
                                        supplier.returnAddress.phoneNumber = $event.phoneNumber;
                                        supplier.returnAddress.faxNumber = $event.faxNumber;
                                        }"
                                    ></p-address>
                            <b-row class='ml-2'>
                                <b-col cols='6'>
                                    <p-phone-number
                                        label='Phone Number'
                                        :disabled="supplier.returnAddress.address.useSameAs"
                                        v-model='supplier.returnAddress.phoneNumber'
                                        rules='max:20'
                                    />
                                </b-col>
                                <b-col cols='6'>
                                    <p-phone-number
                                        label='Fax Number'
                                        :disabled="supplier.returnAddress.address.useSameAs"
                                        v-model='supplier.returnAddress.faxNumber'
                                        rules='max:20'
                                    />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-tab>
                <b-tab title='Parameters'>
                   <p-card>
                       <b-row cols="1" col-sm="2" cols-xl="4">
                        <b-col>
                            <p-input
                                label='EasyLink'
                                v-model='supplier.orderingAddress.easyLink'
                                rules='max:20'
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label='Pubnet SAN'
                                v-model='supplier.supplierParameter.pubnetSan'
                                rules='numeric|max:7'
                            />
                        </b-col>
                       </b-row>
                   </p-card>

                    <p-card title="Location Settings">
                        <b-row cols="2" cols-xl="4" >
                            <b-col>
                                <p-input
                                    label='Account Number with Supplier'
                                    v-model='supplier.supplierLocation.accountNumber'
                                    rules='max:15'
                                />
                            </b-col>
                            <b-col>
                                <p-number
                                    label='Free Shipping Order Value ($)'
                                    v-model.number='supplier.supplierLocation.freeShippingOrderValue'
                                    input-type='money'
                                    left-align
                                />
                            </b-col>
                            <b-col>
                                <p-select
                                    data-type='shippingProviders'
                                    v-model='supplier.supplierLocation.shippingProviderId'
                                    label='Shipping Provider'
                                />
                            </b-col>
                            <b-col>
                                <p-select
                                    data-type='shippingLevels'
                                    v-model='supplier.supplierLocation.shippingLevelId'
                                    label='Shipping Level'
                                />
                            </b-col>
                        </b-row>
                    </p-card>

                    <p-card title="Return Information">
                        <b-row cols="1" cols-sm="2" cols-xl="4">
                            <b-col>
                                <p-select
                                    label="Policy"
                                    data-type="supplierReturnPolicies"
                                    v-model="
                                        supplier.supplierParameter
                                            .returnPolicyId
                                    "
                                />
                            </b-col>
                            <b-col class="d-xl-none"></b-col>
                            <b-col>
                                <p-number
                                    label="Min Days"
                                    rules="min_value:0"
                                    input-type="integer"
                                    v-model="
                                        supplier.supplierParameter.minReturnTime
                                    "
                            /></b-col>
                            <b-col>
                                <p-number
                                    label="Max Days"
                                    rules="min_value:0"
                                    input-type="integer"
                                    v-model="
                                        supplier.supplierParameter.maxReturnTime
                                    "
                            /></b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <p-text-area
                                    label="Return Comment"
                                    v-model="supplier.supplierParameter.comment"
                                    rules="max:255"
                                />
                            </b-col>
                        </b-row>
                    </p-card>
                </b-tab>
                <b-tab title='Discounts' id='discounts'>
                    <b-col>
                        <p-card>
                            <b-row>
                                <b-col>
                                    <label>Shipping Discount Tiers</label>
                                </b-col>
                                <b-col class='text-right'>
                                    <b-button
                                        @click='showAddDiscountCodeModal'
                                        class='btn btn-primary mb-1'
                                    >Add Discount Code</b-button>
                                    <b-button
                                        @click='addDiscountTier'
                                        class='btn btn-primary ml-2 mb-1'
                                    >Add Discount Tier</b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-table
                                        :items='supplier.shippingDiscounts'
                                        :fields='discountFields'
                                        :perPage='10'
                                        primary-key="uniqueId"
                                        :enable-row-selection='false'
                                        ref='discountTable'
                                    >
                                        <template v-slot:cell(vendorDiscountCodeId)='{ value, item, index }'>
                                            <p-select
                                                name='Code'
                                                :options='supplier.shippingDiscountCodes'
                                                v-model='item.vendorDiscountCodeId'
                                                rules='required'
                                                style='min-width: 150px'
                                            />
                                        </template>
                                        <template v-slot:cell(low)='{ value, item }'>
                                            <p-number
                                                name='Low'
                                                :inputType='getInputType(item.isDollar)'
                                                rules='required'
                                                v-model.number='item.lowQty'
                                                :vid='`itemlowQty${item.uniqueId}`'
                                            />
                                        </template>
                                        <template v-slot:cell(high)='{ value, item }'>
                                            <p-number
                                                name='High'
                                                :inputType='getInputType(item.isDollar)'
                                                :rules='`required|qtyGreater:@itemlowQty${item.uniqueId}`'
                                                v-model.number='item.highQty'
                                            />
                                        </template>
                                        <template v-slot:cell(percent)='{ value, item }'>
                                            <p-number
                                                name='Percent Off'
                                                inputType='percent'
                                                rules='required|min_value:0|max_value:100'
                                                v-model.number='item.discount'
                                            />
                                        </template>
                                        <template v-slot:cell(isDollar)='{ value, item }'>
                                            <p-select
                                                name='isDollar'
                                                :options='discountTypeOptions'
                                                v-model='item.isDollar'
                                            />
                                        </template>
                                        <template v-slot:cell(delete)='{value, item}'>
                                            <b-button class='p-1 ml-1 short-button'>
                                                <BIconTrash
                                                    icon='trash'
                                                    @click='deleteDiscountTier(item)'
                                                ></BIconTrash>
                                            </b-button>
                                        </template>
                                    </p-table>
                                </b-col>
                            </b-row>
                        </p-card>
                    </b-col>
                </b-tab>
            </b-tabs>
            <b-row>
                <b-col class='mt-3 text-center'>
                    <p-button variant='primary' type='submit' :isBusy='isBusy'>Save</p-button>
                </b-col>
            </b-row>
            <p-modal
                name='addDiscountCode'
                label='Add Discount Code'
                size='sm'
                @ok='addDiscountCode'
                @shown='() => this.$refs.focusThis.focus()'
            >
                <p-input label='Discount Code' v-model='newCode' ref='focusThis' rules="max:80|required" />
            </p-modal>
        </div>
    </p-form>
</template>

<script>
    import axios from 'axios';
    import NavigationGuard from '@/components/mixins/NavigationGuard.js';
    import uniqueid from 'lodash.uniqueid';
    import debounce from 'lodash/debounce';
    import EditPage from '@/components/mixins/EditPage';
    import {
    datetimeToLocalTimeFormatter
    } from '@/components/Common/Formatters.js';

    export default {
        mixins: [NavigationGuard, EditPage],
        data() {
            return {
                supplier: null,
                newCode: null,
                codeExists: false,
                isBusy: false,
                discountFields: [
                    {
                        key: 'vendorDiscountCodeId',
                        label: 'Code',
                        formatter: (value, key, item) => {
                            return item.discountCode;
                        },
                        sortByFormatted: true,
                        sortable: true
                    },
                    {
                        key: 'low',
                        label: 'Low *',
                        thClass: "text-center",
                        sortable: false
                    },
                    {
                        key: 'high',
                        label: 'High *',
                        thClass: "text-center",
                        sortable: false
                    },
                    {
                        key: 'percent',
                        label: 'Percent Off *',
                        thClass: "text-center",
                        sortable: false
                    },
                    {
                        key: 'isDollar',
                        label: 'Type',
                        sortable: true
                    },
                    {
                        key: 'delete',
                        label: ''
                    }
                ],
                discountTypeOptions: [
                    { value: false, text: 'Quantity' },
                    { value: true, text: 'Dollar' }
                ]
            };
        },
        watch: {
            supplier: NavigationGuard.$watcher
        },
        methods: {
            onSubmit() {
                let hasOverLap = this.supplier.shippingDiscounts.some((d) => {
                    return this.supplier.shippingDiscounts.some(function (x) {
                        if(x.vendorDiscountCodeId != d.vendorDiscountCodeId ||  x == d){
                            return false;
                        }
                        return (x.lowQty <= d.highQty && x.highQty > d.highQty)
                            || (x.lowQty < d.lowQty && x.highQty >= d.lowQty)
                    });
                });

                if (hasOverLap) {
                    this.$toasted.global.app_error('Shipping discount codes overlap.');
                    return;
                }

                this.isBusy = true;
                axios.post('suppliers', this.supplier).then((response) => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push("/suppliers/" + response.data);
                    }
                    this.$toasted.global.app_success(`Supplier '${this.supplier.name}' saved successfully.`).goAway(5000);
                }).finally(() => {
                    this.isBusy = false;
                    this.loadData();
                });
            },
            datetimeToLocalTimeFormatter: datetimeToLocalTimeFormatter,
            getInputType(value) {
                return value ? 'money' : 'integer';
            },
            getSupplierCode() {
                if (!this.supplier.code && this.supplier.name) {
                    this.isBusy = true;
                    axios
                        .get('suppliers/GetNextSupplierCode', {
                            params: { name: this.supplier.name }
                        })
                        .then(resp => {
                            this.supplier.code = resp.data;
                        })
                        .finally(() => {
                            this.isBusy = false;
                        });
                }
            },
           checkSupplierCodeExists() {
                if (this.supplier.code) {
                    this.isBusy = true;
                    axios.get("suppliers/codeexists", { params: { code: this.supplier.code} })
                        .then(resp => {
                            this.codeExists = resp.data;
                        })
                        .finally(() => {
                            this.isBusy = false;
                        });
                }
            },
            addDiscountTier() {
                if(!this.supplier.shippingDiscountCodes || this.supplier.shippingDiscountCodes.length === 0)
                {
                    this.$toasted.global.app_error('There are no discount codes for this supplier. Please add at least one first.');
                    return;
                }
                this.$refs.discountTable.currentPage = 1;
                this.supplier.shippingDiscounts.unshift({vendorDiscountCodeId: null,  isDollar: false, uniqueId: uniqueid() });
            },
            showAddDiscountCodeModal() {
                if(this.isNew)
                {
                    this.$toasted.global.app_error('Please save the new vendor before adding discount codes.');
                    return;
                }
                this.$bvModal.show('addDiscountCode');
            },
            async addDiscountCode(event) {
                if (this.newCode && this.supplier.shippingDiscountCodes.some(c => c.text.toLowerCase() === this.newCode.toLowerCase())) {
                    event.preventDefault();
                    this.$toasted.global.app_error('Code already exists.');
                    return;
                }
                if (this.newCode) {
                    axios
                        .get('suppliers/AddDiscountCode', {
                            params: { code: this.newCode, supplierId: this.supplier.supplierId }
                        })
                        .then(resp => {
                            this.supplier.shippingDiscountCodes.push({ value: resp.data, text: this.newCode });
                            this.newCode = '';
                        });
                } else {
                    event.preventDefault();
                    if (!(await this.$refs.form.validate())) {
                        return;
                    }
                }

            },
            deleteDiscountTier(item) {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to remove this discount tier?'
                    )
                    .then(value => {
                        if (value) {
                            let index = this.supplier.shippingDiscounts.indexOf(
                                item
                            );
                            if (index !== -1) {
                                this.supplier.shippingDiscounts.splice(index, 1);
                            }
                        }
                    });
            },
            deleteSupplier() {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to delete this supplier?',
                        {
                            okVariant: 'danger',
                            okTitle: 'Delete',
                            cancelTitle: 'Go Back'
                        }
                    )
                    .then(value => {
                        if (value) {
                            axios
                                .post('suppliers/delete', this.supplier)
                                .then(resp => {
                                    if (resp) {
                                        this.dataSaved();
                                        this.$router.push('/suppliers');
                                    }
                                });
                        }
                    });
            },
            async loadData() {
                let selectedLocationId = await this.getSelectedLocationId()
                let promise = this.isNew
                    ? axios.get('suppliers/new')
                    : axios.get('suppliers', { params: { id: this.id, locationId: selectedLocationId } });
                promise.then(resp => {
                    this.supplier = resp.data;
                    if (this.isNew){
                        this.supplier.supplierLocation.locationId = selectedLocationId;
                    }
                    this.supplier.shippingDiscounts.sort(function (a, b) {
                        if (a.discountCode < b.discountCode) return -1;
                        if (a.discountCode > b.discountCode) return 1;
                        if (a.lowQty < b.lowQty) return -1;
                        if (a.lowQty > b.lowQty) return 1;
                        return 0;
                    });
                    this.supplier.shippingDiscounts.forEach(function (d) {
                        d.uniqueId = uniqueid();
                    });
                     if (!this.isNew) {
                         this.dataLoaded();
                    }
                });
            }
        },
        mounted: function () {
            this.loadData();
            this.inputDebounce = debounce(this.checkSupplierCodeExists, 300);
        },
        components: {}
    };
</script>
<style scoped>
>>> #discounts .form-control, >>> #discounts .custom-select
{
    min-width: 75px;
}
</style>

