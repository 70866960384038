import axios from 'axios';

export default {
    async checkDataExchangeJobsAsync(jobLogsQueryParams) {
        let runningJobs = [];
        let finishedJobs = [];
        if (jobLogsQueryParams) {
            await axios.get(`dataexchange/joblogs?${jobLogsQueryParams}`)
            .then(resp => {
                runningJobs = resp.data.filter(x => x.isRunning);
                finishedJobs = resp.data.filter(x => !x.isRunning);
            });
        } else {
            await axios.get('dataexchange/runningjobs')
            .then(resp => {
                runningJobs = resp.data;
            });
        }
        return {
            runningJobs: runningJobs, 
            finishedJobs: finishedJobs
        };
    }
}