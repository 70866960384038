import { min_value, integer } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import moment from 'moment';
import {dateGreaterOrEqualTest } from '@/components/mixins/ValidatedInput.js';



extend('valid-date', {
    params: ['isValidDate', 'rowData', 'propertyName'],
    message(field, { propertyName, rowData }) {
        return `'${rowData.originalData[propertyName]}' is not a supported date.`;
    },
    validate(value, { isValidDate, rowData, propertyName }) {
        return isValidDate(rowData[propertyName]);
    }
});

extend('dateGreaterOrEqualImport', {
    params: ['rowData', 'propertyName','propertyLabel'],
    validate(value, { rowData, propertyName }) {
        return dateGreaterOrEqualTest(value, rowData[propertyName]);
    },
    message(field, { propertyLabel}) {
        return `${field} should be the same as or after ${propertyLabel}`;
    }
});

export default {
    methods: {        
        matchesExistingObject(value, isRequired) {
            if(isRequired === true && this.isEmpty(value)){
                return false;
            }
            //test for -1 instead of > 0 to work with store options that have a string instead of
            //id for the value (ex courseRequestTypes)
            return (
                this.isEmpty(value) || value !== -1                
            );
        },

        isNonNegativeInteger(value, isRequired) {
            if(isRequired === true && this.isEmpty(value)){
                return false;
            }

            return this.isEmpty(value) 
            || (min_value.validate(value, { min: 0 }) 
            && integer.validate(value));

        },
        isEmpty(value){
            return value === '' || value === undefined || value === null;
        },

        isValidDate(value) {
            if (!value) {
                return true;
            }
            return moment(
                value,
                this.$validDates,
                true
            ).isValid();
        }
    }
};
