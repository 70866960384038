<template>
  <div>
  <p-button :is-busy="isBusy" @click="showModal" no-border class="pl-2 d-inline">
                    <i class="fas fa-edit"></i
                ></p-button>
     <p-modal      
        size="lg"
        :label="'AR Accounts ('+customer.firstName +' '+ customer.lastName+')'"
        ref="ARAccountsModal"
    >
        <template v-slot:default>
            <div class="col">
                <p-table 
                            :fields="fields"
                            :items="customerARAccounts"
                            :enable-row-selection='false'
                            >
                            <template v-slot:cell(action)="{ value, item }">
                                <b-button
                                variant="outline-primary"
                                class="d-flex"
                                @click="addArAccount(item,webOrder)"
                                :disabled="new Date(item.fundingStartDate) > new Date(todaysDate)"
                               >Select
                            </b-button>
                            </template>
                            
                        </p-table>
            </div>
        </template>

        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <b-button variant="outline-primary" @click="cancel">Cancel</b-button>
                </b-col>
            </b-row>
        </template>
    </p-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { dateFormatter, currencyFormatter } from '../../components/Common/Formatters.js';

export default {
 props: {
            customer:{},
            webOrder:{},
            isBusy: {
                type: Boolean,
                default: false
            }
        },
    data() {
        return {
            fields: [
                {
                    key: 'accountNumber',
                    label: 'Account Code ',
                    sortable: true
                },
                {
                    key: 'accountName',
                    label: 'Account/Agency Name',
                    sortable: true
                },
                {
                    key: 'fundingStartDate',
                    label: 'Funding Start Date',
                    sortable: true,
                     formatter: dateFormatter
                },
                {   
                    key: 'fundingEndDate',
                    label: 'Funding End Date',
                    sortable: true,   
                     formatter: dateFormatter                
                },
                {
                    key: 'availableFund',
                    label: 'Available Funds',                    
                    sortable: true,  
                     formatter: currencyFormatter,
                    tdClass: 'text-right'                
                },
                {
                    key: 'action',
                    sortable: false,
                    
                },
            ],
            customerARAccounts:[],
            todaysDate: new Date()
         };
    },
    methods: {
        async addArAccount(item,webOrder) {

            if (item.isTaxExempt != webOrder.isAccountTaxExempt) {
                await this.$bvModal
                    .msgBoxConfirm('This payment account update will refresh all tax values on the current screen, including overridden values.')
                    .then(value => {
                        if (!value) {
                            this.preventDefault();
                        }
                    });
            }

            if(!webOrder.paymentMethod.isARAccount){
                await this.$bvModal.msgBoxConfirm(
                        'Are you sure you want to switch payment methods? This selection is irreversible.',
                )
                .then(value => {
                    if(value){
                        this.$emit('addaccount', item);
                        this.$refs['ARAccountsModal'].hide();
                    }
                });
            }else{
                this.$emit('addaccount', item);
                this.$refs['ARAccountsModal'].hide();                
            }
        },
        showModal(){
            this.$refs['ARAccountsModal'].show();
             axios
                    .get('customers/searchcustomeraccount', {
                        params: {
                            perPage: 5000,
                            automatic: '',
                            isInactive: true,
                            searchBy: 'All',
                            customerId: this.customer.customerId
                        }
                    })
                    .then(resp => {
                        let today =new Date()
                        this.customerARAccounts = resp.data.data.filter(x => new Date(x.fundingEndDate) >= new Date(today));
                    });
        }
    }
}
</script>