<template>
    <SearchScreen
        title="Instructors"
        api-destination="instructors"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        add-bulk-is-active-toggle
        edit-key="instructorId"
        add-new-button
    />
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'InstructorFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All', 'Name', 'Email'],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'email',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: true,
                    disabled: true
                }
            ]
        };
    },
    components: {
        SearchScreen
    }
};
</script>
