<template>
    <SearchScreen
        title="Courses"
        api-destination="courses"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        edit-key="courseId"
        add-new-button
    >
        <template v-slot:afterSearch>
            <b-col cols="3">
                <p-select
                    class=" style ml-2 "
                    no-selection-message="All Campuses"
                    dataType="campuses"
                    v-model="filters.campusId"
                />
            </b-col>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'CoursesFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: ['All', 'Name', 'Description', 'Department'],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },

                {
                    key: 'department',
                    sortable: true
                },
                {
                    key: 'campus',
                    sortable: true
                }
            ]
        };
    },
    methods: {},
    components: {
        SearchScreen
    }
};
</script>

<style scoped>
>>> .custom-select {
    height: 43px;
}
</style>
