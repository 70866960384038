<template>
    <div>
        <b-row cols="3" class="font-weight-bold mb-1 row-border" >
                <b-col>DCC</b-col>
                <b-col>Allow</b-col>
                <b-col>Discount %</b-col>
        </b-row> 
        <b-card
            v-for="department in dccs"
            :key="department.dccDepartmentId"
            class="mb-1"
            no-body
        >
            <b-card-body class="p-1">
            <b-row cols="3">
            <b-col>
                <b-button
                    v-b-toggle="
                        'departmentToggle_' + department.dccDepartmentId
                    "
                    class="short-button p-0"
                >
                    <b-icon-chevron-down class="when-open mb-1" font-scale=".9"></b-icon-chevron-down>
                    <b-icon-chevron-right class="when-closed mb-1" font-scale=".9"></b-icon-chevron-right>
                </b-button>
                <span
                    :class="{
                        'pl-1' : true,
                        'selectable-element': true,
                        'selected-element':
                            selectedDcc.department === department &&
                            selectedDcc.class === undefined,
                        'selected-parent':
                            selectedDcc.department === department &&
                            selectedDcc.class != undefined
                    }"
                    >{{ numberToString(department.number) }}
                    {{ department.name }}</span 
                > 
            </b-col>
            <b-col><span class="d-inline-block">
                <p-checkbox 
                    v-model="department.isActive" 
                    @change="combinedCategorySettingChange(department, 'isActive',department)">
                </p-checkbox></span></b-col>
            <b-col><span class="d-inline-block">
                    <p-number
                        name="Discount"
                        v-model.number="department.percentDiscount" 
                        rules="min_value:0|max_value:100"
                        input-type="percent"
                        :disabled="!department.allowsDiscounts"
                        @change="combinedCategorySettingChange(department,'percentDiscount',department)">
                    </p-number>
                </span>
            </b-col>
            </b-row>
                <b-collapse
                    :id="'departmentToggle_' + department.dccDepartmentId" visible
                >
                    <div
                        class="pl-3"
                        v-for="dccClass in department.dccClasses"
                        :key="dccClass.dccclassId"
                    >
                    <b-row cols="3">
                        <b-col>    
                            <b-button
                                v-b-toggle="'dccClassToggle_' + dccClass.dccClassId"
                                class="short-button p-0 "
                            >
                                <b-icon-chevron-down class="when-open mb-1" font-scale=".9"></b-icon-chevron-down>
                                <b-icon-chevron-right class="when-closed mb-1" font-scale=".9"></b-icon-chevron-right>

                            </b-button>
                            <span
                                :class="{
                                    'pl-1' : true,
                                    'selectable-element': true,
                                    'selected-element':
                                        selectedDcc.class === dccClass &&
                                        selectedDcc.category === undefined,

                                    'selected-parent':
                                        selectedDcc.class === dccClass &&
                                        selectedDcc.category != undefined
                                }"
                                >{{ numberToString(dccClass.number) }}
                                {{ dccClass.name }}
                            </span> 
                        </b-col>    
                        <b-col style="margin-left: -10px;">
                            <span class="d-inline-block">
                                <p-checkbox 
                                    v-model="dccClass.isActive" 
                                    @change="combinedCategorySettingChange(dccClass, 'isActive',department)">
                                </p-checkbox>
                            </span>
                        </b-col>  
                        <b-col style="margin-left:5px;">
                            <span class="d-inline-block">
                                <p-number 
                                name="Discount"
                                v-model.number="dccClass.percentDiscount" 
                                rules="min_value:0|max_value:100"
                                input-type="percent"
                                :disabled="!dccClass.allowsDiscounts"
                                @change="combinedCategorySettingChange(dccClass,'percentDiscount',department)">
                                </p-number>
                            </span>
                        </b-col>
                    </b-row>
                    <b-collapse
                            :id="'dccClassToggle_' + dccClass.dccClassId" visible
                        >
                            <div
                                class="pl-4"
                                v-for="dccCategory in dccClass.dccCategories"
                                :key="dccCategory.dccCategoryId"
                            >
                            <b-row cols="3">
                                <b-col>    
                                    <span
                                        :class="{
                                            'selectable-element': true,
                                            'inactive-element': !dccCategory.isActive,
                                            'active-element': dccCategory.isActive,
                                            'selected-element':
                                                selectedDcc.category === dccCategory
                                        }"
                                        @click="
                                            selectedElement(
                                                department,
                                                dccClass,
                                                dccCategory
                                            )
                                        "
                                        >{{ numberToString(dccCategory.number) }} 
                                        {{ dccCategory.name }}</span
                                    >
                                </b-col>     
                                <b-col style="margin-left: -26px;">
                                    <span class="d-inline-block">
                                        <p-checkbox 
                                            v-model="dccCategory.isActive"
                                            @change="combinedCategorySettingChange(dccCategory, 'isActive',department)">
                                        </p-checkbox>
                                    </span>
                                </b-col>
                                <b-col style="margin-left:13px;">
                                    <span class="d-inline-block">
                                        <p-number
                                        name="Discount" 
                                        v-model.number="dccCategory.percentDiscount" 
                                        rules="min_value:0|max_value:100"
                                        input-type="percent"
                                        :disabled="!dccCategory.allowsDiscounts"
                                        @change="combinedCategorySettingChange(dccCategory,'percentDiscount',department)">
                                        </p-number>
                                    </span>
                                </b-col>
                        </b-row>
                            </div> 
                        </b-collapse>
                    </div>
                </b-collapse>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
export default {
    name: "dcc-list",
    props: {
        dccs: [Array],
        selectedDcc: [Array]
    },
    data() {
        return {};
    },
    watch: {},
    computed: {
        updatedDepartment() {
            return this.dccs[0];
        },
        updatedCategory() {
            return this.dccs.find(x => x.dccLevel === "Department");
        },
        updatedClass() {
            return this.dccs.find(x => x.dccLevel === "Class");
        }
    },
    methods: {
        numberToString(number) {
            return number.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false
            });
        },
        combinedCategorySettingChange(parentElement, updatedPropertyName,topParentElement){
            let newValue = parentElement[updatedPropertyName];

            if(parentElement.dccLevel === "Department"){
                this.setPropertyRecursivelyDepartmentClick(parentElement,newValue,'dccClasses',updatedPropertyName,topParentElement);
            }
            else if(parentElement.dccLevel === "Class"){
                this.setPropertyRecursivelyClassClick(parentElement,newValue,'dccCategories',updatedPropertyName,topParentElement);
                //added for traversing that parent should be null if childs have diffrent property values.
                if(!topParentElement['dccClasses'].every(ele => ele[updatedPropertyName] === newValue) || topParentElement[updatedPropertyName] != newValue){
                    topParentElement[updatedPropertyName] = null;
                }
                if(topParentElement.dccClasses.every(ele => ele[updatedPropertyName] === newValue))topParentElement[updatedPropertyName]=newValue
            }
            else if(parentElement.dccLevel === "Category"){
                if(!topParentElement.dccClasses[0]['dccCategories'].every(ele => ele[updatedPropertyName] === newValue) || topParentElement.dccClasses[0][updatedPropertyName] != newValue){
                    topParentElement.dccClasses.map((ele)=> {
                        if(parentElement['dccClassId'] === ele['dccClassId']){
                            ele.dccCategories.length == 1 ? ele[updatedPropertyName] = newValue : ele[updatedPropertyName] = null;
                        }
                    });
                    topParentElement[updatedPropertyName] = null;
                }else{
                    topParentElement.dccClasses.map((ele)=> {
                        if(parentElement['dccClassId'] === ele['dccClassId']){
                            ele.dccCategories.length == 1 ? ele[updatedPropertyName] = newValue : ele[updatedPropertyName] = null;
                        }
                    }); 
                }

                topParentElement.dccClasses.map((elem)=>{
                    if(elem.dccCategories.every(ele => ele[updatedPropertyName] === newValue))elem[updatedPropertyName]=newValue;
                })
                if(topParentElement.dccClasses.every(ele => ele[updatedPropertyName] === newValue))topParentElement[updatedPropertyName]=newValue
            }
        },
        setPropertyRecursivelyDepartmentClick(parentElement,newValue,iterableElements,updatedPropertyName) {
            parentElement[iterableElements].map((ele)=> {
                ele[updatedPropertyName] = newValue;
                this.setPropertyRecursivelyClassClick(ele,newValue,'dccCategories',updatedPropertyName);     
                return ele;
            });
        },
        setPropertyRecursivelyClassClick(parentElement,newValue,iterableElements,updatedPropertyName) {
            parentElement[iterableElements].map((ele)=> {
                ele[updatedPropertyName] = newValue;
                return ele;
            });
        }
    },

};
</script>
<style scoped lang="scss">
@import "@/styles/app/common/variables.scss";
/deep/ .selected-element {
    background-color: $gray-lighter;
    outline-style: solid;
    outline-width: 1px;
    padding: 2px;
    outline-color: $gray;
}
/deep/ .collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
/deep/ .short-button {
    height: 20px;
    background-color: inherit;
    border: none;
}
/deep/ .selectable-element {
    cursor: pointer;
    width: 100%;
}
/deep/ .selected-parent {
    text-decoration: underline;
}
/deep/ .inactive-element {
    opacity: .8;
}
.row-border{
    border-color: transparent; 
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); 
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
