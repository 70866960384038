<template>
    <p-modal
        ref="printLabelModal"
        name="printLabelModal"
        :icon-plus="false"
        size="lg"
        :label="'Print Label: ' + this.productFamily.description"
        @ok.prevent="handleModalOk"
        @show="loadData"
        @shown="setFocus"
    >
        <p-form ref="form">
            <template v-slot:default>
                <div>
                    <b-row>
                        <b-col cols="12" md="12" lg="6">
                            Price Tag Type: {{ tagType }}
                        </b-col>
                        <b-col
                            cols="12"
                            md="12"
                            lg="3"
                            v-if="productFamily.isMatrix"
                        >
                            Print Qty (All Products):
                        </b-col>
                        <b-col
                            cols="12"
                            md="12"
                            lg="3"
                            v-if="productFamily.isMatrix"
                        >
                            <p-number
                                input-type="integer"
                                name="All Print Qty"
                                rules="min_value:0|max_value:99"
                                v-model="AllLabelQty"
                                ref="allQtyInput"
                                compact-format
                                @input="onAllQtyChangeHandler()"
                            />
                        </b-col>
                        <span id="tagContent" v-html="tagContent" />
                    </b-row>
                    <b-row style="max-height:300px;overflow:auto">
                        <b-col>
                            <p-table
                                ref="SKUTable"
                                :items="products"
                                :fields="fieldsSKU"
                                :enableRowSelection="false"
                                :per-page="500"
                                class="wide mt-2"
                                outlined
                                id="skuTable"
                            >
                                <template
                                    v-slot:cell(qty)="{
                                        value,
                                        item,
                                        index
                                    }"
                                >
                                    <p-number
                                        input-type="integer"
                                        name="Print Qty"
                                        :rules="'max_value:99|min_value:'+minQty+'|' + requiredQty"
                                        @input="disableCheckPrint()"
                                        compact-format                                        
                                        v-model.number="item.qty"
                                    />
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </div>
            </template>
        </p-form>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row class="mb-3">
                <p-button variant="outline-primary" @click="cancel"
                    >Cancel</p-button
                >
                <p-button variant="primary" @click="ok" class="ml-2" :disabled="!enablePrint"
                    >Print</p-button
                >
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import StoreListManager from '@/components/mixins/StoreListManager.js';
import axios from 'axios';
import printJS from 'print-js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import $ from '@/components/Common/wrapper.js';

export default {
    mixins: [StoreListManager],
    props: {
        productFamily: {
            type: Object
        },
        product: {
            type: Object
        },
        products: {
            type: Array
        }
    },
    data() {
        return {
            labelQty: 1,
            AllLabelQty: null,
            selectListOptions: {
                priceTagTypes: []
            },
            tagContent: null,
            fieldsSKU: [
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'defaultBarcode',
                    label: 'Barcode'
                },
                {
                    key: 'gmVariant1',
                    label: 'Variant 1'
                },
                {
                    key: 'gmVariant2',
                    label: 'Variant 2'
                },
                {
                    key: 'qty',
                    label: 'Print Qty',
                    thClass: 'text-left',
                    tdClass: 'text-right'
                }
            ],
            enablePrint:false
        };
    },
    computed: {
        tagType() {
            return this.selectListOptions.priceTagTypes.find(
                x => x.value === this.product.priceTagTypeId
            )?.text;
        },
        requiredQty() {
            return this.products.length <= 1 ? 'required' : '';
        },
        minQty(){
            return this.products.length <= 1 ? 1:0;
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        loadData() {
            this.AllLabelQty = null;
            this.products.map(
                x => (
                    (x.qty = null),
                    (x.description = this.productFamily.description)
                )
            );
            if (!this.productFamily.isMatrix) {
                this.products.forEach(x => (x.qty = 1));
            }
            this.disableCheckPrint();
        },
        setFocus() {
            this.$refs['allQtyInput']?.focus();
            $(document).ready(function() {
                let rows = $('#skuTable tr');

                for(let row = 0; row < rows.length; row++)
                {
                    rows[row].removeAttribute('tabIndex');
                }                
            });
        },
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            var locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            let printProducts = this.products            
            .map(x => ({
                defaultBarcode: x.defaultBarcode,
                sku: x.sku,
                qty: x.qty ?? null,
                PriceTagTypeId: x.priceTagTypeId
            }))
            .filter(x => x.qty != null && x.qty != 0);
            await axios
                .post('products/printgmpricetag', {
                    products: printProducts,
                    locationId: locationId
                })
                .then(result => {
                    this.tagContent = result.data;
                    this.$nextTick(() => {
                        printJS('tagContent', 'html', '#tagContent: visible;');
                        this.tagContent = null;
                        this.$refs.printLabelModal.hide();
                    });
                });
        },
        onAllQtyChangeHandler() {
            this.products.map(x => (x.qty = this.AllLabelQty));            
            this.disableCheckPrint();
        },       
        disableCheckPrint(){
            this.enablePrint=this.products.some(x=>x.qty!=null&&x.qty!=0);
        }
    }
};
</script>
<style scoped>
#tagContent {
    visibility: hidden;
}
</style>
