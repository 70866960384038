<template>
    <header class="topnavbar-wrapper">
        <!-- START Top Navbar-->
        <nav class="navbar topnavbar">
            <!-- START navbar header-->
            <div class="navbar-header">
                <a class="navbar-brand" href="/">
                    <div class="brand-logo">
                        <img class="img-fluid" src="img/NBC_Mosaic_Logo_White_47x34.png" alt="App Logo">
                    </div>
                    <div class="brand-logo-collapsed">
                        <img class="img-fluid" src="img/NBC_Mosaic_Logo_mark_CMYK_29x40.png" alt="App Logo">
                    </div>
                </a>
            </div>
            <!-- START Left navbar-->
            <ul class="navbar-nav mr-auto flex-row">
                <li class="nav-item">
                    <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
                    <a href="" class="nav-link sidebar-toggle d-md-none" @click.prevent="toggleOffcanvas">
                        <em class="fas fa-bars"></em>
                    </a>
                </li>
                <p-feature-flag ref="multiStoreFlag" name="MultiStore">
                    <li class="nav-item">
                         <p-select
                            v-model.number ='locationId'
                            data-type ='locations'
                            name = "Location Selector"
                            :set-invalid-value-to-first-option = 'true'
                            @change="setLocation"
                           style="min-width:200px"
                           class="ml-3 "
                        />
                    </li>
                </p-feature-flag>
            </ul>
            <!-- END Left navbar-->
            <!-- END navbar header-->
            <ul class="navbar-nav flex-row">
                <!-- START Offsidebar button-->
                <li class="nav-item">
                    <a href="" class="nav-link" @click.prevent="logout" @click.middle.prevent="logout">
                        Log Out
                    </a>
                </li>
                <!-- END Offsidebar.prevent menu-->
            </ul>
        </nav>
        <!-- END Top Navbar-->
    </header>
</template>

<script>
    import userManager from '@/services/SecurityService';
    import SidebarToggle from './SidebarToggle.js';
    import NavigationGuard from '@/components/mixins/NavigationGuard.js';
    import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

    export default {
        name: 'Header',
        mixins: [SidebarToggle, NavigationGuard],
        computed: {
            locationCacheKey() {
                return "SelectedLocationId" + this.$store.getters.getCurrentUserId;
            },

            selectedLocation(){
                return this.$store.getters.getSelectedLocationId;
            }
        },
        data: () => {
            return {
                navSearchOpen: false,
                locationId: null,
            }
        },
        watch: {
            selectedLocation: {
                deep: true,
                handler: function(newVal) {
                    this.locationId = newVal;
                }
            }
        },
        methods: {
            closeNavSearch() {
                this.navSearchOpen = false;
            },
            async logout() {
                 var isFormDirty = false;
                 if (this.$parent && this.$parent.$children) {
                     isFormDirty = this.$parent.$children.some(x => x.formIsDirty);
                 }
                  var promptOk
                 if (isFormDirty) {
                      promptOk = await this.$bvModal
                         .msgBoxConfirm([this.displayMessage]);
                     if (promptOk) {
                         this.$parent.$children.forEach(x => {
                             if (x.formIsDirty && x.dataLoaded) {
                                 x.dataLoaded();
                             }
                         });
                     }
                     else {
                         return;
                     }
                 }
                 else {
                    promptOk = await this.$bvModal
                         .msgBoxConfirm('Are you sure you want to logout?');
                     if (!promptOk) {
                         return;
                     }
                 }
                 window.aptrinsic('reset');
                 userManager.signoutRedirect();
            },
            setLocation(value) {
                this.$store.commit('updateSelectedLocationId', value)
                localStorage.setItem(this.locationCacheKey, value);
                if(this.$router.history.current.path != '/home'){
                    this.$router.push('/home');
                }
            }
        },
        async created() {
            this.locationId = localStorage.getItem(this.locationCacheKey);
            if(this.locationId == null){
                //if no previously chosen location is found, we load the first option available and set that as the selection
                this.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                localStorage.setItem(this.locationCacheKey, this.locationId);
            }

            this.$store.commit('updateSelectedLocationId', this.locationId);
        }
    }
</script>

<style scoped>
    >>> .navbar {
        z-index: 200;
    }
    >>> .form-group{
            margin-bottom: 0rem !important;
}
</style>
<style scoped lang="scss">
/deep/ .compact-control {
    width:150px;
}
</style>