var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-modal',{ref:"addRentals",attrs:{"button-label":_vm.buttonLabel,"size":"lg","label":_vm.pageTitle,"button-variant":_vm.buttonVariant,"disable-button":_vm.disabled},on:{"ok":function($event){$event.preventDefault();return _vm.handleModalOk.apply(null, arguments)},"shown":_vm.assignRentalData},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p-form',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('p-select',{attrs:{"options":_vm.rentalOptions,"label":"Rental Source","disabled":_vm.rentalsData.newRentalItemPeriodId != null ||
                                _vm.rentalsData.usedRentalItemPeriodId != null},model:{value:(_vm.rentalsData.rentalSource),callback:function ($$v) {_vm.$set(_vm.rentalsData, "rentalSource", $$v)},expression:"rentalsData.rentalSource"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-col',[_c('p-number',{attrs:{"label":"Rebate Price","input-type":"money","rules":"min_value:0"},model:{value:(_vm.rentalsData.rebatePrice),callback:function ($$v) {_vm.$set(_vm.rentalsData, "rebatePrice", $$v)},expression:"rentalsData.rebatePrice"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',[_c('b',[_vm._v("Author/Title/ISBN")])]),_c('div',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.rentalsData.author + ' / ' + _vm.rentalsData.title + ' / ' + _vm.rentalsData.isbn))])])],1),_c('b-row',[_c('b-col',[_c('p-table',{attrs:{"items":[_vm.rentalsData],"fields":_vm.rentalFields,"enableRowSelection":false,"showRecordCount":false},scopedSlots:_vm._u([{key:"cell(bookVariant)",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('div',{staticClass:"mt-2 col-2"},[_vm._v(" New ")]),(item.usedSku != null)?_c('div',{staticClass:"mt-4 col-2"},[_vm._v(" Used ")]):_vm._e()]}},{key:"cell(newRentalPrice)",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('p-number',{staticClass:"col-8 mr-0 pr-0 pl-0",attrs:{"input-type":"money","rules":_vm.isRequired + '|min_value:0',"name":"New Rental Price"},model:{value:(item.newRentalPrice),callback:function ($$v) {_vm.$set(item, "newRentalPrice", $$v)},expression:"item.newRentalPrice"}}),(item.usedSku != null)?_c('p-number',{staticClass:"col-8 mr-0 pr-0 pl-0",attrs:{"input-type":"money","rules":_vm.isRequired + '|min_value:0',"name":"Used Rental Price"},model:{value:(item.usedRentalPrice),callback:function ($$v) {_vm.$set(item, "usedRentalPrice", $$v)},expression:"item.usedRentalPrice"}}):_vm._e()]}},{key:"cell(newPrice)",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('div',{staticClass:"mt-2 col-2 pl-0"},[_vm._v(" "+_vm._s(_vm.currencyFormatter(item.newPrice))+" ")]),(item.usedSku != null)?_c('div',{staticClass:"mt-3 pt-3 col-2 pl-0"},[_vm._v(" "+_vm._s(_vm.currencyFormatter(item.usedPrice))+" ")]):_vm._e()]}},{key:"cell(newCost)",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('div',{staticClass:"mt-2 col-2 pl-0"},[_vm._v(" "+_vm._s(_vm.currencyFormatter(item.newCost))+" ")]),(item.usedSku != null)?_c('div',{staticClass:"mt-3 pt-3 col-2 pl-0"},[_vm._v(" "+_vm._s(_vm.currencyFormatter(item.usedCost))+" ")]):_vm._e()]}},{key:"cell(newIsActive)",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('p-checkbox',{staticClass:"text-right mt-1 col-2 pl-0",attrs:{"use-switch":true,"size":"lg"},model:{value:(item.newIsActive),callback:function ($$v) {_vm.$set(item, "newIsActive", $$v)},expression:"item.newIsActive"}}),(item.usedSku != null)?_c('p-checkbox',{staticClass:"text-right mt-3 pt-3 col-2 pl-0",attrs:{"use-switch":true,"size":"lg"},model:{value:(item.usedIsActive),callback:function ($$v) {_vm.$set(item, "usedIsActive", $$v)},expression:"item.usedIsActive"}}):_vm._e()]}}])})],1)],1)],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
                            var ok = ref.ok;
return [_c('b-row',{staticClass:"w-100"},[_c('b-col',{staticClass:"mb-3 text-center"},[_c('p-button',{staticClass:"ml-2",attrs:{"is-busy":_vm.isBusy,"variant":"primary","disabled":_vm.rentalsData.rentalSource == 'None'},on:{"click":ok}},[_vm._v("Save")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }