<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
      <p-form @submit="onSubmit" id="customer-form">
          <div class="container-fluid content-wrapper" v-if="customer">
              <b-row class="content-heading">
                  <b-col>
                      <div>{{ isNew ? 'Add' : 'Edit' }} Customer</div>
                  </b-col>
                  <b-col cols="auto" class="ml-auto">
                    <p-button
                        variant="primary"
                        type="submit"
                        form="customer-form"
                        :is-busy="isBusy"
                        >Save</p-button>
                  </b-col>
              </b-row>

              <Profile
                  v-if="isNew"
                  :customer="customer"
                  :is-busy="isBusy"
              ></Profile>
              <b-tabs v-else justified>
                  <b-tab title="Customer Profile">
                      <Profile :customer="customer" :is-busy="isBusy"></Profile>
                  </b-tab>
                  <b-tab title="Transaction History">
                      <transaction-history :customer="customer"></transaction-history>
                  </b-tab>
                  <p-feature-flag name="Rental History">
                   <b-tab title="Rental History">
                      <rentalHistory :customer="customer"></rentalHistory>
                  </b-tab>
                  </p-feature-flag>
                  <b-tab title="Accounts">
                      <account :customer="customer"></account>
                  </b-tab>
              </b-tabs>
                <b-row class="mt-3">
                    <b-col>
                         <!-- Delete button should only show if user has StoreManager or Admin role.
                            If we ever allow other roles on the page level, we need to add a p-secure
                            tag around the delete button. -->
                    <p-button
                        v-if="!isNew"
                        variant="outline-danger"
                        @click="deleteCustomer"
                        :is-busy="isBusy"
                        >Delete</p-button>
                    </b-col>
                    <b-col cols="auto" class="ml-auto pr-0">     
                            <p-button
                                variant="outline-primary"
                                @click="cancelEdit"
                                :is-busy="isBusy"
                                >Cancel</p-button>
                    </b-col>
                    <b-col cols="auto">     
                            <p-button
                                variant="primary"
                                type="submit"
                                form="customer-form"
                                :is-busy="isBusy"
                                >Save</p-button>
                    </b-col>
                </b-row>
              <b-row>
              </b-row>
          </div>
      </p-form>
    </p-secure>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import editPage from '@/components/mixins/EditPage';
import Profile from './Profile.vue';
import Account from './Account.vue';
import transactionHistory from './TransactionHistory.vue';
import rentalHistory from './RentalHistory.vue';

export default {
    mixins: [NavigationGuard, editPage],
    data() {
        return {
            customer: null,
            isBusy: false
        };
    },
    components: {
        Profile,
        transactionHistory,
        rentalHistory,
        Account
    },
    watch: {
        customer: NavigationGuard.$watcher
    },
    methods: {
        onSubmit() {
            this.isBusy = true;
            axios
                .post('customers', this.customer)
                .then(response => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push('/customers/' + response.data);
                    }
                    this.loadData();

                    this.$toasted.global
                        .app_success(`Customer saved successfully.`)
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        deleteCustomer() {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this customer?')
                .then(value => {
                    if (value) {
                        this.isBusy = true;
                        axios
                            .post('customers/delete', this.customer)
                            .then(resp => {
                                if (resp) {
                                    this.dataSaved();
                                    this.$router.push('/customers');
                                }
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    }
                });
        },

        loadData() {
            let promise = this.isNew
                ? axios.get('customers/new')
                : axios.get('customers', { params: { id: this.id } });
            promise.then(resp => {
                this.customer = resp.data;
                 if (!this.isNew) {
                         this.dataLoaded();
                    }
               
            });
        },
        cancelEdit() {
            this.$router.push('/customers');
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
