<template>
  <div>
    <p-modal
            size="lg"
            name="addNewSerialModal"
            :label="'Assign Serial Numbers ('+productFamily.description+')'"
            button-label="Add New Serial"
            icon-plus
            @shown="showModal"
            ref="addNewSerialModal"
        >
        <template v-slot:default>
             <p-form ref="form">
            <b-row>
               <b-col class="d-flex  align-items-center" >                     
                        <p-input
                        label="Add New Serial Number"
                        ref="serialNumber"
                        rules="max:25"
                        class="w-100"
                        placeholder="Scan or type your serial number (ex: HH07222022)"
                        v-model="serialNumber"
                    />
                     <b-button
                        type="button"
                         variant="primary"
                         class="d-flex  ml-3 mt-2"
                        :disabled="!serialNumber"
                        @click="addSerialNumber"
                        >  <b-icon-plus></b-icon-plus> Add </b-button>
                   
               </b-col>
                    
            </b-row>
             <b-row>
                    <b-col>
                        <p-table
                            :items="serialNumberArr"
                            :fields="fields"
                            :enable-row-selection="false"
                            class="my-3"
                            >
                         <template v-slot:cell(removeDetail)="{ value, item }">
                             <b-button
                             class="p-1 ml-1 "
                            >
                                <BIconTrash
                                    font-scale="1.6"
                                    icon="trash"
                                    class="my-1 mx-1"
                                    @click="deleteSerialNumber(item)"
                                ></BIconTrash>
                            </b-button>
                        </template>
                                    
                            </p-table>
                        </b-col>
            </b-row>
             </p-form>
        </template>
         <template v-slot:modal-footer="{cancel }">
            <b-row>
                    <p-button
                        variant="outline-primary"
                        @click="cancel"
                    >
                        Cancel
                    </p-button>                    

                    <p-button
                        ref="btnSave"
                        variant="primary"
                        @click="addserialNumber"
                        :disabled="serialNumberArr.length<1"
                        class="ml-3"
                        >Add ({{serialNumberArr.length }}) {{serialNumberArr.length > 1 ? "Serials":"Serial"}}  </p-button
                    >
            </b-row>
        </template>
        </p-modal>
  </div>
</template>

<script>
export default {
 props: {
        productFamily: Object,
    },
     data() {
        return {
             serialNumber: null,
             serialNumberArr:[],
              fields:[
                {
                    key: 'serialNumber',
                    label: 'Serial Number',
                    sortable: true,
                },
                {
                    key: 'removeDetail',
                    label: 'Delete',
                     thClass: 'text-right',
                    tdClass: 'text-right'
                }
            ],
        }
        },
         methods:{
        addserialNumber(){            
             this.$emit('ok',this.serialNumberArr);
             this.$refs.addNewSerialModal.hide();
        },
         async addSerialNumber(){
             if (!(await this.$refs.form.validate())) {
                return;
            }
            if(this.productFamily.serialNumbers.some(x=>x.serialNumber==this.serialNumber) || this.serialNumberArr.some(x=>x.serialNumber==this.serialNumber)){
                this.$toasted.global
                     .app_error('Cannot duplicate Serial Number')
                     .goAway(5000);
                return;
            }
             this.serialNumberArr.push({
                 serialNumber:this.serialNumber,
             })
             this.serialNumber = null;
            this.$refs.serialNumber.focus();
         },
        deleteSerialNumber(item) {
            var index = this.serialNumberArr.findIndex(x => x.serialNumber === item.serialNumber);
            this.serialNumberArr.splice(index, 1);
        },
        showModal() {
            this.serialNumber = null;
            this.serialNumberArr = [];
            this.$refs.serialNumber.focus();
        },

    },
}
</script>

<style>

</style>