var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-secure',{attrs:{"require-role":[_vm.SECURITYROLES.STOREMANAGER]}},[_c('div',{staticClass:"container-fluid content-wrapper"},[_c('b-row',{staticClass:"content-heading"},[_c('b-col',[_c('div',[_vm._v("Buyback - Purchasers")])])],1),_c('b-row',[_c('editPurchaser',{staticClass:"pb-2 ml-3 mr-2",attrs:{"purchaser-id":"new"},on:{"data-saved":function($event){return _vm.updatePurchaserSearch()}}})],1),_c('b-row',[_c('p-search-table',{ref:"purchaserSearch",attrs:{"fields":_vm.purchaserFields,"selectable":false,"enable-row-selection":false,"search-destination":"purchasers","sort-by":"purchaserName","api-destination":"buyback"},scopedSlots:_vm._u([{key:"cell(edit)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('editPurchaser',{attrs:{"purchaser-id":item.buybackPurchaserId},on:{"data-saved":function($event){return _vm.updatePurchaserSearch()}}})]}},{key:"cell(isSpeculative)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('p-checkbox',{attrs:{"disabled":""},model:{value:(item.isSpeculative),callback:function ($$v) {_vm.$set(item, "isSpeculative", $$v)},expression:"item.isSpeculative"}})]}},{key:"cell(isActive)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('p-checkbox',{attrs:{"disabled":""},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }