<template>
    <p-modal
        ref="customerAccountDetailsModal"
        :button-label="buttonLabel"
        size="lg"
        :label="modalTitle"
        @ok.prevent="handleModalOk"
        @show="loadData"
    >
        <template v-slot:default>
            <p-form v-if="customerDetails && customerList" ref="form">
                <b-row cols="1">
                    <b-col>
                        <p-advanced-select
                            label="Customer Name"
                            data-type="customers"
                            v-model="customerDetails.customerId"
                            :disabled="!isNew"
                            :filter="filterCustomer"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" lg="6">
                        <p-datepicker
                            label="Start Date"
                            rules="required"
                            vid="startDate"
                            v-model="customerDetails.startDate"
                        />
                    </b-col>
                    <b-col cols="12" lg="6">
                        <!-- todo: make end date -->
                        <p-datepicker
                            label="End Date"
                            vid="endDate"
                            rules="required|dateGreaterOrEqual:@startDate"
                            v-model="customerDetails.endDate"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" lg="4">
                        <p-number
                            :disabled="account.type === 2 || account.noLimit"
                            input-type="money"
                            label="Credit Limit"
                            rules="min_value:0"
                            v-model="customerDetails.limit"
                            @change="creditLimitChangeHandler()"
                        />
                        <span v-if="account.noLimit" style="color:red">No Limit</span>
                    </b-col>
                    <b-col cols="12" lg="4">
                        <p-number
                            input-type="money"
                            label="Customer Balance"
                            v-model="customerDetails.balance"
                            @change="customerBalanceChangeHandler()"
                        />
                    </b-col>
                    <b-col cols="12" lg="4">
                        <p-number
                            disabled="true"
                            input-type="money"
                            label="Available Funds"
                            v-model="customerDetails.availableFunds"
                        />
                    </b-col>
                </b-row>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row class="w-100">
                <b-col class="mb-3 text-right pr-0">
                    <p-button
                        variant="outline-primary"
                        @click="cancel"
                        class="ml-2"
                        :is-busy="isBusy"
                        >Cancel</p-button
                    >
                    <p-button
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        :is-busy="isBusy"
                        >OK</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        buttonLabel: String,
        customerId: Number,
        details: Object,
        account: Object,
        id: {
            default: 'new',
            type: String
        }
    },
    data() {
        return {
            customerDetails: null,
            isBusy: false,
            customerList: Array
        };
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        modalTitle() {
            return this.account.accountCode + ' ' + this.account.agencyName;
        }
    },
    methods: {
        loadData() {
            this.isBusy = true;
            let dataPromise;
            if (this.isNew) {
                this.customerList = null;
                dataPromise = axios.get('AccountReceivable/newcustomer', {
                    params: {
                        accountId: this.account.accountId
                    }
                });
                axios
                    .get('accountreceivable/customersearch', {
                        params: {
                            perPage: 100,
                            automatic: '',
                            searchBy: 'All',
                            accountId: this.account.accountId
                        }
                    })
                    .then(resp => {
                        this.customerList = resp.data.data;
                    });
            } else {
                dataPromise = axios.get('AccountReceivable/customer', {
                    params: {
                        accountId: this.account.accountId,
                        customerId: this.customerId
                    }
                });
            }

            dataPromise
                .then(response => {
                    const details = response.data;
                    details.accountId = this.account.accountId;
                    if (this.isNew) {
                        details.startDate = this.account.defaultStartDate;
                        details.endDate = this.account.defaultEndDate;
                        details.limit = this.account.defaultCreditLimit;
                        details.balance = this.account.defaultBalance;
                    }
                    else{                        
                        details.limit = this.account.noLimit ? null :  this.account.type === 2 ? this.account.defaultCreditLimit : details.limit;
                    }
                    this.customerDetails = details;
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            this.isBusy = true;
            const details = { ...this.customerDetails };
            if(this.account.noLimit){
                details.limit = null;
            }
            else if (this.account.type === 2) {
                details.limit = details.limit === null ? 0 : details.limit;
            }
            details.balance = details.balance === null ? 0 : details.balance;
            details.isDebit = this.account.isDebit;
            axios
                .post('AccountReceivable/customer', details)
                .then(() => {
                    this.$emit('result-updated');
                    this.$refs.customerAccountDetailsModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        filterCustomer(customer) {
            return (
                !this.isNew ||
                !this.customerList?.some(x => x.customerId === customer.value)
            );
        },
        creditLimitChangeHandler() {
            if (this.account.type === 2) {
                this.customerDetails.availableFunds = this.customerDetails.balance;
            }else {
                this.customerDetails.availableFunds =
                    this.customerDetails.limit - this.customerDetails.balance;
            }
        },
        customerBalanceChangeHandler() {

            if (this.account.type === 2) {
                this.customerDetails.availableFunds = this.customerDetails.balance;
            }else {
                this.customerDetails.availableFunds =
                    this.customerDetails.limit - this.customerDetails.balance;
            }
        }
    }
};
</script>
