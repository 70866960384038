<template>
    <div>
        <p-card>
            <b-row cols="1" cols-sm="3">
                <b-col>
                    <p-input
                        v-if="customer.customerId"
                        label="Account Number"
                        disabled
                        rules="required|max:75"
                        v-model="customer.number"
                    />
                    <p-input
                        v-else
                        label="Account Number"
                        placeholder="Leave blank to auto-generate upon save."
                        rules="max:25"
                        v-model="customer.number"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Student ID"
                        rules="max:75"
                        v-model="customer.studentAccount"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Alternate Lookup ID"
                        rules="max:75"
                        v-model="customer.alternativeLookupId"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="First Name"
                        v-model="customer.firstName"
                        rules="required|max:50"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Last Name"
                        v-model="customer.lastName"
                        rules="required|max:50"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Email"
                        v-model="customer.email"
                        rules="email|max:254"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="7"  sm="5" lg="4" xl="3">
                    <p-phone-number
                        label="Primary/Mobile Phone"
                        v-model="customer.defaultPhone.number"
                        rules="max:26"
                    />
                </b-col>
                <b-col cols="5" sm="3" lg="2">
                    <p-input
                        label="Extension"
                        v-model="customer.defaultPhone.extension"
                        rules="max:6"
                    />
                </b-col>
                <b-col cols="7"  sm="5" lg="4" xl="3">
                    <p-phone-number
                        label="Secondary Phone"
                        v-model="customer.secondaryPhone.number"
                        rules="max:26"
                    />
                </b-col>
                 <b-col cols="5" sm="3" lg="2">
                    <p-input
                        label="Extension"
                        v-model="customer.secondaryPhone.extension"
                        rules="max:6"
                    />
                </b-col>
                <p-feature-flag name="Customer Profile">
                    <b-col class="mt-3 pt-3 mb-3 pb-3">
                        <p-checkbox
                            :use-switch="true"
                            label="Active"
                            v-model="customer.isActive"
                        />
                    </b-col>
                </p-feature-flag>
            </b-row>
            <b-row>
              <b-col>
                <p-address-input
                    label="Contact Address"
                    v-model="customer.defaultBillingAddress"
                />
              </b-col>
              <b-col>
                <p-address-input
                    label="Shipping Address"
                    v-model="customer.defaultShippingAddress"
                    same-as-address-name="Contact"
                    :same-as-address="customer.defaultBillingAddress"
                />
              </b-col>
            </b-row>
             <p-card>
                        <h4>
                            Internal Customer Notes
                            <span
                                class="text-normal text-sm"
                                v-if="customer.customerNote.noteId"
                            >
                                Last updated
                                {{
                                    customer.customerNote.modifiedDate ==
                                    null
                                        ? 'unknown'
                                        : datetimeToLocalTimeFormatter(
                                              customer.customerNote
                                                  .modifiedDate
                                          ).replace(', ', ' at ')
                                }}
                                by
                                {{
                                    customer.customerNote.userId == null
                                        ? 'Unknown'
                                        : customer.customerNote.user
                                              .displayName
                                }}.
                            </span>
                        </h4>
                        <p-text-area
                            :shortExpandableFormat="true"
                            :placeholder="
                                'Comments written here are not visible to the customer'
                            "
                            v-model="customer.customerNote.text"
                        />
                    </p-card>
        </p-card>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        customer: {
            type: Object,
            required: true
        },
        isBusy: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        datetimeToLocalTimeFormatter: datetimeToLocalTimeFormatter
    }
};
import { 
    datetimeToLocalTimeFormatter
} from '@/components/Common/Formatters.js';
</script>

