<template>
<p-modal name="exportInventoryByDccModal"
                 label="Export Inventory By DCC"
                 ref="exportInventoryByDccModal"
                 size="lg"
                 @show="loadParameters">
            <template v-slot:default>
                <div class="container-fluid content-wrapper">
                    <b-row cols="1" cols-md="2">
                        <b-col>
                            <p-input label="Fixture"
                                     v-model="reportParameters.fixture" />
                            <p-input label="Area"
                                     v-model="reportParameters.area" />
                            <p-select label="Summary or Detail"
                                      :options="includeDetailsOptions"
                                      v-model="reportParameters.includeDetails" />
                            <p-checkbox size="md"
                                        v-show="reportParameters.includeDetails"
                                        label="Include fixture/area detail"
                                        v-model="
                                    reportParameters.includeFixtureAreaDetail
                                " />
                            <p-checkbox size="md"
                                        label="Include inventory not counted"
                                        v-model="
                                    reportParameters.includeInventoryNotCounted
                                " />
                            <p-checkbox size="md"
                                        label="Only include inventory where variance is not zero"
                                        v-model="
                                    reportParameters.includeOnlyNonZeroVariance
                                " />
                        </b-col>
                        <b-col>
                            <p-card class="mb-2 pl-2 py-2" no-body>
                                <label class="text-sm"
                                       v-if="dccSelectorHelpText">{{ dccSelectorHelpText }}</label>
                                <DCCsSelector class="container-fluid"
                                              @selection-change="
                                        reportParameters.dccIds = $event
                                    " />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:modal-footer>
                <b-row>
                    <b-col>
                        <b-overlay :show="isBusy"
                                   rounded
                                   opacity="0.5"
                                   spinner-small
                                   spinner-variant="primary"
                                   class="d-inline-block">
                            <b-dropdown text="Export"
                                        variant="outline-primary">
                                <b-dropdown-item @click="exportInventoryByDccReport('pdf')">PDF</b-dropdown-item>
                                <b-dropdown-item @click="exportInventoryByDccReport('csv')">CSV</b-dropdown-item>
                            </b-dropdown>
                        </b-overlay>
                    </b-col>
                </b-row>
            </template>
        </p-modal>
</template>

<script>

import reports from './reports.js';

export default {
    props: {},
    mixins: [reports],
    data() {
        return {
            includeDetailsOptions: [
                { text: 'Summary', value: false },
                { text: 'Detail', value: true }
            ],
        };
    },
     components: {
    },
    methods: {

        exportInventoryByDccReport(type) {
            let destination = type === 'pdf' ? '/physicalInventory/exportInventoryByDcc' : 'physicalInventory/exportInventoryByDccCsv';
            let message = `Inventory By DCC report generated successfully.`;
            let fileName = `Inventory By DCC ${this.reportParameters.includeDetails ? 'Detail' : 'Summary'}`;
            this.exportReport(type, destination, message, fileName);
        },

        show() {
            this.$refs.exportInventoryByDccModal.show();
        },
    }
};

</script>

<style scoped></style>
