<template>
    <div>
        <SearchScreen
            title="Data Exchange Log"
            api-destination="dataexchange"
            search-destination="searchlog"
            :filters="filters"
            :filterBy="filterBy"
            :fields="fields"
            :useIsActiveCheckbox="false"
            ref="search"
        >
            <template v-slot:actionButtons>
                <router-link to="/dataexchange" class="btn btn-outline-primary"
                    >Back to Data Exchange</router-link
                >
                <b-overlay
                    :show="isBusy"
                    rounded
                    opacity="0.5"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block pl-3"
                >
                    <p-button variant="outline-primary" type="button" @click="exportJobLog()"
                        >Export (CSV)</p-button
                    >
                </b-overlay>
            </template>

            <template v-slot:belowSearch>
                <b-form>
                    <b-row cols="1" cols-sm="4" class="ml-0 mr-0">
                        <b-col>
                            <p-select
                                class="search-filter search-by-select"
                                no-selection-message="Type (All)"
                                v-model="filters.typeFilter"
                                data-type="jobTypes"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                class="search-filter search-by-select"
                                v-model="filters.statusFilter"
                                :options="statusFilter"
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                class="search-filter search-by-select"
                                v-model="filters.resultFilter"
                                :options="resultFilter"
                            />
                        </b-col>
                    </b-row>
                </b-form>
            </template>
            <template v-slot:cell(message)="{ value, item }">
                <div v-if="item.result">
                    Job executed Successfully
                </div>
                <div v-else>
                    {{ value }}
                </div>
            </template>
            <template v-slot:cell(expand)="{ value, item }">
                <div>
                    <div>
                        <p-button
                            variant="outline-primary"
                            @click="expandMessage(item)"
                            >Expand</p-button
                        >
                    </div>
                </div>
            </template>
        </SearchScreen>
        <p-modal
            name="JobMessageModal"
            :label="'Job Message (' + this.jobName + ')'"
            size="xl"
        >
            <template v-slot:default>
                <div class="container-fluid content-wrapper">
                    <b-row cols="1">
                        <b-col class="messageDetails">
                            <ul>
                                <li
                                    v-for="message in errorMessages"
                                    :key="message"
                                >
                                    {{ message }}
                                </li>
                            </ul>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div></div>
            </template>
        </p-modal>
    </div>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import { datetimeToLocalTimeFormatter } from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'DataExchangeLogFilter',
            isBusy: false,
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'runDate',
                sortDesc: true,
                typeFilter: null,
                statusFilter: 'Status (All)',
                resultFilter: 'Result (All)'
            },
            filterBy: ['Job Name'],
            statusFilter: ['Status (All)', 'Active', 'InActive'],
            resultFilter: [
                'Result (All)',
                'Success',
                'Partial Success',
                'Fail'
            ],
            fields: [
                {
                    key: 'name',
                    label: 'Job Name',
                    sortable: true
                },
                {
                    key: 'runDate',
                    label: 'Run Date',
                    formatter: datetimeToLocalTimeFormatter,
                    sortable: true
                },
                {
                    key: 'cronJobType',
                    label: 'Type',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                },
                {
                    key: 'isActive',
                    label: 'Status',
                    formatter: value => {
                        return value ? 'Active' : 'Inactive';
                    },
                    sortable: true
                },
                {
                    key: 'result',
                    label: 'Result',
                    sortable: true
                },
                {
                    key: 'expand',
                    label: ''
                }
            ],
            errorFields: [
                {
                    key: 'error',
                    label: 'Error Message',
                    sortable: true
                }
            ],
            jobName: null,
            errorMessages: []
        };
    },
    methods: {
        expandMessage(item) {
            this.messageDetail = item.message;
            this.jobName = item.name;
            axios
                    .get('dataexchange/getlogmessage', {
                        params: {
                            id:item.cronJobLogId
                        }
                    })
                    .then(resp => {
                        this.errorMessages=resp.data;
                        this.$bvModal.show('JobMessageModal');
                        navigator.clipboard.writeText(this.errorMessages);
                    });           

        },
        copyText() {
            
        },
        async exportJobLog() {
            let apiUrl = '/dataexchange/exportjoblog';
                this.isExporting = true;
                axios
                    .post(
                        apiUrl,
                        {
                        automatic: this.filters.automatic,
                        searchBy: this.filters.searchBy,
                        StatusFilter: this.filters.statusFilter,
                        ResultFilter: this.filters.resultFilter,
                        TypeFilter: this.filters.typeFilter ?? 0,
                        sortBy: this.filters.sortBy,
                        sortDesc: this.filters.sortDesc
                        },
                        { responseType: 'blob' }
                    )
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            `JobLog Search.csv`,
                            'application/csv'
                        );
                        this.$toasted.global
                            .app_success(
                                `JobLog Search report generated successfully.`
                            )
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isExporting = false;
                    });
            }
    },
    components: {
        SearchScreen
    }
};
</script>

<style scoped lang="scss">
.search-filter /deep/ .custom-select {
    height: 43px;
}
.search-filters {
    margin-bottom: 10px;
}
.messageDetails {
    max-height: 300px;
    overflow-y: auto;
}
</style>
