<template>
    <div class="container-fluid content-wrapper">
        <b-row class="content-heading">
            <b-col>
                <div>Page Layout</div>
            </b-col>
        </b-row>
        <h3>Form column sizes, wrapping, and ordering</h3>
        <example-card header="Simple form with few inputs and a single column">
            <template v-slot:description>
                Takes up half the width for lg and xl screens. For smaller
                screens it fills the full width
                <pre style="color:#f532e5"><code>
                &lt;b-row <u>cols=&quot;1&quot; cols-lg=&quot;</u>2&quot;&gt;
                    &lt;b-col&gt;
                        &lt;p-input label=&quot;Name&quot; /&gt;
                        &lt;p-input label=&quot;Description&quot; /&gt;
                    &lt;/b-col&gt;
                &lt;/b-row&gt;</code></pre>
            </template>
            <template v-slot:example>
                <b-row cols="1" cols-lg="2">
                    <b-col>
                        <p-input label="Name" />
                        <p-input label="Description" />
                        <p-input label="Abbreviation" />
                        <p-checkbox label="Active" class="pb-4" />
                    </b-col>
                </b-row>
            </template>
        </example-card>
        <example-card
            header="Larger form with more inputs and multiple columns"
        >
            <template v-slot:description>
                Renders 1 column on xs, 2 columns on sm through lg, and 4
                columns on xl.
                <br /><br />Most desktop users will probaly see the xl
                rendering. Full screen windows on 720 and 1080 monitors are
                considered xl. Windows filling half the width on a 2K monitor
                are also xl. Half width browser windows on 1080 will see md
                rendering (my geuss for second most common). <br />See:
                <b-link
                    href="https://bootstrap-vue.org/docs/components/layout#row-columns"
                    >Grid Options (screen breakpoints)</b-link
                >

                <br /><br />NOTE: Only use a single row tag, the wrapping will be
                handled for you based on the column counts you set.
                <br />
                <pre style="color:#f532e5"><code>
                &lt;b-row <u>cols=&quot;1&quot; cols-sm=&quot;2&quot; cols-xl=&quot;4&quot;</u>&gt;
                    &lt;b-col&gt;
                        &lt;p-input label=&quot;Name&quot; /&gt;
                    &lt;/b-col&gt;
                    ...yadda yadda yadda...
                    &lt;b-col&gt;
                        &lt;p-input label=&quot;Edition&quot; /&gt;
                    &lt;/b-col&gt;
                &lt;/b-row&gt;
        </code></pre>
                The col attribute defines the number of columns to be rendered
                from xs and up. We add cols-sm and cols-xl to set the number of
                columns at those respective sizes and above. <br />See:
                <b-link
                    href="https://bootstrap-vue.org/docs/components/layout#row-columns"
                    >Row Columns</b-link
                >
            </template>
            <template v-slot:example>
                <b-row cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-input label="Name" />
                    </b-col>
                    <b-col>
                        <p-input label="Description" />
                    </b-col>
                    <b-col>
                        <p-input label="Email" />
                    </b-col>
                    <b-col>
                        <p-input label="Phone" />
                    </b-col>
                    <b-col>
                        <p-input label="Fax" />
                    </b-col>
                    <b-col>
                        <p-input label="ISBN" />
                    </b-col>
                    <b-col>
                        <p-input label="Edition" />
                    </b-col>
                </b-row>
            </template>
        </example-card>

        <example-card
            header="Custom wrapping large form with conditionally rendered b-col"
        >
            <template v-slot:description>
                Generally uneeded, but sometimes it is best to wrap inputs
                before they naturally wrap to a new line on certain screen sizes
                (example: keeping start and end dates on the same line). An easy
                way to do this is with a conditional column. This works well
                when there are only one or two exceptions amongst many inputs
                that wrap properly without modification. <br /><br />The
                following example uses this method to keep start/end date and
                Phone/Fax together during wrapping.
                <br />
                <pre style="color:#f532e5"><code>
                &lt;b-col <u>class="d-lg-none"</u>>hide on screens wider than lg&lt;/b-col&gt;
                &lt;b-col <u>class="d-none d-lg-block"</u>&gt;hide on screens smaller than lg&lt;/b-col&gt;</code></pre>

                See:
                <b-link
                    href="https://getbootstrap.com/docs/4.2/utilities/display/"
                    >Display Property * Booststrap</b-link
                >
            </template>
            <template v-slot:example>
                <b-row cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-input label="Name" />
                    </b-col>
                    <!-- push the start/end dates onto same line on small screens -->
                    <b-col class="d-xl-none"></b-col>
                    <b-col>
                        <p-datepicker label="Start Date" />
                    </b-col>
                    <b-col>
                        <p-datepicker label="End Date" />
                    </b-col>

                    <!-- keep phone and fax together on xl screens -->
                    <b-col class="d-none d-xl-block"></b-col>
                    <b-col>
                        <p-input label="Phone" />
                    </b-col>
                    <b-col>
                        <p-input label="Fax" />
                    </b-col>
                    <b-col>
                        <p-input label="ISBN" />
                    </b-col>
                    <b-col>
                        <p-input label="Edition" />
                    </b-col>
                </b-row>
            </template>
        </example-card>

        <example-card header="Setting column width on b-col">
            <template v-slot:description>
                If setting the number of columns at the row level is not
                flexible enough, this can be configured on the b-col instead.
                <br />In this example the two columns split space 50-50 on small
                screens, and 25-75 on large.
                <pre style="color:#f532e5"><code>
                &lt;b-row&gt;
                    &lt;b-col <u>cols=&quot;12&quot; sm=&quot;6&quot; xl=&quot;3&quot;</u>&gt;
                        &lt;p-select label=&quot;Print Shelf Tag&quot; /&gt;
                    &lt;/b-col&gt;
                    &lt;b-col&gt;
                        &lt;p-text-area label=&quot;Shelf Tag Comment&quot; /&gt;
                    &lt;/b-col&gt;
                &lt;/b-row&gt;
                </code></pre>
            </template>
            <template v-slot:example>
                <b-row>
                    <b-col cols="12" sm="6" xl="3">
                        <p-select label="Print Shelf Tag" :options="emptyOptions" />
                    </b-col>
                    <b-col>
                        <p-text-area label="Shelf Tag Comment" />
                    </b-col>
                </b-row>
            </template>
        </example-card>
        <br />
        <h3>Alignment within rows</h3>
        <example-card
            header="Left aligned element with right aligned element using remaining space"
        >
            <template v-slot:description>
                This lets you set the size of the left element as desired, with
                the right aligned element taking the remaining space.
                <pre style="color:#f532e5"><code>
                &lt;b-row&gt;
                    &lt;b-col cols=&quot;9&quot; sm=&quot;6&quot; xl=&quot;3&quot;&gt;
                        &lt;p-select label=&quot;Status&quot; /&gt;
                    &lt;/b-col&gt;
                    &lt;b-col <u>cols=&quot;auto&quot; class=&quot;ml-auto&quot;</u>&gt;
                        &lt;p-button variant=&quot;outline-primary&quot;&gt;Print&lt;/p-button&gt;
                    &lt;/b-col&gt;
                &lt;/b-row&gt;
				</code></pre>
                See:
                <b-link
                    href="https://bootstrap-vue.org/docs/components/layout#margin-utilities-on-columns"
                    >Margin Utilities on Columns</b-link
                >
            </template>
            <template v-slot:example>
                <b-row>
                    <b-col cols="9" sm="6" xl="3">
                        <p-select label="Status" :options="emptyOptions" />
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <p-button variant="outline-primary">Print</p-button>
                    </b-col>
                </b-row>
            </template>
        </example-card>
        <example-card header="Centered Button Full Row">
            <template v-slot:description>
                Used for save buttons
                <pre style="color:#f532e5"><code>
                 &lt;b-row&gt;
                    &lt;b-col class=&quot; mb-3 <u>text-center</u>&quot;&gt;
                        &lt;b-button
                            variant=&quot;primary&quot;
                            type=&quot;submit&quot;
                            form=&quot;edit-form&quot;
                            &gt;Save&lt;/b-button
                        &gt;
                    &lt;/b-col&gt;
                &lt;/b-row&gt;
				</code></pre>
            </template>
            <template v-slot:example>
                <b-row>
                    <b-col class=" mb-3 text-center">
                        <b-button
                            variant="primary"
                            type="submit"
                            form="edit-form"
                            >Save</b-button
                        >
                    </b-col>
                </b-row>
            </template>
        </example-card>
        <example-card header="Reorder on different size screens">
            <template v-slot:description>
                This example reorders the inputs to keep the dates in the same
                row.
                <br />See:
                <b-link
                    href="https://getbootstrap.com/docs/4.5/utilities/flex/#order"
                    >Flex Order</b-link
                >
                <pre style="color:#f532e5"><code>
                &lt;b-row cols=&quot;1&quot; cols-sm=&quot;2&quot; cols-xl=&quot;4&quot;&gt;
                    &lt;b-col class=&quot;order-0 order-sm-0 order-xl-0&quot;&gt;
                        &lt;p-input label=&quot;First&quot; /&gt;
                    &lt;/b-col&gt;
                    &lt;b-col class=&quot;<u>order-1 order-sm-2 order-xl-1</u>&quot;&gt;
                        &lt;p-datepicker label=&quot;Second&quot; /&gt;
                    &lt;/b-col&gt;
                    &lt;b-col class=&quot;order-2 order-sm-3 order-xl-2&quot;&gt;
                        &lt;p-datepicker label=&quot;Third&quot; /&gt;
                    &lt;/b-col&gt;
                    &lt;b-col class=&quot;order-3 order-sm-1 order-xl-3&quot;&gt;
                        &lt;p-input label=&quot;Fourth&quot; /&gt;
                    &lt;/b-col&gt;
                &lt;/b-row&gt;
				</code></pre>
            </template>
            <template v-slot:example>
                <b-row cols="1" cols-sm="2" cols-xl="4">
                    <b-col class="order-0 order-sm-0 order-xl-0">
                        <p-input label="First" />
                    </b-col>
                    <b-col class="order-1 order-sm-2 order-xl-1">
                        <p-datepicker label="Second" />
                    </b-col>
                    <b-col class="order-2 order-sm-3 order-xl-2">
                        <p-datepicker label="Third" />
                    </b-col>
                    <b-col class="order-3 order-sm-1 order-xl-3">
                        <p-input label="Fourth" />
                    </b-col>
                </b-row>
            </template>
        </example-card>

        <example-card header="Left and right aligned text in same row">
            <template v-slot:description>
                Allows the left and right text take up as much space as possible
                to stay on the same row. If needed the right text will move to
                the second line.
                <br />See:
                <b-link
                    href="https://getbootstrap.com/docs/4.5/utilities/float/"
                    >Float</b-link
                >
                <pre style="color:#f532e5"><code>
                &lt;b-row&gt;
                    &lt;b-col cols=&quot;12&quot; lg=&quot;6&quot; xl=&quot;3&quot;&gt;
                            &lt;span&gt;Here is left text&lt;/span&gt;
                            &lt;span class=&quot;<u>float-right</u> pl-2&quot;&gt;
                                Here is right text
                            &lt;/span&gt;
                        &lt;/b-col&gt;
                &lt;/b-row&gt;
				</code></pre>
            </template>
            <template v-slot:example>
                <b-row>
                    <b-col cols="12" lg="6" xl="3">

                            <span>Here is left text</span>
                            <span class="float-right pl-2"
                                >Here is right text</span
                            >
    </b-col
                    >
                </b-row>
            </template>
        </example-card>
    </div>
</template>

<script>
import ExampleCard from './ExampleCard.vue';

export default {
    data() {
        return {
            emptyOptions: []
        };
    },
    components: {
        ExampleCard
    }
};
</script>
