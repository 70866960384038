<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <div>
            <p-card>
                <b-row cols-sm="2"  cols="1" cols-xl="4" class="mt-3 ml-1 mr-2">
                    <b-col>
                        <p-input label="Account Number"
                                 v-model="customer.number"
                                 readonly />
                    </b-col>
                    <b-col>
                        <p-input label="Student ID"
                                 v-model="customer.studentAccount"
                                 readonly />
                    </b-col>

                    <b-col>
                        <p-input label="First Name"
                                 v-model="customer.firstName"
                                 readonly />
                    </b-col>
                    <b-col>
                        <p-input label="Last Name"
                                 v-model="customer.lastName"
                                 readonly />
                    </b-col>
                </b-row>
                <p-search-table api-destination="customers"
                                :filters="filters"
                                sort-by="transactionDate"
                                :fields="fields"
                                search-destination="searchTransactionHistory"
                                :enable-row-selection="false"
                                :sort-desc="true">
                    <template v-slot:cell(view)="{ value, item }">
                        <b-button variant="outline-primary" size="sm" @click='getTransaction(item)' v-b-modal.customerTransactionModal>View</b-button>
                    </template>
                </p-search-table>

            </p-card>
        </div>
        <TransactionDetailModal :transaction="selectedTransaction" />
    </p-secure>
</template>

<script>
    import { datetimeToLocalTimeFormatter, currencyFormatter } from '../../components/Common/Formatters.js';
    import TransactionDetailModal from '../../components/TransactionDetailModal.vue';
    import axios from 'axios';

    export default {
        props: {
            customer: [Object],
        },
        data() {
            return {
                selectedTransaction: null,
                filters: {
                    automatic: "",
                    searchBy: 'All',
                    customerId: 0,
                    options: [
                        'All',
                        'Transaction ID',
                        'Store',
                        'POS Device',
                        'Cashier',
                        'SKU',
                        'Description',
                        'Serial #'
                    ]
                },
                fields: [
                    {
                        key: 'view',
                        label: '',
                        class: 'tcw100'
                    },
                    {
                        label: 'Transaction ID',
                        key: 'transactionId',
                        sortable: true
                    },
                    {
                        label: 'Date & Time',
                        key: 'transactionDate',
                        sortable: true,
                        formatter: datetimeToLocalTimeFormatter
                    },
                    {
                        label: 'Location',
                        key: 'transactionLocationName',
                        sortable: true
                    },
                    {
                        label: 'POS Device Name',
                        key: 'transactionPOSDeviceName',
                        sortable: true
                    },
                    {
                        label: 'Cashier',
                        key: 'transactionCashier',
                        sortable: true
                    },
                    {
                        label: 'Transaction Type',
                        key: 'transactionType',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (item.transactionType == 'Web Order') {
                                return 'Web Order';
                            } else if (item.transactionType == 'Mail Order') {
                                return 'Mail Order';
                            } else {
                                return 'POS';
                            }
                        }
                    },
                    {
                        label: 'Total',
                        key: 'transactionTotal',
                        sortable: true,
                        tdClass: 'text-right',
                        formatter: currencyFormatter
                    }
                ]
            };
        },
        methods:
        {
            getTransaction(transaction) {
                this.selectedTransaction = null;
                axios.get("customers/getTransaction", { params: { transactionId: transaction.transactionId, saleFulfillmentId: transaction.saleFulfillmentId } })
                    .then(resp => {
                        this.selectedTransaction = resp.data;
                        this.$bvModal.show("customerTransactionModal");
                    });
            }
        },
        created: function () {

            this.filters.customerId = this.customer.customerId;

        },
        components: {
            TransactionDetailModal
        }

    };
</script>
