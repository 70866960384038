<template>
    <aside class="aside-container">
        <!-- START Sidebar (left)-->
        <div class="aside-inner">
            <nav class="sidebar" data-sidebar-anyclick-close="">
                <!-- START sidebar nav-->
                <ul class="sidebar-nav pl-0">
                    <!-- START user info-->
                    <li class="has-user-block" v-if="false">
                        <div id="user-block">
                            <div class="item user-block">
                                <!-- User picture-->
                                <div class="user-block-picture" >
                                    <router-link to="/myProfile" active-class="active">
                                        <div class="user-block-status">
                                            <img class="img-thumbnail rounded-circle" src="/img/user/empty.png" alt="Avatar" width="60" height="60">
                                        </div>
                                    </router-link>
                                </div>
                                <div class="user-block-info">
                                    <span v-if="userName" class="user-block-name">Hello, {{userName}}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- END user info-->
                    <!-- Iterates over all sidebar items-->
                    <template v-for="item in filteredMenu">
                        <!-- Heading -->
                        <li class="nav-heading" v-if="item.heading" :key="item.name">
                            <span>{{ item.heading}}</span>
                        </li>
                        <!-- Single Menu -->
                        <router-link :to="item.path" v-if="!item.heading && !item.submenu" :key="item.name" custom v-slot="{ navigate }">
                            <li @click="navigate" @keypress.enter="navigate" role="link"  :class="routeActiveClass(item.path)" active-class="active">
                                <a :title="item.name" :href="item.path">
                                    <span v-if="item.label" :class="'float-right badge badge-'+item.label.color">{{item.label.value}}</span>
                                    <em :class="item.icon"></em>
                                    <span>{{item.name}}</span>
                                    <div class="text-sm">{{item.shortName || item.name}}</div>
                                </a>
                            </li>
                        </router-link>
                        <!-- Menu With Subitems -->
                        <li :class="buildClasses(item)" v-if="!item.heading && item.submenu" :key="item.name">
                            <a :title="item.name" @click.prevent="toggleItemCollapse(item.name)" href="#">
                                <span v-if="item.label" :class="'float-right badge badge-'+item.label.color">{{item.label.value}}</span>
                                <em :class="item.icon"></em>
                                <span>{{item.name}}</span>
                                <div class="text-sm">{{item.shortName || item.name}}</div>
                            </a>
                            <b-collapse tag="ul" class="sidebar-nav sidebar-subnav" id="item.name" v-model="collapse[item.name]">
                        <li class="sidebar-subnav-header">{{item.name}}</li>
                        <template v-for="sitem in item.submenu">
                            <li v-if='sitem.hasLink' :key="sitem.name" >
                                <a :href='sitem.link' active-class="active" :key="sitem.name" tag="li" target="_blank">
                                    <span v-if="sitem.label" :class="'float-right badge badge-'+sitem.label.color">{{sitem.label.value}}</span>
                                    <span>{{sitem.name}}</span>
                                </a>
                            </li>
                            <router-link v-else :to="sitem.path" custom :key="sitem.name" v-slot="{ navigate }">
                                <li @click="navigateClick($event,sitem.path)" @keypress.enter="navigate" role="link"  active-class="active" :class="routeActiveClass(sitem.path)">
                                    <a :title="sitem.name" :href="sitem.path">
                                        <span v-if="sitem.label" :class="'float-right badge badge-'+sitem.label.color">{{sitem.label.value}}</span>
                                        <span>{{sitem.name}}</span>
                                    </a>
                                </li>
                            </router-link>
                        </template>
                        </b-collapse>
                        </li>
                    </template>
                </ul>  
                <ul class="navbar-nav mr-auto flex-row nav-bar-toggle">
                    <li class="nav-item">
                        <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
                        <a href="" class="nav-link d-none d-md-block d-lg-block d-xl-block" @click.prevent="toggleAsideCollapsed">
                            <div class="expand-icon">
                                <em class="fas fa-angle-double-right"></em>
                            </div>
                            <div class="collapse-icon">
                                <em class="fas fa-angle-double-left"></em>
                            </div>
                        </a>
                        <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
                        <a href="" class="nav-link sidebar-toggle d-md-none" @click.prevent="toggleOffcanvas">
                            <div class="expand-icon">
                                <em class="fas fa-angle-double-right"></em>
                            </div>
                            <div class="collapse-icon">
                                <em class="fas fa-angle-double-left"></em>
                            </div>
                        </a>
                    </li>
                </ul>
                <!-- END sidebar nav-->
            </nav>
        </div>
        <!-- END Sidebar (left)-->
    </aside>
</template>

<script>

    import SidebarRun from './Sidebar.run.js';
    import SidebarToggle from './SidebarToggle.js';
    import Menu from '../../menu.js';
    import { mapActions } from 'vuex';
    import axios from 'axios';

    export default {
        name: 'Sidebar',
        mixins: [SidebarToggle],
        data() {
            return {
                Menu,
                collapse: this.buildCollapseList(),
                posAdminURL: null
            }
        },
        async mounted() {
            //create link for POS admin.
            await axios.get('utility/tenantdatabasename').then(resp => {
                this.posAdminURL = 'https://pos.prismrbs.com/1.9/Web/Account/Login?SiteId=' + resp.data;
            });
            await this.fetchFeatureFlags();

            if (this.$store.getters.getSingleLocationId === null) {
                axios.get('selectlists/singlelocationid').then((resp) => {
                    this.$store.commit('updateSingleLocationId', resp.data);
                });
            }

            SidebarRun(this.$router, this.closeSidebar.bind(this));
        },
        computed: {
            hasMultipleLocations(){                
                return this.$store.getters.getLocations?.length > 1;
            },
            currentUser() {
                return this.$store.getters.getCurrentUser;
            },
            userName(){
                return this.currentUser.profile.name;
            },
            roles(){
                return this.$store.getters.getCurrentUserRoles;
            },
            filteredMenu(){
                var roles = this.roles;
                if(!roles || roles.length == 0){
                    return null;
                }

                let fullMenu = JSON.parse(JSON.stringify(Menu));
                return fullMenu.filter(mi => {
                    var result = this.filterMenuItem(mi, roles);

                    if(result && mi.submenu){
                        //cloning the menu above, otherwise, this would override the source submenu
                        //on initial load (where no flags are avaliable yet).  Then when it does the
                        //second pass with flags, it isn't present to be avaliable for inclusion.
                        mi.submenu = mi.submenu.filter(smi => {
                            return this.filterMenuItem(smi, roles);
                        })
                        //if no submenu items are present due to security or feature flag filtering,
                        //then don't include it in the navigation.
                        result = mi.submenu.length != 0
                    }

                    return result;
                })
            }
        },
        watch:{
            $route (){
                this.closeSidebar()
            }
        },
        methods: {
            ...mapActions(['fetchFeatureFlags']),
            navigateClick(e, destination){
                 e.preventDefault();
                if (e.ctrlKey === true) {
                    // if click + ctrl
                    window.open(destination, '_blank');
                }
                else{
                    this.$router.push(destination)
                }
            },
            closeSidebar() {
                this.$store.commit('changeSetting', { name: 'asideToggled', value: false })
            },
            buildCollapseList() {
                /** prepare initial state of collapse menus. Doesnt allow same route names */
                let collapse = {};
                Menu
                    .filter(({heading}) => !heading)
                    .forEach(({name, path, submenu}) => {
                        collapse[name] = this.isRouteActive(submenu ? submenu.map(({path})=>path) : path)
                    });
                return collapse;
            },
            buildClasses(item) {
                let classes = this.routeActiveClass(this.getSubRoutes(item));
                classes.hide = (item.singleLocationFilter && !this.selectedLocationMatchesDefaultLocation()) ?? false;
                return classes;
            },
            getSubRoutes(item) {
                return item.submenu.map(({path}) => path)
            },
            isRouteActive(paths) {
                paths = Array.isArray(paths) ? paths : [paths];
                return paths.some(p => this.$route.path.indexOf(p) > -1)
            },
            routeActiveClass(paths) {
                return { 'active': this.isRouteActive(paths) }
            },
            toggleItemCollapse(collapseName) {
                for (let c in this.collapse) {
                    if (this.collapse[c] === true && c !== collapseName)
                        this.collapse[c] = false
                }
                this.collapse[collapseName] = !this.collapse[collapseName]
            },
            filterMenuItem(mi){
                if(mi.hasLink)
                {
                    mi.link = this.posAdminURL;
                }
                return this.checkSecurityRoles(mi) && this.checkFeatureFlags(mi) && this.checkAttributes(mi);
            },
            checkAttributes(mi){
                if(mi.requireMultiLocation == true && ! this.hasMultipleLocations){
                    return false;
                }
                return true;
            },
            checkSecurityRoles(mi){
                if(mi.requireAdmin == true){
                    return this.$store.getters.currentUserHasRole('Admin');
                }

                if(mi.requireRole){
                    if(this.$store.getters.currentUserHasRole('Admin')) {return true;}

                    for (let i = 0; i < mi.requireRole.length; i++) {
                        if(this.$store.getters.currentUserHasRole(mi.requireRole[i])){
                            return true;
                        }
                    }

                    return false;
                }

                if(mi.prismRBSOnly){
                    return this.$store.getters.currentUserHasRole('Support') || this.$store.getters.currentUserHasRole('Developer');
                }

                return true;
            },
            checkFeatureFlags(mi){
                if(mi.featureFlag){
                    return this.$store.getters.featureFlagEnabled(mi.featureFlag);
                }
                
                return true;
            },
            selectedLocationMatchesDefaultLocation() {                   
                return this.$store.getters.getSingleLocationId == this.$store.getters.getSelectedLocationId;
            }
        }
    }
</script>

<style scoped>
.hide {
    display: none;
}
</style>