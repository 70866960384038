<template>
    <div>
        <SearchScreen
            title="Products"
            api-destination="products"
            :filters="filters"
            :filterBy="filterByOptions"
            :fields="fields"
            id-key="sku"
            add-bulk-is-active-toggle
            :edit-url-func="
                item => `/products/${item.isBook ? 'tx' : 'gm'}/${item.sku}`
            "
        >
            <template v-slot:actionButtons>
                <b-dropdown variant="primary" text="Add">
                    <template v-slot:button-content>
                        <b-icon-plus></b-icon-plus> Add
                    </template>
                    <b-dropdown-item v-b-modal.addBookModal>
                        Add Book
                    </b-dropdown-item>
                    <p-feature-flag name="Common GM">
                        <b-dropdown-item
                            v-b-modal.addCommonGMModal
                            @click="checkMatrixGMProduct(false)"
                        >
                            Add Common GM Product
                        </b-dropdown-item>
                    </p-feature-flag>
                    <p-feature-flag name="Common GM">
                        <b-dropdown-item
                            v-b-modal.addCommonGMModal
                            @click="checkMatrixGMProduct(true)"
                        >
                            Add Matrix GM Product
                        </b-dropdown-item>
                    </p-feature-flag>
                </b-dropdown>
            </template>
            <template v-slot:appendSlot>
                <div class="append-element">
                    <b-form-radio-group
                        class="rounded-radio-group"
                        v-model="filters.productType"
                        :options="filters.productOptions"
                        button-variant="outline-primary"
                        size="xs"
                        name="radio-btn-outline"
                        buttons
                    ></b-form-radio-group>
                </div>
            </template>
        </SearchScreen>
        <p-modal
            size="sm"
            name="addBookModal"
            label="Add A New Book"
            hide-footer
            no-close-on-backdrop
        >
            <b-row>
                <b-col>
                    <router-link
                        to="/products/tx/new"
                        class="btn btn-outline-primary"
                        >Add New</router-link
                    >
                </b-col>
                <b-col>
                    <ProductMultiSelectQuickSearch
                        label="Adopt From Buyer's Guide"
                        name="adopt"
                        single-select-button-label="Adopt"
                        search-destination="buyersguidesearch"
                        field-set="buyersGuideFields"
                        default-sort="author"
                        single-select
                        hide-buttons
                        :remove-filter-options="[
                            'SKU',
                            'Description',
                            'Catalog #',
                            'Supplier',
                            'Barcode'
                        ]"
                        :add-filter-options="[
                            'Edition',
                            'Imprint',
                            'Copyright'
                        ]"
                        @selections="adoptSelection"
                    />
                </b-col>
            </b-row>
        </p-modal>
        <p-modal
            size="xl"
            name="addCommonGMModal"
            :label="
                isMatrixGMProduct
                    ? 'Add a General Merchandise (Matrix) Product'
                    : 'Add a General Merchandise (Common) Product'
            "
            @shown="loadData"
            no-close-on-backdrop
            @close="checkDirtyBeforeModalClose"
        >
            <template v-slot:default>
                <p-form ref="addGMForm">
                    <p-card title="" v-if="product">
                        <b-row>
                            <p-feature-flag name="Product Images">
                                <b-col cols="12" lg="auto">
                                    <p-image
                                        ref="generalImage"
                                        v-model="
                                            productFamily
                                                .onlineProfileProperties
                                                .images[0]
                                        "
                                        @newURL="newPrimaryURL"
                                    />
                                </b-col>
                            </p-feature-flag>
                            <b-col>
                                <b-row>
                                    <b-col cols="12" lg="8">
                                        <p-input
                                            label="Product Description"
                                            v-model="productFamily.description"
                                            rules="required|max:200"
                                        />
                                    </b-col>
                                    <b-col cols="12" lg="4">
                                        <p-select
                                            label="Product Type"
                                            :options="productTypes"
                                            v-model="productFamily.isMatrix"
                                            text-field="text"
                                            :disabled="true"
                                            rules="required"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" sm="6" xl="2">
                                        <p-number
                                            label="Selling Price"
                                            v-model="
                                                productInventory.sellingPrice
                                            "
                                            rules="required|min_value:0"
                                            input-type="money"
                                            @change="calculateMargin()"
                                            left-align
                                        >
                                        </p-number>
                                    </b-col>
                                    <b-col cols="12" sm="6" xl="2">
                                        <p-number
                                            label="Margin"
                                            v-model.number="
                                                productInventory.margin
                                            "
                                            rules="required|min_value:0"
                                            disabled="disabled"
                                            input-type="percent"
                                            left-align
                                        >
                                        </p-number>
                                    </b-col>
                                    <b-col cols="12" sm="6" xl="2">
                                        <p-number
                                            label="Cost"
                                            v-model="productInventory.cost"
                                            rules="required|min_value:0"
                                            input-type="money"
                                            @change="calculateMargin()"
                                            left-align
                                        >
                                        </p-number>
                                    </b-col>
                                    <b-col cols="12" lg="6">
                                        <p-advanced-select
                                            label="DCC"
                                            data-type="dcc"
                                            @change="dccChanged"
                                            v-model.number="product.dccId"
                                            :filter="dccFilter"
                                            rules="required"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" lg="6">
                                        <p-advanced-select
                                            label="Brand"
                                            data-type="brands"
                                            v-model="
                                                productFamily.productBrandId
                                            "
                                            add-empty-option
                                        >
                                            <template v-slot:append>
                                                <b-button
                                                    class="ml-0 px-1"
                                                    style="white-space:nowrap;"
                                                    v-b-modal.addBrand
                                                    >Add New</b-button
                                                >
                                            </template>
                                        </p-advanced-select>
                                    </b-col>

                                    <b-col cols="12" lg="6">
                                        <p-select
                                            data-type="priceTagTypes"
                                            label="Tag Type"
                                            v-model="product.priceTagTypeId"
                                        />
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" :lg="!isMatrixGMProduct ? 4 : 12">
                                <p-input
                                    label="Product Notes"
                                    v-model="productFamily.comment"
                                    rules="max:200"
                                />
                            </b-col>

                            <b-col cols="12" lg="4" v-if="!isMatrixGMProduct">
                                <p-advanced-select
                                    label="Size"
                                    name="productDetailSize"
                                    data-type="productVariants"
                                    :filter="'1'"
                                    v-model="product.gmVariant1Id"
                                    add-empty-option
                                >
                                </p-advanced-select>
                            </b-col>
                            <b-col cols="12" lg="4" v-if="!isMatrixGMProduct">
                                <p-advanced-select
                                    label="Color"
                                    name="productDetailColor"
                                    data-type="productVariants"
                                    v-model="product.gmVariant2Id"
                                    :filter="'2'"
                                    add-empty-option
                                >
                                </p-advanced-select>
                            </b-col>
                        </b-row>
                        <b-row v-if="isMatrixGMProduct">
                            <b-col cols="12" lg="6">
                                <p-advanced-select
                                    label="Variant Group 1"
                                    data-type="productVariantGroups"
                                    rules="required"
                                    @input="
                                        getProductVariants(
                                            $event,
                                            'variantgrp1'
                                        )
                                    "
                                    v-model="productFamily.gmVariantGroup1Id"
                                    :filter="filterVariantGroup1"
                                >
                                </p-advanced-select>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <p-advanced-select
                                    label="Variant Group 2"
                                    data-type="productVariantGroups"
                                    add-empty-option
                                    @input="
                                        getProductVariants(
                                            $event,
                                            'variantgrp2'
                                        )
                                    "
                                    v-model="productFamily.gmVariantGroup2Id"
                                    :disabled="!productFamily.gmVariantGroup1Id"
                                     :filter="filterVariantGroup2"
                                >
                                </p-advanced-select>
                            </b-col>
                        </b-row>
                        
                        <b-row v-if="isMatrixGMProduct">
                            <b-col cols="12" lg="6">
                                <p-table
                                    :items="productVariantGroupingsGrp1"
                                    :enable-row-selection="false"
                                    :fields="productVariantFieldsGrp1"
                                    :pagination="false"
                                    :showRecordCount="false"
                                    :showEmpty="false"
                                    :per-page="500"
                                >
                                    <template
                                        v-slot:cell(isActive)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <p-checkbox
                                            size="md"
                                            v-model="item.isActive"
                                            @change="calculateSKU()"
                                        ></p-checkbox>
                                    </template>
                                </p-table>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <p-table
                                    :items="productVariantGroupingsGrp2"
                                    :enable-row-selection="false"
                                    :fields="productVariantFieldsGrp2"
                                    :pagination="false"
                                    :showRecordCount="false"
                                    :showEmpty="false"
                                    :per-page="500"
                                >
                                    <template
                                        v-slot:cell(isActive)="{
                                            value,
                                            item
                                        }"
                                    >
                                        <p-checkbox
                                            size="md"
                                            v-model="item.isActive"
                                            @change="calculateSKU()"
                                        ></p-checkbox>
                                    </template>
                                </p-table>
                            </b-col>
                        </b-row>
                    </p-card>
                </p-form>
            </template>
            <template v-slot:modal-footer>
                <b-row>
                    <b-col>
                        <p-button
                            variant="outline-primary"
                            @click="$bvModal.hide('addCommonGMModal')"
                            class="mr-2"
                            :is-busy="generateSKUIsBusy"
                            >Cancel</p-button
                        >
                        <p-button
                            variant="primary"
                            @click="handleGenerateSKU"
                            class="float-right"
                            :is-busy="generateSKUIsBusy"
                            >Generate ({{ isMatrixGMProduct ? SKUCount : 1 }})
                            SKU{{ SKUCount > 1 ? 's' : '' }}</p-button
                        >
                    </b-col>
                </b-row>
            </template>
        </p-modal>
        <p-modal
            name="addBrand"
            label="Add Brand"
            size="md"
            no-close-on-backdrop
            no-close-esc
            @shown="showModal"
            ref="addBrandModal"
        >
            <template v-slot:default>
                <p-form ref="form">
                    <div>
                        <b-row>
                            <b-col>
                                <p-input
                                    label="Brand Name"
                                    vid="brandName"
                                    v-model="brandName"
                                    rules="required|max:50"
                                    ref="setFocus"
                                />
                            </b-col>
                        </b-row>
                    </div>
                </p-form>
            </template>

            <template v-slot:modal-footer>
                <b-row>
                    <b-col class=" mb-3 text-center">
                        <p-button
                            :is-busy="addingBrandIsBusy"
                            variant="outline-primary"
                            @click="handleAddBrand"
                            >OK</p-button
                        >
                    </b-col>
                </b-row>
            </template>
        </p-modal>
        <p-modal
            size="sm"
            name="addNewUsed"
            label="Create new or new and used?"
            hide-footer
            no-close-on-backdrop
        >
            <div v-if="itemToAdopt">
                <b-row>
                    <b-col>
                        <label>ISBN: </label>
                        <label class="ml-2" style="white-space: nowrap;">{{
                            itemToAdopt.isbn
                        }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Author: </label>
                        <label class="ml-2">{{ itemToAdopt.author }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Title: </label>
                        <label class="ml-2">{{ itemToAdopt.title }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Edition: </label>
                        <label class="ml-2">{{ itemToAdopt.edition }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Binding: </label>
                        <label class="ml-2">{{ itemToAdopt.binding }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Price: </label>
                        <label class="ml-2">{{
                            currencyFormatter(itemToAdopt.retail)
                        }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label>Imprint: </label>
                        <label class="ml-2">{{ itemToAdopt.imprint }}</label>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-select
                            data-type="suppliers"
                            label="Supplier"
                            v-model="itemToAdopt.supplierId"
                            rules="required"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-button
                            @click="newOnly"
                            :is-busy="isBusy"
                            variant="outline-primary"
                            >New</p-button
                        >
                    </b-col>
                    <b-col>
                        <p-button
                            @click="newAndUsed"
                            :is-busy="isBusy"
                            variant="outline-primary"
                            >New and Used</p-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-modal>
    </div>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import {
    bookVariantFormatter,
    currencyFormatter,
    integerFormatter
} from '../../components/Common/Formatters.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import pricing from '@/services/PricingCalculations.js';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [NavigationGuard, SearchStorage],
    data() {
        return {
            isBusy: false,
            generateSKUIsBusy: false,
            addingBrandIsBusy: false,
            filterName: 'ProductsSearchFilter',
            isMatrixGMProduct: false,
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'sku',
                sortDesc: true,
                productType: null,
                searchBy: 'All',
                productOptions: [
                    { text: 'Books', value: 'Books' },
                    { text: 'GM', value: 'GM' },
                    { text: 'Both', value: null }
                ]
            },
            productVariantGroupingsGrp1: [],
            productVariantGroupingsGrp2: [],
            productVariantFieldsGrp1: [
                {
                    key: 'isActive',
                    label: ''
                },
                {
                    key: 'name',
                    label: ''
                }
            ],
            productVariantFieldsGrp2: [
                {
                    key: 'isActive',
                    label: ''
                },
                {
                    key: 'name',
                    label: ''
                }
            ],
            fields: [
                {
                    key: 'author',
                    sortable: true,
                    label: 'Author/Title or Description'
                },
                {
                    key: 'gmVariant1',
                    label: 'Variant 1'
                },
                {
                    key: 'gmVariant2',
                    label: 'Variant 2'
                },
                {
                    key: 'defaultBarcode',
                    sortable: true,
                    label: 'Default Barcode',
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'supplierName',
                    sortable: true
                },
                {
                    key: 'sku',
                    sortable: true,
                    label: 'SKU'
                },
                {
                    key: 'bookVariant',
                    formatter: bookVariantFormatter,
                    sortable: true,
                    label: 'Type'
                },
                {
                    key: 'onOrder',
                    sortable: true,
                    label: 'On Order',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    formatter: (value) => integerFormatter(value, "-")
                },
                {
                    key: 'sellingPrice',
                    formatter: (value) => currencyFormatter(value, "-"),
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'stockOnHand',
                    sortable: true,
                    label: 'SOH',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    formatter: (value) => integerFormatter(value, "-")
                },
                {
                    key: 'copyright',
                    sortable: true
                },
                {
                    key: 'edition',
                    sortable: true
                },
                {
                    key: 'isActive',
                    sortable: true,
                    disabled: true
                }
            ],
            itemToAdopt: null,
            productFamily: null,
            product: null,
            productInventory: null,
            defaultSupplier: '',
            productTypes: [
                { value: false, text: 'Common' },
                { value: true, text: 'Matrix' }
            ],
            brandName: '',
            SKUCount: 0
        };
    },
    methods: {
        calculateSKU() {
            this.SKUCount =
                (this.productVariantGroupingsGrp1.filter(x => x.isActive)
                    .length == 0
                    ? 1
                    : this.productVariantGroupingsGrp1.filter(x => x.isActive)
                          .length) *
                (this.productVariantGroupingsGrp2.filter(x => x.isActive)
                    .length == 0
                    ? 1
                    : this.productVariantGroupingsGrp2.filter(x => x.isActive)
                          .length);
        },
        getProductVariants(id, groupName) {
            if (id == null) return;
            axios
                .get('productvariantgroup', {
                    params: { id: id }
                })
                .then(resp => {
                    if (resp.data != null) {
                        resp.data.productVariantGroupings = resp.data.productVariantGroupings.filter(
                            x => x.isActive == true
                        );

                        if (groupName == 'variantgrp1') {
                            this.productVariantGroupingsGrp1 =
                                resp.data.productVariantGroupings;
                        }

                        if (groupName == 'variantgrp2') {
                            this.productVariantGroupingsGrp2 =
                                resp.data.productVariantGroupings;
                        }

                        this.calculateSKU();
                    }
                });
        },
        checkMatrixGMProduct(matrixgmproduct) {
            this.isMatrixGMProduct = matrixgmproduct;
        },
        showModal() {
            this.brandName = '';
            this.$refs.setFocus.focus();
        },
        async loadData() {
            this.product=null;
            this.isBusy = true;
            this.productVariantGroupingsGrp1 = [];
            this.productVariantGroupingsGrp2 = [];
            this.SKUCount = 0;
            let dataPromise = axios.get('products/generalmerchandise/new');
            let paramPromise = axios.get('products/gmparameters');
            axios.all([dataPromise, paramPromise]).then(
                axios.spread(async (dataResp, paramResp) => {
                    this.parameters = paramResp.data;
                    this.productFamily = dataResp.data;
                    this.product =
                        this.productFamily.products.find(
                            x => x.sku == this.id
                        ) ?? this.productFamily.products[0];
                    this.productInventory =
                        this.product.productInventories.length > 0
                            ? this.product.productInventories[0]
                            : null;
                    this.$set(
                        this.productFamily,
                        'imageUrl',
                        unescape(
                            this.productFamily.onlineProfileProperties.images[0]
                                .dataURL
                        )
                    );
                    this.productFamily.packageTypeId = this.parameters.defaultPackageTypeId;
                    this.isMatrixGMProduct
                        ? (this.productFamily.isMatrix = true)
                        : (this.productFamily.isMatrix = false);
                    this.dataLoaded();
                })
            );
        },
        handleGenerateSKU: async function() {
            if (!(await this.$refs.addGMForm.validate())) return;
            this.productFamily.onlineProfileProperties.shortDescription = this.productFamily.description;
            this.generateSKUIsBusy = true;
            let variantGroup1 = this.productVariantGroupingsGrp1.filter(
                x => x.isActive
            );
            let variantGroup2 = this.productVariantGroupingsGrp2.filter(
                x => x.isActive
            );
            if (variantGroup2.length == 0) {
                for (let i = 0; i < variantGroup1.length; i++) {
                    if (i == 0) {
                        this.productFamily.products[0].gmVariant1Id =
                            variantGroup1[i].productVariantId;
                    } else {
                        this.productFamily.products.push(
                            this.productFamily.products[0]
                        );
                        this.productFamily.products[
                            this.productFamily.products.length - 1
                        ] = {
                            ...this.productFamily.products[
                                this.productFamily.products.length - 1
                            ],
                            gmVariant1Id: variantGroup1[i].productVariantId
                        };
                    }
                }
            } else {
                for (let i = 0; i < variantGroup1.length; i++) {
                    for (let j = 0; j < variantGroup2.length; j++) {
                        if (i == 0 && j == 0) {
                            this.productFamily.products[0].gmVariant1Id =
                                variantGroup1[i].productVariantId;
                            this.productFamily.products[0].gmVariant2Id =
                                variantGroup2[j].productVariantId;
                        } else {
                            this.productFamily.products.push(
                                this.productFamily.products[0]
                            );
                            this.productFamily.products[
                                this.productFamily.products.length - 1
                            ] = {
                                ...this.productFamily.products[
                                    this.productFamily.products.length - 1
                                ],
                                gmVariant1Id: variantGroup1[i].productVariantId,
                                gmVariant2Id: variantGroup2[j].productVariantId
                            };
                        }
                    }
                }
            }
            this.productFamily.productType = 1;
            this.productInventory.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            axios
                .post('products/generalmerchandise', this.productFamily)
                .then(response => {
                    this.$toasted.global
                        .app_success(
                            `Product '${this.productFamily.description}' saved successfully.`
                        )
                        .goAway(5000);
                    this.dataSaved();
                    this.$router.push('/products/gm/' + response.data);
                })
                .finally(() => {
                    this.generateSKUIsBusy = false;
                });
        },
        handleAddBrand(event) {
            if (!this.brandName) {
                event.preventDefault();
                return;
            }

            this.addingBrandIsBusy = true;
            axios
                .post('/brand', {
                    brandName: this.brandName
                })
                .then(resp => {
                    this.$refs.addBrandModal.hide();
                    this.productFamily.productBrandId = resp.data;
                    this.brandName = '';
                })
                .finally(() => {
                    this.addingBrandIsBusy = false;
                });
        },
        calculateMargin() {
            this.productInventory.margin = pricing.computeMargin(
                this.productInventory.cost,
                this.productInventory.sellingPrice
            );
        },
        filterVariantGroup1(variantGroup){
             return variantGroup.value != this.productFamily.gmVariantGroup2Id;
        },
        filterVariantGroup2(variantGroup){
             return variantGroup.value != this.productFamily.gmVariantGroup1Id;
        },
        dccChanged: async function() {
            if (!this.product?.dccId) {
                return;
            }
            axios
                .get('products/dccdefaults', {
                    params: { dccId: this.product.dccId }
                })
                .then(resp => {
                    if (resp.data.margin != null) {
                        this.productInventory.margin = resp.data.margin;
                        this.product.priceTagTypeId = resp.data.priceTagTypeId;
                    }
                });
        },
        dccFilter(dcc) {
            return dcc.filter == 1;
        },
        newPrimaryURL(value) {
            this.productFamily.imageUrl = value;
        },
        currencyFormatter: currencyFormatter,
        adoptSelection: function(item) {
            this.itemToAdopt = item;

            this.$bvModal.show('addNewUsed');
        },
        newOnly() {
            this.adoptItem(true);
        },
        newAndUsed() {
            this.adoptItem(false);
        },
        adoptItem: async function(newOnly) {
            this.isBusy = true;
            this.itemToAdopt.newOnly = newOnly;
            this.itemToAdopt.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            axios
                .post('products/adoptbook', this.itemToAdopt)
                .then(response => {
                    this.$bvModal.hide('addBookModal');
                    this.$bvModal.hide('addNewUsed');
                    this.$router.push('/products/tx/' + response.data);
                    this.$toasted.global
                        .app_success(
                            `Product '${this.itemToAdopt.author} / ${this.itemToAdopt.title}' created successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },
    computed: {
        filterByOptions: function() {
            if (this.filters.productType === 'Books') {
                return [
                    'All',
                    'SKU',
                    'Author',
                    'Title',
                    'ISBN',
                    'Barcode',
                    'Supplier'
                ];
            }
            if (this.filters.productType === 'GM') {
                return [
                    'All',
                    'SKU',
                    'Description',
                    'Catalog #',
                    'Barcode',
                    'Serial Number',
                    'Supplier'
                ];
            }
            return [
                'All',
                'SKU',
                'Author',
                'Title',
                'Description',
                'Catalog #',
                'ISBN',
                'Barcode',
                'Serial Number',
                'Supplier'
            ];
        }
    },
    watch: {
        product: NavigationGuard.$watcher

    },
    components: {
        SearchScreen,
        ProductMultiSelectQuickSearch
    }
};
</script>

<style scoped>
>>> .search-by-select {
    /* keep search by select from changing sizes when options change
    due to books/gm/both selection */
    min-width: 135px;
}
</style>
