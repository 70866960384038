<template>
    <SearchScreen
        title="Invoices"
        api-destination="invoices"
        edit-key="invoiceId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        add-new-button
    >
        <template v-slot:belowSearch>
            <p-form>
                <b-row class="ml-3 mt-4 mr-2">
                    <p-checkbox label="Open" class="ml-2" v-model="filters.open" />
                    <p-checkbox label="Posted" class="ml-3" v-model="filters.posted" />
                    <p-checkbox label="Cancelled" class="ml-3" v-model="filters.cancelled" />
                    <p-checkbox label="Paid" class="ml-3" v-model="filters.paid" />
                    <b-col md="3" class="ml-md-auto">
                        <p-datepicker label="Invoice Date: From" vid="invoiceBeginDate" v-model="filters.beginDate" />
                    </b-col>
                    <b-col md="3">
                        <p-datepicker label="Invoice Date: To" v-model="filters.endDate"
                            rules="dateGreaterOrEqual:@invoiceBeginDate" is-end-date />
                    </b-col>
                </b-row>
            </p-form>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import { currencyFormatter, dateFormatter } from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'InvoicesFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'invoiceDate',
                sortDesc: true,
                beginDate: null,
                endDate: null,
                open: true,
                posted: false,
                cancelled: false,
                paid: false,
                searchBy: 'All'
            },
            filterBy: ['All', 'Invoice #', 'ISBN', 'Barcode', 'Catalog #', 'Supplier', 'PO #', 'SKU'],
            fields: [
                {
                    key: 'invoiceNumber',
                    sortable: true
                },
                {
                    key: 'supplier',
                    sortable: true
                },
                {
                    key: 'invoiceDate',
                    formatter: dateFormatter,
                    sortable: true
                },
                {
                    key: 'total',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    formatter: currencyFormatter,
                    sortable: true
                },
                {
                    key: 'status',
                    sortable: true
                }
            ]
        };
    },
    methods: {},
    components: {
        SearchScreen
    }
};
</script>
<style scoped></style>
