<template>
    <div>
        <ProductMultiSelectQuickSearch
            v-if="!purchaseOrderView && !includeInvoices"
            label="Add Product(s)"
            v-model="selectedProducts"
            icon-plus
            :excluded-skus="alreadyIncludedSkus"
            :supplier-ids="supplierIdFilter"
            @selections="submitSelections"
            :disable-add-button="disableAddButton"
        >
            <template v-slot:above-table>
                <b-row class="pl-1" cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-advanced-select
                            v-model="filters.supplierId"
                            add-empty-option
                            data-type="suppliers"
                            label="Supplier"
                            class="pb-3"
                        ></p-advanced-select>
                    </b-col>

                    <b-col class="ml-auto">
                        <p-select
                            v-model="returnReason"
                            data-type="returnReasons"
                            label="Reason"
                            class="pb-3"
                        ></p-select>
                    </b-col>
                </b-row>
            </template>
        </ProductMultiSelectQuickSearch>
        <p-modal
            v-else
            icon-plus
            :button-label="label"
            :name="name"
            :label="label"
            :disable-button="disableAddButton"
            size="xl"
            @ok="submitSelections(selectedProducts)"
            @hidden="resetModal"
            :hide-footer="tabIndex === 0"
        >
            <template v-slot:default>
                <b-tabs justified v-model="tabIndex">
                    <b-tab title="Step 1: Invoices">
                        <template v-if="!purchaseOrderView">
                            <p-search-table
                                :fields="invoiceFields"
                                sort-by="invoiceNumber"
                                ref="apiSearch"
                                v-model="selectedInvoices"
                                search-destination="searchreturnableinvoices"
                                api-destination="creditrequests"
                                :filters="filters"
                                submit-search-as-post
                            >
                                <template v-slot:above-table>
                                    <b-row cols="2">
                                        <b-col>
                                            <p-advanced-select
                                                v-model="filters.supplierId"
                                                add-empty-option
                                                data-type="suppliers"
                                                label="Supplier"
                                                class="pb-3"
                                            ></p-advanced-select>
                                        </b-col>
                                        <b-col>
                                            <p-datepicker
                                                label="Invoice Date"
                                                v-model="filters.invoiceStartDate"
                                                @end-of-selected-day="e => {filters.invoiceEndDate = e}"
                                            />
                                        </b-col>
                                    </b-row>
                                </template>
                            </p-search-table>
                        </template>
                        <template v-else>
                            <p-table
                                :fields="invoiceFields"
                                :items="filteredInvoices"
                                :sort-by="defaultSort"
                                ref="invoicesTable"
                                v-model="selectedInvoices"
                            >
                                <template v-slot:above-table>
                                    <b-row cols="2">
                                        <b-col>
                                            <p-select
                                                v-model="filters.supplierId"
                                                data-type="suppliers"
                                                label="Supplier"
                                                class="pb-3"
                                            ></p-select>
                                        </b-col>
                                        <b-col>
                                            <p-datepicker
                                                label="Invoice Date"
                                                v-model="filters.invoiceStartDate"
                                                @end-of-selected-day="e => {filters.invoiceEndDate = e}"

                                            />
                                        </b-col>
                                    </b-row>
                                </template>
                            </p-table>
                        </template>
                    </b-tab>
                    <b-tab title="Step 2: Line Items">
                        <div class="py-5"
                             v-if="
                                !purchaseOrderView &&
                                    selectedInvoices.length === 0
                            ">
                            Please select an invoice in the previous tab.
                        </div>
                        <p-table v-if="purchaseOrderView || selectedInvoices.length> 0"
                            :fields="productFields"
                            :items="filteredItems"
                            :sort-by="defaultSort"
                            v-model="selectedProducts"
                            ref="detailTable"
                            >
                            <template v-slot:above-table>
                                <b-row cols="2">
                                    <b-col>
                                        <p-select v-model="returnReason"
                                                  data-type="returnReasons"
                                                  label="Reason"
                                                  class="pb-3"></p-select>
                                    </b-col>
                                    <b-col>
                                        <p-checkbox
                                                label="Show Items With <=0 Stock On Hand"
                                                v-model="includeOutOfStock "
                                                class="pt-3 mt-3"
                                        />
                                    </b-col>
                                </b-row>                                
                            </template>
                        </p-table>
                    </b-tab>
                </b-tabs>
            </template>
        </p-modal>
    </div>
</template>

<script>
import {
    currencyFormatter,
    bookVariantFormatter,
    dateFormatter,
    searchPlaceHolderFormatter
} from './Common/Formatters';
import uniqueid from 'lodash.uniqueid';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';

export default {
    name: 'ProductCreditRequestQuickSearch',
    props: {
        items: {
            type: Array,
            default: () => null
        },
        invoices: {
            type: Array,
            default: () => null
        },
        includeInvoices: {
            type: Boolean,
            default: true
        },
        creditRequestId: {
            type: Number,
            default: null
        },
        supplierId: {
            type: Number,
            default: null
        },
        termId: {
            type: Number,
            default: null
        },

        //used for filtering out invoice details that have been added
        //to the return, but not yet save (the server doesn't know about them otherwise)
        alreadySelectedInvoiceDetailIds: {
            type: Array,
            default: () => []
        },
        alreadyIncludedSkus: {
            type: Array,
            default: () => []
        },
        enableRowSelection: {
            type: Boolean,
            default: true
        },
        purchaseOrderView: {
            type: Boolean,
            default: false
        },
        disableAddButton: {
            type: Boolean,
            default: false
        },
        name: {
            //name must be unique on the page, if it is not passed
            //in a unique name will be genearted for you.
            type: String,
            required: false,
            default() {
                return uniqueid('ProductCreditRequestQuickSearch_');
            }
        },
        defaultSort: {
            type: String,
            default: 'authorTitleOrDescription'
        }
    },
    components: {
        ProductMultiSelectQuickSearch
    },
    data() {
        return {
            filters: {
                automatic: "",
                searchBy: 'All',
                options: [
                    'All',
                    'Invoice #',
                    'PO #',
                    'Supplier Code',
                    'Status'
                ],
                invoiceStartDate: null,
                invoiceEndDate:null,
                supplierId: null
            },
            selectedInvoices: [],
            //because vue doesn't keep track of oldvalue in watcher for objects and arrays that are mutated
            previousSelectedInvoices: [],
            selectedProducts: [],
            label: 'Add Product(s) from Invoices',
            returnReason: 'Overstock',
            includeOutOfStock : false,
            tabIndex: null,
            productFields: [
                {
                    key: 'invoiceNumber',
                    label: 'Invoice #',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'sku',
                    label: 'SKU',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'termName',
                    label: 'Term',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    label: 'Author',
                    key: 'author',
                    sortable: true,
                    automaticSearch: true,
                    hidden: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                    automaticSearch: true,
                    hidden: true
                },
                {
                    label: 'Description',
                    key: 'authorTitleOrDescription',
                    sortable: true,
                    automaticSearch: true,
                    hidden: true
                },
                {
                    key: 'authorTitleOrDescription',
                    label: 'Author/Title or Description',
                    sortable: true
                },
                {
                    key: 'isbnCatalog',
                    sortable: true,
                    label: 'Catalog # / ISBN',
                    tdClass: 'noWrap'
                },
                {
                    key: 'catalogNumber',
                    label: 'Catalog #',
                    automaticSearch: true,
                    hidden: true
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    automaticSearch: true,
                    hidden: true
                },
                {
                    key: 'barcodes',
                    label: 'Barcode',
                    hidden: true,
                    automaticSearch: true
                },
                {
                    key: 'stockOnHand',
                    label: 'SOH',
                    automaticSearch: true,
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'qty',
                    label: 'QTY Invoiced',
                    automaticSearch: true,
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                
                {
                    key: 'bookVariant',
                    label: 'Type',
                    formatter: bookVariantFormatter,
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'retail',
                    sortable: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'cost',
                    sortable: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                }
            ],
            invoiceFields: [
                {
                    label: 'Invoice #',
                    key: 'invoiceNumber',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'supplierId',
                    label: 'Supplier Code',
                    automaticSearch: true,
                    formatter: value => {
                        return this.$store.getters.getSupplierById(value)?.code;
                    },
                    sortable: true,
                    sortByFormatted: true
                },
                {
                    key: 'invoiceDate',
                    label: 'Invoice Date',
                    formatter: dateFormatter,
                    sortable: true,
                    sortByFormatted: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    automaticSearch: true,
                    sortable: true
                }
            ]
        };
    },

    mounted: function() {
        this.loadFilters();
    },
    watch: {
        alreadySelectedInvoiceDetailIds: {
            handler: function() {
                this.loadFilters();
            }
        },
        supplierId: {
            handler: function() {
                this.loadFilters();
            }
        },
        selectedInvoices: {
            handler: function(newVal) {
                if(newVal.length === 0){
                    this.$refs.detailTable?.unselectAll();                    
                    this.previousSelectedInvoices = [];
                    return;
                }
                setTimeout(() => {
                    //after the line item table has updated its items, select items for newly selected invoice
                    newVal.forEach(n => {
                    if(!this.previousSelectedInvoices.some(o => n === o)){
                         let invoiceDetails = this.filteredItems.filter(x => n.invoiceDetails.some(y => y.invoiceDetailId === x.invoiceDetailId))
                         this.$refs.detailTable?.addSelections(invoiceDetails)
                     }
                    })
                    this.previousSelectedInvoices = newVal.slice(0);
                }, 50);             
            }
         },
                
    },
    computed: {
        supplierIdFilter: function() {
            return this.filters.supplierId ? [this.filters.supplierId] : [];
        },
        filteredItems: function() {             
            if (!this.purchaseOrderView) {
                return this.selectedInvoices.flatMap(x => x.invoiceDetails).filter(x=>this.includeOutOfStock ==true || x.stockOnHand > 0);
            }
            return this.items.filter(
                x =>
                    this.selectedInvoices.length === 0 ||
                    this.selectedInvoices.some(
                        y => y.invoiceNumber === x.invoiceNumber
                    ) 
            ).filter(x=>this.includeOutOfStock ==true || x.stockOnHand > 0);
        },
        filteredInvoices: function() {
            return this.invoices.filter(
                x =>
                    (!this.filters.supplierId ||
                        x.supplierId === this.filters.supplierId) &&
                    (this.filters.invoiceStartDate === null ||
                        dateFormatter(x.invoiceStartDate) ===
                            dateFormatter(this.filters.invoiceStartDate))
            );
        }
    },
    methods: {
        searchPlaceHolderFormatter: searchPlaceHolderFormatter,
        loadFilters() {
            this.filters.excludedInvoiceDetailIds = this.alreadySelectedInvoiceDetailIds;
            this.filters.creditRequestId = this.creditRequestId;
            this.filters.supplierId = this.supplierId;
        },
        submitSelections(items) {
            this.selectedProducts = items;
            if (this.returnReason) {
                this.selectedProducts = this.selectedProducts.map(x => ({
                    returnReason: this.returnReason,
                    ...x
                }));
            }

            this.$emit('selections', this.selectedProducts);
            this.$bvModal.hide(this.name);
        },
        resetModal() {
            this.tabIndex = 0;
            this.selectedInvoices = [];
            this.selectedProducts = [];
            this.filters.supplierId = this.supplierId;
            this.filters.invoiceStartDate = null;
            this.returnReason = 'Overstock';
        },
    }
};
</script>

<style scoped lang="scss">
/deep/ .noWrap {
    white-space: nowrap;
}
</style>
