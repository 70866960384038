<template>
    <div class="container-fluid content-wrapper"
            v-if="!noSession" >
        <p-form v-if="buybackSession && buyersGuide">
            <b-row class="content-heading">
                <b-col>
                    <div>
                        {{ title }}
                        <b-icon-info-circle
                            id="popover-info"
                        ></b-icon-info-circle>
                    </div>
                </b-col>
            </b-row>
            <b-popover
                target="popover-info"
                triggers="hover"
                placement="bottomleft"
            >
                <template #title>{{ title }}</template>
                <div>
                    {{ currentUser.profile.name }}<br />
                    {{ getLocationName }}<br />
                    {{ buybackSession.cashProvider }}<br />
                    {{ getVersionDate }}
                </div>
            </b-popover>
            <b-row cols="1" cols-sm="3" cols-xl="3">
                <b-col class="text-left">
                    <p-card>
                        <h5>{{ receiptNumber }}</h5>
                        Receipt
                    </p-card>
                </b-col>
                <b-col class="text-left">
                    <p-card>
                        <h5>
                            {{ getCashDrawerAmount }}
                            <p-button @click="showCashDrawer" no-border>
                                <b-icon-pencil-square> </b-icon-pencil-square>
                            </p-button>

                            <cash-drawer
                                :buyback-session-buyer="getCurrentBuyer"
                                @load-data="updateCash"
                            ></cash-drawer>
                        </h5>
                        Drawer Amount
                    </p-card>
                </b-col>

                <b-col class="text-left">
                    <p-card>
                        <h5>
                            ({{ prevTransactionQty }})
                            {{ getPrevTransactionAmount }}
                            {{ prevTransactionRecieptNumber }}
                        </h5>
                        Prev. Transaction
                    </p-card>
                </b-col>
            </b-row>
            <p-card class="mt-2">
                <b-row cols="1" cols-sm="1" cols-xl="3">
                    <b-col>
                        <p-input
                            label="Customer ID"
                            ref="customerInput"
                            :rules="studentIdRequired"
                            v-model="customerInfo.customerId"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            label="Customer Name"
                            v-model="customerInfo.customerName"
                            rules="max:50"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            label="Customer Notes"
                            v-model="customerInfo.customerNotes"
                            rules="max:2000"
                        />
                    </b-col>
                </b-row>
            </p-card>
            <p-card class="mt-2">
                <b-row>
                    <b-col>
                        <b-input-group>
                            <b-form-input
                                ref="searchInput"
                                type="search"
                                placeholder="Scan/Search by ISBN"
                                @keydown.enter.prevent="handleSearch"
                                v-model="automatic"
                            />
                        </b-input-group>
                    </b-col>
                    <b-col cols="3">
                        <buybooksearch
                            class="pb-2"
                            :buybackSessionId="id"
                            @load-data="updateBuyBookData"
                        />
                    </b-col>
                </b-row>
            </p-card>
            <p-card>
                <b-row>
                    <div class="ml-2 ">
                        <h4>Transaction List</h4>
                    </div>
                </b-row>
                <b-row>
                    <b-col>
                        <p-table
                            ref="transactionTable"
                            :items="transactions"
                            :fields="fieldsTransaction"
                            :enableRowSelection="false"
                            :foot-clone="transactions.length >= 1"
                        >
                            <template v-slot:cell(qty)="{ value, item, index }">
                                <b-icon-dash-circle
                                    style="cursor: pointer"
                                    @click="downQty(index)"
                                ></b-icon-dash-circle>
                                {{ value }}
                                <b-icon-plus-circle
                                    style="cursor: pointer"
                                    @click="upQty(index)"
                                ></b-icon-plus-circle>
                            </template>
                            <template v-slot:foot(title)="data">
                                Total
                            </template>
                            <template v-slot:foot(purchaserName)="data">
                                {{ emptyString }}
                            </template>
                            <template v-slot:foot(qty)="data">
                                {{ totalQuantity }}
                            </template>
                            <template v-slot:foot(calculatedPrice)="data">
                                {{ totalPrice }}
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>

            <b-row class="mt-4">
                <b-col class="text-center mb-3">
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        type="button"
                        class="text-center"
                        @click="cancelTransaction"
                        >Cancel</p-button
                    >
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        class="text-center ml-2"
                        @click="completeTransaction"
                        >Complete</p-button
                    >
                </b-col>
            </b-row>
        </p-form>
        </div>
        <div v-else>
            <div class="row pt-5">
                <div class="col-12 text-center">
                    <h2 class="text-thin">You have no open user sessions.</h2>
                </div>
            </div>
        </div>
</template>

<script>
import axios from 'axios';
import CashDrawer from '@/views/Buyback/CashDrawer.vue';
import {
    currencyFormatter,
    dateFormatter
} from '../../components/Common/Formatters.js';
import buybooksearch from '@/views/Buyback/BuyBookSearch.vue';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import Vue from 'vue';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default {
    components: { buybooksearch, CashDrawer },
    mixins: [StoreListManager, NavigationGuard],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        id: String
    },
    data() {
        return {
            isBusy: false,
            buybackSession: null,
            fieldsTransaction: [
                {
                    key: 'title',
                    formatter: (value, key, item) => {
                        return item.author + '/' + item.title;
                    },
                    label: 'Author/Title'
                },
                {
                    key: 'purchaserName',
                    label: 'Purchaser'
                },
                {
                    key: 'qty',
                    label: 'Qty',
                    class: 'tcw100, text-center'
                },
                {
                    key: 'calculatedPrice',
                    label: 'Price',
                    class: 'tcw100, text-right',
                    formatter: currencyFormatter
                }
            ],
            transactions: [],
            receiptNumber: '',
            automatic: '',
            customerInfo: {
                customerId: null,
                customerName: null,
                customerNotes: null
            },
            selectListOptions: {
                locations: []
            },
            emptyString: '',
            buyersGuide: null,
            prevTransactionAmount: 0,
            prevTransactionQty: 0,
            prevTransactionRecieptNumber:"",
            buyBookData: [],
            noSession: false
        };
    },
    created: function() {},
    computed: {
        title() {
            return this.buybackSession.description;
        },
        getLocationName() {
            return this.$store.getters.getLocationById(
                this.buybackSession.postingLocationId
            )?.text;
        },
        getVersionDate() {
            return dateFormatter(this.buyersGuide.versionDate);
        },
        getCurrentBuyer() {
            let currentUserID = this.$store.getters.getCurrentUserId;

            return this.buybackSession.buybackSessionBuyers.find(
                x => x.buyerUserId == currentUserID
            );
        },
        getCashDrawerAmount() {
            return currencyFormatter(this.getCurrentBuyer.cash);
        },
        getPrevTransactionAmount() {
            return currencyFormatter(this.prevTransactionAmount);
        },
        studentIdRequired() {
            return this.buybackSession.buybackSessionLocations[0]
                .studentIdRequired
                ? 'required|max:50'
                : 'max:50';
        },
        totalQuantity() {
            return this.transactions.reduce((a, c) => a + c.qty, 0);
        },
        totalPrice() {
            return currencyFormatter(
                this.transactions.reduce((a, c) => a + c.calculatedPrice, 0)
            );
        },
        currentUser() {
            return this.$store.getters.getCurrentUser;
        }
    },
    mounted: async function() {
        await this.loadData();
        if (this.$refs['customerInput'] != undefined)
            this.$refs['customerInput'].focus();
    },

    methods: {
        updateCash(cash) {
            this.getCurrentBuyer.cash = cash;
        },
        showCashDrawer() {
            this.$bvModal.show('cashDrawerModal');
        },
        async loadData() {
            let promise = axios.get('buyback/usersessions');
            let promise1 = axios.get('buyersguide');
            let promise2 = axios.get('buyback/buybacktransactionnumber');
            let promise3 = axios.get('buyback/session', {
                params: { id: this.id }
            });

            await Promise.all([promise, promise1, promise2, promise3]).then(resp => {
                if (resp[0].data.filter(x => x.buybackSessionId == this.id).length === 0) {
                    this.noSession = true;
                    this.$toasted.global
                        .app_error(`You have no open user sessions`)
                        .goAway(5000);
                    return;
                }

                this.buyersGuide = resp[1].data;
                this.receiptNumber = resp[2].data;
                this.buybackSession = resp[3].data;
            });
        },
        updateBuyBookData(items) {
            items.forEach(x => Vue.set(x, 'qty', 1));
            items.forEach(x =>
                Vue.set(x, 'calculatedPrice', x.qty * x.offerPrice)
            );
            this.transactions.push(...items);
            this.automatic = '';
        },
        upQty(index) {
            this.transactions[index].qty++;
            if (this.transactions[index].isBuyerGuide) {
                this.calculateOfferPrice();
                return;
            }
            let details = this.transactions.filter(
                x =>
                    x.buybackListDetailId ===
                    this.transactions[index].buybackListDetailId
            );
            let qty = details.reduce((a, b) => a + b.qty, 0);
            if (qty > details[0].quantity) {
                this.$toasted.global
                    .app_error(
                        `Book buy quantity cannot be greater than book required quantity`
                    )
                    .goAway(5000);

                this.transactions[index].qty--;
            } else {
                this.calculateOfferPrice();
            }
        },
        downQty(index) {
            if (this.transactions[index].qty === 1) {
                this.bookQuantityZero(index);
            } else {
                this.transactions[index].qty--;
                this.calculateOfferPrice();
            }
        },
        bookQuantityZero(index) {
            this.$bvModal
                .msgBoxConfirm(
                    'The book quantity is set to 0. Please click Ok to return the book to the customer OR increase the book quantity.'
                )
                .then(value => {
                    if (value) {
                        this.transactions.splice(index, 1);
                    }
                });
        },
        calculateOfferPrice() {
            this.transactions.flatMap(
                x => (x.calculatedPrice = x.qty * x.offerPrice)
            );
        },
        resetBuybackData() {
            this.customerInfo = {
                customerId: null,
                customerName: null,
                customerNotes: null
            };
            this.automatic = '';
            this.searchBy = 'Bookkey';
            this.transactions = [];
            if (this.$refs['searchInput'] != undefined)
                this.$refs['searchInput'].focus();

            this.dataLoaded();
        },
        completeTransaction() {
            if (this.transactions.length <= 0) {
                this.$toasted.global
                    .app_error(
                        'There should be atleast one item to complete transaction.'
                    )
                    .goAway(5000);

                return;
            }
            this.isBusy = true;
            axios
                .post('buyback/completebuybacktransaction', {
                    BuybackSessionBuyerId: this.getCurrentBuyer
                        .buybackSessionBuyerId,
                    ReceiptNumber: this.receiptNumber,
                    CustomerId: this.customerInfo.customerId,
                    CustomerName: this.customerInfo.customerName,
                    CustomerComments: this.customerInfo.customerNotes,
                    CalculatedOfferPrice: this.transactions.reduce(
                        (a, c) => a + c.calculatedPrice,
                        0
                    ),
                    BuyBooks: this.transactions
                })
                .then(() => {
                    this.$toasted.global
                        .app_success(
                            'Buy Book ' +
                                this.receiptNumber +
                                ' completed successfully.'
                        )
                        .goAway(5000);
                    this.prevTransactionAmount = this.transactions.reduce(
                        (a, c) => a + c.calculatedPrice,
                        0
                    );
                    this.prevTransactionQty = this.totalQuantity;
                    this.prevTransactionRecieptNumber = this.receiptNumber;
                    this.loadData();
                    this.resetBuybackData();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        cancelTransaction() {
            this.$bvModal
                .msgBoxConfirm('Do you really want to cancel this transaction?')
                .then(value => {
                    if (value) {
                        this.resetBuybackData();
                    }
                });
        },
        handleSearch() {
            if (this.automatic.trim().replace(/-/g, '').length < 13) return;
            let promise = axios.get('buyback/searchbooks', {
                params: {
                    buybackSessionId: this.id,
                    automatic: this.automatic,
                    searchBy: 'ISBN'
                }
            });
            promise.then(resp => {
                this.buyBookData = resp.data;
                if (this.buyBookData.length <= 0) {
                    this.$toasted.global
                        .app_error('This title is not in the buy list')
                        .goAway(5000);
                    return;
                }
                this.buyBookData = this.buyBookData.filter(
                    x => x.quantity > 0 || x.isBuyerGuide
                );
                if (this.buyBookData.length <= 0) {
                    this.$toasted.global
                        .app_error(
                            "This title has reached it's max buy quantity"
                        )
                        .goAway(5000);
                    return;
                }
                this.buyBookData = this.buyBookData.filter(
                    x => x.offerPrice > 0
                );
                if (this.buyBookData.length <= 0) {
                    this.$toasted.global
                        .app_error('This title has no buyback value')
                        .goAway(5000);
                    return;
                }
                this.buyBookData = [
                    this.buyBookData.reduce((prev, current) =>
                        prev.offerPrice > current.offerPrice ? prev : current
                    )
                ];
                this.updateBuyBookData(this.buyBookData);
            });
        }
    },
    watch: {
        transactions: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    }
};
</script>
