<template>
    <p-form @submit="onSubmit">
        <div> 
                    <b-row>
                        <b-col cols="auto" class="ml-auto mb-3">
                            <p-button type="submit" variant="primary" :is-busy="isBusy"
                                >Save Email Templates</p-button
                            >
                        </b-col>
                    </b-row>
                    <p-card>
                        <b-row>
                            <b-col>
                                <h4>Processing Order</h4>
                            </b-col>
                            <b-col cols="auto" class="ml-auto mr-2">
                                <p-checkbox
                                    label="Active"
                                    v-model="
                                        emailDetailProcessingOrder.isActive
                                    "
                                >
                                </p-checkbox>
                            </b-col>
                        </b-row>
                        <EmailTemplate
                            :emailDetail="emailDetailProcessingOrder"
                        />
                    </p-card>
                    <p-card>
                        <b-row>
                            <b-col>
                                <h4>Backorder Notification</h4>
                            </b-col>
                            <b-col cols="auto" class="ml-auto mr-2">
                                <p-checkbox
                                    label="Active"
                                    v-model="
                                        emailDetailBackorderNotification.isActive
                                    "
                                >
                                </p-checkbox>
                            </b-col>
                        </b-row>
                        <EmailTemplate
                            :emailDetail="emailDetailBackorderNotification"
                        />
                    </p-card>
                    <p-card>
                        <b-row>
                            <b-col>
                                <h4>Shipping Order / Ready for Pickup</h4>
                            </b-col>
                            <b-col cols="auto" class="ml-auto mr-2">
                                <p-checkbox
                                    label="Active"
                                    v-model="emailDetailShippingOrder.isActive"
                                >
                                </p-checkbox>
                            </b-col>
                        </b-row>
                        <EmailTemplate
                            :emailDetail="emailDetailShippingOrder"
                        />
                    </p-card>
                    <p-card>
                        <b-row>
                            <b-col>
                                <h4>Cancel Order</h4>
                            </b-col>
                            <b-col cols="auto" class="ml-auto mr-2">
                                <p-checkbox
                                    label="Active"
                                    v-model="emailDetailCancelOrder.isActive"
                                >
                                </p-checkbox>
                            </b-col>
                        </b-row>
                        <EmailTemplate :emailDetail="emailDetailCancelOrder" />
                    </p-card>
                    <b-row>
                        <b-col class=" mt-3 text-center">
                            <p-button
                                variant="primary"
                                type="submit"
                                :is-busy="isBusy"
                                >Save Email Templates</p-button
                            >
                        </b-col>
                    </b-row>
        </div>
    </p-form>
</template>

<script>
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import EmailTemplate from '../../components/EmailTemplate.vue';
import axios from 'axios';
export default {
    mixins: [NavigationGuard],
    components: {
        EmailTemplate
    },
    props: {},
    data() {
        return {
            isBusy: false,
            customEmailTemplateDetails: [],
            emailDetailProcessingOrder: {
                name: 'Processing Order',
                isActive: false
            },
            emailDetailBackorderNotification: {
                name: 'Backorder Notification',
                isActive: false
            },
            emailDetailShippingOrder: {
                name: 'Shipping Order',
                isActive: false
            },
            emailDetailCancelOrder: {
                name: 'Cancel Order',
                isActive: false
            }
        };
    },
    mounted: async function() {
        await this.loadData();
    },

    methods: {
        async loadData() {
            axios.get('CustomEmailTemplate').then(res => {
                this.customEmailTemplateDetails = res.data;
                let data1 = this.customEmailTemplateDetails.find(
                    x => x.name == 'Processing Order'
                );
                this.emailDetailProcessingOrder =
                    data1 === undefined
                        ? this.emailDetailProcessingOrder
                        : data1;
                let data2 = this.customEmailTemplateDetails.find(
                    x => x.name == 'Backorder Notification'
                );
                this.emailDetailBackorderNotification =
                    data2 === undefined
                        ? this.emailDetailBackorderNotification
                        : data2;
                let data3 = this.customEmailTemplateDetails.find(
                    x => x.name == 'Shipping Order'
                );
                this.emailDetailShippingOrder =
                    data3 === undefined ? this.emailDetailShippingOrder : data3;
                let data4 = this.customEmailTemplateDetails.find(
                    x => x.name == 'Cancel Order'
                );
                this.emailDetailCancelOrder =
                    data4 === undefined ? this.emailDetailCancelOrder : data4;
                this.dataLoaded();
            });
        },
        onSubmit() {
            let index1 = this.customEmailTemplateDetails.findIndex(
                x => x.name == 'Processing Order'
            );
            index1 == -1
                ? this.customEmailTemplateDetails.push(
                      this.emailDetailProcessingOrder
                  )
                : (this.customEmailTemplateDetails[
                      index1
                  ] = this.emailDetailProcessingOrder);

            let index2 = this.customEmailTemplateDetails.findIndex(
                x => x.name == 'Backorder Notification'
            );
            index2 == -1
                ? this.customEmailTemplateDetails.push(
                      this.emailDetailBackorderNotification
                  )
                : (this.customEmailTemplateDetails[
                      index2
                  ] = this.emailDetailBackorderNotification);

            let index3 = this.customEmailTemplateDetails.findIndex(
                x => x.name == 'Shipping Order'
            );
            index3 == -1
                ? this.customEmailTemplateDetails.push(
                      this.emailDetailShippingOrder
                  )
                : (this.customEmailTemplateDetails[
                      index3
                  ] = this.emailDetailShippingOrder);

            let index4 = this.customEmailTemplateDetails.findIndex(
                x => x.name == 'Cancel Order'
            );
            index4 == -1
                ? this.customEmailTemplateDetails.push(
                      this.emailDetailCancelOrder
                  )
                : (this.customEmailTemplateDetails[
                      index4
                  ] = this.emailDetailCancelOrder);

            this.isBusy = true;
            axios
                .post('CustomEmailTemplate', {
                    customEmailTemplateDetails: this.customEmailTemplateDetails
                })
                .then(() => {
                    this.dataSaved();
                    this.loadData();
                    this.$toasted.global
                        .app_success(`Email template saved successfully.`)
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },
    watch: {
        emailDetailProcessingOrder: NavigationGuard.$watcher,
        emailDetailBackorderNotification: NavigationGuard.$watcher,
        emailDetailShippingOrder: NavigationGuard.$watcher,
        emailDetailCancelOrder: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    }
};
</script>
