var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-secure',{attrs:{"require-role":[_vm.SECURITYROLES.STOREMANAGER]}},[_c('div',{staticClass:"container-fluid content-wrapper"},[_c('b-row',{staticClass:"content-heading"},[_c('b-col',[_c('div',[_vm._v("Buyback - Buyers")])])],1),_c('b-row',[_c('editbuyer',{staticClass:"pb-2 ml-3 mr-2",attrs:{"buyer-id":"new"},on:{"data-saved":function($event){return _vm.updateBuyerSearch()}}}),_c('editbuyergroup',{staticClass:"pb-2",attrs:{"group-id":"new"},on:{"data-saved":function($event){return _vm.updateGroupSearch()}}})],1),_c('b-tabs',{attrs:{"justified":""}},[_c('b-tab',{attrs:{"title":"Buyback Buyers"}},[_c('p-search-table',{ref:"buyerSearch",attrs:{"fields":_vm.buyerFields,"selectable":false,"enable-row-selection":false,"search-destination":"buyers","sort-by":"name","api-destination":"buyback"},scopedSlots:_vm._u([{key:"cell(edit)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('editbuyer',{attrs:{"buyer-id":item.buybackBuyerId},on:{"data-saved":function($event){return _vm.updateBuyerSearch()}}})]}},{key:"cell(fastEndTransaction)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('p-checkbox',{attrs:{"disabled":""},model:{value:(item.fastEndTransactions),callback:function ($$v) {_vm.$set(item, "fastEndTransactions", $$v)},expression:"item.fastEndTransactions"}})]}}])})],1),_c('b-tab',{attrs:{"title":"Buyback Groups"}},[_c('p-search-table',{ref:"groupSearch",attrs:{"fields":_vm.groupFields,"selectable":false,"enable-row-selection":false,"search-destination":"groups","sort-by":"description","api-destination":"buyback"},scopedSlots:_vm._u([{key:"cell(edit)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('editbuyergroup',{attrs:{"group-id":item.buybackBuyerGroupId},on:{"data-saved":function($event){return _vm.updateGroupSearch()}}})]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }