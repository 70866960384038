<template>
    <p-card>
        <div class="content-wrapper">
            <div class="content-heading">Dashboard</div>
        </div>
        <b-row>
            <b-col>
                <daily-sales/>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <order-status/>
            </b-col>
        </b-row>
        <b-row cols="1" cols-md="2" cols-lg="3">
            <b-col>
                <term-widget ref="Term Widget" />
            </b-col>
            <b-col>
                <transaction-widget ref="Recent Transactions"/>
            </b-col>
            <b-col>
            </b-col>
        </b-row>        
    </p-card>
</template>
<script>
import TermWidget from '@/views/Dashboard/TermWidget.vue';
import TransactionWidget from '@/views/Dashboard/TransactionWidget.vue';

import DailySales from '@/views/Dashboard/DailySales.vue';
import OrderStatus from '@/views/Dashboard/OrderStatus.vue';
export default {
    components: {
        TermWidget,
        DailySales,
        OrderStatus,
        TransactionWidget
    }
};
</script>
<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
</style>
