<template>
    <ContentWrapper>
        <b-container fluid="xl">
            <b-row>
                <b-col>Dccs</b-col>
                <b-col>
                    <p-advanced-select
                        add-empty-option
                        dataType="dcc"
                        v-model="selectedDccId"
                    />
                </b-col>
                <b-col>Selected Dcc: {{ selectedDccId }}</b-col>
            </b-row>
            <b-row>
                <b-col>Dccs - Return Object</b-col>
                <b-col>
                    <p-advanced-select
                        label="DCC"
                        return-object
                        dataType="dcc"
                        v-model="selectedDcc"
                    />
                </b-col>
                <b-col>Selected Dcc: {{ selectedDcc }}</b-col>
            </b-row>
            <b-row>
                <b-col>Supplier p-filter-select</b-col>
                <b-col>
                    <p-card>
                        <p-filter-select
                            label="Supplier"
                            return-object
                            dataType="suppliers"
                            v-model="selectedSuppliers"
                        />
                    </p-card>
                </b-col>
                <b-col>Selected Suppliers: {{ selectedSuppliers }}</b-col>
            </b-row>
            <b-row>
                <b-col>p-tree</b-col>
                <b-col>
                    <course-sections-selector
                        @selection-change="treeSelection = $event"
                    />
                </b-col>
                <b-col
                    >Selected Sections ({{ treeSelection.length }}):
                    <div v-for="(item, index) in treeSelection" :key="index">
                        {{ JSON.stringify(item) }}
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>p-image</b-col>
                <b-col>
                    <p-image v-model="selectedImage" />
                </b-col>
                <b-col>Selected image {{ selectedImageName }} </b-col>
            </b-row>
            <p></p>
        </b-container>
    </ContentWrapper>
</template>

<script>

import courseSectionsSelector from '@/components/CourseSectionsSelector.vue';

export default {
    name: 'advanced-selects',
    data() {
        return {
            selectedDccId: null,
            selectedDcc: null,
            selectedSuppliers: [],
			supplierId: null,
            treeSelection: [],
            selectedImage: null
        };
	},
    computed: {
        selectedImageName() {
            return this.selectedImage?.name;
        },
    },
	components:{
		courseSectionsSelector
	},
    methods: {
    },
};
</script>
