<template>
    <p-form @submit="onSubmit" ref="wantListForm">
        <div class="container-fluid content-wrapper" v-if="wantList">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Want List</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="auto" class="mr-auto mb-2 d-flex" v-if="!isNew">
                    <ProductMultiSelectQuickSearch
                            label="Add Product(s)"
                            icon-plus
                            field-set="wantListFields"
                            name="itemSelectTop"
                            class="mr-2"
                            search-destination="termassociatedsearch"
                            exclude-used
                            empty-text="No matching products for the selected term(s) are available to be added to this wantlist "
                            is-want-list-title
                            caption="Only products with open to buy greater than 0 can be added.  Products already added are not included in results."
                            @selections="addSelectedItems"
                            :disable-row-check="(data) => { return !(data.openToBuy > 0)}  "
                            :is-active-filter="true"
                            :disable-add-button="!termSelected"
                            :term-ids="wantList.termIds"
                            :excluded-sku-terms="skuTermFilter"
                        />
                    <b-button class="mr-2" hidden variant="outline-danger" @click="deleteWantList">Delete Want List</b-button>
                    <b-overlay :show="isBusy"
                               rounded
                               opacity="0.5"
                               spinner-small
                               spinner-variant="primary"
                               class="d-inline-block">
                        <b-dropdown class="mr-2" text="Export PDF" variant="outline-primary">
                            <b-dropdown-text v-if="!formIsDirty">Export PDF for supplier:</b-dropdown-text>
                            <b-dropdown-divider v-if="!formIsDirty"></b-dropdown-divider>
                            <b-dropdown-text v-if="formIsDirty">Please save or discard changes to want list before exporting.</b-dropdown-text>
                            <b-dropdown-item v-for="supplier in wantListSuppliers"
                                             :disabled="formIsDirty"
                                             :key="supplier.supplierId"
                                             @click="exportWantList(supplier)">
                                {{ supplier.name }} - {{supplier.code}}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-overlay>
                    <b-dropdown :text="allPurchaseOrders.length > 0 ? `Purchase Orders (${allPurchaseOrders.length})` : 'Purchase Orders'"
                                variant="outline-primary">
                        <b-dropdown-item @click="generatePurchaseOrder"
                                         :disabled="formIsDirty || wantList.supplierIds.length === 0">
                            Create Purchase Order
                        </b-dropdown-item>
                        <b-dropdown-text v-if="formIsDirty">Please save or discard changes to want list before generating a purchase order.</b-dropdown-text>

                        <b-dropdown-text v-if="wantList.supplierIds.length === 0">Select a supplier before generating a purchase order.</b-dropdown-text>
                        <b-dropdown-divider v-if="allPurchaseOrders.length > 0"></b-dropdown-divider>
                        <b-dropdown-item v-for="purchaseOrder in allPurchaseOrders"
                                         :key="purchaseOrder.purchaseOrderId"
                                         @click="loadPurchaseOrder(purchaseOrder.purchaseOrderId)">
                            {{ purchaseOrder.purchaseOrderNumber }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
            <p-card>
                <b-row cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-input name="name"
                                 v-model="wantList.name"
                                 label="Name"
                                 rules="required|max:50" />
                    </b-col>
                    <b-col>
                        <p-input name="description"
                                 v-model="wantList.description"
                                 label="Description"
                                 rules="max:255" />
                    </b-col>
                    <b-col>
                        <p-datepicker v-model="wantList.createDate"
                                      label="Create Date"
                                      @date-picker-initialized="e => {loadData()}"
                                      disabled />
                    </b-col>
                    <b-col>
                        <p-datepicker v-model="wantList.lastGenerateDate"
                                      label="Last Generated Date"
                                       @date-picker-initialized="e => {loadData()}"
                                      disabled />
                    </b-col>
                    <b-col>
                        <p-checkbox name="doescascade"
                                    v-model="wantList.doesCascade"
                                    label="Cascade" />
                    </b-col>
                    <b-col class="d-xl-none"></b-col>
                    <b-col>
                        <p-card class="mb-2 px-2 py-2" no-body>
                            <p-filter-select v-model="wantList.termIds"
                                             name="terms"
                                             label="Terms"
                                             stacked
                                             :filter="termsFilter"
                                             :clear-button="false"
                                             data-type="terms" />
                        </p-card>
                    </b-col>
                    <b-col>
                        <p-card class="mb-2 px-2 py-2" no-body>
                            <p-filter-select v-model="wantList.supplierIds"
                                             name="suppliers"
                                             label="Suppliers"
                                             stacked
                                             :clear-button="false"
                                             data-type="suppliers" />
                        </p-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-center pt-2">
                        <p-button variant="outline-primary" @click="generateWantList">Generate Want List</p-button>
                    </b-col>
                </b-row>
            </p-card>
            <p-card>
                <b-row>
                    <b-col cols="auto" class="mr-auto mb-2 d-flex">
                        <ProductMultiSelectQuickSearch
                            label="Add Product(s)"
                            icon-plus
                            field-set="wantListFields"
                            name="itemSelect"
                            search-destination="termassociatedsearch"
                            exclude-used
                            empty-text="No matching products for the selected term(s) are available to be added to this wantlist "
                            is-want-list-title
                            caption="Only products with open to buy greater than 0 can be added.  Products already added are not included in results."
                            @selections="addSelectedItems"
                            :disable-row-check="(data) => { return !(data.openToBuy > 0)}  "
                            :is-active-filter="true"
                            :disable-add-button="!termSelected"
                            :term-ids="wantList.termIds"
                            :excluded-sku-terms="skuTermFilter"
                        />
                        <p-button variant='outline-danger' class='ml-2' @click='confirmDeleteDetails' >Delete</p-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-table :items="wantList.books"
                                 sort-by="authorTitleOrDescription"
                                 ref='bookTable'
                                 :fields="fields">
                            <template v-slot:cell(qty)="{ value, item }">
                                <b-overlay :show="value === ''"
                                           rounded
                                           opacity="0.5"
                                           spinner-small
                                           spinner-variant="primary"
                                           class="d-inline-block">
                                    <p-number compact-format
                                              input-type="integer"
                                              v-model.number="item.qty"
                                              name="Qty"
                                              rules="min_value:0" />
                                </b-overlay>
                            </template>
                            <template v-slot:cell(comment)="{ value, item }">
                                <p-text-area compact-format
                                             v-model="item.comment"
                                             name="Comment"
                                             size="sm" />
                            </template>
                            <template v-slot:cell(delete)="{ value, item }">
                                <b-button class="p-1 ml-1 short-button"
                                    @click="confirmDeleteDetail(item)">
                                    <BIconTrash icon="trash"
                                                font-scale="0.9"
                                                class="mb-1"></BIconTrash>
                                </b-button>
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
            <b-row class="mb-4">
                <b-col class="text-center mb-3">
                    <b-button variant="primary"
                              type="submit"
                              class="text-right">Save</b-button>
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import { bookVariantFormatter } from '../../components/Common/Formatters.js';

    export default {
        mixins: [NavigationGuard, StoreListManager],
        props: {
            id: String
        },
        data() {
            return {
                newPurchaseOrders: [],
                wantList: null,
                 isBusy: false,
                currentPage: 1,
                perPage: 25,
                selectListOptions: { terms: [], suppliers: [] },
                termsFilter: ['True'],
                fields: [
                    {
                        key: 'termName',
                        label: 'Term',
                        sortByFormatted: true,
                        sortable: true
                    },
                    {
                        key: 'sku',
                        label: 'SKU',
                        automaticSearch: true,
                        sortable: true
                    },
                    {
                        key: 'bookVariant',
                        formatter: bookVariantFormatter,
                        sortable: true,
                        label: 'Type'
                    },
                    {
                        label: 'Quantity',
                        key: 'qty',
                        thClass: "text-center",
                        sortable: false
                    },
                    {
                        label: 'Author',
                        key: 'author',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Title',
                        key: 'title',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Description',
                        key: 'authorTitleOrDescription',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Author/Title or Description',
                        key: 'authorTitleOrDescription',
                        sortable: true
                    },
                    {
                        key: 'isbnCatalog',
                        sortable: true,
                        label: 'Catalog # / ISBN'
                    },
                    {
                        key: 'catalogNumber',
                        label: 'Catalog #',
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        key: 'isbn',
                        label: 'ISBN',
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        key: 'comment',
                        sortable: false
                    },
                    {
                        key: 'delete',
                        label: ''
                    }
                ]
            };
        },
        watch: {
            wantList: NavigationGuard.$watcher,
            $route: function (to, from) {
                if (to !== from) {
                    this.loadData();
                }
            }
        },
        computed: {
            allPurchaseOrders() {
                return [...this.wantList.purchaseOrders, ...this.newPurchaseOrders]
            },
            isNew() {
                return this.id === 'new';
            },
            skuTermFilter(){
                return this.wantList.books.map(x =>x.sku + '-' + x.termId)
            },
            termSelected() {
                return this.wantList.termIds.length > 0;
            },
            wantListSuppliers() {
                return this.wantList.supplierIds.map(s => {
                    var supplier = this.selectListOptions.suppliers.find(x => x.value === s);
                    return {supplierId: supplier?.value, name: supplier?.text, code: supplier?.code};
                });
            }
        },
        methods: {
            loadPurchaseOrder(purchaseOrderId) {
                this.$router.push(
                    `/purchaseorders/${purchaseOrderId}`
                )
            },
            onSubmit() {
                axios.post('wantLists', this.wantList).then((response) => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push("/wantlists/" + response.data);
                    }
                    this.$toasted.global.app_success(`Want list '${this.wantList.name}' saved successfully.`).goAway(5000);
                });
            },
            generatePurchaseOrder() {
                axios
                    .post('wantLists/generatepurchaseorder', {
                        wantListId: this.wantList.wantListId
                    })
                    .then(resp => {
                        //not pushing new purchase order into the wantlist object, so the navigation guard
                        //doesn't see this wantlist as dirty.
                        this.newPurchaseOrders.push(resp.data);
                        this.$toasted.global.app_success(`Generated purchase order: ${resp.data.purchaseOrderNumber}`).goAway(5000);

                    });
            },
            async generateWantList(){
                
                if (!(await this.$refs.wantListForm.validate())) {
                    return;
                }

                axios
                    .get('wantLists/productsforterms', {
                        params: {
                            termIds: this.wantList.termIds
                        }
                    })
                    .then(resp => {
                        if (resp) {
                            this.wantList.WasGenerated = true;
                            this.wantList.books = resp.data;
                            if (resp.data.length == 0){                                
                                this.$toasted.global.app_error(
                                    'No books found for want list'
                                ).goAway(5000);
                            }
                            //requested that we save after 'Generate Want List' is clicked and the wantlist is updated
                            this.onSubmit();
                        }
                    })
            },
            addSelectedItems(items) {
                let newItems =  items.map(i => ({
                    qty: i.openToBuy,
                    ...i
                }));

                this.wantList.books = [...this.wantList.books, ...newItems]
            },
            confirmDeleteDetails() {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to remove the selected product(s) from the want list?'
                    )
                    .then(value => {
                        if (value) {
                            this.$refs.bookTable.selectedRows.forEach(x => {
                                this.deleteDetail(x);
                            });
                        }
                    });
            },
            confirmDeleteDetail(detail) {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to remove this product from the want list?'
                    )
                    .then(value => {
                        if (value) {
                            this.deleteDetail(detail);
                        }
                    });
            },
            deleteDetail(detail) {
                let index = this.wantList.books.indexOf(detail);
                if (index !== -1) {
                    this.wantList.books.splice(index, 1);
                }
            },
            deleteWantList() {
                this.$bvModal
                    .msgBoxConfirm(
                        'Are you sure you want to delete this want list?'
                    )
                    .then(value => {
                        if (value) {
                            axios
                                .post('wantLists/delete', this.wantList)
                                .then(resp => {
                                    if (resp) {
                                        this.dataSaved();
                                        this.$router.push('/wantLists');
                                    }
                                });
                        }
                    });
            },
            async loadData() {
                let promise = this.isNew
                    ? axios.get('wantLists/new')
                    : axios.get('wantLists', { params: { id: this.id } });

                promise.then(resp => {
                    this.wantList = resp.data;
                    if (!this.isNew) {
                         this.dataLoaded();
                    }
                });
            },
            exportWantList: function (supplier) {
                let apiUrl = `/wantLists/export?id=${this.wantList.wantListId}&supplierId=${supplier.supplierId}`;
                this.isBusy = true;
                axios
                    .get(apiUrl, {
                        responseType: 'blob',
                    })
                    .then((result) => {
                        downloadFileToBrowser(result.data, `Want List - ${this.wantList.name} - ${supplier.code}`, 'application/pdf');
                         this.$toasted.global.app_success(`Want List report generated successfully.`).goAway(5000);
                    }).finally(() => {
                        this.isBusy = false;
                    });
            }
        },
        mounted: async function () {
            await this.loadData();
        },
        components: {
            ProductMultiSelectQuickSearch
        }
    };
</script>

<style scoped>
>>> .short-button {
    height: 20px;
}
</style>
