var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-form',{on:{"submit":_vm.onSubmit}},[(_vm.storeSetupData)?_c('div',{staticClass:"container-fluid content-wrapper"},[_c('b-row',{staticClass:"content-heading"},[_c('b-col',[_c('div',[_vm._v("Store Setup")])]),_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('p-button',{attrs:{"type":"submit","is-busy":_vm.isBusy,"variant":"primary"}},[_vm._v("Save")])],1)],1),_c('b-tabs',{attrs:{"justified":""}},[_c('b-tab',{attrs:{"title":"Contact Information"}},[_c('p-card',[_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"4"}},[_c('b-col',[_c('p-input',{attrs:{"label":"Name","rules":"max:128|required"},model:{value:(_vm.storeSetupData.locations.name),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "name", $$v)},expression:"storeSetupData.locations.name"}})],1),_c('b-col',[_c('p-input',{attrs:{"label":"Short Name","rules":"max:24|required"},model:{value:(_vm.storeSetupData.locations.shortName),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "shortName", $$v)},expression:"storeSetupData.locations.shortName"}})],1),_c('b-col',[_c('p-input',{attrs:{"label":"Email","rules":"max:254|email"},model:{value:(_vm.storeSetupData.locations.email),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "email", $$v)},expression:"storeSetupData.locations.email"}})],1),_c('b-col',[_c('p-input',{attrs:{"label":"URL","rules":"max:255"},model:{value:(_vm.storeSetupData.locations.url),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "url", $$v)},expression:"storeSetupData.locations.url"}})],1),_c('b-col',[_c('p-input',{attrs:{"label":"Abbreviation","rules":"max:4|required"},model:{value:(
                                    _vm.storeSetupData.locations.abbreviation
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "abbreviation", $$v)},expression:"\n                                    storeSetupData.locations.abbreviation\n                                "}})],1),_c('b-col',[_c('p-number',{attrs:{"label":"Store Number","input-type":"integer","rules":"required"},model:{value:(
                                    _vm.storeSetupData.locations.storeNumber
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "storeNumber", _vm._n($$v))},expression:"\n                                    storeSetupData.locations.storeNumber\n                                "}})],1),_c('b-col',[_c('p-input',{attrs:{"label":"SAN","rules":"max:255"},model:{value:(_vm.storeSetupData.locations.san),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "san", $$v)},expression:"storeSetupData.locations.san"}})],1),_c('b-col',[_c('b-form-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('p-phone-number',{attrs:{"label":"Phone Number","rules":"max:16"},model:{value:(
                                            _vm.storeSetupData.locations
                                                .phoneNumber.number
                                        ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations
                                                .phoneNumber, "number", $$v)},expression:"\n                                            storeSetupData.locations\n                                                .phoneNumber.number\n                                        "}})],1),_c('b-col',{attrs:{"cols":"5"}},[_c('p-input',{attrs:{"label":"Extension","rules":"max:6"},model:{value:(
                                            _vm.storeSetupData.locations
                                                .phoneNumber.extension
                                        ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations
                                                .phoneNumber, "extension", $$v)},expression:"\n                                            storeSetupData.locations\n                                                .phoneNumber.extension\n                                        "}})],1)],1)],1)],1)],1),_c('p-card',[_c('b-row',[_c('b-col',{attrs:{"name":"shippingAddressSection"}},[_c('span',{staticClass:"text-center"},[_c('h5',[_vm._v("Shipping")])]),_c('p-address',{attrs:{"fullWidth":true},model:{value:(
                                    _vm.storeSetupData.locations.shippingAddress
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "shippingAddress", $$v)},expression:"\n                                    storeSetupData.locations.shippingAddress\n                                "}})],1),_c('b-col',{attrs:{"name":"billingAddressSection"}},[_c('span',{staticClass:"text-center"},[_c('h5',[_vm._v("Billing")])]),_c('p-address',{attrs:{"same-as-address":_vm.storeSetupData.locations.shippingAddress,"fullWidth":true},model:{value:(
                                    _vm.storeSetupData.locations.billingAddress
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.locations, "billingAddress", $$v)},expression:"\n                                    storeSetupData.locations.billingAddress\n                                "}})],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Parameters"}},[_c('p-card',{attrs:{"title":"System Settings"}},[_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"4"}},[_c('b-col',[_c('p-select',{attrs:{"label":"Location","rules":"required","data-type":"locations"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .singleLocationId
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "singleLocationId", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .singleLocationId\n                                "}})],1),_c('b-col',[_c('p-select',{attrs:{"label":"Time Zone","options":_vm.storeSetupData.parameters.timeZones,"rules":"required"},model:{value:(_vm.storeSetupData.parameters.timeZone),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters, "timeZone", $$v)},expression:"storeSetupData.parameters.timeZone"}})],1)],1)],1),_c('p-card',{attrs:{"title":"Book Pricing Settings"}},[_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"4"}},[_c('b-col',[_c('p-select',{attrs:{"label":"Currency Rounding (Up)","options":_vm.options,"rules":"required","text-field":"value"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .currencyRounding
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "currencyRounding", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .currencyRounding\n                                "}})],1)],1),_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"4"}},[_c('b-col',[_c('p-number',{attrs:{"label":"New Price - Net Price Margin","input-type":"percent","rules":"required|p-decimal:8,2|max_value:99.99"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksNewPriceNetPriceMargin
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksNewPriceNetPriceMargin", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksNewPriceNetPriceMargin\n                                "}})],1),_c('b-col',[_c('p-number',{attrs:{"label":"Used Retail Price - Percent of New Price","input-type":"percent","rules":"required|p-decimal:8,2"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksUsedRetailPricePercentOfNewPrice
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksUsedRetailPricePercentOfNewPrice", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksUsedRetailPricePercentOfNewPrice\n                                "}})],1),_c('b-col',[_c('p-number',{attrs:{"label":"Used Cost - Percent of New Price","input-type":"percent","rules":"required|p-decimal:8,2"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksUsedCostPercentOfNewPrice
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksUsedCostPercentOfNewPrice", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksUsedCostPercentOfNewPrice\n                                "}})],1),_c('b-col',[_c('p-select',{attrs:{"label":"Update Used Prices from New","options":_vm.yesNoOptions,"mode":"lazy","rules":"required"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksUpdateUsedPricesFromNew
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksUpdateUsedPricesFromNew", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksUpdateUsedPricesFromNew\n                                "}})],1)],1)],1),_c('p-card',{attrs:{"title":"Course Materials Settings"}},[_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"3"}},[_c('b-col',[_c('p-select',{attrs:{"label":"Shelf Tag Template","options":_vm.shelfTagTemplateOptions,"text-field":"value"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .shelfTagTemplateOption
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "shelfTagTemplateOption", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .shelfTagTemplateOption\n                                "}})],1),_c('b-col',[_c('p-select',{attrs:{"label":"Rental Agreement","data-type":"rentalAgreement","disabled":_vm.storeSetupData.parameters.rentalAgreementId != null && _vm.storeSetupData.parameters.disableRentalAgreement},model:{value:(_vm.rentalAgreementId),callback:function ($$v) {_vm.rentalAgreementId=$$v},expression:"rentalAgreementId"}}),(_vm.noRentalAgreement)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" Rental Agreement must be set in Thunder Admin. ")]):_vm._e(),(_vm.storeSetupData.parameters.rentalAgreementId != null && _vm.storeSetupData.parameters.disableRentalAgreement)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(" Cannot change rental agreement while active rentals exist. ")]):_vm._e()],1),_c('b-col',[_c('p-select',{attrs:{"dataType":"taxItems","label":"Rental Tax Type"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .rentalTaxItemId
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "rentalTaxItemId", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .rentalTaxItemId\n                                "}})],1)],1)],1),_c('p-card',{attrs:{"title":"Book Default DCC Settings"}},[_c('b-row',{attrs:{"cols":"1","cols-xl":"3"}},[_c('b-col',[_c('p-advanced-select',{attrs:{"label":"New Book DCC","data-type":"dcc","filter":2},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksDefaultNewDCCId
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksDefaultNewDCCId", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksDefaultNewDCCId\n                                "}})],1),_c('b-col',[_c('p-advanced-select',{attrs:{"label":"Used Book DCC","data-type":"dcc","filter":2},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksDefaultUsedDCCId
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksDefaultUsedDCCId", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksDefaultUsedDCCId\n                                "}})],1),_c('b-col',[_c('p-advanced-select',{attrs:{"label":"Trade Book DCC","data-type":"dcc","add-empty-option":""},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksDefaultTradeDCCId
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksDefaultTradeDCCId", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksDefaultTradeDCCId\n                                "}})],1)],1)],1),_c('p-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"auto"}},[_c('h4',[_vm._v("Digital Content")])]),_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('p-checkbox',{staticClass:"text-right",attrs:{"use-switch":true,"label":"Enable","size":"lg"},model:{value:(_vm.storeSetupData.parameters.enableDigitalContent),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters, "enableDigitalContent", $$v)},expression:"storeSetupData.parameters.enableDigitalContent"}})],1)],1),(_vm.storeSetupData.parameters.enableDigitalContent)?_c('div',[_c('b-row',{attrs:{"cols":"1","cols-xl":"3"}},[_c('b-col',[_c('p-advanced-select',{attrs:{"label":"Digital Book DCC","data-type":"dcc"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .textbooksDefaultDigitalDCCId
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "textbooksDefaultDigitalDCCId", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .textbooksDefaultDigitalDCCId\n                                "}})],1),_c('b-col',[_c('p-advanced-select',{attrs:{"label":"Digital Receipt Info","data-type":"digitalAgreements"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .digitalAgreementId
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "digitalAgreementId", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .digitalAgreementId\n                                "}})],1)],1),_c('b-row',{attrs:{"cols":"1","cols-xl":"3"}},[_c('b-col',[_c('p-checkbox',{attrs:{"label":"Auto-Adopt Digital Titles"},model:{value:(_vm.storeSetupData.parameters
                                        .autoAdoptDigitalTitles),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "autoAdoptDigitalTitles", $$v)},expression:"storeSetupData.parameters\n                                        .autoAdoptDigitalTitles"}})],1)],1)],1):_vm._e()],1),_c('p-card',{attrs:{"title":"Point Of Sale"}},[_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"6"}},[_c('b-col',[_c('p-input',{attrs:{"label":"Store Open","type":"time","rules":"required"},model:{value:(
                                    _vm.storeSetupData.parameters.storeOpen
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters, "storeOpen", $$v)},expression:"\n                                    storeSetupData.parameters.storeOpen\n                                "}})],1),_c('b-col',[_c('p-input',{attrs:{"label":"Store Close","type":"time","rules":"required"},model:{value:(
                                    _vm.storeSetupData.parameters.storeClose
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters, "storeClose", $$v)},expression:"\n                                    storeSetupData.parameters.storeClose\n                                "}})],1)],1)],1),_c('p-card',{attrs:{"title":"POS Tax Names"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"3","lg":"2"}},[_c('h5',[_vm._v("Tax Rate Name")])]),_c('b-col',{attrs:{"cols":"8","md":"9","lg":"10"}},[_c('h5',[_vm._v("Custom Tax Name")])])],1),_vm._l((_vm.taxRates),function(item,index){return [_c('b-row',{key:index + 'row'},[_c('b-col',{staticClass:"pr-5",attrs:{"cols":"4","md":"3","lg":"2"}},[_vm._v(" Tax Rate "+_vm._s(index + 1)+" ")]),_c('b-col',{attrs:{"cols":"8","md":"9","lg":"10"}},[_c('p-input',{attrs:{"name":("TaxName" + (index + 1)),"rules":"required|max:80"},model:{value:(
                                        _vm.storeSetupData.parameters[item]
                                    ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters, item, $$v)},expression:"\n                                        storeSetupData.parameters[item]\n                                    "}})],1)],1)]})],2)],1),_c('p-feature-flag',{ref:"StoreSetupAccountingFlag",attrs:{"name":"Store Setup - Accounting"}},[_c('b-tab',{attrs:{"title":"Accounting"}},[_c('p-card',{attrs:{"title":"Global Settings"}},[_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"4"}},[_c('b-col',[_c('p-select',{attrs:{"label":"Fiscal Year start month","disabled":!_vm.selectedLocationMatchesDefaultLocation || !_vm.isAuthorized,"rules":"required","data-type":"monthname"},model:{value:(
                                    _vm.storeSetupData.parameters
                                        .fiscalYearStartMonth
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters
                                        , "fiscalYearStartMonth", $$v)},expression:"\n                                    storeSetupData.parameters\n                                        .fiscalYearStartMonth\n                                "}})],1)],1)],1),_c('p-card',{attrs:{"title":"Location Settings"}},[_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"2"}},[_c('b-col',[_c('p-checkbox',{attrs:{"use-switch":true,"label":"Use GL Management","disabled":!_vm.isAuthorized,"size":"md"},on:{"change":_vm.useGLManagement},model:{value:(_vm.storeSetupData.parameters.accounting.useGLManagement),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters.accounting, "useGLManagement", $$v)},expression:"storeSetupData.parameters.accounting.useGLManagement"}})],1)],1),_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"3"}},[_c('b-col',[_c('p-input',{attrs:{"label":"End of Accounting Day(00:00 - 23:59)","type":"time","disabled":!_vm.isAuthorized},model:{value:(
                                    _vm.storeSetupData.parameters.accounting.endOfAccountingDay
                                ),callback:function ($$v) {_vm.$set(_vm.storeSetupData.parameters.accounting, "endOfAccountingDay", $$v)},expression:"\n                                    storeSetupData.parameters.accounting.endOfAccountingDay\n                                "}})],1)],1)],1)],1)],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{staticClass:"mb-3 text-center"},[_c('p-button',{staticClass:"pr-2",attrs:{"variant":"primary","is-busy":_vm.isBusy},on:{"click":_vm.cancelEdit}},[_vm._v("Cancel")]),_c('p-button',{attrs:{"variant":"primary","is-busy":_vm.isBusy,"type":"submit"}},[_vm._v("Save")])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }