import axios from 'axios';
import { integer, max, min_value } from 'vee-validate/dist/rules';

export default {
    data() {
        return {
            stockOnHandConfiguration: {
                type: 'stockOnHand',
                label: 'Stock On Hand',
                sampleNotes: ['Any Code fields left blank will be generated.'],
                importNotes: this.getImportNotes,
                fields: [
                    {
                        key: 'Location',
                        hasErrorCalculator: value => {
                            return (
                                value == '' ||
                                value == undefined ||
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        rules: 'required|min_value:0',
                        samples: ['"North Campus"', '"City Campus"'],
                        dataType: 'locations'
                    },
                    {
                        key: 'Barcode',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return true;
                            }
                            return !max.validate(value, { length: 128 });
                        },
                        rules: {
                            required: true,
                            max: { length: 128 }
                        },
                        samples: ['"2235467890161"','"1135467890171"']
                    },
                    {
                        key: 'Action',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        rules: 'required|min_value:0',
                        dataType: 'addReplaceOptions',
                        samples: ['"Add"', '"Replace"']
                    },
                    {
                        key: 'AdjustmentQuantity',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return true;
                            }
                            return !integer.validate(value);
                        },
                        rules: "required",
                        samples: [1, 5, 10, 15],
                        convertToNumber: true
                    },
                    {
                        key: 'Comment',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return value.length > 2000;
                        },
                        rules: 'max:2000',
                        samples: ['""', '""']
                    }
                ]
            }
        };
    },
    methods: {
        async getImportNotes(){
            var codes = this.loadedData.map(x => x.Barcode);
            let notFoundProductBarcodes = (await axios.post('imports/barcodesnotfound', { barcodes: codes })).data;
            let notes = []
            if(notFoundProductBarcodes.length > 0){
                notes.push(`Following product barcodes that are not found will be skipped on import: ${notFoundProductBarcodes.join(', ')}`  )
            }

            return notes;
        }
    }
};
