<template>
  <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
    <SearchScreen
      title="Customers"
      api-destination="customers"
      :filters="filters"
      :fields="fields"
      :filter-by="filterBy"
      edit-key="customerId"
      add-new-button
    >

      <template v-slot:cell(fullName)="{ item }">
        {{item.lastName}}
      </template>
    </SearchScreen>
  </p-secure >
</template>

<script>

import SearchScreen from "../../components/SearchScreen.vue";
import {dateFormatter} from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
  mixins: [SearchStorage],
  data() {
    return {
      filterName: 'CustomerFilter',
      filters: {
        automatic: "",
        perPage: 100,
        sortBy: 'fullName',
        sortDesc: false,
        searchBy:'All'
      },
      filterBy:[
          'All',
          'Account #',
          'Student ID',
          'Alternate Lookup ID',
          'Phone #',
          'Last Name',
          'Email'
      ],
      fields: [
      {
          label: 'Account Number',
          key: "number",
          sortable: true
        },
        {
          //use fullName to achieve lastname then firstname sorting,
          //will override the text to only show last name
          label: "Last Name",
          key: "fullName",
          sortable: true
        },
        {
          key: "firstName"
        },
        {
          label: 'Student ID',
          key: "studentAccount",
          sortable: true
        },
        {
          key: "phone"
        },
        {
          key: "email",
          sortable: true
        },
        {
          key: "lastTransactionDate",
          formatter: dateFormatter
        },
      ]
    };
  },
  components: {
    SearchScreen
  }
};
</script>

<style scoped>
</style>
