<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="md"
        :label="pageTitle"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
        :button-variant="buttonVariant"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div>
                    <b-row>
                        <b-col>
                            <p-advanced-select
                                label="Session"
                                v-model="selectedSessionId"
                                data-type="buybackSessions"
                                text-field="text"
                                value-field="value"
                                @change="loadSessionPurchasers"
                                :filter="sessionFilter"
                                rules="required"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-advanced-select
                                label="Purchaser"
                                v-model="sessionPurchasers.buybackPurchaserId"
                                :options="sessionPurchasers"
                                rules="required"
                            />
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default {
    mixins: [NavigationGuard],
    props: {
        buybackListId: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,
            selectedSessionId: 0,
            sessionPurchasers: []
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.buybackListId === 'new';
        },
        pageTitle() {
            return this.isNew ? 'Buyback List Session' : 'Buyback List Session';
        },
        buttonLabel() {
            return this.isNew ? 'Create New List' : 'Edit';
        },
        buttonVariant() {
            return this.isNew ? 'primary' : 'outline-primary';
        }
    },
    methods: {
        handleModalOk: async function() {
             
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.$router.push(
                'buybacklists/' +
                    this.selectedSessionId +
                    '/' +
                    this.sessionPurchasers.buybackPurchaserId
            );
        },
        loadData() {
            this.selectedSessionId = 0;
            this.sessionPurchasers = [];
        },
        loadSessionPurchasers() {
            let promise = axios.get('buyback/sessionpurchaser', {
                params: { sessionId: this.selectedSessionId }
            });

            promise.then(resp => {
                this.sessionPurchasers = resp.data;

                this.sessionPurchasers = this.sessionPurchasers.filter(
                    x => x.buyersGuideVendorId == null
                );

                this.sessionPurchasers.forEach(x => {
                    (x.text = x.name), (x.value = x.buybackPurchaserId);
                });
            });
        },
        sessionFilter(session) {
            return session.status.toLowerCase() === 'open';
        }
    }
};
</script>
