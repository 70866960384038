<template>
        <div>
            <p-card>
                <p-search-table
                    api-destination="customers"
                    sort-by="transactionDate"
                    :filters="filters"
                    :fields="fields"
                    :showTopPaging="false"
                    :perPage="100"
                    :include-search-input="false"
                    :show-per-page-selector="false"
                    search-destination="rentalHistory"
                    :enable-row-selection="false"
                >
                </p-search-table>
            </p-card>
        </div>
</template>

<script>
import {
    dateFormatterYear
} from '../../components/Common/Formatters.js';

export default {
    props: {
        customer: [Object]
    },
    data() {
        return {
            filters: {
                perPage: 100,
                customerId: 0
            },
            fields: [
                {
                    label: 'Rental Term',
                    key: 'rentalTerm',
                    sortable: true
                },
                {
                    label: 'Transaction #',
                    key: 'transactionNumber'
                },
                {
                    label: 'Transaction Date',
                    key: 'transactionDate',
                    sortable: true,
                    formatter: dateFormatterYear
                },
                {
                    label: 'Author',
                    key: 'author',
                    sortable: true
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'ISBN',
                    key: 'isbn',
                    tdClass: 'noWrap'
                },
                {
                    label: 'Rental Xref',
                    key: 'rentalXref',
                    tdClass: 'noWrap'
                },
                {
                    label: 'Status',
                    key: 'rentalStatus'
                },
                {
                    label: 'Status Date',
                    key: 'statusDate',
                    formatter: dateFormatterYear
                }
            ]
        };
    },
    created: function() {
        this.filters.customerId = this.customer.customerId;
    }
};
</script>
