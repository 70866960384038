<template>
  <p-card>
     <p-form> 
      <b-tabs justified v-model="tabIndex" >
        <b-tab  title="Components">
          <Components v-if="visitedTabs[0]" />
        </b-tab>
        <b-tab title="Selects" >
          <Selects  v-if="visitedTabs[1]" />
        </b-tab>
        <b-tab title="Advanced Selects" >
          <Advanced-Selects  v-if="visitedTabs[2]"/>
        </b-tab>
        <b-tab title="Validation">
          <Validation  v-if="visitedTabs[3]" />
        </b-tab>
        <b-tab title="Layout">
          <Layout  v-if="visitedTabs[4]"  />
        </b-tab>
        <b-tab title="Security">
          <Security  v-if="visitedTabs[5]"  />
        </b-tab>
      </b-tabs>
     </p-form> 
  </p-card>
</template>

<script>
import Components from "./Components.vue";
import Selects from "./Selects.vue";
import AdvancedSelects from "./AdvancedSelects.vue";
import Validation from "./Validation.vue";
import Layout from "./Layout.vue";
import Security from "./Security.vue";
export default {
  created(){
  },
  components: {
    Components,
    Selects,
    AdvancedSelects,
    Validation,
    Layout,
    Security
  },
  data() {
			return {				
        visitedTabs: [],
        tabIndex: null,
			};
    },
    
  watch: {
    tabIndex: {
      handler: function(newVal) {
        //this is used to only render a tab that has been visited,
        //an not destroy the render when switching tabs.

        //b-tabs has a lazy attribute that accomplishes the first, but 
        //everything needs to be re-rendered when returning to the tab.
        //That makes moving between tabs painful.
        this.visitedTabs[newVal] = true;
      }
    }
  }
};
</script>