<template>
    <p-form @submit='onSubmit' ref='form' id='wholesale-form'>
        <div class='container-fluid content-wrapper' v-if='wholesale'>
            <b-row class='content-heading'>
                 <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Wholesale</div>
                </b-col>
                <b-col class='text-right'>
                    <p-button type='submit' variant='primary' :is-busy='isBusy'>Save</p-button>
                </b-col>
            </b-row>
            <b-row v-if='!isNew' class='pb-3'>
                <b-col>
                    <p-button variant='outline-primary' v-b-modal.report :is-busy='isBusy'>Export PDF</p-button>
                </b-col>
            </b-row>
            <p-card>
                <b-row cols='1' cols-sm='2' cols-xl='4'>
                    <b-col>
                        <p-select name='type' :options='wholesaleTypeOptions' v-model='wholesale.type' label='Wholesale Type' rules='required' :disabled='disableTypeAndSupplier'
                            @change='updateGuideDate(wholesale.supplierId)' />
                    </b-col>
                    <b-col>
                        <p-advanced-select name='supplier' data-type='suppliers' mode='lazy' :filter='supplierFilter' v-model='wholesale.supplierId' label='Supplier' rules='required'
                            :disabled='disableTypeAndSupplier' @change='updateGuideDate(wholesale.supplierId)' />
                    </b-col>
                    <b-col>
                        <p-input name='wholesaleNumber' data-type='suppliers' v-model='wholesale.wholesaleNumber' label='Wholesale Number' @change='wholesaleNumberIsUnique' rules='required|max:200' :disabled='!isNew' />
                    </b-col>
                    <b-col>
                        <p-select name='status' data-type='poStatus' :value='wholesale.status' mode='lazy' label='Status' rules='required' @change='validateStatusChange'
                            :key='wholesale.status + validationCheck' />
                    </b-col>
                    <b-col>
                        <p-datepicker name='createDate' v-model='wholesale.createDate' label='Create Date' disabled />
                    </b-col>
                    <b-col>
                        <p-datepicker name='statusDate' v-model='wholesale.statusDate' label='Status Date' disabled />
                    </b-col>
                    <b-col>
                        <p-datepicker name='guideDate' v-model='wholesale.guideDate' label='Guide Date' disabled />
                    </b-col>
                    <b-col>
                        <p-datepicker name='resolveDate' v-model='wholesale.resolveDate' label='Resolve Date' disabled />
                    </b-col>
                    <b-col>
                        <p-number name='subtotal' input-type='money' :value='detailSubtotal' label='Subtotal' :disabled='true' />
                    </b-col>
                    <b-col>
                        <p-number name='postage' input-type='money' v-model='wholesale.postage' label='Postage' rules='min_value:0' :disabled='statusClosed' />
                    </b-col>
                    <b-col>
                        <p-number name='commission' input-type='money' :value='commission' label='Commission' :disabled='true' />
                    </b-col>
                    <b-col>
                        <p-number name='creditDue' input-type='money' :value='creditDue' label='Credit Due' :disabled='true' />
                    </b-col>
                    <b-col>
                        <p-number name='totalNew' input-type='integer' :value='totalNew' label='Total New' :disabled='true' />
                    </b-col>
                    <b-col>
                        <p-number name='totalUsed' input-type='integer' :value='totalUsed' label='Total Used' :disabled='true' />
                    </b-col>
                    <b-col offset='2'></b-col>
                    <b-col>
                        <p-modal
                            button-label='Commission %'
                            :disable-button='statusClosed'
                            label='Commission Percentages'
                            ok-only
                            ok-title='Apply'
                            @show='wholesaleObject = JSON.parse(JSON.stringify(wholesale))'
                            @ok='wholesale = wholesaleObject'
                            >
                            <p-number name='abPercent' input-type='percent' rules='min_value:0|max_value:100' v-model='wholesaleObject.abPercent' label='A/B' />
                            <p-number name='newPercent' input-type='percent' rules='min_value:0|max_value:100' v-model='wholesaleObject.newPercent' label='New' />
                            <p-number name='usedPercent' input-type='percent' rules='min_value:0|max_value:100' v-model='wholesaleObject.usedPercent' label='Used' />
                        </p-modal>
                    </b-col>
                </b-row>
                <b-row class='text-right' v-if='!statusProposed'>
                    <b-col>
                        <p-button variant='outline-primary' @click='addMemo' :disabled='statusClosed'>Add Memo</p-button>
                    </b-col>
                </b-row>
                <b-row v-if='!statusProposed'>
                    <b-col cols='12'>
                        <p-table :items='wholesale.creditMemos'
                                :fields='memoFields'
                                :enable-row-selection='false'
                                >
                                <template v-slot:cell(memoCheckNumber)='{ value, item }'>
                                    <p-input compact-format :rules='memoCheckNumberRules(item)' v-model='item.memoCheckNumber' name='Memo/Check Number' :disabled='statusClosed' />
                                </template>
                                <template v-slot:cell(memoDate)='{ value, item }'>
                                    <p-datepicker compact-format rules='required' v-model='item.memoDate' name='Memo Date' :disabled='statusClosed' />
                                </template>
                                <template v-slot:cell(memoAmount)='{ value, item }'>
                                    <p-number compact-format input-type='money' rules='required|min_value:0' v-model='item.memoAmount' name='Memo Amount' :disabled='statusClosed' />
                                </template>
                                <template v-slot:cell(postage)='{ value, item }'>
                                    <p-number compact-format input-type='money' text-only :value='value' />
                                </template>
                                <template v-slot:cell(commission)='{ value, item }'>
                                    <p-number compact-format input-type='money' text-only :value='value' />
                                </template>
                                <template v-slot:cell(isCheck)='{ value, item }'>
                                    <p-checkbox v-model='item.isCheck' :disabled='statusClosed' />
                                </template>
                                <template v-slot:cell(batchResolve)='{ value, item }'>
                                    <p-button compact-format class='p-1 ml-1' @click='!statusClosed && batchResolveMemo(item)' :disabled='statusClosed || memoStatus(item) === "Resolved"'>Batch Resolve</p-button>
                                </template>
                                <template v-slot:cell(batchCancel)='{ value, item }'>
                                    <p-button compact-format class='p-1 ml-1' @click='!statusClosed && batchCancelMemo(item)' :disabled='statusClosed || memoStatus(item) === "Resolved"'>Batch Cancel</p-button>
                                </template>
                                <template v-slot:cell(status)='{ item }'>
                                    {{ memoStatus(item) }}
                                </template>
                                <template v-slot:cell(memoDetails)='{ item }'>
                                    <p-button compact-format class='p-1 ml-1' @click='showMemoDetailModal(item)' :disabled='!wholesaleHasMemo'>Memo Details</p-button>
                                </template>
                                <template v-slot:cell(removeDetail)='{ value, item }'>
                                    <b-button class='p-1 ml-1 short-button'>
                                        <BIconTrash font-scale='0.9' icon='trash' @click='!statusClosed && confirmDeleteMemo(item)' :disabled='statusClosed' ></BIconTrash>
                                    </b-button>
                                </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
            <p-card>
                <b-row>
                    <b-col cols='12' class='text-right'>
                        <p-form inline>
                            <ProductMultiSelectQuickSearch is-active-filter
                                                            name='productSearch'
                                                            :prefill-search='searchPrefill'
                                                            include-terms
                                                            :start-with-term-checked='false'
                                                            @selections='addSelectedProducts'
                                                            :label='modalLabel'
                                                            :button-label='addProducts'
                                                            :adopted-only='isWholesale'
                                                            :use-buyers-guide-cost='isWholesale'
                                                            :associate-available-terms='true'
                                                            books-only
                                                            no-digital
                                                            :disable-add-button='disableProductSearch'
                                                            @hidden='() => {this.multiProductFound = null; this.searchPrefill = null;}'
                                                            show-wholesale-option
                                                            />
                        </p-form>
                    </b-col>
                </b-row>
                <b-row class='mt-2'>
                    <b-col cols='12'>
                        <p-table foot-clone
                                no-footer-sorting
                                foot-variant='light'
                                :items='wholesale.wholesaleDetails'
                                :fields='detailFields'
                                :selection-actions="[{
                                    label: 'Delete',
                                    variant: 'outline-danger',
                                    action: this.removeProducts,
                                    disabled: this.statusClosed
                                }]"
                                empty-text=''
                                >
                                <template v-slot:cell(termId)='{value, item}'>
                                    <p-select data-type='terms'
                                            v-model='item.termId'
                                            :filter='(term) => termFilter(term, item)'
                                            :disabled='!item.availableTerms || statusClosed || detailIsOnMemo(item)'
                                            :add-empty-option='productHasNonTermRecord(item)'
                                            compact-format />
                                </template>
                                <template v-slot:cell(qty)='{ value, item}'>
                                    <p-number v-model='item.qty'
                                            name='Qty'
                                            input-type='integer'
                                            rules='required|min_value:0'
                                            :disabled='statusClosed'
                                            compact-format />
                                </template>
                                <template v-slot:cell(cost)='{ value, item }'>
                                    <p-number v-model='item.cost'
                                            name='Cost'
                                            input-type='money'
                                            :rules='getCostRules(item)'
                                            :disabled='statusClosed || fromOldGuide'
                                            compact-format
                                            />
                                </template>

                                <!-- Footer -->
                                <template v-slot:foot(selected)='data'>
                                </template>
                                <template v-slot:foot(isbn)='data'>
                                    <p-input v-model='newProduct.isbn'
                                                name='ISBN'
                                                ref='newProductIsbn'
                                                placeholder='Search ISBN'
                                                @input='newProductDebounce(newProduct, true, -1)'
                                                rules='max:128'
                                                :disabled='disableProductSearch'
                                                compact-format>
                                    </p-input>
                                </template>
                                <template v-slot:foot(sku)='data'>
                                    <p-input v-model='newProduct.sku'
                                                name='SKU'
                                                placeholder='Search SKU'
                                                ref='newProductSku'
                                                @input='newProductDebounce(newProduct, true, -1)'
                                                rules='max:128'
                                                :disabled='disableProductSearch'
                                                compact-format>
                                    </p-input>
                                </template>
                                <template v-slot:foot(bookVariant)='data'>
                                    {{ formatBookVariant(newProduct.bookVariant) }}
                                </template>
                                <template v-slot:foot(authorTitleOrDescription)='data'>
                                    {{ newProduct.authorTitleOrDescription }}
                                </template>
                                <template v-slot:foot(termId)='data'>
                                    {{ }}
                                </template>
                                <template v-slot:foot(cost)='data'>
                                    <p-number v-model='newProduct.cost'
                                            name='Cost'
                                            input-type='money'
                                            :disabled='true'
                                            compact-format
                                            />
                                </template>
                                <template v-slot:foot(qty)='data'>
                                    <p-number v-model='newProduct.qty'
                                            name='Qty'
                                            input-type='integer'
                                            ref='newProductQty'
                                            rules='min_value:0'
                                            @blur='addSelectedProducts([newProduct])'
                                            :disabled='disableNewQty'
                                            compact-format />
                                </template>
                                <template v-slot:foot(total)='data'>
                                    {{ newProduct.total }}
                                </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
            <b-row>
                <b-col class=" mt-3 text-center">
                    <p-button variant="primary" type="submit" form="wholesale-form" :is-busy="isBusy">Save</p-button>
                </b-col>
            </b-row>
            <p-modal
                size='xl'
                :label='memoDetailsModalTitle'
                :name='wholesale.wholesaleNumber'
                no-close-on-backdrop
                no-close-on-esc
                ok-only
                ok-title='Close'
                @ok='validateCloseMemoModal'
                @close='validateCloseMemoModal'
                >
                <b-row class="text-right pb-3">
                    <b-col>
                        <p-button variant='outline-primary'
                            @click='addPostageToCreditMemo'
                            :disabled='memoHasPostage || statusClosed'
                            >Add Postage</p-button>
                        <p-button
                            class='pl-3'
                            variant='outline-primary'
                            @click='addCommissionToCreditMemo'
                            :disabled='memoHasCommission || statusClosed'
                            >Add Commission</p-button>
                    </b-col>
                    <b-col cols="12" sm="6" xl="2">
                            <ProductMultiSelectQuickSearch
                                label="Add Product(s)"
                                name="memoDetailOptions"
                                :items='memoProductOptions'
                                @selections="addMemoDetails"
                                :disable-add-button='statusClosed'
                                fieldSet='memoDetailFieldsWholesale' />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols='12'>
                        <p-table :items='memoDetails'
                                    :fields='memoDetailFields'
                                    :enable-row-selection='false'
                                    >
                            <template v-slot:cell(resolvedQty)='{ value, item}'>
                                <p-number v-model='item.resolvedQty'
                                        name='Resolved Qty'
                                        input-type='integer'
                                        :rules='memoQtyRules(item)'
                                        compact-format
                                        :disabled='disableMemoDetailPostage(item) || disableMemoInputs(item)'/>
                            </template>
                            <template v-slot:cell(cancelledQty)='{ value, item}'>
                                <p-number v-model='item.cancelledQty'
                                        name='Cancelled Qty'
                                        input-type='integer'
                                        :rules='memoQtyRules(item)'
                                        compact-format
                                        :disabled='disableMemoDetailPostage(item) || disableMemoInputs(item)'/>
                            </template>
                            <template v-slot:cell(deniedQty)='{ value, item}'>
                                <p-number v-model='item.deniedQty'
                                        name='Denied Qty'
                                        input-type='integer'
                                        :rules='memoQtyRules(item)'
                                        compact-format
                                        :disabled='disableMemoDetailPostage(item) || disableMemoInputs(item)'/>
                            </template>
                            <template v-slot:cell(resolvedAmount)='{ value, item}'>
                                <p-number v-model='item.resolvedAmount'
                                        name='Resolved Amount'
                                        input-type='money'
                                        rules='required|min_value:0'
                                        compact-format
                                        :disabled='disableMemoInputs(item)'/>
                            </template>
                            <template v-slot:cell(status)='{ value, item}'>
                                <p-select compact-format :options='memoDetailStatusOptions' v-model='item.status' :disabled='statusClosed' />
                            </template>
                            <template v-slot:cell(isWriteOff)='{ value, item}'>
                                <p-checkbox v-model='item.isWriteOff' :disabled='disableMemoInputs(item)'/>
                            </template>
                            <template v-slot:cell(removeDetail)='{ value, item }'>
                                <b-button class='p-1 ml-1 short-button' :disabled='disableMemoInputs(item)'>
                                    <BIconTrash :disabled='disableMemoInputs(item)' font-scale='0.9' icon='trash' @click='!statusClosed && item.status !== "Resolved" && confirmDeleteMemoDetail(item)'></BIconTrash>
                                </b-button>
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-modal>
            <p-modal
                size='sm'
                label='Sort Order'
                name='report'
                @ok='exportWholesale'
                >
                <b-row class="text-right pb-3">
                    <b-col>
                        <p-select v-model='reportSort' :options='reportSortOptions' />
                    </b-col>
                </b-row>
            </p-modal>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import { currencyFormatter, bookVariantFormatter, dateFormatter } from '../../components/Common/Formatters.js';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import unionBy from 'lodash/unionBy';
import debounce from 'lodash/debounce';
import round from "lodash/round";
import sortBy from "lodash/sortBy";

export default {
    mixins: [NavigationGuard, StoreListManager],
    props: {
        id: String
    },
    data() {
        return {
            isBusy: null,
            wholesale: Object,
            wholesaleTypeOptions: [
                'Wholesale',
                'Speculative'
            ],
            selectListOptions: { terms: [] },
            reportSortOptions: [
                'Author/Title',
                'ISBN',
                'Qty'
            ],
            detailFields: [
                {
                    key: 'isbn',
                    label: 'ISBN',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'sku',
                    label: 'SKU',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'bookVariant',
                    label: 'Type',
                    formatter: bookVariantFormatter,
                    sortable: true,
                    sortByFormatted: true
                },
                {
                    label: 'Author',
                    key: 'author',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Description',
                    key: 'authorTitleOrDescription',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'authorTitleOrDescription',
                    label: 'Author/Title or Description',
                    sortable: true,
                },
                {
                    key: 'termId',
                    label: 'Term'
                },
                {
                    key: 'cost',
                    formatter: currencyFormatter,
                    thClass: 'text-center'
                },
                {
                    key: 'qty',
                    thClass: 'text-center'
                },
                {
                    key: 'total',
                    formatter: (value, key, item) => {
                        return currencyFormatter(item.qty * item.cost);
                    },
                    tdClass: 'text-right',
                    thClass: 'text-center',
                    sortByFormatted: true,
                    sortable: true
                }
            ],
            memoFields: [
                {
                    key: 'memoCheckNumber',
                    label: 'Memo/Check Number'
                },
                {
                    key: 'memoDate',
                    formatter: dateFormatter
                },
                {
                    key: 'memoAmount',
                    thClass: 'text-center'
                },
                {
                    key: 'postage',
                    formatter: (value, key, item) => {
                        let postage = item.creditMemoDetails.find(x => x.noSkuDescription === 'Postage');
                        if(postage)
                        {
                            return postage.resolvedAmount;
                        }
                        return 0.00;
                    },
                    thClass: 'text-center'
                },
                {
                    key: 'commission',
                    formatter: (value, key, item) => {
                        let commission = item.creditMemoDetails.find(x => x.noSkuDescription === 'Commission');
                        if(commission)
                        {
                            return commission.resolvedAmount;
                        }
                        return 0.00;
                    },
                    thClass: 'text-center'
                },
                {
                    key: 'isCheck'
                },
                {
                    key: 'batchResolve'
                },
                {
                    key: 'batchCancel'
                },
                {
                    key: 'memoDetails',
                    label: ''
                },
                {
                    key: 'status',
                    sortable: true
                },
                {
                    key: 'removeDetail',
                    label:''
                }
            ],
            memoDetailFields: [
                {
                    key: 'sku',
                    formatter: (value, key, item) => {
                        return item?.noSkuDescription || value;
                    },
                    label:'SKU',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'termName',
                    label:"Term",
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'resolvedQty',
                },
                {
                    key: 'cancelledQty',
                },
                {
                    key: 'deniedQty',
                },
                {
                    key: 'resolvedAmount',
                },
                {
                    key: 'status'
                },
                {
                    key: 'isWriteOff',
                    label: 'Write off'
                },
                {
                    key: 'removeDetail',
                    label:''
                }
            ],
            memoDetailStatusOptions: [
                'Unresolved',
                'Resolved'
            ],
            wholesaleObject: {},
            newProduct: null,
            newProductDebounce: null,
            searchPrefill: null,
            addProducts: 'Add Product(s)',
            multiProductFound: null,
            validationCheck: 0,
            selectedMemo: null,
            originalWholesaleNumber: null,
            reportSort: 'Author/Title'
        }
    },
    watch: {
        wholesale: NavigationGuard.$watcher
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        statusClosed() {
            return this.wholesale.status === 'Closed';
        },
        statusProposed() {
            return this.wholesale.status === 'Proposed';
        },
        disableNewQty() {
            return !this.newProduct.authorTitleOrDescription || this.newProduct.authorTitleOrDescription === 'PRODUCT NOT FOUND';
        },
        modalLabel() {
            return this.multiProductFound || this.addProducts;
        },
        totalNew() {
            return this.getQtyTotal(1);
        },
        totalUsed() {
            return this.getQtyTotal(2);
        },
        detailSubtotal() {
            if(this.wholesale.wholesaleDetails)
            {
                return round(this.wholesale.wholesaleDetails.reduce((a,b) => a + b.qty * b.cost, 0),2) || 0;
            }
            return 0;
        },
        creditDue() {
            return round((this.detailSubtotal + this.wholesale.postage + this.commission),2) || 0;
        },
        commission() {
            if(this.wholesale.wholesaleDetails)
            {
                let result = 0;

                let newBooks = this.wholesale.wholesaleDetails.filter(x => x.commissionType === 1);
                if(newBooks.length > 0)
                {
                    let newCommission = newBooks.reduce((a,b) => a + b.qty * b.cost, 0);
                    result = newCommission * (this.wholesale.newPercent/100);
                }

                let usedBooks = this.wholesale.wholesaleDetails.filter(x => x.commissionType === 2);
                if(usedBooks.length > 0)
                {
                    let usedCommission = usedBooks.reduce((a,b) => a + b.qty * b.cost, 0);
                    result += usedCommission * (this.wholesale.usedPercent/100);
                }

                let abBooks = this.wholesale.wholesaleDetails.filter(x => x.commissionType === 3);
                if(abBooks.length > 0)
                {
                    let abCommission = abBooks.reduce((a,b) => a + b.qty * b.cost, 0);
                    result += abCommission * (this.wholesale.abPercent/100);
                }

                return round(result,2);
            }

            return 0;
        },
        disableTypeAndSupplier() {
            return !this.isNew || this.wholesale.wholesaleDetails && this.wholesale.wholesaleDetails.length > 0;
        },
        isWholesale() {
            return this.wholesale.type === 'Wholesale';
        },
        disableProductSearch() {
            return !this.wholesale.type || this.statusClosed || this.fromOldGuide;
        },
        wholesaleHasMemo() {
            return this.wholesale.creditMemos.length > 0;
        },
        memoDetailsModalTitle() {
            if(this.selectedMemo)
            {
                return `Memo Details for ${this.selectedMemo.memoCheckNumber}`
            }
            return '';
        },
        memoDetails() {
            if(this.selectedMemo)
            {
                return this.selectedMemo.creditMemoDetails;
            }
            return [];
        },
        memoProductOptions() {
            if(this.wholesale.wholesaleDetails && this.selectedMemo)
            {
                let itemCompare = function(i, selectedMemo){
                    return selectedMemo.creditMemoDetails.some(x => x.sku === i.sku && x.termId === i.termId && x.locationId === i.locationId);
                };
                return this.wholesale.wholesaleDetails.filter(x => !itemCompare(x, this.selectedMemo) && x.qty > 0).flatMap(x => x);
            }
            return [];
        },
        memoHasPostage(){
            if(this.selectedMemo)
            {
                return this.selectedMemo.creditMemoDetails.some(x => x.noSkuDescription === 'Postage');
            }
            return false;
        },
        memoHasCommission(){
            if(this.selectedMemo)
            {
                return this.selectedMemo.creditMemoDetails.some(x => x.noSkuDescription === 'Commission');
            }
            return false;
        },
        fromOldGuide(){
            let buyersGuideBySupplier = this.wholesale.buyersGuideDatas && this.wholesale.buyersGuideDatas.find(x => x.supplierId === this.wholesale.supplierId);
            return buyersGuideBySupplier && this.wholesale.guideDate !== buyersGuideBySupplier.buyersGuideVersionDate;
        }
    },
    methods: {
        async onSubmit(){
            if(await this.wholesaleNumberIsUnique())
            {
                this.isBusy = true;
                axios.post('wholesale', this.wholesale).then((response) => {
                    if (this.isNew) {
                        this.dataSaved();
                        this.$router.push('/wholesale/' + response.data.wholesaleId);
                    }
                    this.wholesale = response.data;

                    this.dataLoaded();
                    this.$toasted.global.app_success(`Wholesale '${this.wholesale.wholesaleNumber}' saved successfully.`).goAway(5000);
                }).finally(() => {
                    this.isBusy = false;
                });
            }
        },
        wholesaleNumberIsUnique: function(){
           return axios.get('wholesale/wholesalenumberisunique', { params: { wholesaleId: this.wholesale.wholesaleId, wholesaleNumber: this.wholesale.wholesaleNumber }})
           .then(resp => {
               if(resp)
               {
                   return true;
               }
           })
           .catch(err => {
               if(err)
               {
                   this.wholesale.wholesaleNumber = this.originalWholesaleNumber;
                   return false;
               }
           });
        },
        productHasNonTermRecord(product){
            return !this.wholesale.wholesaleDetails.some(x => x.sku === product.sku && !x.termId);
        },
        formatBookVariant(variant){
            if(variant)
            {
                return bookVariantFormatter(variant);
            }
        },
        supplierFilter(supplier) {
            if(this.wholesale.buyersGuideDatas && this.wholesale.type === 'Wholesale')
            {
                return this.wholesale.buyersGuideDatas.some(x => x.supplierId === supplier.value);
            }
            return true;
        },
        termFilter(term, product){
            if(product.availableTerms)
            {
                let existingProductWithTerm = this.wholesale.wholesaleDetails.find(x => x.sku === product.sku && x.termId === term.value);
                return product.availableTerms.some(x => (x === term.value && !existingProductWithTerm) || existingProductWithTerm === product);
            }
            return false;
        },
        getCostRules(product) {
            if(this.isWholesale)
            {
                return `required|min_value:0|wholesaleCost:${Number(product.buyersGuideCost).toFixed(2)},${this.isWholesale}`;
            }

            return 'required|min_value:0';
        },
        getResolveCancelRules(product) {
            return `required|min_value:0|exceedQty:${product.qty},${product.cancelQty + product.resolveQty}`;
        },
        disableMemoDetailPostage(detail)
        {
            return !detail.sku;
        },
        disableMemoInputs(detail){
            return this.statusClosed || detail.status === 'Resolved';
        },
        detailIsOnMemo(detail)
        {
            return this.wholesale.creditMemos.flatMap(x => x.creditMemoDetails).some(x => x.sku === detail.sku && x.termId === detail.termId && x.noSkuDescription === detail.noSkuDescription);
        },
        addMemo(){
            this.wholesale.creditMemos.push({
                creditMemoDetails: [],
                memoCheckNumber: null,
                memoDate: null,
                memoAmount: 0.00,
                isCheck: false
            });
        },
        showMemoDetailModal: async function(memo){
            if(!(await this.$refs.form.validate()))
            {
                return;
            }

            this.selectedMemo = memo;
            this.$bvModal.show(this.wholesale.wholesaleNumber);
        },
        memoQtyRules(item)
        {
            //no rules for postage or commission line.
            if(!item.noSkuDescription)
            {
                let summedQty = this.getSummedQtyAllMemosForItem(item);

                let returnQty = this.wholesale.wholesaleDetails.find(x => x.sku === item.sku && x.termId === item.termId).qty;
                return `required|min_value:0|exceedReturnedQty:${returnQty},${summedQty}`;
            }
        },
        getSummedQtyAllMemosForItem(item){
            //Get the sum of all memo details for the sku/term.
            let details = this.wholesale.creditMemos
                            .flatMap(x => x.creditMemoDetails.filter(y => y.sku === item.sku
                                                        && y.termId === item.termId));
            let qtys = details.flatMap(x => ({ qty: x.resolvedQty + x.cancelledQty + x.deniedQty}));
            let retQty = 0;
            if(qtys.length > 0)
            {
                retQty = qtys.reduce((a, b) => a + b.qty, 0);
            }
            return retQty;
        },
        validateCloseMemoModal: async function(event) {
            event.preventDefault();
            if((await this.$refs.form.validate()))
            {
                this.updateWholesaleStatus();
                this.$bvModal.hide(this.wholesale.wholesaleNumber);
            }
        },
        updateWholesaleStatus(){
            let allMemoDetails = this.wholesale.creditMemos.flatMap(x => x.creditMemoDetails);
            let allDetailsResolved = allMemoDetails.every(x => x.status === 'Resolved');

            if(allDetailsResolved)
            {
                let includesDetailFunction = function(crDetail, memoDetails)
                {
                    return memoDetails.some(x => crDetail.sku === x.sku && crDetail.termId === x.termId);
                };

                let allDetailsInMemos = this.wholesale.wholesaleDetails.every(x => includesDetailFunction(x, allMemoDetails));

                if(allDetailsResolved && allDetailsInMemos)
                {
                    this.wholesale.status = 'Closed';
                }
            }
        },
        addMemoDetails: function(selection) {
            let details = selection.map(x => ({
                sku: x.sku,
                termId: x.termId,
                termName: x.termName,
                locationId: x.locationId,
                resolvedQty: 0,
                cancelledQty: 0,
                deniedQty: 0,
                resolvedAmount: 0,
                status: 'Unresolved',
                isWriteOff: false,
                noSkuDescription: x.noSkuDescription
            }));

            this.selectedMemo.creditMemoDetails = unionBy(this.selectedMemo.creditMemoDetails, details, (x) => x.sku + '-' + (x.termId ?? 0) + '-' + x.noSkuDescription);
            this.selectedMemo.creditMemoDetails = sortBy(this.selectedMemo.creditMemoDetails, x => x.sku, 'desc');
        },
        addPostageToCreditMemo() {
            this.addMemoDetails([{
                sku: null,
                termId: null,
                locationId: null,
                resolvedQty: 0,
                cancelledQty: 0,
                deniedQty: 0,
                resolvedAmount: 0,
                status: 'Unresolved',
                isWriteOff: false,
                noSkuDescription: 'Postage'
            }]);
        },
        addCommissionToCreditMemo() {
            this.addMemoDetails([{
                sku: null,
                termId: null,
                locationId: null,
                resolvedQty: 0,
                cancelledQty: 0,
                deniedQty: 0,
                resolvedAmount: 0,
                status: 'Unresolved',
                isWriteOff: false,
                noSkuDescription: 'Commission'
            }]);
        },
        memoCheckNumberRules(memo){
            if(memo.isCheck)
            {
                let memoNumber = memo.memoCheckNumber && memo.memoCheckNumber.toLowerCase();
                let alreadyExists = !this.wholesale.creditMemos.filter(x => x.isCheck && memo !== x)
                                .flatMap(x => x.memoCheckNumber && x.memoCheckNumber.toLowerCase())
                                .some(x => x === memoNumber);
                return { 'required': true, max: { length: 200 }, isUnique: { isUniqueValue: alreadyExists, message: 'Check number already exists for this credit request.' }};
            }
            return 'required|max:200';
        },
        batchCancelMemo(memo){
            memo.creditMemoDetails.forEach(x => {
                if(x.sku)
                {
                    let wholesaleDetail = this.wholesale.wholesaleDetails.find(y => y.sku === x.sku && y.termId === x.termId);
                    let summedQty = this.getSummedQtyAllMemosForItem(x);

                    x.cancelledQty += wholesaleDetail.qty - summedQty;
                }
                x.status = 'Resolved';
            });
            this.updateWholesaleStatus();
        },
        batchResolveMemo(memo){
            memo.creditMemoDetails.forEach(x => {
                let summedAmount = memo.creditMemoDetails.map(x => x.resolvedAmount).reduce((a, b) => a + b, 0);
                let availableMemoAmount = round(memo.memoAmount - summedAmount, 2);

                if(!x.noSkuDescription)
                {
                    let wholesaleDetail = this.wholesale.wholesaleDetails.find(y => y.sku === x.sku && y.termId === x.termId);
                    let summedQty = this.getSummedQtyAllMemosForItem(x);
                    let delta = wholesaleDetail.qty - summedQty;

                    if(delta <= 0)
                    {
                        let term = this.selectListOptions.terms.find(x => x.value === wholesaleDetail.termId);
                        this.displayErrorMessage(`Sku: (${wholesaleDetail.sku}) Term: (${term && term.name || 'No Term'}) combination has already been resolved on another memo.`);
                        return;
                    }

                    if((delta * wholesaleDetail.cost) > availableMemoAmount)
                    {
                        x.resolvedAmount += availableMemoAmount;
                    }else{
                        x.resolvedAmount += delta * wholesaleDetail.cost;
                    }

                    x.resolvedQty = wholesaleDetail.qty;
                }
                else if(x.noSkuDescription === 'Postage') {
                    if(availableMemoAmount > 0)
                    {
                        let postageDetails = this.wholesale.creditMemos.flatMap(x => x.creditMemoDetails.filter(y => y.noSkuDescription === 'Postage'));
                        let totalPostageApplied = 0;
                        if(postageDetails.length > 0)
                        {
                            totalPostageApplied = postageDetails.reduce((a, b) => a + b.resolvedAmount, 0);
                        }
                        let remainingPostage = this.wholesale.postage - totalPostageApplied;

                        if(remainingPostage + x.resolvedAmount > this.wholesale.postage)
                        {
                            x.resolvedAmount = remainingPostage;
                        }else{
                            x.resolvedAmount += remainingPostage;
                        }
                    }
                }else if(x.noSkuDescription === 'Commission') {
                    if(availableMemoAmount > 0)
                    {
                        let commissionDetails = this.wholesale.creditMemos.flatMap(x => x.creditMemoDetails.filter(y => y.noSkuDescription === 'Commission'));
                        let totalCommissionApplied = 0;
                        if(commissionDetails.length > 0)
                        {
                            totalCommissionApplied = commissionDetails.reduce((a, b) => a + b.resolvedAmount, 0);
                        }
                        let remainingCommission = this.commission - totalCommissionApplied;

                        if(remainingCommission + x.resolvedAmount > this.commission)
                        {
                            x.resolvedAmount = remainingCommission;
                        }else{
                            x.resolvedAmount += remainingCommission;
                        }
                    }
                }
                x.status = 'Resolved';
            });
            this.updateWholesaleStatus();
        },
        memoStatus(memo)
        {
            let status = 'Unresolved';
            if(memo.creditMemoDetails && memo.creditMemoDetails.length > 0)
            {
                if(memo.creditMemoDetails.every(x => x.status === 'Resolved'))
                {
                    status = 'Resolved';
                }
            }
            return status;
        },
        confirmDeleteMemo(memo) {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to remove this memo from the wholesale?')
                .then((value) => {
                if (value) {
                    let index = this.wholesale.creditMemos.indexOf(memo);
                    if (index !== -1) {
                        this.wholesale.creditMemos.splice(index, 1);
                    }
                }
                });
        },
        confirmDeleteMemoDetail(memoDetail){
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to remove this memo detail?')
                .then((value) => {
                if (value) {
                    let index = this.selectedMemo.creditMemoDetails.indexOf(memoDetail);
                    if (index !== -1) {
                        this.selectedMemo.creditMemoDetails.splice(index, 1);
                    }
                }
                });
        },
        updateGuideDate(supplierId){
            if(this.wholesale.type === 'Wholesale')
            {
                let buyersGuideData = this.wholesale.buyersGuideDatas.find(x => x.supplierId === supplierId);
                if(buyersGuideData)
                {
                    this.wholesale.guideDate = buyersGuideData.buyersGuideVersionDate;
                    this.wholesale.abPercent = buyersGuideData.abPercent;
                    this.wholesale.newPercent = buyersGuideData.newPercent;
                    this.wholesale.usedPercent = buyersGuideData.usedPercent;
                }
            }else
            {
                this.wholesale.guideDate = null;
                this.wholesale.abPercent = 0;
                this.wholesale.newPercent = 0;
                this.wholesale.usedPercent = 0;
            }
        },
        getQtyTotal(newOrUsed)
        {
            if(this.wholesale.wholesaleDetails)
            {
                let newBooks = this.wholesale.wholesaleDetails.filter(x => x.bookVariant === newOrUsed);
                if(newBooks)
                {
                    return newBooks.reduce((a,b) => a + b.qty, 0);
                }
            }
            return 0;
        },
        searchProducts(product){
            let skuOrIsbn = product.sku || product.isbn;
            if(skuOrIsbn && !this.disableProductSearch){
                //todo get location?
                axios.post('productquicksearch/termassociatedsearch', {
                    automatic: skuOrIsbn,
                    noDigital: true,
                    adoptedOnly: this.isWholesale,
                    booksOnly: true,
                    useBuyersGuideCost: this.isWholesale,
                    associateAvailableTerms: true})
                .then(resp => {
                    resp;
                    if(resp.data.count == 1)
                    {
                        var p = resp.data.data[0];
                        product.sku = p.sku;
                        product.isbn = p.isbn;
                        product.authorTitleOrDescription = p.authorTitleOrDescription;
                        product.locationId = p.locationId;
                        product.cost = p.cost;
                        product.bookVariant = p.bookVariant;
                        product.buyersGuideBuycode = p.buyersGuideBuycode;
                        product.buyersGuideCost = p.buyersGuideCost;
                        product.availableTerms = p.availableTerms;
                        this.$nextTick(() => {
                            this.$refs.newProductQty.focus();
                        });
                    }
                    else if(resp.data.count == 0)
                    {
                        this.newProduct.authorTitleOrDescription = 'PRODUCT NOT FOUND';
                    }
                    else if(resp.data.count > 1)
                    {
                        this.searchPrefill = product.isbn || product.sku;
                        this.multiProductFound = 'Multiple Products Found';
                        this.$nextTick(() => {
                            this.$bvModal.show('productSearch');
                        });

                    }
                });
            }
        },
        getCommissionType(product){
            let ret = product.bookVariant;

            if(this.isWholesale)
            {
                switch(product.buyersGuideBuycode)
                {
                    case "A":
                    case "B":
                        ret = 3; //AB
                        break;
                    case "C":
                    case "D":
                        ret = 1; //NEW
                        break;
                    default:
                        ret = 2; //USED
                        break;
                }
            }

            return ret;
        },
        async validateStatusChange(value) {
            if (!(await this.$refs.form.validate())) {
                this.displayErrorMessage('Please resolve all validation errors on this Wholesale before changing status.');
                return;
            }else if (this.wholesale.wholesaleDetails.length < 1 && value === 'Open') {
                this.displayErrorMessage('The Wholesale must have atleast one item before opening.');
                return;
            }else if (this.wholesale.status === 'Proposed' && value === 'Closed') {
                this.displayErrorMessage('The Wholesale must be in an Open status before being Closed.');
                return;
            }
            else if (this.wholesale.status === 'Closed' && value === 'Proposed') {
                this.displayErrorMessage('The Wholesale status cannot go from Closed to Proposed.');
                return;
            }
            else{
                this.wholesale.status = value;
                this.onSubmit();
            }
        },
        displayErrorMessage(message){
            this.validationCheck ++;
                this.$toasted.global
                    .app_error(message)
                    .goAway(5000);
        },
        removeProducts(products){
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to remove the selected product(s) from the wholesale?')
                .then((value) => {
                    if (value) {
                        products.forEach((x) => {
                            let index = this.wholesale.wholesaleDetails.indexOf(x);
                            if (index !== -1) {
                                this.wholesale.wholesaleDetails.splice(index, 1);
                            }
                        });
                    }
                });
        },
        addSelectedProducts(products) {
            if(products.length > 0){
                let details = products.map(i => ({
                        qty: 0,
                        total: 0.0,
                        resolveQty: 0,
                        cancelQty: 0,
                        commissionType: this.getCommissionType(i),
                        ...i
                    }));

                this.wholesale.wholesaleDetails = unionBy(this.wholesale.wholesaleDetails, details, (x) => x.sku + '-' + (x.termId ?? 0) + '-' + (x.locationId ?? 0));
                this.clearNewProduct();
            }
        },
        clearNewProduct(){
            this.newProduct = {
                isbn: null,
                sku: null,
                authorTitleOrDescription: null,
                cost: null,
                qty: null,
                total: null
            };

            this.clearErrors();
        },
        clearErrors(){
            if(this.$refs.form && this.$refs.form.$refs.observer)
            {
                this.$refs.form.$refs.observer.reset();
            }
        },
        exportWholesale() {
            let apiUrl = `/wholesales/export?id=${this.wholesale.wholesaleId}&sortOrder=${this.reportSort}`;
            this.isBusy = true;
            axios
                .get(apiUrl, {
                    responseType: 'blob',
                })
                .then((result) => {
                    downloadFileToBrowser(result.data, `Wholesale - ${this.wholesale.wholesaleNumber}`, 'application/pdf');
                    this.$toasted.global.app_success(`Wholesale (${this.wholesale.wholesaleNumber}) report generated successfully.`).goAway(5000);
                }).finally(() => {
                    this.isBusy = false;
                });
        }
    },
    mounted() {
        this.newProductDebounce = debounce(this.searchProducts, 525);
        let promise = this.isNew
                ? axios.get('wholesale/new')
                : axios.get('wholesale', { params: { id: this.id } });

        promise.then((resp) => {
            this.wholesale = resp.data;
            if(this.isNew)
            {
                this.originalWholesaleNumber = this.wholesale.wholesaleNumber;
            }
            if (!this.isNew) {
                         this.dataLoaded();
                    }
            this.$nextTick(() => {
                this.clearErrors();
            });
        });
    },
    beforeMount() {
        this.clearNewProduct();
    },
    components: {
        ProductMultiSelectQuickSearch
    }
}
</script>