<template>
    <p-modal name="rentalStatusModal"
        label="Rental Status"
        ref="rentalStatusModal"
        size="sm"
        @ok.prevent="createRentalStatusReport"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="rentalPeriodForm">
            <div class="container-fluid content-wrapper">
                <b-row>
                    <b-col>
                        <p-advanced-select label="Rental Period"
                            :options="rentalPeriodOptions"
                            text-field="text"
                            value-field="value"
                            v-model="reportParameters.rentalPeriodId"
                            mode="lazy"
                            rules="required"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-select label="Rental Source"
                            :options="rentalSourceOptions"
                            v-model="reportParameters.rentalSourceId"
                            mode="lazy"
                            rules="required"
                            selected="selected"
                            :disabled="true"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-card title="Rental Status">
                            <p-checkbox size="md"
                                label="Outstanding"
                                v-model="reportParameters.includeOutstanding"
                            />
                            <p-checkbox size="md"
                                label="Rental Not Returned"
                                v-model="reportParameters.includeRentalNotReturned"
                            />
                            <p-checkbox size="md"
                                label="Returned"
                                v-model="reportParameters.includeReturned"
                            />
                            <p-checkbox size="md"
                                label="Refunded"
                                v-model="reportParameters.includeRefunded"
                            />
                        </p-card>
                    </b-col>
                </b-row>
            </div>
        </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="cancel"
                        >Cancel</p-button
                    >
                    <b-overlay :show="isBusy"
                                rounded
                                opacity="0.5"
                                spinner-small
                                spinner-variant="primary"
                                class="d-inline-block">
                        <b-dropdown text="Export" 
                                    variant="outline-primary">
                            <b-dropdown-item @click="ok">CSV</b-dropdown-item>
                        </b-dropdown>
                    </b-overlay>
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>

import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    data() {
        return {
            isBusy: false,
            disabled: true,
            rentalPeriodOptions: null,
            rentalSourceOptions: [
                { text: 'Store Managed', value: 1 }
            ],
            reportParameters: {
                rentalPeriodId: null,
                rentalSourceId: 1,
                includeOutstanding: true,
                includeRentalNotReturned: false,
                includeReturned: false,
                includeRefunded: false,
                locationId: null
            }
        }
    },
    methods: {
        async show() {
            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.rentalPeriodOptions = (await selectListOptionsDataContext
                .getStoreDropdownData('rentalPeriods'))
                .filter(x => x.locationId == this.reportParameters.locationId);

            this.$refs.rentalStatusModal.show();
        },
        async createRentalStatusReport() {
            if (!(await this.$refs.rentalPeriodForm.validate())) {
                return;
            }
            
            await axios
                .post('/rentals/rentalstatusreport', this.reportParameters, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Rental Status Report.csv',
                        'application/csv'
                    );
                    this.$toasted.global
                        .app_success(`Rental Status Report generated successfully.`)
                        .goAway(5000);
                    this.$refs.rentalStatusModal.hide();
                });
        }
    }
}
</script>
