<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="account">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} AR Account</div>
                    <span v-if="account.agencyName != null || ''">
                    <h2> {{account.agencyName + '('+ account.accountCode +')' }}</h2>
                    </span>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <p-button type="submit" variant="primary" :is-busy="isBusy">Save</p-button>
                </b-col>
            </b-row>
            <b-tabs justified class="non-printable">
                <b-tab title="Profile">
                    <Profile-Tab :account="account"
                     :is-new="isNew"
                     @load-data="loadData"
                     @date-picker-initialized="e => {dataLoaded()}"
                    />
                </b-tab>
                <b-tab title="Customers" v-if="!isNew">
                    <CustomersTab @load-data="loadData" :account="account" ref="customersTab"/>
                </b-tab>
                <b-tab title="Transactions">
                     <TransactionsTab  :account="account" ref="transactionsTab"/> </b-tab>
                <p-feature-flag ref="ArAccountDCCsFlag" name="AR Account DCCs">   
                    <b-tab title="DCC's"> 
                        <DccTab  :dccs="dccs" :selectedDcc="dccs" ref="dccTab"/> 
                    </b-tab>
                </p-feature-flag>  
            </b-tabs>
            <b-row class="mt-3">
                <b-col class="text-center mb-3">
                    <p-button variant="primary" type="submit" :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>
<script>
import axios from 'axios';
import editPage from '@/components/mixins/EditPage';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import ProfileTab from './ProfileTab.vue';
import CustomersTab from './CustomersTab.vue';
import TransactionsTab from './TransactionsTab.vue';
import DccTab from './DccTab.vue'

export default {
    mixins: [NavigationGuard, editPage],
    components: { ProfileTab, CustomersTab, TransactionsTab, DccTab},
    props: {
        id: String
    },
    data() {
        return {
            account: null,
            isBusy: false,
            dccs:[]
        };
    },
    methods: {
        loadData() {
            (this.isNew ?
                axios.get('Accountreceivable/new') :
                axios.get('Accountreceivable', {params: {id: this.id}})
             ).then(response => {

                const account = response.data;

                account.type = null;
                if (this.isNew) {
                    account.accountType = null;
                } else {
                    if (account.isDebit) {
                        account.type = 2;
                    } else{
                        account.type = 1;
                    }
                }
                this.account = account;
                this.dataLoaded();
            });

            if (!this.isNew) {
                axios.get('accountreceivable/Dccs', {params: {id: this.id}}).then(response => {
                    this.dccs = this.setAllowsDiscountsFlagForDccs(response.data);
                    this.dataLoaded();
                });
            }
        },
        setAllowsDiscountsFlagForDccs(arr){
            arr.map((obj)=>{
                obj.dccClasses.map((obj)=>{
                       obj.dccCategories.some(ele => ele['allowsDiscounts'] === false)?obj.allowsDiscounts = false:obj.allowsDiscounts = true;
                });
                obj.dccClasses.some(ele => ele['allowsDiscounts'] === false)?obj.allowsDiscounts = false:obj.allowsDiscounts = true;
            })
            return arr;
        },
        filterDccs(arr){
            const fiterdArr = [];
            arr.map((obj)=>{
                obj.dccClasses.map((obj)=>{
                    obj.dccCategories.map((obj)=>{
                        if(obj.isActive === true || (obj.percentDiscount != null))fiterdArr.push(obj);
                    })
                })
            })
            return fiterdArr;
        },
        onSubmit() {
            this.isBusy = true;
            const account = {...this.account};
            delete account.type;
            account.dccs = this.filterDccs(this.dccs);
            axios.post('Accountreceivable', account).then(response => {
                this.account.accountId=response.data;
                this.dataSaved();
                this.$toasted.global
                    .app_success(
                        `Account '${this.account.accountCode}' saved successfully.`
                    )
                    .goAway(5000);
                if (this.isNew) {
                    this.$router.push('/accountreceivable/' + response.data);
                }
            }).finally(() => {
                this.isBusy = false;
            });


        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        }
    },
    watch: {
        account: NavigationGuard.$watcher,
        dccs: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    mounted() {
        this.loadData();
    }
};
</script>
<style scoped lang="scss">
@media print {
    /* this is to handle hiding <b-tabs> */
    /deep/.non-printable ul , /deep/.non-printable ul * {
        visibility: hidden;
    }
}
</style>
