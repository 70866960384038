<template>
    <SearchScreen
        title="Web Catalog Groups"
        api-destination="catalogs"
        search-destination="searchgroups"
        edit-key="catalogGroupId"
        :edit-url-func="item => `/webcataloggroups/${item.catalogGroupId}`"
        add-url="/webcataloggroups/new"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :use-is-active-checkbox="false"
        add-new-button
    >
        <template v-slot:belowSearch>
            <p-form>
                <b-row class="ml-3 mt-4 mr-2">
                        <p-checkbox label="Active"
                                    class="ml-2"
                                    v-model="filters.active" />
                        <p-checkbox label="Inactive"
                                    class="ml-3"
                                    v-model="filters.inactive" />
                    </b-row>
            </p-form>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { isActiveFormatter } from '@/components/Common/Formatters.js';
export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'CatalogGroupsFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                searchBy: 'Name',
                active: true,
                inactive: false
            },
            filterBy: ['Name'],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true,
                    formatter: isActiveFormatter
                }
            ]
        };
    },
    methods: {},
    components: {
        SearchScreen
    }
};
</script>
<style scoped></style>
