import round from 'lodash/round';
import ceil from 'lodash/ceil';

function roundTo(number, interval) {
    return round(ceil(number / interval) * interval, 2);
}

export default {
    computeRetail: function(cost, margin, retail, roundingTarget) {        
        if(cost === 0 && margin == 100){
            //when cost is 0, any value for retail satisfies retail being 100% profit, 
            //keep provided retail
            return retail;
        }

        if (margin > 99.99) {
            return 0;
        }
        return roundTo(round(cost / (1 - margin / 100), 2), roundingTarget);
    },
    computeMargin: function(cost, retail) {
        if (retail === 0) {
            return 0;
        }
        if (retail == null || cost == null) {
            //avoid -Infinity when retail is null,
            //which get's rendered as a giant negative number
            return 0;
        }

        return round(((retail - cost) / retail) * 100, 2);
    },
    computeCost: function(retail, margin) {
        if (margin > 99.99) {
            return 0;
        }
        return round(retail - (retail * margin) / 100, 2);
    },
    isInvalidMargin: function(margin, cost) {
        if(margin > 100){
            return true;
        }
        if(margin >= 100 && cost != 0){
            return true;
        }
        if((margin !== 0 && margin !== 100) && cost === 0){
            return true;
        }
        return false;
    },

    costFromPriceAndDiscount: function(price, discount) {
        return round(price * (1 - discount / 100), 2);
    },
    discountFromPriceAndCost: function(price, cost) {
        if (price == null || cost == null || price === 0) {
            return null;
        }

        return round(((price - cost) / price) * 100, 2);
    },

    roundTo: roundTo,

    computeEventPrice(retail, priceModification) {
        if (!retail || !priceModification) {
            return null;
        }

        if (priceModification.priceOverride) {
            return priceModification.priceOverride;
        }

        if (priceModification.discountType == 1) {
            //dollar
            return this.roundTo(
                retail - priceModification.amount,
                priceModification.roundingTarget
            );
        } else if (priceModification.discountType == 2) {
            //percent
            let discount = retail * (priceModification.amount / 100);
            let rawEventPrice = retail - discount;
            
            return this.roundTo(
                rawEventPrice,
                priceModification.roundingTarget
            );
        }
    },
};
