<template>
    <div>
        <b-row >
            <b-col class='text-left'>
                <p-button variant='outline-primary' class='mb-3' @click='addCreditRequest' :is-busy='isBusy'>Add New Credit Request</p-button>
            </b-col>
        </b-row>
        <b-card v-for='(creditRequest, index) in creditRequests' :key='index' class='b'>
        <CreditRequest
            :purchase-order-view='true'
            :credit-request='creditRequest'
            :invoices='invoices'
            @date-picker-initialized="e => {$emit('date-picker-initialized');}"
            :allRequestDetails='allRequestDetails'
            :is-busy='isBusy'
            @deleteCreditRequest='deleteCreditRequest(creditRequest)'
        />
        <!-- @dataToCreditRequests="handleDataFromCreditRequest" -->
        </b-card>
    </div>
</template>

<script>
import CreditRequest from './CreditRequest.vue';
import axios from 'axios';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
  name: 'CreditRequests',
  props: {
    purchaseOrder: {
      type: Object,
      required: true
    },
    isBusy: {
        type: Boolean,
        default: false
    }
  },
  data() {
    return {
      creditRequestTemplate: {
        creditRequestDetails: [],
        creditMemos: []
      },
      formIsDirty: false
    };
  },
  components: {
    CreditRequest
  },
  watch: {
        purchaseOrder: {
            deep: true,
            handler: function() {
                    this.formIsDirty = true;
            }
        }
  },
  computed: {
      invoices() {
        return this.purchaseOrder.invoices.map(x => ({...x}));
      },
      creditRequests: function() {
        return this.purchaseOrder.creditRequests.slice().sort((a,b) => {
            if(a.createDate < b.createDate)
            {
                return 1;
            }

            if(a.createDate > b.createDate)
            {
                return -1;
            }

            return 0;
        });
      },
      allRequestDetails: function() {
          return this.creditRequests.slice().flatMap(x => x.creditRequestDetails.map(y => ({requestNumber: x.requestNumber, ...y})));
      }
  },
  methods: {
    deleteCreditRequest(creditRequest) {
        if (creditRequest.creditRequestDetails.length != 0 || creditRequest.creditMemos.length != 0)
        {
            this.$toasted.global.app_error(
                        'Cannot delete. Credit Request has items and/or memos attached to it.'
                    );
                    return;
        }

        this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete this ${creditRequest.requestNumber}?`)
        .then(value => {
                    if (value) {
                        this.isBusy = true;
                        let index = this.purchaseOrder.creditRequests.indexOf(creditRequest);
                        if (index !== -1)
                        {
                            axios
                                .post('purchaseOrders/deleteCreditRequest', {
                                    requestNumber: creditRequest.requestNumber
                                })
                                .then(() => {
                                    this.purchaseOrder.creditRequests.splice(index, 1);
                                    this.$toasted.global.app_success(`Credit Request '${creditRequest.requestNumber}' deleted successfully.`).goAway(5000);
                                })
                                .finally(() => {
                                    this.isBusy = false;
                                    this.$emit('save');
                                });
                        }
                    }
                });
    },
    async addCreditRequest() {
        if (this.purchaseOrder.invoices.length === 0 || !this.purchaseOrder.purchaseOrderDetails.some(x => x.invoicedQty > 0))
        {
            this.$toasted.global
                .app_error('Please invoice at least one product before creating a credit request.')
                .goAway(5000);
            return;
        }

        if (this.purchaseOrder.creditRequests?.some(x => x.status === 'Proposed' &&  !(x.creditRequestDetails?.length > 0)  &&  !(x.creditMemos?.length > 0)))
        {
            this.$toasted.global
                .app_error('Cannot add new credit request.  Please use existing credit request in Proposed status with no items and/or memos attached to it.')
                .goAway(5000);
            return;
        }

        this.isBusy = true;

        if (this.formIsDirty) {
            this.formIsDirty = false;
            await this.savePurchaseorder();
        }

        await axios.get('purchaseOrders/nextCreditRequestNumber').then(resp => {
            this.purchaseOrder.creditRequests.unshift({ ...this.creditRequestTemplate,
                status: 'Proposed',
                supplierId: this.purchaseOrder.supplierId,
                returnPolicyId : this.$store.getters.getSupplierById(this.purchaseOrder.supplierId).returnPolicyId,
                maxReturnTime : this.$store.getters.getSupplierById(this.purchaseOrder.supplierId).maxReturnTime,
                requestNumber: resp.data,
                type: 1
            });
        });

        this.isBusy = false;
    },
    async savePurchaseorder() {
         this.purchaseOrder.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            await axios.post('purchaseOrders/saveCreditRequestPO', this.purchaseOrder);
            this.$toasted.global.app_success(`Purchase order '${this.purchaseOrder.poNumber}' saved successfully.`).goAway(5000);
    }
  }
};
</script>
