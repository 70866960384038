<template>
  <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="sequence">
            <b-row class="content-heading">
                <b-col>
                    <div>Edit Sequence</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
            </b-row>
            <p-card>
                <b-row cols="1" cols-md="3" >
                    <b-col>
                        <p-input label="Name" v-model="sequence.name" disabled rules="required|max:32" />
                    </b-col>
                    <b-col>
                        <p-input label="Template" v-model="sequence.template" rules="required|max:32|pound-sign-min-max" />
                    </b-col>
                    <b-col>
                        <p-select v-model="sequence.sequenceTypeId" dataType="sequenceTypes" label="Sequence Types" disabled  mode='lazy' rules="required" />
                    </b-col>
                    <b-col>
                        <p-number label="Next" v-model.number="sequence.next" input-type="integer" :disabled='true' />
                    </b-col>
                    <b-col>
                        <p-number label="Last" v-model.number="sequence.last" input-type="integer" :disabled='true' />
                    </b-col>
                </b-row>
            </p-card>

            <b-row>
                <b-col class=" mb-3 text-center">
                    <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
            </b-row>
        </div>
    </p-form>
  </p-secure>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import { extend } from 'vee-validate';

extend("pound-sign-min-max", {
    message: (field,) => {
      return 'The ' + field + ' field must include at 3-10 # characters to be replaced by the generated sequence number.'
      },

    validate: (value) => {
        if(!value) {return false;}

        let count = (value.match(new RegExp("#", "g")) || []).length;
        return count < 11 && count > 2;
    },
    params: ['precision','scale']
});

    export default {
        mixins: [NavigationGuard],
        props: {
            id: String
        },
        data() {
            return {
                sequence:null
            };
        },
        watch: {
            sequence: NavigationGuard.$watcher,
            $route: function (to, from) {
                if (to !== from) {
                    this.loadData();
                }
            }
        },
        methods: {
            onSubmit() {
                axios.post("sequences", this.sequence).then(() => {
                    this.dataSaved();
                    this.$toasted.global.app_success(`Sequence '${this.sequence.name}' saved successfully.`).goAway(5000);
                });
            },
            loadData() {
                axios.get("sequences", { params: { id: this.id } }).then(resp => {
                    this.sequence = resp.data;
                    this.dataLoaded();
                });
            }
        },
        mounted: function () {
            this.loadData();
        }
    };
</script>

<style scoped>
</style>
