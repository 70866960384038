<template>
    <p-modal
        size="sm"
        name="exportPriceListModal"
        label="Export Price List"
        ref="exportPriceListModal"
        @show="initialize"
    >
        <template v-slot:default>
            <p-form ref="priceListForm">
                <p-advanced-select
                    v-if="!termId"
                    v-model="selectedTermId"
                    label="Term"
                    data-type="terms"
                    rules="required"
                ></p-advanced-select>
                <p-checkbox
                    label="Include Course Information"
                    v-model="priceListParameters.includeCourseInfo"
                />
                <p-checkbox
                    label="Include Publisher"
                    v-model="priceListParameters.includePublisher"
                />
                
            </p-form>
        </template>
        <template v-slot:modal-footer>
                <b-row>
                    <b-col>
                        <b-overlay :show="isBusy"
                                   rounded
                                   opacity="0.5"
                                   spinner-small
                                   spinner-variant="primary"
                                   class="d-inline-block">
                            <b-dropdown text="Export"
                                        variant="outline-primary">
                                <b-dropdown-item @click="exportReport('pdf')">PDF</b-dropdown-item>
                                <b-dropdown-item @click="exportReport('csv')">CSV</b-dropdown-item>
                            </b-dropdown>
                        </b-overlay>
                    </b-col>
                </b-row>
        </template>
    </p-modal>        
</template>

<script>


import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    props: {
        termId: {
            type: Number,
            required: false
        },
    },
    data() {
        return {
            selectedTermId: null,
            isBusy : false,
            priceListParameters: null,
        };
    },
     components: {
    },
    methods: { 
        async exportReport(type){            
            if (!(await this.$refs.priceListForm.validate())) {
                return;
            }

            let termId = this.termId || this.selectedTermId
            let apiUrl = `/terms/exportPriceList${type === 'pdf' ? '' : 'csv'}?termId=${termId}`;
            let fileName = `Price List${type === 'pdf' ? '' : '-' + this.$store.getters.getTermById(termId)?.text}`;

            this.isBusy = true;


            axios
                .get(apiUrl, {
                    params: this.priceListParameters,
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        type === 'pdf' ? fileName : fileName + '.csv',
                        type === 'pdf' ? 'application/pdf' : 'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Price list report generated successfully.`
                        )
                        .goAway(5000);
                    
                    this.$refs.exportPriceListModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        initialize(){
            this.selectedTermId = null;
            this.priceListParameters = {
                includeCourseInfo: false,
                includePublisher: false
            }
        },
        show() {
            this.$refs.exportPriceListModal.show();
        },
    }
};

</script>