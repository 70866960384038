<template>
    <p-form @submit="onSubmit" id="edit-form">
        <div class="container-fluid content-wrapper" v-if="giftcards">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Gift Cards</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <p-button type="submit" 
                        :is-busy="isBusy"
                        form="edit-form" 
                        variant="primary"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <p-card title="Gift Card Details">
                <b-row>
                    <b-col cols="4">
                        <p-input
                            label="Gift Card Code"
                            rules="required|max:52"
                            v-model="giftcards.cardCode"
                        />
                    </b-col>
                    <b-col cols="4">
                        <p-input
                            label="PIN #"
                            v-model="giftcards.pin"
                            rules="max:4|alphanumeric"
                        />
                    </b-col>
                    <b-col cols="4">
                        <p-select
                            label="Status"
                            name="Status"
                            v-model="giftcards.status"
                            :options="giftcardstatus"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <p-datepicker
                            name="issueDate"
                            v-model="giftcards.issueDate"
                            label="Issue Date"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-datepicker
                            name="lastTransDate"
                            v-model="giftcards.lastTransactionDate"
                            label="Last Trans. Date"
                            :disabled="true"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-datepicker
                            name="expirationDate"
                            v-model="giftcards.expirationDate"
                            label="Expiration Date"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-number
                            label="Balance"
                            input-type="money"
                            v-model="giftcards.balance"
                            rules="min_value:0"
                        >
                        </p-number>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p-input
                            label="Comment"
                            rules="max:2000"
                            v-model="giftcards.comment"
                        />
                    </b-col>
                </b-row>
            </p-card>
            <!-- <p-card>
                <b-row>
                    <div class="ml-2 ">
                        <h4>Gift Card Recepient</h4>
                    </div>
                    <b-col class="text-right pb-4">
                        <p-button>Link to Customer</p-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <p-input
                            label="Recipient First Name"
                            v-model="giftcards.customerFirstName"
                            :disabled="!this.isNew"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-input
                            label="Recipient Last Name"
                            v-model="giftcards.customerLastName"
                            :disabled="!this.isNew"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-input
                            label="Recipient Email"
                            rules="email|max:254"
                            v-model="giftcards.customerEmail"
                            :disabled="!this.isNew"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-phone-number
                            label="Recipient Phone #"
                            rules="max:26"
                            v-model="giftcards.customerPhone"
                            :disabled="!this.isNew"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-advanced-select
                            label="Customer Account #"
                            data-type="customers"
                            @input="updateCustomer($event)"
                            :disabled="!this.isNew"
                        />
                    </b-col>
                </b-row>
            </p-card> -->
            <b-row>
                <b-col class=" mb-3 text-center">
                    <p-button variant="primary" type="submit" form="edit-form" :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>
<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default {
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            giftcards: null,
            isBusy: false,
            giftcardstatus: [
                { value: true, text: 'Active' },
                { value: false, text: 'Inactive' }
            ]
        };
    },
    watch: {
        giftcards: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        }
    },
    methods: {
        async updateCustomer(customerId) {
            if (!this.isNew) return;

            let promise = axios.get('customers', {
                params: { id: customerId }
            });
            promise.then(resp => {
                this.giftcards.customerFirstName = resp.data.firstName;
                this.giftcards.customerLastName = resp.data.lastName;
                this.giftcards.customerEmail = resp.data.email;
                this.giftcards.customerPhone =
                    resp.data.defaultPhone == null
                        ? null
                        : resp.data.defaultPhone.number;
                this.giftcards.customerId = customerId;
            });
        },
        async loadData() {
            let promise = this.isNew
                ? axios.get('giftcards/new')
                : axios.get('giftcards', { params: { id: this.id } });
            promise.then(resp => {
                this.giftcards = resp.data;

                if (!this.isNew) {
                    this.dataLoaded();
                }
            });
        },
        onSubmit() {
            this.isBusy = true;
            axios.post('giftcards', this.giftcards).then(response => {
                this.dataSaved();
                if (this.isNew) {
                    this.$router.push('/giftcards/' + response.data);
                }
                this.$toasted.global
                    .app_success(
                        `Gift card '${this.giftcards.cardCode}' saved successfully.`
                    )
                    .goAway(5000);
            }).finally(() => {
                        this.isBusy = false;
                    });
        }
    },
    mounted: async function() {
        await this.loadData();
    }
};
</script>
