<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="lg"
        :label="pageTitle"
        ref="editLocationModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div v-if="buybackSessionLocation">
                    <b-row>
                        <b-col cols="8">
                            <p-advanced-select
                                label="Location"
                                :disabled="!isNew"
                                data-type="locations"
                                :value="
                                    buybackSessionLocation.buybackLocationId
                                "
                                rules="required"
                                @input="locationChange($event)"
                            />
                        </b-col>
                        <b-col cols="4" class="mt-3 pt-3">
                            <p-checkbox
                                label="Student ID Required"
                                v-model="
                                    buybackSessionLocation.studentIdRequired
                                "
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                        <b-col v-show="false">
                            <p-checkbox
                                label="Student ID Required"
                                v-model="
                                    buybackSessionLocation.studentIdRequired
                                "
                            />
                        </b-col>
                        <b-col v-show="false">
                            <p-checkbox
                                label="Condition Field Prompt"
                                v-model="
                                    buybackSessionLocation.conditionFieldPrompt
                                "
                            />
                        </b-col>
                        <b-col v-show="false">
                            <p-checkbox
                                label="Queue Price Tags"
                                v-model="buybackSessionLocation.queuePriceTags"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" v-show="false">
                            <p-select
                                label="Print Receipts"
                                v-model="buybackSessionLocation.printReceipts"
                                text-field="text"
                                :options="printReceiptOptions"
                            >
                            </p-select>
                        </b-col>
                    </b-row>
                    <b-row cols="12"> </b-row>
                    <b-row>
                        <b-col cols="12" v-show="false">
                            <p-text-area
                                label="Receipt Header"
                                v-model="buybackSessionLocation.receiptHeader"
                                rules="max:255"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" v-show="false">
                            <p-text-area
                                label="Receipt Footer"
                                v-model="buybackSessionLocation.receiptFooter"
                                rules="max:255"
                            />
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
    mixins: [StoreListManager],
    props: {
        buybackSessionlocationId: [String, Number],
        buybackSessionSetupLocations: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,

            printReceiptOptions: [
                { value: true, text: 'Yes' },
                { value: false, text: 'No' }
            ],
            selectListOptions: {
                locations: []
            },
            buybackSessionLocations: [],
            buybackSessionLocation: {
                buybackLocationId: 0,
                itemWarningPercent: 90,
                transactionWarningQty: 5,
                transactionMaxQty: 5,
                studentIdRequired: false,
                conditionFieldPrompt: true,
                queuePriceTags: false,
                printReceipts: false,
                receiptHeader: '',
                receiptFooter: ''
            }
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.buybackSessionlocationId == 'new';
        },
        pageTitle() {
            return 'Session / Location Parameters';
        },
        buttonLabel() {
            return this.isNew ? 'Add' : 'Edit';
        },
        buttonVariant() {
            return this.isNew ? 'primary' : 'outline-primary';
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.$refs.editLocationModal.hide();
            this.$emit('load-data', this.buybackSessionLocation);
        },
        loadData() {
            this.locationChange(-1);
        },
        locationChange(value) {
            this.buybackSessionLocations = [
                ...this.buybackSessionSetupLocations.map(x => ({ ...x }))
            ];

            if (this.buybackSessionlocationId != 'new' || value != -1) {
                let index = this.buybackSessionLocations.findIndex(
                    x =>
                        x.buybackLocationId ==
                        (value == -1 ? this.buybackSessionlocationId : value)
                );

                if (index != -1) {
                    this.buybackSessionLocation = this.buybackSessionLocations[
                        index
                    ];
                } else {
                    if (this.buybackSessionlocationId == 'new') {
                        this.buybackSessionLocation.buybackLocationId = value;
                        this.buybackSessionLocation.itemWarningPercent = 90;
                        this.buybackSessionLocation.transactionWarningQty = 5;
                        this.buybackSessionLocation.transactionMaxQty = 5;
                        this.buybackSessionLocation.studentIdRequired = false;
                        this.buybackSessionLocation.conditionFieldPrompt = true;
                        this.buybackSessionLocation.queuePriceTags = false;
                        this.buybackSessionLocation.printReceipts = false;
                        this.buybackSessionLocation.receiptHeader = '';
                        this.buybackSessionLocation.receiptFooter = '';
                    }
                }
            } else {
                this.buybackSessionLocation = {
                    buybackLocationId: 0,
                    itemWarningPercent: 90,
                    transactionWarningQty: 5,
                    transactionMaxQty: 5,
                    studentIdRequired: false,
                    conditionFieldPrompt: true,
                    queuePriceTags: false,
                    printReceipts: false,
                    receiptHeader: '',
                    receiptFooter: ''
                };
            }
        }
    }
};
</script>
