<template>
    <ContentWrapper>
        <div v-if="showHome" class="content-heading">
            <div>Home</div>
        </div>

        <div v-if="showHome" class="row">
            <div class="col-12 text-center pb-2">
                <h2 class="text-thin">Welcome!</h2>
            </div>
            <div class="col-12 text-center">
                <img class="img-fluid" src="img/NBC_Mosaic_Logo_CMYK.png" alt="App Logo">
            </div>
        </div>

    </ContentWrapper>
</template>
<script>
    import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
    import store from '@/store/store';

    export default {
        data() {
            return {
                showHome: true
            };
        },
        mounted: async function() {
            await store.dispatch('fetchFeatureFlags');

            selectListOptionsDataContext.getStoreDropdownData('storeTimeZone').then(result => {
                window.timeZone = result;
            });
        },
    }
</script>
