var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-modal',{ref:"priceComparisonModal",attrs:{"button-label":"Price Comparison","size":"lg","label":"Price Comparison Report","disable-button":_vm.disabled},on:{"show":_vm.initializeVendor},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"container-fluid content-wrapper"},[_c('b-row',{attrs:{"cols":"1","cols-lg":"2"}},[(_vm.hasReportData)?_c('b-col',[_c('p-select',{attrs:{"label":"Supplier","filter":function (x) {
                                return (
                                    _vm.vendorIds === null ||
                                    _vm.vendorIds.includes(x.value)
                                );
                            },"data-type":"suppliers"},model:{value:(_vm.parameters.supplierId),callback:function ($$v) {_vm.$set(_vm.parameters, "supplierId", $$v)},expression:"parameters.supplierId"}}),_c('p-select',{attrs:{"label":"Report change in dollar or percentage","data-type":"dollarOrPercentage"},model:{value:(_vm.parameters.dollarOrPercentage),callback:function ($$v) {_vm.$set(_vm.parameters, "dollarOrPercentage", $$v)},expression:"parameters.dollarOrPercentage"}}),_c('p-checkbox',{attrs:{"label":"Include used price comparisons"},model:{value:(_vm.parameters.includeUsedPriceComparisons),callback:function ($$v) {_vm.$set(_vm.parameters, "includeUsedPriceComparisons", $$v)},expression:"parameters.includeUsedPriceComparisons"}})],1):_c('b-col',[_vm._v(" No data for report avaliable. Please upload a supplier's buyers guide. ")])],1)],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
                            var cancel = ref.cancel;
return [_c('b-row',[_c('b-col',[_c('p-button',{staticClass:"pr-2",attrs:{"variant":"outline-primary"},on:{"click":cancel}},[_vm._v("Cancel")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.isBusy,"rounded":"","opacity":"0.5","spinner-small":"","spinner-variant":"primary"}},[_c('b-dropdown',{attrs:{"text":"Export","variant":"outline-primary"}},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.exportPriceComparison('pdf')}}},[_vm._v("PDF")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.exportPriceComparison('csv')}}},[_vm._v("CSV")])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }