<template>
    <div class="wrapper">
        <div v-if="currentUser">
            <div v-if="roles && roles.length > 0">
                <!-- top navbar-->
                <Header />

                <!-- sidebar-->
                <Sidebar />

                <!-- Main section-->
                <section class="section-container printable">
                    <!-- Page content-->
                    <router-view/>
                </section>

                <!-- Page footer-->
                <Footer />
            </div>
            <div v-else>
                You do not have permission to access this resource.
                <br><br>
                <a href="" @click.prevent="logout" @click.middle.prevent="logout">
                        Log out and return to login page.
                </a>
            </div>
        </div>
        <div v-else>
            Logging you in...
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Header from './Header'
    import Sidebar from './Sidebar'
    import Footer from './Footer'
    import ContentWrapper from './ContentWrapper'
    import userManager from '@/services/SecurityService';
    import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

    Vue.component('ContentWrapper', ContentWrapper)

    export default {
        name: 'Layout',
        components: {
            Header,
            Sidebar,
            Footer
        },
        computed: {
            roles(){
                return this.$store.getters.getCurrentUserRoles;
            },
            currentUser(){
                return this.$store.getters.getCurrentUser;
            }
        },
        methods: {
            logout() {
                userManager.signoutRedirect();
            }
        },
        mounted: function(){
            selectListOptionsDataContext.getStoreDropdownData('customers');
             selectListOptionsDataContext.getStoreDropdownData('storeTimeZone').then(result => {
                window.timeZone = result;
            })
        }
    }
</script>