<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <div>
            <p-card>
                <b-row class="ml-2 mb-2 pl-2">
                    <CustomerAccountDetailModal
                        class="mr-2"
                        :label="'Link Account'"
                        :id="'new'"
                        :account="defaultAccount"
                        :customer="customer"
                        @result-updated="refreshTable"
                    />
                    <p-button
                        variant="outline-primary"
                        class="mr-2"
                        :is-busy="isBusy"
                        type="button"
                        @click="linkUnlinkCustomers(false)"
                    >
                        Unlink
                    </p-button>                   
                </b-row>
                <p-search-table
                    api-destination="customers"
                    ref="searchScreen"
                    search-destination="searchCustomerAccount"
                    :filters="filters"
                    :fields="fields"
                    edit-key="customerBalanceId"
                    add-bulk-is-active-toggle
                    :show-set-to="false"
                    :refreshSearchResults="refreshSearchResults"
                    :use-bootstrap-selection="true"
                    select-mode="single"
                    :selectable="true"
                    @selected="onSelection"
                    @row-selected="onSelection"
                >
                    <template v-slot:cell(edit)="{ value, item }">
                        <CustomerAccountDetailModal
                            :label="'Edit'"
                            :account="item"
                            :id="item.accountId.toString()"
                            :customer="customer"
                            @result-updated="refreshTable"
                        />
                    </template>

                    <template v-slot:cell(accountName)="{ item }">
                        <router-link
                            :to="'/accountReceivable/' + item.accountId"
                        >
                            {{ item.accountName }}
                        </router-link>
                    </template>
                    <template v-slot:cell(isActive)="{ value, item, field }">
                        <b-form-checkbox
                            v-model="item.isActive"
                            :disabled="true"
                            name="isActive"
                        >
                        </b-form-checkbox>
                    </template>

                    <template v-slot:above-table>
                        <p-form>
                            <b-row class="ml-1 mr-2">
                                <p-checkbox
                                    label="Show Inactive"
                                    v-model="filters.isInactive"
                                />
                            </b-row>
                        </p-form>
                    </template>
                </p-search-table>
            </p-card>
        </div>
    </p-secure>
</template>
<script>
import CustomerAccountDetailModal from './CustomerAccountDetailModal.vue';
import { dateFormatter, currencyFormatter } from '../../components/Common/Formatters.js';
import axios from 'axios';

export default {
    props: {
        customer: [Object]
    },
    data() {
        return {
            selectedAccount: [],
            isBusy: false,
            defaultAccount: {
                accountId: null,
                creditLimit: null,
                customerBalanceId: null,
                accountBalanceId: null,
                accountNumber: null,
                accountName: null,
                fundingStartDate: null,
                fundingEndDate: null,
                availableFund: null,
                accountComment: null,
                noLimit: false
            },
            filters: {
                automatic: '',
                searchBy: 'All',
                perPage: 100,
                isInactive: false,
                options: ['All', 'Account Code', 'Account Name']
            },
            fields: [
                {
                    key: 'Edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    label: 'Account Code',
                    key: 'accountNumber',
                    sortable: true
                },
                {
                    label: 'Account/Agency Name',
                    key: 'accountName',
                    sortable: true
                },

                {
                    key: 'fundingStartDate',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    key: 'fundingEndDate',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    label: 'Available Funds',
                    key: 'availableFund',
                    sortable: true,
                    formatter: currencyFormatter,
                    tdClass: 'text-right'
                },
                {
                    label: 'Comment',
                    key: 'accountComment',                    
                    tdClass: 'showEllipsis'
                },
                {
                    key: 'isActive',
                    disabled: true
                }
            ],
            refreshSearchResults: false
        };
    },
    methods: {
        onSelection(rows) {
            this.selectedAccount = rows;
        },
        linkUnlinkCustomers(isLink) {
            if (this.selectedAccount.length === 0) {
                this.$bvModal.msgBoxOk(isLink?`Please select an account to re-link.`:`Please select an account to unlink.`);
            }else if(isLink==true && this.selectedAccount[0].isActive==true){
                this.$bvModal.msgBoxOk('Selected account is already Linked');
            }else if(isLink==false && this.selectedAccount[0].isActive==false){
                this.$bvModal.msgBoxOk('Selected account is already Unlinked');
            } else {
                this.$bvModal
                    .msgBoxConfirm(
                        isLink?'This action will enable access to the selected account. Select OK to continue.':'This action will disable access to the selected account. Select OK to continue.'
                    )
                    .then(value => {
                        if (value) {
                            this.isBusy = true;

                            const body = {
                                accountId: this.selectedAccount[0].accountId,
                                customerIds: [this.customer.customerId],
                                isLink: isLink
                            };
                            axios
                                .post('accountreceivable/linkunlink', body)
                                .then(() => {
                                    this.isBusy = false;
                                    this.selectedCustomers = [];
                                    this.$refs.searchScreen.refreshSearchResults();
                                });
                        }
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        },
        refreshTable() {
            this.$refs?.searchScreen.refreshSearchResults();
        }
    },
    created: function() {
        this.filters.customerId = this.customer.customerId;
    },
    components: {
        CustomerAccountDetailModal
    }
};
</script>
<style scoped></style>
