<template>
    <SearchScreen
        title="Want Lists"
        api-destination="wantlists"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        add-new-button
        edit-key="wantListId">
        <template v-slot:belowSearch>
            <p-form>
                <b-row class="ml-1 mr-2">
                    <b-col cols="3">
                        <p-datepicker label="Create Date: From"
                                      vid="wantListStartDate"
                                      v-model="filters.beginStartDate" />
                    </b-col>
                    <b-col cols="3">
                        <p-datepicker label="Create Date: To"
                                      vid="wantListEndDate"
                                      v-model="filters.endStartDate"
                                      rules="dateGreaterOrEqual:@wantListStartDate"
                                      is-end-date />
                    </b-col>
                    <b-col cols="3">
                        <p-datepicker label="Last Generated Date"
                                      v-model="filters.lastGeneratedStartDate" 
                                      @end-of-selected-day="e => {filters.lastGeneratedEndDate = e}"
                                      />
                    </b-col>
                </b-row>
            </p-form>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import { dateFormatter } from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'WantListsFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy:'All',
                lastGeneratedStartDate:null,
                lastGeneratedEndDate:null,
                beginStartDate: null,
                endStartDate: null
            },
            filterBy:[
                'All',
                'Name',
                'Term',
                'SKU',
                'Catalog #',
                'ISBN',
                'Supplier'
            ],
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },
                {
                    key: 'createDate',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    key: 'lastGenerateDate',
                    label:'Last Generated Date',
                    sortable: true,
                    formatter: dateFormatter
                }
            ]
        };
    },
    components: {
        SearchScreen
    }
};
</script>

<style scoped>
</style>
