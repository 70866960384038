<template>
    <p-card class="variant-card">
        <b-card-title v-if="!centeredMode" class="text-capitalize">{{ type }}</b-card-title>
        <b-row v-if="centeredMode">
            <b-col >
                <div class="text-center w-100">
                    <strong class="text-capitalize">{{type}}</strong>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col :class="{'text-center': centeredMode, 'pt-2': centeredMode}">
                <b-button
                    v-if="this.showAddButton"
                    variant="outline-primary"
                    @click="addVariant"
                    :disabled="(!hasNew && type === 'used') || (type === 'digital' && isNew)"
                    :is-busy="isBusy"
                    >
                        <span v-if="type !== 'digital'"> <b-icon-plus v-if="this.showAddButton"></b-icon-plus> Add </span>
                        <span class="text-capitalize" v-if="type !== 'digital'">{{ type }}</span>
                        <span class="text-capitalize" v-else>Check for Update</span>
                    </b-button
                >
                <p v-else> N/A </p>
            </b-col>
        </b-row>
    </p-card>
</template>

<script>
export default {
    props: {
        type: String,
        label: String,
        hasNew: Boolean,
        centeredMode: Boolean,
        showAddButton: Boolean,
        isBusy: Boolean,
        isNew: Boolean
    },
    methods: {
        addVariant() {
            this.$emit('addVariant', this.type);
        }
    }
};
</script>
<style scoped>
>>> .variant-card {
    min-height: 140px;
    text-align: center;
}
</style>
