<template>
    <p-modal
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="md"
        :label="pageTitle"
        ref="editPurchaserModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
        :button-variant="buttonVariant"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div class="container-fluid content-wrapper" v-if="purchaser">
                    <b-row cols="1">
                        <b-col>
                            <p-input
                                label="Purchaser Name"
                                v-model="purchaser.purchaserName"
                                rules="required|max:100"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Active"
                                v-model="purchaser.isActive"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Speculative Purchaser"
                                v-model="purchaser.isSpeculative"
                            />
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';

export default {
    mixins: [NavigationGuard],
    props: {
        purchaserId: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isBusy: false,
            purchaser: null
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.purchaserId === 'new';
        },
        pageTitle() {
            return this.isNew
                ? 'Add Buyback Purchaser'
                : 'Edit Buyback Purchaser';
        },
        buttonLabel() {
            return this.isNew ? 'Create Purchaser' : 'Edit';
        },
        buttonVariant() {
            return this.isNew ? 'primary' : 'outline-primary';
        },
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            axios.post('buyback/purchaser', this.purchaser).then(() => {
                this.$emit('data-saved');
                this.$toasted.global
                    .app_success(
                        `Purchaser '${this.purchaser.purchaserName}' saved successfully.`
                    )
                    .goAway(5000);
                this.$refs.editPurchaserModal.hide();
            });
        },
        loadData() {
            this.purchaser = null;
            let promise = this.isNew
                ? axios.get('buyback/newpurchaser')
                : axios.get('buyback/purchaser', {
                      params: { id: this.purchaserId }
                  });

            promise.then(resp => {
                this.purchaser = resp.data;
                this.dataLoaded();
            });
        }
    }
};
</script>
