<template>
    <p-modal
        button-label="Price Comparison"
        size="lg"
        label="Price Comparison Report"
        ref="priceComparisonModal"
        @show="initializeVendor"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <div class="container-fluid content-wrapper">
                <b-row cols="1" cols-lg="2">
                    <b-col v-if="hasReportData">
                        <p-select
                            label="Supplier"
                            v-model="parameters.supplierId"
                            :filter="
                                x => {
                                    return (
                                        vendorIds === null ||
                                        vendorIds.includes(x.value)
                                    );
                                }
                            "
                            data-type="suppliers"
                        />

                        <p-select
                            label="Report change in dollar or percentage"
                            v-model="parameters.dollarOrPercentage"
                            data-type="dollarOrPercentage"
                        />
                        <p-checkbox
                            label="Include used price comparisons"
                            v-model="parameters.includeUsedPriceComparisons"
                        >
                        </p-checkbox>
                    </b-col>
                    <b-col v-else>
                        No data for report avaliable. Please upload a supplier's
                        buyers guide.
                    </b-col>
                </b-row>
            </div>
        </template>
        <template v-slot:modal-footer="{ cancel }">
                <b-row>
                    <b-col>
                        <p-button class="pr-2" variant="outline-primary" @click="cancel">Cancel</p-button>
                        <b-overlay :show="isBusy"
                                    rounded
                                    opacity="0.5"
                                    spinner-small
                                    spinner-variant="primary"
                                    class="d-inline-block">
                        <b-dropdown
                            text="Export"
                            variant="outline-primary"
                        >
                            <b-dropdown-item
                                @click="exportPriceComparison('pdf')"
                                >PDF</b-dropdown-item
                            >
                            <b-dropdown-item
                                @click="exportPriceComparison('csv')"
                                >CSV</b-dropdown-item
                            >
                        </b-dropdown>
                        </b-overlay>
                    </b-col>
                </b-row>
            </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        vendorIds: {
            type: Array,
            default: () => null
        }
    },
    data() {
        return {
            parameters: {
                supplierId: null,
                includeUsedPriceComparisons: false,
                dollarOrPercentage: 'Dollar'
            },
            isBusy: false
        };
    },
    computed: {
        hasReportData() {
            return this.vendorIds != null && this.vendorIds.length > 0;
        }
    },

    methods: {
        initializeVendor(){
            if (this.vendorIds && this.vendorIds.length > 0) {
                this.parameters.supplierId = this.vendorIds[0];
            }
        },
        exportPriceComparison: async function(type) {
            this.isBusy = true;

            let destination = type === 'pdf' ? '/buyersGuide/exportPriceComparison' : '/buyersGuide/exportPriceComparisonCsv';
            let message = `Buyers guide price comparison generated successfully.`;
            let fileName = 'Buyers Guide Price Comparison';
            this.exportReport(type, destination, message, fileName);
        },
        exportReport(type, destination, successMessage, fileName){
            let reportParameters = {
                ...this.parameters
                }
            this.isBusy = true;

            axios
                .post(
                    destination,
                    reportParameters,
                    { responseType: 'blob' }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        type === 'pdf' ? fileName : fileName + '.csv',
                        type === 'pdf' ? 'application/pdf' : 'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            successMessage
                        )
                        .goAway(5000);
                    this.$refs.priceComparisonModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>
