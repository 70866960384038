<template>
    <ContentWrapper>
        <b-container>
            <b-row>
                <b-col>
                    <h3>Basics</h3>
                    There are several main ways to specify permissions that are consistent across the UI and API. Each one is described here, along with their specific implementations below.
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    RequireAdmin
                </b-col>
                <b-col>
                    Anyone with the admin box checked on their user profile can view
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    RequireRole
                </b-col>
                <b-col>
                    A list of roles who are allowed to view the content. Anyone with Admin is also able to view this content.
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    PrismRBSOnly
                </b-col>
                <b-col>
                    Only Developer and Support roles are able to view this content
                </b-col>
            </b-row>
            <hr>
            <br><br>
            <b-row>
                <b-col>
                    <h3>Navigation Menu</h3>
                    On any menu or submenu item, you can limit their visibility
                    by adding any of the following properties to the item in
                    menu.js:
                    <ul>
                        <li>requireAdmin: true</li>
                        <li>requireRole: ['Role1', 'Role2']</li>
                        <li>prismRBSOnly: true</li>
                    </ul>
                </b-col>
            </b-row>
            <br><br>
            <b-row>
                <b-col>
                    <h3>Content/Components</h3>
                    Any content can be shown or hidden based on role by
                    surrounding the content with the p-secure component. Below
                    are examples of different props you can use to configure it.
                </b-col>
            </b-row>
            <br />
            <b-row>
                <b-col>
                    <b>Code</b>
                </b-col>
                <b-col>
                    <b>Result (based on your current role)</b>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col>
                    &lt;p-secure require-admin&gt;<i> content </i>&lt;/p-secure&gt;
                </b-col>
                <b-col>
                    <p-secure require-admin>
                        <div>Admins can see this!!!</div>
                    </p-secure>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col>
                    &lt;p-secure :require-role="['GM Buyer','Cashier']"&gt;<i> content </i>&lt;/p-secure&gt;
                </b-col>
                <b-col>
                    <p-secure :require-role="['GM Buyer','Cashier']">
                        <div>GM Buyers, Cashiers (and admins) can see this!!</div>
                    </p-secure>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col>
                    &lt;p-secure prism-rbs-only&gt;<i> content </i>&lt;/p-secure&gt;
                </b-col>
                <b-col>
                    <p-secure prism-rbs-only>
                        <div>PrismRBS can see this!!</div>
                    </p-secure>
                </b-col>
            </b-row>
            <br><br>

            <b-row>
                <b-col>
                    <h3>API</h3>
                    The API uses controller and method attributes to secure endpoints. The following attributes can be placed on a controller or individual method.
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    [RequireAdmin]
                </b-col>
                <b-col>
                    Anyone with the admin box checked on their user profile can access this endpoint
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    [RequireRole("GM Buyer","Cashier")]
                </b-col>
                <b-col>
                    A list of roles who are allowed to access the endpoint. Anyone with Admin is also able to access this endpoint.
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    [PrismRBSOnly]
                </b-col>
                <b-col>
                    Only Developer and Support roles are able to access this endpoint
                </b-col>
            </b-row>
        </b-container>
        <br><br>
        <b-container>
            <b-row>
                <b-col>
                    <h3>Feature Flags</h3>
                </b-col>
            </b-row>
            <br />
            <b-row>
                <b-col>
                    <b>Code</b>
                </b-col>
                <b-col>
                    <b>Result</b>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col>
                    &lt;p-feature-flag name="Mosaic"&gt;<i> content </i>&lt;/p-feature-flag&gt;
                </b-col>
                <b-col>                    
                    <div>This is visible if "Mosaic" feature flag is enabled</div>                    
                </b-col>
            </b-row>
        </b-container>
    </ContentWrapper>
</template>

<script>
export default {};
</script>

<style></style>