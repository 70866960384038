<template>
    <p-modal
        ref="sessionTermModel"
        :button-label="buttonLabel"
        :icon-plus="isNew"
        size="lg"
        :label="modelLabel"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div v-if="buyBackSesionLocation && terms && amountToUse">
                    <b-row cols="1" cols-md="2">
                        <b-col>
                            <p-advanced-select
                                label="Location"
                                :options="buyBackSesionLocation"
                                v-model="term.locationId"
                                @change="loadTerms($event)"
                                :disabled="isDisabledLocation"
                                rules="required"
                            />
                        </b-col>
                        <b-col>
                            <p-advanced-select
                                label="Term"
                                :options="terms"
                                :filter="termFilter"
                                no-selection-message=""
                                rules="required"
                                :disabled="isDisabledTerm"
                                v-model="term.termId"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-md="3">
                        <b-col>
                            <p-advanced-select
                                label="Amount to Use"
                                :options="amountToUse"
                                text-field="text"
                                value-field="value"
                                no-selection-message=""
                                v-model="term.amountToUse"
                                rules="required"
                                @change="handleAmountToUseChange"
                            />
                        </b-col>
                        <b-col v-if="isShowPercentOfQTP">
                            <p-number
                                v-model="term.qtppercent"
                                input-type="percent"
                                rules="p-decimal:5,2|min_value:1"
                                label="Percent of Qtp"
                            />
                        </b-col>
                        <b-col>
                            <p-advanced-select
                                label="Subtract Buyback"
                                data-type="buybackToSubtractOptions"
                                text-field="text"
                                value-field="value"
                                v-model="term.buybackToSubtract"
                                no-selection-message=""
                                rules="required"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-md="2">
                        <b-col v-if="isShowSubtractOrderShort">
                            <p-checkbox
                                label="Subtract Order Short"
                                v-model="term.subtractOrderShort"
                            >
                            </p-checkbox>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="primary"
                        @click="ok"
                        class="ml-2"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import axios from 'axios';
import Vue from 'vue';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        buybackSessionId: {
            type: [String, Number],
            default: 0
        },
        buybackLocationId: {
            type: [String, Number],
            default: 0
        },
        termId: {
            type: [String, Number],
            default: 0
        },
        index: {
            type: [String, Number],
            default: 0
        },
        buybackListLocationTerms: {
            type: Array,
            default: () => null
        }
    },
    data() {
        return {
            isBusy: false,
            buyBackSesionLocation: [],
            terms: [],
            buybackListTerms: [],
            term: {
                buybackListLocationTermId: 0,
                locationId: 0,
                termId: 0,
                amountToUse: 0,
                qtppercent: 0,
                subtractOrderShort: false,
                locationName: '',
                termName: '',
                buybackToSubtract: '',
                index: -1
            },
            amountToUse: [
                { value: 'Estimated Buyback', text: 'Estimated Buyback' },
                { value: 'Maximum Buyback', text: 'Maximum Buyback' },
                { value: 'Percent of QTP', text: 'Percent of QTP' },
                { value: 'Open to Buy', text: 'Open to Buy' }
            ]
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        },
        buybackListLocationTerms: {
            deep: true,
            handler: function(newVal) {
                this.buybackListTerms = newVal;
            }
        }
    },
    components: {},
    computed: {
        isNew() {
            return this.buybackLocationId === 'new';
        },
        buttonLabel() {
            return this.isNew ? 'Add Location/Term' : 'Edit';
        },
        modelLabel() {
            return this.isNew ? 'Add Term/Location' : 'Edit Term/Location';
        },
        isShowPercentOfQTP() {
            return this.term.amountToUse == 'Percent of QTP';
        },
        isShowSubtractOrderShort() {
            return (
                this.term.amountToUse == 'Percent of QTP' ||
                this.term.amountToUse == 'Open to Buy'
            );
        },
        isDisabledTerm() {
            return (
                this.term.locationId == 0 ||
                this.term.locationId == null ||
                !this.isNew
            );
        },
        isDisabledLocation() {
            return !this.isNew;
        }
    },
    methods: {
        async handleModalOk() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.term.LocationName = this.buyBackSesionLocation.find(
                x => x.buybackLocationId == this.term.locationId
            ).name;
            this.term.TermName = this.terms.find(
                x => x.termId == this.term.termId
            ).name;
            this.term.index = this.index;
            this.$emit('load-data', this.term);
            this.$refs.sessionTermModel.hide();
        },
        loadTerms() {
            let locationId = this.buyBackSesionLocation.find(
                x => x.buybackLocationId == this.term.locationId
            ).buybackLocationId;

            let promise = axios.get('locations/terms', {
                params: {
                    id: locationId,
                    sessionId: this.buybackSessionId,
                    termId: !this.isNew ? this.term.termId : 0
                }
            });

            promise.then(resp => {
                this.terms = resp.data;
                this.terms.forEach(x => {
                    (x.text = x.name), (x.value = x.termId);
                });
            });
        },
        async loadData() {
            let promise = axios.get('buyback/sessionlocation', {
                params: { sessionId: this.buybackSessionId }
            });

            promise.then(resp => {
                this.buyBackSesionLocation = resp.data;

                this.buyBackSesionLocation.forEach(x => {
                    (x.text = x.name), (x.value = x.buybackLocationId);
                });

                if (this.isNew) {
                    this.terms = [];
                    this.term.buybackListLocationTermId = 0;
                    this.term.locationId = 0;
                    this.term.termId = 0;
                    this.term.amountToUse = 0;
                    this.term.qtppercent = 0;
                    this.term.subtractOrderShort = false;
                    this.term.locationName = '';
                    this.term.termName = '';
                    this.term.buybackToSubtract = '';
                    this.term.index = -1;
                } else {
                    if (this.buybackLocationId != 0) {
                        let index = this.buybackListTerms.findIndex(
                            x =>
                                x.locationId == this.buybackLocationId &&
                                x.termId == this.termId
                        );

                        if (index != -1) {
                            this.term = Vue.util.extend(
                                {},
                                this.buybackListTerms[index]
                            );
                        }

                        this.loadTerms();
                    }
                }
            });
        },
        handleAmountToUseChange() {
            if (this.term.amountToUse != 'Percent of QTP') {
                this.term.qtppercent = 0;
            }
        },
        termFilter(term) {
            return (
                !this.isNew ||
                !this.buybackListLocationTerms.some(
                    x => x.termId === term.termId
                )
            );
        }
    },
    created: function() {
        this.buybackListTerms = this.buybackListLocationTerms;
    }
};
</script>
