import { max} from 'vee-validate/dist/rules';
import { pDecimalValidationTest } from '@/components/mixins/ValidatedInput.js';

export default {
    data() {
        return {
            taxRateConfiguration: {
                type: 'taxRates',
                label: 'Tax Rates',
                sampleNotes: ['Jurisdiction is typically a zip code.',                
                    'State must be provided as a valid abbreviation.'
                ],
                importModes: [
                    {value:'add', text:'Add New (Skip when jurisdiction is already present)'},
                    {value:'update', text:'Update (Skip when account jurisdiction is not present, update when found)'},
                    {value:'addAndUpdate', text:'Add and Update'}
                ],
                
                
                fields: [
                    {
                        key: 'Jurisdiction',
                        hasErrorCalculator:  (value) => {
                            return (
                                this.isEmpty(value)
                                || !max.validate(value, { length: 40 })
                            );
                        },
                        
                        rules: {
                            max: {length: 40},
                            required: true
                        },
                        samples: ['"77449"','"11368"','"60629"']
                    },  
                    {
                        key: 'State',
                        dataType: 'states',
                        comparisonProperty: 'abbreviation',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        rules: 'min_value:0|required',
                        samples: ['"TX"', '"NY"', '"IL"']
                    },    
                    {
                        key: 'City',
                        hasErrorCalculator: value => {
                            return  !max.validate(value, { length: 50 });
                        },
                        rules: {
                            max: {length: 50}
                        },
                        samples: ['"Houston"', '"New York City"', '"Bedford Park"']
                    }, 
                    {
                        key: 'TaxRate1',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return ( (!pDecimalValidationTest(value, {
                                    precision: '6',
                                    scale: '4'
                                    })
                                    || value < 0 || value > 99.9999)
                            );
                        },
                        rules: 'p-decimal:6,4|min_value:0|max_value:99.9999',
                        convertToNumber: true,
                        samples: ['1.0', '2.5', '3.25']
                    },
                    {
                        key: 'TaxRate2',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return ((!pDecimalValidationTest(value, {
                                    precision: '6',
                                    scale: '4'
                                    })
                                    || value < 0 || value > 99.9999)
                            );
                        },
                        rules: 'p-decimal:6,4|min_value:0|max_value:99.9999',
                        convertToNumber: true,
                        samples: ['1.0', '0', '0']
                    },
                    {
                        key: 'TaxRate3',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return ((!pDecimalValidationTest(value, {
                                    precision: '6',
                                    scale: '4'
                                    })
                                    || value < 0 || value > 99.9999)
                            );
                        },
                        rules: 'p-decimal:6,4|min_value:0|max_value:99.9999',
                        convertToNumber: true,
                        samples: ['0', '5.5', '4.25']
                    },
                    {
                        key: 'TaxRate4',
                        hasErrorCalculator: value => {
                            if (this.isEmpty(value)) {
                                return false;
                            }
                            return ((!pDecimalValidationTest(value, {
                                    precision: '6',
                                    scale: '4'
                                    })
                                    || value < 0 || value > 99.9999)
                            );
                        },
                        rules: 'p-decimal:6,4|min_value:0|max_value:99.9999',
                        convertToNumber: true,
                        samples: ['4.0', '5.5', '']
                    }
                ]
            }
        };
    },
    methods: {
        
    } 
};
