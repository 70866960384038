import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
export default {
    props: {
        id: String
    },
    computed: {
        isNew() {
            return this.id === 'new';
        }
    },
    watch: {
        $route: function (to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        async getSelectedLocationId() {
          return await selectListOptionsDataContext.getSelectedLocationIdAsync();
        }
    }
};
