<template>
    <p-card title="Active & Open Terms">
        <b-row>
            <b-col style="height: 150px; overflow-y: auto;">
                <p-table
                    :items="terms"
                    :enable-row-selection="false"
                    :fields="termWidgetFields"
                    :is-busy="isBusy"
                    :pagination="false"
                    :showRecordCount="false"
                    :perPage="50"
                    v-if="terms != null"
                >
                    <template v-slot:cell(nameAndDescription)="{ item }">
                        <router-link
                            v-if="item.termId != termId"
                            :to="`/terms/${item.termId}`"
                        >
                            {{ item.nameAndDescription }}
                        </router-link>
                        <template v-else>
                            {{ item.nameAndDescription }}
                        </template>
                    </template>
                </p-table>
            </b-col>
        </b-row>
    </p-card>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            isBusy: false,
            termId: {
                type: Number,
                default: null
            },
            terms: null,
            termWidgetFields: [
                {
                    key: 'nameAndDescription',
                    label: ''
                },
                {
                    key: 'status',
                    label: ''
                }
            ]
        };
    },
    mounted: async function() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.isBusy = true;
            axios
                .get('dashboard/terms')
                .then(resp => {
                    if (resp.data != null) {
                        this.terms = resp.data;
                    }
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>
