<template>
    <p-modal
            name='outstandingCreditRequestModal'
            label='Export Outstanding Credit Request'
                size='lg'
                @hide='resetFilters'>
             <template v-slot:default>
                <div class='container-fluid content-wrapper'>
                    <b-row>
                        <b-col>
                            <p-advanced-select
                                data-type='suppliers'
                                label='Supplier'
                                v-model='filters.supplierId'
                                add-empty-option />
                            <p-checkbox
                                label='Show Detail'
                                v-model='filters.showDetail' />
                            <p-checkbox
                                label='Include list of credit memos'
                                v-model='filters.showMemos' />
                            <p-select
                                :options='sortOrderOptions'
                                label='Sort Order'
                                v-model='filters.sortOrder' />
                        </b-col>
                        <b-col>
                            <p-card class='mb-2 pl-2 py-2' no-body>
                                <label class='text-sm' v-if='dccSelectorHelpText'>{{dccSelectorHelpText}}</label>
                                <DCCsSelector class='container-fluid' @selection-change='filters.dccIds = $event' />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
             </template>
             <template v-slot:modal-footer>
                 <p-button @click='$bvModal.hide("outstandingCreditRequestModal")'
                            variant='outline-primary'>Cancel</p-button>
                 <b-overlay :show='isBusy'
                                    rounded
                                    opacity='0.5'
                                    spinner-small
                                    spinner-variant='primary'
                                    class='d-inline-block'>
                        <b-dropdown
                            text='Export'
                            variant='primary'
                        >
                            <b-dropdown-item
                                @click='exportOutstandingCreditRequestReport("pdf")'
                                >PDF</b-dropdown-item
                            >
                            <b-dropdown-item
                                @click='exportOutstandingCreditRequestReport("csv")'
                                >CSV</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-overlay>
             </template>
        </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import DCCsSelector from '@/components/DCCsSelector.vue';

export default {
    data() {
        return {
            filters: {
                sortOrder: 'SKU',
                showMemos: false,
                showDetail: false,
                supplierId: null,
                dccIds: []
            },
            isBusy: false,
            sortOrderOptions: [
                'SKU',
                'Description'
            ]
        };
    },
    components: {
        DCCsSelector
    },
    computed: {
        dccSelectorHelpText() {
            return this.filters.dccIds.length === 0
                ? 'ALL DCCs will be included in the report, you may select individual DCCs below.'
                : '';
        }
    },
    methods: {
        exportOutstandingCreditRequestReport(exportFormat) {
            this.isBusy = true;
            if (this.filters.dccIds.length > 0) {
                //the dcc selector returns an object
                //for selected dccs, just need the
                //dccId
                this.filters.dccIds = this.filters.dccIds.map(d => d.dccId);
            }

            switch(exportFormat)
            {
                case 'pdf':
                    axios.post('outstandingcreditrequests/export', this.filters, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Outstanding Credit Requests', 'application/pdf');
                                this.$toasted.global.app_success(`Outstanding Credit Requests report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport('outstandingCreditRequestModal');
                        });
                    break;
                case 'csv':
                    axios.post('outstandingcreditrequests/exportcsv', this.filters, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'Outstanding Credit Requests.csv', 'application/csv');
                                this.$toasted.global.app_success(`Outstanding Credit Requests report generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.finalizeExport('outstandingCreditRequestModal');
                        });
                    break;
            }
        },
        resetFilters: function(){
            this.filters = {
                sortOrder: 'SKU',
                showMemos: false,
                showDetail: false,
                supplierId: null,
                dccIds: []
            };
        },
        finalizeExport(modalName) {
            this.isBusy = false;
            this.$bvModal.hide(modalName);
        }
    }
}
</script>