<template>
    <b-row cols="1" cols-lg="3">
        <b-col>
            <p-box-widget
                primary-bg="bg-blue-darker"
                secondary-bg="bg-blue"
                :primary-text="'$' + numberFormatter(dailySales.totalSales)"
                secondary-text="Total Sales Today"
                primary-text-color="#fff"
                secondary-text-color="#fff"
            >
                <i class="fas fa-dollar-sign fa-3x"></i>
            </p-box-widget>
        </b-col>
        <b-col>
            <p-box-widget
                primary-bg="bg-gray-darker"
                secondary-bg="bg-gray-dark"
                :primary-text="'$' + numberFormatter(dailySales.posSales)"
                secondary-text="POS Sales Today"
            >
                <i class="fas fa-cash-register fa-3x"></i>
            </p-box-widget>
        </b-col>
        <b-col>
            <p-box-widget
                primary-bg="bg-primary"
                secondary-bg="bg-orange-light"
                :primary-text="'$' + numberFormatter(dailySales.webSales)"
                secondary-text="Web Sales Today"
            >
                <i class="fas fa-shopping-cart fa-3x"></i>
            </p-box-widget>
        </b-col>
    </b-row>
</template>
<script>
import axios from 'axios';
import {numberFormatter} from '@/components/Common/Formatters.js';
export default {
    data() {
        return {
            dailySales: {
                webSales:"",
                posSales:"",
                totalSales:""
            }
        };
    },
    methods: {
        numberFormatter: numberFormatter,
        loadData() {
            axios.get('dashboard/dailysales').then(resp => {
                this.dailySales = resp.data;
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
