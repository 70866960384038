<template>
    <p-modal
        :button-label="buttonLabel"
        size="md"
        :label="pageTitle"
        ref="purchaserPackingListModal"
        @ok.prevent="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div>
                    <b-row>
                        <b-col>
                            <p-datepicker
                                label="Start Date"
                                vid="startDate"
                                v-model="purchaserPackingList.startDate"
                            />
                        </b-col>
                        <b-col>
                            <p-datepicker
                                label="End Date"
                                vid="endDate"
                                v-model="purchaserPackingList.endDate"
                                rules="dateGreaterOrEqual:@startDate"
                                is-end-date
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="pt-6">
                                <h4>Purchasers</h4>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-checkbox
                                label="All"
                                @change="toggleSelectAll()"
                                :value="selectAll"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-checkbox-group
                                stacked
                                :options="options"
                                v-model="purchaserPackingList.buybackPurchasers"
                                @change="isSelected()"
                            />
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
                <b-col class="mb-3 text-center">
                    <p-button
                        :is-busy="isBusy"
                        variant="outline-primary"
                        @click="ok"
                        class="ml-2"
                        >Generate Report</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        buybackSessionSetupPurchasers: {
            type: Array,
            default: () => null
        }
    },
    created: function() {
        this.buybackSessionPurchasers = this.buybackSessionSetupPurchasers;
    },
    data() {
        return {
            isBusy: false,
            buybackSessionPurchasers: [],
            options: [],

            selectAll: false,
            purchaserPackingList: {
                startDate: null,
                endDate: null,
                buybackPurchasers: []
            }
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        },
         buybackSessionSetupPurchasers: {
            handler: function(newVal) {

                this.buybackSessionPurchasers = newVal;

            }
        }
    },
    computed: {
        pageTitle() {
            return 'Purchaser Packing List';
        },
        buttonLabel() {
            return 'Packing List';
        }
    },
    methods: {
        handleModalOk: async function() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            if (this.purchaserPackingList.buybackPurchasers.length === 0) {
                this.$toasted.global
                    .app_error(
                        `Please select at least one purchaser to generate report.`
                    )
                    .goAway(5000);

                return;
            }

            this.isBusy = true;

            await axios
                .post(
                    'buyback/exportPurchaserPackingList',
                    this.purchaserPackingList,
                    {
                        responseType: 'blob'
                    }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Purchaser Packing List',
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            `Purchaser packing list generated successfully.`
                        )
                        .goAway(5000);
                    this.$refs.purchaserPackingListModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        loadData() {
            this.purchaserPackingList.buybackPurchasers = [];
            this.purchaserPackingList.startDate = null;
            this.purchaserPackingList.endDate = null;

            this.options = this.buybackSessionPurchasers.map(x => ({
                text: x.name,
                value: x.buybackPurchaserId
            }));

            this.purchaserPackingList.buybackSessionId = this.buybackSessionPurchasers[0].buybackSessionId;
        },
        toggleSelectAll() {
            if (!this.selectAll) {
                this.options.forEach(elem => {
                    if (
                        !this.purchaserPackingList.buybackPurchasers.some(
                            x => x === elem.value
                        )
                    ) {
                        this.purchaserPackingList.buybackPurchasers.push(
                            elem.value
                        );
                    }
                });
            } else {
                this.purchaserPackingList.buybackPurchasers = [];
            }
        },
        isSelected() {
            this.selectAll =
                this.purchaserPackingList.buybackPurchasers.length ===
                this.options.length
                    ? true
                    : false;
        }
    }
};
</script>
