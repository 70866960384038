<template>
    <SearchScreen
        title="Sale To Wholesale"
        api-destination="wholesale"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        add-new-button
        edit-key="wholesaleId"
    >
        <template v-slot:belowSearch>
            <b-row class="ml-3 mt-4 mr-2">
                <p-checkbox
                    label="Proposed"
                    class=" ml-2 "
                    v-model="filters.proposed"
                />
                <p-checkbox label="Open" class=" ml-3" v-model="filters.open" />
                <p-checkbox
                    label="Closed"
                    class="ml-3"
                    v-model="filters.closed"
                />
                <p-datepicker
                    label="Wholesale Date"
                    class="ml-3"
                    v-model="filters.createStartDate"
                    @end-of-selected-day="e => {filters.createEndDate = e}"
                />
            </b-row>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '@/components/SearchScreen.vue';
import {
    dateFormatter,
    currencyFormatter
} from '@/components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'WholesaleFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'createDate',
                sortDesc: true,
                searchBy: 'All',
                proposed: true,
                open: true,
                closed: false,
                createStartDate: null,
                createEndDate:null,
            },
            filterBy: [
                'All',
                'Wholesale #',
                'SKU',
                'ISBN',
                'Barcode',
                'Supplier'
            ],
            fields: [
                {
                    key: 'wholesaleNumber',
                    sortable: true
                },
                {
                    key: 'supplier',
                    sortable: true
                },
                {
                    key: 'createDate',
                    label: 'Wholesale Date',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    key: 'total',
                    sortable: true,
                    formatter: currencyFormatter
                },
                {
                    key: 'status',
                    sortable: true
                }
            ]
        };
    },
    components: {
        SearchScreen
    }
};
</script>
