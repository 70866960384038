<template>
    <p-modal
        name="rentalReconciliationModal"
        label="Rental Reconciliation"
        ref="rentalReconciliation"
        size="lg"
        @ok.prevent="handleModalOk"
    >
        <template v-slot:default>
            <p-form ref="rentalReconciliationForm">
                <div class="container-fluid content-wrapper">
                    <b-row>
                        <b-col>
                            <p>Rental Reconciliation by Term</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-card>
                            <b-row cols="1" cols-lg="2">
                            <b-col>
                                <p-select
                                    label="Rental Source"
                                    :options="sourceOptions"
                                    v-model="reportParameters.rentalSource"
                                   :disabled='true'
                                />
                            </b-col>
                            <b-col>
                                <p-advanced-select
                                    shortDropdown
                                    dataType="rentalterms"
                                    name="Term"
                                    label="Rental Term"
                                    v-model="reportParameters.termId"
                                    :value="reportParameters.termId"
                                    rules="required"
                                    compact-format
                                />
                            </b-col>
                            </b-row>
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template  v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="cancel"
                        >Cancel</p-button
                    >
                    <p-button
                        variant="primary"
                        @click="ok"
                        :is-busy="isBusy"
                        class="ml-2"
                        >Export CSV</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import pCard from '@/components/p-card.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    components: { pCard },
    data() {
        return {
            isBusy: false,
            disabled: true,
            reportParameters: {
                registerIds: [],
                rentalSource: 'All Sources',
                termId: null
            },
            sourceOptions: [
                { value: 'All Sources', text: 'All Sources' },
                { value: 'Store Managed', text: 'Store Managed' },
                { value: 'None', text: 'None' }
            ]
        };
    },
    computed: {
        pageTitle() {
            return 'Rental Reconciliation';
        }
    },
    methods: {
        async show() {
            this.reportParameters.registerIds = [];
            this.reportParameters.termId = 0;
            this.reportParameters.rentalSource = 'Store Managed';

            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.$refs.rentalReconciliation.show();
        },
        handleModalOk: async function() {
            if (!(await this.$refs.rentalReconciliationForm.validate())) {
                return;
            }

            if (this.reportParameters.termId === 0) {
                this.$toasted.global.app_error(
                    'Please select a term.'
                );
                return;
            }

            this.isBusy = true;

            await axios
                .post(
                    '/sales/exportRentalReconciliationCsv',
                    this.reportParameters,
                    {
                        responseType: 'blob'
                    }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Rental Reconciliation.csv',
                        'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Rental reconciliation report generated successfully.`
                        )
                        .goAway(5000);
                    this.$refs.rentalReconciliation.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
};
</script>

<style scoped>
.static-height {
    height:370px
}
</style>
