<template>
    <SearchScreen
        title="Product Variants"
        api-destination="productvariant"
        edit-key="productVariantId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :show-set-to="false"
        :use-is-active-checkbox="false"
        add-new-button
    >
        <template v-slot:belowSearch>
            <b-row class=" mt-2">
                <p-checkbox
                    label="Active"
                    class="ml-3"
                    v-model="filters.active"
                />
                <p-checkbox
                    label="Inactive"
                    class="ml-2"
                    v-model="filters.inactive"
                />
            </b-row>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            isBusy: false,
            filterName: 'ProductVariantFilter',
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'variantName',
                sortDesc: true,
                searchBy: 'Web Order Number',
                active: true,
                inactive: false
            },
            filterBy: ['All', 'Variant Name'],
            fields: [
                {
                    key: 'variantName',
                    sortable: true,
                    label: 'Variant Name'
                },
                {
                    key: 'category',
                    sortable: true,
                    label: 'Category'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }
            ]
        };
    },
    methods: {}
};
</script>