<template>
    <p-form ref="creditRequestForm" :id="formId">
        <div>
            <b-row>
                <b-col cols="12" class="d-flex">
                    <ExportCreditRequest
                        :credit-request="creditRequest"
                        :is-busy="isBusy"
                        v-if="purchaseOrderView"
                    >
                    </ExportCreditRequest>
                     <p-button
                        class="mb-2 ml-2 mr-2"
                        variant="outline-danger"
                        v-if="purchaseOrderView"
                        :is-busy="isBusy"
                        :disabled="!statusProposed"
                        @click="$emit('deleteCreditRequest')"
                        >Delete</p-button
                    >
                    <p-button
                        variant="primary"
                        class="mb-2 ml-auto"
                        v-if="purchaseOrderView"
                        type="submit"
                        :is-busy="isBusy"
                        form="purchase-order-form"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-xl="4" class="mt-3">
                <b-col>
                    <p-advanced-select
                        label="Supplier"
                        dataType="suppliers"
                        v-model="creditRequest.supplierId"
                        rules="required"
                        :disabled="statusOpen || statusClosed"
                    />
                </b-col>
                <b-col>
                    <p-select
                        label="Type"
                        :options="creditRequestTypeOptions"
                        v-model="creditRequest.type"
                        :disabled="statusOpen || statusClosed"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Request Number"
                        disabled
                        v-model="creditRequest.requestNumber"
                        rules="required"
                    />
                </b-col>
                <b-col>
                      <p-select
                        label="Status"
                        data-type="poStatus"
                        :value="creditRequest.status"
                        rules="required"
                        @input="setStatus($event)"
                        :key="creditRequest.status + validationCheck"
                    />
                </b-col>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-xl="4">
                <b-col>
                    <p-datepicker
                        label="Permission Date"
                        @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                        v-model="creditRequest.permissionDate"
                        :disabled="statusOpen || statusClosed"
                    />
                </b-col>
                <b-col>
                    <p-datepicker
                        label="Create Date"
                        @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                        v-model="creditRequest.createDate"
                        disabled
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="RMA Number"
                        v-model="creditRequest.rmaNumber"
                        rules="max:100"
                        :disabled="statusOpen || statusClosed"
                    />
                </b-col>
                <b-col>
                    <p-number
                        label="Postage"
                        input-type="money"
                        v-model="creditRequest.postage"
                        rules="min_value:0"
                        :disabled="statusOpen || statusClosed"
                    />
                </b-col>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-xl="4">
                <b-col>
                    <p-select
                        label="Return Policy"
                        data-type="supplierReturnPolicies"
                        v-model="creditRequest.returnPolicyId" 
                    />
                </b-col>
                <b-col>
                    <p-number
                        label="Max Return Time"
                        :value="creditRequest.maxReturnTime"
                        rules="min_value:0"
                        :disabled="true"
                    />
                </b-col>
                 <b-col>
                    <p-input
                        label="Return Comment"
                        :value="creditRequest.returnComment"
                        :disabled="true"
                    />
                </b-col>
                <b-col>
                    <p-checkbox
                        v-if="!purchaseOrderView"
                        class="pt-4"
                        label="Include invoice information?"
                        :disabled="creditRequest.creditRequestDetails.length > 0 || statusOpen || statusClosed"
                        v-model="creditRequest.includeInvoiceInformation"
                    />
                </b-col>
            </b-row>
            <b-row cols="1" cols-sm="2" cols-xl="4">
                <b-col>
                    <p-checkbox
                        class="pt-4"
                        label="Update Order Decisions?"
                        v-if="!purchaseOrderView && !creditRequest.includeInvoiceInformation"
                        :disabled="statusOpen || statusClosed"
                        v-model="creditRequest.updateOrderDecisions"
                    />
                </b-col>
                <b-col>
                    <p-advanced-select
                        v-if="!purchaseOrderView && !creditRequest.includeInvoiceInformation && creditRequest.updateOrderDecisions"
                        v-model="creditRequest.termId"
                        :disabled="statusOpen || statusClosed"
                        label="Term"
                        data-type="terms"
                        rules="required"
                    ></p-advanced-select>
                </b-col>
            </b-row>
            <b-row v-if="!statusProposed" class="text-right">
                <b-col>
                    <p-button
                        variant="outline-primary"
                        @click="addMemo"
                        :disabled="statusClosed"
                        >Add Memo</p-button
                    >
                </b-col>
            </b-row>
            <b-row v-if="!statusProposed">
                <b-col cols="12">
                    <p-table
                        :items="creditRequest.creditMemos"
                        :fields="tableDefinition.memoFields"
                        :enable-row-selection="false"
                    >
                        <template
                            v-slot:cell(memoCheckNumber)="{ value, item }"
                        >
                            <p-input
                                compact-format
                                :rules="memoCheckNumberRules(item)"
                                v-model="item.memoCheckNumber"
                                name="Memo/Check Number"
                                :disabled="statusClosed"
                            />
                        </template>
                        <template v-slot:cell(memoDate)="{ value, item }">
                            <p-datepicker
                                @date-picker-initialized="e => {if(item.creditMemoId){$emit('date-picker-initialized');}}"
                                compact-format
                                rules="required"
                                v-model="item.memoDate"
                                name="Memo Date"
                                :disabled="statusClosed"
                            />
                        </template>
                        <template v-slot:cell(memoAmount)="{ value, item }">
                            <p-number
                                compact-format
                                input-type="money"
                                rules="required|min_value:0"
                                v-model="item.memoAmount"
                                name="Memo Amount"
                                :disabled="statusClosed"
                            />
                        </template>
                        <template v-slot:cell(postage)="{ value, item }">
                            <p-number
                                compact-format
                                input-type="money"
                                text-only
                                :value="value"
                            />
                        </template>
                        <template v-slot:cell(isCheck)="{ value, item }">
                            <p-checkbox
                                v-model="item.isCheck"
                                :disabled="statusClosed"
                            />
                        </template>
                        <template v-slot:cell(batchResolve)="{ value, item }">
                            <p-button
                                compact-format
                                @click="!statusClosed && batchResolveMemo(item)"
                                :disabled="
                                    statusClosed ||
                                        memoStatus(item) === 'Resolved'
                                "
                                >Batch Resolve</p-button
                            >
                        </template>
                        <template v-slot:cell(batchCancel)="{ value, item }">
                            <p-button
                                compact-format
                                @click="!statusClosed && batchCancelMemo(item)"
                                :disabled="
                                    statusClosed ||
                                        memoStatus(item) === 'Resolved'
                                "
                                >Batch Cancel</p-button
                            >
                        </template>
                        <template v-slot:cell(status)="{ item }">
                            {{ memoStatus(item) }}
                        </template>
                        <template v-slot:cell(memoDetails)="{ item }">
                            <b-button
                                compact-format
                                class="p-1 ml-1"
                                @click="showMemoDetailModal(item)"
                                :disabled="!creditHasMemo"
                                >Memo Details</b-button
                            >
                        </template>
                        <template v-slot:cell(removeDetail)="{ value, item }">
                            <b-button class="p-1 ml-1 short-button"
                                    :disabled="statusClosed"
                                    @click="
                                        !statusClosed && confirmDeleteMemo(item)
                                    ">
                                <BIconTrash
                                    font-scale="0.9"
                                    icon="trash"
                                    class="mb-2"
                                ></BIconTrash>
                            </b-button>
                        </template>
                    </p-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p-table
                        :items="creditRequest.creditRequestDetails"
                        :selection-actions="tableActions"
                        :selection-actions-in-title="true"
                        :fields="filteredRequestDetailFields"
                        :enable-row-selection="tableActions.length > 0"
                        sort-by="authorTitleOrDescription"
                    >
                        <template v-slot:title-line>
                            <div class="ml-auto mb-2 d-inline-block">
                                <h4
                                    class="d-inline-block mr-3"
                                >
                                    Products
                                </h4>
                                <ProductCreditRequestQuickSearch
                                    class="d-inline-block"
                                    :invoices="invoices"
                                    :include-invoices="creditRequest.includeInvoiceInformation"
                                    :credit-request-id="creditRequest.creditRequestId"
                                    :supplier-id="creditRequest.supplierId"
                                    :term-id="creditRequest.termId"
                                    :items="addProductOptions"
                                    :disable-add-button="!statusProposed"
                                    :already-selected-invoice-detail-ids="alreadySelectedInvoiceDetailIds"
                                    :already-included-skus="alreadyIncludedSkus"
                                    @selections="addProducts"
                                    :purchase-order-view="purchaseOrderView"
                                >
                                </ProductCreditRequestQuickSearch>
                            </div>
                        </template>
                        <template v-slot:above-table>
                            <p class="text-bold mb-0"> Credit Total: {{ creditTotal }}</p>
                        </template>
                        <template v-slot:cell(invoiceNumber)="{ value, item }">
                            <router-link v-if="!purchaseOrderView" :to="`/invoices/${item.invoiceId}`">
                                <nobr> {{value}}</nobr>
                            </router-link>
                            <span v-else>{{value}}</span>
                        </template>
                        <template v-slot:cell(discount)="{ value, item }">
                            <p-number
                                :value="item.discount"
                                compact-format
                                text-only
                                input-type="percent"
                            />
                        </template>
                        <template v-slot:cell(returnQty)="{ value, item }">
                            <p-number
                                v-model="item.returnQty"
                                name="Return Qty"
                                input-type="integer"
                                :rules="returnQtyRules(item)"
                                compact-format
                                :disabled="!statusProposed"
                            />
                        </template>
                        <template v-slot:cell(returnReason)="{ value, item }">
                            <p-select
                                compact-format
                                data-type="returnReasons"
                                :disabled="!statusProposed"
                                v-model="item.returnReason"
                                rules="required"
                                name="Return Reason"
                                data-vv-validate-on="blur"
                            />
                        </template>
                        <template v-slot:cell(cost)="{ value, item }">
                            <p-number
                                v-model="item.cost"
                                name="Cost"
                                input-type="money"
                                compact-format
                                rules="required|min_value:0"
                                :disabled="!statusProposed"
                            />
                        </template>
                        <template v-slot:cell(removeDetail)="{ value, item }">
                            <b-button
                                class="p-1 ml-1 short-button"
                                :disabled="!statusProposed"
                                    @click="
                                        !statusOpen &&
                                            !statusClosed &&
                                            confirmDeleteDetail(item)
                                    "
                            >
                                <BIconTrash
                                    :disabled="!statusProposed"
                                    font-scale="0.9"
                                    class="mb-2"
                                    icon="trash"
                                ></BIconTrash>
                            </b-button>
                        </template>
                    </p-table>
                </b-col>
            </b-row>
        </div>
        <p-modal
            size="xl"
            :label="memoDetailsModalTitle"
            :name="getModalName"
            no-close-on-backdrop
            no-close-on-esc
            ok-only
            ok-title="Close"
            @ok="validateCloseMemoModal"
            @close="validateCloseMemoModal"
        >
            <b-row class="text-right pb-3">
                <b-col class="ml-auto">
                    <p-button
                        variant="outline-primary"
                        @click="addPostageToCreditMemo"
                        :disabled="memoHasPostage || statusClosed"
                        >Add Postage</p-button
                    >
                </b-col>
                <b-col cols="auto">
                    <ProductMultiSelectQuickSearch
                        label="Add Product(s)"
                        icon-plus
                        name="memoDetailOptions"
                        :items="memoProductOptions"
                        @selections="addMemoDetails"
                        :disable-add-button="statusClosed"
                        :fieldSet="filteredAddMemoDetailFields"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <p-table
                        :items="memoDetails"
                        :fields="filteredMemoDetailFields"
                        :enable-row-selection="false"
                    >
                        <template v-slot:cell(resolvedQty)="{ value, item }">
                            <p-number
                                v-model.number="item.resolvedQty"
                                name="Resolved Qty"
                                input-type="integer"
                                :rules="memoQtyRules(item)"
                                compact-format
                                :disabled="
                                    disableMemoDetailPostage(item) ||
                                        disableMemoInputs(item)
                                "
                            />
                        </template>
                        <template v-slot:cell(cancelledQty)="{ value, item }">
                            <p-number
                                v-model.number="item.cancelledQty"
                                name="Cancelled Qty"
                                input-type="integer"
                                :rules="memoQtyRules(item)"
                                compact-format
                                :disabled="
                                    disableMemoDetailPostage(item) ||
                                        disableMemoInputs(item)
                                "
                            />
                        </template>
                        <template v-slot:cell(deniedQty)="{ value, item }">
                            <p-number
                                v-model.number="item.deniedQty"
                                name="Denied Qty"
                                input-type="integer"
                                :rules="memoQtyRules(item)"
                                compact-format
                                :disabled="
                                    disableMemoDetailPostage(item) ||
                                        disableMemoInputs(item)
                                "
                            />
                        </template>
                        <template v-slot:cell(resolvedAmount)="{ value, item }">
                            <p-number
                                v-model.number="item.resolvedAmount"
                                name="Resolved Amount"
                                input-type="money"
                                :rules="memoAmountRules(item)"
                                compact-format
                                :disabled="disableMemoInputs(item)"
                            />
                        </template>
                        <template v-slot:cell(status)="{ value, item }">
                            <p-select
                                compact-format
                                :options="memoDetailStatusOptions"
                                v-model="item.status"
                            />
                        </template>
                        <template v-slot:cell(isWriteOff)="{ value, item }">
                            <p-checkbox
                                v-model="item.isWriteOff"
                                :disabled="disableMemoInputs(item)"
                            />
                        </template>

                        <template v-slot:cell(removeDetail)="{ value, item }">
                            <b-button
                                class="p-1 ml-1 short-button"
                                :disabled="disableMemoInputs(item)"
                                    @click="
                                        !statusClosed &&
                                            item.status !== 'Resolved' &&
                                            confirmDeleteMemoDetail(item)
                                    "
                            >
                                <BIconTrash
                                    :disabled="disableMemoInputs(item)"
                                    font-scale="0.9"
                                    class="mb-2"
                                    icon="trash"
                                ></BIconTrash>
                            </b-button>
                        </template>
                    </p-table>
                </b-col>
            </b-row>
        </p-modal>
    </p-form>
</template>

<script>
import {
    currencyFormatter,
    bookVariantFormatter,
    dateFormatter
} from '../../components/Common/Formatters.js';
import ProductCreditRequestQuickSearch from '@/components/ProductCreditRequestQuickSearch.vue';
import unionBy from 'lodash/unionBy';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import pricing from '@/services/PricingCalculations.js';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import ExportCreditRequest from '@/components/ExportCreditRequest.vue';

export default {
    name: 'CreditRequest',
    props: {
        creditRequest: [Object],
        invoices: [Array],
        allRequestDetails: [Array],
        purchaseOrderView: {
            type: Boolean,
            default: false
        },
        isBusy: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            validationCheck: 0,
            validationMemoCheck: 0,
            selectedMemo: null,
            selectedMemoDetail: null,
            productOptions: null,
            creditRequestTypeOptions: [
                { value: 1, text: 'Return' },
                { value: 2, text: 'Discrepancy' }
            ],
            memoDetailStatusOptions: ['Unresolved', 'Resolved'],
            isExporting: false,
            sortOrderOptions: [
                {
                    text: 'Author/Title or Description',
                    value: 'AuthorTitleOrDescription'
                },
                { text: 'ISBN', value: 'isbnOrCatNumber' },
                { text: 'Quantity', value: 'returnQty' },
                { text: 'Invoice Number', value: 'invoiceNumber' }
            ],
            sortExportBy: 'AuthorTitleOrDescription',
            includeRetail: true,
            tableDefinition: {
                detailFields: [
                    {
                        key: 'invoiceNumber',
                        label: 'Invoice #',
                        sortable: true,
                        invoiceSpecific: true
                    },
                    {
                        key: 'sku',
                        label:'SKU',
                        sortable: true,
                        automaticSearch: true
                    },
                    {
                        key: 'termName',
                        label: 'Term',
                        invoiceSpecific: true,
                        sortable: true
                    },
                    {
                        label: 'Author',
                        key: 'author',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Title',
                        key: 'title',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Description',
                        key: 'authorTitleOrDescription',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        key: 'authorTitleOrDescription',
                        label: 'Author/Title or Description',
                        sortable: true,
                    },
                    {
                        key: 'isbnCatalog',
                        label: 'Barcode / ISBN',
                        sortable: true,
                        tdClass: 'noWrap'
                    },
                    {
                        key: 'catalogNumber',
                        label: 'Catalog #',
                        sortable: true,
                        hidden:true,
                        automaticSearch: true,
                        tdClass: 'noWrap'
                    },
                    {
                        key: 'isbn',
                        label: 'ISBN',
                        sortable: true,
                        hidden:true,
                        automaticSearch: true,
                        tdClass: 'noWrap'
                    },
                    {
                        key: 'barcodes',
                        label:'Barcode',
                        hidden: true,
                        automaticSearch: true
                    },
                    {
                        key: 'type',
                        formatter: bookVariantFormatter,
                        sortable: true
                    },
                    {
                        key: 'discount',
                        sortable: true,
                        thClass: 'text-center',
                        tdClass: 'text-right',
                        invoiceSpecific: true
                    },
                    {
                        key: 'cost',
                        thClass: 'text-center'
                    },
                    {
                        key: 'totalInvoicedQty',
                        sortable: true,
                        invoiceSpecific: true,
                        thClass: 'text-center',
                        tdClass: 'text-right'
                    },
                    {
                        key: 'returnReason'
                    },
                    {
                        key: 'returnQty',
                        thClass: 'text-center'
                    },
                    {
                        key: 'creditTotal',
                        formatter: (value, key, item) => {
                            let cost = this.getCost(item);
                            return currencyFormatter(item.returnQty * cost);
                        },
                        thClass: 'text-center',
                        tdClass: 'text-right'
                    },
                    {
                        key: 'removeDetail',
                        label: ''
                    }
                ],
                memoFields: [
                    {
                        key: 'memoCheckNumber',
                        label: 'Memo/Check Number'
                    },
                    {
                        key: 'memoDate',
                        formatter: dateFormatter
                    },
                    {
                        key: 'memoAmount',
                        thClass: 'text-center'
                    },
                    {
                        key: 'postage',
                        formatter: (value, key, item) => {
                            let postage = item.creditMemoDetails.find(
                                x => x.noSkuDescription === 'Postage'
                            );
                            if (postage) {
                                return postage.resolvedAmount;
                            }
                            return 0.0;
                        },
                        thClass: 'text-center'
                    },
                    {
                        key: 'isCheck'
                    },
                    {
                        key: 'batchResolve'
                    },
                    {
                        key: 'batchCancel'
                    },
                    {
                        key: 'memoDetails',
                        label: ''
                    },
                    {
                        key: 'status',
                        sortable: true
                    },
                    {
                        key: 'removeDetail',
                        label: ''
                    }
                ],
                memoDetailFields: [
                    {
                        key: 'sku',
                        formatter: (value, key, item) => {
                            return item?.noSkuDescription || value;
                        },
                        sortable: true,
                        label:' SKU',
                        automaticSearch: true
                    },
                    {
                        key: 'authorTitleOrDescription',
                        sortable: true,
                    },
                    {
                        label: 'Author',
                        key: 'author',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Title',
                        key: 'title',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Description',
                        key: 'authorTitleOrDescription',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        key: 'invoiceNumber',
                        label: 'Invoice #',
                        invoiceSpecific: true,
                        sortable: true,
                        automaticSearch: true
                    },
                    {
                        key: 'termName',
                        sortable: true,
                        invoiceSpecific: true,
                        label: 'Term Name',
                        automaticSearch: true
                    },
                    {
                        key: 'resolvedQty'
                    },
                    {
                        key: 'cancelledQty'
                    },
                    {
                        key: 'deniedQty'
                    },
                    {
                        key: 'resolvedAmount'
                    },
                    {
                        key: 'status'
                    },
                    {
                        key: 'isWriteOff',
                        label: 'Write off'
                    },
                    {
                        key: 'removeDetail',
                        label: ''
                    }
                ],
                addMemoDetailFields: [
                    {
                        key: 'sku',
                        label: 'SKU',
                        sortable: true,
                        automaticSearch: true
                    },
                    {
                        key: 'invoiceNumber',
                        label: 'Invoice %',
                        invoiceSpecific: true,
                        sortable: true,
                        automaticSearch: true
                    },
                    {
                        key: 'termName',
                        label: 'Term',
                        invoiceSpecific: true,
                        sortable: true,
                        automaticSearch: true
                    },
                    {
                        key: 'authorTitleOrDescription',
                        sortable: true,
                    },
                    {
                        label: 'Author',
                        key: 'author',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Title',
                        key: 'title',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        label: 'Description',
                        key: 'authorTitleOrDescription',
                        sortable: true,
                        automaticSearch: true,
                        hidden:true
                    },
                    {
                        key: 'isbn',
                        label: 'ISBN',
                        sortable: true,
                        automaticSearch: true,
                        tdClass: 'noWrap'
                    },
                ],
            }
        };
    },
      watch: {
        'creditRequest.supplierId' : function() {
            this.creditRequest.returnPolicyId = this.supplier.returnPolicyId;
            this.creditRequest.maxReturnTime = this.supplier.maxReturnTime;
            this.creditRequest.returnComment = this.supplier.returnComment;
        }
    },
    computed: {
        filteredRequestDetailFields: function (){
            return this.filterFields(this.tableDefinition.detailFields);
        },
        filteredMemoDetailFields: function (){
            return this.filterFields(this.tableDefinition.memoDetailFields);
        },
        filteredAddMemoDetailFields: function(){
            return this.filterFields(this.tableDefinition.addMemoDetailFields);
        },
        alreadySelectedInvoiceDetailIds: function() {
            return this.creditRequest.creditRequestDetails.map(x => x.invoiceDetailId);
        },
        alreadyIncludedSkus: function() {
            return this.creditRequest.creditRequestDetails.map(x => x.sku);
        },
        supplier(){
            return this.$store.getters.getSupplierById(this.creditRequest.supplierId) || {};
        },
        tableActions() {
            if (!this.statusProposed) {
                return [];
            }
            return [
                {
                    label: 'Remove',
                    action: this.confirmDeleteDetails,
                    variant: 'outline-danger'
                }
            ];
        },
        creditTotal() {
            return currencyFormatter(
                this.creditRequest.creditRequestDetails.reduce(
                    (a, i) => a + i.returnQty * i.cost,
                    0
                ) + (this.creditRequest.postage || 0)
            );
        },
        statusProposed() {
            return this.creditRequest.status === 'Proposed';
        },
        statusOpen() {
            return this.creditRequest.status === 'Open';
        },
        statusClosed() {
            return this.creditRequest.status === 'Closed';
        },
        creditHasMemo() {
            return this.creditRequest.creditMemos.length > 0;
        },
        memoProductOptions() {
            if (this.creditRequest.creditRequestDetails && this.selectedMemo) {
                let itemCompare = function(i, selectedMemo) {
                    return selectedMemo.creditMemoDetails.some(
                        x =>
                            x.sku === i.sku &&
                            x.termId === i.termId &&
                            x.locationId === i.locationId &&
                            x.invoiceNumber === i.invoiceNumber
                    );
                };
                return this.creditRequest.creditRequestDetails
                    .filter(
                        x =>
                            !itemCompare(x, this.selectedMemo) &&
                            x.returnQty > 0
                    )
                    .flatMap(x => x);
            }
            return [];
        },
        memoDetails() {
            if (this.selectedMemo) {
                return this.selectedMemo.creditMemoDetails;
            }
            return [];
        },
        memoDetailsModalTitle() {
            if (this.selectedMemo) {
                return `Memo Details for ${this.selectedMemo.memoCheckNumber}`;
            }
            return '';
        },
        memoHasPostage() {
            if (this.selectedMemo) {
                return this.selectedMemo.creditMemoDetails.some(
                    x => x.noSkuDescription === 'Postage'
                );
            }
            return false;
        },
        addProductOptions() {
            //Get details that aren't on current request, and aren't fully returned if on another request.
            let qtyComparer = function(i, allRequestDetails) {
                let details = allRequestDetails
                    .filter(
                        id =>
                            id.sku === i.sku &&
                            id.termId === i.termId &&
                            id.locationId === i.locationId &&
                            id.invoiceNumber === i.invoiceNumber &&
                            id.returnQty
                    )
                    .map(x => x.returnQty);
                return details.length > 0
                    ? details.reduce((a, b) => a.returnQty + b.returnQty) <
                          i.qty
                    : true;
            };

            var options = this.invoices
                .flatMap(x =>
                    x.invoiceDetails
                    //todo: for now only allow adding datails from the current purchase order (these have purchaseOrderDetail filled in)
                    //revisit this when we build returns outside of the po page (like we did with invoices)
                    .filter(y => y.purchaseOrderDetail)
                    .map(y => ({
                        invoiceNumber: x.invoiceNumber,
                        ...y
                    }))
                )
                .filter(
                    i =>
                        !this.creditRequest.creditRequestDetails.some(
                            id =>
                                id.sku === i.sku &&
                                id.termId === i.termId &&
                                id.locationId === i.locationId &&
                                id.invoiceNumber === i.invoiceNumber
                        ) && qtyComparer(i, this.allRequestDetails)
                );

            return options;
        },
        formId() {
            return `creditRequest-${this.creditRequest.requestNumber}`;
        },
        getModalName() {
            return this.creditRequest.requestNumber;
        }
    },
    methods: {
        filterFields(fieldSet){
            if(!this.purchaseOrderView && !this.creditRequest.includeInvoiceInformation){
                return fieldSet.filter(x => !x.invoiceSpecific );
            }
            return fieldSet;
        },
        addProducts(items) {

            let getDiscount = function(x) {
                return x.price && x.price > 0 ? x.discount : 0;
            };

            items = items.map(x => ({
                sku: x.sku,
                termId: x.termId,
                locationId: x.locationId,
                termName: x.termName,
                authorTitleOrDescription:
                x.authorTitleOrDescription,
                invoiceDetailId: x.invoiceDetailId,
                isbn: x.isbn,
                type: x.bookVariant,
                retail: x.retail,
                price: x.price,
                cost: this.getCost(x),
                discount: getDiscount(x),
                barcodes: x.barcodes,
                totalInvoicedQty: x.qty,
                invoiceNumber: x.invoiceNumber,
                returnReason: x.returnReason,
                isbnCatalog: x.isbnCatalog,
                returnQty: 0
            }));
            this.creditRequest.creditRequestDetails = unionBy(
                items,
                this.creditRequest.creditRequestDetails,
                x => x.sku + '-' + (x.termId ?? 0) + '-' + x.invoiceNumber
            );
        },
        getCost(item) {
            return item.price && item.price > 0
                ? pricing.costFromPriceAndDiscount(item.price, item.discount)
                : item.cost;
        },
        confirmDeleteDetails(selectedRows) {
            var detailsWithMemos = selectedRows.filter(x => this.detailHasMemo(x));

            if(detailsWithMemos.length > 0){
                this.$toasted.global
                        .app_error(
                            `Unable to delete selected product(s) because the following have credit memo(s) attatched:\n
                            ${detailsWithMemos.map(x => `SKU: ${x.sku}`).join('\n')}`
                        )
                        .goAway(8000);
                    return;
            }
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to remove the selected product(s) from the return?'
                )
                .then(value => {
                    if (value) {
                        selectedRows.forEach(x => {
                            this.deleteDetail(x);
                        });
                    }
                });
        },
        confirmDeleteDetail(detail) {
            if(this.detailHasMemo(detail)){
                this.$toasted.global
                        .app_error(
                            'This product cannot be deleted because it has credit memo(s) attached to it.'
                        )
                        .goAway(8000);
                    return;
            }

            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to remove this product from the return?'
                )
                .then(value => {
                    if (value) {
                        this.deleteDetail(detail);
                    }
                });
        },
        detailHasMemo(detail){

              let details = this.creditRequest.creditMemos.flatMap(x =>
                x.creditMemoDetails.filter(
                    y =>
                        y.sku === detail.sku &&
                        y.termId === detail.termId &&
                        y.invoiceNumber === detail.invoiceNumber
                )
            );
            return details.length > 0;
        },
        deleteDetail(detail) {
            let index = this.creditRequest.creditRequestDetails.indexOf(detail);
            if (index !== -1) {
                this.creditRequest.creditRequestDetails.splice(index, 1);
            }
        },
        confirmDeleteMemo(memo) {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to remove this memo from the return?'
                )
                .then(value => {
                    if (value) {
                        let index = this.creditRequest.creditMemos.indexOf(
                            memo
                        );
                        if (index !== -1) {
                            this.creditRequest.creditMemos.splice(index, 1);
                        }
                    }
                });
        },
        confirmDeleteMemoDetail(memoDetail) {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to remove this memo detail?'
                )
                .then(value => {
                    if (value) {
                        let index = this.selectedMemo.creditMemoDetails.indexOf(
                            memoDetail
                        );
                        if (index !== -1) {
                            this.selectedMemo.creditMemoDetails.splice(
                                index,
                                1
                            );
                        }
                    }
                });
        },
        returnQtyRules(item) {
            if(!this.creditRequest.includeInvoiceInformation){
                return 'required|min_value:0';
            }

            let sumReturnQty = this.allRequestDetails.filter(
                x =>
                    x.sku === item.sku &&
                    x.termId === item.termId &&
                    x.invoiceNumber === item.invoiceNumber &&
                    x.requestNumber !== this.creditRequest.requestNumber
            );

            let totalReturnQty = 0;
            if (sumReturnQty.length > 0) {
                totalReturnQty = sumReturnQty.reduce(
                    (a, b) => a + b.returnQty,
                    0
                );
            }
            return `required|min_value:0|exceedInvoicedQty:${item.totalInvoicedQty},${totalReturnQty}`;
        },
        memoQtyRules(item) {
            //no rules for postage line.
            if (!item.noSkuDescription) {
                let summedQty = this.getSummedQtyAllMemosForItem(item);
                let returnQty = this.creditRequest.creditRequestDetails.find(
                    x => x.sku === item.sku && x.termId === item.termId
                ).returnQty;
                return `required|min_value:0|exceedReturnedQty:${returnQty},${summedQty}`;
            }
        },
        memoAmountRules() {
             let summedAmount = this.selectedMemo.creditMemoDetails
                    .map(x => x.resolvedAmount)
                    .reduce((a, b) => a + b, 0);

            return `required|min_value:0|exceedMemoAmount:${this.selectedMemo.memoAmount},${summedAmount}`;
        },
        memoStatus(memo) {
            let status = 'Unresolved';
            if (memo.creditMemoDetails && memo.creditMemoDetails.length > 0) {
                if (
                    memo.creditMemoDetails.every(x => x.status === 'Resolved')
                ) {
                    status = 'Resolved';
                }
            }
            return status;
        },
        disableMemoDetailPostage(detail) {
            return !detail.sku;
        },
        disableMemoInputs(detail) {
            return this.statusClosed || detail.status === 'Resolved';
        },
        memoCheckNumberRules(item) {
            if (item.isCheck) {
                let memoNumber =
                    item.memoCheckNumber && item.memoCheckNumber.toLowerCase();
                let alreadyExists = !this.creditRequest.creditMemos
                    .filter(x => x.isCheck && item !== x)
                    .flatMap(
                        x =>
                            x.memoCheckNumber && x.memoCheckNumber.toLowerCase()
                    )
                    .some(x => x === memoNumber);
                return { 'required': true, max: { length: 200 }, isUnique: { isUniqueValue: alreadyExists, message: 'Check number already exists for this credit request.' }};
            }
            return 'required|max:200';
        },
        getSummedQtyAllMemosForItem(item) {
            //Get the sum of all memo details for the sku/term.
            let details = this.creditRequest.creditMemos.flatMap(x =>
                x.creditMemoDetails.filter(
                    y =>
                        y.sku === item.sku &&
                        y.termId === item.termId &&
                        y.invoiceNumber === item.invoiceNumber
                )
            );
            let qtys = details.flatMap(x => ({
                qty: x.resolvedQty + x.cancelledQty + x.deniedQty
            }));
            let retQty = 0;
            if (qtys.length > 0) {
                retQty = qtys.reduce((a, b) => a + b.qty, 0);
            }
            return retQty;
        },
        async setStatus(value) {
            if (!(await this.$refs.creditRequestForm.validate())) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'Please resolve all validation errors on this Credit Request before changing status.'
                    )
                    .goAway(5000);
                return;
            } else if (this.creditRequest.creditRequestDetails.length === 0) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'Credit Request must have at least one product before changing status.'
                    )
                    .goAway(5000);
                return;
            } else if (
                this.creditRequest.status == 'Proposed' &&
                value == 'Closed'
            ) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'Credit Request Status cannot be changed from Proposed to Closed.'
                    )
                    .goAway(5000);
                return;
            } else if (
                value == 'Open' &&
                this.creditRequest.creditRequestDetails.some(
                    x => x.returnQty === 0
                )
            ) {
                this.validationCheck++;
                this.$toasted.global
                    .app_error(
                        'All products must have a return quantity above 0 to move the Credit Reuqest Status to Open.'
                    )
                    .goAway(5000);
                return;
            } else {
                this.creditRequest.status = value;
            }
        },
        addMemo: function() {
            this.creditRequest.creditMemos.push({
                creditMemoDetails: [],
                memoCheckNumber: null,
                memoDate: null,
                memoAmount: 0.0,
                isCheck: false
            });
        },
        addMemoDetails: function(selection) {
            let details = selection.map(x => ({
                sku: x.sku,
                termId: x.termId,
                termName: x.termName,
                locationId: x.locationId,
                invoiceDetailId: x.invoiceDetailId,
                invoiceNumber: x.invoiceNumber,
                authorTitleOrDescription: x.authorTitleOrDescription,
                author: x.author,
                title: x.title,
                description: x.description,
                resolvedQty: 0,
                cancelledQty: 0,
                deniedQty: 0,
                resolvedAmount: 0,
                status: 'Unresolved',
                isWriteOff: false,
                noSkuDescription: x.noSkuDescription
            }));

            this.selectedMemo.creditMemoDetails = unionBy(
                this.selectedMemo.creditMemoDetails,
                details,
                x =>
                    x.sku +
                    '-' +
                    (x.termId ?? 0) +
                    x.invoiceNumber +
                    x.noSkuDescription
            );
            this.selectedMemo.creditMemoDetails = sortBy(
                this.selectedMemo.creditMemoDetails,
                x => x.sku,
                'desc'
            );
        },
        addPostageToCreditMemo() {
                this.addMemoDetails([
                {
                    sku: null,
                    termId: null,
                    locationId: null,
                    invoiceNumber: null,
                    resolvedQty: 0,
                    cancelledQty: 0,
                    deniedQty: 0,
                    resolvedAmount: 0,
                    status: 'Unresolved',
                    isWriteOff: false,
                    noSkuDescription: 'Postage'
                }
            ]);
        },
        detailIsOnMemo(detail) {
            return this.creditRequest.creditMemos
                .flatMap(x => x.creditMemoDetails)
                .some(
                    x =>
                        x.sku === detail.sku &&
                        x.termId === detail.termId &&
                        x.invoiceNumber === detail.invoiceNumber &&
                        x.noSkuDescription === detail.noSkuDescription
                );
        },
        showMemoDetailModal: async function(memo) {
            if (!(await this.$refs.creditRequestForm.validate())) {
                return;
            }

            this.selectedMemo = memo;
            this.$bvModal.show(this.creditRequest.requestNumber);
        },
        validateCloseMemoModal: async function(event) {
            event.preventDefault();
            if (await this.$refs.creditRequestForm.validate()) {
                this.updateCreditRequestStatus();
                this.$bvModal.hide(this.creditRequest.requestNumber);
            }
        },
        updateCreditRequestStatus() {
            //go through all memo details to set status closed if all products are resolved.
            let allMemoDetails = this.creditRequest.creditMemos.flatMap(
                x => x.creditMemoDetails
            );
            let allDetailsResolved = allMemoDetails.every(
                x => x.status === 'Resolved'
            );

            if (allDetailsResolved) {
                let includesDetailFunction = function(crDetail, memoDetails) {
                    return memoDetails.some(
                        x =>
                            crDetail.sku === x.sku &&
                            crDetail.termId === x.termId &&
                            crDetail.invoiceNumber === x.invoiceNumber
                    );
                };

                let allDetailsInMemos = this.creditRequest.creditRequestDetails.every(
                    x => includesDetailFunction(x, allMemoDetails)
                );

                if (allDetailsResolved && allDetailsInMemos) {
                    this.creditRequest.status = 'Closed';
                }
            }
        },
        validateSelectedMemo: async function(event) {
            if (await this.$refs.creditRequestForm.validate()) {
                this.selectedMemoDetail = event;
            }

            this.validationMemoCheck++;
        },
        batchResolveMemo(memo) {
            memo.creditMemoDetails
                .filter(x => x.status != 'Resolved')
                .forEach(x => {
                let summedAmount = memo.creditMemoDetails
                    .map(x => x.resolvedAmount)
                    .reduce((a, b) => a + b, 0);
                let availableMemoAmount = round(
                    memo.memoAmount - summedAmount,
                    2
                );

                if (x.sku) {
                    let crDetail = this.creditRequest.creditRequestDetails.find(
                        y =>
                            y.sku === x.sku &&
                            y.termId === x.termId &&
                            y.invoiceNumber === x.invoiceNumber
                    );
                    let summedQty = this.getSummedQtyAllMemosForItem(x);
                    let delta = crDetail.returnQty - summedQty;
                    let cost = this.getCost(crDetail);

                    if (delta * cost > availableMemoAmount) {
                        x.resolvedAmount = availableMemoAmount;
                    } else {
                        x.resolvedAmount += delta * cost;
                    }

                    x.resolvedQty = x.resolvedQty + delta;
                } else {
                    if (availableMemoAmount > 0) {
                        let postageDetails = this.creditRequest.creditMemos.flatMap(
                            x =>
                                x.creditMemoDetails.filter(
                                    y => y.noSkuDescription === 'Postage'
                                )
                        );
                        let totalPostageApplied = 0;
                        if (postageDetails.length > 0) {
                            totalPostageApplied = postageDetails.reduce(
                                (a, b) => a + b.resolvedAmount,
                                0
                            );
                        }
                        let remainingPostage =
                            this.creditRequest.postage - totalPostageApplied;

                        if (
                            remainingPostage + x.resolvedAmount >
                            this.creditRequest.postage
                        ) {
                            x.resolvedAmount = remainingPostage;
                        } else {
                            x.resolvedAmount += remainingPostage;
                        }
                    }
                }
                x.status = 'Resolved';
            });
            this.updateCreditRequestStatus();
        },
        batchCancelMemo(memo) {
            memo.creditMemoDetails
                .filter(x => x.status != 'Resolved')
                .forEach(x => {
                    if (x.sku) {
                        let crDetail = this.creditRequest.creditRequestDetails.find(
                            y =>
                                y.sku === x.sku &&
                                y.termId === x.termId &&
                                y.invoiceNumber === x.invoiceNumber
                        );
                        let summedQty = this.getSummedQtyAllMemosForItem(x);

                        x.cancelledQty += crDetail.returnQty - summedQty;
                    }
                    x.status = 'Resolved';
                });
            this.updateCreditRequestStatus();
        }
    },
    components: {
        ProductCreditRequestQuickSearch,
        ProductMultiSelectQuickSearch,
        ExportCreditRequest
    }
};
</script>

<style scoped>
>>> .short-button {
    height: 20px;
}

>>> .noWrap {
    white-space: nowrap;
}
</style>
