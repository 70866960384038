<template>
    <div>
    <div>
        <SearchScreen
            title="AR Account Management"
            api-destination="accountreceivable"
            edit-key="accountId"
            :filters="filters"
            :filterBy="filterBy"
            :fields="fields"
            add-new-button
            :use-bootstrap-selection="false"
            :use-is-active-checkbox="false"
            :show-set-to="false"
            ref="SearchScreen"
        >
            <template v-slot:actionButtons>
                <b-row class="pl-3">
                    <b-overlay :show="isBusy"
                                       rounded
                                       opacity="0.5"
                                       spinner-small
                                       spinner-variant="primary"
                                       class="d-inline-block">
                    <router-link
                        to="/accountreceivable/new"
                        class="btn btn-primary mr-2"
                    >
                        <b-icon-plus></b-icon-plus> Add
                    </router-link>
                    </b-overlay>
                    <b-overlay :show="isBusy"
                                       rounded
                                       opacity="0.5"
                                       spinner-small
                                       spinner-variant="primary"
                                       class="d-inline-block">
                    <b-dropdown
                        class="ml-2"
                        text="Export"
                        :disabled="$refs.SearchScreen.selectedRows.length <= 0"
                        variant="outline-primary"
                    >
                        <b-dropdown-item v-b-modal.TransactionSummaryModal
                            >AR Account Transaction Summary
                            (CSV)</b-dropdown-item
                        >
                    </b-dropdown>
                    </b-overlay>
                    <p-button
                        variant="outline-primary"
                        v-b-modal.PrintStatementModal
                        :disabled="$refs.SearchScreen.selectedRows.length <= 0"
                        class="ml-3"
                        :is-busy="isBusy"
                        >Print Statement</p-button>
                </b-row>
            </template>

            <template v-slot:belowSearch>
                <b-form>
                    <b-form-group label-for="automatic" class="ml-3 mr-2">
                        <p-checkbox
                            v-model="filters.isInactive"
                            size="md"
                            label="Show Inactive"
                        ></p-checkbox>
                    </b-form-group>
                </b-form>
            </template>
        </SearchScreen>
        <p-modal
            id="TransactionSummaryModal"
            size="md"
            name="TransactionSummaryModal"
            label="AR Account Transaction Summary"
            @show="loadModal"
            @ok="handleModalOk"
        >
            <template>
                <p-form ref="exportForm">
                <b-row>
                    <b-col>
                        <p-advance-date-range :date-range-options="exportDates" 
                                                :is-date-range-visible="false" />
                    </b-col> 
                    
                </b-row>
                </p-form>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button :is-busy="isBusy" variant="outline-primary" @click="cancel">Cancel</p-button>
                    <p-button :is-busy="isBusy" variant="primary" @click="ok" class="ml-2">Ok</p-button>
                </b-col>
            </b-row>
        </template>
        </p-modal>
        <p-modal
            id="PrintStatementModal"
            size="md"
            name="PrintStatementModal"
            label="Print AR Account"
            @show="loadModal"
            @ok="handlePrintModalOk"
        >
            <template>                
                <p-form ref="printStatementForm">
                <b-row>
                    <b-col>
                        <b-row>                          
                            <b-col>
                                <p-advance-date-range
                                    :date-range-options="printStatementData"
                                    :is-date-range-visible="false"
                                />
                            </b-col>
                        </b-row>
                    </b-col>                    
                </b-row>
                <b-row>
                    <b-col sm="12" lg="6">
                        <p-select
                                label="Sort Transactions By"
                                v-model="printStatementData.sortBy"
                                :options="sortByOptions"
                            />
                    </b-col> 
                    <!-- DV-11035 commented temporarily to do in future. -->
                    <!-- <b-col sm="12" lg="6">
                        <p-checkbox 
                            class="mt-3 pt-3"
                            label="Include Line Items"
                            v-model="printStatementData.includeLineItems">
                        </p-checkbox>
                    </b-col>  -->
                                     
                </b-row>
                </p-form>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button :is-busy="isBusy" variant="outline-primary" @click="cancel">Cancel</p-button>
                    <p-button :is-busy="isBusy" variant="primary" @click="ok" class="ml-2">Ok</p-button>
                </b-col>
            </b-row>
        </template>
        </p-modal>
        
    </div>
    </div>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import { currencyFormatter, dateFormatter, stringHasValueFormatter } from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import axios from 'axios';
import PAdvanceDateRange from '../../components/p-advance-date-range.vue';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'AccountReceivableFilter',            
            isBusy: false,
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'accountCode',
                sortDesc: true,
                searchBy: 'All',
                isInactive: false
            },
            exportDates:{
                fromDate: null,
                toDate: null,
            },
            sortByOptions: [
                {value:'date',text:'Date'},
                {value:'customer',text:'Customer/Date'}
            ],
            printStatementData:{
                ids:null,
                fromDate: null,
                toDate: null,
                sortBy:'date',
                includeLineItems:false
            },
            filterBy: ['All', 'Account Code', 'Agency Name', 'Fund Type'],
            fields: [
                {
                    key: 'accountCode',
                    label: 'Account Code',
                    sortable: true
                },
                {
                    key: 'agencyName',
                    label: 'Agency Name',
                    sortable: true
                },
                {
                    key: 'accountBalance',
                    label: 'Balance Due',
                    formatter: currencyFormatter,
                    sortable: true,
                    thClass: 'text-right',
                    tdClass: 'text-right'
                },
                {
                    key: 'defaultFundingStartDate',
                    label: 'Funding Start Date',
                    formatter: dateFormatter,
                    sortable: true
                },
                {
                    key: 'defaultFundingEndDate',
                    label: 'Funding End Date',
                    formatter: dateFormatter,
                    sortable: true
                },
                {
                    key: 'fundType',
                    label: 'Fund Type',
                    formatter: value => {
                        switch (value) {
                            case 4:
                                return 'Expiring';
                            case 6:
                                return 'Revolving';
                            case 10:
                                return 'Department Charge';
                        }
                    },
                    sortable: true
                },
                {
                    key: 'comment',
                    label: 'Comment',
                    tdClass: 'showEllipsis'
                },
                {
                    key: 'isActive',
                    label: 'Active',
                    formatter: stringHasValueFormatter,                   
                }
            ]
        };
    },
    computed: {},
    mounted: async function() {},
    methods: {  
        loadModal(){           
            this.exportDates.fromDate = null;
            this.exportDates.toDate = null;
            this.printStatementData.fromDate = null;
            this.printStatementData.toDate = null;
            this.printStatementData.sortBy = 'date';
            this.printStatementData.includeLineItems = false;
        },   
        handleModalOk: async function(event) {
                event.preventDefault();
                if (!(await this.$refs.exportForm.validate())) {
                    return;
                }
                let selectedIds = this.$refs.SearchScreen.selectedRows.map(
                    x => x.accountId
                );
                this.isBusy=true;
                axios.post('accountReceivable/exportsummary', {
                        ids: selectedIds,
                        fromDate: this.exportDates.fromDate,
                        toDate:this.exportDates.toDate
                    }, { responseType: 'blob' } )
                        .then((result) => {
                            downloadFileToBrowser(result.data, 'AR Account Transaction Summary.csv', 'application/csv');
                                this.$toasted.global.app_success(`AR Account Transaction Summary generated successfully.`).goAway(5000);
                        }).finally(() => {
                            this.$bvModal.hide('TransactionSummaryModal');
                            this.isBusy=false;
                        });
               
        },
        handlePrintModalOk: async function(event) {
                event.preventDefault();
                if (!(await this.$refs.printStatementForm.validate())) {
                    return;
                }
                this.printStatementData.ids = this.$refs.SearchScreen.selectedRows.map(
                    x => x.accountId
                );
                this.isBusy=true;  
                this.$bvModal.hide('PrintStatementModal');                  
                var htmlContent = null;                     
                await axios.post('accountReceivable/printarstatement', this.printStatementData, { responseType: 'text/html' } )
                        .then((result) => {
                             htmlContent = result.data;
                            this.$toasted.global.app_success(`AR Print statement generated successfully.`).goAway(5000);                            

                        }).finally(() => {
                            this.$bvModal.hide('PrintStatementModal');
                            this.isBusy=false;
                        });
                var newTab = window.open();
                newTab.document.write(htmlContent);
                newTab.document.close();
               
        }
    },

    components: {
        SearchScreen,
        PAdvanceDateRange
    }
};
</script>



