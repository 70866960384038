<template>
    <span >
        <b-row>
            <b-col>
                Course Sections
                <template v-if="treeSelection.length > 0">
                    ({{ treeSelection.length }})
                </template>
            </b-col>
            <b-col cols="auto" class="ml-auto mr-2" v-if="showClosedTermOption">
                <p-checkbox label="Show Closed Terms"
                            v-model="includeClosedTerms">
                </p-checkbox>
            </b-col>
            <b-col cols="auto" class="ml-auto mr-2" v-if="showExpandAllOption">
                <b-button
                    variant="light"
                    size="sm"
                    class="py-0"
                    @click="expandAll = !expandAll">
                    {{expandAll ? 'Collapse All' : 'Expand All'}}
                </b-button>
            </b-col>
        </b-row>
        <p-tree :data="filteredData"
                include-search-filter
                :filter-place-holder="'Search by term'"
                :key="filteredData.length"
                @selection-change="onTreeSelection"
                ref="pTree"
               >
        </p-tree>
    </span>
</template>

<script>
    import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
    export default {
        name: 'CourseSectionsSelector',
        props: {
            courseRequest: {
                type: Object,
                default: null
            },
            filterCourseData: {
                type: Boolean,
                default: false
            },
            selectFilteredData: {
                type: Boolean,
                default: true
            },
            showClosedTermOption: {
                type: Boolean,
                default: true
            },
            showExpandAllOption: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                treeData: [],
                terms: [],
                courseDepartments: [],
                courses: [],
                treeSelection: [],
                includeClosedTerms: false,
                expandAll: false,
            };
        },
        watch: {
            expandAll() {
                //this will map the expandAll bool
                //on each level of the tree
                this.setTreeData();
            },
        },
        computed: {
            filteredData() {
                if (this.includeClosedTerms) {
                    return this.treeData;
                }

                if (this.filterCourseData && this.courseRequest) {
                    //filter terms based on course request prop
                    return this.treeData.filter(x => x.value === this.courseRequest.termId);
                }
                return this.treeData.filter(x => x.filter === 'True');
            }
        },

        methods: {
            setTreeData() {
                //level 1 term
                //level 2 courseDepartments
                //level 3 course
                //level 4 section

                //for the most part we just need text, value, icon, and children at each level.
                //Added course information to the leaf nodes for easy access (we need course and section selection info).
                //Added level descriptor at each level for ease of reading.
                this.treeData = this.terms.map(t => ({
                    ...t,
                    icon: 'fa fa-calendar',
                    level: 'term',
                    opened: this.expandAll,
                    children: this.courseDepartments
                        .filter(cd => t.courseDepartmentIds.indexOf(cd.value) > -1)
                        .map(cd => ({
                            level: 'courseDepartment',
                            opened: this.expandAll,
                            ...cd,
                            icon: 'fa fa-layer-group',
                            children: this.courses
                                .filter(c => c.filter === String(cd.value))
                                .map(c => ({
                                    ...c,
                                    level: 'course',
                                    opened: this.expandAll,
                                    icon: 'fa fa-sticky-note',
                                    children: c.sections
                                        .filter(x => x.termId === t.value)
                                        .map(x => ({
                                            value: x.sectionName,
                                            text: x.sectionName,
                                            courseId: c.value,
                                            courseName: c.text,
                                            courseDepartmentId: cd.value,
                                            termId: t.value,
                                            icon: 'fa fa-thumbtack',
                                            level: 'section',
                                        }))
                                })).filter(c => c.children.length > 0)
                        })).filter(cd => cd.children.length > 0)
                })).filter(t => t.children.length>0);
                if (this.selectFilteredData && this.courseRequest != null && this.courseRequest.termId) {
                    let selectedTerm = this.treeData.find(x => x.value == this.courseRequest.termId);
                    selectedTerm.selected = true;

                    let selectedCourseDepartments = selectedTerm.children
                        .filter(x => !this.courseRequest.courseDepartmentId || x.value === this.courseRequest.courseDepartmentId);

                    selectedCourseDepartments.map(cd => {
                        cd.selected = true;
                        let selectedCourses = cd.children.filter(x => !this.courseRequest.courseId || x.value == this.courseRequest.courseId);

                        selectedCourses.map(c => {
                            c.selected = true;
                            let selectedSections = c.children.filter(x => !this.courseRequest.sectionName || x.value == this.courseRequest.sectionName);

                            selectedSections.map(s => {
                                s.selected = true;
                            });

                            return c;
                        });
                        
                        return cd;
                    });
                    this.expandAll = true;                    
                    this.$refs.pTree.itemClick();
                    this.onTreeSelection(this.filteredData);
                }
            },
            onTreeSelection(data) {
                this.treeSelection = data
                    .flatMap(x => x.children)
                    .flatMap(x => x.children)
                    .flatMap(x => x.children)
                    .filter(x => x.selected)
                    .map(x => ({
                        section: x.value,
                        courseId: x.courseId,
                        courseName: x.courseName,
                        courseDepartmentId: x.courseDepartmentId,
                        termId: x.termId
                    }));
                this.$emit('selection-change', this.treeSelection);
            },

        },

        mounted: async function () {
            this.terms = await selectListOptionsDataContext.getStoreDropdownData(
                'terms'
            );
            this.courseDepartments = await selectListOptionsDataContext.getStoreDropdownData(
                'courseDepartments'
            );
            this.courses = await selectListOptionsDataContext.getStoreDropdownData(
                'courses'
            );
            this.setTreeData();
        }
    };
</script>
