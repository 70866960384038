<template>
    <p-secure :require-role="[SECURITYROLES.MERCHANDISEMANAGER]">
        <!-- TODO:  This page will replace the EditGM.vue pagae when it is functional -->
        <p-form @submit="onSubmit" ref="form">
            <div
                class="container-fluid content-wrapper"
                v-if="productFamily && product && productInventory"
            >
                <b-row class="content-heading">
                    <b-col>
                        <div>
                            {{ isNew ? 'Add' : 'Edit' }} General Merchandise
                        </div>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <p-button
                            type="submit"
                            variant="primary"
                            :is-busy="isBusy"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
                <b-tabs justified v-model="tabIndex">
                    <b-tab title="General Data" @click="isManageButtonClicked=false">
                        <GeneralTab
                            :productFamily="productFamily"
                            :product="product"
                            :products="products"
                            :productInventory="productInventory"
                            :parameters="parameters"
                            :isNew="isNew"
                            :refreshDefaultSupplier="refreshDefaultSupplier"
                            ref="generalTab"
                            @refreshPage="loadData"
                            @manageButtonClick="navigateToSerializationTab"
                            @savePage="onPrintLabelClicked"
                            @clearDirtyFlag="clearDirtyFlag"
                        />
                    </b-tab>
                    <b-tab title="Suppliers">
                        <SuppliersTab
                            :productFamily="productFamily"
                            :product="product"
                            @change-default-supplier="updatedDefaultSupplier"
                        />
                    </b-tab>
                        <b-tab title="Serialization" 
                        v-if="$store.getters.featureFlagEnabled('Serialization') 
                        && productFamily.isSerializationActive 
                        && !productFamily.isMatrix" :active="isManageButtonClicked">
                            <SerializationTab 
                            :product-family="productFamily"
                            />
                        </b-tab>
                </b-tabs>
                <b-row class="mt-3">
                    <b-col>
                        <p-button
                            v-if="!isNew && tabIndex === 0"
                            name="delete-button'"
                            variant="outline-danger"
                            @click="deleteProduct"
                            :is-busy="isBusy"
                            >Delete</p-button
                        >
                    </b-col>
                    <b-col cols="auto" class="ml-auto pr-0">
                        <p-button
                            variant="outline-primary"
                            @click="cancelEdit"
                            :is-busy="isBusy"
                            >Cancel</p-button
                        >
                    </b-col>
                    <b-col cols="auto">
                        <p-button
                            variant="primary"
                            type="submit"
                            :is-busy="isBusy"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </p-secure>
</template>
<script>
import axios from 'axios';
import editPage from '@/components/mixins/EditPage';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import GeneralTab from './GMGeneralTab.vue';
import SuppliersTab from './GMSuppliersTab.vue';
import SerializationTab from './GMSerializationTab.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [NavigationGuard, editPage],
    data() {
        return {
            isBusy: false,
            productFamily: null,
            product: null,
            products: null,
            productInventory: null,
            refreshDefaultSupplier: false,
            tabIndex: 0,
            isManageButtonClicked: false,
            isPrintLabelClicked: false
        };
    },
    watch: {
        productFamily: NavigationGuard.$watcher
    },
    methods: {
        cancelEdit() {
            this.$router.push('/products');
        },
        deleteProduct() {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this product?', {
                    okTitle: 'Delete',
                    okVariant: 'danger'
                })
                .then(value => {
                    if (value) {
                        this.isBusy = true;
                        let SKUs = this.products.map(i => i.sku);
                        axios
                            .post('products/delete', {sku:SKUs})
                            .then(resp => {
                                if (resp) {
                                    this.$toasted.global
                                        .app_success(
                                            `SKU(s) deleted successfully.`
                                        )
                                        .goAway(5000);
                                    this.dataSaved();
                                    this.$router.push('/products');
                                }
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    }
                });
        },
        async onSubmit() {
            if (!(await this.$refs.form.validate())) {
                this.isPrintLabelClicked = false;
                return;
            }
            if (this.product.suppliersProperties != null) {
                if (
                    this.product.suppliersProperties.length &&
                    !this.product.suppliersProperties.some(
                        supplier => supplier.isDefault
                    )
                ) {
                    this.$bvModal.msgBoxOk(`Please select a default supplier.`);
                    this.isPrintLabelClicked = false;
                    return;
                }
            }
            if (
                this.product.newSellingPrice ||
                this.product.newSellingPriceStartDate
            ) {
                if (
                    !(await this.$bvModal.msgBoxConfirm(
                        'Scheduled price updates may require new price labels. Please be sure that prices on merchandise match the application price.'
                    ))
                ) {
                    this.isPrintLabelClicked = false;
                    return;
                }
            }

            if (
                this.productInventory.isEventPercentage == null &&
                this.productInventory.eventPrice != null &&
                this.productInventory.eventPrice > 0
            ) {
                this.$bvModal.msgBoxOk(`Please select the discount type.`);
                this.isPrintLabelClicked = false;
                return;
            }

            if (this.productInventory.cost === 0) {
                this.$bvModal
                    .msgBoxConfirm(
                        'About to save item with a $0.00 cost. Do you wish to continue?'
                    )
                    .then(value => {
                        if (!value) {
                            this.isPrintLabelClicked = false;
                            return;
                        }
                        this.saveProduct();
                    });
            } else {
                this.saveProduct();
            }
        },
        async saveProduct() { 
            //submit product save after validation has passed
            this.isBusy = true;
            this.productInventory.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync(); 
            this.productFamily.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync(); 
            this.productFamily.isFeatureFlag = !this.$store.getters.featureFlagEnabled('Sales Events')?false:true;
            axios
                .post('products/generalmerchandise', this.productFamily)
                .then(response => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push(
                            '/products/generalmerchandise/' + response.data
                        );
                    }
                    this.loadData();
                    this.$toasted.global
                        .app_success(
                            `Product '${this.productFamily.description}' saved successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                    
                });
        },
        async loadData() {
            this.isBusy = true;
            let locationId = await this.getSelectedLocationId();
            let dataPromise = this.isNew
                ? axios.get('products/generalmerchandise/new')
                : axios.get('products/generalmerchandise', {
                      params: { sku: this.id, locationId: locationId }
                  });
            let paramPromise = axios.get('products/gmparameters');
            await axios
                .all([dataPromise, paramPromise])
                .then(
                    axios.spread(async (dataResp, paramResp) => {
                        this.parameters = paramResp.data;
                        this.productFamily = dataResp.data;
                        this.products =
                            this.productFamily.products;
                        this.product =
                            this.productFamily.products.find(
                                x => x.sku == this.id
                            ) ?? this.productFamily.products[0];
                        this.productInventory =
                            this.product.productInventories.length > 0
                                ? this.product.productInventories[0]
                                : null;

                        this.$set(
                            this.productFamily,
                            'imageUrl',
                            unescape(this.productFamily.onlineProfileProperties.images[0]
                                .dataURL)
                        );
                        if (!this.isNew) {
                            if (
                                this.productFamily.productType == 2 ||
                                this.productFamily.productType == 0
                            ) {
                                this.$toasted.global
                                    .app_error(
                                        "Invalid product url, product type doesn't match "
                                    )
                                    .goAway(5000);
                                this.$router.push('/products');
                            }
                        }
                        if (this.isNew) {
                            this.productFamily.packageTypeId = this.parameters.defaultPackageTypeId;
                        }
                    })
                )
                .finally(() => {
                    this.isBusy = false;
                    this.dataLoaded();
                    
                    this.isPrintLabelClicked = false;
                });
        },
        updatedDefaultSupplier() {
            this.$refs?.generalTab.refreshDefaultSupplier();
        },        
        navigateToSerializationTab(){
            this.isManageButtonClicked = true;
        },
        async onPrintLabelClicked(printLabel){
            if(this.formIsDirty)
            {
                await this.onSubmit();
            }
            if(printLabel)
            {
                this.$bvModal.show('printLabelModal');
            }            
        },
        clearDirtyFlag(){
            this.dataLoaded();
        }
    },
    mounted: async function() {
        await this.loadData();
    },
    components: {
        GeneralTab,
        SuppliersTab,
        SerializationTab
    }
};
</script>
