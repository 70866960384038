<template>
    <div>
        <b-card v-for="(invoice, index) in invoices" :key="index" class="b">
            <Invoice :invoice="invoice"
                     :purchase-order-details="avaliableProducts"
                     :all-request-details='allRequestDetails'
                     :is-busy="isBusy"
                     :purchase-order-id="purchaseOrder.purchaseOrderId"
                     @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                     @delete-invoice="deleteInvoice(invoice)" />
        </b-card>
        <p-button variant="outline-primary" @click="addInvoice" :is-busy="isBusy">Add New Invoice</p-button>
    </div>
</template>

<script>
    import Invoice from "./Invoice.vue";
    export default {
        name: "Invoices",
        props: {
            //used for default values on new purchase orders, existing invoices to load, and avaliable products to add to invoice.
            purchaseOrder: {
                type: Object,
                required: true
            },
            isBusy: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            Invoice,
        },
        data() {
            return {
                invoiceTemplate: {
                    isPaid: false,
                    invoiceDetails: [],
                },
                creditRequestDetails: [],

            };
        },
        computed: {
            invoices() {
                return this.purchaseOrder.invoices;
            },
            purchaseOrderDetails() {
                return this.purchaseOrder.purchaseOrderDetails;
            },
            avaliableProducts() {
                return this.purchaseOrderDetails.map((x) => ({
                    totalInvoicedQty: this.invoicedQty(x.sku, x.termId),
                    ...x,
                }));
            },
            allInvoiceDetails() {
                if (this.invoices.length === 0) {
                    return [];
                }
                return this.invoices
                    .map((x) => x.invoiceDetails)
                    .reduce(function (a, b) {
                        return a.concat(b);
                    });
            },

            allRequestDetails: function () {
                return this.purchaseOrder.creditRequests.slice().flatMap(x => x.creditRequestDetails);
            }
        },
        created: function () {
            var { purchaseOrderDetails } = this;
            this.allInvoiceDetails.forEach((x) => {
                let pod = purchaseOrderDetails.find((p) => p.sku === x.sku && p.termId === x.termId && x.purchaseOrderId === this.purchaseOrder.purchaseOrderId);

                if (pod) {
                    //purchaseOrderDetail is used by credit requests, can probably be removed when that section is reworked to work without POs
                    x.purchaseOrderDetail = pod;
                    x.totalPurchaseOrderReceivedQty = x.purchaseOrderDetail.receivedQty;
                    x.totalInvoicedQty = pod.invoicedQty;
                }
            });
        },
        methods: {
            deleteInvoice(invoice) {
                this.$bvModal
                    .msgBoxConfirm("Are you sure you want to delete this invoice?")
                    .then((value) => {
                        if (value) {
                            let index = this.invoices.indexOf(invoice);
                            if (index !== -1) {
                                this.invoices.splice(index, 1);
                            }
                        }
                    });
            },
            invoicedQty(sku, termId) {
                //could be on multiple invoices, so grab the total accross them
                return this.allInvoiceDetails
                    .filter((x) => x.sku === sku && x.termId === termId)
                    .reduce((a, i) => a + i.qty, 0);
            },
            addInvoice() {
                this.invoices.push({
                    ...this.invoiceTemplate,
                    supplierId: this.purchaseOrder.supplierId
                });
            },
        },
    };
</script>
