<template>
    <p-form @submit="onSubmit" id="edit-form">
        <div class="container-fluid content-wrapper" v-if="instructor">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Instructor</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button
                        type="submit"
                        form="edit-form"
                        variant="primary"
                        >Save</b-button
                    >
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col>
                    <b-button
                        variant="outline-primary"
                        v-b-modal.AddDepartmentModel
                        ><b-icon-plus></b-icon-plus> Add Department</b-button
                    >
                </b-col>
            </b-row>
            <b-col class="pl-0">
                <p-card>
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                        <b-col>
                            <p-input
                                label="Name"
                                v-model="instructor.name"
                                rules="required|max:80"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Email"
                                v-model="instructor.email"
                                rules="required|email|max:255"
                            />
                        </b-col>
                        <b-col>
                            <p-phone-number
                                v-model="instructor.phoneNumber"
                                label="Phone Number"
                                rules="max:50"
                            />
                        </b-col>
                        <b-col>
                            <p-phone-number
                                v-model="instructor.faxNumber"
                                label="Fax Number"
                                rules="max:50"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p-checkbox
                                label="Is Active?"
                                v-model="instructor.isActive"
                            />
                        </b-col>
                    </b-row>
                </p-card>
                <b-row>
                    <b-col>
                        <p-card title="Departments">
                            <b-row class="mb-3">
                                <b-col>
                                    <b-button
                                        variant="outline-primary"
                                        v-b-modal.AddDepartmentModel
                                        ><b-icon-plus></b-icon-plus> Add
                                        Department</b-button
                                    >
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12">
                                    <p-select
                                        dataType="campuses"
                                        v-model="campusFilterId"
                                        noSelectionMessage="All Campuses"
                                    />
                                </b-col>
                            </b-row>
                            <p-table
                                :items="filteredDepartments"
                                :fields="fields"
                                :enable-row-selection="false"
                            >
                                <template
                                    v-slot:cell(isDefault)="{ value, item }"
                                >
                                    <p-checkbox
                                        v-model="item.isDefault"
                                        name="isDefault"
                                        @input="isDefaultChange(item)"
                                    >
                                    </p-checkbox>
                                </template>
                                <template
                                    v-slot:cell(removeDepartment)="{
                                        value,
                                        item
                                    }"
                                >
                                    <b-button class="p-1 ml-1 short-button">
                                        <BIconTrash
                                            icon="trash"
                                            @click="removeDepartment(item)"
                                        ></BIconTrash
                                    ></b-button>
                                </template>
                            </p-table>
                        </p-card>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class=" mb-3 text-center">
                        <b-button
                            variant="primary"
                            type="submit"
                            form="edit-form"
                            >Save</b-button
                        >
                    </b-col>
                </b-row>
            </b-col>
            <p-modal
                @show="
                    newDepartment = {
                        department: null,
                        campus: null,
                        isDefault: false
                    }
                "
                id="AddDepartmentModel"
                size="sm"
                name="AddDepartmentModel"
                label="Add Department"
                hide-footer
            >
                <template v-slot:default>
                    <p-form @submit="onSubmitAddDepartment">
                        <p-advanced-select
                            return-object
                            label="Campus"
                            dataType="campuses"
                            rules="required"
                            v-model="newDepartment.campus"
                        />
                        <p-advanced-select
                            label="Academic Department"
                            return-object
                            :options="departmentOptions"
                            rules="required"
                            v-model="newDepartment.department"
                        />
                        <p-checkbox
                            label="Is Default"
                            v-model="newDepartment.isDefault"
                        />
                        <b-row align-h="end">
                            <b-button
                                class="mt-2 mr-3"
                                variant="primary"
                                type="submit"
                                >Add</b-button
                            >
                        </b-row>
                    </p-form>
                </template>
            </p-modal>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [NavigationGuard],
    props: {
        id: String
    },
    data() {
        return {
            newDepartment: {},
            campusFilterId: null,
            instructor: null,
            departments: null,
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },
                {
                    key: 'code',
                    sortable: true
                },
                {
                    key: 'campus',
                    sortable: true
                },
                {
                    key: 'isDefault',
                    sortable: true
                },
                {
                    key: 'removeDepartment',
                    label: '',
                    sortable: false
                }
            ]
        };
    },
    watch: {
        instructor: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        filteredDepartments() {
            if (!this.campusFilterId) {
                return this.instructor.instructorDepartments;
            }
            return this.instructor.instructorDepartments.filter(
                x => x.campusId === this.campusFilterId
            );
        },
        departmentOptions() {
            if (this.newDepartment.campus) {
                let list = this.departments.filter(
                    x => this.newDepartment.campus.value === x.campusId
                );
                return list;
            }
            return [];
        }
    },
    methods: {
        removeDepartment(item) {
            this.instructor.instructorDepartments = this.instructor.instructorDepartments.filter(
                x =>
                    x.campusId != item.campusId ||
                    x.courseDepartmentId != item.courseDepartmentId
            );
        },
        isDefaultChange(item) {
            if (item.isDefault) {
                //remove other default for the campus if applicable
                let otherDefault = this.instructor.instructorDepartments.find(
                    x =>
                        x.isDefault &&
                        x.campusId === item.campusId &&
                        x.courseDepartmentId != item.courseDepartmentId
                );
                if (otherDefault) {
                    otherDefault.isDefault = false;
                }
            }
        },
        onSubmit() {
            axios.post('instructors', this.instructor).then(response => {
                this.dataSaved();
                if (this.isNew) {
                    this.$router.push('/instructors/' + response.data);
                }
                this.$toasted.global
                    .app_success(
                        `Instructor '${this.instructor.name}' saved successfully.`
                    )
                    .goAway(5000);
            });
        },
        onSubmitAddDepartment() {
            let campusId = this.newDepartment.campus.value;
            let courseDepartmentId = this.newDepartment.department.value;

            if (
                this.instructor.instructorDepartments.some(
                    x =>
                        x.campusId === campusId &&
                        x.courseDepartmentId === courseDepartmentId
                )
            ) {
                this.$toasted.global.app_error(
                    'Cannot add department.  The instructor is already assigned to the selected department for the selected campus.'
                );
                return;
            }

            let newDepartment = {
                campus: this.newDepartment.campus.text,
                campusId,
                isDefault: !!this.newDepartment.isDefault,
                name: this.newDepartment.department.text,
                courseDepartmentId,
                ...this.newDepartment.department
            };
            this.instructor.instructorDepartments.push(newDepartment);
            this.isDefaultChange(newDepartment);
            this.$bvModal.hide('AddDepartmentModel');
        },
        async loadData() {
            let promise = this.isNew
                ? axios.get('instructors/new')
                : axios.get('instructors', { params: { id: this.id } });
            promise.then(resp => {
                this.instructor = resp.data;
                 if (!this.isNew) {
                         this.dataLoaded();
                    }
            });
            this.departments = await selectListOptionsDataContext.getStoreDropdownData(
                'courseDepartments'
            );
        }
    },
    mounted: async function() {
        await this.loadData();
    }
};
</script>

<style scoped></style>
