<template>
    <div class="container-fluid content-wrapper" v-if='account'>
        <p-card>
            <b-row>
                <b-col cols="3" lg="1" >
                    <div>Active</div>
                    <p-checkbox
                        class="d-inline-block"
                        v-model="account.isActive"
                        useSwitch
                        style="margin-top:10px"
                    >
                    </p-checkbox>
                </b-col>
                <b-col cols="12" lg="3">
                    <p-input
                        label="Account Code"
                        rules="required|max:26"
                        v-model="account.accountCode"
                    />
                </b-col>
                <b-col cols="9" lg="3">
                    <p-input
                        label="Agency Name"
                        rules="required|max:80"
                        v-model="account.agencyName"
                    />
                </b-col>  
                <b-col cols="12" lg="5">
                    <p-feature-flag ref="BalanceDue" name="Balance Due">  
                    <p-number
                            label="Balance Due"
                            name="Balance Due"
                            v-model="account.accountBalance"
                            disabled="disabled"
                            input-type="money"
                            left-align
                        >
                            <template v-slot:append>
                                <p-button
                                    class="pl-0"   
                                    variant='outline-primary'
                                    @click="$bvModal.show('applyPaymentModal')" 
                                    :disabled="isNew"
                                                             
                                >
                                Apply Payment
                                </p-button>
                                <p-button
                                    class="pl-0"  
                                    variant='outline-primary' 
                                    @click="$bvModal.show('adjustBalanceModal')" 
                                    v-if="$store.getters.currentUserHasRole('Admin')"
                                >
                                Adjust Balance
                                </p-button>
                            </template>
                        </p-number>
                        </p-feature-flag>
                </b-col>              
            </b-row>
             <b-row cols="1" cols-lg="4">
                 <b-col>
                    <p-datepicker
                        label="Default Funding Start Date"
                        v-model="account.defaultStartDate"
                         @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                        vid="startDate"
                    />
                </b-col>
                <b-col>
                    <p-datepicker
                        label="Default Funding End Date"
                        v-model="account.defaultEndDate"
                        @date-picker-initialized="e => {$emit('date-picker-initialized');}"
                        vid="endDate"
                        rules="dateGreaterOrEqual:@startDate"
                    />
                </b-col>
                <b-col>
                    <p-select
                        label="Fund Type"
                        :options="fundTypes"
                        rules="required"
                        v-model="account.accountType"
                        @change="onFundTypeChangeHandler()"
                    />
                </b-col>
                <b-col>
                    <p-select
                        label="Type"
                        :options="types"
                        text-field="text"
                        rules="required"
                        v-model="account.type"
                        @change="onTypeChangeHandler()"
                    />
                </b-col>
             </b-row>
             <b-row>
                 <b-col cols="12" lg="2">
                    <p-number
                        input-type="money"
                        label="Default Credit Limit"
                        left-align
                        :rules="getMinValue"
                        v-model="account.defaultCreditLimit"
                        :disabled="disableCreditLimit"
                    />
                </b-col>
                <b-col cols="12" lg="2" class="mt-3 pt-3" :class="account.noLimit ? 'text-red': ''">
                     <p-checkbox label="No Limit" :disabled="disabledNoLimit" v-model="account.noLimit" @change="onNoLimitChangeHandler()"/>
                </b-col>
                <b-col cols="12" lg="3">
                    <p-number
                        input-type="money"
                        label="Default Account Balance"
                        rules="min_value:0"
                        left-align
                        :disabled="disableAccountBalance"
                        v-model="account.defaultBalance"
                    />
                </b-col>
                <b-col cols="3" lg="2" class="mt-3 pt-3">
                    <p-checkbox label="Tax Exempt" v-model="account.isTaxExempt"/>
                </b-col>
                <b-col cols="9" lg="3">
                    <p-input
                        label="Tax Exempt Code"
                        v-model="account.taxExemptId"
                        rules="max:15"
                    />
                </b-col>
             </b-row>
             <b-row cols="1" cols-lg="2">
                 <b-col>
                     <p-advanced-select
                        label="Tender"
                        data-type="tenders"
                        v-model="account.tenderId"
                        rules="required"

                    />
                </b-col>
                <b-col>
                    <p-input
                        label="Comments"
                        v-model="account.comment"
                        rules="max:256"
                    />
                </b-col>
             </b-row>
             <b-row cols="1" cols-lg="2">
                <b-col>
                    <p-address-input
                        label="Contact Address"
                        v-model="account.contactAddress"
                    />
                </b-col>
                <b-col>
                    <p-address-input
                        label="Billing Address"
                        v-model="account.billingAddress"
                        same-as-address-name="Contact"
                        :same-as-address="account.contactAddress"
                    />
                </b-col>
             </b-row>
             <b-row cols="1" cols-lg="2">
                 <b-col>
                     <p-phone-number
                        label="Contact Phone"
                        rules="max:26"
                        v-model="account.contactPhone.number"
                    />
                 </b-col>
                 <b-col>
                     <p-input
                        label="Contact Email"
                        rules="email|max:254"
                        v-model="account.contactEmail"
                    />
                 </b-col>
             </b-row>
        </p-card>
        <p-modal
            size="md"
            label="Apply Payment"
            name="applyPaymentModal"
            @ok.prevent="balanceDueUpdate(false)"
        >
                <template>
                    <p-form ref="applyPaymentForm">
                        <div>
                            <b-row>
                                <b-col>
                                    <strong>Account Code</strong>
                                </b-col>
                                <b-col>
                                    <strong>Agency Name</strong>
                                </b-col>
                                <b-col>
                                    <strong>Balance Due</strong>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>{{ account.accountCode }}</b-col>
                                <b-col>{{ account.agencyName }}</b-col>
                                <b-col>{{ currencyFormatter(account.accountBalance)}}</b-col>
                            </b-row>
                            <b-row class="mt-3">
                                <b-col>
                                    <p-input
                                        name="Payment Id"
                                        label="Payment Id"   
                                        value="Generated On Save"                                     
                                        disabled
                                    />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-number
                                        name="Payment Amount"
                                        label="Payment Amount"
                                        v-model="balanceDueModalData.amount"
                                        input-type="money"
                                        rules="required|min_value:0"
                                    />
                                </b-col>
                                <b-col>
                                    <p-input
                                        name="Payment Reference"
                                        label="Payment Reference"
                                        v-model="balanceDueModalData.referenceNumber"
                                        rules="required|max:100"
                                    />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-text-area
                                        name="Payment Comment"
                                        label="Payment Comment"
                                        v-model="balanceDueModalData.comment"
                                        rules="max:4000"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                    </p-form>
                    </template>
                    <template v-slot:modal-footer="{ ok, cancel }">                       
                        <p-button variant="outline-primary" @click="cancel">Cancel</p-button>
                        <p-button variant="primary" @click="ok" >Save</p-button>                           
                    </template>
        </p-modal>
        <p-modal
            size="md"
            label="Adjust Balance Due"
            name="adjustBalanceModal"
            @ok.prevent="balanceDueUpdate(true)"
        >
                    <template>
                    <p-form ref="adjustBalanceForm">
                        <div>
                            <b-row>
                                <b-col>
                                    <strong>Account Code</strong>
                                </b-col>
                                <b-col>
                                    <strong>Agency Name</strong>
                                </b-col>
                                <b-col>
                                    <strong>Balance Due</strong>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>{{ account.accountCode }}</b-col>
                                <b-col>{{ account.agencyName }}</b-col>
                                <b-col>{{ currencyFormatter(account.accountBalance)}}</b-col>
                            </b-row>
                            <b-row class="mt-3">
                                <b-col>
                                    <p-input
                                        name="Adjustment Id"
                                        label="Adjustment Id"   
                                        value="Generated On Save"                                     
                                        disabled
                                    />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-number
                                        name="New Balance"
                                        label="New Balance"
                                        v-model="balanceDueModalData.amount"
                                        input-type="money"
                                        rules="required|min_value:0"
                                    />
                                </b-col>                                
                            </b-row>
                            <b-row>
                                <b-col>
                                    <p-text-area
                                        name="Adjustment Reason"
                                        label="Adjustment Reason"
                                        v-model="balanceDueModalData.comment"
                                        rules="required|max:4000"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                    </p-form>
                    </template>
                    <template v-slot:modal-footer="{ ok, cancel }">                       
                        <p-button variant="outline-primary" @click="cancel">Cancel</p-button>
                        <p-button variant="primary" @click="ok" >Save</p-button>                           
                    </template>
                </p-modal>
    </div>
</template>
<script>

import axios from 'axios';
import {
    currencyFormatter
} from '@/components/Common/Formatters.js';
export default {
  components: {  },
    props: {
        account: Object,
        isNew:Boolean
    },
    computed: {
        disableCreditLimit() {
            return this.account.type === 2 || this.account.type === null || this.account.noLimit
        },
        disableAccountBalance() {
            return this.account.type === 1 || this.account.type === null;
        },
        getMinValue(){
            if(this.account.type === 2 || this.account.noLimit ===true){
                return 'min_value:0';
            }else{
                return 'required|min_value:0';
            }

        },
        disabledNoLimit(){
            return this.account.accountType===4 || this.account.type === 2;
        }
    },
    methods: {
        currencyFormatter: currencyFormatter,
        onTypeChangeHandler() {
            if (this.account.type === 2) {
                this.account.isDebit = true;
                this.account.defaultCreditLimit = null;
                this.account.noLimit=false
            } else {
                this.account.isDebit = false;
                this.account.defaultBalance = null;
            }
        },
        onFundTypeChangeHandler(){
            if(this.account.accountType === 4 ){
                this.account.noLimit=false
            }
        },
        onNoLimitChangeHandler(){
            if(this.account.noLimit){
                this.account.defaultCreditLimit = null;
            }
        },
        async balanceDueUpdate(isAdjustBalance){

            if (!isAdjustBalance && !(await this.$refs.applyPaymentForm.validate())) {
                return;
            }
            if (isAdjustBalance && !(await this.$refs.adjustBalanceForm.validate())) {
                return;
            }
            this.balanceDueModalData.isAdjustBalance = isAdjustBalance;
            this.balanceDueModalData.accountId = this.account.accountId;
            this.balanceDueModalData.accountBalance = this.account.accountBalance;
            axios.post('accountReceivable/adjustbalancedue', this.balanceDueModalData).then(() => {
                if(isAdjustBalance){
                    this.$toasted.global
                    .app_success(
                        `Balance due adjusted successfully.`
                    )
                    .goAway(5000);   
                }else{
                    this.$toasted.global
                    .app_success(
                        `Account payment saved successfully.`
                    )
                    .goAway(5000);   
                }
                             
            }).finally(() => {
                this.resetModal();
            });
        },
        resetModal() {
            this.balanceDueModalData.amount=null;
            this.balanceDueModalData.referenceNumber=null;
            this.balanceDueModalData.comment=null;
            this.$bvModal.hide('applyPaymentModal');
            this.$bvModal.hide('adjustBalanceModal');
            this.$emit('load-data');
        }
    },
    data() {
        return {
            fundTypes: [
                {value: 6, text: 'Revolving'},
                {value: 4, text: 'Expiring'},
                {value: 10, text: 'Department Charge'}
            ],
            types: [
                {value: 1, text: 'Credit'},
                {value: 2, text: 'Debit'}
            ],
            balanceDueModalData: {
                amount:null,                
                referenceNumber:null,
                comment:null,
                accountId:null,
                accountBalance:null
            }
        }
    },
}
</script>
<style scoped>
.text-red {
    color:red;
}
</style>