<template>
    <b-tr :name="label == null ? '' : getInputName(label) + 'Row'">
        <b-th sticky-column>{{ label }}
            <p-button
                variant="outline-primary"
                size="sm"
                v-if="isModalEdit"
                :is-busy="isBusy"              
                @click="$emit('propertyModalEditClicked', propertyName)"
                :disabled="disabled"
            >
                <b-icon-pencil-square />
            </p-button>
        </b-th>
        <!-- If edit is needed, put the desired input in the slot -->
        <b-td :name="getInputName(label)" 
            ><slot>
                <p-number
                    v-if="isNumber"
                    text-only
                    :input-type="formatAs"
                    v-model="terms[0][propertyName]"
                    compact-format
                />
                <div v-else>
                    {{ terms[0][propertyName] }}
                </div>

            </slot>
        </b-td>
        <b-td
            :name="getInputName(label, index)"  
            v-for="(term, index) in terms.slice(1)"
            :key="term.termId"
            :class="{
                'accent-column': index % 2 === 0 && propertyName != null,
                'has-property': propertyName != null
            }"
        >
            <p-number
                v-if="isNumber"
                text-only
                :input-type="formatAs"
                v-model="term[propertyName]"
                compact-format
            />
            <div v-else>
                {{ term[propertyName] }}
            </div>
        </b-td>
    </b-tr>
</template>

<script>
export default {
    props: {
        label: String,
        propertyName: {
            type: String,
            default() {
                return null;
            }
        },
        //supports p-number input-types to
        //provide number formatting.
        formatAs: {
            type: String,
            default() {
                return '';
            }
        },
        terms: {
            type: Array,
            default() {
                return [];
            }
        },
        isModalEdit: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
    },
    data() {
        return {
            isBusy: false
        };
    },
    methods: {
        getInputName(label, index){
            if(label == null){
                return null;
            }

            let modifiedLabel = label.replace(/\W/g, '');
            if(index == null){
                return modifiedLabel;
            }
            return `${modifiedLabel}_${index}`;

        }
    },
    computed: {
        isNumber() {
            return (
                this.formatAs === 'money' ||
                this.formatAs === 'decimal' ||
                this.formatAs === 'percent' ||
                this.formatAs === 'integer'
            );
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
/deep/ .accent-column {
    background-color: $table-accent-bg;
}

td.has-property {
    border-left: 1px solid rgb(238, 238, 238);
}
</style>
