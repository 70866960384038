<template>
    <p-modal        
        ref="sessionPurchaserModal"
        button-label="Add / Edit"
        :icon-plus="true"
        size="lg"
        label="Session Purchasers"
        @ok="handleModalOk"
        @shown="loadData"
        :disable-button="disabled"
    >
        <template v-slot:default>
            <p-form ref="form">
                <div>
                    <b-row cols="1" >                        
                        <b-col>
                            <p-dragable
                                numbered
                                v-model="buybackSessionPurchasers"
                                :label="
                                    buybackSessionPurchasers.length > 1
                                        ? 'Active (Please list in priority order)'
                                        : 'Active'
                                "
                                :additional-options-label="
                                    'Inactive'
                                "
                                :additional-options="
                                    buyBackPurchasers
                                "
                                top-message="(Top priority)"
                            ></p-dragable>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        
        <template v-slot:modal-footer="{ ok }">
            <b-row class="w-100">
               <b-col class="mb-3 text-center">
                    <p-button :is-busy="isBusy" variant="primary" @click="ok" class="ml-2">Save</p-button>
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
    mixins: [StoreListManager],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        buybackSessionSetupPurchasers: {
            type: Array,
            default: () => null
        }
    },
    created: function() {
        this.buybackSessionPurchasers = this.buybackSessionSetupPurchasers;
    },
    data() {
        return {
            isBusy: false,            
            buyBackPurchasers:[],
            buybackSessionPurchasers:[],
            selectListOptions: {
                buybackpurchasers: []
            }
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        },
        buybackSessionSetupPurchasers: {
            handler: function(newVal) {
                this.buybackSessionPurchasers = newVal;
            }
        }
    },
    methods: {
        handleModalOk() {           
            this.$refs.sessionPurchaserModal.hide();
            this.buybackSessionPurchasers.map((x,i)=>x.priority=i+1);
            this.$emit('load-data',this.buybackSessionPurchasers);
        },
        loadData() {
            this.buyBackPurchasers = this.selectListOptions.buybackpurchasers
                .filter(
                    x => (!this.buybackSessionPurchasers.some(y=>y.buybackPurchaserId===x.buybackPurchaserId))
                )
                .map(x => ({ name : x.purchaserName, buybackPurchaserId : x.buybackPurchaserId}))
                     
        }
    }
};
</script>
