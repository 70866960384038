<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="catalog">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Web Catalog</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col>
                    <ProductMultiSelectQuickSearch
                        name="addProducts"
                        modal-label="Add Products"
                        label="Add Products"
                        icon-plus
                        variant="primary"
                        @selections="addSelectedProducts"
                    />
                </b-col>
            </b-row>
            <p-card>
                <b-row>
                    <b-col cols="12" lg="auto">
                        <span>Web Catalog Image </span>
                        <p-image
                            ref="generalImage"
                            v-model="
                                catalog.image
                            "
                            @newURL="newPrimaryURL"
                            class="ml-auto mt-2"
                        />
                    </b-col>
                    <b-col>
                        <b-row>
                            <b-col cols="12" sm="6">
                                <p-input
                                    label="Catalog Name"
                                    v-model="catalog.name"
                                    rules="required|max:255"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Catalog Group(s)"
                                    v-model="catalog.group"
                                    disabled
                                />
                            </b-col>
                            <b-col>
                                <p-select
                                    label="Status"
                                    data-type="catalogStatuses"
                                    v-model="catalog.status"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" sm="6">
                                <p-input
                                    label="Description"
                                    v-model="catalog.description"
                                    rules="max:2000"
                                />
                            </b-col>
                            <b-col cols="6" sm="3">
                                <p-datepicker
                                    label="Start Date"
                                    vid="catalogStartDate"
                                    v-model="catalog.startDate"
                                    :rules="startDateRules"
                                    :start-date="getStartDate"
                                    @date-picker-initialized="e => {dataLoaded()}"
                                    infoMessage="If status is Open, this is the date the catalog will start to appear online."
                                />
                            </b-col>
                            <b-col cols="3">
                                <p-datepicker
                                    label="End Date"
                                    v-model="catalog.endDate"
                                    :rules="endDateRules"
                                    :start-date="getStartDate"
                                    @date-picker-initialized="e => {dataLoaded()}"
                                    infoMessage="If status is Open, this is the date the catalog will no longer appear online."
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </p-card>
            <p-card>
                <b-row>
                    <b-col cols="1" class="pt-2" lg="auto">
                        <h4>Products</h4>
                    </b-col>
                    <b-col>
                        <ProductMultiSelectQuickSearch
                            label="Add"
                            icon-plus
                            @selections="addSelectedProducts"
                            okTitle="Add Product Family"
                        />
                    </b-col>
                </b-row>
                <b-row class="pt-3">
                    <b-col>
                        <p-table
                            :items="catalog.products"
                            :fields="productFields"
                            :enableRowSelection="false"
                        >
                            <template v-slot:cell(delete)="{ value, item }">
                                <b-button
                                    class="p-1 ml-1 short-button"
                                    @click="confirmDeleteDetail(item)"
                                >
                                    <BIconTrash
                                        icon="trash"
                                        font-scale="0.9"
                                        class="mb-2"
                                    ></BIconTrash>
                                </b-button>
                            </template>
                        </p-table>
                    </b-col>
                </b-row>
            </p-card>
            <b-row class="mt-3">
                <b-col>
                    <p-button
                        v-if="!isNew"
                        variant="outline-danger"
                        @click="deleteCatalog"
                        :is-busy="isBusy"
                        >Delete Catalog</p-button
                    >
                </b-col>
                <b-col cols="auto" class="ml-auto pr-0">
                    <p-button
                        variant="outline-primary"
                        @click="cancelEdit"
                        :is-busy="isBusy"
                        >Cancel</p-button
                    >
                </b-col>
                <b-col cols="auto">
                    <p-button variant="primary" type="submit" :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import editPage from '@/components/mixins/EditPage';
import unionBy from 'lodash/unionBy';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import { productTypeFormatter } from '@/components/Common/Formatters.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
    mixins: [NavigationGuard, editPage, StoreListManager],
    data() {
        return {
            catalog: null,
            isBusy: false,
            selectListOptions: {
                catalogStatuses: []
            },
            productFields: [
                {
                    key: 'authorTitleOrDescription',
                    label: 'Description',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'productFamilyProductCount',
                    label: 'Variant Count'
                },
                {
                    key: 'type',
                    label: 'Type',
                    automaticSearch: true,
                    sortable: true,
                    sortByFormatted: true,
                    formatter: productTypeFormatter
                },
                {
                    key: 'barcodes',
                    label: 'Barcode',
                    automaticSearch: true,
                    hidden: true
                },
                {
                    key: 'delete',
                    label: ''
                }
            ]
        };
    },
    watch: {
        catalog: NavigationGuard.$watcher
    },
    components: {
        ProductMultiSelectQuickSearch
    },
    computed: {
        startDateRules() {
            return this.catalog.status == 1 ? 'required' : '';
        },
        endDateRules() {
            return this.catalog.status == 1
                ? 'required|dateGreaterOrEqual:@catalogStartDate'
                : 'dateGreaterOrEqual:@catalogStartDate';
        },
        getStartDate() {
            var date = new Date();
            date.setDate(date.getDate());

            if (!this.catalog.startDate) return date;

            var catalogStartDate = new Date(this.catalog.startDate);

            if (catalogStartDate < date) {
                date = catalogStartDate;
            }

            return date;
        }
    },
    methods: {
        onSubmit() {
            if (!this.isBusy) {
                this.isBusy = true;
                axios
                    .post('catalogs', this.catalog)
                    .then(response => {
                        this.dataSaved();
                        if (this.isNew) {
                            this.$router.push('/webcatalogs/' + response.data);
                        }
                        this.$toasted.global
                            .app_success(
                                `Catalog '${this.catalog.name}' saved successfully.`
                            )
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
                // todo error handling
            }
        },
        deleteCatalog() {
            if (this.catalog.status !== 4) {
                this.$toasted.global
                    .app_error('Catalog must be inactive to be removed.')
                    .goAway(5000);
                return;
            }

            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this catalog?')
                .then(value => {
                    if (value) {
                        this.isBusy = true;
                        axios
                            .post('catalogs/delete', this.catalog)
                            .then(resp => {
                                if (resp) {
                                    this.dataSaved();
                                    this.$router.push('/webcatalogs');
                                }
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    }
                });
        },
        confirmDeleteDetail(item) {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to remove "' +
                        item.authorTitleOrDescription +
                        '" from the catalog?'
                )
                .then(value => {
                    if (value) {
                        let index = this.catalog.products.indexOf(item);
                        if (index !== -1) {
                            this.catalog.products.splice(index, 1);
                        }
                    }
                });
        },
        addSelectedProducts(items) {
            axios
                .post(
                    'catalogs/getbarcodes',
                    items.map(x => x.productFamilyId)
                )
                .then(value => {
                    if (value) {
                        items.forEach(i => {
                            let barcodes = value.data[i.productFamilyId];
                            if (barcodes) {
                                i.barcodes = barcodes;
                            }
                        });

                        this.catalog.products = unionBy(
                            this.catalog.products,
                            items,
                            x => x.productFamilyId
                        );
                    }
                });
        },
        cancelEdit() {
            this.$router.push('/webcatalogs');
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('catalogs/new')
                : axios.get('catalogs', { params: { id: this.id } });

            promise.then(resp => {
                this.catalog = resp.data;
                this.$set(
                        this.catalog,
                        'imageUrl',
                        unescape(this.catalog.image.dataURL)
                    );
                if (!this.isNew) {
                    this.dataLoaded();
                }
            });
        },
        newPrimaryURL(value) {
            this.catalog.imageUrl = value;
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>

<style scoped>
>>> .short-button {
    height: 20px;
}
</style>
