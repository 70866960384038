var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-form',[_c('div',[_c('b-row',{staticClass:"pb-2"},[_c('p-feature-flag',{attrs:{"name":"Product Images"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',[_c('img',{staticClass:"img-fluid",staticStyle:{"max-width":"120px","max-height":"120px"},attrs:{"src":_vm.primaryURL,"alt":"Product Image"}})])])],1),_c('b-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('strong',[_vm._v(_vm._s(_vm.productFamily.description))])])],1),_c('p-card',[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"text-left"},[_c('edit-supplier',{staticClass:"pb-2",attrs:{"vendor-id":"new","product":_vm.product,"productFamily":_vm.productFamily},on:{"load-data":_vm.updatedSupplier}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('p-table',{ref:"suppliersProfiles",attrs:{"items":_vm.productFamily.suppliersProperties,"fields":_vm.fieldsSuppliersProfiles,"enableRowSelection":false,"selectable":false,"sort-by":"vendorName"},scopedSlots:_vm._u([{key:"cell(edit)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('edit-supplier',{staticClass:"pb-2",attrs:{"vendor-id":item.vendorId,"product":_vm.product,"productFamily":_vm.productFamily},on:{"load-data":_vm.updatedSupplier}})]}},{key:"cell(isDefault)",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
                            var index = ref.index;
return [_c('p-checkbox',{attrs:{"value":item.isDefault,"disabled":_vm.isDisabled},on:{"change":function($event){return _vm.setDefaultSupplier(item)}}})]}},{key:"cell(removeDetail)",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('b-button',{staticClass:"p-1 ml-1 short-button",on:{"click":function($event){return _vm.deleteSupplier(item)}}},[_c('BIconTrash',{staticClass:"mb-3",attrs:{"font-scale":"0.9","icon":"trash"}})],1)]}}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }