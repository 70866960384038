<template>
    <p-form @submit="onSubmit" v-if="job" ref="form">
        <div class="container-fluid content-wrapper">
        <b-row class="content-heading">
            <b-col>
                <div>{{ isNew ? 'Add' : 'Edit' }} Data Exchange Job</div>
            </b-col>
            <b-col cols="auto" class="ml-auto">
                <p-button
                    type="submit"
                    variant="primary"
                    :disabled="!isUseFtp"
                    >Save</p-button
                >
            </b-col>
        </b-row>
        <b-row cols="1" cols-lg="4">
            <b-col>
                <p-select
                    label="Job Action"
                    :options="jobActions"
                    rules="required"
                    v-model="job.parameters.action"
                    @change="clearCriteria()"
                />
            </b-col>
            <b-col>
                <p-select
                    label="Job Type"
                    :options="jobTypesOptions"
                    :disabled="!job.parameters.action"
                    rules="required"
                    v-model="job.parameters.cronJobTypeId"
                />
            </b-col>
            <b-col cols="auto" class="ml-auto d-flex" v-if="!isNew">
                <div class="extra-padding v-center">
                    <p-checkbox
                        :use-switch="true"
                        size="lg"
                        class="d-inline-block"
                        v-model="job.isActive"
                    >
                    </p-checkbox>
                    <span class="mt-2">
                        {{ job.isActive ? 'Active' : 'Inactive' }}
                    </span>
                </div>
                <div class="v-center">
                    <p-button class="mt-2" variant="outline-danger"
                        @click="deleteJob()"
                        >Delete Job</p-button
                    >
                </div>
            </b-col>
        </b-row>
        <p-card>
            <b-row cols="1" cols-lg="2">
                <b-col>
                    <p-input
                        label="Job Name"
                        rules="required|max:50|valid_jobname"
                        v-model="job.name"
                        @input="areParametersDirty = true"
                    />
                </b-col>
            </b-row>
            <b-row cols="1" cols-lg="1">
                <b-col>
                    <p-text-area
                        label="Job Description"
                        rules="required|max:200"
                        v-model="job.description"
                        @input="areParametersDirty = true"
                    />
                </b-col>
            </b-row>
        </p-card>
                <p-card title="Criteria" v-if="job.parameters.action==='Export'">
                    <b-row class="pr-2">
                    <b-col class="pl-3 pr-2">
                            <b-form>
                                <b-form-group
                                    label-for="automatic"
                                    class="ml-3 mt-2 mr-2"
                                >
                                    <b-form-row>
                                        <b-col>
                                            <b-input-group>
                                                <b-form-input
                                                    size="lg"
                                                    name="automatic"
                                                    ref='searchRef'
                                                    type="search"
                                                    placeholder="Search by Transaction ID, Last Name, First Name, Customer Account #, or Serial #"
                                                    debounce="300"
                                                    @keydown.enter.prevent=""
                                                    v-model="job.searchSettings.automatic"
                                                />
                                                <p-select
                                                    class="ml-1 search-filter search-by-select"
                                                    style="height:15px;"
                                                    :options="filterBy"
                                                    v-model="job.searchSettings.searchBy"
                                                />
                                            </b-input-group>
                                        </b-col>
                                        <slot name="afterSearch"> </slot>
                                    </b-form-row>
                                </b-form-group>
                            </b-form>

                    </b-col>
                </b-row>
                    <b-row class="pl-3 pr-2" cols="1" cols-sm="2" cols-xl="4">
                    <b-col>
                        <p-select
                            label="POS Device"
                            data-type="posDevices"
                            noSelectionMessage="Show All"
                            v-model="job.searchSettings.posDeviceId"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Cashier"
                            data-type="nbcUsers"
                            noSelectionMessage="Show All"
                            v-model="job.searchSettings.cashierId"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Transaction Type"
                            :options="transactionTypeOptions"
                            noSelectionMessage="Show All"
                            v-model="job.searchSettings.transactionType"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Tender"
                            data-type="tenders"
                            noSelectionMessage="Show All"
                            v-model="job.searchSettings.tenderId"
                        />
                    </b-col>
                    <b-col>
                        <p-select
                            label="Date Range"
                            data-type="dateOption"
                            v-model="job.searchSettings.dateOption"
                            @change="onDateOptionChange($event)"
                            />
                    </b-col>
                     <b-col>
                        <p-datepicker v-if="job.searchSettings.dateOption == 8"
                            label="Transaction Date: From"
                            vid="transactionStartDate"
                            v-model="job.searchSettings.fromDate"
                        />
                    </b-col>
                    <b-col>
                        <p-datepicker v-if="job.searchSettings.dateOption == 8"
                            label="Transaction Date: To"
                            vid="transactionEndDate"
                            rules="dateGreaterOrEqual:@transactionStartDate"
                            is-end-date
                            v-model="job.searchSettings.toDate"
                        />
                    </b-col>
                </b-row>
                </p-card>
                <p-card title="Transport Method">
            <b-row cols="1" cols-lg="1">
                <b-col>
                    <b-radio-group
                        class="mt-3"
                        v-model="job.parameters.transportMethod"
                        @input="areParametersDirty = true"
                    >
                        <b-radio :value="1">FTP</b-radio>
                        <b-radio :value="2">SFTP</b-radio>
                        <!-- <b-radio :value="3">SCP</b-radio> -->
                        <b-radio :value="4" v-if="!isNew && job.parameters.action==='Import'"
                            >File Upload</b-radio
                        >
                    </b-radio-group>
                </b-col>
            </b-row>
            <b-row
                cols-lg="4"
                class="mb-2"
                cols="1"
                cols-sm="2"
                cols-xl="4"
                v-if="isUseFtp"
            >
                <b-col>
                    <p-input
                        label="FTP Address"
                        :rules="isUseFtp ? 'required|max:200' : ''"
                        v-model="job.parameters.ftpAddress"
                        @input="areParametersDirty = true"
                    />
                </b-col>
                <b-col>
                    <p-input
                        input-type="integer"
                        left-align
                        label="FTP Port"
                        :rules="
                            isUseFtp
                                ? 'required|min_value:1|max_value:65535|integer'
                                : ''
                        "
                        v-model.number="job.parameters.ftpPort"
                        type="number"
                        @input="areParametersDirty = true"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="FTP Username"
                        :rules="isUseFtp ? 'required|max:50' : ''"
                        v-model="job.parameters.ftpUserName"
                        @input="areParametersDirty = true"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="FTP Password"
                        :rules="isUseFtp ? 'required|max:100' : ''"
                        v-model="ftpPassword"
                        type="password"
                        @input="areParametersDirty = true"
                        @focus="$event.target.select()"
                    />
                </b-col>
                <b-col>
                    <p-input
                        label="File Name"
                        rules="required|max:200"
                        v-model="job.parameters.fileName"
                        @input="areParametersDirty = true"
                    />
                    <p-checkbox
                        v-if="job.parameters.action==='Export'"
                        label="Append Date &amp; Time"
                        class="appendDateTimeCheckbox"
                        size="sm"
                        :useSwitch="true"
                        v-model="job.parameters.appendDateTime"
                    />
                </b-col>

                <b-col>
                    <p-input
                        label="FTP Directory"
                        :rules="isUseFtp ? 'max:200' : ''"
                        v-model="job.parameters.ftpDirectory"
                        @input="areParametersDirty = true"
                    />
                </b-col>
            </b-row>
            <b-row
                        class="mb-2"
                        cols="1"
                        cols-xl="2"
                        v-if="!isUseFtp"
                    >
                        <b-col>
                            <b-form-file
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                ref="deFile"
                                v-model="file"
                            ></b-form-file>
                        </b-col>
                    </b-row>
                    <b-row cols-lg="4"
                        class="mb-2"
                        cols="1"
                        cols-sm="2"
                        cols-xl="4">
                        <b-col>
                    <p-button
                        class="mt-3 pt-2"
                        variant="primary"
                        v-if="!isNew"
                        @click="runJobNow"
                        :is-busy="isBusy || isSelectedJobTypeRunning"
                        >Run Now</p-button
                    >
                </b-col>
            </b-row>
        </p-card>
        <p-card>
            <div class="mt-2">
                <h4>Job Scheduler</h4>
            </div>
            <b-row>
                <b-col>
                    <b-form-group label="Occurs">
                        <b-radio-group
                            v-model="job.cronJobSchedules.occursSchedule"
                        >
                            <b-radio :value="'Daily'">Daily</b-radio>
                            <b-radio :value="'Weekly'">Weekly</b-radio>
                            <b-radio :value="'Monthly'">Monthly</b-radio>
                        </b-radio-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row v-if="job.cronJobSchedules.occursSchedule == 'Weekly'">
                <b-col>
                    <p-checkbox-group
                        label="Weekly"
                        :options="weekDays"
                        v-model="job.cronJobSchedules.daysOfWeek"
                    />
                </b-col>
            </b-row>
            <b-row v-if="job.cronJobSchedules.occursSchedule == 'Monthly'">
                <b-col>
                    <b-form-group label="Monthly">
                            <div class="d-inline-block mt-2">
                                    <div class="d-inline-block">
                                        Day
                                    </div>
                                <p-select
                                    class="ml-2 d-inline-block"
                                    :options="monthCount"
                                    v-model="job.cronJobSchedules.dateOfMonth"
                                />
                                <div class="d-inline-block ml-2">
                                    every month
                                </div>
                            </div>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Frequency">
                        <b-radio-group
                            v-model="job.cronJobSchedules.isOccursOnce"
                        >
                            <div>
                                <b-radio
                                    :value="true"
                                    class="d-inline-block mt-2"
                                    ><div class="d-inline-block mr-2">
                                        Occurs Once At
                                    </div></b-radio
                                ><p-input
                                    v-model="
                                        job.cronJobSchedules.occursOnceTime
                                    "
                                    class="d-inline-block"
                                    type="time"
                                    :disabled="
                                        !job.cronJobSchedules.isOccursOnce
                                    "
                                />
                            </div>
                            <br />
                            <b-radio
                                :value="false"
                                class="d-inline-block mt-2"
                                >Occurs Every </b-radio
                            ><b-time
                                hide-header
                                minutes-step="15"
                                :hour12="false"
                                locale="en-GB"
                                :disabled="job.cronJobSchedules.isOccursOnce"
                                v-model="job.cronJobSchedules.occursEveryTime"
                            ><span class="mr-5">Hours</span><span>Minutes</span></b-time>
                        </b-radio-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </p-card>
        <b-row>
            <b-col class="text-center">
                <p-button
                    variant="primary"
                    type="submit"
                    :is-busy="isBusy"
                    :disabled="!isUseFtp"
                    >Save</p-button
                >
            </b-col>
        </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import pInput from '../../components/p-input.vue';
import { extend } from 'vee-validate';
import utilityService from '@/services/UtilityService.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import JSEncrypt from 'jsencrypt';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import PCheckbox from '../../components/p-checkbox.vue';

const chartOfAccountsJobId = 1;

extend('valid_jobname', {
    message:
        'Job name can only contain alphanumeric characters',
    validate: value => {
        var letters = new RegExp(/^[A-Za-z0-9]+$/);
        return letters.test(value);
    }
});
export default {
    components: { pInput, PCheckbox },
    mixins: [NavigationGuard,StoreListManager],
    props: {
        id: String
    },
    data() {
        return {
            job: null,
            isBusy: false,
            jobActions: [
                { value: 'Export', text: 'Export' },
                { value: 'Import', text: 'Import' }
            ],
            filterBy:[
                'All',
                'Transaction ID',
                'Last Name',
                'First Name',
                'Customer Account #',
                'Serial #'
            ],
            weekDays: [
                { value: 0, text: 'Sun' },
                { value: 1, text: 'Mon' },
                { value: 2, text: 'Tues' },
                { value: 3, text: 'Wed' },
                { value: 4, text: 'Thurs' },
                { value: 5, text: 'Fri' },
                { value: 6, text: 'Sat' }
            ],
            monthCount: [
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30'
            ],
            file: null,
            transactionTypeOptions: [
                { text: 'Pay In', value: 'Pay In' },
                { text: 'Pay Out', value: 'Pay Out' },
                { text: 'Refund', value: 'Refund' },
                { text: 'Sale', value: 'Sale' }
            ],
            areParametersDirty: false,
            isCheckingJobs: false,
            runningJobs: [],
            publicKey: null,
            ftpPassword: null,
            selectListOptions: {
                jobTypes: []
            }
        };
    },
    watch: {
        job: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        isUseFtp() {
            return this.job.parameters.transportMethod == 1 || this.job.parameters.transportMethod == 2 || this.job.parameters.transportMethod == 3;
        },
        jobTypesOptions(){
            var jobTypesSelectList = this.selectListOptions.jobTypes;
            jobTypesSelectList = jobTypesSelectList.filter(jobType => jobType.filter === this.job.parameters.action || jobType.filter === 'Both')
            
            if(!this.$store.getters.featureFlagEnabled('Accounting'))
                jobTypesSelectList = jobTypesSelectList.filter(jobType => jobType.value != chartOfAccountsJobId);

            return jobTypesSelectList;
        },
        runDescription() {
            let description = "";
            if (this.job.name) {
                description = this.job.description ? this.job.name + ': ' + this.job.description : this.job.name;
            } else if (this.job.description) {
                description = this.job.description;
            }

            return description;
        },
        isSelectedJobTypeRunning() {
            return this.runningJobs.some(x => x.cronJobTypeId == this.job.parameters.cronJobTypeId);
        }
    },
    methods: {
        async loadData() {
            let promise = this.isNew
                ? axios.get('dataexchange/new')
                : axios.get('dataexchange', { params: { id: this.id } });

            promise.then(resp => {
                this.job = resp.data;
                this.ftpPassword = this.job.parameters.ftpPassword;
                if (!this.isNew) {
                    this.dataLoaded();
                } else {
                    this.job.parameters.transportMethod = 1;
                }
            });

            await this.checkRunningJobs();
        },
        async checkRunningJobs() {
            if (this.runningJobs.length) {
                let jobLogsQueryParams = this.runningJobs.map((x, index) => `cronJobLogIds[${index}]=${x.cronJobLogId}`).join('&');
                let jobs = await utilityService.checkDataExchangeJobsAsync(jobLogsQueryParams);
                this.runningJobs = jobs.runningJobs;
                if (this.runningJobs.length) {
                    this.isCheckingJobs = true;
                    setTimeout(this.checkRunningJobs, 60000);
                } else {
                    this.isCheckingJobs = false;
                }

                let finishedJobs = jobs.finishedJobs;
                finishedJobs.forEach(job => {
                    if (job.result == 0) {
                        this.$toasted.global
                            .app_error(
                                `The data exchange job '${job.description}' failed. Please review data exchange job logs for details.`
                            );
                    } else if (job.result == 1) {
                        this.$toasted.global
                            .app_success(
                                `The data exchange job '${job.description}' completed successfully. All records were processed.`
                            ).goAway(5000);
                    } else if (job.result == 2) {
                        this.$toasted.global
                            .app_success(
                                `The data exchange job '${job.description}' completed, but not all records were imported. Please review data exchange job logs for more details.`
                            );
                    }
                });
            }
        },
        async getPublicKey() {
            if (this.$store.getters.getPublicKey === null) {
                axios.get('selectlists/publicKey').then((resp) => {
                    this.$store.commit('updatePublicKey', resp.data);
                    this.publicKey = resp.data
                });
            }else{
                this.publicKey = this.$store.getters.getPublicKey;
            }
        },
        async onSubmit() {
            if(!this.job.cronJobSchedules.isOccursOnce && (this.job.cronJobSchedules.occursEveryTime==null || this.job.cronJobSchedules.occursEveryTime=="")){
                this.$toasted.global
                    .app_error(
                        'Please select a time'
                    )
                    .goAway(5000);
                return;
            }
            if(this.job.cronJobSchedules.isOccursOnce && (this.job.cronJobSchedules.occursOnceTime=="" || this.job.cronJobSchedules.occursOnceTime==null)){
                this.$toasted.global
                    .app_error(
                        'Please select a time'
                    )
                    .goAway(5000);
                return;
            }
            if(this.job.cronJobSchedules.occursSchedule=="Weekly" && this.job.cronJobSchedules.daysOfWeek.length==0){
                this.$toasted.global
                    .app_error(
                        'Please select at least one day'
                    )
                    .goAway(5000);
                return;
            }
            if(this.job.cronJobSchedules.occursSchedule=="Monthly" && this.job.cronJobSchedules.dateOfMonth == null){
                this.$toasted.global
                    .app_error(
                        'Please select at least one date.'
                    )
                    .goAway(5000);
                return;
            }
            this.isBusy = true;
            this.job.searchSettings.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();

            if(this.ftpPassword != "**********" && this.ftpPassword != null && this.ftpPassword != ""){
                this.job.parameters.ftpPassword = this.encryptData(this.ftpPassword);
            }
            axios
                .post('dataExchange', this.job)
                .then(response =>{
                    this.dataSaved();
                    this.$toasted.global
                        .app_success(
                            `Data Exchange Job '${this.job.name}' saved successfully.`
                        )
                        .goAway(5000);
                        if (this.isNew) {
                            this.$router.push('/dataexchange/' + response.data);
                        }
                        else {
                            this.loadData();
                        }
                        this.areParametersDirty = false;
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        runJobNow: async function() {
            this.isBusy = true;
            let runNowPromise = null;
            if (!(await this.$refs.form.validate())) {
                this.isBusy = false;
                return;
            }
            if(this.areParametersDirty && this.ftpPassword != "**********" && this.ftpPassword != null && this.ftpPassword != ""){
                this.job.parameters.ftpPassword = this.encryptData(this.ftpPassword);
            }

            await axios
                .post('dataExchange', this.job)
                .then(() => {
                    this.dataSaved();
                    this.areParametersDirty = false;
                });

            let runNowCommand = {
                source: "DataExchange",
                cronJobId: this.job.cronJobId,
                description: this.runDescription + ' - Manual Run',
                runDate: new Date(),
                parameters: this.job.parameters
            };
            this.job.lastRunDate = new Date();

            if (this.isUseFtp) {
                runNowPromise = axios.post('dataexchange/runjobnow', runNowCommand);
            } else {
                if (!this.file) {
                    this.$toasted.global.app_error(
                        'Please select a file or choose FTP.'
                    );
                    this.isBusy = false;
                    this.dataLoaded();
                    return;
                } else {
                    let formData = new FormData();
                    formData.append('file', this.file);
                    formData.append(
                        'commandString',
                        JSON.stringify(runNowCommand)
                        );
                    runNowPromise = axios.post('dataexchange/runuploadjobnow', formData);
                }
            }

            await runNowPromise
                .then(resp => {
                    this.$refs.deFile?.reset();
                    this.runningJobs = resp.data;
                    this.dataLoaded();
                    if (this.runningJobs.some(x => x.isTriggered)){
                        this.$toasted.global
                            .app_success(
                                `Data Exchange job '${runNowCommand.description}' is running. Feel free to continue working. A notification will display when the job has finished running.`
                            )
                            .goAway(10000);
                    } else {
                        this.$toasted.global
                            .app_error(
                                "Another data exchange job of this type is already running. Please wait for the other job finish before starting a new job."
                            );
                    }
                    if (!this.isCheckingJobs) {
                        this.isCheckingJobs = true;
                        setTimeout(this.checkRunningJobs, 60000);
                    }
                }).catch(() => {
                    this.$refs.deFile?.reset();
                    this.dataLoaded();
                    this.$toasted.global
                        .app_error(
                            "There was an error running the Data Exchange job. Please contact support if the problem persists."
                        );
                }).finally(() => {
                    this.isBusy = false;
                });
        },
        deleteJob() {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this Job?')
                .then(value => {
                    if (value) {
                        axios.post('dataexchange/delete', this.job).then(resp => {
                            if (resp) {
                                this.dataSaved();
                                this.$toasted.global
                                    .app_success(
                                        `Job deleted successfully.`
                                    ).goAway(3000);
                                this.$router.push('/dataexchange');
                            }
                        });
                    }
                });
         },
         clearCriteria(){
            if(this.job.parameters.action==='Import'){
                this.job.searchSettings.automatic=null;
                this.job.searchSettings.searchBy='All';
                this.job.searchSettings.posDeviceId=null;
                this.job.searchSettings.cashierId=null;
                this.job.searchSettings.transactionType=null;
                this.job.searchSettings.tenderId=null;
                this.job.searchSettings.fromDate=null;
                this.job.searchSettings.toDate=null;
            }
         },
         encryptData(data) {
            const encryptor = new JSEncrypt();
            encryptor.setPublicKey(this.publicKey);
            const encryptedData = encryptor.encrypt(data);
            return encryptedData;
        },
        onDateOptionChange(val){
            if(val==8){
                this.job.searchSettings.fromDate = new Date();
                this.job.searchSettings.toDate = new Date();
               return
            }
            this.job.searchSettings.fromDate = null;
            this.job.searchSettings.toDate = null;
        }
    },
    mounted: async function() {
        this.loadData();
        this.getPublicKey();
    }
};
</script>
<style scoped lang="scss">
.extra-padding {
    padding: 0 15px;
}
.v-center {
    display: flex;
    align-items: center;
}
.search-filter /deep/ .custom-select {
    height: 43px;
}

.appendDateTimeCheckbox {
    margin-top: -0.75rem;
}
</style>
