<template>
    <p-modal name="BarcodeListModal"
             label="Add Barcode"
            size="lg"
             @ok="okBarcode"
             @cancel="cancelBarcode"
             @hidden='handleModalHidden'
             @shown='handleModalShown'>
        <b-row>
            <b-col cols="6">
                <p-input label="Barcode"
                         v-model="newBarcode"
                         rules="max:40|alphanumeric"
                         >
                    <template v-slot:append>
                        <b-button class="ml-0 px-1"
                                  style="white-space:nowrap;"
                                   @click="addBarcode">Add</b-button>
                    </template>
                </p-input>
            </b-col>
           
        </b-row>
        <b-row>
            <b-col>
                <p-table :items="variant.barcodes"
                         :fields="barcodeFields"
                         :enableRowSelection="false">
                    <template v-slot:cell(isDefault)="{ value, item }">

                        <b-radio :value="item.barcode"
                                 name="isDefault"
                                 v-model="variant.defaultBarcode"> </b-radio>
                    </template>
                    <template v-slot:cell(delete)="{ value, item }">
                        <b-button v-if="currentVariant.sku != item.barcode" class="p-1 ml-1 short-button">
                            <BIconTrash icon="trash"
                                        @click="deleteProduct(item)"></BIconTrash>
                        </b-button>
                    </template>
                </p-table>
            </b-col>
        </b-row>
    </p-modal>


</template>

<script>
    import axios from 'axios';
export default {
    props: {
        currentVariant: Object,
        type: String,
        },
          data() {
            return {
                newBarcode: null,
                 variant: {
                     barcodes: [],
                     defaultBarcode: null
            },
             barcodeFields: [
                    {
                        key: 'isDefault',
                        label: 'Default'

                    },
                    {
                      key: 'barcode',
                      label: 'Barcode'
                    },
                     {
                      key: 'symbology',
                      label: 'Symbology'
                    },
                    {
                        key: 'delete',
                        label: 'Delete'
                },],
             };
        },
        computed: {
        },
    methods: {
        addBarcode() {
            if (!this.newBarcode) { this.$toasted.global
                            .app_error('Please enter barcode.')
                .goAway(5000);
             return;}
                if (this.newBarcode.length > 40) {
                     this.$toasted.global
                            .app_error('Barcode length must be less then 40 .')
                        .goAway(5000);
                    return;
                }
                let count = (this.newBarcode.match(/^[0-9A-Za-z]+$/));
                if (!count) {
                     this.$toasted.global
                            .app_error('This barcode can have only alphanumeric characters.')
                        .goAway(5000);
                    return;

            }
            if (this.variant.barcodes.some(x => x.barcode === this.newBarcode)) {
                 this.$toasted.global
                            .app_error('This barcode is already used by another product. It may not be used again.')
                    .goAway(5000);
                return;
            }
            axios.get('products/addBarcode', { params: { barcode: this.newBarcode } }).then(resp => {
                if (resp && resp.data && resp.data.barcode) {
                        var self = this;
                        var newBarcodeItem = resp.data;
                        newBarcodeItem.isDefault = !self.variant.defaultBarcode;
                        self.variant.barcodes.push(newBarcodeItem);
                        self.variant.barcodes= this.sortBarcodes(self.variant.barcodes);
                        this.newBarcode = '';
                    }
                });
            
        },
           deleteProduct(item) {
                if (item.barcode === this.variant.defaultBarcode ) {
                    this.$toasted.global
                            .app_error('Please select a new default barcode before deletion.')
                        .goAway(5000);
                    return;
                }
                  var currentBarcode = this.variant.barcodes.indexOf(item);
                  if (currentBarcode > -1) {
                      this.variant.barcodes.splice(currentBarcode, 1);
                  }
        },
        okBarcode() {
            this.variant.barcodes.forEach(x => x.isDefault = x.barcode === this.variant.defaultBarcode);
             this.$emit('ok', this.variant);
        },
        cancelBarcode() {
             this.$emit('cancel', this.variant);
        },
          handleModalHidden() {
            this.variant.barcodes =[] ;
              this.variant.defaultBarcode = null;
              this.newBarcode= null;
        },
        handleModalShown() {
            this.variant.barcodes = this.sortBarcodes(this.currentVariant.barcodes.map(b => ({ ...b })));

            this.variant.defaultBarcode = this.currentVariant.defaultBarcode;
            this.variant.barcodes.forEach(x => {
                x.isDefault = x.barcode === this.variant.defaultBarcode;
            });
        },
        sortBarcodes(barcodes) {
            return barcodes.sort((a, b) => a.barcode.localeCompare(b.barcode));
        }
    }
}
</script>
