<template>
    <div>
        <p-modal
            @ok="exportCreditRequestReport"
            size="sm"
            button-label="Export PDF"
            :button-busy="isBusy"
            label="Export Credit Request"
            ref="exportCreditRequestModal"
        >
            <template v-slot:default>
                <p-select
                    label="Sort Order"
                    :options="sortOrderOptions"
                    v-model="sortExportBy"
                />
                <p-checkbox
                    label="Include Retail Price"
                    v-model="includeRetail"
            />
            </template>
        </p-modal>
    </div>
</template>

<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    name: 'ExportCreditRequest',
    props: {
        creditRequest: null
    },
    data() {
        return {
            isBusy: false,            
            sortOrderOptions: [
                {
                    text: 'Author/Title or Description',
                    value: 'AuthorTitleOrDescription'
                },
                { text: 'ISBN', value: 'isbnOrCatNumber' },
                { text: 'Quantity', value: 'returnQty' },
                { text: 'Invoice Number', value: 'invoiceNumber' }
            ],
            sortExportBy: 'AuthorTitleOrDescription',
            includeRetail: false
        };
    },
    methods: {
		exportCreditRequestReport() {
            let apiUrl = `creditRequests/export?id=${this.creditRequest.creditRequestId}&sortBy=${this.sortExportBy}&includeRetail=${this.includeRetail}`;
            this.isBusy = true;

            axios
                .get(apiUrl, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        `CreditRequest_${this.creditRequest.requestNumber}`,
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            `Credit Request report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }
}
</script>
