<template>
    <p-form ref="form">
        <p-modal
            ref="linkAccountModal"
            size="lg"
            :button-label="label"
            :label="modalTitle"
            @ok.prevent="handleModalOk"
            @show="loadData"
            @hidden="resetModal"
        >
            <template v-slot:default>
                <p-card v-if="(existingCustomers || !isNew) && currentAccount">
                    <b-row >
                        <b-col cols="9" sm="9" xl="9">
                            <p-advanced-select
                                label="Account"
                                data-type="accounts"
                                v-model="currentAccount.accountId"
                                :disabled="!isNew"
                                :filter="filterAccount"
                                @change="accountChangeHandler()"
                            />
                        </b-col>
                        <b-col cols="3" sm="3" xl="3" class="mt-3 pt-3">
                            <p-checkbox
                                label="Active"
                                :disabled="true"
                                v-model="currentAccount.isActive"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-lg="2">
                        <b-col>
                            <p-datepicker
                                label="Start Date"
                                v-model="currentAccount.fundingStartDate"
                                vid="startDate"
                                rules="required"
                            />
                        </b-col>
                        <b-col>
                            <p-datepicker
                                label="End Date"
                                vid="endDate"
                                v-model="currentAccount.fundingEndDate"
                                rules="required|dateGreaterOrEqual:@startDate"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-lg="3">
                        <b-col>
                            <p-number
                                input-type="money"
                                label="Credit Limit"
                                v-model="currentAccount.creditLimit"
                                rules="min_value:0"
                                :disabled="currentAccount.isDebit || currentAccount.noLimit"
                                @change="creditLimitChangeHandler()"
                            />
                            <span v-if="currentAccount.noLimit" style="color:red">No Limit</span>
                        </b-col>
                        <b-col>
                            <p-number
                                input-type="money"
                                label="Customer Balance"
                                v-model="currentAccount.customerBalance"
                                @change="customerBalanceChangeHandler()"
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                disabled="true"
                                input-type="money"
                                label="Available Funds"
                                v-model="currentAccount.availableFund"
                            />
                        </b-col>
                    </b-row>
                </p-card>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-row class="w-100">
                    <b-col class="mb-3 text-right pr-0">
                        <p-button
                            variant="outline-primary"
                            @click="cancel"
                            class="ml-2"
                            :is-busy="isBusy"
                            >Cancel</p-button
                        >
                        <p-button
                            variant="primary"
                            @click="ok"
                            class="ml-2"
                            :is-busy="isBusy"
                            >OK</p-button
                        >
                    </b-col>
                </b-row>
            </template>
        </p-modal>
    </p-form>
</template>

<script>
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import axios from 'axios';

export default {
    mixins: [NavigationGuard],
    props: {
        account: Object,
        customer: Object,
        label: String,
        id: String
    },
    data() {
        return {
            isBusy: false,
            existingCustomers: null,
            currentAccount: Object
        };
    },
    created: function() {
        this.currentAccount = this.account;
    },
    computed: {
        isNew() {
            return this.id === 'new';
        },
        modalTitle() {
            return this.customer.firstName + ' ' + this.customer.lastName;
        }
    },
    watch: {
        account: {
            handler: function(newVal) {
                this.currentAccount = newVal;
            }
        }
    },
    methods: {
        resetModal(){
            this.$emit('result-updated');
            this.$refs.linkAccountModal.hide();
            this.currentAccount.noLimit = false;
        },
        creditLimitChangeHandler() {
            if (this.currentAccount.isDebit) {
                this.currentAccount.availableFund =
                this.currentAccount.customerBalance;
            }else{
                this.currentAccount.availableFund =
                this.currentAccount.creditLimit -
                this.currentAccount.customerBalance;
            }

        },
        customerBalanceChangeHandler() {
            if (this.currentAccount.isDebit) {
                this.currentAccount.availableFund =
                this.currentAccount.customerBalance;
            }else{
                this.currentAccount.availableFund =
                this.currentAccount.creditLimit -
                this.currentAccount.customerBalance;
            }
        },
        accountChangeHandler() {
            axios
                .get('AccountReceivable', {
                    params: { id: this.currentAccount.accountId }
                })
                .then(response => {
                    let accountDetails = response.data;

                    this.currentAccount.creditLimit =
                        accountDetails.defaultCreditLimit;
                    this.currentAccount.fundingStartDate =
                        accountDetails.defaultStartDate;
                    this.currentAccount.fundingEndDate =
                        accountDetails.defaultEndDate;
                    this.currentAccount.customerBalance =
                        accountDetails.defaultBalance;
                    this.currentAccount.isDebit = accountDetails.isDebit;
                    if (this.currentAccount.isDebit) {
                        this.currentAccount.availableFund =
                        this.currentAccount.customerBalance;
                    }else{
                        this.currentAccount.availableFund =
                        this.currentAccount.creditLimit -
                        this.currentAccount.customerBalance;
                    }
                    this.currentAccount.noLimit = accountDetails.noLimit;
                });
        },
        handleModalOk: async function(event) {
            event.preventDefault();
            if (!(await this.$refs.form.validate())) {
                return;
            }
            this.currentAccount.customerId = this.customer.customerId;
            if(this.currentAccount.noLimit){
                this.currentAccount.creditLimit = null
            }
            else{
                this.currentAccount.creditLimit =
                    this.currentAccount.creditLimit === null
                        ? 0
                        : this.currentAccount.creditLimit;
            }
            this.currentAccount.customerBalance =
                this.currentAccount.customerBalance === null
                    ? 0
                    : this.currentAccount.customerBalance;
            axios
                .post('customers/updateCustomerBalance', this.currentAccount)
                .then(() => {
                    this.$emit('result-updated');
                    this.$refs.linkAccountModal.hide();
                });
        },
        loadData() {
            this.existingCustomers = null;
            if (this.isNew) {
                this.currentAccount.accountId = null;
                this.currentAccount.creditLimit = null;
                this.currentAccount.fundingStartDate = null;
                this.currentAccount.fundingEndDate = null;
                this.currentAccount.customerBalance = null;
                this.currentAccount.isDebit = false;
                this.currentAccount.isActive = true;
                this.currentAccount.availableFund = null;
                axios
                    .get('customers/searchcustomeraccount', {
                        params: {
                            perPage: 1000,
                            automatic: '',
                            isInactive: true,
                            searchBy: 'All',
                            customerId: this.customer.customerId
                        }
                    })
                    .then(resp => {
                        this.existingCustomers = resp.data.data;
                    });
            }
            else{
                this.currentAccount.creditLimit = this.currentAccount.noLimit ? null : this.currentAccount.creditLimit;
            }
        },
        filterAccount(account) {
            return (
                !this.isNew ||
                !this.existingCustomers?.some(x => x.accountId === account.value)
            );
        }
    }
};
</script>
