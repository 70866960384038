<template>
  <SearchScreen
    title="Sequences"
    api-destination="sequences"
    edit-key="id"
    :filters="filters"
    :fields="fields"
    :filterBy="filterBy"
  >
  </SearchScreen>
</template>

<script>
import SearchScreen from "../../components/SearchScreen.vue";
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
  mixins: [SearchStorage],
  data() {
    return {
      filterName: 'SequencesFilter',
      filters: {
        automatic: "",
        perPage: 100,
        sortBy: 'name',
        sortDesc: true,
        searchBy:'All'
      },
      filterBy:[
          'All',
          'Sequence Name',
          'Template Name',
          'Type'
      ],
      fields: [
        {
          key: "name",
          sortable: true
        },
        {
          key: "template",
          sortable: true
        },
        {
          key: "sequenceTypeName",
          label: "Type",
          sortable: true
        },
        {
          key: "first",
          thClass: "text-center",
          tdClass: "text-right",
          sortable: true
        },
        {
          key: "next",
          thClass: "text-center",
          tdClass: "text-right",
          sortable: true
        },
        {
          key: "last",
          thClass: "text-center",
          tdClass: "text-right",
          sortable: true
        }
      ]
    };
  },
  components: {
    SearchScreen
  }
};
</script>

