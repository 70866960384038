<template>
    <p-modal
        name="AppleSalesModal"
        label="Apple Report"
        ref="AppleSalesModal"
        size="lg"
        @ok.prevent="handleModalOk"
    >
        <template v-slot:default>
            <p-form ref="appleSalesForm">
                <div class="container-fluid content-wrapper">
                    <b-row cols="1" cols-lg="2">
                        <b-col>
                            <p-card title="Suppliers" class="static-height">
                                <p-advanced-select name="supplier" dataType="suppliers" v-model="reportParameters.supplierId" rules="required"/>
                            </p-card>
                        </b-col>
                        <b-col>
                            <p-card title="Date Range">
                                <p-date-range
                                    :date-range-options="reportParameters"
                                />
                            </p-card>
                        </b-col>
                    </b-row>
                </div>
            </p-form>
        </template>
        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <p-button
                        variant="outline-primary"
                        :is-busy="isBusy"
                        @click="cancel"
                        >Cancel</p-button
                    >
                    <p-button
                        variant="primary"
                        @click="ok"
                        :is-busy="isBusy"
                        class="ml-2"
                        >OK</p-button
                    >
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>
<script>
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    data() {
        return {
            isBusy: false,
            reportParameters: {
                supplierId: null,
                startDate: null,
                endDate: null,
                dateOption: 0,
                locationId: null
            },
        };
    },
    methods: {
        async show() {
            this.$refs.AppleSalesModal.show();
        },
        handleModalOk: async function () {
            if (!(await this.$refs.appleSalesForm.validate())) {
                return;
            }

            this.isBusy = true;

            this.reportParameters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();

            await axios
                .post('/sales/exportAppleSalesCsv', this.reportParameters, {
                    responseType: 'blob'
                })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        'Apple Report.csv',
                        'application/csv'
                    );
                    this.$toasted.global
                        .app_success(`Apple Report generated successfully.`)
                        .goAway(5000);
                    this.$refs.AppleSalesModal.hide();
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },
};
</script>
