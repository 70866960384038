<template>
    <SearchScreen
        title="Order Decisions"
        api-destination="orderdecisions"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        ref="search"
        :edit-url-func="item => `/orderdecisions/${item.orderDecisionId}`"
        @is-table-loading="(isBusy = $event)"
    >
        <template v-slot:actionButtons>
            <p-button :is-busy="isBusy" variant="primary" @click="workDecisions"
                >Work Order Decisions</p-button
            >
        </template>
        <template v-slot:filters>
            <div v-if="searchLoaded">
                <b-row>
                    <b-col>
                        <h4 class="">Filters</h4>
                    </b-col>
                    <b-col cols="auto mb-2" class="ml-auto">
                        <p-button
                            @click="saveSearch"
                            :is-busy="savingSearch"
                            variant="primary"
                            finished-message="Search Saved"
                            >Save Search</p-button
                        >
                    </b-col>
                </b-row>
                <!-- no-body allows for us to set smaller padding -->
                 <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        itemText="value"
                        dataType="workStatus"
                        label="Work Status"
                        v-model="filters.workStatuses"
                    />
                </p-card>
                <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        data-type="terms"
                        label="Terms"
                        :filter="termFilter"
                        v-model="filters.termIds"
                         
                    />
                </p-card>
                <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        data-type="instructors"
                        label="Instructors"
                        use-text-for-value
                        v-model="filters.instructorNames"
                        value-itemText="text"
                         
                    />
                </p-card>
                <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        dataType="courseDepartments"
                        use-text-for-value
                        label="Departments"
                        v-model="filters.courseDepartmentNames"
                       
                        
                    />
                </p-card>
                <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        dataType="courses"
                        label="Courses"
                        use-text-for-value
                        :filter="courseFilter"
                        no-match-message="No Matches In Selected Depts"
                        v-model="filters.courseNames"
                    />
                </p-card>
                <p-card class="mb-2 px-2 py-2" no-body>
                    <p-filter-select
                        :options="sectionOptions"
                        label="Sections"
                        no-match-message="No Matches In Selected Courses"
                        v-model="filters.sectionNames"
                       
                    />
                </p-card>
               
            </div>
        </template>
    </SearchScreen>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import lodashUnion from 'lodash/union';
import StoreListManager from '@/components/mixins/StoreListManager.js';

export default {
      mixins: [StoreListManager],
    data() {
        return {
            isBusy: false,
            index: 0,
            searchLoaded: false,
            savingSearch: false,
             selectListOptions: {
                 courses:[],
                 courseDepartments:[],
                 instructors:[],
                 terms:[],
                 
             },
            filters: {
                automatic: '',
                searchBy: 'All',
                perPage: 100,
                termIds: [],
                workStatuses: [],
                instructorNames: [],
                courseDepartmentNames: [],
                courseNames: [],
                sectionNames: []
            },
            filterBy: [
                'All',
                'Author',
                'Title/Description',
                'ISBN',
                'Barcode',
                'Book Key',
                'Instructor'
            ],
            fields: [
                {
                    key: 'authorTitleDescription',
                    label: 'Author/Title/Description',
                    sortable: true
                },
                {
                    key: 'term',
                    label: 'Term/Dept/Course/Sec',
                    sortable: true
                },
                {
                    key: 'isbnBarcode',
                    label: 'ISBN',
                    sortable: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'instructor',
                    label: 'Instructor',
                    sortable: true
                },
                 {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }
            ]
        };
    },
    computed: {
        sectionOptions() {
            return lodashUnion(
                this.selectListOptions.courses
                    .filter(x => this.filters.courseNames.indexOf(x.text) > -1)
                    .flatMap(x => x.sections)
                    .map(x => x.sectionName)
            ).map(x => ({ value: x, text: x }));
        }
    },
    mounted: async function() {
        this.isBusy = true;
        axios
            .get('users/orderdecisionssearchsettings')
            .then(resp => {
                this.filters.automatic = resp.data.automatic;

                this.filters.termIds = resp.data.termIds || [];
                this.filters.instructorNames = resp.data.instructorNames || [];
                this.filters.workStatuses = resp.data.workStatuses || [];
                this.filters.courseDepartmentNames=resp.data.courseDepartmentNames || [];
                this.filters.courseNames=resp.data.courseNames || [];
                this.filters.sectionNames=resp.data.sectionNames || [];
                //setting defaults for newly added filter parameters
                this.filters.searchBy = resp.data.searchBy ?? 'All';
                this.filters.perPage = resp.data.perPage ?? 100;
                this.filters.sortBy =
                    resp.data.sortBy ?? 'authorTitleDescription';
                this.filters.sortDesc = resp.data.sortDesc;
                this.searchLoaded = true;
            
            })
            .catch(() => {
                //Shouldn't happpen, but if the user has an invalid setting saved, let
                //them continue and they can fix it by saving a new search setting
                this.searchLoaded = true;
            }).finally(() => {
                        this.isBusy = false;
            });
    },
    methods: {
         
        courseFilter(option) {
            return  this.filters.courseDepartmentNames.includes(option.departmentName);
        },
        termFilter(option){
            return  option.filter == "True"
        },
        saveSearch() {
            this.savingSearch = true;  
            this.isBusy = true;
            axios
                .post('users/orderdecisionssearchsettings', this.filters)
                .then(() => {
                    this.savingSearch = false;
                }) .finally(() => {
                    this.isBusy = false;
                });
        },
        workDecisions: function() {
            axios
                .get('orderdecisions/getidlist', {
                    params: this.$refs.search.getQueryParameters()
                })
                .then(resp => {
                    var ids = resp.data;
                    this.$router.push({
                        name: 'workorderdecisions',
                        params: { ids }
                    });
                });
        },
        tableDataUpdated(e){
            this.isBusy = e;
        }
    },
    components: {
        SearchScreen
    }
};
</script>

<style scoped lang="scss">
/deep/ .noWrap {
    white-space: nowrap;
}
</style>
