<template>
    <div>
        <p-form @submit="onSubmit" ref="form">
            <div
                class="container-fluid content-wrapper"
                v-if="productTransfer"
            >
                 <b-row class="content-heading" v-if="isReceiveTransfer">
                    <b-col>
                        <div>
                            Receive Transfer
                        </div>
                    </b-col> 
                </b-row>
                <b-row class="content-heading" v-else>
                    <b-col>
                        <div>
                            Send Transfer
                        </div>
                    </b-col>
                    <b-col class="text-right">
                        <p-button type="submit" variant="primary" :disabled="disableAllFields" :is-busy="isBusy">
                            Save
                        </p-button>
                    </b-col>
                </b-row>
                
                    <b-row cols="2" v-if ="disableAllFields && this.productTransfer.status!='Open'" class="mt-2 mb-3">
                        <b-col>
                            <b-button
                                variant="outline-primary" 
                                v-on:click="printScreen()"                                
                                > <i class="fas fa-print"></i> Print </b-button
                            >

                        </b-col>
                    </b-row>
                <p-card>

                    <b-row cols="2" cols-md="4">
                        <b-col>
                            <p-input
                                label="Status"
                                v-model="productTransfer.status"
                                rules="max:50"
                                disabled
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Location of Origin"
                                v-model="originLocationName"
                                disabled
                            />
                        </b-col>
                        <b-col>
                            <p-select
                                data-type="locations"
                                v-model="productTransfer.destinationLocationId"
                                label="Destination"
                                rules="required"
                                :disabled="disableAllFields"
                                :filter="currentLocationFilter"
                                @change="disableProductTransfer()"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Transfer #"
                                v-model="productTransfer.transferNumber"
                                rules="max:50"
                                disabled
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="2" cols-md="4">                        
                        <b-col >
                            <p-input
                                label="Date & Time Last Update"
                                v-model="computedDateTimeLastUpdated"
                                disabled
                            />
                        </b-col>
                                         
                        <b-col v-if="productTransfer.status != 'Open'">
                            <p-input
                                label="Date & Time Sent"
                                v-model="computedDateTimeSent"
                                disabled
                            />
                        </b-col>
                    </b-row>
                </p-card>
                <p-card>
                    <b-row>
                        <div class="ml-3 mt-2">
                            <h4>Products</h4>
                        </div>
                        <b-col>
                            <b-row>
                                <b-col class="text-left" v-if=" !isReceiveTransfer">
                                    <ProductMultiSelectQuickSearch
                                        label="Search Products"
                                        name="itemSearch"     
                                        single-select-button-label="Quick Add" 
                                        fieldSet="productTransferFields"                                       
                                        @selections="addSearchItems"                                       
                                        enable-dual-selection 
                                        location-specific-only  
                                        :disableAddButton='disableAllFields'    
                                    />
                                </b-col>
                                <!-- this is the modal for when their scan finds zero/multiple results. button is disabled by setting buttonLabel to "" -->
                                <ProductMultiSelectQuickSearch
                                    name="multiResultSelect"
                                    @selections="handleMultiResultSelect"
                                    :prefill-search="searchPrefill"
                                    single-select
                                    :modal-label="multiResultLabel"
                                    label=""
                                />
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col>
                            <p-table
                                :foot-clone="!disableAllFields"
                                no-footer-sorting
                                foot-variant="light"
                                :items="
                                    productTransfer.productTransferDetails
                                "
                                :fields="fields"
                                :per-page="itemsPerPage"
                                :current-page="currentPage"
                                empty-text=""
                                show-per-page-selector
                                v-on:pageChanged="onPageChanged"
                                :enableRowSelection="false"
                                ref="detailTable"
                            >
                                <template
                                    v-slot:cell(scanCode)="{
                                        value,
                                        item,
                                        index
                                    }"
                                >

                                    {{ item.scanCode }}
                                </template>
                                <template
                                    v-slot:cell(sentQty)="{ value, item, index }"
                                >
                                    <p-number
                                        v-model.number="item.sentQty"
                                        name="Qty"
                                        input-type="integer"
                                        :ref="'qtyInputs_' + index"
                                        @keydown="
                                            handleQtyKeydown(
                                                $event,
                                                item,
                                                index
                                            )
                                        "
                                        @blur="handleQtyBlur(item)"
                                        @change="disableProductTransfer()"
                                        :rules="getQtyRules(item)"
                                        :disabled="disableAllFields"
                                        compact-format
                                    >
                                    </p-number>
                                </template>
                                <template
                                    v-slot:cell(authorTitleOrDescription)="{ value, item, index }"
                                >
                                    <div>{{ item.authorTitleOrDescription }}</div>
                                    <div>{{ item.bookVariant?bookVariantFormatter(item.bookVariant):(item.gmVariant1?item.gmVariant1+' - '+item.gmVariant2:'') }}</div>
                                </template>
                                <template v-slot:cell(delete)="{ value, item }">
                                    <p-button class="p-1 ml-1 short-button"
                                        v-if="!isReceiveTransfer && !disableAllFields"
                                        @click="confirmDeleteDetail(item)"
                                        :is-busy="isBusy"
                                        >
                                        <BIconTrash icon="trash"
                                                    font-scale="0.9"
                                                    class="mb-1"></BIconTrash>
                                    </p-button>
                                </template>

                                <!-- footer -->
                               <template v-slot:foot(selected)="data">
                                </template>
                                <template v-slot:foot(scanCode)="data" >
                                    <p-input
                                        v-model="newItem.scanCode"
                                        name="ScanCode"
                                        ref="newScanInput"
                                        placeholder="Scan new item..."
                                        @input="
                                            scanCodeInputDebounce(
                                                newItem,
                                                true,
                                                -1
                                            )
                                        "
                                        @change="disableProductTransfer()"
                                        rules="max:128"
                                        compact-format
                                        class="non-printable"
                                    >
                                    </p-input>
                                </template>
                                <template v-slot:foot(sentQty)="data">
                                    <p-number
                                        v-model.number="newItem.sentQty"
                                        input-type="integer"
                                        name="Qty"
                                        ref="newQtyInput"
                                        @keydown="
                                            handleNewQtyKeydown($event, newItem)
                                        "
                                        @blur="handleNewQtyBlur(newItem)"
                                        @change="disableProductTransfer()"
                                       :rules="getNewQtyRules(newItem)"
                                        class="text-right non-printable"
                                        compact-format
                                        
                                    >
                                    </p-number>
                                </template>
                                <template v-slot:foot(sku)="data">
                                    {{ newItem.sku }}
                                </template>
                                <template v-slot:foot(accountingCost)="data">
                                    <div class="text-right">{{ currencyFormatter(newItem.accountingCost) }}</div>
                                </template>
                                <template
                                    v-slot:foot(authorTitleOrDescription)="data"
                                >
                                    <div>{{ newItem.authorTitleOrDescription }}</div>
                                    <div>{{ newItem.bookVariant?bookVariantFormatter(newItem.bookVariant):(newItem.gmVariant1?newItem.gmVariant1+' - '+newItem.gmVariant2:'') }}</div>
                                </template>
                                <template v-slot:foot(defaultBarcode)="data">
                                    <div class="text-right">{{ newItem.defaultBarcode }}</div>
                                </template>
                                <template v-slot:foot(stockOnHand)="data">
                                    <div class="text-center">{{ newItem.stockOnHand }}</div>
                                </template>
                                <template v-slot:foot(delete)="{ value, item }">

                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <b-row>
                    <b-col class=" mb-3 text-left">
                        <p-button variant="outline-primary"
                            @click="cancelProductTransfer"
                            :disabled="this.productTransfer.productTransferDetails.length<=0" :is-busy="isBusy"
                            >Cancel</p-button
                        >
                    </b-col>

                    <b-col class=" mb-3 text-right" v-if="isReceiveTransfer">
                        <p-button variant="primary" class="mr-2" @click="receiveTransfer()" :disabled="disableReceiveTransfer" :is-busy="isBusy"
                            >Receive Transfer</p-button
                        > 
                    </b-col>
                    <b-col class=" mb-3 text-right" v-else>
                        <p-button variant="outline-primary" class="mr-2" @click="sendTransfer()" :disabled="disableSendTransfer" :is-busy="isBusy"
                            >Send Transfer</p-button
                        >
                        <p-button variant="primary" type="submit" :disabled="disableAllFields" :is-busy="isBusy"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </div>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import ProductMultiSelectQuickSearch from '@/components/ProductMultiSelectQuickSearch.vue';
import debounce from 'lodash/debounce';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import { extend } from 'vee-validate';
import {bookVariantFormatter, currencyFormatter} from '../../components/Common/Formatters.js';
import { datetimeToLocalTimeFormatter } from '../../components/Common/Formatters.js';

extend('exceed-soh', {
    message: () => {
        return 'Qty may not exceed SOH';
    },

    validate: (value, { stockOnHand }) => {
        return (
                (parseInt(stockOnHand) || 0) >=
                (parseInt(value) || 0)
        );
    },
    params: ['stockOnHand']
});
export default {
    components: {
        ProductMultiSelectQuickSearch
    },
    mixins: [NavigationGuard,StoreListManager],
    props: {
        id: String
    },
    data() {
        return {
            isBusy:false,
            productTransfer: null, 
            currentItem: null,
            quickSearchSelections: null,
            itemsPerPage: 50,
            scanCodeDebounce: null,
            searchPrefill: null,
            currentPage: 1,
            newItem: {
                scanCode: null,
                sku: null,
                sentQty: null,
                authorTitleOrDescription: null,
                accountingCost: null,
                bookVariant:null,
                gmVariant1: null,
                gmVariant2: null,
                defaultBarcode:null,
                stockOnHand: null
            },
            multiResultLabel: 'Multiple Products Found',
            selectedRows: [],
            originLocationName:null,
            enableProductTransfer:false,
            selectedLocationId :  null,
            isReceiveTransfer: false
        };
    },
    computed: {
         fields() {
             return[
                {
                    key: 'scanCode',
                    label: 'SKU/Barcode'
                },
                {
                    key: 'sentQty',
                    label: 'Qty'
                },
                {
                    key: 'stockOnHand',
                    label: 'Stock On Hand',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    hidden: this.isReceiveTransfer,
                },
                {
                    key: 'accountingCost',
                    formatter: currencyFormatter,
                    thClass: 'text-right',
                    tdClass: 'text-right'
                },
                {
                    label: 'Product Name & Variants',
                    key: 'authorTitleOrDescription'
                },
                {
                    key: 'defaultBarcode',
                    label: 'Default Barcode',
                    thClass: 'text-right',
                    tdClass: 'text-right'
                },
                {
                    key: 'delete',
                    label: '',
                    thClass: 'non-printable',
                    tdClass: 'non-printable',
                    hidden: this.isReceiveTransfer,
                }
            ]
        },
        computedDateTimeSent(){
            return datetimeToLocalTimeFormatter(this.productTransfer.sentDateTime);
        },
        computedDateTimeLastUpdated(){
            return datetimeToLocalTimeFormatter(this.productTransfer.lastModifiedDateTime);
        },
        isNew() {
            return this.id === 'new';
        },
        allItemsValid() {
            return (
                this.productTransfer.productTransferDetails.filter(
                    d => d.sku == null
                ) == 0
            );
        },
        disableSendTransfer(){            
            return !this.productTransfer.destinationLocationId>0 || this.productTransfer.productTransferDetails.length==0 || this.disableAllFields || this.productTransfer.productTransferDetails.some(x=>(x.sentQty == 0 && x.stockOnHand == 0));
        },
        disableAllFields(){

            return this.productTransfer.status=='In Transit'  || this.productTransfer.status=='Closed'|| this.isReceiveTransfer || (this.selectedLocationId == this.productTransfer.destinationLocationId && this.productTransfer.status == 'Open');
        },
        disableReceiveTransfer(){
            return this.productTransfer.status=='Closed';
        }        
    },
    mounted: function() {
        this.scanCodeDebounce = debounce(this.searchProducts, 525);
        this.loadData();
    },
    watch: {
        productTransfer: NavigationGuard.$watcher,
        newItem: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        bookVariantFormatter:bookVariantFormatter,
        currencyFormatter: currencyFormatter,
        printScreen(){
            window.print();
        },
        scanCodeInputDebounce(newItem, fromNewInput, index) {
            this.scanCodeDebounce(newItem, fromNewInput, index);
       },
        getQtyRules(item){
            if (item.scanCode != null){
                 return this.disableAllFields?"":`required|min_value:${item.stockOnHand?'1':'0'}|exceed-soh:${item.stockOnHand}`;
            }
           
        },
        getNewQtyRules(item){
            if (item.scanCode != null){
                 return this.disableAllFields?"":`min_value:${item.stockOnHand?'1':'0'}|exceed-soh:${item.stockOnHand}`;
            }
           
        },
        async loadData() {
            this.selectedLocationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            let locations = await selectListOptionsDataContext.getStoreDropdownData(
                            'locations'
                        );
            let promise = this.isNew
                ? axios.get('productTransfers/new')
                : axios.get('productTransfers', { params: { id: this.id, locationId: this.selectedLocationId } });
            promise.then(res => {
                this.enableProductTransfer = true;
                this.productTransfer = res.data;
                if(this.selectedLocationId == this.productTransfer.destinationLocationId && this.productTransfer.status != 'Open')
                 this.isReceiveTransfer = true;
                if(this.isNew) this.productTransfer.originLocationId = this.selectedLocationId;
                this.originLocationName = locations.find(
                        x => x.value === this.productTransfer.originLocationId
                    ).text;
                if (!this.isNew) {
                    this.dataLoaded();
                }
                if(!this.disableAllFields){
                    this.$nextTick(() => {
                        this.$refs['newScanInput'].focus();
                    });
                }                
            });
        },
        searchProducts(item, fromNewInput, index) {
            if (item.scanCode) {
                axios
                    .post('productquicksearch/search', {
                        automatic: item.scanCode,
                        locationId: this.productTransfer.originLocationId
                    })
                    .then(resp => {
                        if (resp.data.count == 1) {                         
                            var p = resp.data.data[0];
                            if(this.productTransfer.productTransferDetails.some(x=>x.defaultBarcode == p.defaultBarcode)){
                                if (p.sku.toString().indexOf(item.scanCode.toString()) > -1) {       
                                    item.scanCode = p.sku.toString();
                                } else {
                                    item.scanCode = p.defaultBarcode.toString();
                                }  
                                this.$toasted.global
                                    .app_error(
                                        `Item (${item.scanCode}) already added to Transfer`
                                    )
                                    .goAway(5000);
                                item.scanCode = "";
                            }else{
                                item.sku = p.sku;                               
                                item.authorTitleOrDescription = p.authorTitleOrDescription;
                                item.accountingCost = p.accountingCost
                                item.bookVariant = p.bookVariant;
                                item.gmVariant1 = p.gmVariant1;
                                item.gmVariant2 = p.gmVariant2;
                                item.defaultBarcode=p.defaultBarcode;
                                item.stockOnHand = p.stockOnHand;
                                item._rowVariant = null;
                                let sku= p.sku.toString();
                                if (sku.indexOf(item.scanCode.toString()) > -1) {       
                                    item.scanCode = p.sku.toString();
                                } else {
                                    item.scanCode = p.defaultBarcode.toString();
                                }                               
                                if (index > -1) {
                                    this.$refs['qtyInputs_' + index].focus();
                                } else {
                                    this.$refs['newQtyInput'].focus();
                                }
                            }                            
                        } else if (resp.data.count == 0 && fromNewInput) {
                            this.clearItem(item);
                        } else {
                            this.clearItem(item);

                            this.searchPrefill = item.scanCode;
                            this.currentItem = item;
                            this.multiResultLabel =
                                resp.data.count == 0
                                    ? 'Scan Not Found'
                                    : 'Multiple Products Found';
                            this.$nextTick(() => {
                                this.$bvModal.show('multiResultSelect');
                            });
                        }
                    });
            } else {
                //clear item
                item.sentQty = null;
                this.clearNewItem(item);
            }
        },
        clearItem(item) {
            item.sku = null;
            item.authorTitleOrDescription = 'PRODUCT NOT FOUND';
            item.accountingCost = 'N/A'
            item.defaultBarcode = 'N/A'
            item.stockOnHand = 'N/A';
            item._rowVariant = 'danger';
        },
        handleNewQtyKeydown: async function(e, item)  {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            if(item.sentQty == null || item.sentQty=="") return;
            if (this.enterOrTab(e.code)) {
                e.preventDefault();

                if (e.shiftKey == true) {
                    this.$refs['newScanInput'].focus();
                    return;
                }

                this.addItem(item);
            }
        },
        handleNewQtyBlur: async function(item) {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            if (item.sentQty || item.sentQty == 0) {
                this.addItem(item);
            }
        },
        addItem(item) {
            if (item.sku) {
                this.productTransfer.productTransferDetails.push({
                    scanCode: item.scanCode,
                    sku: item.sku,
                    sentQty: item.sentQty,
                    authorTitleOrDescription: item.authorTitleOrDescription,
                    accountingCost: item.accountingCost,
                    bookVariant: item.bookVariant,
                    gmVariant1: item.gmVariant1,
                    gmVariant2: item.gmVariant2,
                    defaultBarcode: item.defaultBarcode,
                    stockOnHand: item.stockOnHand
                });
                this.clearNewItem();
                this.$refs['newScanInput'].focus();
            }
        },
        handleQtyBlur(item) {
            if(item.sentQty>item.stockOnHand)  {
                item.sentQty = item.stockOnHand;
            } 
        },
        clearNewItem() {
            this.newItem.scanCode = '';
            this.newItem.sentQty = null;
            this.newItem.sku = null;
            this.newItem.authorTitleOrDescription = null;
            this.newItem.accountingCost = null;
            this.newItem.bookVariant = null;
            this.newItem.gmVariant1 = null;
            this.newItem.gmVariant2 = null;
            this.newItem.defaultBarcode = null;
            this.newItem.stockOnHand = null;
            this.newItem._rowVariant = null;
        },
        enterOrTab(code) {
            return code == 'Enter' || code == 'Tab' || code == 'NumpadEnter';
        },
        handleQtyKeydown(e, item, index) {
            if (this.enterOrTab(e.code)) {
                e.preventDefault();

                var nextFld = e.shiftKey
                    ? this.$refs['qtyInputs_' + (index - 1)]
                    : this.$refs['qtyInputs_' + (index + 1)];
                if (nextFld) {
                    nextFld.focus();
                } else {
                    this.$refs['newScanInput'].focus();
                }
            }
        },
        cancelProductTransfer() {
            this.$router.push('/producttransfers');
        },
        async onSubmit() {
            //stop if any items are not valid            

            this.beforeSave();
            this.$nextTick(async () => {
                if(!(await this.$refs.form.validate())) {                        
                        return;
                }
            
                if (!this.allItemsValid) {
                    this.$toasted.global
                        .app_error('Please remove invalid rows before saving.')
                        .goAway(5000);
                    return;
                }
                this.isBusy = true;
                axios
                    .post('productTransfers', this.productTransfer)
                    .then(response => {
                        this.dataSaved();
                        this.enableProductTransfer = true;
                        if(this.productTransfer.isSendTransfer){
                            this.$toasted.global
                                .app_success(`product(s) transferred successfully`)
                                .goAway(5000);
                        }else if(this.productTransfer.isReceiveTransfer){
                            this.$toasted.global
                                .app_success(`product(s) received successfully`)
                                .goAway(5000);
                        }else{
                            this.$toasted.global
                                .app_success(`Product transfer record(s) saved successfully.`)
                                .goAway(5000);
                        }  
                        if (this.isNew) {
                            this.$router.push(
                                '/producttransfers/' + response.data.productTransferId
                            ); 
                        }else{
                            this.loadData();
                        }                     
                    })
                    .finally(() => {
                            this.isBusy = false;
                    });
            });
        },
        async sendTransfer() {
            if (!this.allItemsValid) {
                this.$toasted.global
                    .app_error('Please remove invalid rows before transferring.')
                    .goAway(5000);
                return;
            }
            this.beforeSave();
            this.$nextTick(async () => {
                if(!(await this.$refs.form.validate())) {                        
                    return;
                }
                this.isBusy = true;
                try {
                    let response = await axios.post('productTransfers', this.productTransfer);
                    if (this.isNew) {
                        this.$router.push(
                            '/producttransfers/' + response.data.productTransferId
                        ); 
                        this.$toasted.global
                            .app_success(`Product transfer record(s) saved successfully.`)
                            .goAway(3000);
                    }
                    this.productTransfer.productTransferDetails.forEach(x => x.stockOnHand = response.data.productTransferDetails.find(y => y.sku == x.sku)?.stockOnHand);
                    this.productTransfer.productTransferId = response.data.productTransferId;
                    //need to use nextTick to give elements a chance to update before validating.
                    //Otherwise we could do a direct check like this.productTransfer.productTransferDetails.some(x => x.stockOnHand < x.sentQty)
                    this.$nextTick(async () => {
                        if(!(await this.$refs.form.validate())) {
                            this.$toasted.global
                                .app_error(
                                    'One or more items have QTY that is not valid'
                                )
                                .goAway(5000);
                            return;
                        }                                       
                        this.$bvModal
                        .msgBoxConfirm(
                            'Are you sure you want to transfer products?'
                        )
                        .then(value => {
                            if (value) {
                                this.productTransfer.isSendTransfer = true;
                                this.onSubmit();
                            }
                        });
                    })
                } finally {
                    this.isBusy = false;
                }
            });
        },
        receiveTransfer() {
                this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to receive products?'
                )
                .then(value => {
                    if (value) {
                        this.productTransfer.isReceiveTransfer = true;
                        this.onSubmit();
                    }
                });
        },
        beforeSave(){
            //The last input form row should be saved.
            if (this.newItem.sku) {
                this.productTransfer.productTransferDetails.push({
                    scanCode: this.newItem.scanCode,
                    sku: this.newItem.sku,
                    sentQty: this.newItem.sentQty,
                    authorTitleOrDescription: this.newItem.authorTitleOrDescription,
                    accountingCost:this.newItem.accountingCost,
                    bookVariant: this.newItem.bookVariant,
                    gmVariant1: this.newItem.gmVariant1,
                    gmVariant2: this.newItem.gmVariant2,
                    defaultBarcode: this.newItem.defaultBarcode,
                    stockOnHand: this.newItem.stockOnHand
                });

                this.clearNewItem();
                this.$refs['newScanInput'].focus();
            }
            
        },
        addSearchItems(items) {
            let result = items.filter(o1 => this.productTransfer.productTransferDetails.some(o2 => o1.defaultBarcode === o2.defaultBarcode));
            if(result.length>0) {
                this.$toasted.global
                                    .app_error(
                                        'one or more items already added to Transfer'
                                    )
                                    .goAway(5000);
            }
            let index=this.productTransfer.productTransferDetails.length;           
                                
            items.forEach(item => {
                    if(!this.productTransfer.productTransferDetails.some(x=>x.defaultBarcode == item.defaultBarcode)){
                    this.productTransfer.productTransferDetails.push({
                        scanCode: item.sku.toString(),
                        sku: item.sku,
                        sentQty: 0,
                        authorTitleOrDescription: item.authorTitleOrDescription,
                        accountingCost: item.accountingCost,
                        stockOnHand: item.stockOnHand,
                        defaultBarcode: item.defaultBarcode,
                        bookVariant: item.bookVariant,
                        gmVariant1: item.gmVariant1,
                        gmVariant2: item.gmVariant2
                    });
                } 
            });
            let that = this;
            setTimeout(function(){
                that.$refs['qtyInputs_' + index].focus();
            },300)
            
        },
        handleMultiResultSelect(item) {
            if (item) {
                this.currentItem.sku = item.sku;
                this.currentItem.scanCode = item.sku.toString();
                this.currentItem.authorTitleOrDescription =
                    item.authorTitleOrDescription;
                this.currentItem.accountingCost = item.accountingCost;
                this.currentItem.stockOnHand = item.stockOnHand;
                this.currentItem.defaultBarcode= item.defaultBarcode,
                this.currentItem.bookVariant= item.bookVariant,
                this.currentItem.gmVariant1= item.gmVariant1,
                this.currentItem.gmVariant2= item.gmVariant2
                this.currentItem._rowVariant = null;
            }
        },
        disableProductTransfer() {
            this.enableProductTransfer = false;
        },
        onPageChanged(pageNum) {
            this.currentPage = pageNum;
        },
        currentLocationFilter(location){
             return (
                location.value != this.productTransfer.originLocationId
            );
        },
        confirmDeleteDetail(detail) {
            this.$bvModal
                .msgBoxConfirm(
                    'Are you sure you want to remove this product from the list?'
                )
                .then(value => {
                    if (value) {
                        this.deleteDetail(detail);
                        setTimeout(() => {
                            this.$nextTick(() => {
                            this.$refs['newScanInput'].focus();
                            });
                        },300
                    );
                        this.disableProductTransfer();
                    }
                });
        },
        deleteDetail(detail) {
            let index = this.productTransfer.productTransferDetails.indexOf(detail);
            if (index !== -1) {
                this.productTransfer.productTransferDetails.splice(index, 1);                
            }
        },
        datetimeToLocalTimeFormatter: datetimeToLocalTimeFormatter
    }
};
</script>

<style scoped lang="scss">
@media print { 
    /deep/ .content-heading{
         margin-bottom: 20px;
         margin-left: 0px !important;
         padding: 0px;
    }    
    /deep/ .non-printable{
        display: none;
    }     
}
</style>

