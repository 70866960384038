<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <div class="container-fluid content-wrapper">
            <b-row class="content-heading">
                <b-col>
                    <div>Buyback - Purchasers</div>
                </b-col>
            </b-row>
            <b-row>
                <editPurchaser
                    class="pb-2 ml-3 mr-2"
                    purchaser-id="new"
                    @data-saved="updatePurchaserSearch()"
                />
            </b-row>
        <b-row>
                    <p-search-table
                        :fields="purchaserFields"
                        ref="purchaserSearch"
                        :selectable="false"
                        :enable-row-selection="false"
                        search-destination="purchasers"
                        sort-by="purchaserName"
                        api-destination="buyback"
                    >
                        <template v-slot:cell(edit)="{ value, item }">
                            <editPurchaser
                                :purchaser-id="item.buybackPurchaserId"
                                @data-saved="updatePurchaserSearch()"
                            />
                        </template>
                        <template v-slot:cell(isSpeculative)="{ value, item }">
                            <p-checkbox disabled v-model="item.isSpeculative" />
                        </template>
                        <template v-slot:cell(isActive)="{ value, item }">
                            <p-checkbox disabled v-model="item.isActive" />
                        </template>
                    </p-search-table>
        </b-row>
        </div>
    </p-secure>
</template>

<script>
import editPurchaser from '@/views/Buyback/EditPurchaser.vue';

export default {
    components: { editPurchaser },
    props: {
        id: String
    },
    data() {
        return {
            purchaserFields: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'purchaserName',
                    sortable: true
                },
                {
                    label:'Speculative',
                    key: 'isSpeculative',
                    sortable: true
                },
                {
                    label:'Active',
                    key: 'isActive',
                    sortable: true
                }
            ]
        };
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {},
    methods: {
        updatePurchaserSearch() {
            this.$refs?.purchaserSearch.refreshSearchResults();
        }
    }
};
</script>
<style scoped>
/deep/ .tcw100 {
    width: 100px;
}
</style>
