<template>
    <p-secure :require-role="[SECURITYROLES.STOREMANAGER]">
        <SearchScreen
            title="Buyback Session Search"
            api-destination="buyback"
            :filters="filters"
            :filterBy="filterBy"
            :fields="sessionFields"
            edit-key="id"
            :edit-url-func="item => `/buybacksessions/${item.buybackSessionId}`"
            :refreshSearchResults="refreshSearchResults"
            ref="sessionSearch"
        >
            <template v-slot:actionButtons>
                <editSession
                    class="mr-2"
                    session-id="new"
                    @data-saved="updateSessionSearch()"
                />
            </template>

            <template v-slot:belowSearch>
                <p-form>
                    <b-row class="ml-3 mt-4 mr-2">
                        <p-checkbox
                            label="Open"
                            class="ml-3"
                            v-model="filters.open"
                        />
                        <p-checkbox
                            label="Closed"
                            class="ml-3"
                            v-model="filters.closed"
                        />
                    </b-row>
                </p-form>
            </template>
        </SearchScreen>
    </p-secure>
</template>

<script>
import editSession from '@/views/Buyback/EditSession.vue';
import { dateFormatter } from '../../components/Common/Formatters.js';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    components: { editSession, SearchScreen },
    props: {
        id: String
    },
    data() {
        return {
            sessionFields: [
                {
                    key: 'edit',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'purchaserName',
                    label: 'Purchaser',
                    sortable:true
                },
                {
                    key: 'createDate',
                    label: 'Create Date',
                    sortable: true,
                    formatter: dateFormatter,
                    automaticSearch: true,
                    sortByFormatted: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                    automaticSearch: true
                }
            ],
            filterName: 'BuybackSessionFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'description',
                sortDesc: true,
                searchBy: 'All',
                open: true,
                closed: false
            },
            filterBy: ['All', 'Description','Purchaser'],
            refreshSearchResults: false
        };
    },

    computed: {},
    methods: {
        updateSessionSearch() {
            this.$refs?.sessionSearch.refreshSearchResults();
        }
    }
};
</script>
<style scoped>
/deep/ .tcw100 {
    width: 100px;
}
</style>
