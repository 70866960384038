<template>
    <p-feature-flag ref="AccountingFlag" name="Accounting">
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="stockAdjustmentReason">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Stock Adjustment Reason</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <p-button type="submit" :disabled="stockAdjustmentReason.isViewOnly" variant="primary" :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>

            <p-card>
                <b-row cols="1" cols-md="2">
                    <b-col>
                        <p-input
                            label="Adjustment Reason"
                            v-model="stockAdjustmentReason.reason"
                            rules="required|max:100"
                            :disabled="stockAdjustmentReason.isViewOnly"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            label="Reason Description"
                            v-model="stockAdjustmentReason.description"
                            :disabled="stockAdjustmentReason.isViewOnly"
                            rules="max:500"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            label="GL Code"
                            value="Unassigned"
                            disabled
                        />
                    </b-col>
                    <b-col>
                        <p-checkbox
                            label="Active"
                            :use-switch="true"
                            v-model="stockAdjustmentReason.isActive"
                            :disabled="stockAdjustmentReason.isViewOnly"
                            class="mt-3 pt-3 mb-3 pb-3"
                        />
                        <p
                            v-if="!stockAdjustmentReason.isViewOnly">Adjustment Reason must be de-activated in order to be deleted.
                        </p>
                    </b-col>              
                </b-row>
            </p-card>
            <b-row class="mt-3">
                <b-col class="text-left">
                    <p-button
                        variant="outline-danger"
                        type="button"
                        :is-busy="isBusy"
                        @click="deleteReason"
                        v-if="!isNew && !stockAdjustmentReason.isActive"
                        :disabled="stockAdjustmentReason.isViewOnly"
                        >Delete</p-button
                    >
                </b-col>
                <b-col class="text-right">
                    <p-button
                        variant="primary"
                        type="submit"
                        :is-busy="isBusy"
                        :disabled="stockAdjustmentReason.isViewOnly"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
    </p-feature-flag>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js'
import editPage from '@/components/mixins/EditPage';

    export default {
        mixins: [NavigationGuard, editPage],
        data() {
            return {
                stockAdjustmentReason: null,
                isBusy: false
            };
        },
        watch: {
            stockAdjustmentReason: NavigationGuard.$watcher
        },
        methods: {
            onSubmit() {
                this.isBusy = true;
                axios.post("stockadjustmentreason", this.stockAdjustmentReason)
                    .then((response) => {
                        this.dataSaved();
                        if (this.isNew) {
                            this.$router.push("/stockadjustmentreason/" + response.data);
                        }
                        this.$toasted.global.app_success(`Stock Adjustment Reason saved successfully.`).goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('stockadjustmentreason/new')
                : axios.get('stockadjustmentreason', { params: { id: this.id } });

            promise.then(resp => {
                this.stockAdjustmentReason = resp.data;
                this.dataLoaded();                
            });
        },
        deleteReason() {            
                this.isBusy = true;
                axios
                    .post('stockadjustmentreason/delete', this.stockAdjustmentReason)
                    .then(() => {
                        this.dataSaved();
                        this.$router.push('/stockadjustmentreasons');
                        this.$toasted.global
                            .app_success(`Stock Adjustment Reason deleted successfully.`)
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
    
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>
