<template>
    <p-form @submit="onSubmit" v-if="buyersGuide" ref="form">
        <b-overlay
            :show="buyersGuide.isLoadingGuide"
            opacity="0.5"
            spinner-variant="primary"
        >
            <div class="container-fluid content-wrapper">
                <b-row class="content-heading">
                    <b-col>
                        <div>Buyers Guide</div>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <p-button :is-busy="isBusy" variant="primary" v-on:click="saveDetails()"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="auto" class="pr-0">
                        <b-button variant="primary" type="submit"
                            >Load New Guide</b-button
                        >
                    </b-col>
                    <b-col cols="auto" class="pr-0" v-if="false">
                        <ProductMultiSelectQuickSearch
                                        label="Linked Titles"
                                        name="linkedTitles"
                                        default-sort="productauthor"
                                        api-destination="buyersguide"
                                        search-destination="searchlinkedtitles"
                                        field-set='linkedTitleFields'
                                        :enable-row-selection='false'
                                        :remove-filter-options="['Catalog #', 'Description']"
                                        ok-only
                                        ok-title='Close'
                                        />
                    </b-col>
                    <b-col cols="auto" class="pr-0" v-if="false">
                        <b-button
                            variant="outline-primary"
                            v-b-modal.ViewPrintExportModal
                            class="mr-2"
                            >Export</b-button
                        >
                    </b-col>
                    <b-col v-if="false">
                        <price-comparison
                            :vendor-ids="buyersGuide.buyerGuideVendorIds"
                        ></price-comparison>
                    </b-col>
                </b-row>
                
                <p-card>
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                        <b-col >
                            <p-select
                                data-type="guideSource"
                                v-model="buyersGuide.buyersGuideSource"
                                label="Guide Source"
                            />
                        </b-col>
                        <b-col >
                            <p-select
                                data-type="suppliers"
                                v-model="buyersGuide.vendorId"
                                label="Default Supplier Code"
                            />
                        </b-col>
                        <b-col>
                            <p-input
                                label="Last Load Date"
                                v-model="buyersGuide.versionDate"
                                disabled
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                                              
                        <b-col>
                            <p-datepicker v-if="false"
                                label="Expiration Date"
                                v-model="buyersGuide.expirationDate"                                
                                disabled
                            />
                        </b-col>
                        <b-col class="text-left">
                            <p-checkbox v-if="false"
                                label="NBC Buyers Guide"
                                v-model="buyersGuide.isNebraskaBook"
                                disabled 
                            />
                        </b-col>
                    </b-row>
                    <p-card class="mt-2">
                        <b-row class="mb-2">
                            <b-col>
                                <b-radio-group v-model="buyersGuide.isUseFtp">
                                    <b-radio :value="true">sFTP/FTP</b-radio>
                                    <b-radio :value="false"
                                        >Local File</b-radio
                                    >
                                </b-radio-group>
                            </b-col>
                        </b-row>
                        <b-row
                            class="mb-2"
                            cols="1"
                            cols-sm="2"
                            cols-xl="4"
                            v-if="buyersGuide.isUseFtp"
                        >
                            <b-col>
                                <p-input
                                    label="Host URL/Address"
                                    v-model="buyersGuide.ftpAddress"
                                    :rules="buyersGuide.isUseFtp ? 'required|max:200' : ''"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Host Port"
                                    input-type="integer"
                                    v-model.number="buyersGuide.ftpPort"
                                    :rules="buyersGuide.isUseFtp?'required|min_value:1|max_value:65535|integer': ''
                            "
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="Username"
                                    v-model="buyersGuide.ftpUserName"
                                    :rules="buyersGuide.isUseFtp ? 'required|max:50' : ''"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    type="password"
                                    label="Password"
                                    v-model="buyersGuide.ftpPassword"
                                    :rules="buyersGuide.isUseFtp ? 'required|max:100' : ''"
                                />
                            </b-col>
                            <b-col>
                                <p-input
                                    label="File Name"
                                    v-model="buyersGuide.ftpFileName"
                                    rules="required|max:200"
                                />
                            </b-col>
                        </b-row>
                        <b-row
                            class="mb-2"
                            cols="1"
                            cols-xl="2"
                            v-if="!buyersGuide.isUseFtp"
                        >
                            <b-col>
                                <b-form-file
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    ref="bgFile"
                                    v-model="file"
                                ></b-form-file>
                            </b-col>
                        </b-row>
                    </p-card>
                    <b-row>
                        <b-col>
                            <div><b>Commission Percentages</b></div>
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                        <b-col>
                            <p-number
                                input-type="percent"
                                label="A/B"
                                v-model="buyersGuide.abPercentage"
                                rules="required|min_value:0"
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                input-type="percent"
                                label="New"
                                v-model="buyersGuide.newPercentage"
                                rules="required|min_value:0"
                            />
                        </b-col>
                        <b-col>
                            <p-number
                                input-type="percent"
                                label="Used"
                                v-model="buyersGuide.usedPercentage"
                                rules="required|min_value:0"
                            />
                        </b-col>
                    </b-row>
                    <b-row cols="1" cols-sm="2" cols-xl="4">
                        <b-col>
                            <p-checkbox
                                label="Author/Title"
                                v-model="buyersGuide.updateAuthorTitle"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Imprint"
                                v-model="buyersGuide.updateImprint"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Binding"
                                v-model="buyersGuide.updateBinding"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Edition"
                                v-model="buyersGuide.updateEdition"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Copyright"
                                v-model="buyersGuide.updateCopyright"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Adopt ISBN as 13 Digits"
                                v-model="buyersGuide.adoptISBN13"
                            />
                        </b-col>
                        <b-col>
                            <p-checkbox
                                label="Process Retail Price Change"
                                v-model="buyersGuide.updatePrice"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="mt-2">
                            <p-table
                                :items="buyersGuide.locations"
                                :fields="locationFields"
                                :current-page="currentPage"
                                :enable-row-selection="false"
                                :per-page="perPage"
                            >
                                <template
                                    v-slot:cell(priceDecrease)="{ value, item }"
                                >
                                    <p-checkbox
                                        v-model="item.doPriceDecrease"
                                        :disabled="!buyersGuide.updatePrice"
                                    />
                                </template>
                                <template
                                    v-slot:cell(usedPrice)="{ value, item }"
                                >
                                    <p-checkbox
                                        v-model="item.doUsedPriceUpdate"
                                        :disabled="!buyersGuide.updatePrice"
                                    />
                                </template>
                            </p-table>
                        </b-col>
                    </b-row>
                </p-card>
                <b-row>
                    <b-col class="mb-3 text-center">
                        <p-button :is-busy="isBusy" variant="primary" v-on:click="saveDetails()"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
            </div>
            <p-modal
                name="ViewPrintExportModal"
                label="Text Comparison Report"
                ref="ViewPrintExportModal"
                size="md"
                @show="initializeVendor"
            >
                <template v-slot:modal-header> </template>
                <template v-slot:default>
                    <p-select
                        data-type="suppliers"
                        v-model="ExportVendorId"
                        v-if="hasReportData"
                        :filter="
                            x => {
                                return (
                                    buyersGuide.buyerGuideVendorIds === null ||
                                    buyersGuide.buyerGuideVendorIds.includes(
                                        x.value
                                    )
                                );
                            }
                        "
                        label="Supplier"
                    />
                    <div v-else>
                       No data for report avaliable. Please upload a supplier's buyers guide.
                    </div>
                </template>
                <template v-slot:modal-footer>
                    <b-row>
                        <b-col>
                            <b-overlay :show="isBusy"
                                       rounded
                                       opacity="0.5"
                                       spinner-small
                                       spinner-variant="primary"
                                       class="d-inline-block">
                                <b-dropdown
                                    text="Export"
                                    variant="outline-primary"
                                    :disabled="!hasReportData"
                                >
                                    <b-dropdown-item
                                        @click="buyersGuideExport('pdf')"
                                        >PDF</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        @click="buyersGuideExport('csv')"
                                        >CSV</b-dropdown-item
                                    >
                                </b-dropdown>
                            </b-overlay>
                        </b-col>
                    </b-row>
                </template>
            </p-modal>
            <template v-slot:overlay>
                <H3 class="mb-2">Loading Buyers Guide...</H3>
                <div class="text-center mr-3">
                    <b-icon
                        icon="arrow-clockwise"
                        font-scale="3"
                        animation="spin"
                    ></b-icon>
                </div>
            </template>
        </b-overlay>
    </p-form>
</template>

<script>
import axios from 'axios';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import unionBy from 'lodash/unionBy';
import PriceComparison from './PriceComparison.vue';
import ProductMultiSelectQuickSearch from "@/components/ProductMultiSelectQuickSearch.vue";

import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    components: { PriceComparison, ProductMultiSelectQuickSearch },
    mixins: [NavigationGuard],
    data() {
        return {
            isBusy: false,
            buyersGuide: null,
            ExportVendorId: null,
            currentPage: 1,
            perPage: 15,
            file: null,
            triggeredLoadingGuide: false,
            locationFields: [
                {
                    key: 'name',
                    label: 'Location'
                },
                {
                    key: 'priceDecrease',
                    label: 'Do Price Decreases on BG Load'
                },
                {
                    key: 'usedPrice',
                    label: 'Do Used Price on BG Load'
                }
            ]
        };
    },
    watch: {
        buyersGuide: NavigationGuard.$watcher,
        'buyersGuide.isUseFtp': function() {
            //Set data as saved so switching betwen FTP and File upload don't cause unsaved data message if the user leaves the page.
            this.dataSaved();
        }
    },
    mounted: async function() {
        this.$signalRHub.$on('buyersGuideLoaded', this.buyersGuideLoaded);
        await this.loadData();
    },
    computed: {
        hasReportData() {
            return this.buyersGuide.buyerGuideVendorIds != null && this.buyersGuide.buyerGuideVendorIds.length > 0;
        }
    },
    methods: {
        initializeVendor() {
            if (this.buyersGuide.buyerGuideVendorIds && this.buyersGuide.buyerGuideVendorIds.length > 0) {
                this.ExportVendorId = this.buyersGuide.buyerGuideVendorIds[0];
            }
        },
        onSubmit() {
            this.buyersGuide.isLoadingGuide = true;
            let promise = null;
            this.dataSaved();

            if (this.buyersGuide.isUseFtp) {
                promise = axios.post('buyersguide', this.buyersGuide);
            } else {
                if (!this.file) {
                    this.$toasted.global.app_error(
                        'Please select a file or choose FTP.'
                    );
                    this.buyersGuide.isLoadingGuide = false;
                    this.dataLoaded();
                    return;
                }
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append(
                    'commandString',
                    JSON.stringify(this.buyersGuide)
                );

                promise = axios.post('buyersguide/uploadfile', formData);
            }

            this.triggeredLoadingGuide = true;

            promise
                .then(resp => {
                    //retain the password after loading if it exists so we don't display field required.
                    let pass = this.buyersGuide.ftpPassword;

                    this.buyersGuide = resp.data;

                    this.buyersGuide.ftpPassword = pass;

                    this.$refs.bgFile?.reset();

                    this.dataLoaded();

                    this.$toasted.global
                    .app_success(
                        "Buyer's Guide is loading. Feel free to continue working. A notification will display when the Buyer's Guide is finished loading."
                    )
                    .goAway(10000);
                })
                .catch(() => {
                    this.buyersGuide.isLoadingGuide = false;
                    this.$refs.bgFile?.reset();
                    this.dataLoaded();
                    this.triggeredLoadingGuide = false;
                    this.$toasted.global
                        .app_error(
                            "There was an error loading the buyers guide. Please contact support if the problem persists."
                        );
                });

            this.dataLoaded();
            setTimeout(this.loadData, 60000);
        },
        async loadData() {
            let locations = await selectListOptionsDataContext.getStoreDropdownData(
                'locations'
            );
            locations = locations.map(x => ({
                locationId: x.value,
                name: x.text,
                doPriceDecrease: false,
                doUsedPriceUpdate: false
            }));
            axios.get('buyersguide').then(resp => {

                let pass = this.buyersGuide != null ? this.buyersGuide.ftpPassword : null;

                this.buyersGuide = resp.data;

                this.$set(
                    this.buyersGuide,
                    'ftpPassword',
                    pass
                );
                this.buyersGuide.locations = unionBy(
                    this.buyersGuide.locations,
                    locations,
                    'locationId'
                );

                if(this.buyersGuide.isLoadingGuide)
                {
                    setTimeout(this.loadData, 60000);
                }else if (this.triggeredLoadingGuide && !this.buyersGuide.isLoadingGuide)
                {
                    this.triggeredLoadingGuide = false;
                    this.$toasted.global
                    .app_success(
                        "The new Buyer's Guide has successfully loaded."
                    ).goAway(5000);
                }

                this.dataLoaded();
            });
        },
        buyersGuideLoaded: function(isLoading) {
            let loadingGuide = isLoading === 'true';
            this.buyersGuide.isLoadingGuide = loadingGuide;

            if (loadingGuide) {
                setTimeout(this.loadData, 60000);
                return;
            }

            if (!this.triggeredLoadingGuide) {
                this.loadData();
            } else {
                this.triggeredLoadingGuide = false;
            }
        },
        buyersGuideExport(type) {
            this.isBusy = true;
            if (type == 'csv') {
                axios
                    .get('buyersguide/csv', {
                        params: { vendorId: this.ExportVendorId },
                        responseType: 'blob'
                    })
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            'Buyers Guide Text Comparison.csv',
                            'application/csv'
                        );
                        this.$toasted.global
                        .app_success(
                            `Buyers guide report text comparison generated successfully.`
                        )
                        .goAway(5000);
                        this.$refs.ViewPrintExportModal.hide();
                        this.preparingDownload = false;
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }

            if (type == 'pdf') {
                axios
                    .get('buyersguide/exportbuyersguidereport', {
                        params: { vendorId: this.ExportVendorId },
                        responseType: 'blob'
                    })
                    .then(result => {
                        downloadFileToBrowser(
                            result.data,
                            'Buyers Guide Text Comparison',
                            'application/pdf'
                        );
                        this.$toasted.global
                        .app_success(
                            `Buyers guide report text comparison generated successfully.`
                        )
                        .goAway(5000);
                        this.$refs.ViewPrintExportModal.hide();
                        this.preparingDownload = false;
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        },
       async saveDetails() {
            if (!(await this.$refs.form.validate())) {
                return;
            }
            this.isBusy = true;
            axios.post('buyersguide/buyersguideparameters', this.buyersGuide)
            .then(resp => {
                let pass = this.buyersGuide.ftpPassword;
                this.buyerssGuide = resp.data;
                this.buyersGuide.ftpPassword = pass;
                this.dataLoaded();
                this.$toasted.global
                    .app_success(
                        `Buyers Guide saved successfully.`
                    )
                    .goAway(5000);
            })
            .finally(() => {
                        this.isBusy = false;
            });
        },
    }
};
</script>
