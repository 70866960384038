<template>
    <p-secure require-admin>
        <SearchScreen
            ref="searchScreen"
            title="Data Exchange"
            api-destination="dataexchange"
            :filters="filters"
            :filterBy="filterBy"
            :fields="fields"
            add-new-button
            :use-is-active-checkbox="false"
            edit-key="cronJobId"
            :refreshSearchResults="refreshSearchResults"
        >
        <template v-slot:actionButtons>
      
            <router-link to="/dataexchange/new" class="btn btn-primary mr-2"
                ><b-icon-plus></b-icon-plus>Add</router-link
            >
                <p-button 
                    variant="outline-primary"
                    type="button"
                    @click="openJobLog"
                    >Job Log</p-button
                >
        </template>
            <template v-slot:afterSearch>
                <b-col cols="3">
                            <p-select class="h-auto ml-2 shadow-none"
                                    v-model="filters.returnOnlyActiveRecords"
                                    :options="searchOptions" />
                </b-col>
            </template>
            <template v-slot:cell(delete)="{ value, item }">
                        <b-button class="p-1 ml-1 short-button" @click="deleteJob(item)">
                            <BIconTrash icon="trash"
                                       ></BIconTrash>
                        </b-button>
                    </template>
        </SearchScreen>
    </p-secure>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { isActiveFormatter, datetimeToLocalTimeFormatter } from '@/components/Common/Formatters.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'DataExchangeFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy:'All',
                returnOnlyActiveRecords: null
            },
            filterBy:[
                'All',
                'Job Name',
                'Description'
            ],
            searchOptions: [
                { value: null, text: 'All' },
                { value: true, text: 'Active' },
                { value: false, text: 'Inactive' }
            ],
            fields: [
                {
                    key: 'name',
                    label:'Job Name',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },
                {
                    key: 'lastRunDate',
                    label:'Last Run',
                    sortable: true,
                    formatter: datetimeToLocalTimeFormatter
                },
                {
                    key: 'action',
                    sortable: true
                },
                {
                    key: 'cronJobType',
                    label:'Type',
                    sortable: true
                },
                {
                    key: 'isActive',
                    label:'Status',
                    sortable: true,
                    formatter: isActiveFormatter
                },
                {
                        key: 'delete',
                        label: 'Delete'
                }
            ],
            refreshSearchResults: false
        };
    },
    components: {
        SearchScreen
    },
    methods: {
        deleteJob(item) {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this Job?')
                .then(value => {
                    if (value) {
                        axios.post('dataexchange/delete', item).then(resp => {
                            if (resp) {
                                this.$toasted.global
                                    .app_success(
                                        `${item.name} deleted successfully.`
                                    ).goAway(3000);
                                this.$refs.searchScreen.refreshSearchResults();
                            }
                        });
                    }
                });
         },
        openJobLog(){
            this.$router.push('dataexchange/log');
        }
    }        
};
</script>
<style scoped>
>>> .custom-select{
    height: 43px;
}
</style>
