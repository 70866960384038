var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-modal',{ref:"editLocationModal",attrs:{"button-label":_vm.buttonLabel,"icon-plus":_vm.isNew,"size":"lg","label":_vm.pageTitle,"disable-button":_vm.disabled},on:{"ok":function($event){$event.preventDefault();return _vm.handleModalOk.apply(null, arguments)},"shown":_vm.loadData},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('p-form',{ref:"form"},[(_vm.buybackSessionLocation)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('p-advanced-select',{attrs:{"label":"Location","disabled":!_vm.isNew,"data-type":"locations","value":_vm.buybackSessionLocation.buybackLocationId,"rules":"required"},on:{"input":function($event){return _vm.locationChange($event)}}})],1),_c('b-col',{staticClass:"mt-3 pt-3",attrs:{"cols":"4"}},[_c('p-checkbox',{attrs:{"label":"Student ID Required"},model:{value:(
                                _vm.buybackSessionLocation.studentIdRequired
                            ),callback:function ($$v) {_vm.$set(_vm.buybackSessionLocation, "studentIdRequired", $$v)},expression:"\n                                buybackSessionLocation.studentIdRequired\n                            "}})],1)],1),_c('b-row',{attrs:{"cols":"1","cols-sm":"2","cols-xl":"4"}},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('p-checkbox',{attrs:{"label":"Student ID Required"},model:{value:(
                                _vm.buybackSessionLocation.studentIdRequired
                            ),callback:function ($$v) {_vm.$set(_vm.buybackSessionLocation, "studentIdRequired", $$v)},expression:"\n                                buybackSessionLocation.studentIdRequired\n                            "}})],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('p-checkbox',{attrs:{"label":"Condition Field Prompt"},model:{value:(
                                _vm.buybackSessionLocation.conditionFieldPrompt
                            ),callback:function ($$v) {_vm.$set(_vm.buybackSessionLocation, "conditionFieldPrompt", $$v)},expression:"\n                                buybackSessionLocation.conditionFieldPrompt\n                            "}})],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('p-checkbox',{attrs:{"label":"Queue Price Tags"},model:{value:(_vm.buybackSessionLocation.queuePriceTags),callback:function ($$v) {_vm.$set(_vm.buybackSessionLocation, "queuePriceTags", $$v)},expression:"buybackSessionLocation.queuePriceTags"}})],1)],1),_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12"}},[_c('p-select',{attrs:{"label":"Print Receipts","text-field":"text","options":_vm.printReceiptOptions},model:{value:(_vm.buybackSessionLocation.printReceipts),callback:function ($$v) {_vm.$set(_vm.buybackSessionLocation, "printReceipts", $$v)},expression:"buybackSessionLocation.printReceipts"}})],1)],1),_c('b-row',{attrs:{"cols":"12"}}),_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12"}},[_c('p-text-area',{attrs:{"label":"Receipt Header","rules":"max:255"},model:{value:(_vm.buybackSessionLocation.receiptHeader),callback:function ($$v) {_vm.$set(_vm.buybackSessionLocation, "receiptHeader", $$v)},expression:"buybackSessionLocation.receiptHeader"}})],1)],1),_c('b-row',[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"cols":"12"}},[_c('p-text-area',{attrs:{"label":"Receipt Footer","rules":"max:255"},model:{value:(_vm.buybackSessionLocation.receiptFooter),callback:function ($$v) {_vm.$set(_vm.buybackSessionLocation, "receiptFooter", $$v)},expression:"buybackSessionLocation.receiptFooter"}})],1)],1)],1):_vm._e()])]},proxy:true},{key:"modal-footer",fn:function(ref){
                            var ok = ref.ok;
return [_c('b-row',{staticClass:"w-100"},[_c('b-col',{staticClass:"mb-3 text-center"},[_c('p-button',{staticClass:"ml-2",attrs:{"is-busy":_vm.isBusy,"variant":"primary"},on:{"click":ok}},[_vm._v("Save")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }